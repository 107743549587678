<template>
    <div>
        <v-dialog v-model="loading" max-width="400px">
        <v-card>
            <v-card-text class="text-xs-center">
                <v-progress-circular
                :size="35"
                :width="4"
                color="purple"
                indeterminate
                ></v-progress-circular>
                <span>verificación de link</span>
            </v-card-text>            
        </v-card>        
        </v-dialog>
    </div>
  </template>
  
  <script>
  import { db, auth } from '@/firebase'
  import { mapMutations } from 'vuex'
  import Localbase from 'localbase'
  import { v4 as uuidv4 } from 'uuid';

  let dbL = new Localbase('db')
  
  export default {

    data() {
        return {
            codeLanding: '',
            appFromVtaDig: '',
            app: '',
            redirectHost: '',
            tipoEnlace: '',
            itemApp: [],
            loading: true
        }
    },

    computed: {
        activEstat() {
            if (this.app === 'personal' || this.codeLanding !== '') {
                return 'Activada';
            } else if (this.app !== 'personal') {
                return 'Prueba';
            } else {
                return '';
            }
        },
        modoSub() {
            if (this.codeLanding !== '') {
                return 'from-landing';
            } else {
                return 'from-main-web';
            }
        },
        suscripcionID() {
            if (this.codeLanding !== '') {
                return this.codeLanding;
            } else {
                return 'sin-suscripcion';
            }
        }
    },

    created() {        
        this.checkCodigoFromLanding()
        this.checkRedirectHost()
    },

    mounted() {
        this.verificarEnlaceDeAutenticacion()
    },

    methods: {
        ...mapMutations(['mostrarOcupado', 'ocultarOcupado', 'mostrarExito', 'mostrarError', 'mostrarAdvertencia']),
        ...mapMutations('sesion', ['actualizarUsuario']),
        ...mapMutations('usuarioHost', ['actualizarUsuarioHostState']),
        
        async checkCodigoFromLanding () {
            await dbL.collection('subscriPP').get().then(cod => {
                    this.objSubscrip = cod[0];
                    this.codeLanding = cod[0].dataSubscriPayPal.subscriptionID;
                    // this.codeLanding = 'XZXZ'
                }).catch(() => {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                })
        },
        async checkRedirectHost() {
            try {
                const redHo = await dbL.collection('ctaRedirect').get();
                if (redHo.docs.length > 0) {
                    this.redirectHost = redHo.docs[0].data().redirectHost;
                    this.tipoEnlace = redHo.docs[0].data().tipoEnlace;
                } else {
                    // La colección está vacía, puedes manejar este caso si es necesario
                    this.redirectHost = '';
                    this.tipoEnlace = '';
                    // this.mostrarInformacion('La colección está vacía.');
                }
            } catch (error) {
            // Ocurrió un error al obtener la colección
            // this.mostrarInformacion('Ocurrió un error al obtener la colección.');
            }
        },
        async verificarEnlaceDeAutenticacion() {
            this.appSelectedFunc();
            
            // Obtiene el parámetro de la URL de la página actual (el correo electrónico linkado).        
            if (auth.isSignInWithEmailLink(window.location.href)) {
                let email = this.$route.query.email;
                
                if (!email) {
                    email = window.prompt('Please provide your email for confirmation');
                }

                try {
                    const result = await auth.signInWithEmailLink(email, window.location.href);
                    this.guardarUsuario(result.user.uid);
                } catch (error) {
                    console.log(error.code);
                }
            }
        },

        // async verificarEnlaceDeAutenticacion() {
        //     this.appSelectedFunc()
        //        // Obtiene el parámetro de la URL de la página actual (el correo electrónico linkado).        
        //     if (auth.isSignInWithEmailLink(window.location.href)) {
                
        //         const email = this.$route.query.email;
                
        //         if (!email) {
        //             email = window.prompt('Please provide your email for confirmation');
        //         }

        //         auth.signInWithEmailLink(email, window.location.href)
        //             .then((result) => {
        //                 this.guardarUsuario(result.user.uid)                        
        //             })
        //             .catch((error) => {
        //                 console.log(error.code)
        //             });
        //     }
        // },
        async appSelectedFunc() {
            this.app = this.$route.query.app.toLowerCase();
            this.appFromVtaDig = this.$route.query.app;
            
            try {
                const docRef = db.collection('itemsApps').doc(this.appFromVtaDig);
                const doc = await docRef.get();

                if (doc.exists) {
                    // Convierte el documento a un objeto y agrégalo al array
                    this.itemApp = [doc.data()];
                } else {
                    // Si el documento no existe, puedes asignar un valor predeterminado al array o dejarlo vacío según tus necesidades
                    this.itemApp = [];
                }
            } catch (error) {
                console.error('Ocurrió un error consultando las apps:', error);
                this.mostrarError('Ocurrió un error consultando las apps.');
            }
        },
        
        async guardarUsuario(uid) {
      
            try {
                // Verifica si el usuario ya existe en la base de datos
                const userDoc = await db.collection('usuarios').doc(uid).get();
                
                if (userDoc.exists) {
                    // Si el usuario ya existe, redirige a la sesión abierta
                    // const existingUser = userDoc.data();
                    this.$router.push(`/${usuario.userName}`);
                    return;
                }

                let redirectHost = this.redirectHost || 'no-redirect';

                let usuario = {
                    uid,
                    redirectHost: redirectHost,
                    userName: this.$route.query.userName,
                    email: this.$route.query.email,
                    fechaInicioCuenta: new Date(),
                    fechaInitPrueba: new Date(),
                    app: this.app,
                    administrador: true,
                    logo: '',
                    bolsaDeTrabajo: false,
                    activa: this.activEstat,
                    nivelPlan: 'prueba',
                    permisos: this.itemApp[0].permisosPrueba,
                    designColors: {
                    primero: '#8E24AA',
                    segundo: '#757575',
                    tercero: '',
                    cuarto: ''
                    },
                    modoSubscripcion: this.modoSub,
                    suscripcionID: this.suscripcionID
                }
                
                let userName = {
                    userName: this.$route.query.userName,
                    uid,
                    email: this.$route.query.email,
                }
                
                await db.collection('userNames').doc(this.$route.query.userName.toLowerCase()).set(userName);
                
                await db.collection('usuarios').doc(usuario.uid).set(usuario);       
                
                this.itemA = this.itemApp[0].secciones

                for (let index = 0; index < this.itemA.length; index++) {
                    const element = this.itemA[index];

                    // Establece la propiedad fechaStart a la fecha y hora actuales
                    element.fechaStart = new Date().toString();     

                    await db.collection('usuarios').doc(usuario.uid).collection('comprasAppExtra')
                            .doc(element.app)
                            .set(element);
                }

                if (this.redirectHost !== '') {
                    let enlid = uuidv4()

                    let elmenl = {
                        enlace: this.redirectHost,
                        enlid,
                        tipoEnlace: this.tipoEnlace,
                    }

                    await db.collection('usuarios').doc(usuario.uid).collection('enlacesnuvin')
                            .doc(enlid)
                            .set(elmenl);
                }

                await this.saveFromVtaLanding(userName)      

                await dbL.collection('subscriPP').delete();
                await dbL.collection('itemApp').delete()
                await dbL.collection('ctaRedirect').delete()
                this.codeLanding = '';
                
                
                await this.actualizarUsuarioHostState(usuario);
                await this.actualizarUsuario(usuario)
                this.mostrarExito(this.saludo)
                // La mutación se ha completado, ahora puedes navegar
                this.$router.push(`/${usuario.userName}`);
                
            } catch (error) {
                // Manejo de errores si la mutación falla
                console.error('Error al actualizar el estado del usuario:', error);
            }
            
        },
        async saveFromVtaLanding (userName) {
            try {
                if (this.codeLanding !== '') {
                this.objSubscrip.userName = userName.userName;

                await db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra')
                        .doc(this.appFromVtaDig).collection('pagos-suscripciones')
                        .doc(this.objSubscrip.idsuscri).set(this.objSubscrip);

                //cambiar estatusPlan a activo
                const appDocRef = db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra').doc(this.appFromVtaDig);

                // Obtén el documento actual
                const appDoc = await appDocRef.get();

                if (appDoc.exists) {
                    // Cambia el valor de estatusPlan en el documento
                    await appDocRef.update({ estatusPlan: 'activo' });
                }
                }
            } catch (error) {
                console.error('Error in saveFromVtaLanding:', error);
            }
        }
    },
  };
  </script>
  