
let edicionCatalogo = () => import( '../views/negoapp/edicionCatalogo.vue')
let edoVtasNego = () => import( '../views/negoapp/edoVtasNego.vue')

export default [    
    {
        path: '/:userName/edicion-catalogo',
        name: 'edicion-catalogo',
        component: edicionCatalogo,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/estado-ventas',
        name: 'estado-ventas',
        component: edoVtasNego,
        meta: {
            autenticado: true
        }
    },
]