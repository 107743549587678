<template>
    <v-layout justify-center align-center wrap>
        <v-flex xs12 sm8 v-if="dialogProgress == false">
            <v-card class="elevation=5 mt-5 py-3 custom-card">
                <v-layout>                    
                    <v-flex xs2 class="text-xs-left mr-3">
                        <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-group</v-icon>                
                    </v-flex>
                    <v-flex xs8 class="text-xs-left">
                        <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Colaboradores de {{app}}</p></h3>
                        <h3 class="hidden-sm-and-up"><p class="text-xs-center display-1 mt-3">Colaboradores de {{app}}</p></h3>
                    </v-flex>                    
                    <v-flex xs2 class="text-xs-right">
                        <v-dialog
                            v-model="dialogHelp"
                            width="500"
                        >
                            <template v-slot:activator="{ on }">
                            <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                            </template>

                            <v-card>
                            <v-card-title class="headline">
                                Cómo funciona esta sección...
                            </v-card-title>

                            <v-card-text>
                                En esta área se agregan los Colaboradores que interectuarán en tu Oficina-nuVin; así como la asignación de
                                las funciones donde podrán colaborar. <br>
                                <ul>
                                    <li>En el primer apartado "Aceptar Colaboradores" inicia con modalidad Desactivada, al dar click y cambiarla a Activado, se agrega 
                                        de manera inmediata un botón en tu área pública llamado "Postularse"; cualquier persona con Cuenta NuVit, al ir 
                                        a tu área pública podrá verlo y dar click para postularse e inmeditamente aparecerá en la tabla que está en la 
                                        parte inferior.
                                    </li>
                                    <li>
                                        Una vez que aparezca en la tabla, todavia no tiene funciones dentro de tu nuVin; da click en el botón Editar (ícono lápiz)
                                        y da las funciones que desees o desactívalas en cualquier momento.
                                    </li>
                                    <li>
                                        Se elimina el Colaborador definitivamente, dando click en Borrar (ícono-basurero).
                                    </li>
                                    <li>
                                        Recuerda Desactivar "Aceptar Colaboradores" cuando ya tengas a tus colaboradores esperados en la tabla, 
                                        para que el ícono "Postularse" desaparezca de tu área pública.
                                    </li>
                                    <li>
                                        En la parte superior izquierda aparece el número de Colaboradores que tienes autorizados y 
                                        disponibles dentro de la aplicación en la que te encuentras. Si requieres Colaboradores adicionales
                                        puedes solicitarlos al dar click en el botón 'adquirir colaboradores'.
                                    </li>
                                </ul> 
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-flex>            
                </v-layout>                
                <v-layout flat color="white">
                                <!-- área para agregar Colaborador       -->
                    <v-layout align-end>
                        <v-flex xs6 class="text-xs-center">
                            <span>Colaboradores autorizados: {{ numPermisosCols }}</span><br>
                            <span>Disponibles: {{ numPermisosCols - numeroOperando }}</span>
                            <div v-if="showDialog == true">
                                <planesSubs :app="app" @cerrar-dialogo="cerrarDialog"/>
                            </div>
                            <!-- //esto es para adquirir cols con paypal, se requiere que se tenga el paypalid, lo bloqueo por le momento para mandar la app con permisos incluidos -->
                            <div>
                                <v-btn small class="body-2 text-none secondary" @click="mostrarDialog">adquirir colaboradores</v-btn>
                            </div>

                            <!-- <a
                            href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4KL447118U846293LMTLLZNI"
                            target="_blank"
                            >
                            <button class="btn-paypal">Ir a PayPal</button>
                            </a> -->
                            <!-- <div id="paypal-button-container-P-4KL447118U846293LMTLLZNI"></div>
                            <script src="https://www.paypal.com/sdk/js?client-id=Afo_EmWavAcsLb-0i3fHouZoJmCN8qmSvhC6nmoeUDXfw2AMeSEB7PKWM0S_0w9C_lF1m-uVAkGhxPcw&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>
                            <script>
                            paypal.Buttons({
                                style: {
                                    shape: 'rect',
                                    color: 'gold',
                                    layout: 'vertical',
                                    label: 'subscribe'
                                },
                                createSubscription: function(data, actions) {
                                    return actions.subscription.create({
                                    /* Creates the subscription */
                                    plan_id: 'P-4KL447118U846293LMTLLZNI',
                                    quantity: 1 // The quantity of the product for a subscription
                                    });
                                },
                                onApprove: function(data, actions) {
                                    alert(data.subscriptionID); // You can add optional success message for the subscriber here
                                }
                            }).render('#paypal-button-container-P-4KL447118U846293LMTLLZNI'); // Renders the PayPal button
                            </script> -->
                        </v-flex>
                        <v-flex xs6 class="text-xs-center">
                            <span class="caption">Aceptar Colaboradores:</span>                        
                            <v-btn small round :style="{'background-color': paletaColores.segundo}" dark class="caption text-none" @click="activarAceptarColaboradores">
                                {{ usuarioHost.bolsaDeTrabajo ? 'desactivar' : 'activar' }}
                            </v-btn>
                            <v-tooltip v-model="show" top v-if="QCols >= numPermisosCols">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                    <v-icon>mdi-face-agent</v-icon>
                                    </v-btn>
                                </template>
                                <span class="caption">Has superado la cantidad de Colaboradores permitido por el plan, solicita uno adicional en: nubesdebits@gmail.com.</span>
                            </v-tooltip>          
                        </v-flex>
                        <v-flex>
                            <v-dialog v-model="dialogAddColaborador" max-width="500px">
                                <!-- <template v-slot:activator="{ on }">
                                    <v-btn color="primary" dark class="mb-2 caption" v-on="on" small>Agregar</v-btn>
                                </template> -->
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{formTitleCol}}</span>
                                    </v-card-title>

                                    <div>
                                        <v-card-title v-if="formTitleCol == 'Seleccionar Colaborador'">
                                            <span class="subheading">Busca su cuenta:</span>
                                        </v-card-title>
                                        <v-select v-if="formTitleCol == 'Seleccionar Colaborador'"
                                            v-model="editedItemCol.colaborador"
                                            :hint="`Escoge a quien será tu colaborador`"
                                            :items="colaboradores"
                                            item-text="userName"
                                            label="Seleccionar"
                                            persistent-hint
                                            return-object
                                            single-line>
                                        </v-select>
                                        <div v-for="item in appExtra" :key="item.switch">
                                            <v-layout row justify-center v-if="item.app == app">                                               
                                                <v-menu
                                                    bottom persistent
                                                    origin="center center"
                                                    transition="scale-transition"
                                                    >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn small class="elevation-4" v-on="on" @click="appExtraPicked(item)">
                                                            <v-icon small dark left>{{ item.icon }} 
                                                                || mdi-badge-account-horizontal-outline
                                                            </v-icon>
                                                            <span class="caption text-none font-weight-bold">
                                                                {{ item.app }}</span>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-tile
                                                        v-for="(item2, i) in item.accesosCols"
                                                        :key="i"
                                                        @click="setAuths(item2.auths, item2.etiqueta)"
                                                        >
                                                        <v-list-tile-title>{{ item2.etiqueta }}</v-list-tile-title>
                                                                
                                                        </v-list-tile>
                                                    </v-list>
                                                </v-menu>         
                                                <v-dialog v-model="dialogPermissions" persistent max-width="500">                                                  
                                                    <v-card >
                                                        <v-card-text v-if="auths == undefined">Por el momento, no hay opciones de autorización en este componente.</v-card-text>
                                                        <v-card-text v-else>Edita permisos para el colaborador <strong v-if="editedIndexCol >= 0">{{ colaboradores[editedIndexCol].userName }}</strong></v-card-text>
                                                        <v-container fluid v-for="(authItem, key) of (auths || {})" :key="key">
                                                            <v-switch v-model="authItem.value" :label="`${authItem.funcion}`"></v-switch>
                                                        </v-container>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="primary" flat @click="closeDialog">Cerrar</v-btn>
                                                            <v-btn v-if="auths != undefined" color="primary" flat @click="guardarAccesos">Guardar</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>                  
                                            </v-layout>           
                                        </div>
                                    </div>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" flat @click="close">Cerrar</v-btn>
                                    <!-- aqui van los botones para cada uno de los tipos de app para rigistrar colaborador, el que está es solo para restaurant -->
                                    <!-- <div>
                                        <v-btn v-if="formTitleCol == 'Seleccionar Colaborador'" color="primary" :depressed="$v.editedItemCol.colaborador.$invalid" :disabled="$v.editedItemCol.colaborador.$invalid" flat @click="registrarColaborador">Guardar</v-btn>
                                        <v-btn v-if="formTitleCol == 'Secciones de' + ' ' + `${this.usuarioHost.userName}`" color="primary" flat @click="registrarColaborador">Guardar</v-btn>
                                    </div> -->
                                </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-flex>
                    </v-layout>
                </v-layout>
                <v-data-table
                    :headers="headersColaboradores"
                    :items="colaboradores"
                    class="elevation-1"
                    >
                    <template v-slot:items="props">
                        <td>{{ props.item.userName}}</td>
                        <td class="justify-center align-center layout">
                            <v-layout>
                                <v-flex xs6>
                                    <v-icon
                                        class="mt-2 pt-1" color="green"
                                        @click="editItemCol(props.item)" small
                                        :disabled="shouldDisableEdit(props.item)"
                                        >
                                        edit
                                    </v-icon>
                                </v-flex>
                                <v-flex xs6>
                                    <estasSeguro>
                                        <v-icon
                                            color="red"
                                            slot="nombrebtn"
                                            small                                       
                                            >
                                            delete
                                        </v-icon>
                                        <div slot="elemento">
                                            <span>a este Colaborador?</span>
                                        </div>
                                        <div slot="btnaceptar">
                                            <v-btn flat @click="deleteItemCol(props.item)">Ok</v-btn>
                                        </div>
                                    </estasSeguro>
                                </v-flex>
                            </v-layout>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning">
                            No hay Colaboradores por el momento.
                        </v-alert>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import estasSeguro from '@/components/estasSeguro.vue'
import planesSubs from '@/views/planesSubs.vue'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapMutations, mapActions } from 'vuex'
import { db, auth } from '@/firebase'
import { doc, getDoc, getDocs, deleteDoc, collection, updateDoc, WriteBatch, arrayUnion, query, where} from 'firebase/firestore'; 
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')


export default {
    components: { estasSeguro, planesSubs },
    props: {
        isExtraAppsUserEmpty: Boolean,
        app: String // Declarar la prop "app" como String
    },
    data() {
        return {
            sumaUnidadesNCD: 0,
            showDialog: false,
            dialogProgress: true,
            auths: [],
            show: false,
            select: '',
            dialogHelp: false,
            dialogAddColaborador: false,
            dialogPermissions: false,
            headersColaboradores: [
                { text: 'Colaborador', value: 'userName' },
                { text: 'Acciones', value: '', sortable: false }
            ],
            nuvitGratis: 'price_1IWW9wGLlS3xAdXuxEwvjESu',
            nuvit1: 'price_1IUbLQGLlS3xAdXuPAvgzhp2',
            nuvit2: 'price_1IUbMVGLlS3xAdXupUDgRNhi',
            nuvit3: 'price_1IUbNCGLlS3xAdXubxbTjcfF',
            colaborador: [],
            colaboradores: [],
            planes: [],
            planPorPrecio: [],
            appExtra: [],
            appExtraPickedSeccion: '',
            appExtraPickedAuths: [],
            editedIndexCol: -1,
            editedItemCol: [],
            paypalIDs: { 
                IDdelproducto: 'PROD-8WG133376V517792L', 
                IDsdeplanes: [
                    {
                    nombredelplan: 'Colaborador', 
                    incluye: 'operación virtual para 2 colaboradores, productos en menú ilimitados, clientes ilimitados.',
                    periodosdeplan: [
                        // {IDdelplan: 'P-1J864548A6702144WMQO4VYI', periodo: 'mensual', precio: 65.90},
                        {IDdelplan: 'P-5FS882367T7913826MYPMLAY', periodo: 'anual', precio: 4.5, precioSubs: 0.7, statusCli: 'nuevo-cliente'},
                        {IDdelplan: 'P-17M915425H6402115MYO3EMI', periodo: 'anual', precio: 0.7, statusCli: 'ya-es-cliente'},                        
                    ],                                                                                                             
                    }
                ] 
            },
            // defaultItemCol: {          
            //     switchCols: false,
            //     switchCotiz: false,
            //     switchServcs: false,
            //     switchComandas: false,
            //     switchCalendario: false,
            //     switchHist: false,
            //     switchAgProd: false,
            //     switchCocina: false,
            //     switchConfig: false,
            //     switchCtrlAutoPed: false,
            //     switchClientes: false,
            //     switchBorrar: false                    
            // },
        }
    },
    validations: {
        editedItemCol: {
            colaborador: {
                required,
            }   
        }
    },
    computed: {
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      ...mapState('usuarioHost', ['perfilAuth']),
      shouldDisableEdit() {
        return (colaborador) => {
            if (this.numPermisosCols - this.numeroOperando <= 0) {
            const tieneAcceso = colaborador.estatusAccesos.some(
                acceso => acceso.app === this.app && acceso.value === true
            );
            return !tieneAcceso;
            }
            return false;
        };
      },
      numPermisosCols() {
        const appExtraObj = this.appExtra.find(app => app.app === this.app);
        return appExtraObj ? appExtraObj.permisos.colaboradores : 0;
      },
      numeroOperando() {
        return this.colaboradores.reduce((count, colaborador) => {
            const tieneAcceso = colaborador.estatusAccesos.some(
            acceso => acceso.app === this.app && acceso.value === true
            );
            return tieneAcceso ? count + 1 : count;
        }, 0);
      },
      paletaColores() {
        if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
            return this.usuarioHost.designColors;
        } else {
            const colores = ['#E040FB',];
            const colores2 = ['#616161',];
            const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
            const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
            return {
            primero: colorAleatorio,
            segundo: colorAleatorio2,
            tercero: colorAleatorio,
            cuarto: colorAleatorio
            };
        }
      },
      QCols() {
          var x = this.colaboradores.length
          return x
      },
      formTitleCol () {
        return this.editedIndexCol === -1 ? 'Seleccionar Colaborador' : 'Asigna permisos para' + ' ' + `${this.colaboradores[this.editedIndexCol].userName}`
      },
      estadoBolsaTrabajo () {
          return this.usuarioHost.bolsaDeTrabajo
      },
      colsNumPermiso() {
        return this.usuarioHost.permisos.colaboradores
      }
    },
    watch: {
      dialogAddColaborador (val) {
        val || this.close()
      },
    },
    created () {
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()   
        this.consultarAppEXtra()
        this.consultarPlanes()
        this.consultarSwitchColaboradores()
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        async calcSumUdsNCD() {
            await dbL.collection('NumPermCols').delete()
            await dbL.collection('NumPermCols').set([])
            // Calcular la suma de unidadesNCD aquí
            await dbL.collection('NumUdsNCD').delete()
            await dbL.collection('NumUdsNCD').set([])
            const appData = this.appExtra.find(appData => appData.app === this.app);
            
            this.sumaUnidadesNCD = appData.accesosCols.reduce((total, acceso) => {
                if (typeof acceso.unidadesNCD === 'number') {
                return total + acceso.unidadesNCD;
                } else {
                return total; // O puedes manejar el valor no numérico de otra manera
                }
            }, 0);        
            await dbL.collection('NumUdsNCD').add({numUdsNCD: this.sumaUnidadesNCD,})
            await dbL.collection('NumPermCols').add({numPermisosCols: this.numPermisosCols,})
            this.showDialog = true; // Muestra el diálogo cuando se hace clic en el botón            
        },
        cerrarDialog() {
            this.showDialog = false; // Cierra el diálogo cuando se reciba el evento desde el hijo
        },
        async mostrarDialog() {
            
            // const extraApp = this.appExtra.find(extraApp => extraApp.app === this.app);
            await dbL.collection('paypalIDs').set([]);
            await dbL.collection('paypalIDs').add(this.paypalIDs);
            this.calcSumUdsNCD()   
        },
        setAuths(auths, etiq) {
            // Encuentra el objeto en this.colaboradores correspondiente al índice editedIndexCol
            const obj = this.colaboradores[this.editedIndexCol];

            if (obj && Array.isArray(obj.estatusAccesos) && obj.estatusAccesos.length > 0) {
                const filteredArray = obj.estatusAccesos.filter(item => item.etiqueta === etiq);
                if (filteredArray.length > 0) {
                // Realiza la operación aquí si la propiedad "item" está presente y el array está lleno.
                this.auths = filteredArray;
                } else {
                this.auths = auths;
                }
            } else {
                this.auths = auths;
            }

            this.dialogPermissions = true;
        },
        async closeDialog() {
            this.dialogPermissions = false;
            this.auths = undefined; // Establece el valor de this.auths en undefined al cerrar el diálogo
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
                if (this.isExtraAppsUserEmpty == true) {
                    this.$emit('llamar-consultar-extraapps');
                }
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            // await dbL.collection('fromSameHost').delete()
            // this.sameHost = false
            // this.sameHostName = ''
            this.consultarColaboradores()
        },
        async appExtraPicked(item) {
            this.appExtraPickedSeccion = Object.assign({},item)
            this.appExtraPickedAuths = []

            if (this.appExtraPickedSeccion.accesosCols.some(accp => accp.auths)) {
                this.appExtraPickedAuths = await this.appExtraPickedSeccion.accesosCols.filter(accp => accp.auths)    
            }    
            
            let indexInteracc = this.appExtraPickedAuths.findIndex(accp => accp.switch == 'switchInteracc')
            if (this.appExtraPickedAuths.some(accp => accp.switch == 'switchInteracc')) {
                this.appExtraPickedAuths.splice(indexInteracc, 1)
            }
            this.appExtraPickedDepur()
        },
        async appExtraPickedDepur() {
            
            for (let indexA = 0; indexA < this.appExtraPickedAuths.length; indexA++) {
                const elementA = this.appExtraPickedAuths[indexA];
                
                for (let indexB = 0; indexB < this.editedItemCol.length; indexB++) {
                    const elementB = this.editedItemCol[indexB];
                    
                    if (elementA.switch == elementB.switch) {
                        this.appExtraPickedAuths.splice(indexA, 1, elementB)
                    }                    
                }                
            }
        },
        async guardarAccesos() {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            if (this.editedIndexCol > -1) {
                this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });

                try {
                    const userNameDocRef = doc(db, 'userNames', userNameParametro);
                    const userNameDoc = await getDoc(userNameDocRef);

                    if (userNameDoc.exists()) {
                        const userName = userNameDoc.data();

                        // Actualizar datos en Firestore
                        const colaboradorDocRef = doc(db, 'usuarios', userName.uid, 'colaboradores', this.colaboradores[this.editedIndexCol].colid);
                        await updateDoc(colaboradorDocRef, {
                            estatusAccesos: arrayUnion(...this.auths),
                        });

                        this.close();
                        this.mostrarExito('Se cargó la información exitosamente');
                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error cargando la información');
                } finally {
                    this.dialogAddColaborador = false;
                    this.ocultarOcupado();
                }
            } else {
                this.mostrarOcupado({ titulo: 'Registrando Colaborador', mensaje: 'Cargando datos...' });
                const userNameDocRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameDocRef);

              if (userNameDoc.exists()) {
                const userName = userNameDoc.data();

                const batch = WriteBatch(db);
                const colid = uuidv4();

                const colr = {
                    colid,
                    userNameCol: this.editedItemCol.colaborador.userName,
                    userNameAdmin: this.usuarioHost.userName,
                    SistemaApp: this.usuarioHost.app,
                    switchBorrar: this.editedItemCol.switchBorrar,
                    switchCols: this.editedItemCol.switchCols,
                    switchConfig: this.editedItemCol.switchConfig,
                    switchCotiz: this.editedItemCol.switchCotiz,
                    switchHist: this.editedItemCol.switchHist,
                    switchServcs: this.editedItemCol.switchServcs,
                    switchCalendario: this.editedItemCol.switchCalendario,
                    switchClientes: this.editedItemCol.switchClientes
                };

                const colaboradorDocRef = doc(db, 'usuarios', userName.uid, 'colaboradores', colr.colid);
                batch.set(colaboradorDocRef, colr);

                try {
                    await batch.commit();
                    this.colaboradores.push(colr);
                    this.mostrarExito('Se registró el Colaborador exitosamente');
                } catch (error) {
                    this.mostrarError('Ocurrió un error registrando al Colaborador');
                } finally {
                    this.ocultarOcupado();
                    this.close();
                }
              }
            }

            this.auths = undefined; // Establece el valor de this.auths en undefined al cerrar el diálogo
        },
        async consultarColaboradores() {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            this.mostrarOcupado({ titulo: 'Cargando Colaboradores', mensaje: 'Obteniendo datos...' });

            try {
                const userNameDocRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameDocRef);

                if (userNameDoc.exists()) {
                    const userName = userNameDoc.data();

                    const colaboradoresRef = collection(db, 'usuarios', userName.uid, 'colaboradores');
                    const resultado = await getDocs(colaboradoresRef);

                    resultado.forEach((doc) => {
                        let colsdb = doc.data();

                        // Verificar si el userName es diferente al del usuario actual
                        if (colsdb.userName !== this.usuarioHost.userName) {
                            // No agregar a this.colaboradores si el userName es igual al del usuario actual
                            delete colsdb.uid;
                            delete colsdb.uidHost;
                            this.colaboradores.push(colsdb);
                        }
                    });
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando los Colaboradores.');
            } finally {
                this.ocultarOcupado();
            }
        },
        async consultarPlanes() {
            this.mostrarOcupado({ titulo: 'Cargando Planes', mensaje: 'Obteniendo datos...' });

            try {
                const productosRef = collection(db, 'products');
                const resultado = await getDocs(productosRef);

                resultado.forEach((doc) => {
                    let plprod = doc.data();
                    this.planes.push(plprod);
                });

                this.mostrarExito('Planes consultados exitosamente.');
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando los Planes.');
            } finally {
                this.ocultarOcupado();
            }
        },
        async consultarSwitchColaboradores() {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' });

            try {
                // Obtener el documento de usuario
                const userNameRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar los colaboradores
                    const colaboradorRef = collection(db, 'usuarios', userName.uid, 'colaboradores');
                    const q = query(colaboradorRef, where('userName', '==', this.usuario.userName), where('switchCols', '==', true));
                    const colaboradorDocs = await getDocs(q);

                    colaboradorDocs.forEach(doc => {
                        let colab = doc.data();
                        delete colab.uid;
                        delete colab.uidHost;
                        this.colaborador.push(colab);
                    });

                    // Verificar si la lista de colaboradores está vacía y redirigir si es necesario
                    if (this.colaborador.length === 0 && userName.uid !== auth.currentUser.uid) {
                        this.$router.push({ name: '404' });
                    }
                }
            } catch (error) {
                this.$router.push({ name: '404' });
            } finally {
                this.ocultarOcupado();
            }
            this.dialogProgress = false;
        },
        editItemCol (item) {
            this.dialogAddColaborador = true
            this.editedIndexCol = this.colaboradores.indexOf(item)
            this.editedItemCol = Object.assign([], item.estatusAccesos)
        },
        async deleteItemCol(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            const index = this.colaboradores.indexOf(item);
            this.colaboradores.splice(index, 1);

            this.mostrarOcupado({ titulo: 'Eliminando Colaborador', mensaje: 'Borrando datos...' });

            try {
                // Obtener el documento del usuario
                const userNameRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Eliminar el documento del colaborador
                    const colabRef = doc(db, 'usuarios', userName.uid, 'colaboradores', item.colid);
                    await deleteDoc(colabRef);

                    this.mostrarExito('Se eliminó el Colaborador exitosamente');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error borrando el colaborador');
            } finally {
                this.ocultarOcupado();
            }

            this.close();
        },
        close () {
            this.dialogAddColaborador = false
            setTimeout(() => {
                this.editedItemCol = Object.assign({}, this.defaultItemCol) //colsdb?
                this.editedIndexCol = -1
            }, 300)
        },
        async activarAceptarColaboradores() {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            this.usuarioHost.bolsaDeTrabajo = !this.usuarioHost.bolsaDeTrabajo;

            this.mostrarOcupado({ titulo: 'Actualizando Configuración', mensaje: 'Cambiando estado...' });

            try {
                // Obtener el documento del usuario
                const userNameRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Actualizar el campo bolsaDeTrabajo en el documento del usuario
                    const usuarioRef = doc(db, 'usuarios', userName.uid);
                    await updateDoc(usuarioRef, {
                        bolsaDeTrabajo: this.usuarioHost.bolsaDeTrabajo
                    });

                    this.mostrarExito('La activación de Ingreso de Colaboradores sufrió cambios');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error activando el Ingreso de Colaboradores.');
            } finally {
                this.ocultarOcupado();
            }
        },
        async consultarAppEXtra() {
            this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' });

            let userNameParametro = this.$route.params.userName.toLowerCase();
            const userNameRef = doc(db, 'userNames', userNameParametro);
            
            try {
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userHost = userNameDoc.data();

                    const comprasRef = collection(db, 'usuarios', userHost.uid, 'comprasAppExtra');
                    const resultado = await getDocs(comprasRef);

                    resultado.docs.forEach(doc => {
                        let appex = doc.data();
                        this.appExtra.push(appex);
                    });
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando la información.');
            } finally {
                this.ocultarOcupado();
            }
        }
    }
    
}
</script>

<style>
    .custom-card {
        border-radius: 30px;
    }
</style>