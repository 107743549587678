
let oficinaSuperadmin = () => import( '../views/superadmin/oficinaSuperadmin.vue')
let ImagenesFiltro = () => import( '../views/superadmin/ImagenesFiltro.vue')
let UsuariosEstatus = () => import( '../views/superadmin/UsuariosEstatus.vue')
let cronologiaDePagos = () => import( '../views/superadmin/cronologiaDePagos.vue')
let edicionClientesNuVit = () => import( '../views/superadmin/edicionClientesNuVit.vue')


export default [
    {
      path: '/:userName/Sadmin',
      name: 'oficinaSuperadmin',
      component: oficinaSuperadmin,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/Sadmin/imgs-filtro',
      name: 'imgs-filtro',
      component: ImagenesFiltro,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/Sadmin/usuarios-estatus',
      name: 'usuarios-estatus',
      component: UsuariosEstatus,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/Sadmin/cronologiadepagos',
      name: 'cronologiadepagos',
      component: cronologiaDePagos,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/Sadmin/clientes',
      name: 'clientes-nuVin',
      component: edicionClientesNuVit,
      meta: {
        autenticado: true
      }
    },
]