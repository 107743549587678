<template>
  <v-layout>
    <v-flex xs12>
      <v-card>
        <v-container grid-list-xs >
            <!-- aquidebe estar el layout de servicios de eventos estandar con un formato de cotizacion mas sencillo -->
            <!-- Este layout contiene la Forma de Bodas, debe ser pesonalizado -->
          <v-layout row wrap>
            <v-flex xs4 d-flex>
              <v-card flat tile class="d-flex">
                <template>
                    <v-layout row justify-center>
                        <v-dialog v-model="dialogBoda" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" fab large dark v-on="on" flat class="caption">
                                <v-icon class="pb-5">mdi-ring</v-icon><br>Boda
                                </v-btn>
                            </template>
                            <v-card class="text-xs-center">
                                <v-container fluid>
                                    <span>Gracias por contactar al equipo de Claudia García Bodas y Eventos Exlusivos.</span><br>
                                    <span>Por favor completa el formulario debajo, para poder asistirte:</span><br>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.nombre" box name="input-7-4" label="Novios" value="Nombres y Apellidos" ></v-textarea>
                                    <v-textarea counter="50" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.direccion" box name="input-7-4" label="Dirección" value="Calle o fraccionamiento, #"></v-textarea>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.ciudad" label="Ciudad" box></v-text-field>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.estadopais" label="Estado/País." box></v-text-field>
                                    <v-text-field counter="15" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.telefono" label="Teléfono" box></v-text-field>
                                    <v-text-field counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.email" label="Correo electrónico" box></v-text-field>
                                    <span>Tipo de Boda:</span>
                                    <v-radio-group autofocus :error-messages="erroresCamposCliente" v-model="clientedb.radiosBodaTipo" :mandatory="false">
                                        <v-radio color="principal" label="Boda Civil" value="Boda Civil"></v-radio>
                                        <v-radio color="principal" label="Boda Simbólica" value="Boda Simbólica"></v-radio>
                                        <v-radio color="principal" label="Boda Maya" value="Boda Maya"></v-radio>
                                    </v-radio-group>
                                    <span>Lugar de tu Boda</span>
                                    <v-radio-group autofocus :error-messages="erroresCamposCliente" v-model="clientedb.lugar" :mandatory="false">
                                        <v-radio color="principal" label="Playa del Carmen" value="Playa del Carmen"></v-radio>
                                        <v-radio color="principal" label="Tulum" value="Tulum"></v-radio>
                                        <v-radio color="principal" label="Riviera Maya" value="Riviera Maya"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="clientedb.lugar == 'Otro'" v-model="clientedb.otroLugarBoda" label="Lugar" placeholder="Especifica lugar de su agrado" box></v-text-field>
                                    <span>¿Te interesa algún otro evento después de tu ceremonia?</span>
                                    <v-radio-group autofocus :error-messages="erroresCamposCliente" v-model="clientedb.radiosBodaDespEv" :mandatory="false">
                                        <v-radio color="principal" label="Recepción" value="Recepción"></v-radio>
                                        <v-radio color="principal" label="Coctel" value="Coctel"></v-radio>
                                        <v-radio color="principal" label="Otro" value="Otro"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="clientedb.radiosBodaDespEv == 'Otro'" counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.despotro" label="Evento" placeholder="Especifica evento" box></v-text-field>
                                    <v-menu 
                                        ref="menuBoda"
                                        v-model="menuBoda"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="clientedb.date"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="clientedb.date"
                                            label="Selecc. fecha"
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="clientedb.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="primary" @click="menuBoda = false">Cancel</v-btn>
                                        <v-btn flat color="primary" @click="$refs.menuBoda.save(clientedb.date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>                               
                                    <v-text-field counter="10" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.numinvitados" label="Invitados" placeholder="Especifica # de invitados" box></v-text-field>
                                    <v-text-field counter="10" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.presupuesto" label="Presupuesto" placeholder="Proporciona un estimado" box></v-text-field>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.comentarios" box name="input-7-4" label="Comentarios" value=""></v-textarea>
                                </v-container>          
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="principal" flat @click="dialogBoda = false" class="caption">Cerrar</v-btn>
                                <v-btn :depressed="$v.clientedb.$invalid" :disabled="$v.clientedb.$invalid" color="principal" flat @click="solicitarInfoBoda" class="caption">Solicitar información</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-layout>
                </template>  
              </v-card>     
            </v-flex>
            <v-flex xs4 d-flex>
                <v-card flat tile class="d-flex">
                    <template>
                        <v-layout row justify-center>
                            <v-dialog v-model="dialogCenaRom" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" fab large dark v-on="on" flat class="caption">
                                <v-icon class="pb-5">mdi-tag-heart</v-icon><br>Cena<br>Romántica
                                </v-btn>
                            </template>
                            <v-card class="text-xs-center">
                                <v-container fluid>
                                    <span>Gracias por contactar al equipo de Claudia García Bodas y Eventos Exlusivos.</span><br>
                                    <span>Por favor completa el formulario debajo, para poder asistirte:</span><br>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.nombre" box name="input-7-4" label="Novios" value="Nombres y Apellidos" ></v-textarea>
                                    <v-textarea counter="50" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.direccion" box name="input-7-4" label="Dirección" value="Calle o fraccionamiento, #"></v-textarea>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.ciudad" label="Ciudad" box></v-text-field>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.estadopais" label="Estado/País." box></v-text-field>
                                    <v-text-field counter="15" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.telefono" label="Teléfono" box></v-text-field>
                                    <v-text-field counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.email" label="Correo electrónico" box></v-text-field>
                                    <v-menu 
                                        ref="menuCena"
                                        v-model="menuCena"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="clientedb.date"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="clientedb.date"
                                            label="Selecc. fecha"
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="clientedb.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="primary" @click="menuCena = false">Cancel</v-btn>
                                        <v-btn flat color="primary" @click="$refs.menuCena.save(clientedb.date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <v-text-field counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.lugar" label="Invitados" placeholder="Especifica # de invitados" box></v-text-field>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.cenadetalles" box name="input-7-4" label="Detalles" value="Por favor, especifique detalles que desee incluir en su cena:"></v-textarea>
                                    <v-text-field counter="15" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.presupuesto" label="Presupuesto" placeholder="Proporciona un estimado" box></v-text-field>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.comentarios" box name="input-7-4" label="Comentarios" value=""></v-textarea>
                                </v-container>          
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="principal" flat @click="dialogCenaRom = false" class="caption">Cerrar</v-btn>
                                <v-btn :depressed="$v.clientedb.$invalid" :disabled="$v.clientedb.$invalid" color="principal" flat @click="solicitarInfoCena" class="caption">Solicitar información</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-layout>
                    </template>  
                </v-card>
            </v-flex>
            <v-flex xs4 d-flex>
                <v-card flat tile class="d-flex">
                    <template>
                        <v-layout row justify-center>
                            <v-dialog v-model="dialogPedidaMano" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" fab large dark v-on="on" flat class="caption">
                                <v-icon class="pb-5">mdi-hand-heart</v-icon><br>Pedida <br> de Mano
                                </v-btn>
                            </template>
                                <v-card class="text-xs-center">
                                    <v-container fluid>
                                        <span>Gracias por contactar al equipo de Claudia García Bodas y Eventos Exlusivos.</span><br>
                                        <span>Por favor completa el formulario debajo, para poder asistirte:</span><br>
                                        <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.nombre" box name="input-7-4" label="Novios" value="Nombres y Apellidos" ></v-textarea>
                                        <v-textarea counter="50" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.direccion" box name="input-7-4" label="Dirección" value="Calle o fraccionamiento, #"></v-textarea>
                                        <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.ciudad" label="Ciudad" box></v-text-field>
                                        <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.estadopais" label="Estado/País." box></v-text-field>
                                        <v-text-field counter="15" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.telefono" label="Teléfono" box></v-text-field>
                                        <v-text-field counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.email" label="Correo electrónico" box></v-text-field>
                                        <v-menu 
                                            ref="menuPedida"
                                            v-model="menuPedida"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="clientedb.date"
                                            lazy
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="clientedb.date"
                                                label="Selecc. fecha"
                                                prepend-icon="event"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="clientedb.date" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn flat color="primary" @click="menuPedida = false">Cancel</v-btn>
                                            <v-btn flat color="primary" @click="$refs.menuPedida.save(clientedb.date)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                        <v-text-field counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.lugar" label="Invitados" placeholder="Especifica # de invitados" box></v-text-field>
                                        <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.cenadetalles" box name="input-7-4" label="Detalles" value="Por favor, especifique detalles que desee incluir en su cena:"></v-textarea>
                                        <v-text-field counter="15" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.presupuesto" label="Presupuesto" placeholder="Proporciona un estimado" box></v-text-field>
                                        <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.comentarios" box name="input-7-4" label="Comentarios" value=""></v-textarea>
                                    </v-container>          
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="principal" flat @click="dialogPedidaMano = false" class="caption">Cerrar</v-btn>
                                    <v-btn color="principal" :depressed="$v.clientedb.$invalid" :disabled="$v.clientedb.$invalid" flat @click="solicitarInfoPedida" class="caption">Solicitar información</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-layout>
                    </template>  
                </v-card>
            </v-flex>
            <v-flex xs4 d-flex>
                <v-card flat tile class="d-flex">
                    <template>
                        <v-layout row justify-center>
                            <v-dialog v-model="dialogRenov" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" fab large dark v-on="on" flat class="caption">
                                <v-icon class="pb-5">mdi-battery-heart</v-icon><br>Renovación <br> de Votos
                                </v-btn>
                            </template>
                            <v-card class="text-xs-center">
                                <v-container fluid>
                                    <span>Gracias por contactar al equipo de Claudia García Bodas y Eventos Exlusivos.</span><br>
                                    <span>Por favor completa el formulario debajo, para poder asistirte:</span><br>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.nombre" box name="input-7-4" label="Novios" value="Nombres y Apellidos" ></v-textarea>
                                    <v-textarea counter="50" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.direccion" box name="input-7-4" label="Dirección" value="Calle o fraccionamiento, #"></v-textarea>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.ciudad" label="Ciudad" box></v-text-field>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.estadopais" label="Estado/País." box></v-text-field>
                                    <v-text-field counter="15" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.telefono" label="Teléfono" box></v-text-field>
                                    <v-text-field counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.email" label="Correo electrónico" box></v-text-field>
                                    <v-menu 
                                        ref="menuRenov"
                                        v-model="menuRenov"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="clientedb.date"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="clientedb.date"
                                            label="Selecc. fecha"
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="clientedb.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="primary" @click="menuRenov = false">Cancel</v-btn>
                                        <v-btn flat color="primary" @click="$refs.menuRenov.save(clientedb.date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <span>Lugar</span>
                                    <v-radio-group autofocus :error-messages="erroresCamposCliente" v-model="clientedb.lugar" :mandatory="false">
                                        <v-radio color="principal" label="Playa" value="Playa"></v-radio>
                                        <v-radio color="principal" label="Cenote" value="Cenote"></v-radio>
                                        <v-radio color="principal" label="Otro" value="Otro"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="clientedb.lugar == 'Otro'" v-model="clientedb.despotro" label="Lugar" placeholder="Especifica lugar de su agrado" box></v-text-field>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.comentarios" box name="input-7-4" label="Comentarios" value=""></v-textarea>
                                </v-container>          
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="principal" flat @click="dialogRenov = false" class="caption">Cerrar</v-btn>
                                <v-btn color="principal" :depressed="$v.clientedb.$invalid" :disabled="$v.clientedb.$invalid" flat @click="solicitarInfoRenov" class="caption">Solicitar información</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-layout>
                    </template>  
                </v-card>
            </v-flex>
            <v-flex xs4 d-flex>
                <v-card flat tile class="d-flex">
                    <template>
                        <v-layout row justify-center>
                            <v-dialog v-model="dialogEscapada" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" fab large dark v-on="on" flat class="caption">
                                <v-icon class="pb-5">mdi-calendar-heart</v-icon><br>Escapada <br> Romántica
                                </v-btn>
                            </template>
                            <v-card class="text-xs-center">
                                <v-container fluid>
                                    <span>Gracias por contactar al equipo de Claudia García Bodas y Eventos Exlusivos.</span><br>
                                    <span>Por favor completa el formulario debajo, para poder asistirte:</span><br>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.nombre" box name="input-7-4" label="Novios" value="Nombres y Apellidos" ></v-textarea>
                                    <v-textarea counter="50" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.direccion" box name="input-7-4" label="Dirección" value="Calle o fraccionamiento, #"></v-textarea>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.ciudad" label="Ciudad" box></v-text-field>
                                    <v-text-field counter="20" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.estadopais" label="Estado/País." box></v-text-field>
                                    <v-text-field counter="15" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.telefono" label="Teléfono" box></v-text-field>
                                    <v-text-field counter="30" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.email" label="Correo electrónico" box></v-text-field>
                                    <v-menu 
                                        ref="menuEscap"
                                        v-model="menuEscap"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="clientedb.date"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="clientedb.date"
                                            label="Selecc. fecha"
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="clientedb.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="primary" @click="menuEscap = false">Cancel</v-btn>
                                        <v-btn flat color="primary" @click="$refs.menuEscap.save(clientedb.date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <span>Tipo de Ceremonia:</span>
                                    <v-radio-group autofocus :error-messages="erroresCamposCliente" v-model="clientedb.radiosBodaTipo" :mandatory="false">
                                        <v-radio color="principal" label="Ceremonia Civil" value="Ceremonia Civil"></v-radio>
                                        <v-radio color="principal" label="Ceremonia Simbólica" value="Ceremonia Simbólica"></v-radio>
                                        <v-radio color="principal" label="Ceremonia Maya" value="Ceremonia Maya"></v-radio>
                                    </v-radio-group>
                                    <span>Lugar</span>
                                    <v-radio-group autofocus :error-messages="erroresCamposCliente" v-model="clientedb.lugar" :mandatory="false">
                                        <v-radio color="principal" label="Playa" value="Playa"></v-radio>
                                        <v-radio color="principal" label="Cenote" value="Cenote"></v-radio>
                                        <v-radio color="principal" label="Otro" value="Otro"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="clientedb.lugar == 'Otro'" v-model="clientedb.despotro" label="Lugar" placeholder="Especifique lugar de su agrado" box></v-text-field>
                                    <v-textarea counter="80" autofocus :error-messages="erroresCamposCliente" v-model="clientedb.comentarios" box name="input-7-4" label="Comentarios" value=""></v-textarea>
                                </v-container>          
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="principal" flat @click="dialogEscapada = false" class="caption">Cerrar</v-btn>
                                <v-btn color="principal" :depressed="$v.clientedb.$invalid" :disabled="$v.clientedb.$invalid" flat @click="solicitarInfoEscapada" class="caption">Solicitar información</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-layout>
                    </template>  
                </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import { db, storage } from '@/firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

export default {
    
    data() {
        return {
            menuBoda: false,
            menuCena: false,
            menuPedida: false,
            menuRenov: false,
            menuEscap: false,
            dialog: false,
            dialogBoda: false,
            dialogCenaRom: false,
            dialogPedidaMano: false,
            dialogRenov: false,
            dialogEscapada: false,
            clientedb: {
                nombre: 'Nombres y Apellidos',
                direccion: 'Calle o fraccionamiento, #',
                ciudad: '',
                estadopais: '',
                telefono: '',
                email: '',
                radiosBodaTipo: '',
                radiosBodaDespEv: '',
                despotro: '',
                numinvitados: '',
                presupuesto: '',                
                date: new Date().toISOString().substr(0, 10),
                lugar: '',
                otroLugarBoda: '',
                cenadetalles: '',
                comentarios: '',
            },
            defaultItem: {
                nombre: '',
                direccion: '',
                ciudad: '',
                estadopais: '',
                telefono: '',
                email: '',
                radiosBodaTipo: '',
                radiosBodaDespEv: '',
                despotro: '',
                numinvitados: '',
                presupuesto: '',                
                date: new Date().toISOString().substr(0, 10),
                lugar: '',
                otroLugarBoda: '',
                cenadetalles: '',
                comentarios: '',
            },
        }
    },
    validations: {
        clientedb: {
            nombre: {
                required,
                maxLength: maxLength(80),
            },
            direccion: {
                required,
                maxLength: maxLength(80),
            },
            ciudad: {
                required,
                maxLength: maxLength(80),
            },
            estadopais: {
                required,
                maxLength: maxLength(80),
            },
            telefono: {
                required,
                maxLength: maxLength(80),
            },
            email: {
                required,
                maxLength: maxLength(80),
            },
            radiosBodaTipo: {
                // required,
            },
            despotro: {
                // required,
                maxLength: maxLength(80),
            },
            numinvitados: {
                // required,
                maxLength: maxLength(80),
            },
            presupuesto: {
                // required,
                maxLength: maxLength(80),
            },
            lugar: {
                // required,
                maxLength: maxLength(80),
            },
            cenadetalles: {
                // required,
                maxLength: maxLength(80),
            },
            comentarios: {
                // required,
                maxLength: maxLength(80),
            },
        }
    },
    created() {
        this.consultarUsuarioHost() 
    },
    computed:{
        erroresCamposCliente() {
            let errores = []
            if (!this.$v.clientedb.$dirty) { return errores }  
            if (!this.$v.clientedb.required) { errores.push('Ingresa la información solicitada.') }      
            if (!this.$v.clientedb.maxLength) { errores.push('Ingresa máximo los caracteres que se señalan debajo del campo.') }
            return errores
        },
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        async consultarUsuarioHost() {
        let userNameParametro = this.$route.params.userName.toLowerCase();

        this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' });

        try {
            let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

            if (userNameDoc.exists()) {
            let userName = userNameDoc.data();

            let usuarioDoc = await getDoc(doc(db, 'usuarios', userName.uid));

            if (usuarioDoc.exists()) {
                let usuarioHost = usuarioDoc.data();

                if (usuarioHost.fotoPortada) {
                let uid = userName.uid;
                let fotoPortada = usuarioHost.fotoPortada;

                const fotoRef = ref(storage, `usuarios/${uid}/fotos-portada/${fotoPortada}-256x256.jpg`);
                const url = await getDownloadURL(fotoRef);
                this.fotoPortada = url;
                } else {
                this.fotoPortada = require(`@/assets/fotoUsuario.png`);
                }
            } else {
                this.$router.push({ name: '404' });
            }
            } else {
            this.$router.push({ name: '404' });
            }
        } catch (error) {
            this.$router.push({ name: '404' });
        } finally {
            this.ocultarOcupado();
        }
        },

        async solicitarInfoBoda() {
        let userNameParametro = this.$route.params.userName.toLowerCase();
        let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

        this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

        if (userNameDoc.exists()) {
            let userName = userNameDoc.data();

            let cliid = uuidv4();

            let clientedb = {
            cliid,
            estatusCliente: 'cotizacion',
            tipoServicio: 'Boda',
            nombre: this.clientedb.nombre,
            direccion: this.clientedb.direccion,
            ciudad: this.clientedb.ciudad,
            estadopais: this.clientedb.estadopais,
            telefono: this.clientedb.telefono,
            email: this.clientedb.email,
            radiosBodaTipo: this.clientedb.radiosBodaTipo,
            radiosBodaDespEv: this.clientedb.radiosBodaDespEv,
            despotro: this.clientedb.despotro,
            numinvitados: this.clientedb.numinvitados,
            presupuesto: this.clientedb.presupuesto,
            date: this.clientedb.date,
            otroLugarBoda: this.clientedb.otroLugarBoda,
            comentarios: this.clientedb.comentarios,
            };

            try {
            await setDoc(doc(db, 'usuarios', userName.uid, 'clientes', clientedb.cliid), clientedb);
            this.mostrarExito('Se envió la solicitud exitosamente. En Breve el equipo de Claudia García te contactará.');
            } catch (error) {
            this.mostrarError('Ocurrió un error enviando la solicitud, tal vez sea la señal de internet, por favor verifica y reintenta enviarla.');
            } finally {
            this.ocultarOcupado();
            }

            this.closeBoda();
        }
        },
        async solicitarInfoCena() {
        let userNameParametro = this.$route.params.userName.toLowerCase();
        let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

        this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

        if (userNameDoc.exists()) {
            let userName = userNameDoc.data();

            let cliid = uuidv4();

            let clientedb = {
            cliid,
            estatusCliente: 'cotizacion',
            tipoServicio: 'Cena Romántica',
            nombre: this.clientedb.nombre,
            direccion: this.clientedb.direccion,
            ciudad: this.clientedb.ciudad,
            estadopais: this.clientedb.estadopais,
            telefono: this.clientedb.telefono,
            email: this.clientedb.email,
            presupuesto: this.clientedb.presupuesto,
            date: this.clientedb.date,
            lugar: this.clientedb.lugar,
            cenadetalles: this.clientedb.cenadetalles,
            comentarios: this.clientedb.comentarios,
            };

            try {
            await setDoc(doc(db, 'usuarios', userName.uid, 'clientes', clientedb.cliid), clientedb);
            this.mostrarExito('Se envió la solicitud exitosamente. En Breve el equipo de Claudia García te contactará.');
            } catch (error) {
            this.mostrarError('Ocurrió un error enviando la solicitud, tal vez sea la señal de internet, por favor verifica y reintenta enviarla.');
            } finally {
            this.ocultarOcupado();
            }
        }

        this.closeCena();
        },

        async solicitarInfoPedida() {
        let userNameParametro = this.$route.params.userName.toLowerCase();
        let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

        this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

        if (userNameDoc.exists()) {
            let userName = userNameDoc.data();

            let cliid = uuidv4();

            let clientedb = {
            cliid,
            estatusCliente: 'cotizacion',
            tipoServicio: 'Pedida de Mano',
            nombre: this.clientedb.nombre,
            direccion: this.clientedb.direccion,
            ciudad: this.clientedb.ciudad,
            estadopais: this.clientedb.estadopais,
            telefono: this.clientedb.telefono,
            email: this.clientedb.email,
            presupuesto: this.clientedb.presupuesto,
            date: this.clientedb.date,
            lugar: this.clientedb.lugar,
            cenadetalles: this.clientedb.cenadetalles,
            comentarios: this.clientedb.comentarios,
            };

            try {
            await setDoc(doc(db, 'usuarios', userName.uid, 'clientes', clientedb.cliid), clientedb);
            this.mostrarExito('Se envió la solicitud exitosamente. En Breve el equipo de Claudia García te contactará.');
            } catch (error) {
            this.mostrarError('Ocurrió un error enviando la solicitud, tal vez sea la señal de internet, por favor verifica y reintenta enviarla.');
            } finally {
            this.ocultarOcupado();
            }

            this.closePedida();
        }
        },
        async solicitarInfoRenov() {
        let userNameParametro = this.$route.params.userName.toLowerCase();
        let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

        this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

        if (userNameDoc.exists()) {
            let userName = userNameDoc.data();

            let cliid = uuidv4();

            let clientedb = {
            cliid,
            estatusCliente: 'cotizacion',
            tipoServicio: 'Renovación de Votos',
            nombre: this.clientedb.nombre,
            direccion: this.clientedb.direccion,
            ciudad: this.clientedb.ciudad,
            estadopais: this.clientedb.estadopais,
            telefono: this.clientedb.telefono,
            email: this.clientedb.email,
            lugar: this.clientedb.lugar,
            despotro: this.clientedb.despotro,
            date: this.clientedb.date,
            comentarios: this.clientedb.comentarios,
            };

            try {
            await setDoc(doc(db, 'usuarios', userName.uid, 'clientes', clientedb.cliid), clientedb);
            this.mostrarExito('Se envió la solicitud exitosamente. En Breve el equipo de Claudia García te contactará.');
            } catch (error) {
            this.mostrarError('Ocurrió un error enviando la solicitud, tal vez sea la señal de internet, por favor verifica y reintenta enviarla.');
            } finally {
            this.ocultarOcupado();
            }
            this.closeRenov();
        }
        },

        async solicitarInfoEscapada() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            if (userNameDoc.exists()) {
                let userName = userNameDoc.data();

                let cliid = uuidv4();

                let clientedb = {
                cliid,
                estatusCliente: 'cotizacion',
                tipoServicio: 'Escapada Romántica',
                nombre: this.clientedb.nombre,
                direccion: this.clientedb.direccion,
                ciudad: this.clientedb.ciudad,
                estadopais: this.clientedb.estadopais,
                telefono: this.clientedb.telefono,
                email: this.clientedb.email,
                radiosBodaTipo: this.clientedb.radiosBodaTipo,
                lugar: this.clientedb.lugar,
                despotro: this.clientedb.despotro,
                comentarios: this.clientedb.comentarios,
                date: this.clientedb.date,
                };

                try {
                await setDoc(doc(db, 'usuarios', userName.uid, 'clientes', clientedb.cliid), clientedb);
                this.mostrarExito('Se envió la solicitud exitosamente. En Breve el equipo de Claudia García te contactará.');
                } catch (error) {
                this.mostrarError('Ocurrió un error enviando la solicitud, tal vez sea la señal de internet, por favor verifica y reintenta enviarla.');
                } finally {
                this.ocultarOcupado();
                }
                this.closeEscapada();
            }
        },
        closeBoda () {
            this.dialogBoda = false
            setTimeout(() => {
            this.clientedb = Object.assign({}, this.defaultItem)
            }, 300)
        },
        closeCena () {
            this.dialogCenaRom = false
            setTimeout(() => {
            this.clientedb = Object.assign({}, this.defaultItem)
            }, 300)
        },
        closePedida () {
            this.dialogPedidaMano = false
            setTimeout(() => {
            this.clientedb = Object.assign({}, this.defaultItem)
            }, 300)
        },
        closeRenov () {
            this.dialogRenov = false
            setTimeout(() => {
            this.clientedb = Object.assign({}, this.defaultItem)
            }, 300)
        },
        closeEscapada () {
            this.dialogEscapada = false
            setTimeout(() => {
            this.clientedb = Object.assign({}, this.defaultItem)
            }, 300)
        }
    }   
}
</script>