<!-- MODIFICAR: poner en el menu de avatar link a la web de nuvit, link de favoritos -->
<template>
  <v-app class="white">
    <!-- tool y drawer en área de oficina -->
    <v-navigation-drawer v-if="countForNavBar > 1 && showDrawer && !rutaDeClienteVisitante" class="hidden-xs-only" v-model="menu" absolute permanent width=250 dark stateless>
      <v-toolbar color="white" v-if="usuario || perfilAuth === true">
        <v-list class="pa-0">
          <v-list-tile avatar>
            <!-- <v-toolbar-side-icon  @click="menu = !menu"></v-toolbar-side-icon> -->
            <!-- <v-toolbar-title class="title logo logoFont" @click="avoidDuplicatedNav">
              <strong class="logoFont">nu<strong :style="{'color': paletaColores.primero}">Vin</strong></strong>
            </v-toolbar-title>      -->
            <!-- @click.stop="menu = !menu" -->
            <v-list-tile-avatar
              size="32" class="ml-0 pl-0"
              v-if="$route.path !== '/inicio-cuenta' && $route.path !== '/envio-verificacion-email'"
              >              
              <!-- <v-img :src="usuario.fotoPortada32"><span v-if="usuarioHost.redirectHost" class="my-5 py-5 text-xs-center white--text headline">{{usuariosubstr}}</span></v-img> -->
              <v-img v-if="usuario.fotoPortada32" :src="usuario.fotoPortada32"></v-img>
              <v-img v-else :style="{'background-color': paletaColores.primero}">
                <p class="white--text pt-1">{{usuariosubstr}}</p>
              </v-img>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title class="body-2 logoFont primary--text ml-0" v-if="usuario.userName != usuarioHost.userName">
                <strong v-if="usuario && usuario.userName" class="logoFont">{{ usuario.userName }}</strong>
                <span v-if="usuario && usuario.userName"> estas en la</span> <br>                
                <!-- <strong class="logoFont">nu<strong :style="{'color': paletaColores.primero}">Vin</strong></strong> -->
              </v-list-tile-title> 
              <v-list-tile-title class="body-2 logoFont primary--text ml-0" v-if="usuarioHost && usuarioHost.userName">
                <strong class="logoFont primary--text">nu<strong :style="{'color': paletaColores.primero}">Vin </strong></strong>
                <strong v-if="usuarioHost && usuarioHost.userName" class="logoFont">{{ usuarioHost.userName }}</strong>
                <strong v-else>app</strong>
              </v-list-tile-title>
            </v-list-tile-content>

            <!-- <v-list-tile-action>
              <v-btn
                icon light
                @click.stop="mini = !mini"
              >
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-tile-action> -->
            
            <v-spacer></v-spacer>
            
            <!-- <v-btn
              color="transparent"
              icon
              @click.stop="dialog = true"
              >
              <v-icon>mdi-cloud-search-outline</v-icon>
            </v-btn> -->
            <!-- <v-dialog
              v-model="dialog"
              max-width="290"
              >
              <v-toolbar
                color="white"
                >
                <v-toolbar-title></v-toolbar-title>
                <v-autocomplete
                  v-model="select"
                  :loading="loading"
                  :items="items"
                  :search-input.sync="search"
                  cache-items
                  class="mx-3"
                  flat
                  hide-no-data
                  hide-details
                  label="a qué nuVin quieres ir?"
                  solo-inverted
                >ir</v-autocomplete>
                <button v-if="select != null" @click="irSearch" >Ir</button>
              </v-toolbar>        
            </v-dialog> -->

            <!-- <v-btn @click="clearNtf" icon flat v-if="usuario != null">
              <v-badge
                v-model="show"
                color="purple"
                rigth
                overlap
                small          
                >
                <template v-slot:badge>
                  <span>!</span>
                </template>
                <v-icon class="primary">notifications_none</v-icon>
              </v-badge>        
            </v-btn>       -->

            <!-- <router-link v-if="usuario && usuario.userName !== null" :to="{ path: `/&${this.usuario.userName}/datos`, params: { userName: usuario.userName } }" >
              <span>{{ usuario.nombres }}</span>
            </router-link> -->

            <!-- <v-btn @click="irSearchUser" v-if="!usuarioEnCasa" small icon><span>{{ usuario.nombres }}</span></v-btn> -->
            <!-- <v-btn v-if="usuarioVisita = false"><span>{{ usuario.nombres }}</span></v-btn> -->
            
            <!-- <v-menu :nudge-width="100">
              <template v-slot:activator="{ on }" pr-5 mr-5>          
                  <v-avatar size="38"> 
                    <img
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                      alt="John"
                      v-on="on"
                    >
                  </v-avatar>
              </template>

              <v-list>
                <v-list-tile v-if="usuario && usuario.userName !== null" :to="{}">
                  <v-list-tile-action>
                    <v-icon small>edit</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title v-text="'Editar'"></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-if="usuario && usuario.userName !== null" :to="{ }">
                  <v-list-tile-action>
                    <v-icon small>notifications</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title v-text="'Avisos'"></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-if="usuario && usuario.userName !== null" :to="{ name: 'homePublicaUsuario', params: {userName: usuario.userName} }">
                  <v-list-tile-action>
                    <v-icon>account_circle</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title v-text="'Perfil'"></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile> 
              </v-list>
            </v-menu>  -->
            
            <!-- <router-link v-if="usuario && usuario.userName !== null" :to="{ name: 'homePublicaUsuario', params: { userName: usuario.userName } }" >
              <span>{{ usuario.nombres }}</span>
            </router-link> -->
            <!-- <v-btn v-if="usuario && usuario.userName !== null" color="secondary" :to="{ name: 'admin', params: { userName: usuario.userName }}">{{ usuario.userName }}</v-btn> -->
            <!-- <v-btn v-if="usuario && usuario.userName !== null" @click="salir">salir</v-btn> -->
          </v-list-tile>         
        </v-list>
      </v-toolbar>

      <v-list v-if="perfilAuth === true">          
        <v-list-group 
          v-for="item in extraAppsUser" :key="item.app"
          v-model="item.active"
          value="false" @click="calcSumUdsNCD(item.app)"
        >
          <template v-slot:activator>
            <v-list-tile-action class="ml-2">
              <v-btn fab small :style="{'background-color': paletaColores.primero}"><v-icon dark>{{ item.icon }}</v-icon></v-btn>
            </v-list-tile-action>
            <v-list-tile>
              <v-list-tile-title>{{item.app}}</v-list-tile-title>
            </v-list-tile>
          </template>
          
          <v-list-tile
            v-for="(item2, index) in item.accesosCols"
            :key="index"
          >
            <template v-if="item2.funcion != 'secundaria'">
            <v-btn flat @click="movingSameHost(item2.componente)">
              <v-list-tile-action>
                <v-icon small>{{item2.icon}}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title class="caption">{{item2.etiqueta}}</v-list-tile-title>
              </v-list-tile-content>
            </v-btn>
            </template>
          </v-list-tile>          
        </v-list-group>          
      </v-list>
        <!-- no es perfilauth pero es colaborador -->
      <v-list v-if="usuario && !perfilAuth">
        <v-list-group 
          v-for="item in extraAppsUser" :key="item.app"
          v-model="item.active"
          value="false"
        >
          <template v-slot:activator>
            <v-list-tile-action class="ml-2">
              <v-btn fab small :style="{'background-color': paletaColores.primero}"><v-icon>{{ item.icon }}</v-icon></v-btn>
            </v-list-tile-action>
            <v-list-tile>
              <v-list-tile-title>{{item.app}}</v-list-tile-title>
            </v-list-tile>
          </template>

          <v-list-tile
            v-for="(item2, index) in item.accesosCols"
            :key="index"
          >
            <template >
              <div v-if="colaborador.estatusAccesos.some(el => el.etiqueta == item2.etiqueta && el.value == true)">
                <v-btn flat @click="movingSameHost(item2.componente)">
                  <v-list-tile-action>
                    <v-icon small>{{item2.icon}}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title class="caption">{{item2.etiqueta}}</v-list-tile-title>
                  </v-list-tile-content>
                </v-btn>
              </div>
              <div v-else>
                <v-btn flat disabled>
                  <v-list-tile-action>
                    <v-icon small>{{item2.icon}}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title class="caption">{{item2.etiqueta}}</v-list-tile-title>
                  </v-list-tile-content>
                </v-btn>
              </div>
            </template>
          </v-list-tile>
        </v-list-group>
      </v-list>    

      <v-divider></v-divider>

      <v-list v-if="!perfilAuth">
        <v-list-tile v-if="usuarioHost.userName != null && usuarioHost != null" :to="{ name: 'Entrada', params: {userName: usuarioHost.userName} }">
          <v-list-tile-action>
            <div class="square" :style="{'background-color': paletaColores.segundo}"><v-icon color="white">mdi-home</v-icon></div>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="mt-2 white--text">Área Pública</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <v-list v-if="perfilAuth">
        <v-list-tile v-if="usuarioHost" @click="movingSameHostAoficina">
          <v-list-tile-action >
            <div class="square" :style="{'background-color': paletaColores.segundo}"><v-icon>mdi-chair-rolling</v-icon></div>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="mt-2 white--text text-none">Centro de Control</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="usuarioHost.userName != null && usuarioHost != null" :to="{ name: 'Entrada', params: {userName: usuarioHost.userName} }">
          <v-list-tile-action>
            <div class="square" :style="{'background-color': paletaColores.segundo}"><v-icon color="white">mdi-home</v-icon></div>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="mt-2 white--text">Área Pública</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="usuarioHost && perfilAuth" >          
          <SubscripcionesUser/>          
        </v-list-tile>
        <aplicaciones v-if="usuarioHost && usuario.userName === 'llogaran'" :extra-apps-user="extraAppsUser" @agregarAppExtra="agregarAppExtra"/>
        <v-list-tile v-if="usuarioHost" >          
          <v-dialog
            v-model="dialogHelpDash"
            width="500"
          >
            <template v-slot:activator="{ on }">
              <v-list-tile-action>
                <div class="square" v-on="on" :style="{'background-color': paletaColores.segundo}"><v-icon color="white">mdi-help-circle</v-icon></div>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-on="on" class="mt-2 white--text">Ayuda - Soporte</v-list-tile-title>
              </v-list-tile-content>
            </template>

            <v-card>
              <v-card-title class="headline">
                Cómo funciona la Plataforma nuVin...
              </v-card-title>

              <v-card-text>
                De lado izquierdo de la pantalla se encuentra la sección prinicpal de Navegación,
                dividida en dos secciones: en la de arriba están las funciones de La o Las Aplicaciones y
                abajo están las funciones estándar. <br><br>

                De lado derecho, aparecerá lo correspondiente a lo que se dé click de lado izquierdo. En cuanto 
                a las funciones de la aplicacíon, de lado superior derecho aparecerá un ícono (" ? ") que al dar Click
                aparecerá una explicación-instrucciones sobre cómo manejar esa función.<br><br>

                En la sección de botones estándar: <br><br>
                
                <ul>
                  <li>El botón de Área Pública te envía a la Entrada de la nuVin en la que te encuentras, para cerrar sesión 
                  o para dirigirte a otra nuVin,</li>
                  <li>El botón de Centro de Control te lleva a la vista dónde se resume lo correspondiente a la nuVin en la que te encuentras,
                  y de lo que tienes permiso en caso de entrar como Colaborador. Aparecrán indicadores o avisos importantes
                  dependiendo de la Aplicación que estés usando. Por ejemplo, en 'finanzasPrácticas', aparecen los indicadores
                  de Gastos Mensuales, Ingresos Mensuales y Avisos de Gastos Vencidos</li>
                  <li>En Suscripciones, encuentras los datos de cada una de las suscripciones que has adquirido: fecha, 
                    botón de cancelación, clave de identificación, etc.
                  </li>
                  <li>
                    Aplicaciones, despliega todas las aplicaciones adicionales que nuVin te ofrece, por si quieres potenciar
                    tu aplicación o herramienta digital nuVin.
                  </li>
                </ul>
                
                <span><span class="principal--text font-weight-bold">email:</span>  nubesdebits@gmail.com</span><br>
                <span><span class="principal--text font-weight-bold">móvil-whatsapp:</span>  +529996470286</span><br>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn flat small dark class="principal--text caption" @click="dialogHelpDash = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>          
        </v-list-tile>
      </v-list>      
    </v-navigation-drawer>
    
    <!-- tool y drawer para dispositivos pequeños en oficina-->
    <v-toolbar v-if="countForNavBar > 1 && perfilAuth === true && rutaDeClienteVisitante === false" color="white">
      <v-list class="pa-0">
        <v-list-tile avatar>
          <v-list-tile-avatar
            size="32" class="ml-0 pl-0"
            @click.stop="(menu = !menu)"
            v-if="$route.path !== '/inicio-cuenta' && $route.path !== '/envio-verificacion-email'"
            >
            <v-img v-if="usuario.fotoPortada32" :src="usuario.fotoPortada32"></v-img>
              <v-img v-else :style="{'background-color': paletaColores.primero}">
                <p class="white--text pt-1">{{usuariosubstr}}</p>
              </v-img>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title class="body-2 logoFont primary--text ml-0" v-if="usuario.userName !== usuarioHost.userName">
              <strong v-if="usuarioHost != null && usuario.userName != null" class="logoFont">{{ usuario.userName }}</strong>
              <span v-if="usuarioHost != null && usuario.userName != null"> estas en la</span> <br>                
              <!-- <strong class="logoFont">nu<strong :style="{'color': paletaColores.primero}">Vin</strong></strong> -->
            </v-list-tile-title> 
            <v-list-tile-title class="body-2 logoFont primary--text ml-0" v-if="usuarioHost">
              <strong class="logoFont primary--text">nu<strong :style="{'color': paletaColores.primero}">Vin </strong></strong>
              <strong v-if="usuarioHost != null && usuarioHost.userName != null" class="logoFont">{{ usuarioHost.userName }}</strong>
              <strong v-else>app</strong>
            </v-list-tile-title>
          </v-list-tile-content>
          
          <v-spacer></v-spacer>
          
        </v-list-tile>         
      </v-list>
    </v-toolbar>  
    <v-navigation-drawer v-if="countForNavBar > 1 && perfilAuth === true && rutaDeClienteVisitante === false" class="hidden-sm-and-up" v-model="menu" 
      value="true" app temporary width=400 hide-overlay absolute>      
      <v-list>
        <div v-if="perfilAuth == true">
          <v-list-group 
            no-action v-for="item in extraAppsUser" :key="item.app"
            sub-group v-model="item.active"
            value="false" 
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title :style="{'color': paletaColores.primero}">{{ item.app }}</v-list-tile-title>                  
              </v-list-tile>
            </template>

            <v-list-tile
              v-for="(item2, index) in item.accesosCols"
              :key="index"
            >
              <template v-if="item2.funcion != 'secundaria'">
                <v-btn v-if="$route.name !== item2.componente" flat @click="movingSameHost(item2.componente)">
                  <v-list-tile-action>
                    <v-icon small :style="{'color': paletaColores.primero}">{{item2.icon}}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title class="caption">{{item2.etiqueta}}</v-list-tile-title>
                  </v-list-tile-content>
                </v-btn>
              
                <v-btn v-else flat @click="toggleItem()">
                  <v-list-tile-action>
                    <v-icon small>{{ item2.icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title class="caption">{{ item2.etiqueta }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-btn>
              </template>
            </v-list-tile>
          </v-list-group>
        </div>

        <div v-else>
          <v-list-group 
            no-action v-for="item in extraAppsUser" :key="item.app"
            sub-group v-model="item.active"
            value="false"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{item.app}}</v-list-tile-title>
              </v-list-tile>
            </template>

            <v-list-tile
              v-for="(item2, index) in item.accesosCols"
              :key="index"
            >
              <template v-if="colaborador.estatusAccesos.some(el => el.etiqueta == item2.etiqueta && el.value == true) && item2.funcion != 'secundaria'">
                <v-btn v-if="$route.name !== item2.componente" flat @click="movingSameHost(item2.componente)">
                  <v-list-tile-action>
                    <v-icon>{{item2.icon}}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title class="caption">{{item2.etiqueta}}</v-list-tile-title>
                  </v-list-tile-content>
                </v-btn>

                <v-btn v-else flat @click="toggleItem()">
                  <v-list-tile-action>
                    <v-icon small>{{ item2.icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title class="caption">{{ item2.etiqueta }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-btn>
              </template>                
            </v-list-tile>
          </v-list-group>
        </div>        
      </v-list>

      <v-divider></v-divider>

      <v-list-tile v-if="usuarioHost && $route.name !== 'oficina'" @click="movingSameHostAoficina">
        <v-list-tile-action>
          <v-icon :style="{'color': paletaColores.primero}">mdi-chair-rolling</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title class="mt-2 primary--text">Centro de Control</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile v-else @click="toggleItem()">
        <v-list-tile-action>
          <v-icon :style="{'color': paletaColores.primero}">mdi-chair-rolling</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title class="mt-2 primary--text">Centro de Control</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile v-if="usuarioHost !== null && usuarioHost.userName !== null" :to="{ name: 'Entrada', params: {userName: usuarioHost.userName} }">
        <v-list-tile-action>
          <v-icon :style="{'color': paletaColores.primero}">mdi-home</v-icon>
        </v-list-tile-action>
        <v-list-tile-title class="mt-2 primary--text">Área Pública</v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-if="usuarioHost && perfilAuth">          
        <SubscripcionesUser/>
      </v-list-tile>
      <v-list-tile v-if="usuarioHost" >          
        <v-dialog
          v-model="dialogHelpDash"
          width="500"
        >
          <template v-slot:activator="{ on }">
            <v-list-tile-action>
              <v-icon :style="{'color': paletaColores.primero}">mdi-help-circle</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-on="on" class="mt-2 primary--text">Ayuda - Soporte</v-list-tile-title>
            </v-list-tile-content>
          </template>

          <v-card>
            <v-card-title class="headline">
              Cómo funciona la Plataforma nuVin...
            </v-card-title>

            <v-card-text>
              De lado izquierdo de la pantalla se encuentra la sección prinicpal de Navegación,
              dividida en dos secciones: en la de arriba están las funciones de La o Las Aplicaciones y
              abajo están las funciones estándar. <br><br>

              De lado derecho, aparecerá lo correspondiente a lo que se dé click de lado izquierdo. En cuanto 
              a las funciones de la aplicacíon, de lado superior derecho aparecerá un ícono (" ? ") que al dar Click
              aparecerá una explicación-instrucciones sobre cómo manejar esa función.<br><br>

              En la sección de botones estándar: <br><br>
              
              <ul>
                <li>El botón de Área Pública te envía a la Entrada de la nuVin en la que te encuentras, para cerrar sesión 
                o para dirigirte a otra nuVin,</li>
                <li>El botón de Centro de Control te lleva a la vista dónde se resume lo correspondiente a la nuVin en la que te encuentras,
                y de lo que tienes permiso en caso de entrar como Colaborador. Aparecrán indicadores o avisos importantes
                dependiendo de la Aplicación que estés usando. Por ejemplo, en 'finanzasPrácticas', aparecen los indicadores
                de Gastos Mensuales, Ingresos Mensuales y Avisos de Gastos Vencidos</li>
                <li>En Suscripciones, encuentras los datos de cada una de las suscripciones que has adquirido: fecha, 
                  botón de cancelación, clave de identificación, etc.
                </li>
                <li>
                  Aplicaciones, despliega todas las aplicaciones adicionales que nuVin te ofrece, por si quieres potenciar
                  tu aplicación o herramienta digital nuVin.
                </li>
              </ul>
              
              <span><span class="principal--text font-weight-bold">email:</span>  nubesdebits@gmail.com</span><br>
              <span><span class="principal--text font-weight-bold">móvil-whatsapp:</span>  +529996470286</span><br>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat small dark class="principal--text caption" @click="dialogHelpDash = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>          
      </v-list-tile>      
    </v-navigation-drawer>
    
    <!-- tool y drawer en área pública con user -->
    <v-toolbar color="white" v-if="(countForNavBar == 1 && usuario) || (rutaDeClienteVisitante === true && usuario)">
      <v-list class="pa-0">
        <v-list-tile avatar>
          <v-list-tile-avatar
            size="32" class="ml-0 pl-0"
            @click.stop="(menuHome = !menuHome)"
            v-if="$route.path !== '/inicio-cuenta' && $route.path !== '/envio-verificacion-email'"
            >
            <v-img v-if="usuario.fotoPortada32" :src="usuario.fotoPortada32"></v-img>
            <v-img v-else :style="{'background-color': paletaColores.primero}">
              <p class="white--text pt-1">{{usuariosubstr}}</p>
            </v-img>
          </v-list-tile-avatar>

          <v-list-tile-content v-if="dialogProgress == false">
            <v-list-tile-title class="body-2 logoFont primary--text ml-0" v-if="usuario.userName !== usuarioHost.userName">
              <strong v-if="usuario && usuario.userName"  class="logoFont">{{ usuario.userName }}</strong>
              <span v-if="usuario && usuario.userName" > estas en la</span> <br>                
              <!-- <strong class="logoFont">nu<strong :style="{'color': paletaColores.primero}">Vin</strong></strong> -->
            </v-list-tile-title>
            <v-list-tile-title class="body-2 logoFont primary--text ml-0" v-if="usuarioHost">
              <strong class="logoFont primary--text">nu<strong :style="{'color': paletaColores.primero}">Vin </strong></strong>
              <strong v-if="usuarioHost && usuarioHost.userName" class="logoFont">{{ usuarioHost.userName }}</strong>
              <strong v-else>app</strong>
            </v-list-tile-title>                         
          </v-list-tile-content>
          
          <v-spacer></v-spacer>
          
        </v-list-tile>         
      </v-list>
    </v-toolbar>  
    <v-navigation-drawer v-model="menuHome" 
      value="true" app temporary width=250 hide-overlay> 
      <v-list>
        <v-list-tile v-if="usuario != null" @click="irUser">
          <v-list-tile-action>
            <v-icon>mdi-storefront</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>mi nuVin</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="usuario && usuario.userName !== null" @click="dialogQR = true">
          <v-list-tile-action>
            <v-icon>mdi-qrcode</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>mi QR</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <template>
          <v-layout row justify-center>
            <v-dialog v-model="dialogQR" width='220'>
              <v-card class="text-xs-center" v-if="usuario && usuario.userName !== null">
                <vue-qr class="my-2" :text="'nuvin.app/' + usuario.userName" :size="200" backgroundColor="transparent" ></vue-qr>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    flat
                    @click="dialogQR = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>            
        </template>
        <v-list-tile v-if="usuario && usuario.userName !== null" @click="salir">
          <v-list-tile-action>
            <v-icon>arrow_back</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Cerrar sesión</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="usuario && usuario.userName !== null" @click="dialogEnlaces = true">
          <v-list-tile-action>
            <v-icon>clouds</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>nuvin Enlaces</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <template>
          <v-layout row justify-center>
            <v-dialog v-model="dialogEnlaces" scrollable max-width="300px">
              <v-card>
                <v-card-title class="headline">Enlaces Guardados</v-card-title>
                <v-btn @click="saveEnlace" v-if="usuario && usuarioHost && usuario.userName !== null && usuarioHost.userName !== null && usuario.userName !== usuarioHost.userName && (!enlaces.some(el => el.enlace === usuarioHost.userName))">guardar enlace</v-btn>
                
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">
                  <div v-for="item in enlaces" :key="item.enlid">
                    <v-btn class="ml-1 pl-1 caption elevation-10" round outline small :style="{'color': paletaColores.primero}" 
                           @click="irEnlace(item.enlace)">
                      <span class="pl-0 ml-0">{{  item.enlace }}</span>
                    </v-btn>
                  </div>  
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="primary" flat @click="dialogEnlaces = false">Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </template>        
        <v-list-tile v-if="usuario && usuario.userName !== null">
          <v-list-tile-action>
            <avisoDePrivacidad/>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="usuario && usuario.userName !== null">
          <v-list-tile-action>
            <terminosYcondiciones/>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>    
   
    <!-- tool área pública no usuario-->
    <v-toolbar v-if="!usuario" color="white" app dense flat>
      <v-toolbar-title class="title logo logoFont" @click="avoidDuplicatedNav">
        <strong class="logoFont">nu<strong :style="{'color': paletaColores.primero}">Vin</strong></strong>
        <!-- <strong class="logoFont">nu<strong :style="{'color': paletaColores.primero}">Vin</strong></strong> -->
      </v-toolbar-title>   
      <v-toolbar-title class="body-2 logo logoFont ml-0" @click="avoidDuplicatedNav" >
        <strong v-if="usuarioHost !== null && usuarioHost.userName !== null && this.$route.path != '/web'" class="logoFont">{{ usuarioHost.userName }}</strong>
        <strong v-else>app</strong>
      </v-toolbar-title>     

      <v-spacer></v-spacer>

      <v-toolbar-items v-if="['/web', '/'].includes(this.$route.path)" class="hidden-xs-only">
        <v-menu
          v-model="menuQue1"
          :close-on-content-click="false"
          :max-width="600"
          offset-x
          class="text-xs-center"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              v-on="on"
              class="caption text-none"
              small
              flat
            >
            ¿Qué somos?
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline">
              ¿Qué es nu<strong class="logoFont"><span :style="{'color': paletaColores.primero}">Vin</span><span class="principal--text"></span></strong>.app?
            </v-card-title>

            <v-card-text>
              Somos una Plataforma de trabajo, que proporciona sevicios digitales y 
              virtuales a las necesidades de cualquier tamaño de negocio o profesión, desde Personas con
              Actividad Independiente, hasta grandes Empresas o Industria. <br>
              Sistemas que ayudan a la productividad en: la planeación, organzación, dirección; de manera práctica, intuitiva y eficiente. 
              Contáctanos o adquiere uno de nuestros planes... Virtualízate.    
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat small dark class="principal--text caption" @click="menuQue1 = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          class="ml-4 pl-1 mt-1 pt-4"
        >
          <template v-slot:activator>
            <v-btn              
              color="secondary"
              class="caption text-none principal--text"
              dark
              round small
            > 
            Productos
            </v-btn>
          </template>
          <v-dialog
            v-model="menuConsul"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn  v-on="on" round  class="mt-3 purple lighten-4">
                <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> consultoría</strong>
              </v-btn>
            </template>

            <v-card v-if="menuConsul == true">
              <v-card-title class="headline">
                <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> consultoría</strong>:
              </v-card-title>

              <v-card-text>
                En nuVin ofrecemos consultoría en la introducción de tecnología digital en las operaciones de las empresas,
                ya sea que estén en etapa de inicio o en operación.

                La consultoría puede incluir o no las aplicaciones software necesarias que resulten del análisis de la empresa.

                Contáctanos para saber nuestros precios y nuestra forma de trabajo.<br>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Crear nuVin</v-btn> -->
                <v-btn flat small dark class="principal--text caption" @click="menuConsul = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="menuPaq"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on" round
                class="mt-3 purple lighten-4"
              >
              <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> talleresApp</strong>
              </v-btn>
            </template>

            <v-card v-if="menuPaq == true">
              <v-card-title class="headline">
                <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> talleresApp</strong>:
              </v-card-title>

              <v-card-text>
                En nuVin organizamos talleres grupales o privados para todo tamaño y tipo de negocio <br><br>
                Ofreciendo las funciones estándar para la administración de un negocio y a precios adecuados a su nivel
                de operación.

                <!-- Es el Perfil de nuVin listo para ser utilizado. <br><br>
                Inicialmente se ofrecen 3 tipos de plan, cada uno con paquetes de elementos: número de colaboradores
                a utilizar la aplicación, productos, miembros, etc. Puedes iniciar en cualquier plan, dependiendo de 
                tus necesidades y tamaño de negocio, y conforme tu negocio vaya creciendo puedes ir cambiando de plan o
                solicitar Servicios Personalizados. <br><br>

                Sólo da click en <strong class="principal--text">Crea una nuVin</strong>, elige tu giro de negocio y llena la nuVin con la información
                de tu negocio para empezar a utilizarla. Te damos un tiempo de prueba para que la conozcas y con calma eligas
                el plan que más se adepte a tus necesidades. -->
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Crear nuVin</v-btn> -->
                <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>          

          <v-dialog
            v-model="menuHerr"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <!-- <template v-slot:activator="{ on }"> -->
              <!-- <v-btn
                v-on="on" round
                class="mt-3 purple lighten-4"
              >
              <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> herramienta</strong>
              </v-btn> -->
            <!-- </template> -->

            <v-card v-if="menuHerr == true">
              <v-card-title class="headline">
                <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> herramienta</strong>:
              </v-card-title>

              <v-card-text>
                Esta modalidad de nuVin es complemento para , transformando a 
                tu nuvin más productiva y funcional.  <br>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Crear nuVin</v-btn> -->
                <v-btn flat small dark class="principal--text caption" @click="menuHerr = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="menuCorp"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <template v-slot:activator="{ on }">            
              <v-btn
                  round
                  class="mt-3 black"
                  v-on="on"
                >
                <strong class="logoFont text-none white--text">nu<strong class="yellow--text">Vin</strong> corporativo</strong>
                </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline">
                En qué consisten los Servicios Corporativos:
              </v-card-title>

              <v-card-text>
                Son servicios para cadenas de negocios, franquicias, matriz de negocios que no solo requieren sistemas software
                para sus sucursales, sino también para el corporativo que administra la organización y control de las unidades 
                "hijo" de la marca.  <br><br>

                Escuchamos las necesidades del cliente y las desarrollamos para poder ser utilizadas en el ámbito virtual,
                desde una sencilla aplicación, hasta una estructura compleja de aplicaciones interactuando entre si, con el 
                objetivo de facilitar y aumentar la productividad de su tiempo. Es un servicio cercano al cliente en consultoría y
                capacitación desde el principio hasta la duración del proyecto.<br><br>

                <!-- Simplemente das click en <strong>Virtualizarme</strong>, describes brevemente tus necesidades, en nuVin hacemos -->
                Simplemente haz click en el botón <strong class="principal--text">Virtualizarme</strong>, describes brevemente tus necesidades, en nuVin hacemos
                un breve análisis y posteriormente nos comunicamos contigo para definir detalles,
                precio, tiempo de entrega, etc.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <template>
                  <v-layout row justify-center>
                    <v-dialog v-model="dialogVit" persistent max-width="600px">
                      <template v-slot:activator="{ on }">
                        <v-btn flat small dark class="principal--text caption" v-on="on">Virtualizarme</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Solicitud de Cotización para Sistema-Aplicación </span>
                        </v-card-title>
                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm6>
                                <v-text-field label="nombre" counter="45" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.nombre"></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-text-field label="email*" counter="55" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.email"></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-text-field label="en qué tipo de negocio o proyecto será utilizado?" counter="145" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.tipo"></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-select
                                  :items="['0-25', '26-45', '46-105', '106+']"
                                  label="# de usuarios aproximado"
                                  autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.numusuarios"
                                ></v-select>
                              </v-flex>
                              <v-flex xs12>
                                <v-textarea
                                  name="input-7-1"
                                  label="describe brevemente el sistema-aplicación que necesitas"
                                  value=""
                                  counter="555" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.descripcion"
                                ></v-textarea>
                              </v-flex>
                            </v-layout>
                          </v-container>
                          <small>*indicates required field</small>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn class="principal--text" flat @click="dialogVit = false">Cerrar</v-btn>
                          <v-btn class="principal--text" flat @click="solCotiz" :depressed="$v.clientedb.$invalid" :disabled="$v.clientedb.$invalid">Enviar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template>
                <v-btn flat small dark class="principal--text caption" @click="menuCorp = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-speed-dial>

        <!-- <v-btn
          color="principal"
          :to="{ name: 'visita-en-lobby' }"
          class="caption text-none strong"
          small
          flat
          >
          <strong class="principal--text">Contáctanos</strong>
        </v-btn> -->
        <template>
          <v-layout row justify-center>
            <v-btn
              color="principal"
              class="caption text-none strong"
              small
              flat
              @click.stop="dialogContactanos = true"
            >
              <strong class="principal--text">Contáctanos</strong>
            </v-btn>

            <v-dialog
              v-model="dialogContactanos"
              max-width="290"
            >
              <v-card>
                <v-card-text>
                  <span><span class="principal--text font-weight-bold">email:</span>  nubesdebits@gmail.com</span><br>
                  <span><span class="principal--text font-weight-bold">móvil-whatsapp:</span>  +529996470286</span><br>
                  <span><span class="principal--text font-weight-bold">dirección:</span>  31B por 14 y 16 145, Nueva Alemán, Mérida, Yucatán,
                    C. P. 97146</span>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="principal"
                    flat="flat"
                    @click="dialogContactanos = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </template>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-btn
        dark
        class="caption text-none white--text principal"
        small
        round 
        flat
        @click="accesoCliente()"
        >
        <strong>Acceso Clientes</strong>
      </v-btn>
      <div v-if="['/web', '/'].includes(this.$route.path)" class="text-xs-center hidden-sm-and-up">
        <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          class="ml-4 pl-1 mt-1 pt-4"
        >
          <template v-slot:activator>
            <v-btn              
              color=""
              class="caption text-none principal--text"
              dark icon
              small
            > 
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-menu
            v-model="menuQue2"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
            class="text-xs-center"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                flat round
                class="mt-3 purple lighten-4 caption text-none white--text"
              >
              ¿Qué somos?
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline">
                ¿Qué es nu<strong class="logoFont"><span :style="{'color': paletaColores.primero}">Vin</span><span class="principal--text"></span></strong>.app?
              </v-card-title>

              <v-card-text>
                Somos una Plataforma de trabajo, que proporciona sevicios digitales y 
                virtuales a las necesidades de cualquier tamaño de negocio o profesión, desde Personas con
                Actividad Independiente, hasta grandes Empresas o Industria. <br>
                Sistemas que ayudan a la productividad en: la planeación, organzación, dirección; de manera práctica, intuitiva y eficiente. 
                Contáctanos o adquiere uno de nuestros planes... Virtualízate.    
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn flat small dark class="principal--text caption" @click="menuQue2 = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <!-- <v-btn
            :to="{ name: 'visita-en-lobby' }"
            small
            flat round
            class="mt-3 purple lighten-4 caption text-none white--text"
            >
            <strong>Contáctanos</strong>
          </v-btn> -->

          <template>
            <v-layout row justify-center>
              <v-btn
                color="principal"
                class="caption text-none strong"
                small
                flat
                @click.stop="dialogContactanos = true"
              >
                <strong class="principal--text">Contáctanos</strong>
              </v-btn>

              <v-dialog
                v-model="dialogContactanos"
                max-width="290"
              >
                <v-card>
                  <v-card-text>
                    <span><span class="principal text--bold">email:</span>nubesdebits@gmail.com</span><br>
                    <span><span class="principal text--bold">móvil:</span>9996470286</span><br>
                    <span><span class="principal text--bold">dirección:</span>31B por 14 y 16 145, Nueva Alemán, Mérida, Yucatán,
                      C. P. 97146</span>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="principal"
                      flat="flat"
                      @click="dialogContactanos = false"
                    >
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </template>
          <!-- consultoria -->
          <v-dialog
            v-model="menuConsul"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on" round
                class="mt-3 purple lighten-4 mr-5"
              >
              <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> consultoría</strong>
              </v-btn>
            </template>

            <v-card v-if="menuConsul == true">
              <v-card-title class="headline">
                <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> consultoría</strong>:
              </v-card-title>

              <v-card-text>
                En nuVin ofrecemos consultoría en la introducción de tecnología digital en las operaciones de las empresas,
                ya sea que estén en etapa de inicio o en operación.

                La consultoría puede incluir o no las aplicaciones software necesarias que resulten del análisis de la empresa.

                Contáctanos para saber nuestros precios y nuestra forma de trabajo.<br>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Crear nuVin</v-btn> -->
                <v-btn flat small dark class="principal--text caption" @click="menuConsul = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
            <!-- talleres -->
          <v-dialog
            v-model="menuPaq"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on" round
                class="mt-3 purple lighten-4 mr-5"
              >
              <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> talleresApp</strong>
              </v-btn>
            </template>

            <v-card v-if="menuPaq == true">
              <v-card-title class="headline">
                <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> talleresApp</strong>:
              </v-card-title>

              <v-card-text>
                En nuVin organizamos talleres grupales o privados para todo tamaño y tipo de negocio <br><br>
                Ofreciendo las funciones estándar para la administración de un negocio y a precios adecuados a su nivel
                de operación.

                <!-- Es el Perfil de nuVin listo para ser utilizado. <br><br>
                Inicialmente se ofrecen 3 tipos de plan, cada uno con paquetes de elementos: número de colaboradores
                a utilizar la aplicación, productos, miembros, etc. Puedes iniciar en cualquier plan, dependiendo de 
                tus necesidades y tamaño de negocio, y conforme tu negocio vaya creciendo puedes ir cambiando de plan o
                solicitar Servicios Personalizados. <br><br>

                Sólo da click en <strong class="principal--text">Crea una nuVin</strong>, elige tu giro de negocio y llena la nuVin con la información
                de tu negocio para empezar a utilizarla. Te damos un tiempo de prueba para que la conozcas y con calma eligas
                el plan que más se adepte a tus necesidades. -->
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Crear nuVin</v-btn> -->
                <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>          
          <!-- herramienta -->
          <v-dialog
            v-model="menuHerr"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <!-- <template v-slot:activator="{ on }"> -->
              <!-- <v-btn
                v-on="on" round
                class="mt-3 purple lighten-4 mr-5 pr-3"
              >
              <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> herramienta</strong>
              </v-btn> -->
            <!-- </template> -->

            <v-card v-if="menuHerr == true">
              <v-card-title class="headline">
                <strong class="logoFont text-none">nu<strong :style="{'color': paletaColores.primero}">Vin</strong> herramienta</strong>:
              </v-card-title>

              <v-card-text>
                Esta modalidad de nuVin es complemento para una nuVin Personal o nuvin Negocio, transformando a 
                tu nuvin más productiva y funcional.  <br>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn flat small dark class="principal--text caption" @click="menuPaq = false">Crear nuVin</v-btn> -->
                <v-btn flat small dark class="principal--text caption" @click="menuHerr = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- corporativo -->
          <v-dialog
            v-model="menuCorp"
            :close-on-content-click="false"
            :max-width="600"
            offset-x
          >
            <template v-slot:activator="{ on }">            
              <v-btn
                  round
                  class="mt-3 black mr-5 pr-3"
                  v-on="on"
                >
                <strong class="logoFont text-none white--text">nu<strong class="yellow--text">Vin</strong> corporativo</strong>
                </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline">
                En qué consisten los Servicios Corporativos:
              </v-card-title>

              <v-card-text>
                Son servicios para cadenas de negocios, franquicias, matriz de negocios que no solo requieren sistemas software
                para sus sucursales, sino también para el corporativo que administra la organización y control de las unidades 
                "hijo" de la marca.  <br><br>

                Escuchamos las necesidades del cliente y las desarrollamos para poder ser utilizadas en el ámbito virtual,
                desde una sencilla aplicación, hasta una estructura compleja de aplicaciones interactuando entre si, con el 
                objetivo de facilitar y aumentar la productividad de su tiempo. Es un servicio cercano al cliente en consultoría y
                capacitación desde el principio hasta la duración del proyecto.<br><br>

                <!-- Simplemente das click en <strong>Virtualizarme</strong>, describes brevemente tus necesidades, en nuVin hacemos -->
                Simplemente haz click en el botón <strong class="principal--text">Virtualizarme</strong>, describes brevemente tus necesidades, en nuVin hacemos
                un breve análisis y posteriormente nos comunicamos contigo para definir detalles,
                precio, tiempo de entrega, etc.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <template>
                  <v-layout row justify-center>
                    <v-dialog v-model="dialogVit" persistent max-width="600px">
                      <template v-slot:activator="{ on }">
                        <v-btn flat small dark class="principal--text caption" v-on="on">Virtualizarme</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Solicitud de Cotización para Sistema-Aplicación </span>
                        </v-card-title>
                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm6>
                                <v-text-field label="nombre" counter="45" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.nombre"></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-text-field label="email*" counter="55" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.email"></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-text-field label="en qué tipo de negocio o proyecto será utilizado?" counter="145" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.tipo"></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-select
                                  :items="['0-25', '26-45', '46-105', '106+']"
                                  label="# de usuarios aproximado"
                                  autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.numusuarios"
                                ></v-select>
                              </v-flex>
                              <v-flex xs12>
                                <v-textarea
                                  name="input-7-1"
                                  label="describe brevemente el sistema-aplicación que necesitas"
                                  value=""
                                  counter="555" autofocus :error-messages="erroresCamposCotiz" v-model="clientedb.descripcion"
                                ></v-textarea>
                              </v-flex>
                            </v-layout>
                          </v-container>
                          <small>*indicates required field</small>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn class="principal--text" flat @click="dialogVit = false">Cerrar</v-btn>
                          <v-btn class="principal--text" flat @click="solCotiz" :depressed="$v.clientedb.$invalid" :disabled="$v.clientedb.$invalid">Enviar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template>
                <v-btn flat small dark class="principal--text caption" @click="menuCorp = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-speed-dial>
      </div>
    </v-toolbar>    

    <v-content class="grey lighten-4" >
      <v-container fluid>
        <router-view v-if="!usuario" @carga-finalizada="actualizarDialogProgress"></router-view>
        <router-view v-else :class="{ 'margenizq': isDesktopAndNotRootRoute }" :isExtraAppsUserEmpty="extraAppsUser.length === 0" @llamar-consultar-extraapps="consultarExtraAppsCompradas" @carga-finalizada="actualizarDialogProgress" @nuevo-enlace="agregarEnlace" :app="appActiva"></router-view>        
      </v-container>
    </v-content>

    <v-snackbar 
      v-model="notificacion.visible" 
      :color="notificacion.color" 
      multi-line top 
      :timeout="6000" dark>
      {{ notificacion.mensaje }}
      <v-btn color="white" flat @click="ocultarNotificacion">
        Cerrar
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="ocupado.visible" max-width="400" persistent>
      <v-card>
        <v-toolbar color="white" card>
          <v-toolbar-title>
            {{ ocupado.titulo }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="subheading">
          {{ ocupado.mensaje}}
        </v-card-text>
        <v-card-text>
          <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer class="pa-3 grey darken-3" v-if="!usuario">
      <div>
        <v-btn small color="grey darken-3" dark :to="{ path: '/web' }"><span class="caption text-none">miniWeb</span></v-btn>
      </div>
      <v-spacer></v-spacer>
      <avisoDePrivacidad/>
      <terminosYcondiciones/>
      <v-spacer></v-spacer>
      <span class="pl-4 py-1 white--text">&copy; {{ new Date().getFullYear() }} nuVin</span>
    </v-footer>
  </v-app>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapMutations, mapState } from 'vuex'
import { collection, doc, getDoc, getDocs, setDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import avisoDePrivacidad from '@/components/avisoDePrivacidad'
import terminosYcondiciones from '@/components/terminosYcondiciones'
import aplicaciones from '@/components/aplicaCiones'
import SubscripcionesUser from '@/components/SubscripcionesUser'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {  
  components: { avisoDePrivacidad, terminosYcondiciones, aplicaciones, SubscripcionesUser },
  name: 'App',
  data () {
    return {
      appActiva: '',
      sumaUnidadesNCD: 0, // Inicialmente no hay suma calculada
      showDrawer: true,
      esMovil: window.innerWidth <= 640,
      colaborador: '',
      colaboradores: [],
      enlaces: [],
      item: {active: false},
      extraAppsUser: [],
      mini: false,
      direction: 'bottom',
      notifStatus: false,
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-transition',
      menuQue1: false,
      menuQue2: false,
      menuCorp: false,
      menuPaq: false,
      menuHerr: false,
      menuConsul: false,
      dialogProgress: true,
      dialogEnlaces: false,
      dialogQue: false,
      dialogPers: false,
      dialogPaq: false,
      dialogVit: false,
      dialogHelp: false,
      dialogHelpDash: false,
      dialogSubscrips: false,
      dialogContactanos: false,
      notificaciones: [],
      show: false,
      show2: false,
      dialogQR: false,
      componentKey: 0,
      menu: true,
      menuHome: false,
      dialog: false,
      titulo1: 'nuVin',
      loading: false,
      items: [],
      search: null,
      select: null,
      usuariosDB: [],
      itemsFunciones: [
        {funcion: 'mi nuVin', descripcion: 'Única área de tu cuenta nuVin en donde el público en general puede ver los datos, foto y productos de tu negocio.'},
        // {funcion: 'Ofi-nuVin', descripcion: 'Sección privada, es un oficina virtual en donde sólo tú y los Colaboradores que autorices pueden accesar. Es la zona principal en donde se configura, se trabaja, ingresan los datos de clientes, productos, gastos, ingress, etc.'},
        {funcion: 'QR-nuVin', descripcion: 'Al presionar se despliega un código QR para utilizar tu cuneta nuVin como tarjeta de presentación virtual; cuando una tercera persona escanée el QR le arrojará la dirección detu cuenta nuVin'},
        {funcion: 'Cerrar sesión', descripcion: 'Cierra cesión de tu Cuenta nuVin'},
        // {funcion: 'Soporte', descripcion: 'Contiene el mail para contactar al personal de nuVin y realizar cualquier aclaración o duda.'},
      ],
      clientedb: {
          nombre: '',          
          email: '',
          tipo: '',
          numusuarios: '',
          descripcion: '',
      },
      defaultItem: {
          nombre: '',          
          email: '',
          tipo: '',
          numusuarios: '',
          descripcion: '',
      },
    }    
  },
  validations: {
      clientedb: {
          nombre: {
              required,
              maxLength: maxLength(45),
          },
          email: {
              required,
              maxLength: maxLength(55),
          },
          numusuarios: {
              required,
              // maxLength: maxLength(80),
          },
          tipo: {
              required,
              maxLength: maxLength(145),
          },
          descripcion: {
              required,
              maxLength: maxLength(555),
          },
      }
  },
  async created () {
    // this.resetReloadTimer();
    if (this.usuario) {
    this.consultarEnlaces()
    }
    this.consultarUsuariosDB()
    if (this.usuario) {
      this.consultarNotificaciones()
    }
    if (this.usuario != null) {
      await this.consultarUsuarioHost()
      if (this.usuario && this.usuarioHost) {
        this.dialogProgress = false;
      }
    }
  },
  watch: {
    usuario: {
      handler() {
        this.consultarExtraAppsCompradas();
      },
      immediate: true
    },
    extraAppsUser: {
      handler(newVal) {
        const appActivaObj = newVal.find(app => app.active === true);
        this.appActiva = appActivaObj ? appActivaObj.app : '';
      },
      deep: true // Observar cambios en objetos anidados
    },
    isPublic() {
      this.showDrawer = !this.isPublic;
    },
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
    '$route' () {
      if (this.usuario != null) {
        this.consultarUsuarioHost()
      }
    },
  },
  computed: {
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['usuarioHost']),
    ...mapState('usuarioHost', ['perfilAuth']),
    ...mapState(['notificacion', 'ocupado']),
    isPublic() {
      return this.$route.meta.isPublic !== undefined ? this.$route.meta.isPublic : false;
    },
    isDesktopAndNotRootRoute() {
      return window.innerWidth >= 640 && this.$route.path !== '/';
    },
    paletaColores() {
      if (this.usuarioHost && this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
          return this.usuarioHost.designColors;
      } else {
          const colores = ['#E040FB',];
          const colores2 = ['#616161',];
          const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
          const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
          return {
          primero: colorAleatorio,
          segundo: colorAleatorio2,
          tercero: colorAleatorio,
          cuarto: colorAleatorio
          };
      }
    },
    rutaDeClienteVisitante() {
      // Obtenemos la ruta actual
      const rutaActual = this.$route.path;
      
      // Comprobamos si this.usuario no es null y no contiene el nombre del usuario en sesión
      let noContieneUserName = this.usuario ? !rutaActual.includes(this.usuario.userName) : true;

      // Devolvemos true si la ruta contiene "/persona-especial", "/infoliga" o no contiene el nombre del usuario en sesión
      return (rutaActual.includes("/persona-especial") || rutaActual.includes("/infoliga") || rutaActual.includes("/clientes")) && noContieneUserName;
      
    },
    countForNavBar() {
      var str = this.$route.path;
      var ch = '/';      
      return [...str].filter(x => x === ch).length;
    },
    erroresCamposCotiz() {
        let errores = []
        if (!this.$v.clientedb.$dirty) { return errores }  
        if (!this.$v.clientedb.required) { errores.push('Ingresa la información solicitada.') }      
        if (!this.$v.clientedb.maxLength) { errores.push('Ingresa máximo los caracteres que se señalan debajo del campo.') }
        return errores
    },
    usuariosubstr() {
      if (this.usuario && this.usuario.userName) {
        return this.usuario.userName.charAt(0);
      }
      return '';
    },
  },
  mounted() {
    this.$children.forEach((child) => {
      child.$on('carga-finalizada', this.actualizarDialogProgress);
    });
    if (this.usuario) {
      this.resetReloadTimer()
    }
  },
  methods: {
    ...mapMutations(['ocultarNotificacion', 'mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
    ...mapActions('sesion', ['cerrarSesion']),
    ...mapActions('usuarioHost', ['borrarActualUsuarioHost']),
    ...mapActions('usuarioHost', ['actualizarUsuarioHost']),
    async calcSumUdsNCD() {
      await dbL.collection('NumUdsNCD').delete()
      await dbL.collection('NumUdsNCD').set([])
      this.sumaUnidadesNCD = this.extraAppsUser.reduce((total, appData) => {
        return total + appData.accesosCols.reduce((acc, acceso) => {
          if (typeof acceso.unidadesNCD === 'number') {
            return acc + acceso.unidadesNCD;
          } else {
            return acc;
          }
        }, 0);
      }, 0);
      await dbL.collection('NumUdsNCD').add({numUdsNCD: this.sumaUnidadesNCD,})
    },
    agregarEnlace(enl) {
      this.enlaces.push(enl); // Agregar el objeto enl al array enlaces
    },
    resetReloadTimer() {
      let reloadTimer;
      const reloadPage = () => {
        // window.location.reload(true); // Recarga la página forzadamente
        this.$router.push({name: 'Entrada', params: {userName: this.usuario.userName}})
      };

      const startReloadTimer = () => {
        clearTimeout(reloadTimer); // Reinicia el temporizador si ya estaba en funcionamiento
        reloadTimer = setTimeout(reloadPage, 60 * 60 * 1000); // Recarga después de 1 hora de inactividad (1 hora = 60 minutos * 60 segundos * 1000 milisegundos)        
      };

      document.addEventListener('mousemove', startReloadTimer);
    },
    agregarAppExtra(nuevoElemento) {
      this.extraAppsUser.push(nuevoElemento);
    },
    actualizarDialogProgress(valor) {
      this.dialogProgress = valor;
    },
    async consultarEnlaces() {
      

      try {
        let userNameParametro = this.usuario.userName.toLowerCase();
        // Supongamos que el problema está en la variable 'enlace'
        if (!this.usuario.userName || typeof this.usuario.userName !== 'string') {
          throw new Error('Enlace no válido');
        }
        const userNameDocRef = doc(collection(db, 'userNames'), userNameParametro);
        const userNameDocSnap = await getDoc(userNameDocRef);
        
        if (userNameDocSnap.exists()) {
          const userName = userNameDocSnap.data();
          
          // Obtener la colección de enlaces del usuario
          const enlacesCollectionRef = collection(db, 'usuarios', userName.uid, 'enlacesnuvin');
          const resultadoSnap = await getDocs(enlacesCollectionRef);
          
          resultadoSnap.forEach(docSnap => {
            const colsdb = docSnap.data();
            this.enlaces.push(colsdb);
          });
        } else {
          console.log('No such document!');
        }
      }
      catch (error) {                    
        console.error('Error al consultar enlaces:', error.message);    
        this.mostrarError('Ocurrió un error consultando los Colaboradores.')                
      }
    },
    async saveEnlace() {
      let userNameParametro = this.usuario.userName.toLowerCase();
      
      // Obtener el documento del nombre de usuario
      const userNameDocRef = doc(collection(db, 'userNames'), userNameParametro);
      const userNameDocSnap = await getDoc(userNameDocRef);

      if (userNameDocSnap.exists()) {
        let user = userNameDocSnap.data();
        
        let enlid = uuidv4();
        let enl = {
          enlid,
          enlace: this.usuarioHost.userName,
          tipoEnlace: ''
        };

        // Crear la referencia al documento en la colección de enlaces del usuario
        const enlaceDocRef = doc(collection(db, 'usuarios', user.uid, 'enlacesnuvin'), enl.enlid);
        
        try {
          await setDoc(enlaceDocRef, enl);
          this.enlaces.push(enl);
          this.mostrarExito('Haz guardado el Enlace');
        } catch (error) {
          this.mostrarError('Ocurrió un error registrando el Enlace');
        }
      }
    },
    irEnlace(item) {
      this.$router.push({name: 'Entrada', params: {userName: item}})
                  .then(() => {
                    // Realizar el reload después de que la navegación haya sido completada
                    window.location.reload();
                  });
    },
    async consultarCol() {
          
      if (this.perfilAuth == true) {
        this.colaborador = this.usuarioHost
        
      } else if (this.perfilAuth == false) {
      
        let userNameParametro = this.$route.params.userName.toLowerCase();
  
        try {
          // Obtener el documento del nombre de usuario
          const userNameDocRef = doc(collection(db, 'userNames'), userNameParametro);
          const userNameDocSnap = await getDoc(userNameDocRef);

          if (userNameDocSnap.exists()) {
            let userHost = userNameDocSnap.data();

            // Obtener los documentos de la colección 'colaboradores' del usuario
            const colaboradoresCollectionRef = collection(db, 'usuarios', userHost.uid, 'colaboradores');
            const colaboradoresSnapshot = await getDocs(colaboradoresCollectionRef);

            this.colaboradores = [];
            colaboradoresSnapshot.forEach(doc => {
              let col = doc.data();
              this.colaboradores.push(col);
            });
          }
        } catch (error) {                
          // this.mostrarError('Ocurrió un error consultando el Colaborador.')                
        }

        if (this.colaboradores.length > 0) {
          let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
          if (typeof colaborador === 'object') {
            let convertINarray = Object.values(colaborador.estatusAccesos)
            delete colaborador.estatusAccesos
            colaborador.estatusAccesos = convertINarray
            this.colaborador = colaborador  
          } else {
            this.colaborador = this.usuarioHost
            // //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
          }
        } else {
          // // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a la ofi-nuVin de'+' '+`${this.usuarioHost.userName}`+', intenta desde su área pública....')
          this.$router.push({ name: 'Entrada' })
        }
      }
    },
    toggleItem() {
      // if (this.$route.name !== item.componente) {
        this.menu = !this.menu;
      // }
    },
    async movingSameHost(item) {
      await dbL.collection('fromSameHost').set([])
      await dbL.collection('fromSameHost').add({sameHost: true, userHost: this.$route.params.userName.toLowerCase()})
      this.$router.push({ name: item })
      // this.mini = true
    },
    async movingSameHostAoficina() {
      if (this.$route.name != 'oficina') {
        await dbL.collection('fromOfi').set([])
        await dbL.collection('fromOfi').add({sameHost: true, userHost: this.$route.params.userName.toLowerCase()})
        this.$router.push({ name: 'oficina' }) 
      }
    },
    async consultarExtraAppsCompradas() {
        
      if (this.usuario != null) {
        this.extraAppsUser = [];
      
        // Asegúrate de que userName existe antes de llamar a toLowerCase()
        let userNameParametro = await this.$route.params.userName
        ? await this.$route.params.userName.toLowerCase()
        : '';
        
        try {
          const userNameDocRef = doc(collection(db, 'userNames'), userNameParametro);
          const userNameDocSnap = await getDoc(userNameDocRef);

          if (userNameDocSnap.exists()) {
            const userName = userNameDocSnap.data();
            const comprasAppExtraCollectionRef = collection(db, 'usuarios', userName.uid, 'comprasAppExtra');
            const comprasAppExtraSnapshot = await getDocs(comprasAppExtraCollectionRef);

            comprasAppExtraSnapshot.forEach(doc => {
              const extraapps = doc.data();
              this.extraAppsUser.push(extraapps);
            });
          }
        } catch (error) {                
            // this.mostrarError('Ocurrió un error consultando los extra.')              
        }
      }
    },
    async accesoCliente () {
      this.$router.push({ name: 'login' })
    },
    async solCotiz() {
      const userNameDocRef = doc(collection(db, 'userNames'), 'soportenuvit');
      const userNameDocSnap = await getDoc(userNameDocRef);

      this.mostrarOcupado({ titulo: 'Enviando Solicitud de Cotización', mensaje: 'Cargando datos...' });

      if (userNameDocSnap.exists()) {
        const userName = userNameDocSnap.data();

        const cliid = uuidv4();

        const clientedb = {
          cliid,
          nombreCliente: this.clientedb.nombre, 
          email: this.clientedb.email,
          tipoServApp: this.clientedb.tipo,
          numusuarios: this.clientedb.numusuarios,
          descripcionApp: this.clientedb.descripcion,
          date: new Date().toISOString().substr(0, 7),
        };

        try {
          const clienteDocRef = doc(collection(db, 'usuarios', userName.uid, 'clientes'), clientedb.cliid);
          await setDoc(clienteDocRef, clientedb);
          this.mostrarExito('Se envió la solicitud exitosamente. En Breve el equipo de nuVin te contactará.');
        } catch (error) {
          this.mostrarError('Ocurrió un error enviando la solicitud, tal vez sea la señal de internet, por favor verifica y reintenta enviarla.');
        } finally {
          this.ocultarOcupado();
        }
        this.closeSolCotiz();
      }
    },
    closeSolCotiz () {
        this.dialogVit = false
        setTimeout(() => {
        this.clientedb = Object.assign({}, this.defaultItem)
        }, 300)
    },
    avoidDuplicatedNav() {
      if (this.$route.path !== '/web') {
      window.open(this.$router.resolve("/web").href, "_blank");
      } else {
        // No hacer nada
      }

      // if (this.$route.path != '/web') {
      //     this.$router.push("/web");
      //     dbL.delete()
      //     this.borrarActualUsuarioHost()
      // }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    irSearch () {
      dbL.delete()
      this.$router.push({ path: `/${this.select}`}),
      this.dialog = false, 
      this.select=[],
      this.forceRerender()
    },
    async irUser () {
      // this.$router.push({ path: `/${this.usuario.userName}`})
      if (this.usuario && this.usuario.userName && this.usuario.userName !== this.$route.params.userName) {
        
        this.$router.push({name: 'Entrada', params: {userName: this.usuario.userName}})
        .then(() => {
          // Realizar el reload después de que la navegación haya sido completada
          window.location.reload();
        });
      }
    },
    async consultarUsuariosDB() {         
      try {
        const userNamesCollectionRef = collection(db, 'userNames');
        const querySnapshot = await getDocs(userNamesCollectionRef);

        querySnapshot.forEach(doc => {
          const usersdb = doc.data();
          this.usuariosDB.push(usersdb.userName);
        });
      } catch (error) {
        this.mostrarError('Ocurrió un error consultando los usuarios.');
      }
    },
    async consultarUsuarioHost() {
      
      if (this.$route.path != '/inicio-cuenta' && this.$route.path != '/registro/personal' &&
          this.$route.path != '/') {
            
          this.actualizarUsuarioHost()
      } 

      if (!this.$route.path.includes('/clientes') && this.perfilAuth) {        
        this.consultarCol()  
      }

      if ( !this.perfilAuth ) {
        this.consultarCol() 
      }
      
    },
    querySelections (v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.usuariosDB.filter(e => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500)      
    },
    salir() {
      dbL.delete()
      this.borrarActualUsuarioHost()
      this.cerrarSesion()
      this.menu = false
      this.$router.push({ name: 'Home' })
    },
    consultarNotificaciones() {
      const userDocRef = doc(db, 'userNames', this.usuario.userName.toLowerCase());

      onSnapshot(userDocRef, (docSnapshot) => {
        const datauser = docSnapshot.data();
        if (datauser && datauser.notifNew === true) {
          this.show = true;
        }
      });
    },
    async clearNtf() {
      try {
        const userDocRef = doc(db, 'userNames', this.usuario.userName.toLowerCase());
        await updateDoc(userDocRef, {
          notifNew: false
        });
        this.show = false;
        this.$router.push({ name: 'notificaciones', params: { userName: this.usuario.userName } });
      } catch (error) {
        this.mostrarError('Ocurrió un error al actualizar las notificaciones.');
      }
    }
  }
}
</script>

<style>
  /* @import url('https://fonts.googleapis.com/css?family=Maiden+Orange&display=swap');   */
  .logoFont {
    font-family: 'Comfortaa', cursive;
  }

  @media (min-width: 640px) {
    .margenizq {
      padding-left: 250px;
    }
  }
  
  .v-navigation-drawer.v-navigation-drawer--fixed {
    margin-left: 0 !important;
  }

  .square {
    width: 40px;
    height: 40px;
    /* background-color: blue; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>