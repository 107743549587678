<template>
  <v-dialog
    v-model="dialogSubscrips"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-list-tile-action>
        <div class="square" :style="{'background-color': paletaColores.segundo}"><v-icon dark>mdi-check-decagram-outline</v-icon></div>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title v-on="on" @click="consultarSubscrisUser()" class="mt-2" dark>Suscripciones</v-list-tile-title>
      </v-list-tile-content>
    </template>

    <v-card>
    <v-card-title class="headline">
      Estatus de tus Suscripciones
    </v-card-title>

    <v-card-text>
      <div v-for="item in subscripcionesdb" :key="item.index">
        <span>Subscripción ID: {{ item.dataFromPayPal.subscriptionID }}</span><br>
        <span>Fecha inicio: {{ item.fechaPagoSubscri }}</span><br>
        <v-btn v-if="mostrarBotonDevolucion[index]" small class="caption text-lowercase" @click="solicitarDevolucion(item.dataFromPayPal.subscriptionID)">solicitar devolución</v-btn><br>
        <v-btn small class="caption text-lowercase" @click="cancelarSubscripcion(item.dataFromPayPal.subscriptionID)">cancelar suscripción</v-btn>
      </div><br>
        <!-- Estamos trabajando en la presentación detallada de tus subscripciones. 
        Por el momento comunícate con nostros para cualquier duda, cambio de plan, agregar funciones o 
        cancelaciones (será inmediata, sin solicitar explicación). Gracias.<br><br> -->
        <span>Para cualquier aclaración o duda:</span><br>
        <span><span class="principal--text font-weight-bold">email:</span>  nubesdebits@gmail.com</span><br>
        <span><span class="principal--text font-weight-bold">móvil-whatsapp:</span>  +529996470286</span><br>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn flat small dark class="principal--text caption" @click="dialogSubscrips = false">Cerrar</v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { db } from '@/firebase'
import axios from 'axios';

export default {
  data () {
    return {
      dialogSubscrips: false,
      subscripcionesdb: [],
    }
  },

  mounted() {
    this.paypalClientId= process.env.VUE_APP_paypalClientId
  },

  computed: {
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['usuarioHost']),
    ...mapState('usuarioHost', ['perfilAuth']),
    paletaColores() {
  if (this.usuarioHost && this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
    return this.usuarioHost.designColors;
  } else {
    return {
      primero: 'principal',
      segundo: 'secondary',
    };
  }
},

    mostrarBotonDevolucion() {
      const fechaActual = new Date();
      return this.items.map(item => {
        const fechaPagoSubscri = new Date(item.fechaPagoSubscri);
        const diferenciaEnDias = Math.floor(
          (fechaActual - fechaPagoSubscri) / (1000 * 60 * 60 * 24)
        );
        return diferenciaEnDias <= 14;
      });
    }
  },

  methods: {
    ...mapMutations(['ocultarNotificacion', 'mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),    
    async consultarSubscrisUser () {
      
      try {
        let docs = await db.collectionGroup('pagos-suscripciones')
                            .where('userName', '==', this.usuario.userName)                                         
                            .get()
                            
        docs.forEach(doc => {
          let ss = doc.data()
            this.subscripcionesdb.push(ss)
        })          
      }
      catch (error) {
        console.error('Error en la consulta:', error);
        this.mostrarError('Ocurrió un error consultando las suscripciones. Inténtelo más tarde')
      }
    },
    solicitarDevolucion(idsub) {
      // Aquí debes comunicarte con la API de PayPal para solicitar la devolución
      // Puedes usar la biblioteca axios u otra para hacer una solicitud HTTP a PayPal
      // Proporciona los detalles necesarios, como el ID de la transacción que deseas reembolsar

      const transactionId = idsub //'ID_DE_TRANSACCION_A_DEVOLVER'; // Reemplaza con el ID de la transacción real

      // Ejemplo de solicitud de devolución con axios
      axios.post('https://api.paypal.com/v2/payments/captures/' + transactionId + '/refund', {
        // Aquí proporciona los detalles de la solicitud de devolución si es necesario
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.paypalClientId //'Bearer TU_TOKEN_DE_API_PAYPAL' // Reemplaza con tu token de API de PayPal
        }
      })
      .then(response => {
        // Procesa la respuesta de PayPal aquí
        console.log('Solicitud de devolución exitosa', response);

        // Puedes mostrar un mensaje de éxito al usuario si lo deseas
        this.mostrarExito('Solicitud de devolución exitosa');
      })
      .catch(error => {
        // Maneja errores de la solicitud de devolución aquí
        console.error('Error al solicitar la devolución', error);

        // Puedes mostrar un mensaje de error al usuario si lo deseas
        this.mostrarError('Error al solicitar la devolución');
      });
    },
    cancelarSubscripcion(idsub) {
      // Aquí debes comunicarte con la API de PayPal para cancelar la suscripción
      // Proporciona los detalles necesarios, como el ID de la suscripción que deseas cancelar

      const subscriptionId = idsub //'ID_DE_SUSCRIPCION_A_CANCELAR'; // Reemplaza con el ID de la suscripción real

      // Ejemplo de solicitud de cancelación con axios
      axios.post('https://api.paypal.com/v1/billing/subscriptions/' + subscriptionId + '/cancel', {
        // Aquí puedes proporcionar detalles adicionales de la cancelación si es necesario
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.paypalClientId //'Bearer TU_TOKEN_DE_API_PAYPAL' // Reemplaza con tu token de API de PayPal
        }
      })
      .then(response => {
        // Procesa la respuesta de PayPal aquí
        console.log('Cancelación de suscripción exitosa', response);

        // Puedes mostrar un mensaje de éxito al usuario si lo deseas
        this.mostrarExito('Cancelación de suscripción exitosa');
      })
      .catch(error => {
        // Maneja errores de la cancelación aquí
        console.error('Error al cancelar la suscripción', error);

        // Puedes mostrar un mensaje de error al usuario si lo deseas
        this.mostrarError('Error al cancelar la suscripción');
      });
    },

  },
}
</script>

<style >
  .square {
    width: 40px;
    height: 40px;
    /* background-color: blue; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>