<template>
    <v-layout justify-center align-center wrap >
      <v-flex xs12 v-if="dialogProgress == false">
        <v-card class="elevation=5 ma-3 mt-5 py-3 custom-card">

          <v-layout>
            <v-flex xs2 class="text-xs-left ml-4">
                <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-calendar-text</v-icon>                
            </v-flex>
            <v-flex xs8 class="text-xs-left">
                <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Gastos Programados</p></h3>
                <h3 class="hidden-sm-and-up"><p class="text-xs-center headline mt-3">Gastos Programados</p></h3>
            </v-flex>        
            <v-flex xs2 class="text-xs-right">
                <v-dialog
                    v-model="dialogHelp"
                    width="500"
                >
                    <template v-slot:activator="{ on }">
                    <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                    </template>
                    <v-card>
                    <v-card-title class="headline">
                        Cómo funciona esta sección...
                    </v-card-title>

                    <v-card-text>
                        Esta área es una cómoda herramienta para que te lleve una agenda de tus gastos recurrentes
                        y así evites el vencimiento de tus servicios o cargos de intereses.
                        <ul>
                            <li>Da click en el botón Programar Gasto y llena los campos.
                            </li>
                            <li>
                                Cuando llegue la fecha que asignaste, aparecerá un aviso de Vencimiento, en esta Sección y 
                                en la de Centro de Control. Y cuando hayas realizado este Gasto, sólo dirigete a el botón Editar
                                y desactiva el switch que aparece en la parte inferiror, da click en Guardar y de manera automática,
                                desaparecerá de la Sección de Gastos Programados y se registrará en GASTOS; o si lo requieres,
                                sólo cambia de fecha de vencimiento.
                            </li>
                        </ul> 
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>

          <v-layout>
            <v-flex xs12 class="text-xs-center" v-if="gastoProgrToday">
                <span class="red--text text--accent-4">Hay Gastos Programados para Pago el día de Hoy o Vencidos</span>
            </v-flex>
          </v-layout> 
          <v-flex xs12>            
              <v-card class="elevation=5 ma-3" >
                  <v-toolbar flat :style="{'background-color': paletaColores.tercero}">
                      <v-layout wrap>
                          <!-- INGRESAR GASTO DATOS -->
                          <v-flex xs12 sm6 >
                              <v-dialog v-model="dialog">
                                  <template v-slot:activator="{ on }">
                                      <v-btn class="hidden-xs-only text-none elevation-13" :style="{'background-color': paletaColores.primero}" dark mb-2 v-on="on" @click="consultarGastos"><span>Programar Gasto</span></v-btn>
                                      <v-btn icon top right absolute small fab dark :style="{'background-color': paletaColores.primero}" class="hidden-sm-and-up mt-2" v-on="on" @click="consultarGastos">
                                          <v-icon class="pt-2 mt-1">add</v-icon>   
                                      </v-btn>
                                  </template>
                                  <v-card>
                                      <v-card-title>
                                          <span class="headline">{{ formTitle }}</span>
                                      </v-card-title>
                                          <v-card-text>
                                              <v-container grid-list-md>
                                              <v-layout wrap >
                                                  <v-flex xs12 sm6 md4>
                                                      <v-text-field v-model="gasto.proveedor" label="Proveedor" @blur="$v.gasto.proveedor.$touch()" :error-messages="erroresProveedor"></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs12 sm6 md4>
                                                      <v-text-field type="number" v-model="gasto.monto" label="Monto" @blur="$v.gasto.monto.$touch()" :error-messages="erroresMonto"></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs12 sm6 md4>
                                                    <v-select @blur="$v.gasto.tipoGasto.$touch()" :error-messages="erroresTipoGasto"
                                                    v-model="gasto.tipoGasto"
                                                    :items="etiquetasGasto"
                                                    label="Escoge una etiqueta de gasto"
                                                    ></v-select>
                                                    <v-select v-if="gasto.tipoGasto == 'abonoAcrédito/préstamo' && editedIndex == -1"
                                                    v-model="gasto.etiqCyP"
                                                    :items="etiquetasCreditoPrestamo"
                                                    label="Etiqueta de la Fuente (etiqueta-crédito/préstamo)"
                                                    ></v-select>                      
                                                  </v-flex>
                                                  <v-flex xs12 sm6 md4>
                                                      <v-select @blur="$v.gasto.tipoDinero.$touch()" :error-messages="erroresTipoDinero"
                                                      v-model="gasto.tipoDinero" :items="saldoTotPorDinero"
                                                      label="Con qué se realizará el Pago (etiqueta-Dinero)"
                                                      :item-text="itemText"
                                                      ></v-select>                                        
                                                  </v-flex>
                                                      <!-- :rules="rules" -->
                                                  <v-flex xs12 sm6 md4>
                                                    <v-menu
                                                        ref="menu1"
                                                        v-model="menu1"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        lazy
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        max-width="290px"
                                                        min-width="290px"
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                            v-model="dateFormatted"
                                                            label="Fecha"
                                                            persistent-hint
                                                            prepend-icon="event"
                                                            @blur="updateFechaFactura"
                                                            v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="gasto.fechaFactura" no-title @input="menu1 = false"></v-date-picker>
                                                    </v-menu>
                                                  </v-flex>
                                                  <v-flex xs12 sm6 md4>
                                                    <v-text-field v-model="gasto.numFactura" label="Núm. de factura (opcional)" counter="20" @blur="$v.gasto.numFactura.$touch()" :error-messages="erroresNumFactura"></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs12 sm6>
                                                      <v-text-field
                                                          v-model="gasto.notas"
                                                          counter="25"
                                                          label="Notas (opcional)"
                                                          outline
                                                          @blur="$v.gasto.numFactura.$touch()" :error-messages="erroresNumFactura"
                                                      ></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs12 sm6 v-if="editedIndex > -1">
                                                    <v-switch
                                                    v-model="gasto.programado"
                                                    :label="`Desactiva este switch para registrar este Gasto como REALIZADO y eliminar de Gastos Programados, posteriormente da click en Guardar.`"
                                                    ></v-switch>
                                                  </v-flex>
                                              </v-layout>
                                              </v-container>
                                          </v-card-text>
                                      <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn color="primary" flat @click="close">Cancelar</v-btn>
                                          <v-btn color="primary" :depressed="$v.gasto.$invalid" :disabled="$v.gasto.$invalid" flat @click="guardarGastoProgr">Guardar</v-btn>
                                      </v-card-actions>
                                  </v-card>
                              </v-dialog>
                          </v-flex>
                      </v-layout>
                  </v-toolbar>            
                  <v-data-table
                      :headers="headers"
                      :items="gastos"
                      :search="search"
                      class="elevation-1"
                      >
                      <template v-slot:items="props">
                          <td><strong>{{ props.item.proveedor }}</strong></td>
                          <td class="justify-center align-center layout">       
                              <!-- icono dialog de book para ver gastos-->
                              <v-dialog v-model="dialog2" persistent max-width="600px">                            
                                  <template v-slot:activator="{ on }">                                
                                      <v-icon
                                          @click="abrirGasto(props.item)"
                                          dark v-on="on"
                                          color="blue"
                                          class="mr-2"
                                          small
                                          >
                                          book
                                      </v-icon>
                                  </template>
                                  <v-card  
                                      v-if="gasto"
                                      :key= "gasto.gaid"
                                      :gastoPartidas="gasto.gastoPartidas"
                                      >
                                      <v-toolbar flat color="white">
                                          <h3>Partidas de:</h3><br>
                                          <v-toolbar-title >
                                              <h3 class="caption"> {{gasto.proveedor}} </h3>
                                              <h6 class="caption">Número de factura:</h6><h3 class="caption font-weight-medium"> {{gasto.numFactura}} </h3>
                                          </v-toolbar-title>
                                          <v-divider
                                              class="mx-2"
                                              inset
                                              vertical
                                          ></v-divider>
                                          <v-spacer></v-spacer>
                                          <v-dialog v-model="dialog3" max-width="500px" persistent>
                                              <template v-slot:activator="{ on }">
                                                      <!-- @click="agregarPedido" -->
                                                  <v-btn class="hidden-xs-only" mb-2 color="primary" dark v-on="on" >Nueva partida</v-btn>
                                                  <v-btn  class="hidden-sm-and-up pt-2" small fab color="primary" outline v-on="on">
                                                      <v-icon>add</v-icon>   
                                                  </v-btn>
                                              </template>
                                              <v-card>
                                              <v-card-title>
                                                  <span class="headline">{{ formTitlePartidas }}</span>
                                              </v-card-title>
                                              <v-card-text >
                                                  <v-container grid-list-md>
                                                      <v-layout wrap>
                                                          <!-- <multiselect 
                                                              v-model="partida.producto" 
                                                              :options="partidaId" 
                                                              :custom-label="nameWithLang" 
                                                              placeholder="Seleccionar" 
                                                              label="name" 
                                                              track-by="nombre">                                                
                                                          </multiselect> -->
                                                          <v-flex xs12 sm6 md4>
                                                              <v-text-field v-model="partida.nombreProducto" label="Producto" @blur="$v.partida.nombreProducto.$touch()" :error-messages="erroresProducto"></v-text-field>
                                                              <v-text-field v-model="partida.unidad" label="Unidad" @blur="$v.partida.unidad.$touch()" :error-messages="erroresUnidad"></v-text-field>
                                                              <v-text-field v-model="partida.precio" label="Precio" @blur="$v.partida.precio.$touch()" :error-messages="erroresPrecio" type="number"></v-text-field>
                                                              <v-text-field v-model="partida.cantidad" label="Cantidad" class="number" @blur="$v.partida.cantidad.$touch()" :error-messages="erroresCantidad" type="number" min="1" max="100"></v-text-field>
                                                          </v-flex>                                                                  
                                                          <!-- <v-flex xs12 sm6>
                                                              <v-select
                                                              v-model="partida.cantidad"
                                                              :items="['1', '2', '3', '4', '5']"
                                                              label="Cantidad"
                                                              required
                                                              ></v-select>                                                
                                                          </v-flex>                                 -->
                                                      </v-layout>
                                                  </v-container>
                                                  <small>*Campo requerido</small>
                                              </v-card-text>
                                              <v-card-actions>
                                                  <v-spacer></v-spacer>
                                                  <v-btn color="primary" flat @click="closePartida">Cerrar</v-btn>
                                                  <v-btn color="primary" flat :depressed="$v.partida.$invalid" :disabled="$v.partida.$invalid" @click="savePartida">Guardar</v-btn>
                                              </v-card-actions>
                                              </v-card>
                                          </v-dialog>
                                          <v-btn outline class="hidden-sm-and-up pt-2" small fab dark color="primary" @click="dialog2 = false">
                                              <v-icon dark>close</v-icon>   
                                          </v-btn>
                                          <v-btn outline class="hidden-xs-only" color="primary" flat @click="dialog2 = false">Cerrar</v-btn>
                                      </v-toolbar>
                                      <v-data-table 
                                          :headers="headers2"
                                          :items="gastoPartidas"
                                          class="elevation-1"                    
                                          >
                                          <template v-slot:items="props">
                                              <td><strong>{{ props.item.producto }}</strong></td>
                                              <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                              <td class="text-xs-center">{{ props.item.precio }}</td>
                                              <td class="text-xs-right">{{ props.item.unidad }}</td>                                                                                        
                                              <!-- <td class="text-xs-right">{{ props.item.subtotal }}</td> -->
                                              <td class="text-xs-center number">$ {{ props.item.subtotal }}</td>
                                              <td class="text-xs-center">
                                                  <v-icon
                                                      small color="green"
                                                      class="mr-2"
                                                      @click="editItemPartida(props.item)"
                                                  >
                                                      edit
                                                  </v-icon>
                                                  <estasSeguro v-if="colaboradorBorrar == true">
                                                      <v-icon color="red" slot="nombrebtn" small> 
                                                        delete
                                                      </v-icon>
                                                      <div slot="elemento">
                                                          <span>esta partida?</span>
                                                      </div>
                                                      <div slot="btnaceptar">
                                                          <v-btn flat @click="deletePartida(props.item)">Ok</v-btn>
                                                      </div>
                                                  </estasSeguro>
                                                  <v-icon
                                                      disabled
                                                      small
                                                      v-if="colaboradorBorrar == false"
                                                  >
                                                      delete
                                                  </v-icon>
                                              </td>                                                          
                                          </template>
                                      </v-data-table>   
                                      <template>
                                          <v-layout row wrap>
                                              <v-flex xs12>
                                                  <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                                      <span class="subheading mr-1">Sub-Total: $ {{ subtotSum }}</span><br>
                                                      <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                                      <span class="subheading mr-1">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                  </v-card>
                                              </v-flex>            
                                              <!-- Ticket para imprimir -->
                                              <!-- <v-layout row justify-center>
                                                  <v-dialog v-model="cardTicket" scrollable max-width="330px">
                                                  <template v-slot:activator="{ on }">
                                                      <v-btn color="primary" dark v-on="on">Mostrar ticket o imprimir?</v-btn>
                                                  </template>
                                                  <v-card>
                                                      <v-card-title>Ticket de Gastos</v-card-title>
                                                      <v-card-actions>
                                                          <v-btn icon color="primary" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                                      </v-card-actions>
                                                      <v-divider></v-divider>
                                                      <v-card-text style="height: 350px;" ref="printTable">                                                    
                                                          <v-flex xs12 sm12>
                                                          <v-card>                                                            
                                                              <v-list two-line>
                                                              <template >                                                                
                                                                  <p class="text-xs-center">
                                                                      <span>{{ contTicket.empresa }}</span><br>
                                                                      <span>{{ contTicket.RFC }}</span><br>
                                                                      <span>{{ contTicket.direccion }}</span><br>
                                                                      <span>{{ contTicket.telefono }}</span><br>
                                                                      <span>{{ contTicket.notapie }}</span>
                                                                  </p>                                                                
                                                                  <v-divider></v-divider>
                                                                      <template>
                                                                      <v-data-table
                                                                          :headers="headers3"
                                                                          :items="gastoPartidas"
                                                                          class="elevation-1"                                                            
                                                                          hide-actions
                                                                      >
                                                                          <template v-slot:items="props">
                                                                          <td>{{ props.item.producto }}</td>
                                                                          <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                                          <td class="text-xs-right">{{ props.item.precio }}</td>                                                            
                                                                          </template>
                                                                      </v-data-table>
                                                                      <v-card class="text-xs-right">
                                                                          <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                                          <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                                          <span class="body-2">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                                      </v-card>
                                                                      </template>
                                                                  <v-divider></v-divider>
                                                                      <span>{{ contTicket.empresa }}</span><br>
                                                                      <span>{{ contTicket.RFC }}</span><br>
                                                                      <span>{{ contTicket.direccion }}</span><br>
                                                                      <span>{{ contTicket.telefono }}</span><br>
                                                                      <span>{{ contTicket.notapie }}</span>
                                                                  </template>
                                                              </v-list>
                                                          </v-card>
                                                          </v-flex>                                                    
                                                      </v-card-text>
                                                      <v-divider></v-divider>                                                
                                                  </v-card>
                                                  </v-dialog>
                                              </v-layout>                                     -->
                                          </v-layout>
                                      </template>                    
                                  </v-card>      
                              </v-dialog>
                              <v-icon
                                  class="mr-2" color="green"
                                  @click="editItem(props.item)" small
                                  >
                                  edit
                              </v-icon>
                              <estasSeguro v-if="colaboradorBorrar == true || perfilAuth == true">
                                  <v-icon
                                      color="red"
                                      slot="nombrebtn"
                                      small                                       
                                      >
                                      delete
                                  </v-icon>
                                  <div slot="elemento">
                                      <span>este concepto de Gasto?</span>
                                  </div>
                                  <div slot="btnaceptar">
                                      <v-btn flat @click="deleteItem(props.item.gaid)">Ok</v-btn>
                                  </div>
                              </estasSeguro>
                              <v-icon
                                  disabled
                                  small
                                  v-else
                              >
                                  delete
                              </v-icon>
                          </td>
                          <td>{{ props.item.numFactura }}</td>
                          <td>{{ props.item.tipoGasto }}</td>
                          <td>{{ props.item.tipoDinero }}</td>
                          <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                          <td>{{ props.item.fechaFactura }}</td>                                                       
                      </template>
                      <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">
                          Your search for "{{ search }}" found no results.
                          </v-alert>
                      </template>
                  </v-data-table>
                  <template>
                      <v-layout row wrap>
                          <v-flex xs12>
                              <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                  <!-- <span class="subheading mr-1">Total: $ {{ totGastos }}</span><br> -->
                                  <!-- <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                  <span class="subheading mr-1">Total: $ {{ totSum }}</span> -->
                              </v-card>
                          </v-flex>
                      </v-layout>
                  </template>
              </v-card>            
          </v-flex> 
        </v-card>   
      </v-flex>

      <template v-else>
        <div class="mt-5 pt-5" >
            <div class="text-xs-center mt-5 pt-5">
            <v-progress-circular
                :size="70"
                :width="7"
                :style="{'color': paletaColores.primero}"
                indeterminate
                class="mt-5 pt-5"
                ></v-progress-circular>
            </div>
        </div>                  
      </template>
    </v-layout>  
  </template>
  
  <script>
  import estasSeguro from '@/components/estasSeguro.vue'
  import { required, minLength, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { updateDoc, writeBatch, deleteDoc, collection, doc, getDoc, getDocs } from 'firebase/firestore';
  import { db } from '@/firebase'
  import { v4 as uuidv4 } from 'uuid';
  import Localbase from 'localbase'
  
  let dbL = new Localbase('db')
  
  export default {
      components: { estasSeguro },
      data: () => ({
          // rules: [v => v.length <= 25 || 'Max 25 characters'],
          // colaboradorEnviado: [],
          dateFormatted: null,
          menu1: false,
          colaborador: '',
          colaboradores: [],
          // sameHost: false,
          // sameHostName: '',
          contTicket: '',
          rowsPerPageItems: [4, 8, 12],
          pagination: {
              rowsPerPage: 4
          },
          dialog: false,
          dialog2: false,
          dialog3: false,
          dialogProgress: true,
          dialogHelp: false,
          cardTicket: false,
          meseroUser: [],
          partidaId: [],
          productoid: {
              name: 'Seleccionar',
              nombre: '',
              unidad: '',
              precio: 0,
              grupo: ''
          },
          gastosData: [],
          fechaSeleccionada: null, // Variable para almacenar la fecha seleccionada en el date picker
          mostrarDatePicker: false, // Variable para controlar la visibilidad del date picker
          gastos: [],
          gasto: {
              proveedor: '',
              autor: {
                  userName: ''
              },
              fechaMili: '',
              fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
              fechaFactura: null,
              etiqCyP: null,
              tipoGasto: '',
              tipoDinero: '',
              numFactura: '',
              monto: '',
              gaid: '',
              notas: '',            
              gastoPartidas: [''],
              programado: ''
          },
          etiquetasDinero: ['Efectivo', 'Banco'],
          etiquetasGasto: [],
          etiquetasCreditoPrestamo: [],
          sumGastos: '',
          editedIndex: -1,
          editedIndexPartida: -1,
          defaultItem: {
            proveedor: '',
              autor: {
                  userName: ''
              },
              fechaMili: '',
              fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
              fechaFactura: null,
              etiqCyP: null,
              tipoGasto: '',
              tipoDinero: '',
              numFactura: '',
              monto: '',
              gaid: '',
              notas: '',            
              gastoPartidas: [''],
              programado: ''
          },
          defaultItemPartida: {
              nombreProducto: '',
              unidad: '',
              precio: '',
              subtotal: 0,
              cantidad: '',
          },    
          gastoPartidas: [],
          partida: {
              nombreProducto: '',
              unidad: '',
              subtotal: 0,
              cantidad: '',
              precio: '',
              ptdaId: '',
              proveedor: '',
              gastogaId: '',
              hora: '',
              autor: {
                  colid: '',
                  userName: '',
                  // uid: ''    
              },
          },
          search: '',  
          selectComanda: null,
          today: '',
          daysPast: '',
          headers: [
            {
              text: 'Proveedor',
              align: 'center',
              value: 'proveedor'
            },
            { text: 'Acciones', value: '', sortable: false, align: 'center' },
            { text: '#Factura', value: 'numFactura', },
            { text: 'Tipo de gasto', value: 'tipoGasto', },
            { text: 'Recurso para Pago', value: 'tipoDinero', },
            { text: 'Monto', value: 'monto', },
            { text: 'Fecha Programada de Pago', value: 'fechaFactura', }
          ],              
          headers2: [
            {
              text: 'Producto',
              align: 'left',
              value: 'producto'
            },
            { text: 'Cant', value: 'cantidad', align: 'center', },
            { text: '$Precio', value: 'precio' },
            { text: 'Unidad', value: 'unidad', align: 'center', },
            { text: 'Sub-Total', value: 'subtotal' },
            { text: 'Acciones', value: '', align: 'center' },
          ], 
          headers3: [ //ticket
            {
              text: 'Producto',
              align: 'left',
              value: 'producto',
            },
            { text: 'Cantidad', value: 'cantidad', sortable: false, },
            { text: '$Precio', value: 'precio', sortable: false, },
          ],
      }),
      validations() {
        const maxSaldo = this.getSaldoFromTipoDinero(this.gasto.tipoDinero);
        return {
          gasto: {
              proveedor: {
                  required,
                  minLength: minLength(3),
                  maxLength: maxLength(20),
              },
              numFactura: {
                  maxLength: maxLength(20),
              },
              monto: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(20),
                minValue: minValue(0),
                maxValue: maxValue(maxSaldo)
              },
              tipoDinero: {
                required,
              },
              tipoGasto: {
                required,
              },
          },
          partida: {            
              nombreProducto: {
                  required,
                  minLength: minLength(3),
                  maxLength: maxLength(20),
              },
              unidad: {
                  required,
                  minLength: minLength(2),
                  maxLength: maxLength(15),
              },            
              precio: {
                  required,
                  minLength: minLength(1),
                  maxLength: maxLength(15),
              },
              cantidad: {
                  required,
                  minLength: minLength(1),
                  maxLength: maxLength(15),
              }
          },
          editedItemEtiq: {
              tipoDato: {
                  required,
              },
              dato: {
                  required,
              },
          },
        }
      },
      created() {
          this.dateFormatted = this.formatDate(this.gasto.fechaFactura);
          this.consultarWhereFrom()
          this.actualizarPerfilAuth()   
          this.consultaretiquetasFzsDB()
        //   this.consultarGastos()
      },
      watch: {
        'gasto.fechaFactura'() {
            this.dateFormatted = this.formatDate(this.gasto.fechaFactura);
        }
      },
      mounted() {
        this.setFechaFactura();
      },
      computed: {
          ...mapState('sesion', ['usuario']),
          ...mapState('usuarioHost', ['usuarioHost']),
          ...mapState('usuarioHost', ['perfilAuth']),
          ...mapState('fzsPracticas', ['saldoTotPorDinero']),
          computedDateFormatted() {
            return this.formatDate(this.gasto.fechaFactura);
          },
          paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
          }, 
          gastoProgrToday() {
            let todayDate = new Date().toISOString().substr(0, 10)
            return this.gastos.some(elm => elm.fechaFactura <= todayDate)
          },
          colaboradorBorrar() {
  
              if(this.colaborador.switchBorrar == true){
                  // alert("Object found inside the array.");
                  return true
              } else{
                  // alert("Object not found.");
                  return false
              }
          },
          formTitle () {
              return this.editedIndex === -1 ? 'Nuevo Gasto a Programar' : 'Editar Gasto Programado'  
          },
          formTitlePartidas () {
              return this.editedIndexPartida === -1 ? 'Nueva Partida' : 'Editar Partida'
          }, 
          subtotSum () {
              var sum = 0;
              this.gastoPartidas.forEach(x => {
                  sum += x.subtotal;
              });
              return sum            
          },
          impuesto () {
              let x = 0.16
              var imp = this.subtotSum * x
              // return imp
              return +(Math.round(imp + "e+2")  + "e-2");
          },
          totSum () {
              var tS = this.impuesto + this.subtotSum
              return tS
          },
          totGastos () {
              var suma = 0;
              this.gastos.forEach(x => {
                  suma += x.monto;
                  
                  
              });
              // var sumafix = Number(suma).toFixed(2);
                  // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              return suma.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
          erroresProveedor() {
              let errores = []
              if (!this.$v.gasto.proveedor.$dirty) { return errores }
              if (!this.$v.gasto.proveedor.required) { errores.push('Ingresa el nombre del proveedor.') }
              if (!this.$v.gasto.proveedor.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
              if (!this.$v.gasto.proveedor.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
              return errores
          },
          erroresNumFactura() {
              let errores = []
              if (!this.$v.gasto.proveedor.$dirty) { return errores }
              if (!this.$v.gasto.proveedor.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
              return errores
          },
          erroresTipoDinero() {
            let errores = []
            if (!this.$v.gasto.tipoDinero.$dirty) { return errores }
            if (!this.$v.gasto.tipoDinero.required) { errores.push('Selecciona de dónde estás tomando recursos para este gasto.') }
            return errores
          },
          erroresTipoGasto() {
            let errores = []
            if (!this.$v.gasto.tipoGasto.$dirty) { return errores }
            if (!this.$v.gasto.tipoGasto.required) { errores.push('Selecciona el tipo de gasto.') }
            return errores
          },
          erroresMonto() {
              let errores = []
              if (!this.$v.gasto.monto.$dirty) { return errores }
              if (!this.$v.gasto.monto.required) { errores.push('Ingresa el monto.') }
              if (!this.$v.gasto.monto.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
              if (!this.$v.gasto.monto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
              if (!this.$v.gasto.monto.minValue) { errores.push('Ingresa un número mayor a cero.') }
              if (!this.$v.gasto.monto.maxValue) { errores.push('Ingresa un monto igual o menor al Saldo-cuenta que seleccionaste.') }
              
              return errores
          },
          erroresProducto() {
              let errores = []
              if (!this.$v.partida.nombreProducto.$dirty) { return errores }
              if (!this.$v.partida.nombreProducto.required) { errores.push('Ingresa el nombre del producto.') }
              if (!this.$v.partida.nombreProducto.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
              if (!this.$v.partida.nombreProducto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
              return errores
          },
          erroresUnidad() {
              let errores = []
              if (!this.$v.partida.unidad.$dirty) { return errores }
              if (!this.$v.partida.unidad.required) { errores.push('Ingresa el unidad del producto.') }
              if (!this.$v.partida.unidad.minLength) { errores.push('Ingresa al menos 2 caracteres.') }
              if (!this.$v.partida.unidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
              return errores
          },
          erroresPrecio() {
              let errores = []
              if (!this.$v.partida.precio.$dirty) { return errores }
              if (!this.$v.partida.precio.required) { errores.push('Ingresa el precio.') }
              if (!this.$v.partida.precio.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
              if (!this.$v.partida.precio.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
              return errores
          },
          erroresCantidad() {
              let errores = []
              if (!this.$v.partida.cantidad.$dirty) { return errores }
              if (!this.$v.partida.cantidad.required) { errores.push('Ingresa la cantidad de unidades.') }
              if (!this.$v.partida.cantidad.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
              if (!this.$v.partida.cantidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
              return errores
          },
      },
      methods: {
          ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
          ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
          ...mapActions('fzsPracticas', ['consultarGastos']),
          getSaldoFromTipoDinero(tipoDinero) {
                if (!tipoDinero) return Infinity;
                const saldo = tipoDinero.split('Saldo: ')[1];
                return parseFloat(saldo);
          },
          itemText(item) {
                return `${item.tipoDinero} - Saldo: ${item.saldo.toFixed(2)}`;
          },
          setFechaFactura() {
            const fechaActual = new Date();
            const tzOffset = fechaActual.getTimezoneOffset() * 60000; // Obtener compensación de la zona horaria en milisegundos
            const fechaLocalISO = new Date(fechaActual - tzOffset)
            .toISOString()
            .substr(0, 10);
            this.gasto.fechaFactura = fechaLocalISO;
          },
          formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year.slice(-2)}`;
          },
          parseDate(date) {
            if (!date) return null;

            const [day, month, year] = date.split('/');
            return `20${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
          },
          updateFechaFactura() {
            this.gasto.fechaFactura = this.parseDate(this.dateFormatted);
          },
          async consultarWhereFrom() {
              await dbL.collection('fromSameHost').get().then(fP => {
                  this.sameHost = fP[0].sameHost
                  this.sameHostName = fP[0].userHost
              }).catch(() => {
                  // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                  this.$router.push({ name: 'Entrada' })
              }).finally(() => {
                  this.consultarColLB()
              });
          },
          async consultarColLB() {
              if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                  // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                  this.$router.push({ name: 'Entrada' })
              } else if (this.sameHost == true) {
              
                  this.colaboradores = []
                  this.colaborador = ''
                  if (this.perfilAuth == true) {
                      this.colaborador = this.usuarioHost
                      //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                  } else if (this.perfilAuth == false) {
                  
                      await dbL.collection('colaboradores').get().then(cols => {
                      this.colaboradores = cols
                      })
  
                      if (this.colaboradores.length > 0) {
                          let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                          if (typeof colaborador === 'object') {
                              let convertINarray = Object.values(colaborador.estatusAccesos)
                              delete colaborador.estatusAccesos
                              colaborador.estatusAccesos = convertINarray
                              this.colaborador = colaborador  
                          } else {
                              this.colaborador = this.usuarioHost
                              //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                          } 
                      } else {
                          // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                          this.$router.push({ name: 'Entrada' })  
                      }
                  } else {
                      // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                      this.$router.push({ name: 'Entrada' })
                  }
              }
              await dbL.collection('fromSameHost').delete()
              this.sameHost = false
              this.sameHostName = ''
              this.consultarGastosProgr()
          },    
          async savePartida() {
                let userNameParametro = this.$route.params.userName.toLowerCase();
                this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                    
                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        if (this.editedIndexPartida > -1) {
                            this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });

                            // Actualizar la partida existente
                            Object.assign(this.gastoPartidas[this.editedIndexPartida], this.partida);

                            try {
                                await updateDoc(doc(db, 'usuarios', userName.uid, 'gastos', this.gasto.gaid, 'gastoPartidas', this.partida.ptdaId), {
                                    cantidad: this.partida.cantidad,
                                    producto: this.partida.nombreProducto,
                                    unidad: this.partida.unidad,
                                    proveedor: this.gasto.proveedor,
                                    numFactura: this.gasto.numFactura,
                                    autor: this.gasto.autor.userName,
                                    notas: this.gasto.notas,
                                    tipoGasto: this.gasto.tipoGasto,
                                    tipoDinero: this.gasto.tipoDinero,
                                });

                                this.close();
                                this.dialog3 = false;
                                this.dialog2 = false;
                                this.mostrarExito('Se cargó la información exitosamente');
                            } catch (error) {
                                this.mostrarError('Ocurrió un error cargando la información');
                            } finally {
                                this.ocultarOcupado();
                            }
                        } else {
                            // Crear una nueva partida
                            let batch = writeBatch(db);
                            let ptdaId = uuidv4();
                            let subtotal = this.partida.precio * this.partida.cantidad;

                            let partida = {
                                ptdaId,
                                cantidad: Number(this.partida.cantidad),
                                precio: Number(this.partida.precio),
                                producto: this.partida.nombreProducto,
                                unidad: this.partida.unidad,
                                subtotal,
                                gaid: this.gasto.gaid,
                                proveedor: this.gasto.proveedor,
                                fechaRegistro: this.gasto.fechaRegistro,
                                numFactura: this.gasto.numFactura,
                                notas: this.gasto.notas,
                                tipoGasto: this.gasto.tipoGasto,
                                tipoDinero: this.gasto.tipoDinero,
                                autor: {
                                    colid: this.colaborador.colid || 'userHostAsColaborador',
                                    userName: this.colaborador.userName,
                                },
                            };

                            batch.set(doc(db, 'usuarios', userName.uid, 'gastos', this.gasto.gaid, 'gastoPartidas', partida.ptdaId), partida);

                            try {
                                await batch.commit();
                                this.gastoPartidas.push(this.partida);
                                this.mostrarExito('Se cargó la información exitosamente');
                            } catch (error) {
                                this.mostrarError('Ocurrió un error cargando la información');
                            } finally {
                                this.ocultarOcupado();
                            }

                            this.$nextTick(() => {
                                this.gastoPartidas = [];
                                this.consultarPartidas();
                            });
                            this.closePartida();
                        }
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error al guardar la partida:', error);
                    this.mostrarError('Ocurrió un error al guardar la partida.');
                }
            }, 
            async guardarGastoProgr() {
                let userNameParametro = this.$route.params.userName.toLowerCase();
                this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        if (this.editedIndex > -1) {
                            this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });
                            Object.assign(this.gastos[this.editedIndex], this.gasto);

                            try {
                                // Actualizar gasto existente
                                await updateDoc(doc(db, 'usuarios', userName.uid, 'gastos', this.gasto.gaid), {
                                    proveedor: this.gasto.proveedor,
                                    tipoGasto: this.gasto.tipoGasto,
                                    tipoDinero: this.gasto.tipoDinero,
                                    numFactura: this.gasto.numFactura,
                                    fechaFactura: this.gasto.fechaFactura,
                                    notas: this.gasto.notas,
                                    monto: Number(this.gasto.monto),
                                    programado: this.gasto.programado
                                });

                                if (this.gasto.programado === false) {
                                    this.gastos.splice(this.editedIndex, 1);
                                }

                                this.close();
                                this.mostrarExito('Se cargó la información exitosamente');
                            } catch (error) {
                                this.mostrarError('Ocurrió un error cargando la información');
                            } finally {
                                this.ocultarOcupado();
                            }
                        } else {
                            // Crear nuevo gasto
                            let batch = writeBatch(db);
                            let gaid = uuidv4();

                            let gasto = {
                                gaid,
                                proveedor: this.gasto.proveedor,
                                numFactura: this.gasto.numFactura,
                                monto: Number(this.gasto.monto),
                                fechaMili: new Date().getTime(),
                                fechaFactura: this.gasto.fechaFactura,
                                fechaRegistro: this.gasto.fechaRegistro,
                                etiqCyP: this.gasto.etiqCyP,
                                tipoGasto: this.gasto.tipoGasto,
                                tipoDinero: this.gasto.tipoDinero,
                                notas: this.gasto.notas,
                                autor: {
                                    colid: this.colaborador.colid || 'userHostAsColaborador',
                                    userName: this.colaborador.userName,
                                },
                                programado: true
                            };

                            batch.set(doc(db, 'usuarios', userName.uid, 'gastos', gasto.gaid), gasto);

                            if (this.gasto.tipoGasto == 'abonoAcrédito/préstamo') {
                                let credYprestm = {
                                    credid: gaid,
                                    fuente: this.gasto.etiqCyP,
                                    numDoc: this.gasto.numFactura,
                                    monto: Number(-this.gasto.monto),
                                    fechaMili: new Date().getTime(),
                                    fechaDoc: this.gasto.fechaFactura,
                                    fechaRegistro: this.gasto.fechaRegistro,
                                    tipoDinero: this.gasto.tipoDinero,
                                    notas: this.gasto.notas,
                                    autor: {
                                        colid: this.colaborador.colid || 'userHostAsColaborador',
                                        userName: this.colaborador.userName,
                                    },
                                };

                                batch.set(doc(db, 'usuarios', userName.uid, 'credsYprestms', credYprestm.credid), credYprestm);
                            }

                            try {
                                await batch.commit();
                                this.gastos.push(gasto);
                                this.mostrarExito('Se cargó la información exitosamente');
                            } catch (error) {
                                this.mostrarError('Ocurrió un error cargando la información');
                            } finally {
                                this.ocultarOcupado();
                            }

                            this.close();
                        }
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error al guardar el gasto:', error);
                    this.mostrarError('Ocurrió un error al guardar el gasto.');
                }
            },
            async updatecredyprestms() {
                let userNameParametro = this.$route.params.userName.toLowerCase();
                
                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Actualizar el documento en 'credsYprestms'
                        await updateDoc(doc(db, 'usuarios', userName.uid, 'credsYprestms', this.gasto.gaid), {
                            fuente: this.gasto.proveedor,
                            tipoDinero: this.gasto.tipoDinero,
                            numDoc: this.gasto.numFactura,
                            notas: this.gasto.notas,
                            monto: Number(-this.gasto.monto),
                        });
                    }
                } catch (error) {
                    console.error('Error al actualizar el crédito o préstamo:', error);
                    this.mostrarError('Ocurrió un error actualizando el crédito o préstamo.');
                }
            },
          close () {
            this.dialog = false
            setTimeout(() => {
                this.gasto = Object.assign({}, this.defaultItem)
                this.gasto.fechaFactura = this.date
                this.editedIndex = -1
            }, 300)            
          },
          closePartida () {            
              this.dialog3 = false
              setTimeout(() => {
                  this.partida = Object.assign({}, this.defaultItemPartida)
                  this.editedIndexPartida = -1
              }, 300)
          },
          editItem (item) {          
              this.editedIndex = this.gastos.indexOf(item) 
              this.gasto = Object.assign({}, item)
              this.dialog = true
          },
          editItemPartida (item) {          
              this.editedIndexPartida = this.gastoPartidas.indexOf(item) 
              this.partida = Object.assign({}, item)
              this.dialog3 = true
          },
          async deleteItem(item) {
                let userNameParametro = this.$route.params.userName.toLowerCase();
                
                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Eliminar el documento de 'gastos'
                        await deleteDoc(doc(db, 'usuarios', userName.uid, 'gastos', item));
                        
                        // Actualizar la lista local
                        const index = this.gastos.indexOf(item);
                        if (index > -1) {
                            this.gastos.splice(index, 1);
                        }

                        this.mostrarExito('Se eliminó el concepto de Gasto exitosamente');
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error al eliminar el gasto:', error);
                    this.mostrarError('Ocurrió un error borrando la información.');
                } finally {
                    this.ocultarOcupado();
                    this.close();
                }
            },
            async deletePartida(item) {
                let userNameParametro = this.$route.params.userName.toLowerCase();
                
                // Eliminar la partida de la lista local
                const index = this.gastoPartidas.indexOf(item);
                if (index > -1) {
                    this.gastoPartidas.splice(index, 1);
                }
                
                try {
                    this.partida = { ...item };

                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();
                        
                        // Eliminar el documento de la colección 'gastoPartidas'
                        await deleteDoc(doc(db, 'usuarios', userName.uid, 'gastos', this.gasto.gaid, 'gastoPartidas', this.partida.ptdaId));
                        
                        this.mostrarExito('Se eliminó la partida exitosamente');
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error al eliminar la partida:', error);
                    this.mostrarError('Ocurrió un error borrando la información.');
                } finally {
                    this.ocultarOcupado();
                }
                
                this.closePartida();
            },
            async consultaretiquetasFzsDB() {
                let userNameParametro = this.$route.params.userName.toLowerCase();

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Obtener las etiquetas desde 'etiquetasFzsDB'
                        let etiquetasSnapshot = await getDocs(collection(db, 'usuarios', userName.uid, 'etiquetasFzsDB'));

                        etiquetasSnapshot.forEach(doc => {
                            let datadb = doc.data();
                            let nom = datadb.nombreEtq;

                            if (datadb.tipoEtiq === 'Gasto') {
                                this.etiquetasGasto.push(nom);
                            } else if (datadb.tipoEtiq === 'Dinero') {
                                this.etiquetasDinero.push(nom);
                            } else if (datadb.tipoEtiq === 'crédito/préstamo') {
                                this.etiquetasCreditoPrestamo.push(nom);
                            }
                        });
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error consultando las etiquetas:', error);
                    this.mostrarError('Ocurrió un error consultando las Etiquetas.');
                }
            },
            async consultarGastosProgr() {
                let userNameParametro = this.$route.params.userName.toLowerCase();
                this.today = new Date().getTime();
                this.daysPast = this.today - (86400000 * 30);

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Obtener los documentos de la colección 'gastos'
                        let gastosSnapshot = await getDocs(collection(db, 'usuarios', userName.uid, 'gastos'));

                        // Procesar cada documento
                        gastosSnapshot.forEach(doc => {
                            let gasto = doc.data();
                            gasto.formatToIso = new Date(gasto.fechaMili).toISOString().substr(0, 10);
                            this.gastosData.push(gasto);
                        });

                        // Filtrar los gastos programados
                        this.gastos = this.gastosData.filter(elm => elm.programado === true);
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error consultando los gastos:', error);
                    this.mostrarError('Ocurrió un error consultando los gastos.');
                } finally {
                    this.dialogProgress = false;
                }
            },
            async consultarPartidas() {         
                let userNameParametro = this.$route.params.userName.toLowerCase();

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();
                        
                        // Obtener las partidas del gasto
                        let partidasSnapshot = await getDocs(collection(db, 'usuarios', userName.uid, 'gastos', this.gasto.gaid, 'gastoPartidas'));
                        
                        // Procesar cada documento
                        partidasSnapshot.forEach(subdoc => {
                            let partida = subdoc.data();
                            
                            // Verificar si el gasto coincide
                            if (partida.gaid === this.gasto.gaid) {
                                this.gastoPartidas.push(partida);
                            }
                        });
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error consultando las partidas:', error);
                    this.mostrarError('Ocurrió un error consultando las partidas.');
                }
            },
            async consultarTicket() {   
                let userNameParametro = this.$route.params.userName.toLowerCase();

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();
                        
                        // Obtener el documento del ticket
                        let ticketDoc = await getDoc(doc(db, 'usuarios', userName.uid, 'contTicket', 'cTicket'));

                        if (ticketDoc.exists()) {
                            this.contTicket = ticketDoc.data();
                        } else {
                            this.mostrarError('Faltan datos en el área de Configuración para incluir en el ticket.');
                        }
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    console.error('Error consultando el ticket:', error);
                    this.mostrarError('Ocurrió un error consultando el ticket.');
                }
            },         
          async abrirGasto(gasto) {    
              this.gastoPartidas = []
              this.gasto = gasto;
              await this.consultarPartidas(); //con o sin await?
              // this.consultarTicket()            
          },
          printData() {
            var divToPrint= this.$refs.printTable
            let newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML);
            newWin.print();
            newWin.close();
          }
      }
      
  }
  </script>
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  <style>
  .text-right {
    text-align: right;
  }
  
  .tablasubtot {
      font-size: 1rem;
  }

  .custom-card {
  border-radius: 30px;
}
      
  </style>
  
  