<template>
    <v-layout justify-center align-center wrap>
        <!-- <v-flex xs12 ml-3 mt-2>
            <v-btn icon dark>                
                <a @click="movingSameHost"><v-icon small dark left color="black">arrow_back</v-icon></a>
            </v-btn>
            <span class="body-2">oficina virtual</span>
        </v-flex> -->
        <v-flex xs12>
        <v-card class="text-xs-center elevation=10 mt-5 pt-3" >
            <div class="text-xs-right">
            <v-dialog
                v-model="dialogHelp"
                width="500"
            >
                <template v-slot:activator="{ on }">
                    <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                </template>

                <v-card>
                <v-card-title class="headline">
                    Cómo funciona esta sección...
                </v-card-title>

                <v-card-text>
                    Se Guardan y editan los datos correspondiente a Ticket o SPEI, dependiendo en la pestaña en la que te 
                    encuentres.
                    <ul>
                        <li>
                            Al dar click en Agregar, se despliega un formulario para registrar los Datos y al dar click en Guardar, se reflejarán 
                            en la parte inicial de esta sección (Configuración).
                        </li>
                        <li>
                            Al momento de ser Guardados los Datos de Ticket, estos aparecerán en las notas de venta de cada cliente, de lo contrario este
                            apartado estará en blanco. Al igual los de SPEI, en las secciones donde tu aceptes mostrarlos, estos se reflejarán, de lo ocntrario
                            aparecerá en blanco los campos o inactiva la opción.
                        </li>
                    </ul> 
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
            </div>
            <v-layout justify-center align-center>
                <v-flex xs12 sm6 class="mt-5 pt-3 text-xs-center">
                <template>
                    <v-tabs
                        centered
                        icons-and-text
                        
                        >
                        <v-tabs-slider class="text-none elevation-13" :style="{'background-color': paletaColores.primero}" dark></v-tabs-slider>

                        <v-tab href="#tab-1">
                            <span :style="{'color': paletaColores.primero}">Ticket</span>
                            <v-icon :style="{'color': paletaColores.primero}">mdi-paper-roll-outline</v-icon>
                        </v-tab>

                        <v-tab href="#tab-3">
                            <span :style="{'color': paletaColores.primero}">PayPal y <br>Tarjeta</span>
                            <v-icon :style="{'color': paletaColores.primero}">mdi-credit-card-outline</v-icon>
                        </v-tab>

                        <v-tab href="#tab-2">
                            <span :style="{'color': paletaColores.primero}">CLABE SPEI</span>
                            <v-icon :style="{'color': paletaColores.primero}">mdi-wifi</v-icon>
                        </v-tab>                        

                        <v-tab-item
                            :value="'tab-1'"
                            >
                            <v-card class="elevation=5 ma-3">
                                <v-dialog v-model="editandoTicket" max-width="400">
                                    <v-card>
                                        <v-toolbar color="transparent" card>
                                        <v-toolbar-title>
                                            Edita los datos:
                                        </v-toolbar-title>
                                        </v-toolbar>                
                                        <v-card-text>
                                            <v-container grid-list-md>
                                            <v-layout wrap >
                                                <v-flex xs12>
                                                <v-text-field v-model="cTicket.empresa" label="Nombre de empresa" counter="30" @blur="$v.cTicket.empresa.$touch()" :error-messages="erroresEmpresa"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>
                                                <v-text-field v-model="cTicket.RFC" label="RFC" counter="30" @blur="$v.cTicket.RFC.$touch()" :error-messages="erroresRFC"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>
                                                <v-text-field v-model="cTicket.direccion" label="Dirección" counter="30" @blur="$v.cTicket.direccion.$touch()" :error-messages="erroresDireccion"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>
                                                <v-text-field v-model="cTicket.telefono" label="teléfono" counter="30" @blur="$v.cTicket.telefono.$touch()" :error-messages="erroresTelefono"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>                            
                                                    <v-text-field
                                                        v-model="cTicket.notapie"
                                                        counter="25"
                                                        label="Mensaje al pie del ticket. Ej: promociones, eMail, despedida, etc."
                                                        outline 
                                                        @blur="$v.cTicket.notapie.$touch()" :error-messages="erroresNota"
                                                    ></v-text-field>
                                                </v-flex>                                       
                                            </v-layout>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-text>
                                        <v-layout>
                                            <v-flex xs6>
                                            <v-layout justify-start>
                                                <v-btn @click="editandoTicket = false">Cancelar</v-btn>
                                            </v-layout>
                                            </v-flex>
                                            <v-flex xs6>
                                            <v-layout justify-end>
                                                <v-btn @click="guardarDatosTicket" color="secondary" :depressed="$v.cTicket.$invalid" :disabled="$v.cTicket.$invalid">Guardar</v-btn>
                                            </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <v-card-text>
                                    <v-container grid-list-md>
                                        <v-layout wrap>
                                            <v-flex class="text-xs-right">
                                                <v-btn
                                                    class="hidden-xs-only text-none elevation-13" :style="{'background-color': paletaColores.primero}" 
                                                    dark
                                                    @click="editarTicket(contTicket)"
                                                    small
                                                    >
                                                    Editar
                                                    <v-icon right dark small>edit</v-icon>
                                                </v-btn>
                                            </v-flex>
                                            <v-flex xs12>
                                                <span class="text-xs-left font-weight-medium">Datos en encabezado del ticket de venta:</span><br><br>
                                                <v-text-field disabled box label="Nombre del negocio" v-model="contTicket.empresa"></v-text-field>
                                                <v-text-field disabled box label="RFC" v-model="contTicket.RFC"></v-text-field>
                                                <v-text-field disabled box label="Dirección" v-model="contTicket.direccion"></v-text-field>
                                                <v-text-field disabled box label="Teléfono" v-model="contTicket.telefono"></v-text-field>
                                                <span class="text-xs-left font-weight-medium">Datos al pie del ticket de venta:</span><br><br>
                                                <v-text-field disabled box label="Nota o mensaje" v-model="contTicket.notapie"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                    <!-- <li v-for="item in contTicket" :key="item.id">
                                        {{ item }}
                                    </li> -->
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item
                            :value="'tab-3'"
                            >
                            <v-card class="elevation=5 ma-3">
                                <v-dialog v-model="editandoKeyPayPal" max-width="400">
                                    <v-card>
                                        <v-toolbar color="transparent" card>
                                        <v-toolbar-title>
                                            Edita los datos:
                                        </v-toolbar-title>
                                        </v-toolbar>                
                                        <v-card-text>
                                            <v-layout wrap >
                                                <v-flex xs12>
                                                <v-text-field v-model="kPP.keypaypal" label="Clave PayPal" counter="80" @blur="$v.kPP.keypaypal.$touch()" :error-messages="erroresKeypaypal"></v-text-field>
                                                </v-flex>                       
                                            </v-layout>
                                        </v-card-text>
                                        <v-card-text>
                                        <v-layout>
                                            <v-flex xs6>
                                            <v-layout justify-start>
                                                <v-btn @click="editandoKeyPayPal = false">Cancelar</v-btn>
                                            </v-layout>
                                            </v-flex>
                                            <v-flex xs6>
                                            <v-layout justify-end>
                                                <v-btn @click="guardarKeyPayPal" color="secondary" :depressed="$v.kPP.$invalid" :disabled="$v.kPP.$invalid">Guardar</v-btn>
                                            </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <v-card-text>
                                    <v-container grid-list-md>
                                        <v-layout wrap >
                                            <v-flex class="text-xs-right">
                                                <v-btn
                                                    class="hidden-xs-only text-none elevation-13" :style="{'background-color': paletaColores.primero}" 
                                                    dark @click="editarKeyPayPal(kPP)" small
                                                    >
                                                    Editar
                                                    <v-icon right dark small>edit</v-icon>
                                                </v-btn>
                                            </v-flex>
                                            <v-flex xs12>
                                                <span class="text-xs-left font-weight-medium">Clave API de PayPal:</span><br><br>
                                                <v-text-field disabled box label="Clave API de PayPal" v-model="kPP.keypaypal"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                    <!-- <li v-for="item in contTicket" :key="item.id">
                                        {{ item }}
                                    </li> -->
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item
                            :value="'tab-2'"
                            >
                            <v-card class="elevation=5 ma-3">
                                <v-dialog v-model="editandoDatosBanco" max-width="400">
                                    <v-card>
                                        <v-toolbar color="transparent" card>
                                        <v-toolbar-title>
                                            Edita los datos:
                                        </v-toolbar-title>
                                        </v-toolbar>                
                                        <v-card-text>
                                            <v-layout wrap >
                                                <v-flex xs12>
                                                <v-text-field v-model="cBank.titular" label="Nombre del titular" counter="40" @blur="$v.cBank.titular.$touch()" :error-messages="erroresTitular"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>
                                                <v-text-field v-model="cBank.banco" label="Banco" counter="30" @blur="$v.cBank.banco.$touch()" :error-messages="erroresBanco"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>
                                                <v-text-field v-model="cBank.tarjeta" label="# Tarjeta" counter="16" @blur="$v.cBank.tarjeta.$touch()" :error-messages="erroresTarjeta"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12>
                                                <v-text-field v-model="cBank.spei" label="# SPEI" counter="18" @blur="$v.cBank.spei.$touch()" :error-messages="erroresSpei"></v-text-field>
                                                </v-flex>                                  
                                            </v-layout>
                                        </v-card-text>
                                        <v-card-text>
                                        <v-layout>
                                            <v-flex xs6>
                                            <v-layout justify-start>
                                                <v-btn @click="editandoDatosBanco = false">Cancelar</v-btn>
                                            </v-layout>
                                            </v-flex>
                                            <v-flex xs6>
                                            <v-layout justify-end>
                                                <v-btn @click="guardarDatosBanco" color="secondary" :depressed="$v.cBank.$invalid" :disabled="$v.cBank.$invalid">Guardar</v-btn>
                                            </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <v-card-text>
                                    <v-container grid-list-md>
                                        <v-layout wrap >
                                            <v-flex class="text-xs-right">
                                                <v-btn
                                                    class="hidden-xs-only text-none elevation-13" :style="{'background-color': paletaColores.primero}" 
                                                    dark @click="editarDatosBanco(contDBank)" small
                                                    >
                                                    Editar
                                                    <v-icon right dark small>edit</v-icon>
                                                </v-btn>
                                            </v-flex>
                                            <v-flex xs12>
                                                <span class="text-xs-left font-weight-medium">Datos para transferecnia electrónica:</span><br><br>
                                                <v-text-field disabled box label="Nombre del titular de la cuenta" v-model="contDBank.titular">{{  }}</v-text-field>
                                                <v-text-field disabled box label="Banco" v-model="contDBank.banco"></v-text-field>
                                                <v-text-field disabled box label="Número de tarjeta" v-model="contDBank.tarjeta"></v-text-field>
                                                <v-text-field disabled box label="Número CLABE para SPEI" v-model="contDBank.spei"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                    <!-- <li v-for="item in contTicket" :key="item.id">
                                        {{ item }}
                                    </li> -->
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>

                    </v-tabs>
                </template>
                </v-flex>
            </v-layout>
        </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import {mapState, mapMutations, mapActions } from 'vuex'
import { db, auth } from '@/firebase'
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    
    data: () => ({        
        contDBank: [],
        contTicket: [],
        contKeyPayPal: [],
        colaborador: [],
        dialogHelp: false,
        cTicket: {
            empresa: '',
            RFC: '',
            direccion: '',
            teléfono: '',
            notapie: ''
        },
        cBank: {
            titular: '',
            banco: '',
            tarjeta: '',
            spei: '',
        },
        kPP: {
            keypaypal: ''
        },
        editandoTicket: false,
        editandoDatosBanco: false,
        editandoKeyPayPal: false,
    }),

    validations: {
        cTicket: {
            empresa: {
                required,
                maxLength: maxLength(30),
                minLength: minLength(3)
            },
            RFC: {
                required,
                maxLength: maxLength(15),
                minLength: minLength(12)
            },
            direccion: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(10)
            },
            telefono: {
                maxLength: maxLength(12),
            },
            notapie: {
                maxLength: maxLength(25),
            },
        },
        cBank: {
            titular: {
                required,
                maxLength: maxLength(40),
                minLength: minLength(3)
            },
            banco: {
                required,
                maxLength: maxLength(20),
                minLength: minLength(3)
            },
            tarjeta: {
                required,
                maxLength: maxLength(16),
                minLength: minLength(16)
            },
            spei: {
                maxLength: maxLength(18),
                minLength: minLength(18)
            },
        },
        kPP: {
            keypaypal: {
                required,
                maxLength: maxLength(80),
                minLength: minLength(80)
            },
        }
    },

    created() {
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()   
        this.consultarColaboradores()
        this.consultarContenidoTicket()
        this.consultarDatosBanco()        
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        paletaColores() {
            if (this.usuarioHost.designColors) {
                return this.usuarioHost.designColors
            } else {
                return ''
            }
        },
        // ...mapState('usuarioHost', ['contTicket']),
        erroresEmpresa() {
            let errores = []
            if (!this.$v.cTicket.empresa.$dirty) { return errores }  
            if (!this.$v.cTicket.empresa.required) { errores.push('Ingresa el empresa del producto.') }      
            if (!this.$v.cTicket.empresa.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
            if (!this.$v.cTicket.empresa.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            return errores
        },
        erroresRFC() {
            let errores = []
            if (!this.$v.cTicket.RFC.$dirty) { return errores }  
            if (!this.$v.cTicket.RFC.required) { errores.push('Ingresa el RFC de la empresa.') }      
            if (!this.$v.cTicket.RFC.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            if (!this.$v.cTicket.RFC.minLength) { errores.push('Ingresa al menos 12 caracteres.') }
            return errores
        },
        erroresDireccion() {
            let errores = []
            if (!this.$v.cTicket.direccion.$dirty) { return errores }
            if (!this.$v.cTicket.direccion.required) { errores.push('Ingresa la direccion de la empresa.') }      
            if (!this.$v.cTicket.direccion.maxLength) { errores.push('Ingresa máximo 50 caracteres.') }
            if (!this.$v.cTicket.direccion.minLength) { errores.push('Ingresa al menos 10 caracteres.') }
            return errores
        },
        erroresTelefono() {
            let errores = []
            if (!this.$v.cTicket.telefono.$dirty) { return errores }   
            if (!this.$v.cTicket.telefono.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresNota() {
            let errores = []
            if (!this.$v.cTicket.notapie.$dirty) { return errores }   
            if (!this.$v.cTicket.notapie.maxLength) { errores.push('Ingresa máximo 25 caracteres.') }
            return errores
        },
        erroresTitular() {
            let errores = []
            if (!this.$v.cBank.titular.$dirty) { return errores }  
            if (!this.$v.cBank.titular.required) { errores.push('Ingresa el nombre del titular de la cuenta.') }      
            if (!this.$v.cBank.titular.maxLength) { errores.push('Ingresa máximo 80 caracteres.') }
            if (!this.$v.cBank.titular.minLength) { errores.push('Ingresa al menos 80 caracteres.') }
            return errores
        },
        erroresKeypaypal() {
            let errores = []
            if (!this.$v.kPP.keypaypal.$dirty) { return errores }  
            if (!this.$v.kPP.keypaypal.required) { errores.push('Ingresa la clave API de tu PayPal') }      
            if (!this.$v.kPP.keypaypal.maxLength) { errores.push('Ingresa máximo 40 caracteres.') }
            if (!this.$v.kPP.keypaypal.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            return errores
        },
        erroresBanco() {
            let errores = []
            if (!this.$v.cBank.banco.$dirty) { return errores }  
            if (!this.$v.cBank.banco.required) { errores.push('Ingresa el nombre del banco.') }      
            if (!this.$v.cBank.banco.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            if (!this.$v.cBank.banco.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            return errores
        },
        erroresTarjeta() {
            let errores = []
            if (!this.$v.cBank.tarjeta.$dirty) { return errores }
            if (!this.$v.cBank.tarjeta.required) { errores.push('Ingresa la tarjeta de la empresa.') }      
            if (!this.$v.cBank.tarjeta.maxLength) { errores.push('Ingresa máximo 16 caracteres.') }
            if (!this.$v.cBank.tarjeta.minLength) { errores.push('Ingresa al menos 16 caracteres.') }
            return errores
        },
        erroresSpei() {
            let errores = []
            if (!this.$v.cBank.spei.$dirty) { return errores }   
            if (!this.$v.cBank.spei.maxLength) { errores.push('Ingresa máximo 18 caracteres.') }
            if (!this.$v.cBank.spei.minLength) { errores.push('Ingresa al menos 18 caracteres.') }
            return errores
        },
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),              
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        ...mapMutations('usuarioHost', ['actualizarContTicket']),
        async movingSameHost() {
            await dbL.collection('fromSameHost').set([])
            await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName.toLowerCase() })
            this.$router.go(-1)
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            // await dbL.collection('fromSameHost').delete()
            // this.sameHost = false
            // this.sameHostName = ''
            this.consultarContenidoTicket()
        },
        editarTicket(contTicket) {
            this.cTicket = Object.assign({}, contTicket)
            this.editandoTicket = true
        },
        editarDatosBanco(contDBank) {
            this.cBank = Object.assign({}, contDBank)
            this.editandoDatosBanco = true
        },
        editarKeyPayPal(contKeyPayPal) {
            this.kPP = Object.assign({}, contKeyPayPal)
            this.editandoKeyPayPal = true
        },
        async guardarDatosTicket() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            this.mostrarOcupado({ titulo: 'Actualizando Información', mensaje: 'Estamos guardando la info del Ticket...' })

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('contTicket')
                            .doc('cTicket')
                            .set({ empresa: this.cTicket.empresa,
                                    RFC: this.cTicket.RFC,
                                    direccion: this.cTicket.direccion,
                                    telefono: this.cTicket.telefono,
                                    notapie: this.cTicket.notapie  })

                    // this.actualizarContTicket({ empresa: this.cTicket.empresa,
                    //                 RFC: this.cTicket.RFC,
                    //                 direccion: this.cTicket.direccion,
                    //                 telefono: this.cTicket.telefono,
                    //                 notapie: this.cTicket.notapie  })

                    this.editandoTicket = false
                    this.mostrarExito('Se cargó la información exitosamente')               
                }                
            }
            catch (error) {
                this.mostrarError('Ocurrió un error actualizando tus datos.')
            }
            finally {
                this.ocultarOcupado()
            }
            // this.$nextTick(function () {
                this.contTicket = []
                this.consultarContenidoTicket();
            // })
        },
        async guardarDatosBanco() {
            let userNameParametro = this.$route.params.userName.toLowerCase()
            this.mostrarOcupado({ titulo: 'Actualizando Información', mensaje: 'Estamos guardando la info de la cuenta bancaria...' })

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('contDBank')
                            .doc('cBank')
                            .set({ titular: this.cBank.titular,
                                    banco: this.cBank.banco,
                                    tarjeta: this.cBank.tarjeta,
                                    spei: this.cBank.spei,
                            })

                    this.editandoDatosBanco = false
                    this.mostrarExito('Se cargó la información bancaria exitosamente')            
                }                
            }
            catch (error) {
                this.mostrarError('Ocurrió un error actualizando tus datos bancarios.')
            }
            finally {
                this.ocultarOcupado()
            }
            this.$nextTick(function () {
                this.contDBank = []
                this.consultarDatosBanco();
            })
        },
        async guardarKeyPayPal() {
            let userNameParametro = this.$route.params.userName.toLowerCase()
            this.mostrarOcupado({ titulo: 'Actualizando Información', mensaje: 'Estamos guardando la Key de PayPal...' })

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .update({ keypaypal: this.kPP.keypaypal })

                    this.editandoKeyPayPal = false
                    this.mostrarExito('Se cargó la información exitosamente')            
                }                
            }
            catch (error) {
                this.mostrarError('Ocurrió un error actualizando tus datos.')
            }
            finally {
                this.ocultarOcupado()
            }
            // this.$nextTick(function () {
            //     this.contKeyPayPal = []
            //     this.consultarKeyPayPal();
            // })
        },

        async consultarDatosBanco() {   
            let userNameParametro = this.$route.params.userName.toLowerCase()

            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()
                 let bankDoc = await db.collection('usuarios')
                                    .doc(userName.uid)
                                    .collection('contDBank')
                                    .doc('cBank')
                                    .get()

                if (bankDoc.exists) {
                    this.contDBank = bankDoc.data()
                }
            }
        },
        async consultarContenidoTicket() {   
            let userNameParametro = this.$route.params.userName.toLowerCase()

            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()
                 let tickDoc = await db.collection('usuarios')
                                    .doc(userName.uid)
                                    .collection('contTicket')
                                    .doc('cTicket')
                                    .get()

                if (tickDoc.exists) {
                    this.contTicket = tickDoc.data()
                }
            }
        },

        async consultarColaboradores() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let colaboradorDoc = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('colaboradores')
                                            .where("switchConfig", '==', true)
                                            // .where("uidCol", '==', auth.currentUser.uid)
                                            .get()                

                        colaboradorDoc.docs.forEach(doc => {                    
                            let colab = doc.data()

                            this.colaborador.push(colab)

                        })

                    if(this.colaborador.length <= 0 && userName.uid != auth.currentUser.uid) {

                        this.$router.push({ name: '404' })
                        
                    }
                }
            }
            catch (error) {
                this.$router.push({ name: '404' })
            }
            finally {
                this.ocultarOcupado()
            }
        },     
    },
}
</script>