<template >    
    <v-layout justify-center align-center wrap>
        <v-flex xs12 sm10 v-if="dialogProgress == false">
            <v-card class="elevation=5 ma-3 mt-5 pt-3 pb-3 custom-card" :style="{'background-color': paletaColores.cuarto}">
                <div class="text-xs-right">
                    <v-dialog
                        v-model="dialogHelp"
                        width="500"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                        </template>

                        <v-card>
                        <v-card-title class="headline">
                            Cómo funciona esta sección...
                        </v-card-title>

                        <v-card-text>
                            Se muestran todas las ventas Pagadas o No Pagadas, en un rango de Fechas.
                            <ul>
                                <li>Selecciona una FECHA INICIAL y una FECHA FINAL, posteriormete da click en CONSULTAR. 
                                    A continuación se desplega la lista de ventas en la tabla inferior.
                                </li>
                                <li>
                                    Al mimso tiempo se reflejan los subtotales antes del IVA, en los campos PAGADAS Y PENDIENTES DE PAGO.
                                </li>
                                <li>
                                    En la tabla, por cada renglón de venta hay una columna CONSULTA, al dar click desglosa las partidas 
                                    correspondientes c la venta.
                                </li>
                            </ul> 
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <v-card>
                    <v-layout>
                        <v-flex xs4 class="text-xs-center">
                            <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-finance</v-icon>                
                        </v-flex>
                        <v-flex xs8 class="text-xs-center">
                            <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Estatus de Ventas</p></h3>
                            <h3 class="hidden-sm-and-up"><p class="text-xs-center display-1 mt-3">Estatus de Ventas</p></h3>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                        <v-flex xs12 sm6 md4 class="pl-3">
                            <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="dateFormatted1"
                                label="Fecha inicial"
                                hint="DD/MM/YYYY formato"
                                persistent-hint
                                prepend-icon="event"
                                @blur="date1 = parseDate1(dateFormatted1)"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date1" no-title @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="dateFormatted2"
                                label="Fecha Final"
                                hint="DD/MM/YYYY formato"
                                persistent-hint
                                prepend-icon="event"
                                @blur="date2 = parseDate2(dateFormatted2)"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" no-title @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <div class="text-xs-center">
                        <v-btn small @click="consultarComandas" :style="{'background-color': paletaColores.primero}" dark class="caption elevation-13" v-if="this.dateMili1 <= this.dateMili2">Consultar</v-btn><br>
                         <v-tooltip top v-if="this.dateMili1 > this.dateMili2">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  small class="caption text-none">Consultar</v-btn><br>
                            </template>
                            <span>Escoge una fecha Inicial menor c la Final</span> 
                        </v-tooltip>                        
                    </div>
                    <strong class="subheading ml-2">Estatus:</strong> <br>
                    <!-- {{ props.item.montoPagado !== undefined ? props.item.montoPagado.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' }} -->
                    <span class="subheading ml-2">Pendientes de Pago: <strong class="blue--text">$ {{ sumPedsNoPagados.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                    <!-- <span class="subheading ml-2">Pendientes de Pago (Con IVA): <strong class="blue--text">$ {{ sumPedsNoPagados.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' }}</strong></span><br> -->
                    <span class="subheading ml-2">Pagadas: <strong class="green--text">$ {{ sumComandasPagadas.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                    <!-- <span class="subheading ml-2">Pagadas (Con IVA): <strong class="green--text">$ {{ sumComandasPagadas.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' }}</strong></span><br> -->
                    <span class="subheading ml-4">pagadas en efectivo: <strong class="green--text">$ {{ sumComandasPagadasByPaymentType('efectivo').toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                    <span class="subheading ml-4">pagadas por transferencia: <strong class="green--text">$ {{ sumComandasPagadasByPaymentType('transferencia').toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>

                </v-card>
                <v-card>
                    <v-card-title>
                    <v-spacer></v-spacer>
                    <v-flex xs10 sm4>
                        <v-text-field
                            max-widht
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-flex>
                    </v-card-title>
                    <v-data-table 
                        :headers="headers"
                        :items="comandas"
                        :search="search"                 
                        class="elevation-1"   
                        >
                        <template v-slot:items="props">
                            <td class="text-xs-right">{{ props.item.fecha }}</td>
                            <td>{{ props.item.cliente }}</td>
                            <td>{{ props.item.estatusPago }}</td>
                            <td>{{ props.item.montoPagado !== undefined ? props.item.montoPagado.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '' }}</td>
                            <td>{{ props.item.formaPago }}</td>
                            <td>{{ props.item.hora }}</td>
                            <td>{{ props.item.formaPedido }}</td>
                            <td>{{ props.item.mesero.userName }}</td>
                            <td>{{ props.item.caja }}</td>
                            <td>{{ props.item.comid.substr(0, 10) }}</td>
                            <td class="justify-center align-center layout">
                                <v-dialog v-model="dialog" persistent max-width="600px">                            
                                    <template v-slot:activator="{ on }">                                
                                        <v-icon
                                            @click="abrirComanda(props.item)"
                                            dark v-on="on"
                                            color="blue"
                                            class="mr-2"
                                            small
                                            >
                                            book
                                        </v-icon>
                                    </template>
                                    <v-card  
                                        v-if="comanda"
                                        :key= "comanda.comid"
                                        :nombre="comanda.autor"
                                        
                                        >
                                        <v-toolbar flat color="white">
                                                <h3 class="title"> {{comanda.cliid}} </h3>                                        
                                            <v-divider
                                                class="mx-2"
                                                inset
                                                vertical
                                            ></v-divider>
                                            <v-spacer></v-spacer>
                                            <v-btn icon small fab class="hidden-sm-and-up text-xs-right" color="transparent" @click="emptyFields">
                                                <v-icon >close</v-icon>   
                                            </v-btn>
                                            <v-btn outline class="hidden-xs-only" color="primary" flat @click="emptyFields">Cerrar</v-btn>
                                        </v-toolbar>
                                        <v-data-table 
                                            :headers="headers2"
                                            :items="pedidosPorComanda"
                                            class="elevation-1"                    
                                            >
                                            <template v-slot:items="props">
                                                <td>{{ props.item.producto }}</td>
                                                <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                <td class="text-xs-center">{{ props.item.precio }}</td>
                                                <td class="text-xs-right">{{ props.item.unidad }}</td>
                                                <td class="text-xs-center">$ {{ props.item.pxq }}</td>                                                     
                                            </template>
                                        </v-data-table>   
                                        <template>
                                            <v-layout row wrap>
                                                <v-flex xs12>
                                                    <v-card color="blue-grey" class="white--text text-xs-right">
                                                        <!-- <span class="subheading mr-1">Sub-Total: $ {{ subTotPorComanda.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span><br>
                                                        <span class="subheading mr-1">TAX: $ {{ impuesto.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span><br>
                                                        <span class="subheading mr-1">Total: $ {{ totSum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span> -->
                                                        <span class="subheading mr-1">Total: $ {{ subTotPorComanda.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
                                                    </v-card>
                                                </v-flex>                                   
                                            </v-layout>
                                        </template>                    
                                    </v-card>      
                                </v-dialog>
                            </td>
                            <!-- <td class="text-xs-right">{{ props.item.estatus }}</td>
                            <td class="text-xs-right">{{ props.item.mesero.userName }}</td>
                            <td class="text-xs-right">{{ props.item.formaPedido }}</td>
                            <td class="text-xs-right">{{ props.item.notas }}</td> -->
                            
                            <!-- <td class="text-xs-right">{{ props.item.pxq }}</td> -->
                                        
                        </template>
                        <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                            </v-alert>
                        </template>
                    </v-data-table>
                </v-card>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { db } from '@/firebase'
import { mapMutations, mapState, mapActions } from 'vuex'
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {

    data: () => ({
        date1: new Date().toISOString().substr(0, 10),
        date2: new Date().toISOString().substr(0, 10),
        dateFormatted1: new Date().toLocaleDateString('es-MX', { timeZone: 'America/Mexico_City', year: 'numeric', month: '2-digit', day: '2-digit' }),
        dateFormatted2: new Date().toLocaleDateString('es-MX', { timeZone: 'America/Mexico_City', year: 'numeric', month: '2-digit', day: '2-digit' }),
        dateMili1: '',
        dateMili2: '',
        dialog: false,
        dialog2: false,
        dialogHelp: false,
        dialogProgress: true,
        menu1: false,
        menu2: false,
        modal: false,        
        subTotPagado: 0,
        search: '',
        colaborador: [],
        colaboradores: [],
        comandas: [],        
        comsNoPags: [],
        comsPags: [],
        pedidoTot: [],
        pedidosPagados: [],
        pedidosNoPagados: [],
        comanda: {
            cliid: '',
            autor: '',
            fecha: new Date().toISOString().substr(0, 10),
            caja: '',
            zona: '',
            mesa: '',
            comid: '',
            notas: '',            
            pedidoTot: [''],
            estid: '',
            estatusPago: 'Pendiente de Pago'
        },
        pedidosPorComanda: [],
        headers: [
          { text: 'Fecha', value: 'fecha' },
          {
            text: 'Cliente',
            align: 'left',
            value: 'cliente'
          },
          { text: 'Estado-Pago', value: 'estatusPago' },
          { text: 'Monto-Pagado', value: 'montoPagado' },
          { text: 'Forma-Pago', value: 'formaPago' },
          { text: 'Hora', value: 'hora' },
          { text: 'Tipo-Venta', value: 'formaPedido' },
          { text: 'Autor', value: 'mesero.userName' },
          { text: 'Caja', value: 'caja' },
          { text: 'clave-documento', value: 'comid' },
          { text: 'Consulta', value: '', align: 'center' },
        ],
        headers2: [
          {
            text: 'Producto',
            align: 'left',
            value: 'producto'
          },
          { text: 'Cant', value: 'cantidad', align: 'center', },
          { text: '$Precio', value: 'precio' },
          { text: 'Unidad', value: 'unidad', align: 'center', },
          { text: 'Sub-Total', value: 'pxq' },
        ],
    }),
    created() {
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        computedDateFormatted1 () {
            return this.formatDate1(this.date1)
        },
        computedDateFormatted2 () {
            return this.formatDate2(this.date2)
        },
        sumComandasPagadas() {
            return this.comandas.reduce((sum, comanda) => {
            if (comanda.estatusPago === 'Pagada') {
                return sum + comanda.montoPagado;
            }
            return sum;
            }, 0);
        },
        sumComandasPagadasByPaymentType() {
            return function(paymentType) {
            return this.comandas.reduce((sum, comanda) => {
                if (comanda.estatusPago === 'Pagada' && comanda.formaPago === paymentType) {
                return sum + comanda.montoPagado;
                }
                return sum;
            }, 0);
            };
        },
        sumPedsNoPagados() {

            var i;
            var sum = 0;
            for ( i=0; i < this.pedidosNoPagados.length; i++) {

                this.pedidosNoPagados[i].forEach(pedXcid => {
                    
                    sum += pedXcid.pxq;
                });
            }
            return sum
        },
        subTotPorComanda () {
            var sum = 0;
            this.pedidosPorComanda.forEach(x => {
                sum += x.pxq;
            });
            return sum
        },
        impuesto () {
            var imp = this.subTotPorComanda * .16
            // return imp
            return +(Math.round(imp + "e+2")  + "e-2");
        },
        totSum () {
            var tS = this.impuesto + this.subTotPorComanda
            return tS
        }
    },
    watch: {
      date1 () {
        this.dateFormatted1 = this.formatDate1(this.date1)
      },
      date2 () {
        this.dateFormatted2 = this.formatDate2(this.date2)
      },
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        emptyFields() {
            this.comanda = this.defaultItem
            this.dialog = false
        },
        async abrirComanda(comanda) {    
            this.comanda = comanda;
            this.pedidosPorComanda = this.pedidoTot.filter(pedXcom => pedXcom.comid == comanda.comid);
        },
        formatDate1 (date1) {
            if (!date1) return null

            const [year, month, day] = date1.split('-')
            return `${day}/${month}/${year}`
        },
        formatDate2 (date2) {
            if (!date2) return null

            const [year, month, day] = date2.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate1 (date1) {
            if (!date1) return null

            const [month, day, year] = date1.split('/')
            return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
        },
        parseDate2 (date2) {
            if (!date2) return null

            const [month, day, year] = date2.split('/')
            return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
        },
        // dateMili1F (date1) {
        //     this.dateMili1 = new Date(date1).getTime()
        // }, 
        // dateMili2F (date2) {
        //     this.dateMili2 = new Date(date2).getTime()
        // },
        async consultarComandas() {     
            // let userNameParametro = this.$route.params.userName.toLowerCase()
            this.pedidoTot = []
            // let userNameDoc = await db.collection('userNames')
            //                             .doc(userNameParametro)
            //                             .get()

            // if(userNameDoc.exists) {
            //     let userName = userNameDoc.data()            
            
            try {
                this.dateMili1 = new Date(this.date1).getTime()//dia en milisegundos al empezar el día
                this.dateMili2 = Number(new Date(this.date2).getTime() + 86400000) // dia en milisegundos al termina el dia alsumar milisegundos
                // this.consultarPedidos()
                if (this.dateFormatted1 == this.dateFormatted2) {                        
                    // Dividimos el valor de this.dateFormatted2 en día, mes y año
                    const [day, month, year] = this.dateFormatted2.split('/');
                    // Creamos una nueva fecha en formato "dd/MM/yy" (por ejemplo: "18/07/23")
                    const formattedDate2 = `${day}/${month}/${year.slice(-2)}`;
                    this.comandas = []
                    let resultadoCom =  await db.collectionGroup('comandas')
                                        .where('userNameHost', '==', this.usuario.userName)
                                        .where('fecha', '==', formattedDate2)
                                        .get()  
                                        
                    resultadoCom.docs.forEach(subdoc => {
                        
                        let comandasInd = subdoc.data()
                        this.comandas.push(comandasInd)
                            
                    })

                } else {
                // } else if (this.dateFormatted1 != this.dateFormatted2) {
                    
                    this.comandas = []
                    let resultadoCom =  await db.collectionGroup('comandas')
                                        .where('userNameHost', '==', this.usuario.userName)
                                        // .where('fecha', '==', this.date1)                          
                                        // .where('fecha', '==', this.date2)
                                        .where('fechaMili', '>=', this.dateMili1)
                                        .where('fechaMili', '<=', this.dateMili2)                                            
                                        .get()  

                    resultadoCom.docs.forEach(subdoc => {
                        
                        let comandasInd = subdoc.data()                                              
                        this.comandas.push(comandasInd)
                        
                    })                
                }
                                    
            }
            catch (error) {                
                this.mostrarError('No se encontró información para desplegar.')              
            }
            
            // this.$nextTick(function () {
                        //     this.pedidoTot = []
            this.consultarPedidos()
                        // })
        },
        async consultarPedidos() {         
            // let userNameParametro = this.$route.params.userName.toLowerCase()
            // let userNameDoc = await db.collection('userNames')
            //                                 .doc(userNameParametro)
            //                                 .get()

            // if(userNameDoc.exists) {
            //     let userName = userNameDoc.data()

            try {
                
                if (this.dateFormatted1 == this.dateFormatted2) {
                    
                    let resultadoPed =  await db.collectionGroup('pedidoTot')
                                        .where('userNameHost', '==', this.usuario.userName)
                                        .where('fecha', '==', this.date2)                            
                                        .get()  

                    await resultadoPed.docs.forEach(subdoc => { // resultadoPed.docs.forEach(subdoc  quité docs
                        
                        let pedidoIs = subdoc.data()
                        this.pedidoTot.push(pedidoIs)
                            
                    })

                } else {

                    let resultadoPed =  await db.collectionGroup('pedidoTot')
                                        .where('userNameHost', '==', this.usuario.userName)
                                        .where('fechaMili', '>=', this.dateMili1)
                                        .where('fechaMili', '<=', this.dateMili2)                               
                                        .get()  

                    await resultadoPed.docs.forEach(subdoc => {
                        
                        let pedidoIs = subdoc.data()                                              
                        this.pedidoTot.push(pedidoIs)
                    })
                    
                }
                
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los pedidos.')              
            }
            // }
            this.consultarPedidosPagados()
            this.consultarPedidosNoPagados()
        },
        async consultarPedidosPagados () {            
            
            this.pedidosPagados = []
            this.comsPags = await this.comandas.filter(comPag => comPag.estatusPago == "Pagada")

            
                this.comsPags.forEach(subdoc => {                    
                    
                    let pedsPag = this.pedidoTot.filter(ped => ped.comid == subdoc.comid)

                    this.pedidosPagados.push(pedsPag)

                })
        },
        async consultarPedidosNoPagados () {

            this.pedidosNoPagados = []
            this.comsNoPags = await this.comandas.filter(comNoPag => comNoPag.estatusPago == "Pendiente de Pago")
            
            this.comsNoPags.forEach(subdoc => {                    
                    
                    let pedsNoPag = this.pedidoTot.filter(ped => ped.comid == subdoc.comid)

                    this.pedidosNoPagados.push(pedsNoPag)

                }) 
        },
        async movingSameHost() {
            await dbL.collection('fromSameHost').set([])
            await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName.toLowerCase() })
            this.$router.go(-1)
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder c esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder c esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido c tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido c tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder c esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder c esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            this.consultarComandas()
            this.dialogProgress = false
        },
    }
}
</script>