let ExpedienteContable = () => import( '@/views/despachoContable/ExpedienteContable.vue')
let EdicionImgComprobantes = () => import( '@/views/despachoContable/EdicionImgComprobantes.vue')
let EdicionImgCompHonorarios = () => import( '@/views/despachoContable/EdicionImgCompHonorarios.vue')
let VistaExpediente = () => import( '@/views/despachoContable/VistaExpediente.vue')
let VistaExpNum = () => import( '@/views/despachoContable/VistaExpNum.vue')
let VistaHonorarios = () => import( '@/views/despachoContable/VistaHonorarios.vue')
let fotoComprobante = () => import( '@/views/despachoContable/fotoComprobante.vue')

export default [

    {
      path: '/:userName/expedientes-contables',
      name: 'expedienteContable',
      component: ExpedienteContable,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/expedientes-contables/:expid/:fecha/img-comprobantes',
      name: 'edicion-comprobantes-img',
      component: EdicionImgComprobantes,
      props: true,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/expedientes-contables/:expid/:fecha/img-comphonorarios',
      name: 'edicion-comphonorarios-img',
      component: EdicionImgCompHonorarios,
      props: true,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/expedientes-contables/:expid/vista',
      name: 'vista-expediente',
      component: VistaExpediente,
      props: true,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/expedientes-contables/:expid/vistaNum',
      name: 'vista-expNum',
      component: VistaExpNum,
      props: true,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/expedientes-contables/:expid/vistaHonorarios',
      name: 'vista-honorarios',
      component: VistaHonorarios,
      props: true,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/expedientes-contables/:expid/:fecha/:tipoComprobante',
      name: 'vista-comprobante',
      component: fotoComprobante,
      props: true,
      meta: {
        autenticado: true
      }
    },

]

