<template>
  <div>
    <v-flex xs12 ml-3 mt-2>
        <v-btn icon dark>                
            <a @click="movingSameHost"><v-icon small dark left color="black">arrow_back</v-icon></a>
        </v-btn>
        <span class="body-2">oficina virtual</span>
    </v-flex>
    <v-toolbar flat color="white">
      <v-toolbar-title>Mensajes de Ventanilla</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-btn @click="saveVentanilla" flat transparent class="caption text-capitalize">
          <v-switch v-model="usuarioHost.ventanilla"></v-switch>
        {{ usuarioHost.ventanilla ? 'Mensajes de Ventanilla ACTIVADO' : 'Mensajes de Ventanilla DESACTIVADO' }}
      </v-btn>
      <!-- <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.name" label="Dessert name"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.calories" label="Calories"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.fat" label="Fat (g)"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.carbs" label="Carbs (g)"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.protein" label="Protein (g)"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="mnsjsVentanilla"
      class="elevation-1"
    >
      <template v-slot:items="props">
        <td>{{ props.item.cliente }}</td>
        <td class="text-xs-right">{{ props.item.contenidoMsj }}</td>
        <td class="text-xs-right">{{ props.item.hora }}</td>
        <td class="text-xs-right">{{ props.item.fecha }}</td>
        <td class="justify-center layout px-0">
          <v-icon
            small
            class="mr-2"
            @click="editItem(props.item)"
          >
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteItem(props.item)"
          >
            delete
          </v-icon>
        </td>
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { db } from '@/firebase'
import Localbase from 'localbase'

let dbL = new Localbase('db')

  export default {
    data: () => ({
      dialog: false,
      headers: [
        {
          text: 'Cliente',
          align: 'left',
          sortable: false,
          value: 'cliente'
        },
        { text: 'Mensaje', value: 'contenidoMsj' },
        { text: 'Hora', value: 'hora' },
        { text: 'Fecha', value: 'fecha' },
      ],
      mnsjsVentanilla: [],
      editedIndex: -1,
      editedItem: {
        cliente: '',
        contenidoMsj: '',
        hora: '',
        fecha: '',
      },
      defaultItem: {
        cliente: '',
        contenidoMsj: '',
        hora: '',
        fecha: '',
      }
    }),

    computed: {
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      ...mapState('usuarioHost', ['perfilAuth']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.consultarWhereFrom()
      this.actualizarPerfilAuth()
    },

    methods: {
      ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
      async movingSameHost() {
          await dbL.collection('fromSameHost').set([])
          await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName })
          this.$router.go(-1)
      },
      async consultarWhereFrom() {
          await dbL.collection('fromSameHost').get().then(fP => {
              this.sameHost = fP[0].sameHost
              this.sameHostName = fP[0].userHost
          })
          this.consultarColLB()
      },
      async consultarColLB() {
          if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName ) {
              // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
              this.$router.push({ name: 'Entrada' })
          } else if (this.sameHost == true) {
          
              this.colaboradores = []
              this.colaborador = ''
              if (this.perfilAuth == true) {
                  this.colaborador = this.usuarioHost
                  //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
              } else if (this.perfilAuth == false) {
              
                  await dbL.collection('colaboradores').get().then(cols => {
                  this.colaboradores = cols
                  })

                  if (this.colaboradores.length > 0) {
                      let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                      if (typeof colaborador === 'object') {
                          let convertINarray = Object.values(colaborador.estatusAccesos)
                          delete colaborador.estatusAccesos
                          colaborador.estatusAccesos = convertINarray
                          this.colaborador = colaborador  
                      } else {
                          this.colaborador = this.usuarioHost
                          //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                      } 
                  } else {
                      // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                      this.$router.push({ name: 'Entrada' })  
                  }
              } else {
                  // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                  this.$router.push({ name: 'Entrada' })
              }
          }
          await dbL.collection('fromSameHost').delete()
          this.sameHost = false
          this.sameHostName = ''
          this.consultarMnsjsVentanilla()
      },
      async consultarMnsjsVentanilla () {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                        .doc(userNameParametro)
                        .get()

        if(userNameDoc.exists) {
            let userHost = userNameDoc.data()
            this.mnsjsVentanilla = []
            
            try {
                
                let resultado = await db.collection('usuarios')
                                        .doc(userHost.uid)
                                        .collection('ventanilla')
                                        .get()                
                    
                await resultado.docs.forEach(doc => {
                    
                    let msjdb = doc.data()
                    this.mnsjsVentanilla.push(msjdb)
                })
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los mensajes. Tal vez haya problemas con la señal. Intenta más tarde.')                
            }
        }
        this.ocultarOcupado()
      },
      async saveVentanilla() {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

        this.mostrarOcupado({ titulo: 'Modificando Tipos de Auto-Pedido', mensaje: 'Cargando datos...' })

        if(userNameDoc.exists) {
                let userName = userNameDoc.data()

            try {
                let ventanilla = this.usuarioHost.ventanilla || false

                await db.collection('usuarios')
                        .doc(userName.uid)
                        .update({
                                ventanilla: ventanilla,
                        })                    

                this.mostrarExito('El estatus de recepción de mensajes en Ventanilla ha sido Modificado')
            
            }              
            catch (error) {
                this.mostrarError('Ocurrió un error configurando recepción de mensjes de ventanilla.')
            }
            finally {
                this.ocultarOcupado()
            }
        }
      },

      editItem (item) {
        this.editedIndex = this.mnsjsVentanilla.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.mnsjsVentanilla.indexOf(item)
        confirm('Are you sure you want to delete this item?') && this.mnsjsVentanilla.splice(index, 1)
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.mnsjsVentanilla[this.editedIndex], this.editedItem)
        } else {
          this.mnsjsVentanilla.push(this.editedItem)
        }
        this.close()
      }
    }
  }
</script>