import { vuexfireMutations } from 'vuexfire'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import sesion from './sesion'
import usuarioHost from './usuarioHost'
import fzsPracticas from './fzsPracticas'
import cartcatalogo from './cartcatalogo'
import despConta from './despConta'
// import { storage } from 'firebase-admin'
// import { db } from '@/firebase'

Vue.use(Vuex)

export default new Vuex.Store({  
  state: {
    notificacion: {
      visible: false,
      mensaje: '',
      color: 'info'
    },
    ocupado: {
      visible: false,
      titulo: '',
      mensaje: ''
    },
  },
  mutations: {
    ...vuexfireMutations,
    mostrarInformacion(state, mensaje) {
      state.notificacion.mensaje = mensaje
      state.notificacion.color = 'info'
      state.notificacion.visible = true
    },
    mostrarExito(state, mensaje) {
      state.notificacion.mensaje = mensaje
      state.notificacion.color = 'success'
      state.notificacion.visible = true
    },
    mostrarAdvertencia(state, mensaje) {
      state.notificacion.mensaje = mensaje
      state.notificacion.color = 'warning'
      state.notificacion.visible = true
    },
    mostrarError(state, mensaje) {
      state.notificacion.mensaje = mensaje
      state.notificacion.color = 'error'
      state.notificacion.visible = true
    },
    ocultarNotificacion(state) {
      state.notificacion.visible = false
    },
    mostrarOcupado(state, ocupado) {
      state.ocupado.titulo = ocupado.titulo
      state.ocupado.mensaje = ocupado.mensaje
      state.ocupado.visible = true
    },
    ocultarOcupado(state) {
      state.ocupado.visible = false
    },
  },
  actions: {
    
  },
  getters: {

  },
  modules: {
    sesion,
    usuarioHost,
    cartcatalogo,
    despConta,
    fzsPracticas,
  },
  plugins: [
    new VuexPersistence({
      storage: window.localStorage
    }).plugin
  ]
})
