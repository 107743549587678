<template>
    <v-list-tile v-if="usuarioHost && usuarioHost.userName == 'llogaran'" >          
        <v-list-tile-action>
        <div class="square" @click="openGiros()" :style="{'background-color': paletaColores.segundo}"><v-icon color="white">mdi-apps</v-icon></div>
        </v-list-tile-action>
        <v-list-tile-content>
        <v-list-tile-title  @click="openGiros()" class="mt-2 white--text">Aplicaciones</v-list-tile-title>
        </v-list-tile-content>
        <v-dialog v-model="dialogApps" fullscreen hide-overlay transition="dialog-bottom-transition">            
        <v-card>
            <v-toolbar flat class="white logoFont">
            <v-btn icon @click="dialogApps = false">
                <v-icon>close</v-icon>
            </v-btn>
            <h3>apps nuVin adicionales que puedes incluir en tu cuenta</h3>
            <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>                
            <v-container fluid class="mt-3">            
                <v-layout justify-space-around row wrap>
                    <template>
                        <div v-for="item in filteredItemsExtraAppsUser" :key="item.app">
                            <v-flex xs12 >                                
                                <template>
                                    <v-layout row justify-center>
                                        <div class="custom-content my-2" style="height: 120px;">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-badge overlap color="green">
                                                        <template v-slot:badge>
                                                            <v-icon dark small v-on="on">done</v-icon>
                                                        </template>
                                                        <v-btn fab small dark color="principal">
                                                            <v-icon>{{ item.icon }}</v-icon>
                                                        </v-btn>
                                                    </v-badge>
                                                </template>
                                                <span>Ya tienes una subscripción de esta app</span>
                                            </v-tooltip>                                                                                             
                                            <span class="logoFont mt-3 text-none font-weight-medium white--text" style="font-size: 17px;">{{ item.app }}</span>
                                            <div class="description-content text-none font-weight-light">{{ item.descripcion }}
                                                <v-divider></v-divider>
                                            <v-dialog v-model="dialog" max-width="500px">
                                                <template v-slot:activator="{ on }">                                            
                                                    <div class="text-xs-right">
                                                        <v-btn small round class="text-none" v-on="on" @click="selectItem(item)">complementos</v-btn>
                                                    </div>
                                                </template>
                                            </v-dialog>
                                            </div>                                                
                                        </div>
                                    </v-layout>
                                </template>
                            </v-flex>
                        </div>
                        <div v-for="item in filteredItemsApps" :key="item.app">
                            <v-flex xs12 >                                
                                <template>
                                    <v-layout row justify-center>
                                        <div class="custom-content my-2">
                                            <v-btn fab dark small color="principal">
                                            <v-icon dark>{{ item.icon }}</v-icon>
                                            </v-btn>
                                            <span class="logoFont mt-3 text-none font-weight-medium white--text" style="font-size: 20px;">{{ item.app }}</span>
                                            <div class="description-content text-none font-weight-light">{{ item.descripcion }}</div>
                                            <v-divider></v-divider>
                                            <v-dialog v-model="dialog" max-width="500px">
                                                <template v-slot:activator="{ on }">                                            
                                                    <div class="text-xs-right">
                                                        <v-btn small round class="text-none" v-on="on" @click="selectItem(item)">ver más...</v-btn>
                                                    </div>
                                                </template>
                                                <v-card
                                                    class="mx-auto" 
                                                    max-width="500"            
                                                    v-if="selectedItem"
                                                    >
                                                    <v-layout align-center justify-center row mt-1 py-3>
                                                    <v-flex xs7 text-xs-center>
                                                        <v-img
                                                        v-if="selectedItem.fotoLogoApp"
                                                        :src=selectedItem.fotoLogoApp
                                                        aspect-ratio="1.75"
                                                        ></v-img>
                                                        <v-btn v-else fab dark large color="principal">
                                                        <v-icon large >{{ selectedItem.icon }}</v-icon>
                                                        </v-btn>           
                                                    </v-flex>
                                                    <v-flex xs5 text-xs-center>
                                                        <strong class="logoFont black--text">nu<strong class="principal--text">Vin</strong></strong><br>
                                                        <span class="headline">{{ selectedItem.app }}</span><br>
                                                        <span class="caption grey--text">sofware-web-aplicación</span><br>
                                                        <div v-if="selectedItem.secciones">
                                                        <v-btn
                                                            color="principal" 
                                                            dark 
                                                            depressed 
                                                            class="text-none" 
                                                            small
                                                            @click="instalarApp()"
                                                        >
                                                            <v-icon>get_app</v-icon>
                                                            adquirir acceso
                                                        </v-btn>
                                                        </div>
                                                        <template v-if="!selectedItem.secciones">
                                                        <v-layout row justify-center>
                                                            <v-dialog v-model="dialogSolAsesor" persistent max-width="290">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" color="principal" 
                                                                    dark 
                                                                    depressed 
                                                                    class="text-none" 
                                                                    small
                                                                >
                                                                    Solicitar</v-btn>
                                                            </template>
                                                            <v-card>
                                                                <v-card-title class="headline">Solicitar Cotización o más información:</v-card-title>
                                                                <v-card-text>Por favor comunícate directamente con tu asesor o 
                                                                <v-card-title class="subheading font-weight-medium">contáctanos:</v-card-title>
                                                                <v-card-text>
                                                                    <span><span class="principal--text font-weight-bold">email:</span>  nubesdebits@gmail.com</span><br>
                                                                    <span><span class="principal--text font-weight-bold">móvil-whatsapp:</span>  +529996470286</span><br>
                                                                </v-card-text>
                                                                </v-card-text>
                                                                <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn color="prinicipal" flat @click="dialogSolAsesor = false">Enterado</v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                            </v-dialog>
                                                        </v-layout>
                                                        </template>
                                                    </v-flex>
                                                    </v-layout>
                                                    <v-divider></v-divider>
                                                    <v-card-title class="title font-weight-regular justify-space-between">
                                                    <span>descripción</span>
                                                    </v-card-title>
                                                    <v-card-text class="py-0 text-xs-left">
                                                    <span class="caption grey--text">
                                                        {{ selectedItem.descripcion }}
                                                    </span>
                                                    </v-card-text>
                                                    <v-card-title class="title font-weight-regular justify-space-between">
                                                    <!-- <span>{{ selectedItem.app }}</span> -->
                                                    <span>características-funciones</span>
                                                    </v-card-title>
                                                    <v-card-text v-for="element in selectedItem.funciones" :key="element.index" class="py-0 text-xs-left">
                                                    <span class="caption grey--text">
                                                        {{ element }}
                                                    </span>
                                                    </v-card-text>

                                                    <div v-if="selectedItem.precioMensualSemilla || selectedItem.precioAnualSemilla">
                                                    <v-card-title class="title font-weight-regular justify-space-between">
                                                        <span>precios</span>
                                                    </v-card-title>
                                                    <v-card-text class="py-0 text-xs-left">
                                                        <span class="caption grey--text">
                                                        {{ selectedItem.descPrueba }}
                                                        </span><br>
                                                        <span class="subheading">precios desde</span><br>
                                                        <span class="caption grey--text">
                                                        mensual: ${{ selectedItem.precioMensualSemilla }} mxn
                                                        </span><br>
                                                        <span class="caption grey--text">
                                                        anual: ${{ selectedItem.precioAnualSemilla }} mxn
                                                        </span><br>
                                                        <span class="subheading">precios hasta</span><br>
                                                        <span class="caption grey--text">
                                                        mensual: ${{ selectedItem.precioMensualEmpresario }} mxn
                                                        </span><br>
                                                        <span class="caption grey--text">
                                                        anual: ${{ selectedItem.precioAnualEmpresario }} mxn
                                                        </span>
                                                    </v-card-text>
                                                    </div>

                                                    <v-card-title class="title font-weight-regular justify-space-between">
                                                    <span>información extra</span>
                                                    </v-card-title>
                                                    <v-card-text class="py-0 text-xs-left">
                                                    <span class="caption grey--text">
                                                        °Tamaño: dentro del equipo no se ocupa nada de espacio. Los datos son almacenados en la nube virtual. <br>
                                                        °Dispositivo para los que funciona: móvil, tableta, laptop, computadores de escritorio, <br>
                                                        °Fecha de ultima actualización: {{ selectedItem.fechaUltUpdate }}
                                                    </span>
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <div v-if="selectedItem.secciones">
                                                    <v-card-title class="title font-weight-regular justify-space-between">
                                                        <span>adquirir acceso</span>
                                                    </v-card-title>
                                                    <v-card-text class="py-0 text-xs-left">
                                                        <span class="caption grey--text">
                                                        Al dar click en adquirir acceso, por seguridad de la información que ingresarás, se te pedirá un 
                                                        email, password y el nombre de usuario como quieres ser reconocido en la nuVin.
                                                        </span>
                                                    </v-card-text>
                                                    </div>
                                                    <v-card-actions>          
                                                    <v-flex v-if="selectedItem.secciones" class="text-xs-right" >
                                                        <v-btn
                                                        color="principal" 
                                                        dark 
                                                        depressed 
                                                        class="text-none" 
                                                        small
                                                        @click="instalarApp()"
                                                        >
                                                        <v-icon>get_app</v-icon>
                                                        adquirir acceso
                                                        </v-btn>
                                                    </v-flex>     
                                                    <v-flex v-if="!selectedItem.secciones">
                                                        <v-dialog v-model="dialogSolAsesor" persistent max-width="290">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" color="principal" 
                                                                dark 
                                                                depressed 
                                                                class="text-none" 
                                                                small
                                                            >
                                                                Solicitar</v-btn>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="headline">Solicitar Cotización o más información:</v-card-title>
                                                            <v-card-text>Por favor comunícate directamente con tu asesor o envía un email con tus datos a: 
                                                            <strong>nubesdebits@gmail.com</strong> y con gusto nos comunicaremos contigo.
                                                            </v-card-text>
                                                            <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="principal" flat @click="dialogSolAsesor = false">Enterado</v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                        </v-dialog>
                                                    </v-flex>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="principal" flat="flat" @click="dialog = false">Cerrar</v-btn>        
                                                    </v-card-actions>        
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                    </v-layout>
                                </template>
                            </v-flex>
                        </div>
                    </template>
                </v-layout>
            </v-container>              
        </v-card>
    </v-dialog>   
    </v-list-tile>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { db} from '@/firebase'

export default {
    props: {
        extraAppsUser: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            dialog: false,
            dialogApps: false,
            dialogSolAsesor: false,
            itemsApps: [],
            selectedItem: null
        }
    },

    computed: {
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('sesion', ['usuario']),
        filteredItemsApps() {
            return this.itemsApps.filter(selectedItem => {
            const excludedApps = ["personal", "concentrado-funciones",];
            return (
                !excludedApps.includes(selectedItem.app) &&
                !this.extraAppsUser.some(extraApp => extraApp.app === selectedItem.app)
            );
            });
        },
        filteredItemsExtraAppsUser() {
            const excludedApps = ["personal", "concentrado-funciones"];
            return this.extraAppsUser.filter(extraApp => !excludedApps.includes(extraApp.app));
        },
        paletaColores() {
          if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
            return this.usuarioHost.designColors;
          } else {
            // const colores = ['red', 'green', 'blue', 'purple', 'orange'];
            // const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
            return {
              primero: 'principal',
              segundo: 'secondary',
              // tercero: colorAleatorio,
              // cuarto: colorAleatorio
            };
          }
        },
    },

    methods: {
        ...mapMutations(['ocultarNotificacion', 'mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        async instalarApp() {
            
            let userNameParametro = this.$route.params.userName.toLowerCase();
            
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });
            
            let userNameDoc = await db.collection('userNames')
                .doc(userNameParametro)
                .get();

            if (userNameDoc.exists) {
                var userName = userNameDoc.data();
                const appName = this.selectedItem.app.toLowerCase();

                const secciones = this.selectedItem.secciones;
                const element = secciones
                .find(seccion => seccion.app.toLowerCase() === appName)
                ?.accesosCols;
                const permisos = secciones
                .find(seccion => seccion.app.toLowerCase() === appName)
                ?.permisos;
                
                if (element) {
                const fecha = this.obtenerFechaActual();
                const nuevoElemento = {
                    accesosCols: element,
                    permisos: permisos,
                    activa: 'Prueba',
                    app: this.selectedItem.app,
                    fecha: fecha,
                    funcion: 'principal',
                    icon: this.selectedItem.icon,
                    interaccion: false,
                    tipoApp: 'seccion',
                    valor: false
                };

                this.$emit('agregarAppExtra', nuevoElemento);

                await db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra')
                    .doc(this.selectedItem.app)
                    .set(nuevoElemento);
                }
            } else {
                // this.mostrarError('aaa');
            }
            this.dialog = false;
            this.ocultarOcupado();
        },

        // Función para obtener la fecha actual en formato "24/5/2023"
        obtenerFechaActual() {
            const fecha = new Date();
            const dia = fecha.getDate();
            const mes = fecha.getMonth() + 1;
            const anio = fecha.getFullYear();
            return `${dia}/${mes}/${anio}`;
        },


        selectItem() {
            // this.selectedItem = selectedItem;
            window.alert('sección en desarrollo')
        },
        isAppDisabled(app) {
            return this.extraAppsUser.some(extraApp => extraApp.app === app);
        },
        async getitemsApps() {
      
            try {
                let resultado = await db.collection('itemsApps')
                                        .get()
                
                resultado.docs.forEach(doc => {
                    let selectedItem = doc.data()
                    this.itemsApps.push(selectedItem)    
                })

            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando las apps.')                
            }
        },
        async openGiros() {
            this.itemsApps = []; // vaciar la matriz antes de llamar a getitemsApps
            this.getitemsApps()
            this.dialogApps = true
        },
    },
}
</script>

<style>
    .square {
        width: 40px;
        height: 40px;
        /* background-color: blue; */
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .custom-content {
        border-radius: 10px;
        background-color: #CE93D8;
        padding: 10px;
        text-align: left;
        width: 250px; /* Puedes ajustar el ancho máximo del cuadro según tus necesidades */
        max-width: 100%; /* Limita el ancho máximo del cuadro */
        max-height: 220px;
        overflow: hidden; /* Oculta el contenido que supere la altura máxima */
        position: relative;
    }

    .logoFont {
        margin-left: 10px;
    }

    .description-content {
        margin-top: 10px;
        white-space: normal; /* Permite que el texto se ajuste automáticamente */
        word-wrap: break-word; /* Rompe las palabras largas para ajustarse al ancho del cuadro */
    }
</style>
