<template>
  <!-- <v-layout row justify-left> -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        <v-btn small flat icon v-on="on">
            <slot name="nombrebtn"/>            
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="subheading">¿Estás seguro de querer eliminar<slot name="elemento"/></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="dialog = false">Cancelar</v-btn>
          <v-btn @click="dialog = false">
            <slot name="btnaceptar"/>
          </v-btn>                
        </v-card-actions>
      </v-card>
    </v-dialog>
  <!-- </v-layout> -->
</template>

<script>
  export default {
    data () {
      return {
        dialog: false
      }
    }
  }
</script>