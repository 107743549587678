<template>
    <v-layout align-center justify-center fill-height>
    <v-flex xs10 md4>
        <v-card>
            <v-card-title>
            <span class="headline">Crear Cita/Evento</span>
            <v-spacer></v-spacer>
            <div v-if="evClicked[0].cliente != 'agregar'">
                <estasSeguro v-if="colaboradorBorrar == true">
                    <v-icon
                        color="grey darken-2"
                        slot="nombrebtn"
                        small                                       
                        >
                        mdi-delete
                    </v-icon>
                    <div slot="elemento">
                        <span>este Elemento?</span>
                    </div>
                    <div slot="btnaceptar">
                        <v-btn flat @click="deleteEv(event)">Ok</v-btn>
                    </div>
                </estasSeguro>
                <v-icon
                    disabled
                    small
                    v-if="colaboradorBorrar == false"
                    class="ml-3 pl-3"
                >
                    mdi-delete
                </v-icon>
            </div>
            </v-card-title>

            <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                <v-flex xs12 sm6 d-flex>
                    <v-select
                    v-if="evClicked[0].cliente == 'agregar'"
                    v-model="editedItemEv"
                    :items="clientesdb"
                    item-text="cliente"
                    item-value="concepto"
                    label="Pacientes"
                    persistent-hint
                    return-object
                    single-line
                    ></v-select>
                    <span v-else class="pl-3">Paciente: <strong>{{evClicked[0].cliente}}</strong></span><br>
                </v-flex>
                <v-flex xs12 sm6 >
                    <span class="pl-3">Horario: <strong>{{evClicked[0].time}}</strong></span><br>
                    <span class="pl-3">Fecha: <strong>{{evClicked[0].fechaEvento}}</strong></span>
                </v-flex>
                <v-flex xs12 sm6 >
                    <!-- <v-text-field v-model="editedItemEv.concepto" label="Concepto" required @blur="$v.editedItemEv.concepto.$touch()" :error-messages="erroresConcepto"></v-text-field> -->
                    <v-text-field v-if="evClicked[0].cliente == 'agregar'" v-model="editedItemEv.concepto" label="Concepto" required></v-text-field>
                    <span v-else class="pl-3">Servicio: <strong>{{evClicked[0].concepto}}</strong></span><br>
                </v-flex>
                <v-flex xs12 sm6 >
                    <!-- <v-textarea
                    v-model="editedItemEv.activInit"
                    outline
                    name="input-6-4"
                    label="Actividad a realizar"
                    value=""
                    required @blur="$v.editedItemEv.activInit.$touch()" :error-messages="erroresActivInit"
                    ></v-textarea> -->
                </v-flex>                 
                </v-layout>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat :to="{ name: 'calendario', params: {userName: usuarioHost.userName} }">Cancelar</v-btn>
            <v-btn v-if="!editedItemEv.concepto || editedItemEv.concepto == ''" disabled>Guardar</v-btn>
            <v-btn v-if="editedItemEv.concepto && editedItemEv.concepto != ''" @click="saveEv">Guardar</v-btn>
            </v-card-actions>
        </v-card>    
    </v-flex>   
    </v-layout>   
</template>

<script>
import estasSeguro from '@/components/estasSeguro.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    components: { estasSeguro },
    data: () => ({
        editedItemEv: {
            cliente: '',
            concepto: '',
            cliid: ''
        },
        evClicked: [{
            time: '',
            fechaEvento: '',
            cliente: '',
            concepto: ''
        }],
        clientesdb: [],
        colaborador: '',
        fromSameHost: '',
        fromOfiuserHost: ''

    }),

    created() {
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()
        this.consulEvPicked()
    },

    computed: {
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      ...mapState('usuarioHost', ['perfilAuth']),
      colaboradorBorrar() {
        if(this.perfilAuth == true ){
            return true
        } else{
            return false
        }
      },
    },

    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        async movingSameHost() {
          await dbL.collection('fromSameHost').set([])
          await dbL.collection('fromSameHost').add({sameHost: true, userHost: this.usuarioHost.userName.toLowerCase()})
          this.$router.push({ name: 'calendario', params: {userName: this.usuarioHost.userName} })  
        },
        async consulEvPicked() {
            dbL.collection('evClicked').get().then(ev => {
                this.evClicked = ev
            })
        },
        async consultarClientes () {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()

                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('clientes')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let clidb = doc.data()                   
                        this.clientesdb.push(clidb)    
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Hay un problema con el internet favor de intentar más tarde.')              
            }
        },
        async saveEv () {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                var userName = userNameDoc.data()

                if (this.editedIndexEv > -1) {
                    this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                    Object.assign(this.cliEventos[this.editedIndexEv], this.editedItemEv)

                    try {
                        db.collection('usuarios')
                        .doc(userName.uid)
                        .collection('clientes')
                        .doc(this.clientePicked.cliid)
                        .collection('eventos-citas')
                        .doc(this.evClicked[0].cliEvid)
                        .update({
                            concepto: this.evClicked[0].concepto,
                            fechaEvento: this.evClicked[0].fechaEvento,
                            duration: this.evClicked[0].duration,
                            time: this.evClicked[0].fechaEvento.substr(-5,5),
                            activInit: this.evClicked[0].activInit,
                            activEnd: this.evClicked[0].activEnd,
                        })
                        this.mostrarExito('Se actualizó la información exitosamente.')                
                    }   
                    finally {
                        this.ocultarOcupado()
                    }

                } else {

                let cliEvid = uuidv4()

                let clienteEvObj = {
                    cliEvid,
                    cliente: this.editedItemEv.cliente,
                    cliid: this.editedItemEv.cliid,                  
                    fechaEvento: this.evClicked[0].fechaEvento,
                    duration: 60,
                    time: this.evClicked[0].time,
                    activInit: '',
                    activEnd: '',
                    concepto: this.editedItemEv.concepto,
                    autor: this.usuario.userName,                  
                    userNameHost: this.usuarioHost.userName,
                    open: false,
                    imgBefore: '',
                    imgAfter: ''
                }

                try {
                    await db.collection('usuarios') 
                            .doc(userName.uid)
                            .collection('clientes')
                            .doc(this.editedItemEv.cliid)
                            .collection('eventos-citas')
                            .doc(clienteEvObj.cliEvid)
                            .set(clienteEvObj)

                    // this.cliEventos.push(this.editedItemEv)
                    this.mostrarExito('Se cargó la información exitosamente.')
                }
                finally {                    
                    this.ocultarOcupado()
                }
                }
                // this.consulEventos()
                this.movingSameHost()
            } else {
                this.mostrarError('Hay un problema con la red, favor de intentar más tarde.')
            }
        },
        async deleteEv (itemEv) {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            // const index = this.eventsMap[date].indexOf(itemEv)
            // this.eventsMap[date].splice(index, 1)
        
            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('clientes')
                            .doc(itemEv.cliid)
                            .collection('eventos-citas')
                            .doc(itemEv.cliEvid)
                            .delete()                

                    this.mostrarExito('Se eliminó el elemento exitosamente.')
                }
            }
            finally {
                this.ocultarOcupado()
            }
            // this.consulEventos()
            this.$router.push({ name: 'calendario', params: { userName: this.usuarioHost.userName }})
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fOf => {
                this.fromSameHost = fOf[0].sameHost
                this.fromOfiuserHost = fOf[0].userHost
            })
            this.consultarColLB()
        },
        consultarColLB() {
            if (this.fromSameHost == false || this.fromOfiuserHost != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    // //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            // //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        }
                        
                        if (this.colaborador != '') {
                            if (this.perfilAuth != true) {              
                            // this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a la ofi-nuVin de '+`${this.usuarioHost.userName}`)
                            } 
                        } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })
                        }
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            this.consultarClientes()
            dbL.collection('fromSameHost').set([])
            dbL.collection('fromSameHost').add({fromSameHost: false, userHost: ''})
            this.fromSameHost = false
            this.fromOfiuserHost = ''
            
        },
    },
}
</script>        