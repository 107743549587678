<template>
    <div class="text-xs-center">
        <v-bottom-sheet v-model="sheet">
            <template v-slot:activator>
                <v-btn color="primary" dark small>
                    herramientas-utilidades
                </v-btn>
            </template>
            <v-list>
                <v-subheader>nuVin apps Herramientas</v-subheader>                
                <v-list-tile
                    v-for="(item, index) in itemsAppsHerr"
                    :key="index"
                    avatar
                    >
                    <v-layout row justify-center v-if="item.activa != 'Activada' && item">
                        <v-btn dark icon @click.stop="openDialogAppDisp(item)">
                            <v-list-tile-avatar >
                                <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
                            </v-list-tile-avatar>                       
                        </v-btn>
                        <v-dialog v-model="dialogAppDisp" persistent max-width="290">
                            <v-card v-if="itemSelect.length > 0">
                                <v-card-title class="headline">Planes para <br><strong> {{ itemSelect[0].app }}</strong></v-card-title>
                                <v-radio-group v-model="radiosPlan" :mandatory="false">
                                    <v-radio label="nuVin-Semilla" value="semilla"></v-radio>
                                    <div class="ml-3">{{ itemSelect[0].incluyeSemilla }} <br> Precio Anual: <strong>${{ itemSelect[0].precioMensualSemilla }},</strong> <br>
                                        Precio Anual (ahorro de dos meses): <strong>${{ itemSelect[0].precioAnualSemilla }}</strong>
                                    </div>
                                    <div v-if="radiosPlan == 'semilla'">
                                        <v-btn small color="primary" class="caption text-lowercase" @click="pagoTransfMensual(item.precioMensualSemilla)">mensual</v-btn>
                                        <v-btn small color="primary" class="caption text-lowercase" @click.stop="pagoTransfAnual(item.precioAnualSemilla)">anual</v-btn>
                                        <!-- <v-btn small color="primary" class="caption text-lowercase" @click="sendToCheckout(itemSelect[0].precioMensualSemillaStripe, item)">mensual</v-btn>
                                        <v-btn small color="primary" class="caption text-lowercase" @click="sendToCheckout(itemSelect[0].precioAnualSemillaStripe, item)">anual</v-btn> -->
                                    </div><br>

                                    <v-radio label="nuVin-Emprendedor" value="emprendedor"></v-radio>
                                    <div class="ml-3">{{ itemSelect[0].incluyeEmprendedor }} <br> Precio mensual: <strong>${{ itemSelect[0].precioMensualEmprendedor }},</strong> <br>
                                        Precio Anual (ahorro de dos meses): <strong>${{ itemSelect[0].precioAnualEmprendedor }}</strong>
                                    </div>
                                    <div v-if="radiosPlan == 'emprendedor'">
                                        <v-btn small color="primary" class="caption text-lowercase" @click="pagoTransfMensual(item.precioMensualEmprendedor)">mensual</v-btn>
                                        <v-btn small color="primary" class="caption text-lowercase" @click.stop="pagoTransfAnual(item.precioAnualEmprendedor)">anual</v-btn>
                                        <!-- <v-btn small color="primary" class="caption text-lowercase" @click="sendToCheckout(itemSelect[0].precioMensualEmprendedorStripe, item)">mensual</v-btn>
                                        <v-btn small color="primary" class="caption text-lowercase" @click="sendToCheckout(itemSelect[0].precioAnualEmprendedorStripe, item)">anual</v-btn> -->
                                    </div><br>

                                    <v-radio label="nuVin-Empresario" value="empresario"></v-radio>
                                    <div class="ml-3">{{ itemSelect[0].incluyeEmpresario }} <br> Precio mensual: <strong>${{ itemSelect[0].precioMensualEmpresario }},</strong> <br>
                                        Precio Anual (ahorro de dos meses): <strong>${{ itemSelect[0].precioAnualEmpresario }}</strong>
                                    </div>
                                    <div v-if="radiosPlan == 'empresario'">
                                        <v-btn small color="primary" class="caption text-lowercase" @click="pagoTransfMensual(item,precioMensualEmpresario)">mensual</v-btn>
                                        <v-btn small color="primary" class="caption text-lowercase" @click.stop="pagoTransfAnual(item.precioAnualEmpresario)">anual</v-btn>
                                        <!-- <v-btn small color="primary" class="caption text-lowercase" @click="sendToCheckout(itemSelect[0].precioMensualEmpresarioStripe, item)">mensual</v-btn>
                                        <v-btn small color="primary" class="caption text-lowercase" @click="sendToCheckout(itemSelect[0].precioAnualEmpresarioStripe, item)">anual</v-btn> -->
                                    </div><br>

                                    <div v-if="appsPruebaVda.some( aP => aP.app == itemSelect[0].app ) == false">
                                        <v-radio label="nuVin-Prueba" value="prueba"></v-radio>
                                        <div class="ml-3">{{ itemSelect[0].incluyePrueba }}</div>
                                        <div v-if="radiosPlan == 'prueba'">
                                            <v-btn small color="primary" class="caption text-lowercase" @click="generarPrueba(itemSelect[0])">prueba gratuita</v-btn>
                                        </div>
                                    </div>
                                </v-radio-group>                                
                                <v-card-text>
                                    Si requieres un Plan con un mayor número de permisos (colaboradores, productos y/o servicios), podrás 
                                    solicitarlo en la sección "nuVin Apps Extra".
                                </v-card-text>
                                <v-flex xs6>
                                    <v-text-field class="ml-3" v-model="codigoSoporte" label="exclusivoNuvit" outline></v-text-field>
                                    <v-btn @click="especialCheckout(itemSelect[0])" dark small color="primary" v-if="codigoSoporte == 'gratisfree'"><v-icon dark>favorite</v-icon></v-btn>            
                                </v-flex>
                                    <!-- poner boton de prueba prueba -->
                                <v-dialog v-model="dialogPagoTransfMensual" persistent max-width="290">
                                    <v-card>
                                        <v-card-title class="title">El monto a pagar por esta ocasión será de: <strong>${{ precioProporcionalMensual }} pesos MXN,</strong>
                                            el cual es el proporcional a los días 
                                            por transcurrir para el día de cobro (día 3 de cada mes).                                        
                                        </v-card-title>
                                        <v-btn :to="{  name: 'pagoConTransfElect', params: {userName: usuarioHost.userName, referencia: 'cuotaProp-plan'} }">hacer transferencia</v-btn>
                                        <!-- <pagoConTransfElect/> -->
                                        <!-- <pagoConTransfElect :datosItemPicked = "itemPicked"/> -->
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" flat @click="dialogPagoTransfMensual = false">Cerrar</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogPagoTransfAnual" persistent max-width="290">
                                    <v-card>
                                        <v-card-title class="headline">El monto a pagar será de: <strong>${{ precioProporcionalAnual }} pesos MXN,</strong>
                                            el cual es el proporcional a los días 
                                            que faltan para el día de cobro (día 3 de cada mes) del año siguiente.
                                        </v-card-title>
                                        <v-btn :to="{  name: 'pagoConTransfElect', params: {userName: usuarioHost.userName, referencia: 'cuotaProp-plan' } }">hacer transferencia</v-btn>
                                        
                                        <!-- <pagoConTransfElect/> -->
                                        <!-- <pagoConTransfElect :datosItemPicked = "itemPicked"/> -->
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" flat @click="dialogPagoTransfAnual = false">Cerrar</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" flat @click="dialogAppDisp = false">Cerrar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-layout>
                    <v-layout row justify-center v-else>
                        <v-btn dark icon @click.stop="dialogAppNoDisp = true">
                            <v-list-tile-avatar >
                                <v-badge overlap color="principal" >
                                    <template v-slot:badge>
                                        <v-icon dark small >mdi-check</v-icon>
                                    </template>
                                    <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
                                </v-badge>                                
                            </v-list-tile-avatar>
                        </v-btn>
                        <v-dialog v-model="dialogAppNoDisp" max-width="290">
                            <v-card >
                                <v-card-text>
                                    Esta App ya está contratada y en funcionamiento hasta el vencimiento del periodo de subscripción. <br>
                                    ¿Deseas suspender la contratación?.
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-layout>
                                        <v-btn
                                            color="primary"
                                            flat="flat"
                                            @click.stop="dialogCancelarApp = true"
                                        >
                                            Sí, suspender
                                        </v-btn>
                                        <v-btn color="primary" flat @click="dialogAppNoDisp = false">Cancelar</v-btn>
                                        <v-dialog v-model="dialogCancelarApp" persistent max-width="290">
                                            <v-card>
                                                <v-card-text>Estás seguro de querer cancelar la app <strong>{{ item.title }}</strong>.
                                                    Puedes renovar cuando gustes. Al cancelar seguirá en funcionamiento, hasta el
                                                    periodo de vencimiento de subscripción. Ya no aparecerá en el recibo de pago.
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <!-- <v-btn color="primary" flat @click="createPortalLink">Sí, estoy seguro.</v-btn> -->
                                                    <v-btn color="primary" flat @click="cancelarAppExtra(item)">Sí, estoy seguro.</v-btn>
                                                    <v-btn color="primary" flat @click="dialogCancelarApp = false">Cancelar</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-layout>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-layout>

                    <v-list-tile-content>
                    <v-list-tile-title>{{ item.app }}</v-list-tile-title>
                    </v-list-tile-content>

                    <v-list-tile-action>
                    <v-btn icon ripple>
                        <v-icon color="grey lighten-1">info</v-icon>
                    </v-btn>
                    </v-list-tile-action>
                </v-list-tile>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
// import pagoConTransfElect from '@/components/pagoConTransfElect';
import { db, auth } from '@/firebase';
import { doc, getDoc, getDocs, collection, updateDoc, setDoc, WriteBatch } from 'firebase/firestore'; 
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

  export default {
    // components: { pagoConTransfElect },
    props: [ 'estatusAppsExtraUser' ],
    data: () => ({
      codigoSoporte: '',
      radiosPlan: null,
      sheet: false,
      dialogAppDisp: false,
      dialogAppNoDisp: false,
      dialogPagoTransfMensual: false,
      dialogPagoTransfAnual: false,
      dialogCancelarApp: false,
      subscripciones: [],
      extraAppsUser: [],
      appExtraExistenteActiv: '',
    //   extraAppsOferta: [],
      itemsApps: [],
      itemSelect: [],
      appsPruebaVda: [],
      apps: [],
      itemAppExt: '',
      precioProporcionalMensual: '',
      precioProporcionalAnual: '',
      atoday: new Date().getTime(),
      atoday2: new Date(),
      atoday3: new Date(new Date().getFullYear()+1, new Date().getMonth(), 15,),
      atoday4: new Date(new Date().getFullYear()+1, 3, 15,),
      adiadelmes: new Date().getDate(),
    }),

    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        itemsAppsHerr()    {
            let arr1 = this.itemsApps.filter(itHrr => itHrr.tipoApp == 'herramienta' && itHrr.appComplemento.some(el => el == 'todas' || this.usuarioHost.app))
            let arr2 = this.extraAppsUser.filter(itHrr => itHrr.activa == 'Activada' && itHrr.tipoApp == 'herramienta')

            arr1 = arr1.concat(arr2) // merge two arrays
            let foo = new Map();
            for(const tag of arr1) {
            foo.set(tag.app, tag);
            }
            let final = [...foo.values()]
            return final
        },
    },

    created() {
        this.getItemsGiros()
        this.consulAppsEnPrueba()
        // this.consultarSubscriptions()
        this.appExtraExistenteActivada()
        this.extraAppsUser = this.estatusAppsExtraUser
        // this.extraAppsOferta = this.listaextraAppsOferta        
    },

    methods: {
        ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        async getItemsGiros() {
            try {
                const itemsAppsCollection = collection(db, 'itemsApps');
                const resultado = await getDocs(itemsAppsCollection);
                
                resultado.forEach(doc => {
                    let item = doc.data();
                    this.itemsApps.push(item);
                });

            } catch (error) {
                this.mostrarError('Ocurrió un error consultando las apps.');
            }
        },
        async openDialogAppDisp(item) {
            // this.itemSelect = item
            this.itemSelect = this.itemsApps.filter(itApD => itApD.app == item.app)
            if (this.itemSelect.length > 0) {
            this.dialogAppDisp = true    
            }
            
        },
        async cancelarAppExtra(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cancelando contratación de App Extra', mensaje: 'Enviando el nuevo ajuste...' });

            try {
                const userNameDocRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameDocRef);

                if (userNameDoc.exists()) {
                    const userName = userNameDoc.data();

                    // Actualiza el estado de la app extra
                    const appDocRef = doc(db, 'usuarios', userName.uid, 'comprasAppExtra', item.app);
                    await updateDoc(appDocRef, { activa: 'Desactivada' });
                }

            } catch (error) {
                // Manejo de errores si es necesario
                console.error('Error al cancelar la app extra:', error);
            } finally {
                this.ocultarOcupado();
                this.dialogCancelarApp = false;
                this.$router.push({ name: 'Entrada', params: { userName: this.usuarioHost.userName } });
            }
        },
        async appExtraExistenteActivada(item) {
            this.appExtraExistenteActiv = this.extraAppsUser.some(extr => extr.app === item.app
                                            && extr.activa === 'Activada') 
        },
        async especialCheckout(item) {
            this.mostrarOcupado({ titulo: 'Redireccionando Pago Seguro...', mensaje: 'Cargando datos...' });

            try {
                // Referencia al documento en 'comprasAppExtra'
                const compraDocRef = doc(db, 'usuarios', auth.currentUser.uid, 'comprasAppExtra', item.app);

                // Establecer los datos en el documento
                await setDoc(compraDocRef, {
                    fecha: new Date().toISOString().substr(0, 10),
                    activa: 'Activada',
                    app: item.app,
                    tipoApp: 'herramienta',
                    nivelPlan: 'prueba',
                    accesosCols: item.accesCols,
                    funcion: 'extra',
                    etiqueta: item.app,
                    icon: item.icon,
                    iconClass: item.iconClass,
                    valor: false,
                    interaccion: false,
                    priceStripe: '',
                });

                // Actualización de accesosCols si es necesario
                // const bH = item.accesCols.find(btn => btn.etiqueta === item.app);
                // if (bH) {
                //     const userDocRef = doc(db, 'usuarios', auth.currentUser.uid);
                //     await updateDoc(userDocRef, {
                //         accesosCols: arrayUnion({
                //             componente: bH.componente,
                //             etiqueta: bH.etiqueta,
                //             switch: bH.switch,
                //             funcion: 'extra',
                //             icon: item.icon,
                //             valor: false,
                //         })
                //     });
                // }
            } catch (error) {
                // Manejo de errores si es necesario
                console.error('Error al realizar el checkout:', error);
            } finally {
                this.ocultarOcupado();
                this.$router.push({ name: 'Entrada', params: { userName: this.usuarioHost.userName } });
            }
        },
        async consulAppsEnPrueba() {
            await dbL.collection('extraAppsUser').get().then(extr => {
                this.apps = extr
            })
            this.AppsEnPr()            
        },
        async AppsEnPr() {
            this.appsPruebaVda = await this.apps.filter(gi => gi.nivelPlan == 'prueba' && gi.activa == 'Desactivada')
            // this.appGiroNivelPlan = this.appGiro[0].nivelPlan
            // this.consultarGirosNegocios()
        },
        async generarPrueba(item) {
            this.mostrarOcupado({ titulo: 'Obteniendo prueba gratis', mensaje: 'Almacenando datos para la prueba de la app...' });

            let userNameParametro = this.$route.params.userName.toLowerCase();

            try {
                // Obtener la referencia del documento del usuario
                const userNameDocRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameDocRef);

                if (userNameDoc.exists()) {
                    const userName = userNameDoc.data();

                    const datosPrueba = {
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaInitPrueba: new Date(),
                        activa: 'Prueba',
                        app: item.app,
                        nivelPlan: this.radiosPlan,
                        funcion: 'extra',
                        accesosCols: item.accesCols,
                    };

                    // Guardar los datos de la prueba
                    const appExtraDocRef = doc(db, 'usuarios', userName.uid, 'comprasAppExtra', item.app);
                    await setDoc(appExtraDocRef, datosPrueba);

                    // Crear un batch para las notificaciones
                    const batch = WriteBatch(db);
                    const ntfid = uuidv4();

                    const notif = {
                        ntfid,
                        tipoNotif: 'prueba-plan',
                        asunto: 'Se ha escogido un plan de prueba',
                        cliente: this.usuarioHost.userName,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                    };

                    const userNotifRef = doc(db, 'usuarios', userName.uid, 'notificaciones', notif.ntfid);
                    const adminNotifRef = doc(db, 'usuarios', '6ooam9l7LdMrCVHG9Qwm6qS7MCq1', 'notificaciones', notif.ntfid);

                    // Añadir las notificaciones al batch
                    batch.set(userNotifRef, notif);
                    batch.set(adminNotifRef, notif);

                    // Commit del batch
                    await batch.commit();

                    this.mostrarExito('La prueba gratuita nuVin está activa.');
                }
            } catch (error) {
                console.error('Error al generar prueba:', error);
                // Manejo de errores si es necesario
            } finally {
                this.ocultarOcupado();
                this.$router.push({ name: 'Entrada', params: { userName: this.usuarioHost.userName } });
            }
        },
        async pagoTransfMensual(item) {            
            let precioMensual = item
            try {
                let diaescogidoCobroSubs = 3
                let diaToday = new Date().getDate()
                if ( diaToday < diaescogidoCobroSubs ) {
                    var fechaMes = new Date(new Date().getFullYear(), new Date().getMonth(), diaescogidoCobroSubs)
                    let today = new Date()
                    let diasLeft = (fechaMes - today)/86400000 | 0
                    let precioProp = precioMensual/30 * diasLeft
                    this.precioProporcionalMensual = Math.floor(precioProp * 100) / 100
                } else {
                    var fechaSig = new Date(new Date().getFullYear(), new Date().getMonth()+1, diaescogidoCobroSubs)
                    let today = new Date()
                    let diasLeft = (fechaSig - today)/86400000 | 0
                    let precioProp = precioMensual/30 * diasLeft
                    this.precioProporcionalMensual = Math.floor(precioProp * 100) / 100
                }  

            } finally {
                await dbL.collection('itemAppExt').delete()
                this.itemAppExt = Object.assign(item, {plazo: 'mensual', fechaSig: fechaSig, fechaMes: fechaMes})
                await dbL.collection('itemAppExt').set([this.itemAppExt])                
                this.dialogPagoTransfMensual = true
            }            
        },
        async pagoTransfAnual(item) {            
            let precioAnual = item
            try {
                let diaescogidoCobroSubs = 3
                let mesescogidoCobroSubs = new Date().getMonth()
                var fechaSig = new Date(new Date().getFullYear()+1, mesescogidoCobroSubs, diaescogidoCobroSubs,)
                let today = new Date()
                let diasLeft = (fechaSig - today)/86400000 | 0
                let precioProp = precioAnual/365 * diasLeft
                this.precioProporcionalAnual = Math.floor(precioProp * 100) / 100    
            } finally {
                await dbL.collection('itemAppExt').delete()
                this.itemAppExt = Object.assign(item, {plazo: 'anual', fechaSig: fechaSig})
                await dbL.collection('itemAppExt').set([this.itemAppExt])  
                this.dialogPagoTransfAnual = true
            }            
        }
    },
  }
</script>