import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { auth } from './firebase'
import './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import VueQr from 'vue-qr'


Vue.config.productionTip = false;


Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(VueQr);

let vue = null;

auth.onAuthStateChanged(async user => {
  if (user) {
    try {
      await store.dispatch('sesion/iniciarSesion', user.uid)
      iniciarVue()
    } catch (error) {
      console.error('Error during user session initiation:', error)
    }

    // store.dispatch('sesion/iniciarSesion', user.uid)
    // // store.dispatch('sesion/consultarCatalogosDB', user.uid)
    // // store.dispatch('sesion/consultarExpedientes', user.uid)
    // .then(() => {
    //   iniciarVue()
    // })
  }
  else {
    try {
      await store.dispatch('sesion/cerrarSesion')
      iniciarVue()
    } catch (error) {
      console.error('Error during user session termination:', error)
    }
    // store.dispatch('sesion/cerrarSesion')
    // .then(() => {
    //   iniciarVue()
    // })
  }
});


function iniciarVue() {
  if(!vue) {
    vue = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
}

