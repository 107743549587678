// import { db } from '@/firebase'
// import router from '@/router'

export default ({
    namespaced: true,
    state: {
        inCart: [],
        forSale: []
    },
    getters: {
        forSale: state => state.forSale,
        inCart: state => state.inCart,
    },
    mutations: {
        
        ADD_TO_CART(state, cid) { 
            // if(!state.usuario) { return }
            state.inCart.push(cid); 
        },


        REMOVE_FROM_CART(state, index) { state.inCart.splice(index, 1); },
        GUARDAR_PROD(state, catdb) { state.forSale.push(catdb); }
    },
    actions: {

        addToCart(context, cid) { context.commit('ADD_TO_CART', cid); },
        removeFromCart(context, index) { context.commit('REMOVE_FROM_CART', index); },
        forSaleCat(context, catdb) { context.commit('GUARDAR_PROD', catdb); }
        
    },
  });

