<!-- MODIFICAR: agregar boton de favoritos en el grupo de botones y que solo lo vea el usuario principal -->
<!-- o dos botones, uno de enlaces donde es cliente y otro donde es colaborador, o un solo boton que agrupe estas dos modalidades -->
<!-- y asegurarse que cada que alguien sea haga cliente o colaborador, desde la funcion hacer el registro en el user -->
<!-- y tambien poner un boton de que si no es usuario, crear cuenta y se registre el enlaces del user -->
<template>
<!-- id="scrolling-techniques"
      class="scroll-y" -->
    
      
    <v-container style="max-height: 600px;" px-0 pt-5 mt-5>
      <template v-if="dialogProgress === true">
        <div class="mt-0 pt-0" >
            <div class="text-xs-center mt-5 pt-5">
              <v-progress-circular
                  :size="70"
                  :width="7"
                  :style="{'color': paletaColores.primero}"
                  indeterminate
                  class="mt-5 pt-5"
                ></v-progress-circular>
                <div class="hello-text" :class="{ 'fade-in-out': dialogProgress }"><strong>Loading...</strong> </div>
            </div>
        </div>                  
      </template>
      <v-layout v-else align-center justify-center wrap>
        <v-flex xs12 sm6 md4 class="elevation-0 rounded-img d-flex">
          <v-layout align-center justify-center row>            
            <v-flex text-xs-center class="d-flex align-center">              
              <v-list-tile-avatar size="161">
                <v-img class="elevation-10"
                  v-if="isFotoPortadaLoaded"
                  :src="usuarioHost.fotoPortada256"
                ></v-img>
                <v-img class="elevation-10 rounded-img" v-else :style="{'background-color': paletaColores.primero}" style="width: 150px; height: 150px;">
                  <p class="pl-3 ml-4 mt-3 white--text display-4">{{usuarioHsubstr}}</p>
                </v-img>
              </v-list-tile-avatar>
              
              <!-- <template>
                  <v-container fluid px-0 py-0>
                    <v-layout row>
                      <v-flex xs6 pl-4>
                        <div class="item">
                          <v-card dark tile flat color="transparent">                              
                            <template v-if="!colaboradorEnLinea && usuarioHost.bolsaDeTrabajo == true && QCols <= colsNumPermiso">
                              <v-layout row justify-center>
                                <v-dialog v-model="dialog" persistent max-width="290">
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" small dark outline color="transparent " flat>
                                      <v-chip small class="elevation-14 white--text caption" outline color="white">
                                        <v-icon left>mdi-human-edit</v-icon>Postularse
                                      </v-chip>
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title class="headline">Intégrate</v-card-title>
                                    <v-card-text>Quieres postularte para Colaborar en esta nuVin?</v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="" flat @click="dialog = false">Cancelar</v-btn>
                                      <v-btn color="" flat @click="aceptarSerColaborador">Sí</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-layout>
                            </template>
                            <template v-if="!miembroPlaza && usuarioHost.bolsaMiembros == true && QMiembros <= this.miemNumPermiso">
                              <v-layout row justify-center>
                                <v-dialog v-model="dialogPostMiembros" persistent max-width="290">
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" small dark outline color="transparent " flat>
                                      <v-chip small class="elevation-14 white--text caption" outline color="white">
                                        <v-icon left>mdi-human-edit</v-icon>Solicitud
                                      </v-chip>
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title class="headline">Solicitud</v-card-title>
                                    <v-card-text>Quieres postularte para Miembro en esta nuVin-plaza?</v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="" flat @click="dialogPostMiembros = false">Cancelar</v-btn>
                                      <v-btn color="" flat @click="aceptarSerMiembro">Sí</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-layout>
                            </template>
                          </v-card>
                        </div>
                      </v-flex>
                    </v-layout>                     
                  </v-container>
                </template> -->

            </v-flex>
            <v-spacer></v-spacer>
            <v-flex text-xs-center>
              <!-- boton oficina -->
              <div v-if="perfilAuth === true">
                <v-btn class="ml-1 pl-1 caption elevation-10" round outline small :style="{'color': paletaColores.primero}" v-if="perfilAuth == true" @click="movingSameHost()">
                    <v-icon left class="pl-0 ml-0 mr-2">mdi-office-building-outline</v-icon><span class="pl-0 ml-0">ofinuvin</span>
                </v-btn>                  
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn class="ml-1 pl-1 caption" v-on="on" small round>
                        <v-icon left disabled class="pl-0 ml-0 mr-2">mdi-office-building-outline</v-icon><span class="pl-0 ml-0">ofinuvin</span>
                    </v-btn>
                  </template>
                  <span>Acceso sólo para personal autorizado</span>
                </v-tooltip>
              </div>
                <!-- BOTÓN CLIENTE -->
              <div v-if="perfilAuth === false || perfilAuth === null">
                <template>
                  <v-layout row justify-center >
                    <v-btn @click.stop="dialogCliente = true" class="ml-1 pl-0 caption elevation-10" outline round small :style="{'color': paletaColores.primero}">
                      <v-icon left>mdi-account-heart</v-icon>Cliente/Visitante
                    </v-btn>
                    <v-dialog v-model="dialogCliente" max-width="290">
                      <v-card>
                        <v-card-title class="headline">Aquí puedes saber tu estatus como cliente en {{ usuarioHost.userName }}</v-card-title>
                        <!-- <v-card-text v-if="areaClientes == false">Todavía no hay aplicaciones para interactuar como cliente en esta nuVin</v-card-text> -->
                        <v-card-text >
                          <div v-for="item in appExtra" :key="item.switch">
                            <!-- <v-flex xs6 sm4 v-for="itemAcc in item.accesosCols" :key="itemAcc.switch"> -->
                            <v-flex v-if="usuario === null && item.reqSerUser === true">
                              <template>
                                <v-layout row justify-center>
                                  <v-dialog v-model="dialogNoUser" persistent max-width="290">
                                    <template v-slot:activator="{ on }">
                                      <v-btn v-on="on" class="ml-1 pl-0 caption elevation-10" outline small flat round>
                                        <v-icon small dark left>{{ item.icon }} || mdi-hand-heart</v-icon><span class="caption text-none font-weight-bold">{{ item.app }}</span>                    
                                      </v-btn>
                                    </template>
                                    <v-card>
                                      <v-card-title class="headline">Intégrate</v-card-title>
                                      <v-card-text>Para interactuar con esta sección requieres ser Usuario, deseas registrarte?</v-card-text>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="" flat @click="dialogNoUser = false">Cancelar</v-btn>
                                        <v-btn color="" flat @click="regRedirectEmpHost('cliente')">Sí</v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-layout>
                              </template>
                            </v-flex>
                            <v-flex v-else>
                              <div v-for="itemAcc in item.accesosCols" :key="itemAcc.switch">
                                <v-btn v-if="itemAcc.btnCliente === true && usuarioHost.userName" round small class="elevation-4" :to="{ name: itemAcc.compoCliente, params: { userName: usuarioHost.userName,} }">
                                  <v-icon small dark left>{{ item.icon }} || mdi-hand-heart</v-icon><span class="caption text-none font-weight-bold">{{ itemAcc.etiqueta }}</span>
                                </v-btn>
                              </div>
                              <!-- <v-btn round small v-if="itemAcc.etiqueta == 'Interacción' && itemAcc.valor == true && usuario != null" class="elevation-4" :to="{ path: '/'+`${usuarioHost.userName}`+'/'+ item.app +'/persona-especial-estatus/'+`${usuario.userName}`}">
                                <v-icon small dark left>{{ item.icon }} || mdi-hand-heart</v-icon><span class="caption text-none font-weight-bold">{{ item.app }}</span>
                              </v-btn>
                              <v-btn round small v-if="itemAcc.etiqueta == 'Interacción' && itemAcc.valor == true && usuario == null" class="elevation-4" :to="{ path: '/'+`${usuarioHost.userName}`+'/'+ item.app +'/persona-especial-estatus/'+`${cliInvitado[0].cliInvitID}`}">
                                <v-icon small dark left>{{ item.icon }} || mdi-hand-heart</v-icon><span class="caption text-none font-weight-bold">{{ item.app }}</span>
                              </v-btn>                   -->
                            </v-flex>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color=""
                            flat="flat"
                            @click="dialogCliente = false"
                          >
                            Cerrar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template>
              </div>
              <!-- <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="ml-1 pl-1 caption" v-on="on" small round>
                      <v-icon left disabled>mdi-account-heart</v-icon>Cliente
                    </v-btn>
                  </template>
                  <span>Esta sección es exclusiva para Clientes, cuando adquieras algún 
                    producto o servicio tendrás acceso para consultar tu historial de compras o estatus de pedidos.</span>
                </v-tooltip>
              </div> -->
              
              <!-- botón colaborador -->
              <div v-if="perfilAuth === false && usuario">
                <v-btn v-if="colaboradorEnLinea == true" @click="movingSameHost()" class="ml-1 pl-1 caption elevation-10" round outline small :style="{'color': paletaColores.primero}" >
                    <v-icon left class="pl-0 ml-0 mr-2">mdi-human-edit</v-icon><span class="pl-0 ml-0">Colaorador</span>
                </v-btn>
                <template v-else-if="perfilAuth == false && usuario && colaboradorEnLinea == false && usuarioHost.bolsaDeTrabajo == true && QCols <= colsNumPermiso">
                  <v-layout row justify-center>
                    <v-dialog v-model="dialog" persistent max-width="290">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="ml-1 pl-0 caption elevation-10" outline round small flat :style="{'color': paletaColores.primero}">
                          <v-icon left class="ml-0 mr-0">mdi-human-edit</v-icon><span class="pl-0 ml-0">Colaborador</span>                        
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="headline">Intégrate</v-card-title>
                        <v-card-text>Quieres postularte para Colaborar en esta nuVin?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="" flat @click="dialog = false">Cancelar</v-btn>
                          <v-btn color="" flat @click="aceptarSerColaborador">Sí</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template>
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn class="ml-1 pl-0 caption" v-on="on" small round>
                      <v-icon left disabled class="ml-0 mr-0">mdi-human-edit</v-icon><span disabled class="pl-1 ml-0">Colaborador</span>                            
                  </v-btn>
                  </template>
                  <span>El administrador no ha activado la Integración</span>
                </v-tooltip>
              </div>
              <div v-if="usuario === null">
                <!-- <template v-else-if="!usuario && colaboradorEnLinea == false && usuarioHost.bolsaDeTrabajo == true && QCols <= colsNumPermiso"> -->
                <template>
                  <v-layout row justify-center>
                    <v-dialog v-model="dialog" persistent max-width="290">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="ml-1 pl-0 caption elevation-10" outline small flat round>
                          <v-icon left class="ml-0 mr-0">mdi-human-edit</v-icon><span class="pl-0 ml-0">Colaborador</span>                        
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="headline">Intégrate</v-card-title>
                        <v-card-text>Para ser Colaborador requieres ser Usuario, quieres registrarte?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="" flat @click="dialog = false">Cancelar</v-btn>
                          <v-btn color="" flat @click="regRedirectEmpHost('colaborador')">Sí</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template>
              </div>

              <template v-if="!miembroPlaza && usuarioHost.bolsaMiembros == true && QMiembros <= this.miemNumPermiso">
                <v-layout row justify-center>
                  <v-dialog v-model="dialogPostMiembros" persistent max-width="290">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" class="ml-1 pl-0 caption" outline small flat>
                        <v-icon left class="ml-0 mr-0">mdi-human-edit</v-icon><span class="pl-1 ml-0">Miembro</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline">Solicitud</v-card-title>
                      <v-card-text>Quieres postularte para Miembro en esta nuVin-plaza?</v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" flat @click="dialogPostMiembros = false">Cancelar</v-btn>
                        <v-btn color="" flat @click="aceptarSerMiembro">Sí</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </template>

              <!-- botón Enlaces -->
              <div v-if="perfilAuth === true">
                <template>
                  <v-btn @click.stop="abrirDialogoYConsultarEnlaces" class="ml-1 pl-0 caption elevation-10" outline round small :style="{'color': paletaColores.primero}">
                    <v-icon left class="pl-1 ml-0 mr-2">mdi-cloud</v-icon><span class="pl-0 ml-0">mis enlaces</span>
                  </v-btn>
                  <template>
                    <v-layout row justify-center>
                      <v-dialog v-model="dialogEnlaces" scrollable max-width="300px">
                        <v-card>
                          <v-card-title class="headline">Enlaces Guardados</v-card-title>
                          <span class="ml-2">Aquí se enlistan los enlaces donde eres colabordor o cliente y los favoritos que guardas.</span>
                          <!-- <v-btn @click="saveEnlace" v-if="usuario && usuarioHost && usuario.userName !== null && usuarioHost.userName !== null && usuario.userName !== usuarioHost.userName && (!enlaces.some(el => el.enlace === usuarioHost.userName))">guardar enlace</v-btn> -->
                          
                          <v-divider></v-divider>
                          <v-card-text style="height: 300px;">
                            <div v-for="item in enlaces" :key="item.enlid">
                              <v-btn class="ml-1 pl-1 caption elevation-8" round outline small :style="{'color': paletaColores.primero}" 
                                    @click="irEnlace(item.enlace)">
                                <span class="pl-0 ml-0">{{  item.enlace }}</span>
                              </v-btn>
                            </div>  
                          </v-card-text>
                          <v-divider></v-divider>
                          <div class="text-xs-right">
                              <v-btn color="primary" class="caption" flat @click="dialogEnlaces = false">Cerrar</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </v-layout>
                  </template>        
                </template>
              </div>

              <!-- <div v-if="!usuarioHost.app"> -->
                <!-- <v-tooltip bottom v-if="!usuario">
                  <template v-slot:activator="{ on }">
                    <v-btn class="ml-1 pl-0 caption" v-on="on" small>
                      <v-icon left disabled class="ml-0 mr-0">mdi-radiobox-marked</v-icon><span disabled class="pl-1 ml-0">interact</span>                            
                    </v-btn>
                  </template>
                  <span>Todavía no tienes cuenta nuVin para interactuar</span>
                </v-tooltip> -->
                
                  <!-- botón INTERACCION -->
                <!-- <template v-if="!usuario">
                  <v-layout row justify-center >
                    <v-btn @click.stop="dialogInteractSinUser = true" class="ml-1 pl-0 caption" outline small>
                      <v-icon left class="ml-0 mr-0">mdi-radiobox-marked</v-icon><span class="pl-1 ml-0">interact</span>                            
                    </v-btn>
                    <v-dialog
                      v-model="dialogInteractSinUser"
                      max-width="290"
                    >
                      <v-card>
                        
                        <v-card-text>
                          <span>Para interactuar con alguna de las aplicaciones de esta nuVin, debes ingresar con tu cuenta; 
                            ¿todavía no tienes una? da click aquí:</span><br>
                          <v-btn round small outline class="elevation-4" @click="crearCuenta">
                            <v-icon small dark left>mdi-user</v-icon>crear cuenta nuVin
                          </v-btn>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color=""
                            flat="flat"
                            @click="dialogInteractSinUser = false"
                          >
                            Cerrar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template> -->
                    
                <template v-if="perfilAuth === false || perfilAuth === null">
                  <v-layout row justify-center >
                    <v-btn @click.stop="dialogInteraccion = true" class="ml-1 pl-0 caption elevation-10" outline round small :style="{'color': paletaColores.primero}">
                      <v-icon left class="ml-0 mr-0">mdi-radiobox-marked</v-icon><span class="pl-1 ml-0 caption text-none">INTERACTUAR</span>                            
                    </v-btn>
                    <v-dialog
                      v-model="dialogInteraccion"
                      max-width="290"
                    >
                      <v-card>
                        <v-card-title class="headline">Interacciones con {{ usuarioHost.userName }}</v-card-title>
                        <v-card-text v-if="appInteraccion == false">Todavía no hay aplicaciones para interactuar en esta nuVin</v-card-text>
                        <v-card-text v-else>
                          <div v-for="item in appExtra" :key="item.app">
                            <v-flex xs6 sm4 v-for="itemAcc in item.accesosCols" :key="itemAcc.switch">
                              <v-btn round small v-if="itemAcc.etiqueta == 'Interacción' && itemAcc.valor == true" class="elevation-4" :to="{ path: '/'+ item.app +'/'+`${usuarioHost.userName}`}">
                                <v-icon small dark left>{{ item.icon }} || mdi-hand-heart</v-icon><span class="caption text-none font-weight-bold">{{ item.app }}</span>
                              </v-btn>                  
                            </v-flex>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="" flat="flat" @click="dialogInteraccion = false">
                            Cerrar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template>
              <!-- </div> -->

              <div v-if="usuarioHost.app == true">
                <template v-if="!usuario">
                  <v-layout row justify-center>
                    <v-dialog v-model="dialogAdquirir" scrollable max-width="300px">
                      <template v-slot:activator="{ on }">
                        <v-btn class="ml-1 pl-1 caption" v-on="on" outline small>
                          <v-icon left class="pl-0 ml-0 mr-2">mdi-shopping</v-icon><span class="pl-0 ml-0">adquirir</span>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>Opciones para adquirir esta nuvin app:</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="height: 300px;">
                          <template>
                            <v-container>
                              <v-layout row wrap>
                                <v-flex xs12>
                                  <v-expansion-panel popout>
                                    <v-expansion-panel-content
                                      v-for="(item,i) in itemsAdquirir"
                                      :key="i"
                                    >
                                      <template v-slot:header>
                                        <div>{{ item.opcion }}</div>
                                      </template>
                                      <v-card>
                                        <v-card-text>{{ item.descrip }}</v-card-text>
                                        <v-btn v-if="item.opcion == 'AutoConfiguración'" small color="primary" :to="{ name: 'registro', params: {app: 'personal'} }">Aceptar</v-btn>
                                        <div v-for="(item,i) in item.modoprecio" :key="i">
                                          <template>
                                            <v-layout row justify-center>
                                              <v-dialog v-model="dialogTransfer" persistent max-width="290">
                                                <template v-slot:activator="{ on }">
                                                  <v-btn small color="primary" class="text-lowercase" dark v-on="on">{{ item.modo }}: ${{ item.precio }}</v-btn>
                                                </template>
                                                <v-card>
                                                  <v-card-title class="headline">Información para transferencia electrónica:</v-card-title>
                                                  <v-card-text>
                                                    Por favor, realiza tu pago usando <span class="font-weight-bold">Transferencia 
                                                    Electrónica,</span> a favor de: <br><br>
                                                    <tr>Titular de cuenta: <span class="font-weight-bold">{{ datosBanco.titular }}</span><v-icon class="ml-2" color="blue" v-clipboard:copy="datosBanco.titular" small @click="copy" left>mdi-content-copy</v-icon></tr>
                                                    <tr>Número de cuenta: <span class="font-weight-bold">{{ datosBanco.cuenta }}</span><v-icon class="ml-2" color="blue" v-clipboard:copy="datosBanco.cuenta" small @click="copy" left>mdi-content-copy</v-icon></tr>
                                                    <tr>Núumero CLABE SPEI: <span class="font-weight-bold">{{ datosBanco.clabe }}</span><v-icon class="ml-2" color="blue" v-clipboard:copy="datosBanco.clabe" small @click="copy" left>mdi-content-copy</v-icon></tr>
                                                    <tr>Banco: <span class="font-weight-bold">{{ datosBanco.banco }}</span></tr>
                                                    <tr>Asesoría-modo/Monto a transferir: <strong>{{ item.modo }}: ${{ item.precio }} MXN</strong><v-icon class="ml-2" color="blue" v-clipboard:copy="item.precio" small @click="copy" left>mdi-content-copy</v-icon></tr><br>
                                                    Posteriormente envíanos un mail a: <strong>nubesdebits@gmail.com</strong> adjuntando tu comprobante de transferencia (ya sea eMail por parte de tu banco o screenshot desde tu dispositivo), 
                                                    tu nombre y teléfono para comunicarnos contigo y acordar la cita :)
                                                  </v-card-text>
                                                  <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="primary" flat @click="dialogTransfer = false">Cerrar</v-btn>
                                                  </v-card-actions>
                                                </v-card>
                                              </v-dialog>
                                            </v-layout>
                                          </template>
                                        </div>
                                        <div v-if="item.opcion != 'AutoConfiguración'">Al realizar la transferencia, nos comunicaremos contigo para acordar la cita :).</div>
                                      </v-card>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </template>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-btn color="primary" flat @click="dialogAdquirir = false">Cerrar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </template>
                <div v-if="usuario"> 
                  <v-tooltip bottom v-if="usuarioHost.appGiro == true"> 
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-1 pl-1 caption" v-on="on" small>
                          <v-icon left disabled class="pl-0 ml-0 mr-2">mdi-shopping</v-icon><span class="pl-0 ml-0">adquirir</span>
                      </v-btn>
                    </template>
                    <span>No puedes adquirir esta app-negocio porque ya has adquirido una de este giro u otro.</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="usuarioHost.appHerramienta == true"> 
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-1 pl-1 caption" v-on="on" small>
                          <v-icon left class="pl-0 ml-0 mr-2">mdi-shopping</v-icon><span class="pl-0 ml-0">adquirir</span>
                      </v-btn>
                    </template>
                    <span>Para adquirir esta Herramienta app, dirígete al botón Herramientas-Utilidades que se encuentra en tu Oficina nuVin, :)</span>
                  </v-tooltip>
                </div>                  
              </div>

              <div v-if="usuarioHost.ventanilla == true">
                <v-layout row justify-center>
                  <v-btn v-if="usuarioHost.ventanilla == true && usuario != null" class="ml-1 pl-1 caption" outline small @click.stop="dialogMsj = true">
                    <v-icon left class="pl-0 ml-0 mr-2">mdi-face-agent</v-icon>Atención                    
                  </v-btn>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-1 pl-0 caption" v-on="on" small>
                        <v-icon left disabled class="ml-0 mr-0">mdi-face-agent</v-icon><span disabled class="pl-1 ml-0">Atención</span>                            
                    </v-btn>
                    </template>
                    <span>Por el momento no puedes dejar mensaje en ventanilla porque: el usuario de esta nuVin no lo ha habilitado o no has creado tu nuVin.</span>
                  </v-tooltip>

                  <v-dialog
                    v-model="dialogMsj"
                    max-width="290"
                  >
                    <v-card>
                      <v-card-title class="headline">Contáctanos</v-card-title>

                      <v-card-text>
                        Envía un mensaje y tus datos para comunicarnos contigo y asistir a cualquiera de tus dudas o comentarios :)
                      </v-card-text>
                      <v-textarea class="mx-2"
                        v-model="msjText" 
                        counter="55"
                        auto-grow
                        box @blur="$v.msjText.$touch()" :error-messages="erroresMsjText"
                        color="primary"
                        label="...escribir Mensaje"
                        rows="1"
                      ></v-textarea>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="principal"
                          flat="flat"
                          @click="dialogMsj = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn :depressed="$v.msjText.$invalid" :disabled="$v.msjText.$invalid"
                          color="principal"
                          flat="flat"
                          @click="enviarMsj"
                        >
                          Enviar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </div>

              <template v-if="userHostPull.length > 0">
                <v-layout row justify-center >
                  <!-- <v-btn @click.stop="dialogUhPull = true" class="ml-1 pl-0 caption" outline small>
                    <v-icon left class="ml-0 mr-0">mdi-radiobox-marked</v-icon><span class="pl-1 ml-0">interact</span>                            
                  </v-btn> -->
                  <v-dialog
                    v-model="dialogUhPull"
                    max-width="290"
                  >
                    <v-card>                        
                      <v-card-text>
                        <span>¿Quieres seguir interactuando en la nuVin de <strong>{{ userHostPull[0].userNameHostPull }}</strong>?</span><br><br>
                        <v-btn round small outline class="elevation-4" @click="irHostPull">
                          <!-- path: '/reservoHab/:userName/:evento/reservaciones' -->
                          <v-icon small dark left>mdi-user</v-icon>{{ userHostPull[0].userNameHostPull }}
                        </v-btn>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color=""
                          flat="flat"
                          @click="closeUserHost"
                        >
                          quiero quedarme en mi cuenta
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </template>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- <v-flex xs12 v-if="appExtra.some(el => el.app == 'infoLiga' && el.activa == 'Activada' || el.app == 'infoLiga' && el.activa == 'Prueba')">
          <displayInfoLiga></displayInfoLiga>
        </v-flex>       -->

        <!-- Tabs -->
        <v-flex xs12 sm10 md8 lg7 v-if="usuarioHost.tabsEnHome == true" >
          <v-tabs grow v-model="activeTab" v-if="usuarioHost.app == 'personal'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab 
              @click="tabChange(item)"
              v-for="item in menuPers"
              :key="item.descripcionPers"
              :to="item.routePers"
              class="menu caption"                                            
              >
                <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionPers }}
            </v-tab>                
          </v-tabs>
          
          <v-tabs grow v-model="activeTab" v-if="usuarioHost.app == 'restaurante'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab 
              v-for="item in menuRest"
              @click.stop="tabChange(item)"                  
              :key="item.descripcionRest"
              :to="item.routeRest"
              class="menu caption"
              small                   
              >                        
                <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionRest }}
            </v-tab>                
          </v-tabs>

          <v-tabs grow v-model="activeTab" v-if="usuarioHost.app == 'plaza'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab 
              v-for="item in opcPlaza"
              @click="tabChange(item)"                      
              :key="item.descripcionPlaz"
              :to="item.routePlaz"
              class="menu caption"
              small                   
              >                        
                <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionPlaz }}
            </v-tab>                
          </v-tabs>

          <v-tabs grow v-model="activeTab" v-if="usuarioHost.app == 'despachocp'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab 
              v-for="item in opcDchCP"
              @click="tabChange(item)"                      
              :key="item.descripcionDcp"
              :to="item.routeDcp"
              class="menu caption"
              small                   
              >                        
                <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionDcp }}
            </v-tab>                
          </v-tabs>

          <v-tabs grow v-model="activeTab" v-if="usuarioHost.app == 'servicios profesionales'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="item in menuServPro"
              @click.stop="tabChange(item)"                  
              :key="item.descripcionServPro"
              :to="item.routeServPro"
              class="menu caption"
              small                   
              >              
                <div v-if="item.descripcionServPro == 'Datos'">
                  <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionServPro }}
                </div>          
                <div v-else>
                  <v-icon left>{{item.icon}}</v-icon><span v-if="usuarioHost.app == true">Funciones</span><span v-else>{{ item.descripcionServPro }}</span>  
                </div>
                
            </v-tab>                
          </v-tabs>

          <v-tabs grow v-model="activeTab" v-if="usuarioHost.app == 'consultorio'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="item in menuConsultorio"
              @click.stop="tabChange(item)"                  
              :key="item.descripcionConsultorio"
              :to="item.routeConsultorio"
              class="menu caption"
              small                   
              >                        
                <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionConsultorio }}
            </v-tab>                
          </v-tabs>

          <v-tabs fixed-tabs v-model="activeTab" v-if="usuarioHost.app == 'comercio'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="item in menuComercio"
              @click.stop="tabChange(item)"                  
              :key="item.descripcionComercio"
              :to="item.routeComercio"
              class="menu caption"
              small                   
              >                        
                <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionComercio }}
            </v-tab>                
          </v-tabs>

          <v-tabs grow v-model="activeTab" v-if="usuarioHost.app == 'orgevent'">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="item in menuOrgEvent"
              @click.stop="tabChange(item)"                  
              :key="item.descripcionOrgEvent"
              :to="item.routeOrgEvent"
              class="menu caption"
              small                   
              >                        
                <v-icon left>{{item.icon}}</v-icon>{{ item.descripcionOrgEvent }}
            </v-tab>                
          </v-tabs>
          
          <component v-if="usuarioHost.app == 'personal'" :is="componenteActual1"></component>
          <component v-if="usuarioHost.app == 'restaurante'" :is="componenteActual2"></component>
          <component v-if="usuarioHost.app == 'plaza'" :is="componenteActual3" @selectFromListMember="goWithMember"></component>
          <component v-if="usuarioHost.app == 'despachocp'" :is="componenteActual4"></component>
          <component v-if="usuarioHost.app == 'servicios profesionales'" :is="componenteActual5"></component>
          <component v-if="usuarioHost.app == 'consultorio'" :is="componenteActual8"></component>
          <component v-if="usuarioHost.app == 'comercio'" :is="componenteActual6"></component>
          <component v-if="usuarioHost.app == 'orgevent'" :is="componenteActual7"></component>
        </v-flex>
      </v-layout>
      <!-- seccion cuando area publica esta cerrada -->
      <v-dialog v-if="colaboradorEnLinea == false" v-model="usuarioHost.areaPublica" fullscreen hide-overlay transition="dialog-bottom-transition">
        <!-- <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">Op</v-btn>
        </template> -->
        <v-card>
          <v-toolbar color="transparent">
            <!-- <v-btn icon v-if="colaboradorEnLinea == true">
              <v-icon>close</v-icon>
            </v-btn> -->
            <v-btn class="ml-2" icon v-if="colaboradorEnLinea != true" :to="{ name: 'HomeSistema' }">
              <v-icon small dark left color="black">arrow_back</v-icon>
              salir
            </v-btn>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <template>
            <v-layout>
              <v-flex xs12 sm6 offset-sm3>
                <v-card>
                  <v-img
                    v-if="usuarioHost.fotoPortada256 != null"
                    :src="usuarioHost.fotoPortada256"
                    max-height="232px"
                    contain center
                    >
                  </v-img>
                  <v-img v-else :style="{'background-color': paletaColores.primero}" style="width: 150px; height: 150px;">
                    <p class="pl-3 ml-4 mt-3 white--text display-4">{{usuarioHsubstr}}</p>
                  </v-img>

                  <v-card-title primary-title>
                    <div>
                      <h3 class="subtitle mb-0">Por el momento esta Área es Privada, sólo tiene acceso personal autorizado de </h3>
                      <h3 class="headline mb-0">{{ usuarioHost.userName }}</h3>
                      <!-- <div> Área privada </div> -->
                    </div>
                  </v-card-title>
                  <div>
                    <v-btn class="ml-1 pl-1 caption" outline small v-if="colaboradorEnLinea == true || perfilAuth == true" @click="movingSameHost()">
                        <v-icon left class="pl-0 ml-0 mr-2">mdi-office-building-outline</v-icon><span class="pl-0 ml-0">ofinuvin</span>
                    </v-btn>
                    <v-btn class="ml-1 pl-1 caption" small v-else disabled>
                        <v-icon left class="pl-0 ml-0 mr-2">mdi-office-building-outline</v-icon><span class="pl-0 ml-0">ofinuvin</span>
                    </v-btn>
                  </div>
                  <div>
                    <template v-if="!colaboradorEnLinea && usuarioHost.bolsaDeTrabajo == true && QCols <= colsNumPermiso && perfilAuth == true">
                      <v-layout row justify-center>
                        <v-dialog v-model="dialog" persistent max-width="290">
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="ml-1 pl-0 caption" outline small flat>
                              <v-icon left class="ml-0 mr-0">mdi-human-edit</v-icon><span class="pl-0 ml-0">Intégrate</span>                        
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title class="headline">Intégrate</v-card-title>
                            <v-card-text>Quieres postularte para Colaborar en esta nuVin?</v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="" flat @click="dialog = false">Cancelar</v-btn>
                              <v-btn color="" flat @click="aceptarSerColaborador">Sí</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-layout>
                    </template>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn class="ml-1 pl-0 caption" v-on="on" small>
                          <v-icon left disabled class="ml-0 mr-0">mdi-human-edit</v-icon><span disabled class="pl-1 ml-0">Intégrate</span>                            
                      </v-btn>
                      </template>
                      <span>El administrador no ha activado la Integración</span>
                    </v-tooltip>
                  </div>
                  <!-- <v-card-actions>
                    <v-btn flat color="orange">Share</v-btn>
                    <v-btn flat color="orange">Explore</v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </v-card>
      </v-dialog>
<!-- seccion para redireccionar colaboradores y creen cuenta sujeta al host -->
      <v-dialog v-model="dialogRedirect" fullscreen hide-overlay transition="dialog-bottom-transition" v-if="usuarioHost.redirectHost && usuarioHost.redirectHost != 'no-redirect'">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
        </template>
        <v-card>
          <v-layout align-center justify-center fill-height column class="mt-5 pt-5">
              <v-flex class="mt-5 pt-5 mb">
                <span>Esta cuenta fue creada para operar con:</span>
              </v-flex>
              <v-flex>
                <div class="text-xs-center">
                  <v-btn round color="principal" dark :to = "({name: 'Entrada', params: {userName: this.usuarioHost.redirectHost} })">{{this.usuarioHost.redirectHost}}</v-btn>
                </div>            
              </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>

      <v-layout row justify-center>
        <v-dialog v-model="dialogRecargar" persistent max-width="290">
          <!-- <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
          </template> -->
          <v-card>
            <v-card-text>Has estado un tiempo inactivo o hay problemas con la red (espera un momento)... da click en recargar.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :style="{'color': paletaColores.primero}" flat @click="recargarPagina">Recargar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

    </v-container>
</template>


<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapMutations, mapState, mapActions } from 'vuex'
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { db } from '@/firebase'
// import displayInfoLiga from '@/views/infoLiga/displayInfoLiga.vue'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
  // components: {displayInfoLiga },
  props: ["userName"],
  data() {
    return {
      show: true,
      member: '',
      componentKey: 0,
      cliInvitado: [],
      enlaces: [],
      dialogRecargar: false,
      dialogProgress: true,
      dialog: false,
      dialog2: false,
      dialogMsj: false,
      dialogBloqPublic: false,
      dialogRedirect: false,
      dialogNoUser: false,
      msjText: '',
      dialogPostMiembros: false,
      dialogAdquirir: false,
      dialogTransfer: false,
      dialogInteraccion: false,
      dialogInteractSinUser: false,
      dialogCliente: false,
      dialogUhPull: false,
      dialogEnlaces: false,
      datosBanco: {
          titular: 'Grupo Gueboy, S. de R.L. de C.V.',
          banco: 'Banca Mifel, S.A.',
          cuenta: '01600484377',
          clabe: '042910016004843774',
      },
      itemsAdquirir: [{opcion: 'AutoConfiguración', descrip: 'Presionando el botón ACEPTAR, estarás a dos sencillos pasos de tener tu NUVIT, configurarla y adquirir Herramientas, es muy intuitiva. Se te ofrece una opción de prueba gratuita para los Giros de Negocio.'},
                      {opcion: 'Configuración con Asesoría Personal', modoprecio: [{modo: 'videollamada', precio: 300}, {modo: 'enPersona', precio: 500}], descrip: 'Incluye sesión de una hora y un mes de operación de la app. Explicación detallada de cada una de las funciones para sacar el mayor provecho a la app para tu negocio. Atención 100% a sólo una persona.'}, 
                      {opcion: 'Configuración con Asesoría Grupal', modoprecio: [{modo: 'videollamada', precio: 200}, {modo: 'enPersona', precio: 350}], descrip: 'Incluye sesión de una hora y un mes de operación de la app. Explicación detallada de cada una de las funciones para sacar el mayor provecho a la app para tu negocio. Podrá haber más personas dentro de la sesión haciendo sus preguntas (máximo 10 personas)'}],
      // dialogQR: false,
      editando: false,
      colaboradores: [],
      clientes: [],
      arrayspedidos: [],
      comandas: [],
      pedidosTot: [],
      dSenvAserv: '',
      miembros: [],
      clientesAutoPedidos: [],
      planPorPrecio: [],
      appExtra: [],
      userHostPull: [],
      nuvitGratis: 'price_1IWW9wGLlS3xAdXuxEwvjESu',
      nuvit1: 'price_1IUbLQGLlS3xAdXuPAvgzhp2',
      nuvit2: 'price_1IUbMVGLlS3xAdXupUDgRNhi',
      nuvit3: 'price_1IUbNCGLlS3xAdXubxbTjcfF',
      miemNumPermiso: '',      
      componenteActual1: 'minidatosg',
      componenteActual2: 'minidatosg',
      componenteActual3: 'minidatosg',
      componenteActual4: 'minidatosg',
      componenteActual5: 'minidatosg',
      componenteActual6: 'minidatosg',
      componenteActual7: 'minidatosg',
      componenteActual8: 'minidatosg',
      // activeTabPers: `/${this.userName}`,
      // activeTabRest: `/${this.userName}`,
      // activeTabPlaz: `/${this.userName}`,
      activeTab: `/${this.userName}`,
      menuPers: [
        { descripcionPers: 'Datos', nombrePers: 'minidatosg', routePers: `/${this.userName}/datos`, icon:'account_box' },
      ],
      menuRest: [
        { descripcionRest: 'Datos', nombreRest: 'minidatosg', routeRest: `/${this.userName}/datos`, icon:'account_box' },
        { descripcionRest: 'Menú', nombreRest: 'minimenu', routeRest: `/${this.userName}/menu`, icon: 'mdi-food-fork-drink' },
      ],
      opcPlaza: [
        { descripcionPlaz: 'Datos', nombrePlaz: 'minidatosg', routePlaz: `/${this.userName}/datos`, icon:'account_box' },
        { descripcionPlaz: 'Miembros', nombrePlaz: 'listaMiembros', routePlaz: `/${this.userName}/miembros`, icon: 'mdi-storefront' },
      ],
      opcDchCP: [
        { descripcionDcp: 'Datos', nombreDcp: 'minidatosg', routeDcp: `/${this.userName}/datos`, icon:'account_box' },
        { descripcionDcp: 'Servicios', nombreDcp: 'miniservicios', routeDcp: `/${this.userName}/servicios`, icon: 'mdi-format-list-bulleted-square' },
      ],
      menuServPro: [
        { descripcionServPro: 'Datos', nombreServPro: 'minidatosg', routeServPro: `/${this.userName}/datos`, icon:'account_box' },
        { descripcionServPro: 'Servicios', nombreServPro: 'miniservicios', routeServPro: `/${this.userName}/servicios`, icon: 'mdi-format-list-bulleted-square' },
      ],
      menuConsultorio: [
        { descripcionConsultorio: 'Datos', nombreConsultorio: 'minidatosg', routeConsultorio: `/${this.userName}/datos`, icon:'account_box' },
        { descripcionConsultorio: 'Servicios', nombreConsultorio: 'miniservicios', routeConsultorio: `/${this.userName}/servicios`, icon: 'mdi-format-list-bulleted-square' },
      ],
      menuComercio: [
        { descripcionComercio: 'Datos', nombreComercio: 'minidatosg', routeComercio: `/${this.userName}/datos`, icon:'account_box' },
        { descripcionComercio: 'Productos', nombreComercio: 'minicatalogo', routeComercio: `/${this.userName}/productos`, icon: 'mdi-store' },
      ],
      menuOrgEvent: [
        { descripcionOrgEvent: 'Datos', nombreOrgEvent: 'minidatosg', routeOrgEvent: `/${this.userName}/datos`, icon:'account_box' },
        // esta tab de servicos es para quien escoja eventos de boda
        { descripcionOrgEvent: 'Servicios', nombreOrgEvent: 'miniservicioseventos', routeOrgEvent: `/${this.userName}/servicios`, icon: 'mdi-format-list-bulleted-square' },
      ],
      // subscripcion: [],
      vtto: '',
      vttoX2: '',
      diasPrueba: 2,
      today: new Date().toISOString().substr(0, 10),
      yesterday: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
      versionLB: []
    }
  },
  validations: {
    msjText: {
        required,
        maxLength: maxLength(55),
        minLength: minLength(9)
    },
  },
  mounted() {
    console.log('Ruta Entrada montada con userName:', this.userName);
      // Otras lógicas de inicialización
    
    setTimeout(() => {
      if (!this.dialogProgress) {
        this.dialogRecargar = true
      }
    }, 180000);
  },
  created() {
    this.verifLink()
    this.consultarClientes()
    this.consultarAppExtra()
    this.verifHostPull()
    this.cleanUserHost()
    this.limpiarEntradaUsuarioHost()
  },
  computed: {
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['perfilAuth']),
    ...mapState('usuarioHost', ['usuarioHost']),
    ...mapState('usuarioHost', ['fotoPortada']),
    isFotoPortadaLoaded() {
      // Check if the fotoPortada256 is not null and usuarioHost is loaded
      return this.usuarioHost && this.usuarioHost.fotoPortada256 !== null;
    },
    paletaColores() {
      if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
          return this.usuarioHost.designColors;
      } else {
          const colores = ['#E040FB',];
          const colores2 = ['#616161',];
          const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
          const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
          return {
          primero: colorAleatorio,
          segundo: colorAleatorio2,
          tercero: colorAleatorio,
          cuarto: colorAleatorio
          };
      }
    },
    usuarioHsubstr() {
      if (this.usuarioHost && this.usuarioHost.userName) {
        return this.usuarioHost.userName.charAt(0);
      }
      return '';
    },
    colaborador() {
      if (this.perfilAuth == true) {
        return this.usuario
      } else {
        return this.colaboradores.find(({userName}) => userName === this.usuario.userName)
      }
    },
    colaboradorEnLinea() {
      
      if(this.colaboradores.some(col => col.userName == this.usuario.userName) ){
          // alert("Object found inside the array.");
        var acc = [];
        let colAccs = this.colaborador.estatusAccesos;
        for (var key in colAccs) {
            if (colAccs[key].value === true) {
                acc.push(key);
            }
        }
        if ( acc.length > 0 ) {
          return true
        } else {
          return false
        }
          
      } else {
          // alert("Object not found.");
          return false
      }
    },
    QCols() {
        var x = this.colaboradores.length
        return x
    },
    QMiembros() {
        var x = this.miembros.length
        return x
    },
    appInteraccion() {      
      return this.appExtra.some( elem => elem.interaccion == true)
    },
    areaClientes() {
      return this.appExtra.some(elem => {
        return elem.accesosCols.flatMap(col => col.btnCliente).some(btn => btn === true)
      })
    },
    miembroPlaza() {

      if(this.miembros.some(miem => miem.userName == this.usuario.userName) ){
          // if( this.miembros == ''){
          return true
      } else {
          // alert("Object not found.");
          return false
      }
    },
    colsNumPermiso() {
      return this.usuarioHost.permisos.colaboradores
    },
    erroresMsjText() {
        let errores = []
        if (!this.$v.msjText.$dirty) { return errores }    
        if (!this.$v.msjText.maxLength) { errores.push('Ingresa máximo 55 caracteres.') }
        return errores
    },
  },
  watch: {
    '$route' () {
      this.consultarUsuarioHost()
    },
    
    userName () {
      // this.activeTabPers = `/${this.userName}`
      // this.activeTabRest = `/${this.userName}`
      // this.activeTabPlaz = `/${this.userName}`
      this.activeTab = `/${this.userName}`
    },

    perfilAuth(newVal) {
      if (newVal !== null) { // Cuando perfilAuth se conozca (no sea null)
        this.dialogProgress = false;
      }
    },

    usuarioHost(newVal) {
      if (newVal) {
        this.dilogProgress = false;
      }
    }
  },
  methods: {
    ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
    ...mapActions('usuarioHost', ['actualizarUsuarioHost']),
    ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
    ...mapActions('usuarioHost', ['borrarActualUsuarioHost']),    
    // ...mapActions('usuarioHost', ['consultarTicket']),
    recargarPagina() {
      window.location.reload();
    },
    irEnlace(item) {
      this.$router.push({name: 'Entrada', params: {userName: item}})
                  .then(() => {
                    // Realizar el reload después de que la navegación haya sido completada
                    window.location.reload();
                  });
    },
    abrirDialogoYConsultarEnlaces() {
      // Abre el diálogo
      this.dialogEnlaces = true;

      // Realiza la consulta a la función consultarEnlaces
      this.consultarEnlaces();
    },
    async consultarEnlaces() {
      this.enlaces = [];
      const userNameParametro = this.usuario.userName.toLowerCase();

      try {
        const userNameDocRef = doc(db, 'userNames', userNameParametro);
        const userNameDocSnap = await getDoc(userNameDocRef);

        if (userNameDocSnap.exists()) {
          const userNameData = userNameDocSnap.data();

          const enlacesCollectionRef = collection(db, 'usuarios', userNameData.uid, 'enlacesnuvin');
          const resultado = await getDocs(enlacesCollectionRef);

          resultado.forEach(doc => {
            this.enlaces.push(doc.data());
          });
        }
      } catch (error) {
        this.mostrarError('Ocurrió un error consultando los Enlaces.');
      }
    },
    async visulizarVersion() {      
      if (this.usuario && this.usuario.userName !== null && 
          this.usuarioHost && this.usuarioHost.userName !== null && 
          this.usuario.userName === this.usuarioHost.userName) {

        const versionDocRef = doc(db, 'datasoporte', 'version');
        const versionDocSnap = await getDoc(versionDocRef);
        const versionServer = versionDocSnap.data().version;   
        
        if (!this.versionLB || !this.versionLB.exists || versionServer !== this.versionLB) {
          const versionLBCollectionRef = collection(dbL, 'versionLB');
          const versionLBDocSnap = await getDocs(versionLBCollectionRef);

          if (versionLBDocSnap.docs.length > 0) {
            const versionLBDocRef = doc(dbL, 'versionLB', '0'); // Assuming '0' is the ID, replace as necessary
            await deleteDoc(versionLBDocRef);
          } else {
            console.error('No se encontraron documentos en la colección "versionLB" con { id: 0 }');
          }

          await addDoc(versionLBCollectionRef, { id: 0, versionLB: versionServer });

          const userNameParametro = this.$route.params.userName.toLowerCase();
          const userNameDocRef = doc(db, 'userNames', userNameParametro);
          const userNameDocSnap = await getDoc(userNameDocRef);
          
          if (userNameDocSnap.exists()) {
            const userNameData = userNameDocSnap.data();
            const usuarioRef = doc(db, 'usuarios', userNameData.uid);
            await updateDoc(usuarioRef, { version: versionServer });
          }

          window.location.reload(true); // Recargar la página desde el servidor
        }
      }
    },
    async consultarItemsApps() {
      try {
        const itemsAppsCollectionRef = collection(db, 'itemsApps');
        const querySnapshot = await getDocs(itemsAppsCollectionRef);

        // Limpiar el array antes de agregar nuevos elementos
        this.itemsApps = [];

        querySnapshot.forEach(doc => {
          let item = doc.data();
          this.itemsApps.push(item);
        });

        // Realizar cualquier otra lógica necesaria después de obtener los datos
        // ...

      } catch (error) {
        this.mostrarError('Ocurrió un error consultando las Apps.');
      }
    },
    async regRedirectEmpHost(itemEnl) {
      await this.consultarItemsApps()
      await dbL.collection('ctaRedirect')
      if (this.itemsApps) {
        let itemApp = this.itemsApps.filter( item => item.app == 'personal')
        await dbL.collection('itemApp').set(itemApp)
        await dbL.collection('ctaRedirect').add({redirectHost: this.$route.params.userName, tipoEnlace: itemEnl}) 
        this.$router.push({name: 'registro', params: {app: 'personal'} })
      } else {
        // Manejo del caso en que this.itemsApps es undefined
        console.error("this.itemsApps es undefined");
      }
    },
    async irHostPull() {
      this.dialogUhPull = false
      dbL.collection('userHostPull').delete()
      this.$router.push({name: 'Entrada', params: {userName: this.userHostPull[0].userNameHostPull}})
      this.userHostPull = ''
    },
    async cleanUserHost() {
      await dbL.collection('fromSameHost').delete()
    },
    async movingSameHost() {
      this.$emit('llamar-consultar-extraapps');
      
      await dbL.collection('fromSameHost');
      await dbL.collection('fromSameHost').add({sameHost: true, userHost: this.$route.params.userName.toLowerCase()});
      // Realizar otras operaciones en tu código
      this.$router.push({ name: 'oficina' });             
    },
    async closeUserHost() {
      this.dialogUhPull = false
      dbL.collection('userHostPull').delete()
      this.userHostPull = ''
    },
    async verifHostPull() {
      await dbL.collection('userHostPull').get().then(uHPull => {
            this.userHostPull = uHPull
          })
      this.dialogUhPull = true
    },
    async crearCuenta() {
      await dbL.collection('userHostPull').set([{userNameHostPull: this.usuarioHost.userName}])
      this.$router.push({path: `/registro/Negocio/personal`})
    },
    async verifLink() {
      let userNameParametro = this.$route.params.userName.toLowerCase();
      let userNameDocRef = doc(db, 'userNames', userNameParametro);
      let userNameDoc = await getDoc(userNameDocRef);

      if (!userNameDoc.exists()) {
        this.mostrarError('Esta liga nuVin no existe, por favor verifica.');
        // this.$router.push({ path: `/`})
      }
    },
    copy() {
        this.mostrarExito('Dato COPIADO')
    },
    async enviarMsj() {
      this.mostrarOcupado({ titulo: 'Almacenando información', mensaje: 'Enviando mensaje...' });
      let userNameParametro = this.$route.params.userName.toLowerCase();

      try {
        let userNameDocRef = doc(db, 'userNames', userNameParametro);
        let userNameDoc = await getDoc(userNameDocRef);

        if (userNameDoc.exists()) {
          let userName = userNameDoc.data();
          let msjid = uuidv4();

          let redactorUser = this.usuario ? this.usuario.userName : 'usuario-sinCuenta';

          let msjObj = {    
            msjid,
            cliente: redactorUser,
            tipoMensaje: 'mensaje-corto',
            contenidoMsj: this.msjText,
            hora: new Date().toLocaleTimeString('es-MX'),
            fecha: new Date().toISOString().substr(0, 10),
            fechaStd: new Date(),
          };

          let ntfid = uuidv4();

          let notif = {    
            ntfid,
            tipoNotif: 'mensaje-ventanilla',
            asunto: "Has recibido un mensaje en ventanilla",
            cliente: redactorUser,
            hora: new Date().toLocaleTimeString('es-MX'),
            fecha: new Date().toISOString().substr(0, 10),
            fechaStd: new Date(),
          };

          await setDoc(doc(db, 'usuarios', userName.uid, 'notificaciones', ntfid), notif);
          await setDoc(doc(db, 'usuarios', userName.uid, 'ventanilla', msjid), msjObj);

          this.ocultarOcupado();
          this.mostrarExito('El mensaje ha sido enviado.');
          this.dialogMsj = false;
          this.msjText = '';
        }
      } catch (error) {
        this.mostrarError('Ocurrió un error. Por favor verifica internet y reintenta el envío.');
      }
    },
    goWithMember(member) {
      this.member = member
      this.$router.push({ path: `/${this.member}/datos`})
    },
    async tabChange(item) {

      if (this.usuarioHost.app == 'restaurante') {
        
        
        this.activeTab = `/${this.usuarioHost.userName}`
        // this.$router.push({ path: `/${this.usuarioHost.userName}`})
        this.componenteActual2 = item.nombreRest 

      } else if (this.usuarioHost.app == 'plaza') {
        
        this.activeTab = `/${this.usuarioHost.userName}`
        // this.$router.push({ path: `/${this.usuarioHost.userName}`})   
        this.componenteActual3 = item.nombrePlaz

      } else if (this.usuarioHost.app == 'despachocp') {
        
        this.activeTab = `/${this.usuarioHost.userName}`
        // this.$router.push({ path: `/${this.usuarioHost.userName}`})   
        this.componenteActual4 = item.nombreDcp

      } else if (this.usuarioHost.app == 'servicios profesionales') {
        
        this.activeTab = `/${this.usuarioHost.userName}`
        // this.$router.push({ path: `/${this.usuarioHost.userName}`})   
        this.componenteActual5 = item.nombreServPro

      } else if (this.usuarioHost.app == 'consultorio') {
        
        this.activeTab = `/${this.usuarioHost.userName}`
        // this.$router.push({ path: `/${this.usuarioHost.userName}`})   
        this.componenteActual8 = item.nombreConsultorio

      } else if (this.usuarioHost.app == 'comercio') {
        
        this.activeTab = `/${this.usuarioHost.userName}`
        // this.$router.push({ path: `/${this.usuarioHost.userName}`})   
        this.componenteActual6 = item.nombreComercio

      } else if (this.usuarioHost.app == 'orgevent') {
        
        this.activeTab = `/${this.usuarioHost.userName}`
        // this.$router.push({ path: `/${this.usuarioHost.userName}`})   
        this.componenteActual7 = item.nombreOrgEvent

      } else {

        this.activeTab = `/${this.usuarioHost.userName}`
        this.$router.push({ path: `/${this.usuarioHost.userName}`})
        this.componenteActual1 = item.nombrePers
      }

    },
    forceRerender() {
      this.componentKey += 1;      
    },
    async tiempoPrueba() {

      this.vtto = this.usuarioHost.fechaInicioCuenta.toDate().getTime()
      this.vttoX2= this.vtto + (86400000 *this.diasPrueba)
      
      if(this.today > this.vttoX2) {
        this.dialog2 = true
      }
    },   
    async aceptarSerColaborador() {
      let userNameParametro = this.$route.params.userName.toLowerCase();
      let userNameDocRef = doc(db, 'userNames', userNameParametro);
      let userNameDoc = await getDoc(userNameDocRef);

      if (userNameDoc.exists()) {
        let userHost = userNameDoc.data();
        let usuarioParametro = this.usuario.userName.toLowerCase();
        let usuarioDocRef = doc(db, 'userNames', usuarioParametro);
        let usuarioDoc = await getDoc(usuarioDocRef);
        let uDoc = usuarioDoc.data();

        let colid = uuidv4();
        let colr = {
          colid,
          userName: this.usuario.userName,
          userNameHost: this.usuarioHost.userName,
          estatusAccesos: [],
        };

        let enlid = uuidv4();
        let enl = {
          enlid,
          enlace: this.usuarioHost.userName,
          tipoEnlace: ''
        };

        try {
          await setDoc(doc(db, 'usuarios', uDoc.uid, 'enlacesnuvin', enlid), enl);
          await setDoc(doc(db, 'usuarios', userHost.uid, 'colaboradores', colid), colr);
          
          this.dialog = false;
          this.mostrarExito('Te has postulado como Colaborador');
        } catch (error) {
          this.mostrarError('Ocurrió un error registrando al Colaborador');
        }
      }
    },
    async aceptarSerMiembro() {
      let miemid = uuidv4();
      let userNameParametro = this.$route.params.userName.toLowerCase();
      let userNameDocRef = doc(db, 'userNames', userNameParametro);
      let userNameDoc = await getDoc(userNameDocRef);

      if (userNameDoc.exists()) {
        let userHost = userNameDoc.data();

        let miembr = {
          miemid,
          SistemaApp: this.usuario.app,
          userName: this.usuario.userName,
          userNameHost: this.usuarioHost.userName,
          switchMiembro: false,
          foto: this.usuario.fotoPortada64
        };

        try {
          await setDoc(doc(db, 'usuarios', userHost.uid, 'miembros', miemid), miembr);

          this.dialogPostMiembros = false;
          this.mostrarExito('Has solicitado ser Miembro');
        } catch (error) {
          this.mostrarError('Ocurrió un error al registrarse');
        }
      }
    },
    async limpiarEntradaUsuarioHost() {
      dbL.delete()
      await this.borrarActualUsuarioHost()
      await this.consultarUsuarioHost()
      // this.forceRerender()
    },
    async consultarUsuarioHost() {
      await this.actualizarPerfilAuth()
      await this.actualizarUsuarioHost()
      await this.consultarColaboradoresFB()
      
      // this.consultarCol()
      // this.consultarTicket()
      if (this.usuarioHost.exists) {
        this.tiempoPrueba()  
      }
      this.forceRerender()
      // let uGir = this.usuarioHost.app
      // if (uGir == 'restaurante' || uGir == 'comercio') {
        // await this.consultarAutoPedCliFB()
        // await this.consultarComYpedLB()
      // }
    },
    async consultarClientes() {
      this.clientes = []; // Limpiar el array antes de agregar nuevos elementos
      let userNameParametro = this.$route.params.userName.toLowerCase();
      let userNameDocRef = doc(db, 'userNames', userNameParametro);
      let userNameDoc = await getDoc(userNameDocRef);

      if (userNameDoc.exists()) {
        let userHost = userNameDoc.data();

        try {
          let resultado = await getDocs(collection(db, 'usuarios', userHost.uid, 'clientes'));

          resultado.forEach(doc => {
            let col = doc.data();
            this.clientes.push(col);
          });
        } catch (error) {
          this.mostrarError('Ocurrió un error consultando los Clientes.');
        }
      }
      this.ocultarOcupado();
    },
    async consultarAppExtra() {
      this.appExtra = []; // Limpiar el array antes de agregar nuevos elementos
      let userNameParametro = this.$route.params.userName.toLowerCase();
      let userNameDocRef = doc(db, 'userNames', userNameParametro);
      let userNameDoc = await getDoc(userNameDocRef);

      if (userNameDoc.exists()) {
        let userHost = userNameDoc.data();

        try {
          let resultado = await getDocs(collection(db, 'usuarios', userHost.uid, 'comprasAppExtra'));

          resultado.forEach(doc => {
            let appex = doc.data();
            this.appExtra.push(appex);
          });
        } catch (error) {
          this.mostrarError('Ocurrió un error consultando las Apps Extras.');
        }
      }
      this.ocultarOcupado();
    },
    async consultarColaboradoresFB() {
      let userNameParametro = this.$route.params.userName.toLowerCase();
      try {
        let userNameDocRef = doc(db, 'userNames', userNameParametro);
        let userNameDoc = await getDoc(userNameDocRef);

        if (userNameDoc.exists()) {
          let userHost = userNameDoc.data();
          await dbL.collection('colaboradores').set([])
          let resultado = await getDocs(collection(db, 'usuarios', userHost.uid, 'colaboradores'));

          this.colaboradores = [];
          resultado.forEach(async (doc) => {
            let col = doc.data();
            this.colaboradores.push(col);
            dbL.collection('colaboradores').add(col)
          });
        }
      }
      catch (error) {                
          // this.mostrarError('Ocurrió un error consultando el Colaborador.')                
      }      
    },
    async consultarComYpedLB() {
      let userOnLine = '';
      if (this.perfilAuth == true) {
        userOnLine = this.colaborador.userName;
      } else {
        if (this.perfilAuth == false) {
          userOnLine = this.colaborador.colid;
        }
      }

      await dbL.collection('comandas').get().then(comds => {
          this.comandas = comds.filter( c => c.mesero.colid == userOnLine );
      })
      await dbL.collection('pedidosTot').get().then(peds => {
          this.pedidosTot = peds
      }) 
      await this.datosSinEnviarAservidor()        
    },    
    async datosSinEnviarAservidor() {
        let cOutS = await this.comandas.some( element => element.placeStored == 'stored-LB' )
        let pOutS = await this.pedidosTot.some( element => element.placeStored == 'stored-LB' )
        // let cOutS = this.comandas.some( element => element.placeStored == 'stored-LB' && element.userNameHost == this.userNameHost.userName)
        // let pOutS = this.pedidosTot.some( element => element.placeStored == 'stored-LB' && element.userNameHost == this.usuarioHost.userName)

        if (cOutS || pOutS) {
            this.dSenvAserv = true
        } else {
            this.dSenvAserv = false
            if (navigator.onLine) {
              this.consultarComandasFirebase()
              this.consultarPedidosFirebase()
            }
        }
    },
    // async consultarComandasFirebase() {
    //     let userNameParametro = this.$route.params.userName.toLowerCase()
    //     // let colaborador = this.colaborador
    //     try {
    //         let userNameDoc = await db.collection('userNames')
    //                                         .doc(userNameParametro)
    //                                         .get()

    //         if(userNameDoc.exists) {
    //             let userName = userNameDoc.data()
                
    //             await dbL.collection('comandas')
    //             let resultado = await db.collection('usuarios')
    //                                     .doc(userName.uid)
    //                                     .collection('comandas')
    //                                     .where('fecha', '>=', this.yesterday)
    //                                     .where('fecha', '<=', this.today)
    //                                     .get()                
                
    //             await resultado.docs.forEach(doc => {
                    
    //                 let comanda = doc.data()
    //                 delete comanda.uidHost;
    //                 this.comandas.push(comanda)
    //                 dbL.collection('comandas').add(comanda)
    //             })
                
    //         }
    //     }
    //     catch (error) {                
    //         // this.mostrarError('Ocurrió un error consultando las comandas.')                
    //     }
    // },
    // async consultarPedidosFirebase() {
    //   this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'CCargando datos... Si pasan más de 5 segundos y la carga no ha concluido, da click en reload (F5) o Swipe-Abajo' })
    //   let userNameParametro = this.$route.params.userName.toLowerCase()
    //   let userNameDoc = await db.collection('userNames')
    //                     .doc(userNameParametro)
    //                     .get()

    //   if(userNameDoc.exists) {
    //     let userHost = userNameDoc.data() 

    //     try {
    //         await dbL.collection('pedidosTot')
    //         let resultado = await db.collectionGroup('pedidoTot')
    //                                 .where('uidHost', '==', userHost.uid )
    //                                 .where('mesero.colid', '==', this.colaborador.colid)
    //                                 .where('fecha', '<=', this.today)  
    //                                 .where('fecha', '>=', this.yesterday)                                     
    //                                 .get()   

    //         await resultado.docs.forEach(subdoc => {
    //             let pedidoI = subdoc.data();
    //             delete pedidoI.uidHost;
    //             dbL.collection('pedidosTot').add(pedidoI)   
    //         })
    //         // let arryPs = await this.arryP.filter(item => item !== item.uidHost)
            
            
    //     }
    //     catch (error) {                
    //         // this.mostrarError('Ocurrió un error consultando el pedido.')                
    //     }
    //   }
    //   this.ocultarOcupado()
    // },     
    // async consultarAutoPedCliFB() { 

    //     try {
    //       await dbL.collection('arrayspedidos')
    //       let autoPedCocina = await db.collectionGroup('pedidoTot')
    //                               // .where('fecha', '==', fechaActual)              
    //                               .where('userNameHost', '==', this.usuarioHost.userName)
    //                               .where('userName', '==', this.usuario.userName)
    //                               .get()

    //       await autoPedCocina.docs.forEach(doc => {

    //           let cocinaAutoPed = doc.data()
    //           // this.arrayspedidos.push(cocinaAutoPed)
    //           dbL.collection('arrayspedidos').add(cocinaAutoPed)
    //       })
    //     }
    //     catch (error) {                
    //         // this.mostrarError('Ocurrió un error consultando el auto pedido.')                
    //     }
    //   // }
    //   this.consulAutoPedCliLB()
    // },
    // async consulAutoPedCliLB() {
    //   await dbL.collection('arrayspedidos').get().then(arryp => {
    //     this.arrayspedidos = arryp
    //   })
    // },
  },
}
</script>

<style lang="stylus" scoped>
.hello-text {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-out {
  animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}


  .rounded-img {
    /* Añade estilos para redondear los bordes del botón */
    border-radius: 50px;
  }

  .rounded-circle {
    border-radius: 50%;
  }

  .v-progress-circular {
    margin: 1rem
  }

  .item {
    min-height: 50px;
    min-width: 80px;
  }
</style>