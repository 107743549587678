<template>
  <v-layout justify-center align-center class="mt-5 pt-5">
    <v-flex xs12 sm4>
      <!-- <v-slide-y-transition mode="out-in" @enter="enter"> -->
        <v-slide-y-transition mode="out-in">
        <v-card v-if="vista == 1" :key="1" flat color="transparent">
          <v-toolbar color="transparent" card class="pb-5">
            <v-layout column>
              <v-flex xs2 class="text-xs-center display-2 principal elevation-5 rounded-img">
                <p class="font-weight-bold white--text mt-2">{{ $route.params.app }}</p>
              </v-flex>
              <v-flex class="text-xs-center headline my-3">
                <p class="font-weight-bold">Crea tu cuenta</p>
              </v-flex>
            </v-layout>
          </v-toolbar>
          <v-card-text>
            <v-text-field outline @blur="$v.f1.email.$touch()" :error-messages="erroresEmail" v-model="f1.email" label="Email donde enviaremos tu acceso"></v-text-field>
            <!-- <v-text-field outline @blur="$v.f1.password.$touch()" :error-messages="erroresPassword" v-model="f1.password" label="Password" type="password"></v-text-field>
            <v-text-field outline @keyup.enter="siguiente(1)" @blur="$v.f1.repetirPassword.$touch()" :error-messages="erroresRepetirPassword" v-model="f1.repetirPassword" label="Repetir Password" type="password"></v-text-field> -->
          </v-card-text>
          <v-card-text>
            <v-layout justify-end>
              <v-btn :depressed="$v.f1.$invalid" :disabled="$v.f1.$invalid" @click="siguiente(1)" color="principal white--text" class="caption text-none">Siguiente</v-btn>
            </v-layout>
          </v-card-text>
          <!-- <v-flex class="text-xs-center">
            <span class="font-weight-bold">¿Ya tienes cuenta?</span>
            <v-btn :to="{ name: 'login' }" flat color="principal white--text" class="caption text-none">
              Ingresa
            </v-btn>
          </v-flex> -->
        </v-card>
        <!-- <v-card v-if="vista == 2" :key="2" class="elevation-6">
          <v-toolbar color="transparent" card>
            <v-toolbar-title>
              Ingresa tu Nombre Personal o Negocio
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field @blur="$v.f2.nombres.$touch()" :error-messages="erroresNombres" v-model="f2.nombres" label="Nombre"></v-text-field>
            <v-text-field @keyup.enter="siguiente(2)"  v-model="f2.apellidos" label="Apellido (si es para cta. personal)"></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-layout>
              <v-flex xs6>
                <v-layout justify-start>
                  <v-btn @click="vista--">Atrás</v-btn>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout justify-end>
                  <v-btn @click="siguiente(2)" :depressed="$v.f2.$invalid" :disabled="$v.f2.$invalid" color="principal white--text">Siguiente</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card> -->
        <!-- <v-card v-if="vista == 3" :key="3" class="elevation-6">
          <v-toolbar color="transparent" card>
            <v-toolbar-title>
              Selecciona tu Fecha de Creación
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-layout justify-center>
              <v-date-picker ref="calendario" :max="fechaMaxima" v-model="fechaNacimiento" reactive locale="es-co" class="elevation-3"></v-date-picker>
            </v-layout>
          </v-card-text>
          <v-card-text>
            <v-layout>
              <v-flex xs6>
                <v-layout justify-start>
                  <v-btn @click="vista--">Atrás</v-btn>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout justify-end>
                  <v-btn @click="siguiente(3)" :depressed="$v.fechaNacimiento.$invalid" :disabled="$v.fechaNacimiento.$invalid" color="principal white--text">Siguiente</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card> -->
        <v-card v-if="vista == 2" :key="2" flat color="transparent">
          <v-toolbar color="transparent" card class="mt-3">
            <v-flex class="text-xs-center title">
              <p class="font-weight-bold">Ingresa el nombre público para tu nuVin</p>
            </v-flex>
          </v-toolbar>
          <v-card-text>
            <v-text-field outline @blur="$v.userName.$touch()" :error-messages="erroresUserName" v-model="userName" label="Nombre para tu nuVin"></v-text-field>
            <div row>
              <avisoDePrivacidad/>
              <terminosYcondiciones/>
            </div><br><br>
            <v-layout justify-center>
              <v-flex class="xs2">
                <v-checkbox v-model="checkbox"></v-checkbox>
              </v-flex>
              <v-flex class="xs10">
                <span>He leído y estoy de acuerdo con los 'Términos y Condiciones' y 'Aviso de Privacidad'</span>
              </v-flex>
            </v-layout>
            <v-flex xs6>
              <v-text-field  label="ID Subscripción" v-model="codeLanding" outline></v-text-field>
            </v-flex>
          </v-card-text>
          <v-card-text>
            <v-layout>
              <v-flex xs6>
                <v-layout justify-start>
                  <v-btn @click="vista--" class="caption text-none" >Atrás</v-btn>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout justify-end>
                  <v-btn  class="caption text-none" v-if="checkbox == false" disabled>Registrarse</v-btn>
                  <v-btn  class="caption text-none" v-if="checkbox == true" @click="registrar" :depressed="$v.userName.$invalid" :disabled="$v.userName.$invalid" color="principal white--text">Registrarse</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-flex>
    <!-- Diálogo que se muestra si ya hay un usuario -->
    <v-dialog v-model="dialogHayU" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">Mensaje</v-card-title>
        <v-card-text>No puedes crear una cuenta porque ya eres usuario. Cierra Cesión y vuelve a intentarlo.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark text @click="salirRegistro">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { required, email, minLength, maxLength, alphaNum } from 'vuelidate/lib/validators'
import { nombreCompuesto } from '@/utilidades/validaciones'
import { mapMutations, mapGetters, mapState } from 'vuex'
import { auth, db } from '@/firebase'
import avisoDePrivacidad from '@/components/avisoDePrivacidad'
import terminosYcondiciones from '@/components/terminosYcondiciones'
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
  components: { avisoDePrivacidad, terminosYcondiciones },
  data() {
    return {
      dialogHayU: false,
      codeLanding: '',
      checkbox: false,
      vista: 1,
      uid: '',
      f1: {
        email: '',
        // password: '',
        repetirPassword: ''
      },
      f2: {
        nombres: '',
        apellidos: ''
      },
      // fechaNacimiento: null,
      // fechaMaxima: null,
      userName: '',
      app: '',
      itemApp: ''
    }
  },
  validations: {
    f1: {
      email: {
        required,
        email
      },
      // password: {
      //   required,
      //   minLength: minLength(6),
      //   maxLength: maxLength(20)
      // },
      // repetirPassword: {
      //   sameAs: sameAs('password')
      // }
    },
    f2: {
      nombres: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
        nombreCompuesto
      },
      apellidos: {
        // required,
        // minLength: minLength(3),
        maxLength: maxLength(20),
        nombreCompuesto
      }
    },
    // fechaNacimiento: {
    //   required
    // },
    userName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(33),
      alphaNum
    }
  },
  mounted() {
    // Comprobamos si ya hay un usuario al iniciar el componente
    if (this.usuario) {
      this.dialogHayU = true;
    }
  },
  computed: {
    ...mapState('sesion', ['usuario']),
    ...mapGetters('sesion', ['saludo']),
    erroresEmail() {
      let errores = []
      if (!this.$v.f1.email.$dirty) { return errores }
      if (!this.$v.f1.email.required) { errores.push('Ingresa tu email.') }
      if (!this.$v.f1.email.email) { errores.push('Ingresa un email válido.') }
      return errores
    },
    // erroresPassword() {
    //   let errores = []
    //   if (!this.$v.f1.password.$dirty) { return errores }
    //   if (!this.$v.f1.password.required) { errores.push('Ingresa tu password.') }
    //   if (!this.$v.f1.password.minLength) { errores.push('Ingresa al menos 6 caracteres.') }
    //   if (!this.$v.f1.password.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
    //   return errores
    // },
    erroresRepetirPassword() {
      let errores = []
      if (!this.$v.f1.repetirPassword.$dirty) { return errores }
      if (!this.$v.f1.repetirPassword.sameAs) { errores.push('Las contraseñas no coinciden.') }
      return errores
    },
    erroresNombres() {
      let errores = []
      if (!this.$v.f2.nombres.$dirty) { return errores }
      if (!this.$v.f2.nombres.required) { errores.push('Ingresa tu nombre.') }
      if (!this.$v.f2.nombres.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
      if (!this.$v.f2.nombres.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
      if (!this.$v.f2.nombres.nombreCompuesto) { errores.push('Ingresa un nombre válido.') }
      return errores
    },
    erroresApellidos() {
      let errores = []
      if (!this.$v.f2.apellidos.$dirty) { return errores }
      if (!this.$v.f2.apellidos.required) { errores.push('Ingresa tus apellidos.') }
      if (!this.$v.f2.apellidos.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
      if (!this.$v.f2.apellidos.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
      if (!this.$v.f2.apellidos.nombreCompuesto) { errores.push('Ingresa un apellido válido.') }
      return errores
    },
    erroresUserName() {
      let errores = []
      if (!this.$v.userName.$dirty) { return errores }
      if (!this.$v.userName.required) { errores.push('Ingresa tu nombre Comercial.') }
      if (!this.$v.userName.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
      if (!this.$v.userName.maxLength) { errores.push('Ingresa máximo 33 caracteres.') }
      if (!this.$v.userName.alphaNum) { errores.push('Ingresa sólo letras y números, sin espacios') }
      return errores
    },
    suscripcionID() {
      if (this.codeLanding !== '') {
        return this.codeLanding;
      } else {
        return 'sin-suscripcion';
      }
    }
  },
  created() {
    // this.checkCodigoFromLanding()
  },
  methods: {
    ...mapMutations(['mostrarOcupado', 'ocultarOcupado', 'mostrarExito', 'mostrarError', 'mostrarAdvertencia']),
    ...mapMutations('sesion', ['actualizarUsuario']),
    async salirRegistro() {
      this.dialogHayU = false
      this.$router.push({name: 'Entrada', params: {userName: this.usuario.userName}})
    },
    async checkCodigoFromLanding () {
      await dbL.collection('subscriPP').get().then(cod => {
                    this.objSubscrip = cod[0];
                    this.codeLanding = cod[0].dataSubscriPayPal.subscriptionID;
                    // this.codeLanding = 'XZXZ'
                }).catch(() => {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                })
    },

    siguiente(vista) {
      switch (vista) {
        case 1:
          if (this.$v.f1.$invalid) {
            this.$v.f1.$touch()
            return
          }
          else {
            this.vista++
          }
          break

        case 2:
          if (this.$v.f2.$invalid) {
            this.$v.f2.$touch()
            return
          }
          else {
            this.vista++
          }
          break

        // case 3:
        //   if(this.$v.fechaNacimiento.$invalid) { return }

        //   this.vista++
        //   break
      }
    },
    async registrar() {
      if (this.$v.userName.$invalid) { return }
      
      if (this.codeLanding) {
          // Verificar si el código de suscripción es válido
        const suscripcionRef = db.collection('suscripcionesPayPal')
          .where('dataSubscriPayPal.subscriptionID', '==', this.codeLanding)
          .where('estatusCodigo', '==', 'no-utilizado');

        const querySnapshot = await suscripcionRef.get();

        if (querySnapshot.empty) {
          // El código no es válido o ya ha sido utilizado
          this.mostrarError('El código no es válido o ya ha sido utilizado');
          return;
        }

        // Cambiar el estatusCodigo a 'utilizado' para la primera coincidencia encontrada
        const primeraCoincidencia = querySnapshot.docs[0];
        await primeraCoincidencia.ref.update({
          estatusCodigo: 'utilizado',
        });
      }

      let userNameExistente = await db.collection('userNames')
                                      .doc(this.userName.toLowerCase())
                                      .get()

      if(userNameExistente.exists) {
        this.mostrarAdvertencia(`El nombre Comercial '${this.userName}', ya está tomado, selecciona uno diferente`)
        return
      }

      try {
        this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })

        this.app = this.$route.params.app
        if (auth.currentUser) {
          this.uid = auth.currentUser.uid
        } else {
          // Cambio: En lugar de crear un usuario con correo y contraseña, envía un enlace de autenticación al correo electrónico del usuario.
          const actionCodeSettings = {
            url: 'http://nuvin.app/verificacion-email-link?email=' + this.f1.email + '&userName=' + this.userName + '&app=' + this.app,
          // Cambia esto por la URL de redirección de tu aplicación.
            handleCodeInApp: true, // Habilita la confirmación del código en la aplicación.
          };

          // Cambio: Envía el enlace de autenticación al correo electrónico proporcionado.
          await auth.sendSignInLinkToEmail(this.f1.email, actionCodeSettings)
          // Cambio: Informa al usuario que se ha enviado un enlace de autenticación a su correo electrónico.

          // Puedes mostrar un mensaje de éxito o redirigir al usuario a una página que indique que se ha enviado un enlace de verificación al correo electrónico.
          this.$router.push( { name: 'envio-verificacion-email' } )
          // Nota: No necesitas crear un usuario aquí, ya que el usuario debe hacer clic en el enlace de verificación en su correo electrónico antes de registrarse.

          // El flujo de confirmación del enlace se manejará después de que el usuario haga clic en el enlace y regrese a tu aplicación, en VerificacionEmail componente

          return; // Sale de la función después de enviar el enlace.
        }
        
      }
      catch (error) {

        switch(error.code) {
          case 'auth/email-already-in-use':
            this.mostrarAdvertencia('Ya se ha registrado esta diección de email con anterioridad')
            break 

          default:
            this.mostrarError('Ocurrió un error registrando tu cuenta. Inténtalo más tarde.')
            console.log(error)
            break
        }
      }
      finally {
        
        dbL.collection('ctaRedirect').delete()   
        this.ocultarOcupado()
      }
    },
  },
}
</script>

<style>

.rounded-img {
  /* Añade estilos para redondear los bordes del botón */
  border-radius: 50px;
}

</style>
