<template>
    <v-layout wrap>
        <v-flex xs12 ml-3 mt-2>
            <v-btn icon  dark :to="{ name: 'oficina', params: {userName: usuarioHost.userName} }">
                <v-icon small dark left color="black">arrow_back</v-icon>
            </v-btn>
            <span class="body-2">oficina</span>
        </v-flex>
        <v-card-title primary-title>
            <h3 class="headline mb-0">Escoge tu forma de pago:</h3>
        </v-card-title>
        <br>
        <!-- <div>
            <stripe-checkout
            ref="checkoutRef"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
            /> -->
            <!-- <button @click="submit">Pagar con tarjeta</button> -->
        <!-- </div> -->

        <v-card-text>
            Por favor, realiza tu pago usando <span class="font-weight-bold">Transferencia 
            Electrónica,</span> a favor de: <br><br>
            <tr>Titular de cuenta: <span class="font-weight-bold">{{ datosBanco.titular }}</span><v-icon class="ml-2" color="blue" v-clipboard:copy="datosBanco.titular" small @click="copy" left>mdi-content-copy</v-icon></tr>
            <tr>Número de cuenta: <span class="font-weight-bold">{{ datosBanco.cuenta }}</span><v-icon class="ml-2" color="blue" v-clipboard:copy="datosBanco.cuenta" small @click="copy" left>mdi-content-copy</v-icon></tr>
            <tr>Núumero CLABE SPEI: <span class="font-weight-bold">{{ datosBanco.clabe }}</span><v-icon class="ml-2" color="blue" v-clipboard:copy="datosBanco.clabe" small @click="copy" left>mdi-content-copy</v-icon></tr>
            <tr>Banco: <span class="font-weight-bold">{{ datosBanco.banco }}</span></tr>
            <tr v-if="referenciaPago == 'total-cuotas'">Monto a transferir: <strong>${{ totCuotas[0].totalCuotas }} MXN</strong></tr><br>
            Adjunta tu comprobante de pago aquí:
        </v-card-text>
        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                    <v-btn small color="primary" class="caption" dark v-on="on">Comprobante</v-btn>
                </template>
                <v-card>
                    <v-toolbar dark color="">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                    </v-toolbar>
                    <v-layout justify-center align-center wrap column>
                    <!-- <v-flex xs12 sm10 md8 lg6 xl4 mt-3> -->
                        <!-- <v-card class="elevation=5 ma-3"> -->
                            <v-flex xs12 sm6 md4 mt-3>
                                <v-card class="mt-2">
                                    <v-toolbar card>
                                    <v-toolbar-title>
                                        <span v-if="vista == 1">Comprobante de Pago</span>
                                        <span v-if="vista == 2" >Vista Previa</span>
                                    </v-toolbar-title>        
                                    </v-toolbar>
                                    <v-card-text>
                                    <v-img v-if="vista == 1" alt="Foto de Pago"></v-img>
                                    <div v-if="vista == 2" ref="vistaPrevia" class="vistaPrevia"></div>
                                    </v-card-text>
                                    <v-card-text>
                                    <!-- <v-layout v-if="vista == 1" justify-center>
                                        <v-btn color="secondary" v-if="usuarioHost" :to="{ name: 'homePublicaUsuario', params: { userName: usuarioHost.userName } }">Regresar</v-btn>
                                    </v-layout> -->
                                    <v-layout v-if="vista == 2" justify-center wrap>
                                        <v-flex xs12>
                                            <v-text-field
                                                v-model="folioComp"
                                                label="Folio"
                                                placeholder="núm. que aparece en el comprobante"
                                                outline
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs6>
                                        <v-btn @click="vista = 1">Cancelar</v-btn>
                                        </v-flex>
                                        <v-flex xs6>
                                        <v-btn v-if="folioComp != '' && itemAppExt != '' && referenciaPago == 'cuotaProp-appExt'" @click="guardarPagoAppExt" color="secondary">Enviar</v-btn>
                                        <v-btn v-if="folioComp != '' && itemPicked != '' && referenciaPago == 'cuotaProp-plan'" @click="guardarPagoAppPlan" color="secondary">Enviar</v-btn>
                                        <v-btn v-if="folioComp != '' && totCuotas != '' && referenciaPago == 'total-cuotas'" @click="guardarPagoTotApps" color="secondary">Enviar</v-btn>
                                        </v-flex>
                                    </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                            <v-flex xs12 sm6 md4 mt-3>
                                <v-card>
                                    <v-tabs icons-and-text grow>
                                    <v-tabs-slider color="primary"></v-tabs-slider>
                                    <v-tab>
                                        <span class="caption">Subir Imagen</span>
                                        <v-icon>cloud_upload</v-icon>
                                    </v-tab>
                                    <v-tab-item>
                                        <file-pond v-if="vista == 1" @addfile="cargarImagen" instant-upload="false" 
                                                fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}"
                                                labelFileTypeNotAllowed="Agrega una imagen .png o .jpg"
                                                accepted-file-types="image/jpeg, image/png" 
                                                label-idle="Selecciona o arrastra una imagen...">
                                        </file-pond>
                                        <v-responsive v-if="vista == 2">
                                        <img ref="imagenOriginal" src="" alt="" class="edicionImagen">
                                        </v-responsive>
                                    </v-tab-item>
                                    <!-- <v-tab v-if="fotosPortada.length > 1">
                                        <span class="caption">Galería</span>
                                        <v-icon>photo_library</v-icon>
                                    </v-tab> -->
                                    <!-- <v-tab-item v-if="fotosPortada.length > 1">
                                        <v-layout wrap justify-start>
                                        <v-flex xs6 sm4 lg3 v-for="foto in fotosPortada" :key="foto.id">
                                            <v-card class="ma-3">
                                            <v-card-text>
                                                <v-img :src="foto.urlFotoPago" alt="Foto Portada"></v-img>
                                                <v-layout class="mt-3">
                                                <v-flex xs6>
                                                    <v-layout justify-start>
                                                    <v-icon v-if="foto.urlFotoPago == usuarioHost.fotoPago" color="green" medium>check_circle</v-icon>
                                                    <v-icon v-else color="grey" @click="seleccionarfotoPago(foto.urlFotoPago)" medium>check_circle_outline</v-icon>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs6>
                                                    <v-layout justify-end>
                                                    <v-icon v-if="foto.urlFotoPago != usuarioHost.fotoPago" medium @click="preguntarEliminarImagen(foto)">delete</v-icon>
                                                    </v-layout>
                                                </v-flex>
                                                </v-layout>
                                            </v-card-text>
                                            </v-card>
                                        </v-flex>
                                        </v-layout>
                                        <v-dialog v-model="preguntaEliminar" max-width="400" persistent>
                                        <v-card>
                                            <v-card-text class="title">
                                            La imagen se eliminará permanentemente, ¿Deseas Continuar?
                                            </v-card-text>
                                            <v-btn color="secondary" flat @click="preguntaEliminar = false">Cancelar</v-btn>
                                            <v-btn flat @click="eliminarImagen">Eliminar</v-btn>
                                        </v-card>
                                        </v-dialog>
                                    </v-tab-item> -->
                                    </v-tabs>
                                </v-card>
                            </v-flex>
                        <!-- </v-card> -->
                    <!-- </v-flex> -->
                    </v-layout>
                </v-card>
                </v-dialog>
            </v-layout>    
        </template>

        <div class="mt-3">
            <span class="subheading ml-4 font-weight-black">Si prefieres que te guiémos en el proceso de pago contáctenos en
                Soporte</span><strong class="logoFont ml-1">nu<strong class="principal--text">Vin</strong></strong><br>
            <span><span class="primary--text font-weight-bold ml-2">email:</span>  nubesdebits@gmail.com</span><br>
            <span><span class="primary--text font-weight-bold ml-2">móvil-whatsapp:</span>  9996470286</span><br>
        </div>
    </v-layout>
</template>

<script>
// import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { db, storage } from '@/firebase'
import { mapMutations, mapState } from 'vuex'
import Localbase from 'localbase'

let dbL = new Localbase('db')

import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond/dist/filepond.min.css"

import Cropper from 'cropperjs/dist/cropper'
import 'cropperjs/dist/cropper.css'

import { v4 as uuidv4 } from 'uuid';

const FilePond = vueFilePond(FilePondPluginFileValidateType)

  export default {
    components: { FilePond },
    data () {
    //   this.publishableKey = 'pk_test_wk9TFDEeu4kRrI1pT0wxYrBC00bSQO9djj';
      return {
        // dialogPagoTransf: false,
        loading: false,
        lineItems: [
            {
            price: 'price_1KxXZiGLlS3xAdXuoR2dT2bZ', // The id of the one-time price you created in your Stripe dashboard
            quantity: 10,
            },
        ],
        successURL: 'https://www.nuvin.app',
        cancelURL: 'https://www.nuvin.app/visita-en-lobby',
        datosBanco: {
            titular: 'Grupo Gueboy, S. de R.L. de C.V.',
            banco: 'Banca Mifel, S.A.',
            cuenta: '01600484377',
            clabe: '042910016004843774',
        },
        referenciaPago: '',
        itemPicked: '',
        itemAppExt: '',
        totCuotas: {
            totalCuotas: 0
        },
        folioComp: '',
        dialog: false,
        vista: 1,
        cropper: null,
        //   fotosPortada: [],
        preguntaEliminar: false,
        fotoEliminar: null,
      }
    },

    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
    },

    created() {        
        this.consulTotCuotas()
        this.consulItemPicked()
        this.consulItemAppExt()        
        this.refPago()
    },

    methods: {        
        ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        submit () {
            // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
        },
        refPago() {
            this.referenciaPago = this.$route.params.referencia
        },
        copy() {
            this.mostrarExito('Dato COPIADO')
        },
        async consulTotCuotas() {
            await dbL.collection('totCuotas').get().then(totC => {
                this.totCuotas = totC
            })     
        },
        async consulItemPicked() {
            await dbL.collection('itemPicked').get().then(ipk => {
                this.itemPicked = ipk
            })     
        },
        async consulItemAppExt() {
            await dbL.collection('itemAppExt').get().then(ipk => {
                this.itemAppExt = ipk
            })     
        },
        cargarImagen (error, archivo) {
            if (error) {
                this.mostrarError('Ocurrió un error leyendo la imagen.')
                return
            }

            this.vista = 2

            let reader = new FileReader()

            let vm = this

            reader.onloadend = () => {
                vm.$refs.imagenOriginal.src = reader.result
                vm.recortarImagen()
            }

            if (archivo) {
                if (archivo.file) {
                reader.readAsDataURL(archivo.file)
                }
            }
        },
        recortarImagen() {
            this.cropper = new Cropper(this.$refs.imagenOriginal, {
                preview: this.$refs.vistaPrevia,
                aspectRatio: .68,
                modal: false,
                guides: true,
                sized: false,
                zoomable: false,
                highlight: true,
                background: false,
                autoCrop: true
            })
        },
        async guardarPagoAppExt() {
            this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Almacenando el comprobante de Pago...' })
            
            let canvas = this.cropper.getCroppedCanvas()
            let imagen = canvas.toDataURL('image/jpeg')
            let fotoPagoId = uuidv4()
            let appExtra = this.itemAppExt[0].title
            // let appExtraObj = this.itemAppExt[0]
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let ref = storage.ref()

                    let resultado = await ref.child(`usuarios/${userName.uid}/comprasAppsExtra/${appExtra}/foto-pago/${fotoPagoId}.jpg`)
                                            .putString(imagen, 'data_url')
                                            
                    let batch = db.batch()
                    let urlFotoPago = await resultado.ref.getDownloadURL()
                    let pagoAppId = uuidv4()

                    let fotoPago = {
                        fotoPagoId,
                        urlFotoPago,
                        // appExtraObj: this.itemAppExt[0],
                        folioComp: this.folioComp,
                        fecha: new Date().toISOString().substr(0, 10),
                        activa: 'Activada',
                        estatusPago: 'NoVerif',
                        etiqueta: this.itemAppExt[0].etiqueta,
                        // fechaMes: this.itemAppExt[0].fechaMes, aparece cmo undined por eso no la agregamos
                        fechaSigPago: this.itemAppExt[0].fechaSig,
                        girosqueaplica: this.itemAppExt[0].girosqueaplica,
                        app: this.itemAppExt[0].app,
                        tipoApp: this.itemAppExt[0].tipoApp,
                        icon: this.itemAppExt[0].icon,
                        iconClass: this.itemAppExt[0].iconClass,                        
                        plazo: this.itemAppExt[0].plazo,                        
                        precioMensual: this.itemAppExt[0].precioMensual,
                        precioAnual: this.itemAppExt[0].precioAnual,
                        title: this.itemAppExt[0].title,
                    }   

                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('comprasAppExtra').doc(appExtra), fotoPago)

                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('comprasAppExtra').doc(appExtra)
                            .collection('pagosApps').doc(pagoAppId), fotoPago)

                    await batch.update(db.collection('usuarios').doc(userName.uid), 
                                        { accesosCols: { componente: this.itemAppExt[0].app,
                                                        etiqueta: this.itemAppExt[0].etiqueta,
                                                        funcion: 'herramienta',
                                                        tipoApp: 'herramienta',
                                                        icon: this.itemAppExt[0].icon,
                                                        switch: 'switch' + this.itemAppExt[0].etiqueta,
                                                        valor: false }}, 
                                        // { merge: true }
                                    )

                    // for (let index = 0; index < this.colaboradores.length; index++) {

                    //     await batch.set(db.collection('usuarios').doc(userName.uid)
                    //         .collection('colaboradores').doc(this.colaboradores[index].colid), 
                    //                     { accesosCols: { componente: this.itemAppExt[0].app,
                    //                                     etiqueta: this.itemAppExt[0].etiqueta,
                    
                    //                                     icon: this.itemAppExt[0].icon,
                    //                                     switch: 'switch' + this.itemAppExt[0].etiqueta,
                    //                                     valor: false }},
                    //                     { merge: true }
                    //                     )
                    // }
                    
                    
                    let ntfid = uuidv4()

                    let notif = {    
                        ntfid,
                        tipoNotif: 'compra-appExtra',
                        asunto: "Se ha comprado una appExtra",
                        // avatar: this.usuarioHost.fotoPortada,
                        // irA: "usuarios",
                        cliente: this.usuarioHost.userName,
                        appExtra,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                    }
                
                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('notificaciones').doc(notif.ntfid), notif)

                    await batch.set(db.collection('usuarios').doc('6ooam9l7LdMrCVHG9Qwm6qS7MCq1')
                            .collection('notificaciones').doc(notif.ntfid), notif)

                    await batch.update(db.collection('userNames').doc(this.usuarioHost.userName.toLowerCase()), {notifNew: true})
                    await batch.update(db.collection('userNames').doc(this.usuario.userName.toLowerCase()), {notifNew: true})

                    try {
                        batch.commit()
                    } 
                    finally {
                        this.ocultarOcupado()
                        this.mostrarExito('Has realizado la subscripción de una nuVin App Extra. El comprobante ha sido enviado a verificación. Recibirás una confrmación en tu email.')
                        await dbL.collection('itemAppExt').delete()
                    }
                }
            }
            finally {
                this.ocultarOcupado()
                this.$router.push({ name: 'Entrada', params: {userName: this.usuarioHost.userName} })
            }
        },
        async guardarPagoAppPlan() {
            this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Almacenando el comprobante de Pago...' })
            
            let canvas = this.cropper.getCroppedCanvas()
            let imagen = canvas.toDataURL('image/jpeg')
            let fotoPagoId = uuidv4()
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let ref = storage.ref()
                    let app = this.itemPicked[0].app

                    let resultado = await ref.child(`usuarios/${userName.uid}/comprasAppsExtra/${app}/foto-pago/${fotoPagoId}.jpg`)
                                            .putString(imagen, 'data_url')                                            

                    let urlFotoPago = await resultado.ref.getDownloadURL()
                    let pagoAppId = uuidv4()
                    let batch = db.batch()

                    let fotoPago = {
                        fotoPagoId,
                        urlFotoPago,
                        folioComp: this.folioComp,
                        fecha: new Date().toISOString().substr(0, 10),
                        momentoExacto: new Date().toISOString(),
                        activa: 'Activada',
                        estatusPago: 'NoVerif',
                        fechaSigPago: this.itemPicked[0].fechaSig,
                        app: this.itemPicked[0].app,
                        nivelPlan: this.itemPicked[0].nivelPlan,
                        plazo: this.itemPicked[0].plazo,
                        monto: this.itemPicked[0].monto,
                        userName: this.usuarioHost.userName
                        // precioMensual: this.itemPicked[0].precioMensual,
                        // precioAnual: this.itemPicked[0].precioAnual,
                    }

                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('comprasAppExtra').doc(fotoPago.app), fotoPago)

                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('comprasAppExtra').doc(fotoPago.app)
                            .collection('pagosApps').doc(pagoAppId), fotoPago)

                    await batch.update(db.collection('usuarios').doc(userName.uid), 
                                {
                                    activa: 'Activada',
                                    app: this.itemPicked[0].app,
                                    // tipoCuenta: 'Negocio',
                                    plazo: this.itemPicked[0].plazo,
                                    nivelPlan: this.itemPicked[0].nivelPlan,
                                    fechaSigPago: this.itemPicked[0].fechaSig,
                                }, { merge: true });
                    
                    let ntfid = uuidv4()

                    let notif = {    
                        ntfid,
                        tipoNotif: 'compra-plan',
                        asunto: "Se ha comprado una plan",
                        // avatar: this.usuarioHost.fotoPortada,
                        // irA: "usuarios",
                        cliente: this.usuarioHost.userName,
                        // appExtra,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                    }
                
                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('notificaciones').doc(notif.ntfid), notif)

                    await batch.set(db.collection('usuarios').doc('6ooam9l7LdMrCVHG9Qwm6qS7MCq1')
                            .collection('notificaciones').doc(notif.ntfid), notif)

                    await batch.update(db.collection('userNames').doc(this.usuarioHost.userName.toLowerCase()), {notifNew: true})
                    await batch.update(db.collection('userNames').doc('soportenuvit'), {notifNew: true})

                    try {
                        batch.commit()
                    } 
                    finally {
                        this.ocultarOcupado()
                        this.mostrarExito('Has realizado la subscripción de un Plan nuVin. El comprobante ha sido enviado a verificación. Recibirás una confrmación en tu email.')
                        await dbL.collection('itemPicked').delete()
                    }
                }
            }
            finally {
                this.ocultarOcupado()
                this.$router.push({ name: 'Entrada', params: {userName: this.usuarioHost.userName} })
            }
        },
        async guardarPagoTotApps() {//creo que esta fucion es para juntar la suma del resultado entre planes y apps, y así no pagar una a una
            this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Almacenando el comprobante de Pago...' })
            
            let canvas = this.cropper.getCroppedCanvas()
            let imagen = canvas.toDataURL('image/jpeg')
            let fotoPagoId = uuidv4()
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let ref = storage.ref()

                    let resultado = await ref.child(`usuarios/${userName.uid}/comprasAppsExtra/${this.referenciaPago}/foto-pago/${fotoPagoId}.jpg`)
                                            .putString(imagen, 'data_url')
                                            

                    let urlFotoPago = await resultado.ref.getDownloadURL()
                    let pagoAppId = uuidv4()
                        
                    let fotoPago = {
                        fotoPagoId,
                        urlFotoPago,
                        folioComp: this.folioComp,
                        fecha: new Date().toISOString().substr(0, 10),
                        activa: 'Activada',
                        estatusPago: 'NoVerif',
                        totalCuotas: this.totCuotas[0].totalCuotas,
                        referencia: this.totCuotas[0].referencia,
                        fechaSigPago: this.totCuotas[0].fechaSig,
                    }

                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('comprasAppExtra').doc(this.referenciaPago), fotoPago)

                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('comprasAppExtra').doc(this.referenciaPago)
                            .collection('pagosApps').doc(pagoAppId), fotoPago)

                    await batch.update(db.collection('usuarios').doc(userName.uid),
                                {
                                    // app: this.itemPicked[0].app,
                                    // tipoCuenta: 'Negocio',
                                    fechaSigPago: this.totCuotas[0].fechaSig,
                                });
                    
                    let batch = db.batch()
                    let ntfid = uuidv4()

                    let notif = {    
                        ntfid,
                        tipoNotif: 'compra-plan',
                        asunto: "Se ha enviado comprobante-pago de Cuota mensual",
                        // avatar: this.usuarioHost.fotoPortada,
                        // irA: "usuarios",
                        cliente: this.usuarioHost.userName,
                        // appExtra,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                    }
                
                    await batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('notificaciones').doc(notif.ntfid), notif)

                    await batch.set(db.collection('usuarios').doc('6ooam9l7LdMrCVHG9Qwm6qS7MCq1')
                            .collection('notificaciones').doc(notif.ntfid), notif)

                    await batch.update(db.collection('userNames').doc(this.usuarioHost.userName.toLowerCase()), {notifNew: true})
                    await batch.update(db.collection('userNames').doc(this.usuario.userName.toLowerCase()), {notifNew: true})

                    try {
                        batch.commit()
                        this.mostrarExito('Has enviado la transferencia de la cuota nuVin. El comprobante ha sido enviado.')
                    } 
                    finally {
                        this.ocultarOcupado()
                        await dbL.collection('totCuotas').delete()
                    }
                }
            }
            finally {
                this.ocultarOcupado()
                this.$router.push({ name: 'Entrada', params: {userName: this.usuarioHost.userName} })
            }
        },
        async seleccionarfotoPago(urlFotoPago) {
            let userNameParametro = this.$route.params.userName.toLowerCase()
            this.mostrarOcupado({ titulo: 'Actualizando Imagen', mensaje: 'Estableciendo foto de portada...' })
            
            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()
                await db.collection('usuarios')
                        .doc(userName.uid)
                        .update({ fotoPago: urlFotoPago })

                this.actualizarImgPortada(urlFotoPago)
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error seleccionando la imagen.')
            }
            finally {
                this.ocultarOcupado()
            }
        },
        preguntarEliminarImagen (foto) {
            this.fotoEliminar = foto
            this.preguntaEliminar = true
        },
    },
  }
</script>

<style>
  
.filepond--drop-label {
  font-size: 14pt !important;
}

.edicionImagen {
  width: 100%;
}

.vistaPrevia {
  width: 240px;
  height: 426px;
  overflow: hidden;
}
</style>