<template>
  <!-- <v-layout align-center justify-center fill-height class="mt-5 ml-5 pl-5"> -->
    <v-layout align-center justify-center class="ml-5 mt-5">
    <v-flex xs10>
      <!-- <button @click="toggleWeekends">toggle weekends</button> -->
      <FullCalendar :options="calendarOptions">
        
        <template v-slot:eventContent='arg'>
          <b>{{ arg.event.title.slice(5) }}</b>
        </template>
      </FullCalendar>
    </v-flex>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="400px">
        <!-- <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
        </template> -->
        <v-card>
          <v-card-title>
            <span class="headline" v-if="editedIndex == -1">Crear Evento/Cita</span>
            <span class="headline" v-else>Información de Evento/Cita</span>
            <v-spacer></v-spacer>
            <estasSeguro v-if="editedIndex > -1">
                <v-icon
                    :style="{'color': paletaColores.segundo}"
                    slot="nombrebtn"                   
                    >
                    mdi-delete
                </v-icon>
                <div slot="elemento">
                    <span>este Elemento?</span>
                </div>
                <div slot="btnaceptar">
                    <v-btn flat @click="deleteEv(editedItem)">Ok</v-btn>
                </div>
            </estasSeguro>
          </v-card-title>
          
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <span class="font-weight-bold">Inicia:</span><span>   {{ new Date(selectedEvent.start).toLocaleTimeString() }}</span>  a las <span> {{ new Date(selectedEvent.start).toLocaleDateString() }}</span><br>
                  <span class="font-weight-bold">Finaliza:</span><span>   {{ new Date(selectedEvent.end).toLocaleTimeString() }}</span>  a las <span> {{ new Date(selectedEvent.end).toLocaleDateString() }}</span>
                </v-flex>
                <v-flex xs12 class="mt-3">
                  <v-select v-if="editedIndex == -1"
                  v-model="selectedEvent.cliente"
                  :items="clientesdb"
                  item-text="cliente"
                  item-value="cliente"
                  label="Pacientes"
                  persistent-hint
                  return-object
                  single-line
                  ></v-select>
                  <span v-else><span>Paciente:</span><span class="title ml-2">{{ editedItem.cliente }}</span></span>
                </v-flex>
                <v-flex class="mt-3">
                  <v-text-field v-if="editedIndex == -1" label="Concepto/Referencia" v-model="selectedEvent.description" outline></v-text-field>
                  <v-text-field v-else label="Concepto/Referencia" :value="editedItem.description" outline></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
            
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="editedIndex > -1" :style="{'color': paletaColores.primero}" flat @click="dialog = false">Cerrar</v-btn>
            <v-btn v-if="editedIndex == -1" :style="{'color': paletaColores.primero}" flat @click="dialog = false">Cancelar</v-btn>
            <v-btn v-if="editedIndex == -1 && puedeEditarCalendar" :style="{'color': paletaColores.primero}" flat @click="saveEv">Guardar</v-btn>
            <v-btn v-else :style="{'color': paletaColores.primero}" flat disabled>Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-layout>
</template>
  
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import { mapState, mapMutations } from 'vuex'
import estasSeguro from '@/components/estasSeguro.vue'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    estasSeguro,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'timeGridDay',
        dateClick: this.handleDateClick,
        select: this.handleSelect,
        eventClick: this.handleEventClick,
        events: [
          { title: 'event 1', start: '2023-01-13' },
          { title: 'event 2', date: '2023-01-13' }
        ],
        locale: 'es',
        weekends: true, // initial value
        selectable: true,
      },
      selectedEvent: {
        startStr: '',
        start: '',
        cliente: {
          cliente: '',
          cliid: ''
        },
        cliid: '',
        title: '',
        description: '',
        inicioFecha: '',
        inicioHora: '',
        finFecha: '',
        finHora: '',
      },      
      dialog: false,
      clientesdb: [],
      clienteDB: '',
      editedIndex : -1,
      editedItem: {
        cliente: '',
        cliid: '',
        title: '',
        inicioFecha: '',
        inicioHora: '',
        finFecha: '',
        finHora: '',
      },
      eventsMap: {},
      fixDepur: [],
      listEventos: [],
      datosx: ''
    }
  },

  created() {
    this.consulEventos()
  },

  computed: {
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['usuarioHost']),      
    ...mapState('usuarioHost', ['perfilAuth']),
    paletaColores() {
      if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
          return this.usuarioHost.designColors;
      } else {
          const colores = ['#E040FB',];
          const colores2 = ['#616161',];
          const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
          const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
          return {
          primero: colorAleatorio,
          segundo: colorAleatorio2,
          tercero: colorAleatorio,
          cuarto: colorAleatorio
          };
      }
    },
    puedeEditarCalendar() {
        const perfilAuth = this.perfilAuth;

        if (perfilAuth) {
            return true;
        } else {
            const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "editarYborrarCalendario" && acceso.value);
            return Boolean(accesoAgregar);
        }
    },
  },
  methods: {
    ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
    handleEventClick(arg) {
      this.datosx = arg.event.title
      
      this.selectedEvent = {
        description: arg.event.description,
        cliente: arg.event.cliente,
        title: arg.event.title,
        start: arg.event.start,
        end: arg.event.end,
      }

      this.editedIndex = this.calendarOptions.events.findIndex(x => x.title === this.datosx);
      this.editedItem = this.calendarOptions.events[this.editedIndex]
      this.dialog = true;
    },
    handleSelect(arg) {
      console.log(arg)
      this.selectedEvent = {};
      this.clienteDB = {};
      this.editedItem = {};
      this.editedIndex = -1;
      this.consultarClientes()
      this.selectedEvent = arg;
      this.dialog = true
        // this.$store.commit("ADD_EVENT", {
        //     id: (new Date()).getTime(),
        //     title: "Something",
        //     start: arg.start,
        //     end: arg.end,
        //     allDay: arg.allDay
        // })
    },
    // handleDateClick: function(arg) {
    //   alert('date click! ' + arg.dateStr)
    // },
    toggleWeekends: function() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
    },
    async consultarClientes () {
      let userNameParametro = this.$route.params.userName.toLowerCase()

      try {
          let userNameDoc = await db.collection('userNames')
                              .doc(userNameParametro)
                              .get()

          if(userNameDoc.exists) {
              let userName = userNameDoc.data()

              let resultado = await db.collection('usuarios')
                                      .doc(userName.uid)
                                      .collection('clientes')
                                      .get()                
              
              resultado.docs.forEach(doc => {
                  
                  let clidb = doc.data()                   
                  this.clientesdb.push(clidb)    
              })
          }
      }
      catch (error) {                
          this.mostrarError('Hay un problema con el internet favor de intentar más tarde.')              
      }
    },
    async saveEv () {
      let userNameParametro = this.$route.params.userName.toLowerCase()

      let userNameDoc = await db.collection('userNames')
                              .doc(userNameParametro)
                              .get()

      if(userNameDoc.exists) {
        var userName = userNameDoc.data()

        // if (this.editedIndexEv > -1) {
        //     this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
        //     Object.assign(this.cliEventos[this.editedIndexEv], this.editedItemEv)

        //     try {
        //         db.collection('usuarios')
        //         .doc(userName.uid)
        //         .collection('clientes')
        //         .doc(this.clientePicked.cliid)
        //         .collection('eventos-citas')
        //         .doc(this.evClicked[0].cliEvid)
        //         .update({
        //             concepto: this.evClicked[0].concepto,
        //             fechaEvento: this.evClicked[0].fechaEvento,
        //             duration: this.evClicked[0].duration,
        //             time: this.evClicked[0].fechaEvento.substr(-5,5),
        //             activInit: this.evClicked[0].activInit,
        //             activEnd: this.evClicked[0].activEnd,
        //         })
        //         this.mostrarExito('Se actualizó la información exitosamente.')                
        //     }   
        //     finally {
        //         this.ocultarOcupado()
        //     }
        // } else {

          let cliEvid = uuidv4()
          let clievidCorto = cliEvid.slice(0, 5)

          let clienteEvObj = {
            cliEvid,
            title: clievidCorto + this.selectedEvent.cliente.cliente,
            // inicioFecha: new Date(this.selectedEvent.startStr).toLocaleDateString(),
            // inicioHora: new Date(this.selectedEvent.startStr).toLocaleTimeString(),
            // finFecha: new Date(this.selectedEvent.endStr).toLocaleDateString(),
            // finHora: new Date(this.selectedEvent.endStr).toLocaleTimeString(),
            start: this.selectedEvent.startStr.toString(),
            end: this.selectedEvent.endStr.toString(),
            startStr: this.selectedEvent.startStr,
            // date: this.selectedEvent.startStr,
            endStr: this.selectedEvent.endStr,
            description: this.selectedEvent.description,
            fechaEvento: this.selectedEvent.startStr,
            time: new Date(this.selectedEvent.startStr).toLocaleTimeString(),
            cliente: this.selectedEvent.cliente.cliente,
            cliid: this.selectedEvent.cliente.cliid,
            autor: this.usuario.userName,                  
            userNameHost: this.usuarioHost.userName,
            open: false,
            activInit: '',
            activEnd: '',
            imgBefore: '',
            imgAfter: '',
            
            // duration: 60,
             
          }

          try {
            await db.collection('usuarios') 
                    .doc(userName.uid)
                    .collection('clientes')
                    .doc(clienteEvObj.cliid)
                    .collection('eventos-citas')
                    .doc(clienteEvObj.cliEvid)
                    .set(clienteEvObj)

            // this.cliEventos.push(this.editedItemEv)
            this.mostrarExito('Se cargó la información exitosamente.')
          }
          finally {                    
              this.ocultarOcupado()
          }
        // }
        this.consulEventos()
      } else {
          this.mostrarError('Hay un problema con la red, favor de intentar más tarde.')
      }
      this.dialog = false
    },
    async consulEventos () {
      this.calendarOptions.events = []
      let userNameParametro = this.$route.params.userName.toLowerCase()

      try {
        let userNameDoc = await db.collection('userNames')
                            .doc(userNameParametro)
                            .get()

        if(userNameDoc.exists) {
            let userName = userNameDoc.data()

            let resultado = await db.collectionGroup('eventos-citas')
                                    .where('userNameHost', '==', userName.userName)
                                    .get()                
            
            await resultado.forEach(doc => {
                
                let evdb = doc.data()                   
                this.calendarOptions.events.push(evdb)    
            })
            
        }
        // this.eventsMapF()
      }
      catch (error) {                
        this.mostrarError('Hay un problema con el internet, favor de intentar más tarde.')              
      }
    },
    async deleteEv (itemEv) {
      let userNameParametro = this.$route.params.userName.toLowerCase()

      try {
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
          let userName = userNameDoc.data()

          await db.collection('usuarios')
                  .doc(userName.uid)
                  .collection('clientes')
                  .doc(itemEv.cliid)
                  .collection('eventos-citas')
                  .doc(itemEv.cliEvid)
                  .delete()                

          this.mostrarExito('Se eliminó el elemento exitosamente.')
          this.dialog = false
          this.editedItem = {}
        }
      }
      finally {
          this.ocultarOcupado()
      }
      this.consulEventos()
    },
  },
}
</script>
