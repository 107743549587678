<template>        
    <div class="text-xs-center">
        <v-subheader>Legal</v-subheader>
        <avisoDePrivacidad/>
        <terminosYcondiciones/>
    </div>          
</template>

<script>
import avisoDePrivacidad from '@/components/avisoDePrivacidad'
import terminosYcondiciones from '@/components/terminosYcondiciones'

export default {
    components: { avisoDePrivacidad, terminosYcondiciones },
}
</script>