<template>
    <v-card >                
        <v-layout justify-center>
            <v-list three-line>
                <!-- <template > -->
                    <v-list-tile                        
                        v-for="item in datosDB" :key="item.did"
                        avatar
                        >
                        <v-list-tile-avatar icon>
                            <v-avatar center>
                                <v-icon                                    
                                    color="primary"
                                    v-if="item.tipoDato == 'horario en establecimiento'"
                                    class="pr-2 mr-1"
                                    >
                                    mdi-store
                                </v-icon>
                                <v-icon                                    
                                    color="primary"
                                    v-if="item.tipoDato == 'horario en línea'"
                                    class="pr-2 mr-1"
                                    >
                                    mdi-devices
                                </v-icon>
                                <v-icon                                    
                                    color="primary"
                                    v-if="item.tipoDato == 'nosotros'"
                                    class="pr-2 mr-1"
                                    >
                                    mdi-card-text
                                </v-icon>
                                <v-icon                                    
                                    color="primary"
                                    v-else
                                    >
                                    mdi-{{item.tipoDato}}
                                </v-icon>
                            </v-avatar>
                        </v-list-tile-avatar>

                        <v-list-tile>                                    
                            <v-list-tile-content>
                                <!-- <v-list-tile-title v-html="item.tipoDato"></v-list-tile-title> -->
                                <v-list-tile-sub-title v-if="item.tipoDato != 'map-marker' && item.tipoDato != 'nosotros' && item.tipoDato != 'horario en establecimiento' && item.tipoDato != 'horario en línea'" class="ml-3">{{ item.dato }}</v-list-tile-sub-title>
                                <v-list-tile-sub-title v-if="item.tipoDato == 'map-marker'" class="ml-3"><a :href="item.datoMap">{{ item.dato }}</a></v-list-tile-sub-title>
                                
                                <template v-if="item.tipoDato == 'nosotros'">
                                    <v-layout row justify-center>
                                        <v-dialog v-model="dialogNosotros" scrollable max-width="300px">
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary" class="caption text-lowercase mt-4 ml-4" outline small dark v-on="on">nosotros</v-btn>
                                        </template>
                                        <v-card>
                                            <v-divider></v-divider>
                                            <v-card-text style="height: 300px;">
                                                {{ item.dato }}
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-btn color="" flat @click="dialogNosotros = false">Cerrar</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-layout>
                                </template>
                                <template v-if="item.tipoDato == 'horario en establecimiento'">
                                    <v-layout row justify-center>
                                        <v-dialog v-model="dialogHorarioEstablecimiento" scrollable max-width="300px">
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary" class="caption text-lowercase mt-4 ml-3" outline small dark v-on="on">horario en establecimiento</v-btn>
                                        </template>
                                        <v-card>
                                            <v-divider></v-divider>
                                            <v-card-text style="height: 300px;">
                                                {{ item.dato }}
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-btn color="" flat @click="dialogHorarioEstablecimiento = false">Cerrar</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-layout>
                                </template>
                                <template v-if="item.tipoDato == 'horario en línea'">
                                    <v-layout row justify-center>
                                        <v-dialog v-model="dialogHorarioLinea" scrollable max-width="300px">
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary" class="caption text-lowercase mt-4 ml-3" outline small dark v-on="on">horario en línea</v-btn>
                                        </template>
                                        <v-card>
                                            <v-divider></v-divider>
                                            <v-card-text style="height: 300px;">
                                                {{ item.dato }}
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-btn color="" flat @click="dialogHorarioLinea = false">Cerrar</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-layout>
                                </template>
                                
                            </v-list-tile-content>
                        </v-list-tile>                            
                    </v-list-tile>
                    <!-- <v-divider
                    :key="index"
                    :inset="item.inset"
                    ></v-divider>
                    -->
                <!-- </template> -->
            </v-list>
        </v-layout>
        <template v-if="datosDB.length < 1">
            <v-alert :value="true" color="transparent" icon="warning" dark class="black--text">
                Todavía no hay datos públicos.
            </v-alert>
        </template>
    </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { db } from '@/firebase'
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    // props: ['usuarioRecibido'],
    
    data() {
        return {
            dialogNosotros: false,
            dialogHorarioLinea: false,
            dialogHorarioEstablecimiento: false,
            // usuarioHost: '',
            datosDB: [],
        }
    },
    created() {
        // if (navigator.onLine) {
            this.consultarDatosDB()
        // }  
        // if (!navigator.onLine) {
        //     this.consultarDatosLB()
        // }
    },
    computed: {
        ...mapState('usuarioHost', ['usuarioHost']),
        // ...mapState('usuarioHost', ['datosDB']),
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarOcupado', 'mostrarError', 'ocultarOcupado']),
        async consultarDatosLB() {
            // this.datosDB = []
            await dbL.collection('datosDB').get().then( dts => {
                this.datosDB = dts
            })
            this.ocultarOcupado()
        },
        async consultarDatosDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()
            let userNameDoc = await db.collection('userNames')
                            .doc(userNameParametro)
                            .get()

            if(userNameDoc.exists) {
                let userHost = userNameDoc.data()
                this.datosDB = []
                
                try {
                    dbL.collection('datosDB').set([])
                    let resultado = await db.collection('usuarios')
                                            .doc(userHost.uid)
                                            .collection('datosDB')
                                            .get()                
                        
                    await resultado.docs.forEach(doc => {
                        
                        let datadb = doc.data()
                        // if  (this.grupodb.uidG == this..uid) {                     
                        this.datosDB.push(datadb)
                        dbL.collection('datosDB').add(datadb)
                        // }
                    })
                }
                catch (error) {                
                    this.mostrarError('Ocurrió un error, puede haber problemas con el internet.')                
                }
            }
            this.ocultarOcupado()
            this.consultarDatosLB()  
        },        
    }
}
</script>