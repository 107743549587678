<template>
  <v-layout>
    <v-flex xs12 sm6 offset-sm3>
      <v-card class="mt-5">
        <!-- <v-img
          src="https://cdn.vuetifyjs.com/images/cards/desert.jpg"
          aspect-ratio="2.75"
        ></v-img> -->
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0"><strong class="logoFont">nu<strong class="principal--text">Vin</strong></strong>, tu área de trabajo Virtual</h3>
            <!-- <div> {{ card_text }} </div> -->
          </div>
        </v-card-title>
        <v-card-actions>          
          <!-- <v-btn :to="{ name: 'registro'}">Registro</v-btn> -->
          <v-btn flat color="principal" @click="irHomeUser">Iniciar</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  // import { db } from '@/firebase'
  // import { v4 as uuidv4 } from 'uuid';
  
  export default {
    data () {
      return {
        card_text: 'Ya tienes tu nuVin!',
        colaboradores: []
      }
    },
    computed: {
    ...mapState('sesion', ['usuario']),
    },
    created() {
      this.irHomeUser()
    },
    methods: {
      ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      irHomeUser () {
        
        this.$router.push({ path: `/${this.usuario.userName}`})
        // let userIsCol = this.colaboradores.some(col => col.userName == this.usuario.userName)

        // if (userIsCol == false) {
        
          // this.mostrarExito('...entra a tu OFICINA para configurar la información para tus clientes.')

        // } 
        // else if (userIsCol == true) {
          
        //   return

        // }
        
      },      
      // async consultarColaboradores() {

      //     this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })

      //     try {

      //         let colaboradorDoc = await db.collection('usuarios')
      //                                 .doc(.uid)
      //                                 .collection('colaboradores')
      //                                 // .where("uid", '==', .uid)
      //                                 .get()                

      //             colaboradorDoc.docs.forEach(doc => {                    
      //                 let colab = doc.data()

      //                 this.colaboradores.push(colab)

      //             })

      //         // if(this.colaborador == '' && .uid != auth.currentUser.uid) {

      //         //     this.$router.push({ name: '404' })
                  
      //         // }
      //     }
      //     catch (error) {
      //         this.$router.push({ name: '404' })
      //     }
      //     finally {
      //         this.ocultarOcupado()
      //     }
      //     this.obteniendoLosPermisos()
      // },
      // obteniendoLosPermisos() {

      //   let userIsCol = this.colaboradores.some(col => col.userName == this.usuario.userName)

      //   if (userIsCol == true) {
          
      //     return

      //   } else if (userIsCol == false) {
          
      //     if (this.usuario.app == 'restaurante') {

      //       let batch = db.batch()
      //       let colid = uuidv4()
            
      //       let colr = {
      //           colid,
      //           SistemaApp: 'Restaurante',
      //           userName: this.usuario.userName,
      //           userNameHost: this.usuario.userName,
      //           uid: .uid,          
      //           uidHost: .uid,
      //           switchCols: true,
      //           switchComandas: true,
      //           switchHist: true,
      //           switchAgProd: true,
      //           switchCocina: true,
      //           switchConfig: true,
      //           switchCtrlAutoPed: true,
      //           switchBorrar: true
      //       }

      //       batch.set(db.collection('usuarios').doc(.uid)
      //                   .collection('colaboradores').doc(colr.colid), colr)

      //       this.dialog = false                                          

      //       try {    
      //           batch.commit()          
      //           this.mostrarExito('Tienes todos los permisos para administrar esta cuenta...')
      //       }   
      //       catch (error) {
      //           this.mostrarError('Ocurrió un error registrando los permisos para esta Cuenta')
      //       }
            
      //     } else if (this.usuario.app == 'plaza') {
            
      //       let batch = db.batch()
      //       let colid = uuidv4()
            
      //       let colr = {
      //           colid,
      //           SistemaApp: 'Plaza',
      //           userName: this.usuario.userName,
      //           userNameHost: this.usuario.userName,
      //           uid: .uid,          
      //           uidHost: .uid,
      //           switchCols: true,
      //           switchMiembros: true,
      //           switchHist: true,
      //           switchConfig: true,
      //           switchBorrar: true
      //       }

      //       batch.set(db.collection('usuarios').doc(.uid)
      //                   .collection('colaboradores').doc(colr.colid), colr)

      //       this.dialog = false                                          

      //       try {    
      //           batch.commit()          
      //           this.mostrarExito('Tienes todos los permisos para administrar esta cuenta...')
      //       }   
      //       catch (error) {
      //           this.mostrarError('Ocurrió un error registrando los permisos para esta Cuenta')
      //       }

      //     } else if (this.usuario.app == 'despachocp') {
            
      //       let batch = db.batch()
      //       let colid = uuidv4()
            
      //       let colr = {
      //           colid,
      //           SistemaApp: 'Despacho-CP',
      //           userName: this.usuario.userName,
      //           userNameHost: this.usuario.userName,
      //           uid: .uid,          
      //           uidHost: .uid,
      //           switchCols: true,
      //           switchExped: true,
      //           switchHist: true,
      //           switchConfig: true,
      //           switchBorrar: true
      //       }

      //       batch.set(db.collection('usuarios').doc(.uid)
      //                   .collection('colaboradores').doc(colr.colid), colr)

      //       this.dialog = false                                          

      //       try {    
      //           batch.commit()          
      //           this.mostrarExito('Tienes todos los permisos para administrar esta cuenta...')
      //       }   
      //       catch (error) {
      //           this.mostrarError('Ocurrió un error registrando los permisos para esta Cuenta')
      //       }

      //     } else if (this.usuario.app == 'servpro') {
            
      //       let batch = db.batch()
      //       let colid = uuidv4()
            
      //       let colr = {
      //           colid,
      //           SistemaApp: 'ServPro',
      //           userName: this.usuario.userName,
      //           userNameHost: this.usuario.userName,
      //           uid: .uid,          
      //           uidHost: .uid,
      //           switchServcs: true,
      //           switchCols: true,
      //           switchCotiz: true,
      //           switchHist: true,
      //           switchConfig: true,
      //           switchBorrar: true
      //       }

      //       batch.set(db.collection('usuarios').doc(.uid)
      //                   .collection('colaboradores').doc(colr.colid), colr)

      //       this.dialog = false                                          

      //       try {    
      //           batch.commit()          
      //           this.mostrarExito('Tienes todos los permisos para administrar esta cuenta...')
      //       }   
      //       catch (error) {
      //           this.mostrarError('Ocurrió un error registrando los permisos para esta Cuenta')
      //       }

      //     } else if (this.usuario.app == 'comercio') {
            
      //       let batch = db.batch()
      //       let colid = uuidv4()
            
      //       let colr = {
      //           colid,
      //           SistemaApp: 'Comercio',
      //           userName: this.usuario.userName,
      //           userNameHost: this.usuario.userName,
      //           switchCols: true,
      //           switchCotiz: true,
      //           switchHist: true,
      //           switchConfig: true,
      //           switchBorrar: true,
      //           switchCatalogo: true,
      //           switchAgProd: true,
      //           switchComandas: true,
      //           switchCtrlAutoPed: true,
      //       }

      //       batch.set(db.collection('usuarios').doc(.uid)
      //                   .collection('colaboradores').doc(colr.colid), colr)

      //       this.dialog = false                                          

      //       try {    
      //           batch.commit()          
      //           this.mostrarExito('Tienes todos los permisos para administrar esta cuenta...')
      //       }   
      //       catch (error) {
      //           this.mostrarError('Ocurrió un error registrando los permisos para esta Cuenta')
      //       }
      //     } else if (this.usuario.app == 'orgevent') {
            
      //       let batch = db.batch()
      //       let colid = uuidv4()
            
      //       let colr = {
      //           colid,
      //           SistemaApp: 'OrgEvent',
      //           userName: this.usuario.userName,
      //           userNameHost: this.usuario.userName,
      //           uid: .uid,          
      //           uidHost: .uid,
      //           switchCols: true,
      //           switchCotiz: true,
      //           switchHist: true,
      //           switchConfig: true,
      //           switchBorrar: true
      //       }

      //       batch.set(db.collection('usuarios').doc(.uid)
      //                   .collection('colaboradores').doc(colr.colid), colr)

      //       this.dialog = false                                          

      //       try {    
      //           batch.commit()          
      //           this.mostrarExito('Tienes todos los permisos para administrar esta cuenta...')
      //       }   
      //       catch (error) {
      //           this.mostrarError('Ocurrió un error registrando los permisos para esta Cuenta')
      //       }

      //     } else if (this.usuario.app == 'personal') {
            
      //       let batch = db.batch()
      //       let colid = uuidv4()
            
      //       let colr = {
      //           colid,
      //           SistemaApp: 'OrgEvent',
      //           userName: this.usuario.userName,
      //           userNameHost: this.usuario.userName,
      //           uid: .uid,          
      //           uidHost: .uid,
      //           switchCols: true,
      //           switchHist: true,
      //           switchConfig: true
      //       }

      //       batch.set(db.collection('usuarios').doc(.uid)
      //                   .collection('colaboradores').doc(colr.colid), colr)

      //       this.dialog = false                                          

      //       try {    
      //           batch.commit()          
      //           this.mostrarExito('Tienes todos los permisos para administrar esta nuVin...')
      //       }   
      //       catch (error) {
      //           this.mostrarError('Ocurrió un error registrando los permisos para esta nuVin')
      //       }

      //     }
      //   }            
      // },
    },
  }
</script>