<template>
    <v-card>                                            
        <v-layout wrap justify-center align-start>
            <v-flex xs12>
                <v-btn icon  dark>                
                    <a @click="$router.go(-1)"><v-icon small dark left color="black">arrow_back</v-icon></a>
                </v-btn>
                <span class="body-2">edición de productos</span>
                <v-layout justify-center>
                    <v-card max-width="350">
                        <v-toolbar color="white" card>
                            <v-toolbar-title>
                            <span v-if="vista == 1">Foto Actual</span> 
                            <span v-if="vista == 2">Vista Previa</span>
                            </v-toolbar-title>        
                        </v-toolbar>
                        <v-card-text>
                            <v-img v-if="vista == 1" :src="fotoProducto" alt="Foto de Producto"></v-img>
                            <div v-if="vista == 2" ref="vistaPrevia" class="vistaPrevia"></div>
                        </v-card-text>
                        <v-card-text>
                            <v-layout v-if="vista == 1" justify-center>
                                <!-- <v-btn color="secondary" v-if="usuario" :to="{ name: 'edicion-mini-catalogo', params: { userName: usuario.userName } }">Regresar</v-btn> -->
                                <!-- <v-flex xs6 sm4 lg3 v-if="this.fotoProducto != ''">                                   
                                    <v-layout class="mt-3">
                                        <v-flex xs6>
                                            <v-layout justify-end>
                                            <v-icon medium @click="preguntarEliminarImagen(foto)">delete</v-icon>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                    <v-dialog v-model="preguntaEliminar" max-width="400" persistent>
                                        <v-card>
                                            <v-card-text class="title">
                                            La imagen se eliminará permanentemente, ¿Deseas Continuar?
                                            </v-card-text>
                                            <v-btn color="secondary" flat @click="preguntarEliminar = false">Cancelar</v-btn>
                                            <v-btn flat @click="eliminarImagen">Eliminar</v-btn>
                                        </v-card>
                                    </v-dialog>
                                </v-flex> -->
                            </v-layout>
                            <v-layout v-if="vista == 2" justify-center>
                                <v-flex xs6>
                                    <v-btn v-if="usuario" :to="{ name: 'edicion-mini-catalogo', params: { userName: usuario.userName } }">Cancelar</v-btn>
                                </v-flex>
                                <v-flex xs6>
                                    <v-btn @click="guardarFotoProducto" color="secondary">Guardar</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-layout>
            </v-flex>
            <v-flex xs12 sm4 mt-3>
                <v-card class="mx-2">
                    <v-tabs color="white" icons-and-text centered>
                    <v-tab>
                        Agregar o Cambiar Imagen
                        <v-icon>cloud_upload</v-icon>
                    </v-tab>
                    <v-tab-item >
                        <file-pond v-if="vista == 1"  @addfile="cargarImagen" instant-upload="false" 
                                fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}" 
                                labelFileTypeNotAllowed="Agrega una imagen .png o .jpg" 
                                accepted-file-types="image/jpeg, image/png" 
                                label-idle="Selecciona o arrastra una imagen...">
                        </file-pond>
                        <v-responsive v-if="vista == 2">
                            <img ref="imagenOriginal" src="" alt="" class="edicionImagen" >
                        </v-responsive>
                    </v-tab-item>
                    <!-- <v-tab v-if="fotosProducto.length > 1">
                        Galería
                        <v-icon>photo_library</v-icon>
                    </v-tab>
                    <v-tab-item v-if="fotosProducto.length > 1">
                        <v-layout wrap justify-start>
                        <v-flex xs6 sm4 lg3 v-for="foto in fotosProducto" :key="foto.fotoId">
                            <v-card class="ma-3">
                            <v-card-text>
                                <v-img :src="foto.url" alt="Foto Producto"></v-img>
                                <v-layout class="mt-3">
                                <v-flex xs6>
                                    <v-layout justify-start>
                                    <v-icon v-if="foto.url == fotoProducto" color="green" medium>check_circle</v-icon>
                                    <v-icon v-else color="grey" @click="seleccionarFotoProducto(foto.url)" medium>check_circle_outline</v-icon>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs6>
                                    <v-layout justify-end>
                                    <v-icon v-if="foto.url != fotoProducto" medium @click="preguntarEliminarImagen(foto)">delete</v-icon>
                                    </v-layout>
                                </v-flex>
                                </v-layout>
                            </v-card-text>
                            </v-card>
                        </v-flex>
                        </v-layout>
                        <v-dialog v-model="preguntaEliminar" max-width="400" persistent>
                        <v-card>
                            <v-card-text class="title">
                            La imagen se eliminará permanentemente, ¿Deseas Continuar?
                            </v-card-text>
                            <v-btn color="secondary" flat @click="preguntarEliminar = false">Cancelar</v-btn>
                            <v-btn flat @click="eliminarImagen">Eliminar</v-btn>
                        </v-card>
                        </v-dialog>
                    </v-tab-item> -->
                    </v-tabs>
                </v-card>
            </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat 
                @click="guardarFotoProducto(props.item)">Guardar
            </v-btn>
        </v-card-actions> -->
    </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { storage, db, functions, auth } from '@/firebase'

import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond/dist/filepond.min.css"

import Cropper from 'cropperjs/dist/cropper'
import 'cropperjs/dist/cropper.css'

import { v4 as uuidv4 } from 'uuid';

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
    components: { FilePond },
    data: () => ({
      cropper: null,
      vista: 1,
      prodCatalogo: '',
      fotoProducto: '',
      preguntaEliminar: false,
      fotoEliminar: null,
    }),
    watch: {
        '$route' () {
            this.consultarFotosProducto()
        }
    },
    created() {
        this.consultarFotosProducto()
    },
    computed: {
      ...mapState('sesion', ['usuario']),
      cidParam() {
          return this.$route.params.pid
      },
    //   ...mapState('sesion', ['catalogosDB']),
    //   ...mapGetters('sesion', ['fotoProducto']),
    },
    methods: {
        ...mapMutations(['mostrarError', 'mostrarError', 'mostrarExito', 'mostrarOcupado', 'ocultarOcupado']),  
        // ...mapMutations('sesion', ['actualizarFotoProducto']),
        async consultarFotosProducto() {
            let userNameParametro = this.$route.params.userName.toLowerCase()
            this.mostrarOcupado({ titulo: 'Consultando Galería', mensaje: 'Recuperando galería de imágenes...' })

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('catalogosDB')
                                            .doc(this.cidParam)
                                            // .collection('fotos-producto')
                                            // .orderBy('fecha', 'desc')
                                            .get()

                    if(resultado.exists) {
                        this.prodCatalogo = resultado.data()

                        if (this.prodCatalogo.fotoProducto) {
                            
                            this.fotoProducto = this.prodCatalogo.fotoProducto
                        }
                        else {
                            this.fotoProducto = require(`@/assets/fotoUsuario.png`)
                        }
                    }
                    else {
                        this.$router.push({ name: '404' })
                    }
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error recuperando tu galería de fotos.')
            }
            finally {
                this.ocultarOcupado()
            }
        },
        cargarImagen (error, archivo) {
            if (error) {
                this.mostrarError('Ocurrió un error leyendo la imagen.')
                return  
            }

            this.vista = 2

            let reader = new FileReader()

            let vm = this

            reader.onloadend = () => {
                vm.$refs.imagenOriginal.src = reader.result
                vm.recortarImagen()
            }

            if (archivo) {
                if (archivo.file) {
                    reader.readAsDataURL(archivo.file)
                }
            }
        },
        recortarImagen() {
            this.cropper = new Cropper(this.$refs.imagenOriginal, {
                preview: this.$refs.vistaPrevia,
                aspectRatio: 1,
                modal: false,
                guides: true,
                sized: false,
                zoomable: false,
                highlight: true,
                background: false,
                autoCrop: true
            })
        },
        async guardarFotoProducto() {
            this.mostrarOcupado({ titulo: 'Almacenando Foto', mensaje: 'Actualizando foto de producto...' })
            
            let userNameParametro = this.$route.params.userName.toLowerCase()
            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                let canvas = this.cropper.getCroppedCanvas()
                let imagen = canvas.toDataURL('image/jpeg')
                let fotoId = uuidv4()

                try {
                    let ref = storage.ref()

                    let resultado = await ref.child(`usuarios/${userName.uid}/catalogosDB/${this.cidParam}/fotos-producto/${fotoId}.jpg`)
                                            .putString(imagen, 'data_url')

                    let generarMiniaturas = functions.httpsCallable('generarMiniaturas')
                    let idToken = await auth.currentUser.getIdToken(true)
                    let url = await resultado.ref.getDownloadURL()

                    if (await generarMiniaturas({ idToken, fotoId })) {

                        // let fotoProducto = {
                        // fotoId,
                        // fecha: new Date(),
                        // url, 
                        // pid: this.cidParam
                        // }

                        // await db.collection('usuarios')
                        //         .doc(.uid)
                        //         .collection('catalogosDB')
                        //         .doc(this.cidParam)
                        //         .collection('fotos-producto')
                        //         .doc(fotoId)
                        //         .set(fotoProducto)

                        // this.catalogodb = Object.assign({}, item)
                        // Object.assign(this.catalogosDB[item], fotoProducto)

                        await db.collection('usuarios')
                                .doc(userName.uid)
                                .collection('catalogosDB')
                                .doc(this.cidParam)
                                .update({ fotoProducto: url })

                        // this.actualizarFotoProducto({
                        //     url, 
                        //     pid: this.cidParam
                        // })

                        this.$router.push({ name: 'edicion-mini-catalogo', params: { userName: this.usuario.userName } })
                        this.mostrarExito('Se agregó la imagen del producto exitosamente')
                        
                    } else {
                        this.mostrarError('Ocurrió un error almacenando la imagen.')
                    }
                }
                catch (error) {
                    this.mostrarError('Ocurrió un error almacenando la foto.')
                }
                finally {
                    this.ocultarOcupado()
                }
            }                                                                                                                                                                                                                       
        },       
        async seleccionarFotoProducto(url) {
            this.mostrarOcupado({ titulo: 'Actualizando Imagen', mensaje: 'Estableciendo foto de producto...' })

            let userNameParametro = this.$route.params.userName.toLowerCase()
            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                try {
                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('catalogosDB')
                            .doc(this.cidParam)
                            .update({ fotoProducto: url })

                    // this.actualizarFotoProducto({
                    //     url, 
                    //     pid: this.cidParam
                    // })
                }
                catch (error) {
                    this.mostrarError('Ocurrió un error seleccionando la imagen.')
                }
                finally {
                    this.ocultarOcupado()
                }
            }
        },
        preguntarEliminarImagen (foto) {
            this.fotoEliminar = foto
            this.preguntaEliminar = true
        },
        async eliminarImagen () {
            this.preguntaEliminar = false

            this.mostrarOcupado({ titulo: 'Eliminando Imagen', mensaje: 'Se está eliminando tu imagen...' })

            let userNameParametro = this.$route.params.userName.toLowerCase()
            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                try {
                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('catalogosDB')
                            .doc(this.cidParam)
                            .collection('fotos-producto')
                            .doc(this.fotoEliminar.fotoId)
                            .delete()

                    await storage.ref().child(`usuarios/${userName.uid}/catalogosDB/${this.cidParam}/fotos-producto/${this.fotoEliminar.fotoId}.jpg`)
                                    .delete()

                    // console.log(`usuarios/${.uid}/fotos-portada/${this.fotoEliminar.fotoId}.jpg`)

                    let index = this.fotosProducto.indexOf(this.fotoEliminar)
                    this.fotosProducto.splice(index, 1)
                }
                catch (error) {        
                    this.mostrarError('Ocurrió un error eliminando la imagen.')
                }
                finally {
                    this.ocultarOcupado()
                }
            }
        },
         
    }
}
</script>

<style>

  .filepond--drop-label {
    font-size: 14pt !important;
  }

  .edicionImagen {
    width: 100%;
  }

  .vistaPrevia {
    width: 288px;
    height: 180px;
    overflow: hidden;
  }

</style>