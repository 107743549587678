<template>
  <div>
    <v-flex xs12 ml-3 mt-2>
        <v-btn icon dark>                
            <a @click="$router.go(-1)"><v-icon small dark left color="black">arrow_back</v-icon></a>
        </v-btn>
        <span class="body-2">oficina virtual</span>
    </v-flex>
    <v-toolbar flat color="white">
      <v-toolbar-title>Clientes</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <!-- <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" small dark class="mb-2 caption" v-on="on">Agregar</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.name" label="Dessert name"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.calories" label="Calories"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.fat" label="Fat (g)"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.carbs" label="Carbs (g)"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.protein" label="Protein (g)"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="clientes"
      class="elevation-1"
    >
      <template v-slot:items="props">
        <td>{{ props.item.nombre }}</td>
        <td class="text-xs-right">{{ props.item.estatusCliente }}</td>
        <td class="text-xs-right">{{ props.item.tipoServicio }}</td>
        <td class="justify-center layout px-0 pt-3">
          <v-icon
            small
            class="mr-2"
            @click="editItem(props.item)"
          >
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteItem(props.item)"
          >
            delete
          </v-icon>
        </td>
        <td class="text-xs-right">{{ props.item.direccion }}</td>
        <td class="text-xs-right">{{ props.item.ciudad }}</td>
        <td class="text-xs-right">{{ props.item.estadopais }}</td>
        <td class="text-xs-right">{{ props.item.telefono }}</td>
        <td class="text-xs-right">{{ props.item.email }}</td>
        <td class="text-xs-right">{{ props.item.radiosBodaTipo }}</td>
        <td class="text-xs-right">{{ props.item.radiosBodaDespEv }}</td>
        <td class="text-xs-right">{{ props.item.despotro }}</td>
        <td class="text-xs-right">{{ props.item.numinvitados }}</td>
        <td class="text-xs-right">{{ props.item.presupuesto }}</td>
        <td class="text-xs-right">{{ props.item.date }}</td>
        <td class="text-xs-right">{{ props.item.lugar }}</td>
        <td class="text-xs-right">{{ props.item.cenadetalles }}</td>
        <td class="text-xs-right">{{ props.item.cmentarios }}</td>
        
      </template>
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '@/firebase'
import { mapMutations, mapState } from 'vuex'

  export default {
    data: () => ({
      dialog: false,
      headers: [
        {
          text: 'Cliente',
          align: 'left',
          sortable: false,
          value: 'nombre'
        },
        { text: 'Estatus', value: 'estatusCliente' },
        { text: 'Tipo Servicio', value: 'tipoServicio' },
        { text: 'Actions', value: 'nombre', sortable: false },
        { text: 'Dirección', value: 'direccion' },        
        { text: 'Ciudad', value: 'ciudad' },
        { text: 'Estado/País', value: 'estadopais' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Email', value: 'email' },
        { text: 'Tipo Boda', value: 'radiosBodaTipo' },
        { text: 'Desp. del Evento', value: 'radiosBodaDespEv' },
        { text: 'Cliente-Sugerencia', value: 'despotro' },
        { text: '# invitados', value: 'numinvitados' },
        { text: 'Presupuesto', value: 'presupuesto' },
        { text: 'Fecha', value: 'date' },
        { text: 'Lugar', value: 'lugar' },
        { text: 'Detalles', value: 'cenadetalles' },
        { text: 'Comentarios', value: 'comentarios' }
      ],
      clientes: [],
      editedIndex: -1,
      clientedb: {
                nombre: 'Nombres y Apellidos',
                direccion: 'Calle o fraccionamiento, #',
                ciudad: '',
                estadopais: '',
                telefono: '',
                email: '',
                radiosBodaTipo: '',
                radiosBodaDespEv: '',
                despotro: '',
                numinvitados: '',
                presupuesto: '',                
                date: new Date().toISOString().substr(0, 7),
                lugar: '',
                cenadetalles: '',
                comentarios: '',
            },
            defaultItem: {
                nombre: '',
                direccion: '',
                ciudad: '',
                estadopais: '',
                telefono: '',
                email: '',
                radiosBodaTipo: '',
                radiosBodaDespEv: '',
                despotro: '',
                numinvitados: '',
                presupuesto: '',                
                date: new Date().toISOString().substr(0, 7),
                lugar: '',
                cenadetalles: '',
                comentarios: '',
            },
    }),

    computed: {
      ...mapState('sesion', ['usuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created() {
      this.consultarClientes()
    },

    methods: {
        ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),        
      async consultarClientes() {                
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                  .doc(userNameParametro)
                                  .get()

        if(userNameDoc.exists) {
                let userName = userNameDoc.data()

          try {  
                let resultado = await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('clientes')
                                        .get()                
                
                resultado.docs.forEach(doc => {
                    
                    let cli = doc.data()
                    
                    this.clientes.push(cli)    
                    
                })              
          }
          catch (error) {                
              this.mostrarError('Ocurrió un error consultando la información de Clientes.')                
          }
        }  // this.consultarPedido()
      },
      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.desserts.indexOf(item)
        confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      }
    }
  }
</script>