<template>
  <v-layout justify-center align-center wrap>
    <v-flex xs12 v-if="dialogProgress == false">
        <v-card class="elevation=5 mt-5 py-3 custom-card" >
            <v-layout>
                <v-flex xs2 class="text-xs-left ml-4">
                    <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-cash-minus</v-icon>                
                </v-flex>
                <v-flex xs8 class="text-xs-left">
                    <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Gastos</p></h3>
                    <h3 class="hidden-sm-and-up"><p class="text-xs-center display-1 mt-3">Gastos</p></h3>
                </v-flex>        
                <v-flex xs2 class="text-xs-right">
                    <v-dialog
                        v-model="dialogHelp"
                        width="500"
                    >
                        <template v-slot:activator="{ on }">
                        <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                        </template>

                        <v-card>
                        <v-card-title class="headline">
                            Cómo funciona esta sección...
                        </v-card-title>

                        <v-card-text>
                            En esta área se agregan los conceptos de Gasto que utiliza el negocio, hogar o perosna. Esta sección 
                            es útil para tener noción de los Gastos de Operación que ha realizado el negocio en le tiempo y nos ayuda a realizar
                            cálculos de rentabilidad.
                            <ul>
                                <li>En el signo de "+" o botón "Agregar" dar click, se desplega un formulario para ingresar los datos característicos
                                    de este Gasto.
                                </li>
                                <li>
                                    Asignar etiquetas te ayudará a tener una mejor perspectiva sobren en qué estás Gastando y su magnitud.
                                </li>
                                <li>
                                    La cifra total, también será reflejada en la sección de SALDOS. 
                                </li>
                            </ul> 
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs6 ml-3 mt-2>        
                    <!-- <h3><p class="text-xs-left ml-3 display-1">Gastos</p></h3> -->
                    <!-- {{colaborador.userName}} -->
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs4 class="mr-3">
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-flex>
            </v-layout>   
            <v-flex xs12>            
                <v-card class="elevation=5 ma-3 mt-3">
                    <v-toolbar flat class="mt-4" >
                        <v-layout wrap>
                            <!-- INGRESAR GASTO DATOS -->
                            <v-flex xs12 sm6 >
                                <v-dialog v-model="dialog">
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="hidden-xs-only text-none elevation-13" :style="{'background-color': paletaColores.primero}" dark mb-2 v-on="on" v-if="puedeRegGastos">Registrar Gasto</v-btn>
                                        <v-btn icon top right absolute small fab dark :style="{'background-color': paletaColores.primero}" class="hidden-sm-and-up mt-2" v-on="on" v-if="puedeRegGastos">
                                            <v-icon class="pt-2 mt-1">add</v-icon>   
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>
                                            <v-card-text>
                                                <v-container grid-list-md>
                                                <v-layout wrap >
                                                    <v-flex xs12 sm6 md4>
                                                        <v-text-field v-model="gasto.proveedor" label="Proveedor/Concepto o Descripción del Gasto" @blur="$v.gasto.proveedor.$touch()" :error-messages="erroresProveedor"></v-text-field>
                                                    </v-flex>
                                                    <v-flex xs12 sm6 md4>
                                                        <v-text-field v-model="gasto.numFactura" label="Núm. de factura (opcional)" counter="20" @blur="$v.gasto.numFactura.$touch()" :error-messages="erroresNumFactura"></v-text-field>
                                                    </v-flex>
                                                    <v-flex xs12 sm6 md4>
                                                        <v-select @blur="$v.gasto.tipoGasto.$touch()" :error-messages="erroresTipoGasto"
                                                        v-model="gasto.tipoGasto"
                                                        :items="etiquetasGasto"
                                                        label="Subgrupo de Gasto (etiqueta-Gasto)"
                                                        ></v-select>
                                                        <v-select v-if="gasto.tipoGasto == 'abonoAcrédito/préstamo' && editedIndex == -1"
                                                        v-model="gasto.etiqCyP" @blur="$v.gasto.tipoGasto.$touch()" :error-messages="erroresTipoGasto"
                                                        :items="etiquetasCreditoPrestamo"
                                                        label="Fuente del crédito o préstamo (etiqueta-crédito/préstamo)"
                                                        ></v-select>                           
                                                    </v-flex>
                                                    <v-flex xs12 sm6 md4>
                                                        <v-select @blur="$v.gasto.tipoDinero.$touch()" :error-messages="erroresTipoDinero"
                                                        v-model="gasto.tipoDinero" :items="saldoTotPorDinero"
                                                        label="Con qué se realizó el Pago (etiqueta-Dinero)"
                                                        :item-text="itemText"
                                                        ></v-select>                                        
                                                    </v-flex>
                                                    <v-flex xs12 sm6 md4>
                                                        <v-menu
                                                            ref="menu1"
                                                            v-model="menu1"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            lazy
                                                            transition="scale-transition"
                                                            offset-y
                                                            full-width
                                                            max-width="290px"
                                                            min-width="290px"
                                                            >
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                v-model="dateFormatted"
                                                                label="Fecha"
                                                                persistent-hint
                                                                prepend-icon="event"
                                                                @blur="updateFechaFactura"
                                                                v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="gasto.fechaFactura" no-title @input="menu1 = false"></v-date-picker>
                                                        </v-menu>
                                                    </v-flex>
                                                    <v-flex xs12 sm6 md4>
                                                        <v-text-field type="number" v-model="gasto.monto" label="Monto" @blur="$v.gasto.monto.$touch()" :error-messages="erroresMonto"></v-text-field>
                                                    </v-flex>
                                                        <!-- :rules="rules" -->
                                                    <v-flex xs12 sm6>
                                                        <v-text-field
                                                            v-model="gasto.notas"
                                                            counter="25"
                                                            label="Notas"
                                                            outline
                                                            @blur="$v.gasto.numFactura.$touch()" :error-messages="erroresNumFactura"
                                                        ></v-text-field>
                                                    </v-flex>
                                                </v-layout>
                                                </v-container>
                                            </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" flat @click="close">Cancelar</v-btn>
                                            <v-btn color="primary" :depressed="$v.gasto.$invalid" :disabled="$v.gasto.$invalid" flat @click="save">Guardar</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-flex>
                            <!-- ETIQUETAS DE GASTO -->
                            <v-flex class="mb-1 mr-5">
                                <v-btn class="caption text-lowercase" small :style="{'background-color': paletaColores.primero}" dark :to="{ name: 'etiquetasFinanzas', params: {userName: usuarioHost.userName}}" v-if="puedeRegEtqs">Etiquetas</v-btn>
                            </v-flex>
                            <!-- <v-layout align-end justify-end>
                                <v-flex xs12 sm6 class="ml-5 pl-5 ">
                                    <v-text-field
                                        class="mx-3 mb-4"
                                        v-model="search"
                                        flat
                                        label=""
                                        prepend-inner-icon="search"
                                        solo-inverted
                                    ></v-text-field>
                                </v-flex>
                            </v-layout> -->
                        </v-layout>
                    </v-toolbar>            
                    <v-data-table
                        :headers="headers"
                        :items="gastos"
                        :search="search"
                        class="elevation-1"
                        >
                        <template v-slot:items="props">
                            <td><strong>{{ props.item.proveedor }}</strong></td>
                            <td class="justify-center align-center layout">       
                                <!-- icono dialog de book para ver gastos-->
                                <v-dialog v-model="dialog2" persistent max-width="600px">                            
                                    <template v-slot:activator="{ on }">                                
                                        <v-icon
                                            @click="abrirGasto(props.item)"
                                            dark v-on="on"
                                            color="blue"
                                            class="mr-3"
                                            small
                                            >
                                            book
                                        </v-icon>
                                    </template>
                                    <v-card  
                                        v-if="gasto"
                                        :key= "gasto.gaid"
                                        :gastoPartidas="gasto.gastoPartidas"
                                        >
                                        <v-toolbar flat color="white">
                                            <h3>Partidas de:</h3><br>
                                            <v-toolbar-title >
                                                <h3 class="caption"> {{gasto.proveedor}} </h3>
                                                <h6 class="caption">Número de factura:</h6><h3 class="caption font-weight-medium"> {{gasto.numFactura}} </h3>
                                            </v-toolbar-title>
                                            <v-divider
                                                class="mx-2"
                                                inset
                                                vertical
                                            ></v-divider>
                                            <v-spacer></v-spacer>
                                            <v-dialog v-model="dialog3" max-width="500px" persistent>
                                                <template v-slot:activator="{ on }">
                                                        <!-- @click="agregarPedido" -->
                                                    <v-btn class="caption text-none hidden-xs-only" mb-2 :style="{'background-color': paletaColores.primero}" dark v-on="on" >Nueva Partida</v-btn>
                                                    <v-btn  class="caption text-none hidden-sm-and-up pt-2" small fab :style="{'background-color': paletaColores.primero}" outline v-on="on">
                                                        <v-icon>add</v-icon>   
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ formTitlePartidas }}</span>
                                                </v-card-title>
                                                <v-card-text >
                                                    <v-container grid-list-md>
                                                        <v-layout wrap>
                                                            <!-- <multiselect 
                                                                v-model="partida.producto" 
                                                                :options="partidaId" 
                                                                :custom-label="nameWithLang" 
                                                                placeholder="Seleccionar" 
                                                                label="name" 
                                                                track-by="nombre">                                                
                                                            </multiselect> -->
                                                            <v-flex xs12 sm6 md4>
                                                                <v-text-field v-model="partida.nombreProducto" label="Producto" @blur="$v.partida.nombreProducto.$touch()" :error-messages="erroresProducto"></v-text-field>
                                                                <v-text-field v-model="partida.unidad" label="Unidad" @blur="$v.partida.unidad.$touch()" :error-messages="erroresUnidad"></v-text-field>
                                                                <v-text-field v-model="partida.precio" label="Precio" @blur="$v.partida.precio.$touch()" :error-messages="erroresPrecio" type="number"></v-text-field>
                                                                <v-text-field v-model="partida.cantidad" label="Cantidad" class="number" @blur="$v.partida.cantidad.$touch()" :error-messages="erroresCantidad" type="number" min="1" max="100"></v-text-field>
                                                            </v-flex>                                                                  
                                                            <!-- <v-flex xs12 sm6>
                                                                <v-select
                                                                v-model="partida.cantidad"
                                                                :items="['1', '2', '3', '4', '5']"
                                                                label="Cantidad"
                                                                required
                                                                ></v-select>                                                
                                                            </v-flex>                                 -->
                                                        </v-layout>
                                                    </v-container>
                                                    <small>*Campo requerido</small>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn class="caption text-none" dark :style="{'background-color': paletaColores.primero}" flat @click="closePartida">Cerrar</v-btn>
                                                    <v-btn class="caption text-none" flat :depressed="$v.partida.$invalid" :disabled="$v.partida.$invalid" @click="savePartida">Guardar</v-btn>
                                                </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-btn outline class="hidden-sm-and-up pt-2" small fab dark color="primary" @click="dialog2 = false">
                                                <v-icon dark>close</v-icon>   
                                            </v-btn>
                                            <v-btn outline class="hidden-xs-only caption text-none" color="primary" flat @click="dialog2 = false">Cerrar</v-btn>
                                        </v-toolbar>
                                        <v-data-table 
                                            :headers="headers2"
                                            :items="gastoPartidas"
                                            class="elevation-1"                    
                                            >
                                            <template v-slot:items="props">
                                                <td><strong>{{ props.item.producto }}</strong></td>
                                                <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                <td class="text-xs-center">{{ props.item.precio }}</td>
                                                <td class="text-xs-right">{{ props.item.unidad }}</td>                                                                                        
                                                <!-- <td class="text-xs-right">{{ props.item.subtotal }}</td> -->
                                                <td class="text-xs-center number">$ {{ props.item.subtotal }}</td>
                                                <td class="text-xs-center">
                                                    <v-icon
                                                        small color="green"
                                                        class="mr-2"
                                                        @click="editItemPartida(props.item)"
                                                    >
                                                        edit
                                                    </v-icon>
                                                    <estasSeguro v-if="colaboradorBorrar == true">
                                                        <v-icon
                                                            color="red"
                                                            slot="nombrebtn"
                                                            small                                       
                                                            >
                                                            delete
                                                        </v-icon>
                                                        <div slot="elemento">
                                                            <span>esta partida?</span>
                                                        </div>
                                                        <div slot="btnaceptar">
                                                            <v-btn flat @click="deletePartida(props.item)">Ok</v-btn>
                                                        </div>
                                                    </estasSeguro>
                                                    <v-icon
                                                        disabled
                                                        small
                                                        v-if="colaboradorBorrar == false"
                                                    >
                                                        delete
                                                    </v-icon>
                                                </td>                                                          
                                            </template>
                                        </v-data-table>   
                                        <template>
                                            <v-layout row wrap>
                                                <v-flex xs12>
                                                    <v-card class="white--text text-xs-right">
                                                        <span class="subheading mr-1">Sub-Total: $ {{ subtotSum }}</span><br>
                                                        <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                                        <span class="subheading mr-1">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                    </v-card>
                                                </v-flex>            
                                                <!-- Ticket para imprimir -->
                                                <!-- <v-layout row justify-center>
                                                    <v-dialog v-model="cardTicket" scrollable max-width="330px">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn color="primary" dark v-on="on">Mostrar ticket o imprimir?</v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>Ticket de Gastos</v-card-title>
                                                        <v-card-actions>
                                                            <v-btn icon color="primary" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                                        </v-card-actions>
                                                        <v-divider></v-divider>
                                                        <v-card-text style="height: 350px;" ref="printTable">                                                    
                                                            <v-flex xs12 sm12>
                                                            <v-card>                                                            
                                                                <v-list two-line>
                                                                <template >                                                                
                                                                    <p class="text-xs-center">
                                                                        <span>{{ contTicket.empresa }}</span><br>
                                                                        <span>{{ contTicket.RFC }}</span><br>
                                                                        <span>{{ contTicket.direccion }}</span><br>
                                                                        <span>{{ contTicket.telefono }}</span><br>
                                                                        <span>{{ contTicket.notapie }}</span>
                                                                    </p>                                                                
                                                                    <v-divider></v-divider>
                                                                        <template>
                                                                        <v-data-table
                                                                            :headers="headers3"
                                                                            :items="gastoPartidas"
                                                                            class="elevation-1"                                                            
                                                                            hide-actions
                                                                        >
                                                                            <template v-slot:items="props">
                                                                            <td>{{ props.item.producto }}</td>
                                                                            <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                                            <td class="text-xs-right">{{ props.item.precio }}</td>                                                            
                                                                            </template>
                                                                        </v-data-table>
                                                                        <v-card class="text-xs-right">
                                                                            <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                                            <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                                            <span class="body-2">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                                        </v-card>
                                                                        </template>
                                                                    <v-divider></v-divider>
                                                                        <span>{{ contTicket.empresa }}</span><br>
                                                                        <span>{{ contTicket.RFC }}</span><br>
                                                                        <span>{{ contTicket.direccion }}</span><br>
                                                                        <span>{{ contTicket.telefono }}</span><br>
                                                                        <span>{{ contTicket.notapie }}</span>
                                                                    </template>
                                                                </v-list>
                                                            </v-card>
                                                            </v-flex>                                                    
                                                        </v-card-text>
                                                        <v-divider></v-divider>                                                
                                                    </v-card>
                                                    </v-dialog>
                                                </v-layout>                                     -->
                                            </v-layout>
                                        </template>                    
                                    </v-card>      
                                </v-dialog>
                                <v-icon @click="editItem(props.item)" small color="green">
                                    edit
                                </v-icon>
                                <estasSeguro v-if="colaboradorBorrar == true || perfilAuth == true">
                                    <v-icon
                                        color="red"
                                        slot="nombrebtn"
                                        small                                       
                                        >
                                        delete
                                    </v-icon>
                                    <div slot="elemento">
                                        <span>este concepto de Gasto?</span>
                                    </div>
                                    <div slot="btnaceptar">
                                        <v-btn flat @click="deleteItem(props.item)">Ok</v-btn>
                                    </div>
                                </estasSeguro>
                                <v-icon disabled small v-else>
                                    delete
                                </v-icon>
                            </td>                            
                            <td>{{ props.item.tipoGasto }}</td>
                            <td>{{ props.item.tipoDinero }}</td>
                            <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                            <td>{{ props.item.fechaFactura }}</td>
                            <td>{{ props.item.numFactura }}</td>                                                     
                        </template>
                        <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                            </v-alert>
                        </template>
                    </v-data-table>
                    <template>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                    <!-- <span class="subheading mr-1">Total: $ {{ totGastos.toLocaleString('es-MX') }}</span><br> -->
                                    <!-- <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                    <span class="subheading mr-1">Total: $ {{ totSum }}</span> -->
                                </v-card>
                            </v-flex>            
                            <!-- Ticket para imprimir -->
                            <!-- <v-layout row justify-center>
                                <v-dialog v-model="cardTicket" scrollable max-width="330px">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" dark v-on="on">imprimir</v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>Ticket de Gastos</v-card-title>
                                    <v-card-actions>
                                        <v-btn icon color="primary" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                    </v-card-actions>
                                    <v-divider></v-divider>
                                    <v-card-text style="height: 350px;" ref="printTable">                                                    
                                            <v-flex xs12 sm12>
                                            <v-card>                                                            
                                                <v-list two-line>
                                                <template >                                                                
                                                    <p class="text-xs-center">
                                                        <span>{{ contTicket.empresa }}</span><br>
                                                        <span>{{ contTicket.RFC }}</span><br>
                                                        <span>{{ contTicket.direccion }}</span><br>
                                                        <span>{{ contTicket.telefono }}</span><br>
                                                        <span>{{ contTicket.notapie }}</span>
                                                    </p>                                                                
                                                    <v-divider></v-divider>
                                                        <template>
                                                        <v-data-table
                                                            :headers="headers3"
                                                            :items="gastoPartidas"
                                                            class="elevation-1"                                                            
                                                            hide-actions
                                                        >
                                                            <template v-slot:items="props">
                                                            <td>{{ props.item.producto }}</td>
                                                            <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                            <td class="text-xs-right">{{ props.item.precio }}</td>                                                            
                                                            </template>
                                                        </v-data-table>
                                                        <v-card class="text-xs-right">
                                                            <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                            <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                            <span class="body-2">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                        </v-card>
                                                        </template>
                                                    <v-divider></v-divider>
                                                        <span>{{ contTicket.empresa }}</span><br>
                                                        <span>{{ contTicket.RFC }}</span><br>
                                                        <span>{{ contTicket.direccion }}</span><br>
                                                        <span>{{ contTicket.telefono }}</span><br>
                                                        <span>{{ contTicket.notapie }}</span>
                                                    </template>
                                                </v-list>
                                            </v-card>
                                            </v-flex>                                                    
                                    </v-card-text>
                                    <v-divider></v-divider>                                                
                                </v-card>
                                </v-dialog>
                            </v-layout>                                     -->
                        </v-layout>
                    </template>
                </v-card>            
            </v-flex> 
        </v-card>   
    </v-flex>

    <template v-else>
        <div class="mt-5 pt-5" >
            <div class="text-xs-center mt-5 pt-5">
            <v-progress-circular
                :size="70"
                :width="7"
                :style="{'color': paletaColores.primero}"
                indeterminate
                class="mt-5 pt-5"
                ></v-progress-circular>
            </div>
        </div>                  
    </template>
  </v-layout>
</template>

<script>
import estasSeguro from '@/components/estasSeguro.vue'
import { required, minLength, maxLength, minValue, maxValue  } from 'vuelidate/lib/validators'
import { mapMutations, mapState, mapActions } from 'vuex'
import { query, orderBy, collection, deleteDoc, setDoc, updateDoc, writeBatch, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    components: { estasSeguro },
    data: () => ({
        // date: new Date().toISOString().substr(0, 10),
        dateFormatted: null,
        menu1: false,
        // rules: [v => v.length <= 25 || 'Max 25 characters'],
        // colaboradorEnviado: [],
        colaborador: '',
        colaboradores: [],
        // sameHost: false,
        // sameHostName: '',
        contTicket: '',
        rowsPerPageItems: [4, 8, 12],
        pagination: {
            rowsPerPage: 4
        },
        dialogProgress: true,
        dialog: false,
        dialog2: false,
        dialog3: false,
        dialogHelp: false,
        cardTicket: false,
        meseroUser: [],
        partidaId: [],
        productoid: {
            name: 'Seleccionar',
            nombre: '',
            unidad: '',
            precio: 0,
            grupo: ''
        },
        gastosData: [],
        saldoTotPorDinero: [],
        fechaSeleccionada: null, // Variable para almacenar la fecha seleccionada en el date picker
        mostrarDatePicker: false, // Variable para controlar la visibilidad del date picker
        ingresos: [],
        gastos: [],
        gasto: {
            proveedor: '',
            autor: {
                userName: ''
            },
            fechaMili: '',
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaFactura: null,
            tipoGasto: '',
            etiqCyP: null,
            tipoDinero: '',
            numFactura: '',
            monto: '',
            gaid: '',
            notas: '',            
            gastoPartidas: [''],
        },
        etiquetasGasto: [],
        etiquetasDinero: ['Efectivo', 'Banco'],
        etiquetasCreditoPrestamo: [],
        sumGastos: '',
        editedIndex: -1,
        editedIndexPartida: -1,
        defaultItem: {
            proveedor: '',
            autor: {
                userName: ''
            },
            fechaMili: '',
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaFactura: null,
            tipoGasto: '',
            etiqCyP: null,
            tipoDinero: '',
            numFactura: '',
            monto: '',
            gaid: '',
            notas: '',            
            gastoPartidas: [''],
        },
        defaultItemPartida: {
            nombreProducto: '',
            unidad: '',
            precio: '',
            subtotal: 0,
            cantidad: '',
        },    
        gastoPartidas: [],
        partida: {
            nombreProducto: '',
            unidad: '',
            subtotal: 0,
            cantidad: '',
            precio: '',
            ptdaId: '',
            proveedor: '',
            gastogaId: '',
            hora: '',
            autor: {
                colid: '',
                userName: '',
                // uid: ''    
            },
        },
        search: '',  
        selectComanda: null,
        today: '',
        daysPast: '',
        headers: [
          {
            text: 'Proveedor',
            align: 'center',
            value: 'proveedor'
          },
          { text: 'Acciones', value: '', sortable: false, align: 'center' },
          { text: 'Tipo de gasto', value: 'tipoGasto', },
          { text: 'Recurso para Pago', value: 'tipoDinero', },
          { text: 'Monto', value: 'monto', },
          { text: 'Fecha de factura', value: 'fechaFactura', },
          { text: '#Factura', value: 'numFactura', },
        ],              
        headers2: [
          {
            text: 'Producto',
            align: 'left',
            value: 'producto'
          },
          { text: 'Cant', value: 'cantidad', align: 'center', },
          { text: '$Precio', value: 'precio' },
          { text: 'Unidad', value: 'unidad', align: 'center', },
          { text: 'Sub-Total', value: 'subtotal' },
          { text: 'Acciones', value: '', align: 'center' },
        ], 
        headers3: [ //ticket
          {
            text: 'Producto',
            align: 'left',
            value: 'producto',
          },
          { text: 'Cantidad', value: 'cantidad', sortable: false, },
          { text: '$Precio', value: 'precio', sortable: false, },
        ],
    }),
    validations() {
        const maxSaldo = this.getSaldoFromTipoDinero(this.gasto.tipoDinero);
        return {
            gasto: {
                proveedor: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(20),
                },
                numFactura: {
                    maxLength: maxLength(20),
                },
                monto: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(20),
                    minValue: minValue(0),
                    maxValue: maxValue(maxSaldo)
                },
                tipoGasto: {
                    required,
                },
                tipoDinero: {
                    required,
                }
            },
            partida: {            
                nombreProducto: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(20),
                },
                unidad: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(15),
                },            
                precio: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(15),
                },
                cantidad: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(15),
                }
            },
            editedItemEtiq: {
                tipoDato: {
                    required,
                },
                dato: {
                    required,
                },
            },
        }
    },
    created() {
        this.dateFormatted = this.formatDate(this.gasto.fechaFactura);
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()   
        this.consultaretiquetasFzsDB()       
    },
    watch: {
      'gasto.fechaFactura'() {
        this.dateFormatted = this.formatDate(this.gasto.fechaFactura);
      }
    },
    mounted() {
        this.setFechaFactura();
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        puedeRegGastos() {
            const perfilAuth = this.perfilAuth;

            if (perfilAuth) {
                return true;
            } else {
                const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "registrarGasto" && acceso.value);
                return Boolean(accesoAgregar);
            }
        },
        puedeRegEtqs() {
            const perfilAuth = this.perfilAuth;

            if (perfilAuth) {
                return true;
            } else {
                const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "CrearEtiquetas" && acceso.value);
                return Boolean(accesoAgregar);
            }
        },
        computedDateFormatted() {
            return this.formatDate(this.gasto.fechaFactura);
        },
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        colaboradorBorrar() {

            if(this.colaborador.switchBorrar == true){
                // alert("Object found inside the array.");
                return true
            } else{
                // alert("Object not found.");
                return false
            }
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Nuevo Gasto' : 'Editar Gasto'  
        },
        formTitlePartidas () {
            return this.editedIndexPartida === -1 ? 'Nueva Partida' : 'Editar Partida'
        }, 
        subtotSum () {
            var sum = 0;
            this.gastoPartidas.forEach(x => {
                sum += x.subtotal;
            });
            return sum            
        },
        impuesto () {
            let x = 0.16
            var imp = this.subtotSum * x
            // return imp
            return +(Math.round(imp + "e+2")  + "e-2");
        },
        totSum () {
            var tS = this.impuesto + this.subtotSum
            return tS
        },
        totGastos () {
            var suma = 0;
            this.gastos.forEach(x => {
                suma += Number(x.monto);
            });
            return suma
        },
        erroresTipoGasto() {
            let errores = []
            if (!this.$v.gasto.tipoGasto.$dirty) { return errores }
            if (!this.$v.gasto.tipoGasto.required) { errores.push('Selecciona el tipo de Gasto.') }
            return errores
        },
        erroresTipoDinero() {
            let errores = []
            if (!this.$v.gasto.tipoDinero.$dirty) { return errores }
            if (!this.$v.gasto.tipoDinero.required) { errores.push('Selecciona de dónde estás tomando recursos para este gasto.') }
            return errores
        },
        erroresProveedor() {
            let errores = []
            if (!this.$v.gasto.proveedor.$dirty) { return errores }
            if (!this.$v.gasto.proveedor.required) { errores.push('Ingresa el nombre del proveedor.') }
            if (!this.$v.gasto.proveedor.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            if (!this.$v.gasto.proveedor.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresNumFactura() {
            let errores = []
            if (!this.$v.gasto.proveedor.$dirty) { return errores }
            if (!this.$v.gasto.proveedor.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresMonto() {
            let errores = []
            if (!this.$v.gasto.monto.$dirty) { return errores }
            if (!this.$v.gasto.monto.required) { errores.push('Ingresa el monto.') }
            if (!this.$v.gasto.monto.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.gasto.monto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            if (!this.$v.gasto.monto.minValue) { errores.push('Ingresa un número mayor a cero.') }
            if (!this.$v.gasto.monto.maxValue) { errores.push('Ingresa un monto igual o menor al Saldo-cuenta que seleccionaste.') }
            return errores
        },
        erroresProducto() {
            let errores = []
            if (!this.$v.partida.nombreProducto.$dirty) { return errores }
            if (!this.$v.partida.nombreProducto.required) { errores.push('Ingresa el nombre del producto.') }
            if (!this.$v.partida.nombreProducto.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            if (!this.$v.partida.nombreProducto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresUnidad() {
            let errores = []
            if (!this.$v.partida.unidad.$dirty) { return errores }
            if (!this.$v.partida.unidad.required) { errores.push('Ingresa el unidad del producto.') }
            if (!this.$v.partida.unidad.minLength) { errores.push('Ingresa al menos 2 caracteres.') }
            if (!this.$v.partida.unidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresPrecio() {
            let errores = []
            if (!this.$v.partida.precio.$dirty) { return errores }
            if (!this.$v.partida.precio.required) { errores.push('Ingresa el precio.') }
            if (!this.$v.partida.precio.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.precio.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresCantidad() {
            let errores = []
            if (!this.$v.partida.cantidad.$dirty) { return errores }
            if (!this.$v.partida.cantidad.required) { errores.push('Ingresa la cantidad de unidades.') }
            if (!this.$v.partida.cantidad.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.cantidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        getSaldoFromTipoDinero(tipoDinero) {
            if (!tipoDinero) return Infinity;
            const saldo = tipoDinero.split('Saldo: ')[1];
            return parseFloat(saldo);
        },
        itemText(item) {
            return `${item.tipoDinero} - Saldo: ${item.saldo.toFixed(2)}`;
        },
        async consultarIngresos() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.today = new Date().getTime();
            this.daysPast = this.today - (86400000 * 30);

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar los ingresos del usuario ordenados por fechaMili en orden descendente
                    let ingresosQuery = query(
                        collection(doc(db, 'usuarios', userName.uid), 'ingresos'),
                        orderBy('fechaMili', 'desc')
                    );

                    let resultado = await getDocs(ingresosQuery);

                    // Procesar los resultados
                    resultado.forEach(doc => {
                        let ingreso = doc.data();
                        // Añadir ingresos a la lista
                        this.ingresos.push(ingreso);
                    });
                }
            } catch (error) {
                console.error('Error consultando los ingresos:', error);
                this.mostrarError('Ocurrió un error consultando los ingresos.');
            }

            // Consultar el saldo total por dinero
            this.consultarSaldoTotPorDinero();
        },
        async consultarSaldoTotPorDinero() {
            // Primero, creamos un objeto para almacenar los saldos por tipo de dinero
            let saldo = {};

            // Luego, sumamos todos los ingresos por tipo de dinero
            this.ingresos.forEach(ingreso => {
            if (!saldo[ingreso.tipoDinero]) {
                saldo[ingreso.tipoDinero] = 0;
            }
            saldo[ingreso.tipoDinero] += ingreso.monto;
            });

            // Después, restamos todos los gastos por tipo de dinero
            this.gastos.forEach(gasto => {
            if (!saldo[gasto.tipoDinero]) {
                saldo[gasto.tipoDinero] = 0;
            }
            saldo[gasto.tipoDinero] -= gasto.monto;
            });

            // Finalmente, convertimos el objeto a un array de objetos para poder desplegarlo en una tabla
            this.saldoTotPorDinero = Object.keys(saldo).map(tipoDinero => {
            return {
                tipoDinero: tipoDinero,
                saldo: saldo[tipoDinero]
            };
            });

            return this.saldoTotPorDinero;
        },
        setFechaFactura() {
            const fechaActual = new Date();
            const tzOffset = fechaActual.getTimezoneOffset() * 60000; // Obtener compensación de la zona horaria en milisegundos
            const fechaLocalISO = new Date(fechaActual - tzOffset)
            .toISOString()
            .substr(0, 10);
            this.gasto.fechaFactura = fechaLocalISO;
        },
        formatDate(date) {
        if (!date) return null;

        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year.slice(-2)}`;
        },
        parseDate(date) {
        if (!date) return null;

        const [day, month, year] = date.split('/');
        return `20${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        updateFechaFactura() {
        this.gasto.fechaFactura = this.parseDate(this.dateFormatted);
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            // await dbL.collection('fromSameHost').delete()
            // this.sameHost = false
            // this.sameHostName = ''
            this.consultarGastos()
        },
        mostrarTicket() {
            this.consultarTicket()
            this.cardTicket = true            
        },        
        async savePartida() {
            let userNameParametro = this.$route.params.userName.toLowerCase();      
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    if (this.editedIndexPartida > -1) {
                        // Actualizar la partida existente
                        this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });

                        Object.assign(this.gastoPartidas[this.editedIndexPartida], this.partida);

                        try {
                            await updateDoc(doc(
                                db,
                                'usuarios',
                                userName.uid,
                                'gastos',
                                this.gasto.gaid,
                                'gastoPartidas',
                                this.partida.ptdaId
                            ), {
                                cantidad: this.partida.cantidad,
                                producto: this.partida.nombreProducto,
                                unidad: this.partida.unidad,
                                proveedor: this.gasto.proveedor,
                                numFactura: this.gasto.numFactura,
                                autor: this.gasto.autor.userName,
                                notas: this.gasto.notas,
                                tipoGasto: this.gasto.tipoGasto,
                                tipoDinero: this.gasto.tipoDinero,
                            });

                            this.close();
                            this.dialog3 = false;
                            this.dialog2 = false;

                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }
                    } else {
                        // Crear una nueva partida
                        let batch = writeBatch(db);
                        let ptdaId = uuidv4();
                        let subtotal = this.partida.precio * this.partida.cantidad;

                        let partida = {                            
                            ptdaId,
                            cantidad: Number(this.partida.cantidad),
                            precio: Number(this.partida.precio),
                            producto: this.partida.nombreProducto,
                            unidad: this.partida.unidad,
                            subtotal,
                            gaid: this.gasto.gaid,
                            proveedor: this.gasto.proveedor,
                            fechaRegistro: this.gasto.fechaRegistro,
                            numFactura: this.gasto.numFactura,
                            notas: this.gasto.notas,
                            tipoGasto: this.gasto.tipoGasto,
                            tipoDinero: this.gasto.tipoDinero,
                            autor: {
                                colid: this.colaborador.colid || 'userHostAsColaborador',
                                userName: this.colaborador.userName,
                            },
                        };

                        batch.set(doc(
                            db,
                            'usuarios',
                            userName.uid,
                            'gastos',
                            this.gasto.gaid,
                            'gastoPartidas',
                            ptdaId
                        ), partida);

                        try {
                            await batch.commit();
                            this.gastoPartidas.push(this.partida);
                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }

                        this.$nextTick(() => {
                            this.gastoPartidas = [];
                            this.consultarPartidas();
                        });
                        this.closePartida();
                    }
                }
            } catch (error) {
                console.error('Error obteniendo datos del usuario:', error);
                this.mostrarError('Ocurrió un error obteniendo los datos del usuario.');
            }
        },
        async save() {
            let userNameParametro = this.$route.params.userName.toLowerCase();           
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();                
                    
                    if (this.editedIndex > -1) {
                        // Actualizar un gasto existente
                        this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });

                        Object.assign(this.gastos[this.editedIndex], this.gasto);

                        try {
                            await updateDoc(doc(
                                db,
                                'usuarios',
                                userName.uid,
                                'gastos',
                                this.gasto.gaid
                            ), {
                                proveedor: this.gasto.proveedor,
                                tipoGasto: this.gasto.tipoGasto,
                                tipoDinero: this.gasto.tipoDinero,
                                numFactura: this.gasto.numFactura,
                                fechaFactura: this.gasto.fechaFactura,
                                notas: this.gasto.notas,
                                monto: Number(this.gasto.monto),
                            });

                            if (this.gasto.tipoGasto === 'abonoAcrédito/préstamo') {
                                await this.updatecredyprestm(this.gasto);
                            }

                            this.close();
                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }
                    } else {
                        // Crear un nuevo gasto
                        let batch = writeBatch(db);
                        let gaid = uuidv4();
                        
                        let gasto = {
                            gaid,
                            proveedor: this.gasto.proveedor,
                            numFactura: this.gasto.numFactura,
                            monto: Number(this.gasto.monto),
                            fechaMili: new Date().getTime(),
                            fechaFactura: this.gasto.fechaFactura,
                            fechaRegistro: this.gasto.fechaRegistro,
                            etiqCyP: this.gasto.etiqCyP,
                            fuente: this.gasto.etiqCyP,
                            tipoGasto: this.gasto.tipoGasto,
                            tipoDinero: this.gasto.tipoDinero,
                            notas: this.gasto.notas,
                            autor: {
                                colid: this.colaborador.colid || 'userHostAsColaborador',
                                userName: this.colaborador.userName,                            
                            },
                            userNameHost: this.usuarioHost.userName                                        
                        };

                        batch.set(doc(
                            db,
                            'usuarios',
                            userName.uid,
                            'gastos',
                            gasto.gaid
                        ), gasto);

                        if (this.gasto.tipoGasto === 'abonoAcrédito/préstamo') {
                            batch.set(doc(
                                db,
                                'usuarios',
                                userName.uid,
                                'credsYprestms',
                                gasto.etiqCyP,
                                'credYprestmPartidas',
                                gasto.gaid
                            ), gasto);
                        }

                        try {
                            await batch.commit();
                            this.gastos.push(gasto);

                            this.mostrarExito('Se cargó la información exitosamente');
                            this.close();
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }
                    }
                }
            } catch (error) {
                console.error('Error obteniendo datos del usuario:', error);
                this.mostrarError('Ocurrió un error obteniendo los datos del usuario.');
            }

            this.sendToDash();
        },
        async sendToDash() {
            let userNameParametro = this.$route.params.userName.toLowerCase();           
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Calcular la suma de gastos del mes actual
                    let suma = 0;
                    let today = new Date();
                    let currentMonth = today.getMonth();
                    this.gastos.forEach(x => {
                        let gastoDate = new Date(x.formatToIso);
                        if (gastoDate.getMonth() === currentMonth) {
                            suma += Number(x.monto);
                        }
                    });

                    let objToDash = {
                        fromComponent: this.$route.name,
                        icon: 'mdi-cash-minus',
                        title: 'Gastos Mensual',
                        typeShow: 'datoNum', // datoNum, datoTexto, grafica, list-text, list-number, list-text-number
                        data: suma,
                        autor: this.usuario.userName
                    };

                    // Guardar los datos en la colección 'dashData'
                    await setDoc(doc(
                        db,
                        'usuarios',
                        userName.uid,
                        'dashData',
                        'dash-' + objToDash.title
                    ), objToDash);

                }
            } catch (error) {
                console.error('Error enviando datos al dashboard:', error);
                this.mostrarError('Ocurrió un error enviando los datos al dashboard.');
            } finally {
                this.ocultarOcupado();
            }
        },
        async updatecredyprestm(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Actualizar el documento en 'credsYprestms'
                    await updateDoc(doc(
                        db,
                        'usuarios',
                        userName.uid,
                        'credsYprestms',
                        item.etiqCyP,
                        'credYprestmPartidas',
                        item.gaid
                    ), {
                        numDoc: item.numFactura,
                        proveedor: item.proveedor,
                        tipoGasto: item.tipoGasto,
                        tipoDinero: item.tipoDinero,
                        fechaFactura: item.fechaFactura,
                        notas: item.notas,
                        monto: Number(item.monto),
                    });
                }
            } catch (error) {
                console.error('Error actualizando credYprestm:', error);
                this.mostrarError('Ocurrió un error actualizando la información.');
            }
        },
        close () {
            this.dialog = false
            setTimeout(() => {
                this.gasto = Object.assign({}, this.defaultItem)
                // this.gasto.fechaFactura = this.date
                this.editedIndex = -1
            }, 300)            
        },
        closePartida () {            
            this.dialog3 = false
            setTimeout(() => {
                this.partida = Object.assign({}, this.defaultItemPartida)
                this.editedIndexPartida = -1
            }, 300)
        },
        editItem (item) {          
            this.editedIndex = this.gastos.indexOf(item) 
            this.gasto = Object.assign({}, item)
            this.dialog = true
        },
        editItemPartida (item) {          
            this.editedIndexPartida = this.gastoPartidas.indexOf(item) 
            this.partida = Object.assign({}, item)
            this.dialog3 = true
        },
        async deleteItem(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                const index = this.gastos.indexOf(item);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Eliminar el documento del gasto
                    await deleteDoc(doc(db, 'usuarios', userName.uid, 'gastos', item.gaid));

                    // Si es un abono a crédito/préstamo, eliminar también en 'credYprestmPartidas'
                    if (this.gastos[index].tipoGasto === 'abonoAcrédito/préstamo') {
                        await deleteDoc(doc(db, 'usuarios', userName.uid, 'credsYprestms', item.etiqCyP, 'credYprestmPartidas', item.gaid));
                    }

                    // Eliminar el elemento de la lista local
                    this.gastos.splice(index, 1);
                    this.mostrarExito('Se eliminó el concepto de Gasto exitosamente');
                } else {
                    this.mostrarError('Ocurrió un error borrando la información');
                }
            } catch (error) {
                console.error('Error borrando el item:', error);
                this.mostrarError('Ocurrió un error borrando la información');
            } finally {
                this.ocultarOcupado();
            }
            this.close();
        },
        async deletePartida(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            const index = this.gastoPartidas.indexOf(item);
            this.gastoPartidas.splice(index, 1);

            try {
                this.partida = Object.assign({}, item);

                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Eliminar el documento de la partida de gasto
                    await deleteDoc(doc(db, 'usuarios', userName.uid, 'gastos', this.gasto.gaid, 'gastoPartidas', this.partida.ptdaId));

                    this.mostrarExito('Se eliminó la partida exitosamente');
                }
            } catch (error) {
                console.error('Error borrando la partida:', error);
                this.mostrarError('Ocurrió un error borrando la información');
            } finally {
                this.ocultarOcupado();
            }
            this.closePartida();
        },
        async consultaretiquetasFzsDB() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

            if (userNameDoc.exists()) {
                let userName = userNameDoc.data();

                try {
                    let etiquetasCollection = collection(db, 'usuarios', userName.uid, 'etiquetasFzsDB');
                    let resultado = await getDocs(etiquetasCollection);

                    resultado.forEach((doc) => {
                        let datadb = doc.data();
                        let nom = datadb.nombreEtq;

                        switch(datadb.tipoEtiq) {
                            case 'Gasto':
                                this.etiquetasGasto.push(nom);
                                break;
                            case 'Dinero':
                                this.etiquetasDinero.push(nom);
                                break;
                            case 'crédito/préstamo':
                                this.etiquetasCreditoPrestamo.push(nom);
                                break;
                        }
                    });

                } catch (error) {
                    this.mostrarError('Ocurrió un error consultando las Etiquetas.');
                }
            }
        },
        async consultarGastos() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.today = new Date().getTime();
            this.daysPast = this.today - (86400000 * 30);

            try {
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    let gastosCollection = collection(db, 'usuarios', userName.uid, 'gastos');
                    let resultado = await getDocs(gastosCollection);

                    resultado.forEach((doc) => {
                        let gasto = doc.data();
                        gasto.formatToIso = new Date(gasto.fechaMili).toISOString().substr(0, 10);
                        this.gastosData.push(gasto);
                        this.gastos = this.gastosData.filter(elm => !elm.programado || elm.programado === false);
                    });
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando las gastos.');
            }

            this.dialogProgress = false;
            this.consultarIngresos();
        },
        async consultarPartidas() {         
            try {
                let userNameParametro = this.$route.params.userName.toLowerCase();
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    let partidasCollection = collection(db, 'usuarios', userName.uid, 'gastos', this.gasto.gaid, 'gastoPartidas');
                    let resultado = await getDocs(partidasCollection);

                    resultado.forEach((subdoc) => {
                        let partida = subdoc.data();
                        if (partida.gaid === this.gasto.gaid) {
                            this.gastoPartidas.push(partida);
                        }
                    });
                }                     
            } catch (error) {                
                this.mostrarError('Ocurrió un error consultando las partidas.');                
            }
        },
        async consultarTicket() {   
            let userNameParametro = this.$route.params.userName.toLowerCase();

            try {
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    let ticketDoc = await getDoc(doc(db, 'usuarios', userName.uid, 'contTicket', 'cTicket'));

                    if (ticketDoc.exists()) {
                        this.contTicket = ticketDoc.data();
                    } else {
                        this.mostrarError('Faltan datos en el área de Configuración para incluir en el ticket.');  
                    }
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando el ticket.');
            }
        },         
        async abrirGasto(gasto) {    
            this.gastoPartidas = []
            this.gasto = gasto;
            await this.consultarPartidas(); //con o sin await?
            // this.consultarTicket()            
        },
        printData() {
          var divToPrint= this.$refs.printTable
          let newWin= window.open("");
          newWin.document.write(divToPrint.outerHTML);
          newWin.print();
          newWin.close();
        }
    }
    
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>

.tablasubtot {
    font-size: 1rem;
}

.custom-card {
  border-radius: 30px;
}

.text-right {
  text-align: right;
}
    
</style>

