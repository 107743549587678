import { db, auth } from '@/firebase'
import router from '@/router'

export default ({
    namespaced: true,
    state: {
        usuario: null,
        expediente: [],
        expedNum: []
    },
    getters: {
      fotoHonorarios (state) {
          if (!state.expedientesNum || !state.expedientesNum.fotoHonorarios) {
            return require('@/assets/fotoUsuario.png')
          }
          else {
            return state.expedientesNum.fotoHonorarios
          }
        }
    },
    mutations: {
      actualizarUsuario(state, usuario) {
        state.usuario = usuario
      },
      actualizarFotoHonorarios(state, payload) {
          if(!state.expedientesNum) { return }
          const index = state.expedientesNum.findIndex(item => item.expNid === payload.expNid);
          state.expedientesNum[index].fotoHonorarios = payload.url;
  
      },
      actualizarExpedientes(state, expediente) {
          // if(!state.expediente) { return }
          state.expediente = expediente          
      },
      actualizarExpedienteNum(state, expedNum) {
        // if(!state.expedNum) { return }
        state.expedNum = expedNum          
    },
    },
    actions: {
      async iniciarSesion({ commit, getters }, uid ) {
        try {
          let doc = await db.collection('usuarios')
                            .doc(uid)
                            .get()

          if(doc.exists) {
            let usuario = doc.data()          
            commit('actualizarUsuario', usuario)

            switch(router.currentRoute.name) {
              case 'login':
                commit('mostrarExito', getters.saludo, { root: true })
                router.push({ name: 'HomeSistema' })
                break

              case 'envio-verificacion-email':
                commit('mostrarExito', getters.saludo + ', tu contraseña ha sido cambiada exitosamente.', { root: true})
                router.push({ name: 'HomeSistema' })
                break
            }            
          }
          else {
            router.push({ name: 'HomePrincipal'})
          }
        }
        catch (error) {
          commit('mostrarError', 'Ocurrió un error consultando tu información.', { root: true })
        }
      },
      cerrarSesion({ commit }) {
        auth.signOut()
        commit('actualizarUsuario', null)
      },
      async consultarExpedientes({ commit }, uid) {         

        try {
            let resultado = await db.collection('usuarios')
                                    .doc(uid)
                                    .collection('expedientes')
                                    .get()                

              let expediente = [];
              resultado.docs.forEach(doc => {
                
                 expediente.push(doc.data())       
            });

            commit('actualizarExpedientes', expediente)   
        }
        catch (error) {                
          commit('mostrarError', 'Ocurrió un error consultando tu información2.', { root: true })         
        }     

      },
      async consultarExpedienteNum({ commit }, uid) {         

        try {
            let resultado = await db.collection('usuarios')
                                    .doc(uid)
                                    .collection('expedientes')
                                    .doc(this.expediente.expid)
                                    .collection('expedientesNum')
                                    .get()                

              let expedNum = [];
              resultado.docs.forEach(doc => {
                
                 expedNum.push(doc.data())       
            });

            commit('actualizarExpedienteNum', expedNum)   
        }
        catch (error) {                
          commit('mostrarError', 'Ocurrió un error consultando tu información2.', { root: true })         
        }     

      },    
    },
  });

