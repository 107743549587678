
let clientesEventos = () => import( '../views/organizacionEventos/clientesEventos.vue')
let carteraProveedores = () => import( '../views/organizacionEventos/carteraProveedores.vue')
let serviciosPorEvento = () => import( '../views/organizacionEventos/serviciosPorEvento.vue')


export default [
    
      {
        path: '/:userName/eventos',
        name: 'clientesEventos',
        component: clientesEventos,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/cartera-proveedores',
        name: 'carteraProveedores',
        component: carteraProveedores,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/servicios-evento/:evento',
        name: 'serviciosPorEvento',
        component: serviciosPorEvento,
        meta: {
          autenticado: true
        }
      },

]