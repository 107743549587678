
let guiaFinanzas = () => import( '../views/guiaDigitalPractiFzs/guiaFinanzas.vue')
let videoTaller = () => import( '../views/guiaDigitalPractiFzs/videoTaller.vue')
let landingOferta = () => import( '../views/ventaProdDigital/landingOferta.vue')
let LandingsPages = () => import( '../views/ventaProdDigital/LandingsPages.vue')
let productoEnLandingTXT = () => import( '../views/ventaProdDigital/productoEnLandingTXT.vue')

export default [
  
  {
    path: '/:userName/guia-finanzas',
    name: 'guiaFinanzas',
    component: guiaFinanzas,
  },
  {
    path: '/:userName/workshop',
    name: 'videoTaller',
    component: videoTaller,
  },
  {
    path: '/producto-oferta',
    name: 'landingOferta',
    component: landingOferta,
  },
  {
    path: '/:userName/LandingsPages',
    name: 'LandingsPages',
    component: LandingsPages,
    meta: {
      autenticado: true
    }
  },
  {
    path: '/:userName/landingtxt-prod/:productodigital',
    name: 'productoEnLandingTXT',
    component: productoEnLandingTXT,
  },

]