<template>
    <div>
      <v-layout align-center justify-center column fill-height v-if="dialogProgress == false" >
        <v-flex xs12 sm6 class="mt-5">
            <v-text-field
                label="Color Principal"
                v-model="designColors.primero"
                append-icon=""
                @click:append="abrirSelectorColor('primero')"
                >
                <template v-slot:append>
                    <v-btn
                    icon
                    @click.stop="abrirSelectorColor('primero')" 
                    >
                    <v-icon :color="paletaColores.primero" size="48">
                        mdi-palette
                    </v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
            <v-text-field
                label="Color Secundario"
                v-model="designColors.segundo"
                append-icon=""
                @click:append="abrirSelectorColor('segundo')"
                >
                <template v-slot:append>
                    <v-btn
                    icon
                    @click.stop="abrirSelectorColor('segundo')"
                    >
                    <v-icon :color="paletaColores.segundo" size="48">
                        mdi-palette
                    </v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex>
            <!-- <v-btn v-if="coloresSeleccionados" small class="caption" :style="{'background-color': paletaColores.primero}" @click="guardarColores" dark>Guardar Colores</v-btn> -->
            <v-btn
                small 
                class="caption" 
                :style="{'background-color': paletaColores.primero}" 
                @click="guardarColores" 
                :disabled="!coloresSeleccionados" 
                dark
            >
                Guardar Colores
            </v-btn>
        </v-flex>        
      </v-layout>

      <template v-else>
            <div class="mt-5 pt-5" >
                <div class="text-xs-center mt-5 pt-5">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    :style="{'color': paletaColores.primero}"
                    indeterminate
                    class="mt-5 pt-5"
                    ></v-progress-circular>
                </div>
            </div>                  
       </template>
  
      <v-dialog v-model="dialog" max-width="290">
        <sketch v-model="colorSeleccionado"></sketch>
        <v-btn small class="caption" @click="dialog = false">Cancelar</v-btn>
        <v-btn small class="caption" :style="{'background-color': paletaColores.segundo}" @click="guardarColor" dark>Seleccionar</v-btn>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  import { Sketch } from 'vue-color';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { db } from '@/firebase'
  import Localbase from 'localbase'

  let dbL = new Localbase('db')

  export default {
    components: {
    Sketch,
    },

    data() {
      return {
        dialogProgress: true,
        colorSeleccionado: '',
        dialog: false,
        campoActivo: '',
        designColors: {
            primero: '',
            segundo: '',
            tercero: '',
            cuerto: '',
        },
        sameHostName: '',
        sameHost: ''
      };
    },

    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['contTicket']),
        ...mapState('usuarioHost', ['perfilAuth']),
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        coloresSeleccionados() {
            // Verifica si los colores 'primero' y 'segundo' están seleccionados
            return this.designColors.primero !== '' && this.designColors.segundo !== '';
        }
    },
    created() {
        this.consultarWhereFrom();
    },
    methods: {
      ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
      ...mapActions('usuarioHost', ['actualizarUsuarioHost']),
    //   async movingSameHostAoficina() {
    //     if (this.$route.name != 'oficina') {
    //         await dbL.collection('fromOfi').set([])
    //         await dbL.collection('fromOfi').add({sameHost: true, userHost: this.$route.params.userName.toLowerCase()})
    //         this.$router.push({ name: 'oficina' }) 
    //     }
    //   },
    //   async movingSameHost() {
    //     await dbL.collection('fromSameHost').set([])
    //     await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName.toLowerCase() })
    //     this.$router.go(-1)
    //   },
      async consultarWhereFrom() {
        await dbL.collection('fromSameHost').get().then(fP => {
            this.sameHost = fP[0].sameHost
            this.sameHostName = fP[0].userHost
        }).catch(() => {
            // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
            this.$router.push({ name: 'Entrada' })
        }).finally(() => {
            this.consultarColLB()
        });
      },
      async consultarColLB() {
        if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
            // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
            this.$router.push({ name: 'Entrada' })
        } else if (this.sameHost == true) {
        
            this.colaboradores = []
            this.colaborador = ''
            if (this.perfilAuth == true) {
                this.colaborador = this.usuarioHost
                //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
            } else if (this.perfilAuth == false) {
            
                await dbL.collection('colaboradores').get().then(cols => {
                this.colaboradores = cols
                })

                if (this.colaboradores.length > 0) {
                    let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                    if (typeof colaborador === 'object') {
                        let convertINarray = Object.values(colaborador.estatusAccesos)
                        delete colaborador.estatusAccesos
                        colaborador.estatusAccesos = convertINarray
                        this.colaborador = colaborador  
                    } else {
                        this.colaborador = this.usuarioHost
                        //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                    } 
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })  
                }
            } else {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }
        }
        this.dialogProgress = false
      },
      abrirSelectorColor(campo) {
        this.campoActivo = campo;
        this.dialog = true;
      },
      guardarColor() {
        this.designColors[this.campoActivo] = this.colorSeleccionado.hex; // Actualiza designColors.primero o designColors.segundo
        this.dialog = false;
        // this.guardarColores()
      },
      async guardarColores() {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        try {
            this.mostrarOcupado({ titulo: 'Realizando la operación', mensaje: 'Estamos registrando los datos...' })
            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data();   

                await db.collection('usuarios')
                    .doc(userName.uid)
                    .update({designColors: this.designColors}) 

                this.mostrarExito('Cambio de colores realizado!')                        
            }
            this.actualizarUsuarioHost()                    
        }
        catch (error) {
            this.mostrarError('Ocurrió un error cambiando los colores, verifique su conexión de internet')
        }          
        this.ocultarOcupado()     
        this.dialogCobrar = false
        this.dialog2= false
      },
      async consultarColores() {
        // this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                        .doc(userNameParametro)
                        .get()

        if(userNameDoc.exists) {
            let userHost = userNameDoc.data()

            try {
                const docRef = db.collection('usuarios').doc(userHost.uid);
                const docSnapshot = await docRef.get();

                if (docSnapshot.exists) {
                    const userData = docSnapshot.data();
                    
                    if (userData && userData.designColors) {
                    this.designColors = userData.designColors;
                    
                    // Ahora puedes trabajar con la propiedad designColors
                    } else {
                    // console.log('El documento de usuario no contiene la propiedad designColors.');
                    }
                } else {
                    // console.log('El documento de usuario no existe.');
                }
            } catch (error) {
            // console.error('Error al obtener el documento de usuario:', error);
            }

        }
        
        // this.ocultarOcupado()
      },
    },
  };
  </script>
  