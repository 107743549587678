
let veiFloti = () => import( '../views/veiFloti/veiFloti.vue')

export default [
    
  {
    path: '/:userName/veiFloti',
    name: 'veiFloti',
    component: veiFloti,
  },

]