<template>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">configuración de apps</v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Configuración de Apps para el Usuario</v-toolbar-title>
          </v-toolbar>
          <v-layout wrap align-center justify-space-around row class="mt-5">
            <v-flex xs12>
                <v-text-field
                    v-model="section"
                    label="nueva sección"
                    outline style="max-width: 200px"
                ></v-text-field>
                <v-btn small color="primary" @click="saveSeccion(section)" class="caption text-none">guardar sección</v-btn>
            </v-flex>
            <v-flex v-for="item in itemsFuncs" :key="item.switch">         
                <template>
                    <v-layout align-center justify-center row fill-height>
                        <v-dialog v-model="dialogSaveFunc" persistent max-width="290">
                        <template v-slot:activator="{ on }">
                            <v-btn round small class="elevation-4" v-on="on" @click="selectFunc(item)">
                                <v-icon small dark left>{{ item.icon }}</v-icon><span class="caption text-none font-weight-bold">{{ item.etiqueta }}</span>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="headline">Escoge una sección para esta función</v-card-title>
                            <v-flex xs12 sm6 d-flex>
                                <v-select
                                v-model="funcAdd"
                                :items="extraappsList"
                                label="Select"
                                ></v-select>
                            </v-flex>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" flat @click="dialogSaveFunc = false">cerrar</v-btn>
                                <v-btn color="primary" flat @click="saveFuncEnSecc(indexFunc)">guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </v-layout>
                </template>                
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
  </template>

<script>
import { db } from '@/firebase'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      dialogSaveFunc: false,
      itemsFuncs: [],
      section: '',
      funcAdd: '',
      indexFunc: '',
      extraappsList: [],
      extraAppsUser: [],
      arrayAccCols: []
    }
  },

  created() {
    this.consultarItemsApps()
    this.consultarExtraAppsCompradas()
  },

//   computed: {
    // ...mapState('sesion', ['usuario']),
    // ...mapState('usuarioHost', ['usuarioHost']),
    // ...mapState('usuarioHost', ['perfilAuth']),
//   },

  methods: {
    ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
    async consultarItemsApps() {

        try {
            let resultado = await db.collection('itemsApps')
                                    .doc('concentrado-funciones')
                                    .get()                
                           
                let apps = resultado.data()      
            this.itemsFuncs = apps.accesCols
            
        } catch (error) {                
            // this.mostrarError('Ocurrió un error consultando los extra.')              
        } 
    },
    async saveSeccion(item) {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
            var userName = userNameDoc.data()
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' })

            let objectSecc = {
                app: item,
                activa: 'Activada',
                fecha: new Date().toLocaleDateString(),
                funcion: 'extra',
                interaccion: false,
                tipoApp: 'seccion',
                valor: false,
                accesosCols: [],
                icon: '',
                permisos: {
                    colaboradores: 0
                }
            }
        
            try {
                await db.collection('usuarios') 
                        .doc(userName.uid)
                        .collection('comprasAppExtra')
                        .doc(objectSecc.app)
                        .set(objectSecc)    

            } catch (error) {
                //
            }
            this.ocultarOcupado()
            this.section = ''
        }
        this.consultarExtraAppsCompradas()
    },
    async saveFuncEnSecc(indexFunc) {
        
        let index = this.extraAppsUser.findIndex(el => el.app == this.funcAdd)
        this.arrayAccCols = this.extraAppsUser[index].accesosCols
        this.arrayAccCols.push(this.itemsFuncs[indexFunc])
        this.saveFuncEnSeccDB()
    },

    async saveFuncEnSeccDB() {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
            var userName = userNameDoc.data()
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' })
        
            try {
                await db.collection('usuarios') 
                        .doc(userName.uid)
                        .collection('comprasAppExtra')
                        .doc(this.funcAdd)
                        .set({accesosCols: this.arrayAccCols}, {merge: true})    

            } catch (error) {
                alert('hi')
            }
            this.ocultarOcupado()
            this.funcAdd = ''
            this.indexFunc = ''
        }
        this.consultarExtraAppsCompradas()
        this.dialogSaveFunc = false
    },
    async consultarExtraAppsCompradas() {
        this.extraAppsUser = []
        let userNameParametro = this.$route.params.userName.toLowerCase()

        try {
            let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

            if(userNameDoc.exists) {
                var userName = userNameDoc.data()
                
                let resultado = await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('comprasAppExtra')
                                        .get()                
                
                resultado.docs.forEach(doc => {
                    
                    let extraapps = doc.data()               
                    this.extraAppsUser.push(extraapps)    
                })
            }
        } catch (error) {                
            // this.mostrarError('Ocurrió un error consultando los extra.')              
        }
        this.extraappsList = this.extraAppsUser.map(({ app }) => app);
    },
    async selectFunc(item) {
        this.indexFunc = this.itemsFuncs.indexOf(item)
    }
  }

}
</script>