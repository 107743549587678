
let ventasLocalComercio = () => import( '../views/comercio/ventasLocalComercio.vue')

export default [
    
      {
        path: '/:userName/venta-en-establecimiento',
        name: 'ventasEstablecimiento',
        component: ventasLocalComercio,
        meta: {
          autenticado: true
        }
      },

]