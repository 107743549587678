<template>    
    <v-dialog v-model="dialogPlanes" persistent max-width="350">
        <v-card class="rounded my-custom-card">
            <v-layout v-if="$route.name === 'agregarColaboradores'">                    
                <v-flex xs4 class="text-xs-left ml-4">
                    <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-face-agent</v-icon>                
                </v-flex>
                <v-flex xs8 class="text-xs-left">
                    <h3 class="hidden-xs-only"><p class="text-xs-left headline mt-3">Adquisición de Colaboradores</p></h3>
                    <h3 class="hidden-sm-and-up"><p class="text-xs-center headline mt-3">Adquisición de Colaboradores</p></h3>
                </v-flex>  
            </v-layout>
            <!-- <v-card-title primary-title v-if="$route.name != 'agregarColaboradores'"> -->
                <!-- <h3 class="headline mb-0">Planes</h3> -->
                <!-- <span> Ha transcurrido tu periodo de prueba de 14 días, para continuar con los servicos de esta app, subscribete! :).</span>
            </v-card-title> -->
            
            <v-layout v-for="(item, index) in paypalIDs.IDsdeplanes" :key="index">
                <v-layout justify-center>
                    <v-flex xs12>
                        <template>
                            <v-card class="my-2 mx-2 rounded my-custom-card" flat>
                                <v-spacer></v-spacer>
                                <v-card-title class="text-xs-center display-1 grey lighten-1"><strong class="white--text">{{ app }}</strong></v-card-title>
                                <v-card-text v-if="$route.name != 'agregarColaboradores'">{{ item.incluye }}</v-card-text>
                                <template>
                                <v-layout align-center justify-space-around row fill-height wrap>
                                    <v-flex xs6 class="text-xs-center" v-if="$route.name == 'agregarColaboradores'">
                                        Selecciona el número de Colaboradores adicionales 
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="blue" small dark v-on="on">info</v-icon>
                                            </template>
                                            <span>Por favor, ten en cuenta que al seleccionar <br>
                                                un colaborador, automáticamente tendrás dos <br>
                                                usuarios. El primero es el creador de la cuenta, <br>
                                                quien tiene permisos para toda la aplicación. <br>
                                                El segundo es el colaborador, a quien aún <br>
                                                se le deben asignar permisos.</span>
                                        </v-tooltip>:<br>
                                        <v-text-field
                                            v-model="selectNumCols"  min="0"
                                            label="ingresa" outline @input="validateInput"
                                            type="number" class="mx-5 font-weight-bold"
                                        ></v-text-field>
                                    </v-flex>                                                                     
                                    <v-flex xs6 class="text-xs-center" v-for="(item2, index2) in item.periodosdeplan" :key="index2">
                                        <v-btn v-if="selectNumCols > 0 && NumPermCols === 0 && item2.statusCli == 'nuevo-cliente'" small color="principal" class="caption text-none" dark @click="dialogRconPPal(item, item2, index2)">
                                            adquirir subscripción {{ item2.periodo }}
                                        </v-btn ><br>
                                        <v-btn v-if="selectNumCols > 0 && NumPermCols > 0 && item2.statusCli == 'ya-es-cliente'" small color="principal" class="caption text-none" dark @click="dialogRconPPal(item, item2, index2)">
                                            adquirir subscripción {{ item2.periodo }}
                                        </v-btn ><br>
                                        <span v-if="$route.name !== 'agregarColaboradores'" class="ml-3 subheading" strong><strong> ${{ (item2.precio * numUdsNCD * 2).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} USD</strong></span>
                                        <span v-if="$route.name === 'agregarColaboradores' && NumPermCols === 0 && item2.statusCli == 'nuevo-cliente'" class="ml-3 subheading" strong><strong> ${{ calculatePrice(item2, numUdsNCD, selectNumCols).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} USD</strong></span>
                                        <span v-if="$route.name === 'agregarColaboradores' && NumPermCols > 0 && item2.statusCli == 'ya-es-cliente'" class="ml-3 subheading" strong><strong> ${{ (item2.precio * numUdsNCD * selectNumCols).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} USD</strong></span>
                                        <v-dialog v-model="dialogPP" scrollable max-width="400px" v-if="selectedPlanIndex === index2">                                                
                                            <v-card >
                                                <v-card-text>
                                                    <div v-if="selectedPlanIndex === index2" id="paypal-button-container" class="px-3 pt-2"></div>
                                                </v-card-text>                                                        
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                <v-btn color="principal" dark class="caption" small flat @click="dialogPP = false, selectedPlanIndex = null">Cerrar</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-flex>
                                    <!-- cupon campo -->
                                    <v-flex xs12 v-if="selectNumCols > 0">
                                        <v-layout align-center justify-space-around column wrap>
                                        <v-text-field
                                            v-model="cupon" 
                                            label="Introduce tu cupón aquí" outline @input="validateInput"
                                            type="text" class="mx-5"
                                        ></v-text-field>                                        
                                        <v-btn :style="{'background-color': paletaColores.primero}" dark small class="caption text-none" @click="onCuponEntered" :disabled="isProcessing">aplicar cupón</v-btn>
                                    </v-layout>                                        
                                    </v-flex>
                                </v-layout>
                                </template>
                            </v-card>
                        </template>
                    </v-flex>
                </v-layout>
            </v-layout>                                   
            
            <!-- <v-card-text>
            Si requieres un Plan con un mayor número de permisos (colaboradores, productos y/o servicios), podrás 
            solicitarlo en la sección "nuVin Apps Extra".
            </v-card-text> -->
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="$route.name == 'agregarColaboradores'" color="secondary" small @click="cerrarDialog">Cerrar</v-btn >
            <v-btn v-if="$route.name != 'agregarColaboradores'" color="primary" flat small @click="movingSameHostAoficina">Cerrar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- <v-dialog
    v-model="dialog"
    width="500"
    >
        <template v-slot:activator="{ on }">
            <v-list-tile-action>
            <div class="square" v-on="on" :style="{'background-color': paletaColores.segundo}"><v-icon color="white">mdi-check-decagram-outline</v-icon></div>
            </v-list-tile-action>
            <v-list-tile-content>
            <v-list-tile-title v-on="on" class="mt-2 white--text">Subscripciones</v-list-tile-title>
            </v-list-tile-content>
        </template>

        <v-card>
        <v-card-title class="headline">
            Estatus de tus Subscripciones
        </v-card-title>

        <v-card-text>
            <div> Ha transcurrido tu periodo de prueba de 14 días, para continuar con los servicos de esta app, comunícate con nostros: </div><br><br>
            <span><span class="principal--text font-weight-bold">email:</span>  nubesdebits@gmail.com</span><br>
            <span><span class="principal--text font-weight-bold">móvil-whatsapp:</span>  9996470286</span><br>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat small dark class="principal--text caption" @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>      -->
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { loadScript } from '@paypal/paypal-js';
import Localbase from 'localbase'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
// import comandasVue from './alimentosYbebidas/comanDas.vue';
// import avisoSalirPruebaVue from '../components/avisoSalirPrueba.vue';
  
let dbL = new Localbase('db')

export default {
    props: ['app',],
    data() {
        return {
            numUdsNCD: 0,
            NumPermCols: '',
            selectNumCols: 0,
            paypal: {},
            dialog: true,
            dialogPP: false,                
            dialogPlanes: true,
            paypalIDs: {},
            planpicked: '',
            selectedPlanIndex: null,
            quantity: 0,
            cupon: '',
            periodopicked: '',
            isProcessing: false,
        }
    },
    
    computed: {
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['usuarioHost']),
      paletaColores() {
        if (this.usuarioHost.designColors) {
            return this.usuarioHost.designColors
        } else {
            return ''
        }
      },
    },

    mounted() {
        this.paypalClientId= process.env.VUE_APP_paypalClientId
    },

    created() {
        this.consultarPayPalIDs()
        // this.sumaUnidadesNCD = this.sumaUnidadesNCD;
    },
    
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        calculatePrice(item2, numUdsNCD, selectNumCols) {
            let total = 0;
            if (selectNumCols > 0) {
            total += item2.precio; // El primer selectNumCols vale item2.precio
            total += item2.precioSubs * (selectNumCols - 1); // Los demás selectNumCols valen item2.precioSubs
            }
            total *= numUdsNCD;
            return total;
        },
        validateInput() {
            if (this.selectNumCols < 0) {
                this.selectNumCols = 0; // Establecer el valor a cero o cualquier otro valor mínimo aceptable
            }
        },
        cerrarDialog() {
            this.$emit('cerrar-dialogo'); // Emitir evento personalizado al hacer clic en "cerrar"
        },
        async movingSameHostAoficina() {
            if (this.$route.name != 'oficina') {
                await dbL.collection('fromOfi').set([])
                await dbL.collection('fromOfi').add({sameHost: true, userHost: this.$route.params.userName.toLowerCase()})
                this.$router.push({ name: 'oficina' }) 
            }
        },
        async consultarPayPalIDs() {
            await dbL.collection('paypalIDs').get().then(pp => {
                this.paypalIDs = pp[0]
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                // this.$router.push({ name: 'Entrada' })
            })
            // .finally(() => {
            //     this.consultarColLB()
            // });
            // if (this.$route.name == 'agregarColaboradores') {
            await dbL.collection('NumUdsNCD').get().then(ncd => {
                this.numUdsNCD = ncd[0].numUdsNCD
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                // this.$router.push({ name: 'Entrada' })
            })

            await dbL.collection('NumPermCols').get().then(npc => {
                this.NumPermCols = npc[0].numPermisosCols
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                // this.$router.push({ name: 'Entrada' })
            })
        },
        async onCuponEntered() {
            this.isProcessing = false;
            let cuponData = await db.collection('datasoporte')
                                    .doc('CuponDescuento')
                                    .get()

            if(cuponData.exists) {
                let cuponIngresado = this.cupon;
                let cuponEncontrado = null;

                for (let cupon in cuponData.data()) {
                    if (cuponData.data()[cupon].cupon === cuponIngresado && cuponData.data()[cupon].estaActivo === true) {
                        cuponEncontrado = cuponData.data()[cupon];
                        break;
                    }
                }

                if (cuponEncontrado) {
                    this.numUdsNCD *= cuponEncontrado.fraccion;
                    this.isProcessing = true;
                } else {
                    this.mostrarError('Ese cupón no existe.');
                }
            }
        },
        async dialogRconPPal(itemPlan, itemPeriodo, index2) {
            this.selectedPlanIndex = index2;
            this.dialogPP = true
            this.planpicked = itemPlan
            this.periodopicked = itemPeriodo
            await this.loadPaypalScript(itemPeriodo)
        },            
        async loadPaypalScript(itemPeriodo) {            
            try {
                const data = this.paypalClientId
                this.paypal = await loadScript({
                    'client-id': data,
                    vault: true,
                    intent: 'subscription',
                    // currency: 'MX',
                });
                this.renderPaypalButtons(itemPeriodo);                        
            } catch (error) {
                console.error('Error al cargar el script de PayPal:', error);
                this.mostrarError('Hay un problema con el internet, favor de intentar más tarde.');
            }
        },
        renderPaypalButtons(itemPeriodo) {
            if (this.paypal) {
                const container = document.getElementById("paypal-button-container");
                
                if (container) {
                    
                    try {
                        this.paypal.Buttons({ 
                            createSubscription: (data, actions) => {
                                return actions.subscription.create({
                                    'plan_id': itemPeriodo.IDdelplan,// Creates the subscription
                                    // 'plan_id': 'P-4KL447118U846293LMTLLZNI',
                                    'quantity': this.numUdsNCD // The quantity of the product for a subscription
                                });
                            },
                            
                            onApprove: (data) => {
                                // 'paypal config para enviar mail cuando no pase la tarjeta a partir de la segunda mensualidad, '
                                // seccion de monitoreo de clientes-subscripciones 
                                if (this.$route.name !== 'agregarColaboradores') {
                                    this.mostrarExito('Tu subscripción ha sido exitosa' + data.subscriptionID + 'podrás ver los datos de ésta en SUBSCRIPCIONES del Dash')
                                    this.updateEstatusPlan(data)    
                                }

                                if (this.$route.name === 'agregarColaboradores') {
                                    this.mostrarExito('Ya tienes Coloboradores adicionales' + data.subscriptionID + 'podrás ver los datos de registro en SUBSCRIPCIONES del Dash')
                                    this.updateEstatusCols(data)    
                                }
                                
                                this.dialogPP = false;
                                this.dialogPlanes = false
                                dbL.collection('paypalIDs').delete()
                                this.$router.push({ name: 'Entrada', params: {userName: this.usuarioHost.userName} });
                            },
                        }).render("#paypal-button-container");

                    } catch (error) {
                        console.error("failed to render the PayPal Buttons", error);
                    }
                } else {
                console.error("container element not found in the DOM");
                }
            }
        },
        async updateEstatusPlan(data) {
            try {
                const userNameParametro = this.$route.params.userName.toLowerCase();
                const userNameDoc = await db.collection('userNames').doc(userNameParametro).get();

                if (userNameDoc.exists) {
                    const userName = userNameDoc.data();                        

                    // Actualizar el documento en Firestore con el array modificado
                    await db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra')
                        .doc(this.$props.app)
                        .update({
                            estatusPlan: "activo"
                        });

                    // Aquí es donde se realiza la operación set en la colección "pagos-subscripciones"
                    let idsubs = uuidv4()

                    let objSubscrip = {
                        idsubs,
                        dataFromPayPal: data,
                        planPicked: this.planpicked,
                        periodoPicked: this.periodopicked,
                        usuario: this.usuario.userName,
                        compra: 'subscripción',
                        fromReg: 'cuenta-nuvin',
                        formaDEpago: 'paypal',
                        app: this.$props.app
                    }

                    await db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra')
                    .doc(this.$props.app).collection('pagos-subscripciones')
                    .doc(objSubscrip.idsubs).set(objSubscrip);

                } else {
                // console.error('Error: no se encontró el documento del usuario.');
                }
            } catch (error) {
                // this.mostrarError('Ocurrió un error consultando los extra.');
                // console.error('Error al actualizar propiedad:', error);
            }
        },
        async updateEstatusCols(data) {
            try {
                const userNameParametro = this.$route.params.userName.toLowerCase();
                const userNameDoc = await db.collection('userNames').doc(userNameParametro).get();

                if (userNameDoc.exists) {
                    const userName = userNameDoc.data();                

                    const selectNumCols = parseInt(this.selectNumCols);
                    const newNumCols = this.NumPermCols + selectNumCols

                    await db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra')
                        .doc(this.$props.app)
                        .update({
                            estatusPlan: "activo"
                        });

                    // Actualizar el documento en Firestore con el array modificado
                    await db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra')
                        .doc(this.$props.app)
                        .update({
                        permisos: {colaboradores: newNumCols}
                        });

                    // Aquí es donde se realiza la operación set en la colección "pagos-subscripciones"
                    let idsubs = uuidv4()

                    let objSubscrip = {
                        idsubs,
                        dataFromPayPal: data,
                        planPicked: this.planpicked,
                        periodoPicked: this.periodopicked,
                        usuario: this.usuario.userName,
                        formaDEpago: 'paypal',
                        compra: 'colaboradores adicionales',
                        fromReg: 'cuenta-nuvin',
                        numColsAdd: this.selectNumCols,
                        precioColporApp: this.item2.precio * this.numUdsNCD,
                        app: this.$props.app,
                        fechaPagoSubscri: new Date()
                    }

                    await db.collection('usuarios').doc(userName.uid).collection('comprasAppExtra')
                    .doc(this.$props.app).collection('pagos-subscripciones')
                    .doc(objSubscrip.idsubs).set(objSubscrip);

                } else {
                console.error('Error: no se encontró el documento del usuario.');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando los extra.');
                console.error('Error al actualizar propiedad:', error);
            }
        }

        // LO SIGUIENTE son ejemplos STRIPE

        // async sendToCheckout1() {
        //     this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })
        //     let userNameParametro = this.$route.params.userName.toLowerCase()
        //     let userNameDoc = await db.collection('userNames')
        //                                 .doc(userNameParametro)
        //                                 .get()

        //     if(userNameDoc.exists) {
        //         let userName = userNameDoc.data()

        //         const docRef = await db
        //             .collection('usuarios')
        //             .doc(userName.uid)
        //             .collection('checkout_sessions')
        //             .add({
        //             price: this.precioNuvit1,
        //             success_url: window.location.origin,
        //             cancel_url: window.location.origin,
        //             });
        //         // Wait for the CheckoutSession to get attached by the extension
        //         docRef.onSnapshot((snap) => {
        //             const { error, sessionId } = snap.data(); 
        //             if (error) {
        //             // Show an error to your customer and 
        //             // inspect your Cloud Function logs in the Firebase console.
        //             alert(`An error occured: ${error.message}`);
        //             }
        //             if (sessionId) {
        //             // We have a session, let's redirect to Checkout
        //             // Init Stripe
        //             const stripe = window.Stripe('pk_live_n0Naki9dg0yyNM6LAIjBfm5I00vLQGSLdj');
        //             stripe.redirectToCheckout({ sessionId });
        //             }
        //         });
        //     }
        // },

        // async sendToCustomerPortal() {
        //     // had to update firebase.app().functions() to firebase.default.functions() and
        //     // removed the region from the functions call (from stripe firebase extension docs)
        //     this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })

        //     const functionRef = firebase.default
        //     .functions()
        //     .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
        //     const { data } = await functionRef({ returnUrl: window.location.origin });
        //     window.location.assign(data.url);
        // },
    },
}
</script>

<style>
    .my-custom-card {
        border-radius: 10px;
        outline: 1px solid #212121;
    }
</style>