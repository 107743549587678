<template>
    <v-layout justify-center align-center wrap>
        <v-flex xs12 ml-3 mt-2>
            <v-btn icon dark>                
                <a @click="movingSameHost"><v-icon small dark left color="black">arrow_back</v-icon></a>
            </v-btn>
            <span class="body-2">oficina virtual</span>
        </v-flex>
        <v-flex xs12 sm6>
            <v-card class="elevation=5 ma-3">
                <v-toolbar flat color="white">
                    <v-toolbar-title>Agrega un<br> SERVICIO</v-toolbar-title>
                        <v-divider
                            class="mx-2"
                            inset
                            vertical
                            ></v-divider>
                        <v-spacer></v-spacer>
                                    <!-- área para agregar Grupos       -->
                        <v-dialog v-model="dialogAddG" max-width="500px">
                            <template v-slot:activator="{ on }">
                                <v-btn v-if="QServ < servsNumPermiso" class="hidden-xs-only mb-2" color="primary" dark v-on="on" @click="emptyFieldsG">Nuevo servicio</v-btn>
                                <v-btn v-else disabled class="hidden-xs-only" mb-2 >Nuevo servicio</v-btn>
                                <div v-if="QServ < servsNumPermiso">
                                    <v-btn small class="hidden-sm-and-up caption mb-2" color="primary" dark v-on="on" @click="emptyFieldsG">agregar</v-btn>    
                                </div>                                
                                <div v-else>
                                    <v-btn disabled class="hidden-sm-and-up caption" small icon>agregar</v-btn>
                                    <v-tooltip v-model="show" top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                            <v-icon>mdi-face-agent</v-icon>
                                            </v-btn>
                                        </template>
                                        <span class="caption">Has superado la cantidad de servicios permitido por el plan, solicita uno adicional en: nubesdebits@gmail.com.</span>
                                    </v-tooltip> 
                                </div>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ formTitleG }}</span>
                                </v-card-title>

                                <v-card-text>
                                <v-container grid-list-md>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6 md4>
                                            <v-text-field v-model="menugpodb.grupo" label="Servicio, ej. Desarrollo de páginas web" counter="30" autofocus :error-messages="erroresGrupo"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-textarea
                                            v-model="menugpodb.descripcion"
                                            name="input-7-1"
                                            label="Descripción"
                                            value=""
                                            hint="Este campo puede quedar vacío."
                                            ></v-textarea>
                                        </v-flex>
                                        <v-flex>
                                            <v-switch
                                            v-model="menugpodb.btnexactiv"
                                            :label="`Botón para información extra`"
                                            ></v-switch>
                                            <v-text-field
                                                v-if="menugpodb.btnexactiv == true"
                                                v-model="menugpodb.btnexetiq"
                                                label="Etiqueta del botón"
                                                placeholder="ej. instrucciones, lista, proceso..."
                                                box
                                            ></v-text-field>
                                            <v-textarea
                                            v-if="menugpodb.btnexactiv == true"
                                            v-model="menugpodb.btnexcont"
                                            name="input-7-1"
                                            label="Contenido"
                                            value=""
                                            hint="Este campo puede quedar vacío."
                                            ></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" flat @click="closeG">Cancelar</v-btn>
                                <v-btn color="primary" :depressed="$v.menugpodb.grupo.$invalid" :disabled="$v.menugpodb.grupo.$invalid" flat @click="registrarGrupo">Guardar</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                </v-toolbar>
                <v-data-table
                    :headers="headersG"
                    :items="menuGpoDB"
                    class="elevation-1"
                    >
                    <template v-slot:items="props">
                        <td>{{ props.item.grupo }}</td>
                        <td class="justify-center align-center layout">
                                <!-- área para agregar servicios dentro de grupos icono book    -->
                                <!-- se quita de la tabla temporalmente, se está pensando en cómo hacer la funcion de cotizador -->
                            <!-- <v-dialog v-model="dialogAddPs" persistent max-width="600px">                            
                                <template v-slot:activator="{ on }">                                
                                    <v-icon
                                        @click="abrirGrupo(props.item)"
                                        dark v-on="on"
                                        color="primary"
                                        class="mr-2"
                                        small
                                        >
                                        book
                                    </v-icon>
                                </template>
                                <v-card  
                                    v-if="menugpodb"
                                    :key= "menugpodb.gid"
                                    :menuProductosDB="menugpodb.menuProductosDB"
                                    >
                                    <v-toolbar flat color="white">
                                        <h3 class="title"> {{menugpodb.grupo}} </h3>                                        
                                        <v-divider
                                            class="mx-2"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-dialog v-model="dialogAddP" max-width="500px" persistent>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="hidden-xs-only" mb-2 color="primary" dark v-on="on" >Nuevo producto</v-btn>
                                                <v-btn class="hidden-sm-and-up caption" small color="primary" dark v-on="on" @click="emptyFieldsP">agregar</v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ formTitleP }}</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container grid-list-md>
                                                        <v-layout wrap>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="menuproductosdb.nombre" label="Nombre del producto" counter="30" autofocus @blur="$v.menuproductosdb.nombre.$touch()" :error-messages="erroresNombre"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="menuproductosdb.unidad" label="Unidad" counter="10" autofocus @blur="$v.menuproductosdb.unidad.$touch()" :error-messages="erroresUnidad"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model.number="menuproductosdb.precio" type="number" label="Precio" counter="10" autofocus @blur="$v.menuproductosdb.precio.$touch()" :error-messages="erroresPrecio"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="menuproductosdb.descripcion" label="Breve descripción" counter="300" autofocus :error-messages="erroresDescripcion"></v-text-field>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue darken-1" flat @click="closeP">Cancelar</v-btn>
                                                    <v-btn color="blue darken-1" :depressed="$v.menuproductosdb.$invalid" :disabled="$v.menuproductosdb.$invalid" flat @click="guardarProducto">Guardar</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-btn icon small fab class="hidden-sm-and-up text-xs-center" color="transparent" @click="dialogAddPs = false">
                                            <v-icon>close</v-icon>   
                                        </v-btn>
                                        <v-btn small flat outline class="hidden-xs-only" color="blue darken-1" @click="dialogAddPs = false">Cerrar</v-btn>
                                    </v-toolbar>
                                    <v-data-table
                                        :headers="headersP"
                                        :items="menuProductosDB"
                                        class="elevation-1"
                                        >
                                        <template v-slot:items="props">
                                            <td>{{ props.item.nombre }}</td>
                                            <td class="text-xs-right">{{ props.item.unidad }}</td>
                                            <td class="text-xs-right">{{ props.item.precio }}</td>
                                            <td class="text-xs-right">{{ props.item.descripcion }}</td>
                                            <td class="justify-center align-center layout">
                                                <v-layout row >
                                                    <v-flex>
                                                        <v-icon
                                                        small
                                                        class="mt-2 pt-1 mr-0 pr-0"
                                                        @click="editItemP(props.item)"
                                                        >
                                                            edit
                                                        </v-icon>
                                                    </v-flex>
                                                    <v-flex>
                                                        <estasSeguro v-if="colaboradorBorrar == true" small>
                                                            <v-icon
                                                                color="grey darken-2"
                                                                slot="nombrebtn"
                                                                small                                   
                                                                >
                                                                delete
                                                            </v-icon>
                                                            <div slot="elemento">
                                                                <span>este Producto?</span>
                                                            </div>
                                                            <div slot="btnaceptar">
                                                                <v-btn flat @click="deleteItemP(props.item)">Ok</v-btn>
                                                            </div>
                                                        </estasSeguro>
                                                        <v-icon
                                                            disabled
                                                            small
                                                            v-if="colaboradorBorrar == false"
                                                        > 
                                                            delete
                                                        </v-icon>
                                                    </v-flex>
                                                </v-layout>
                                            </td>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-alert :value="true" color="error" icon="warning">
                                                Todavía no hay servicios, presiona el botón '+' para agregar.
                                            </v-alert>
                                        </template>
                                    </v-data-table>                     
                                </v-card>  
                            </v-dialog> -->
                            <v-layout row>
                                <v-flex>
                                    <v-icon
                                        class="mt-2 pt-1 pr-0 mr-0"
                                        @click="editItemG(props.item)" small
                                        >
                                        edit
                                    </v-icon>
                                </v-flex>
                                <v-flex class="ml-0 pl-0">
                                    <estasSeguro v-if="colaboradorBorrar == true">
                                        <v-icon
                                            color="grey darken-2"
                                            slot="nombrebtn"
                                            small                            
                                            >
                                            delete
                                        </v-icon>
                                        <div slot="elemento">
                                            <span>este Servicio?</span>
                                        </div>
                                        <div slot="btnaceptar">
                                            <v-btn flat @click="deleteItemG(props.item)">Ok</v-btn>
                                        </div>
                                    </estasSeguro>
                                    <v-icon
                                        disabled
                                        small
                                        v-if="colaboradorBorrar == false"
                                    >
                                        delete
                                    </v-icon>
                                </v-flex>
                            </v-layout>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning">
                            Todavía no hay servicios, presiona el botón "agregar".
                        </v-alert>
                    </template>
                </v-data-table>       
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import estasSeguro from '@/components/estasSeguro.vue'
import { mapMutations, mapState, mapActions } from 'vuex'
import { db, auth } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    components: { estasSeguro },
    data() {
        return {
            QServ: '',
            usuarioHost: {
                nivelPlan: '',
            },
            colaboradorBorrar: false,
            colaborador: '',
            colaboradores: [],
            show: false,
            dialogAddGyP: false,
            dialogAddG: false,
            dialogAddPs: false,
            dialogAddP: false,
            nuvitGratis: 'price_1IWW9wGLlS3xAdXuxEwvjESu',
            nuvit1: 'price_1IUbLQGLlS3xAdXuPAvgzhp2',
            nuvit2: 'price_1IUbMVGLlS3xAdXupUDgRNhi',
            nuvit3: 'price_1IUbNCGLlS3xAdXubxbTjcfF',
            planes: [],
            planPorPrecio: [],
            headersG: [
                { text: 'Servicio', value: 'grupo' },
                { text: 'Acciones', value: 'nombre', sortable: false }
            ],
            // headersP: [
            //     {
            //     text: 'Productos',
            //     align: 'left',
            //     value: 'nombre'
            //     },
            //     // { text: 'Servicio', value: 'grupo' },
            //     { text: 'Unidad', value: 'unidad' },
            //     { text: 'Precio ($)', value: 'precio' },
            //     { text: 'Descripcion', value: 'descripcion', sortable: false },
            //     { text: 'Acciones', value: 'nombre', sortable: false }
            // ],
            menuGpoDB: [],
            menugpodb: {
                // elaboracion: 'cocina',
                grupo: '',
                btnexactiv: false,
                btnexetiq: '',
                btnexcont: '',
                // menuProductosDB: [{nombre:''}],
                productos: [
                    {nombre: '',}
                ],
            },
            // menuProductosDB: [],
            // menuproductosdb: {
            //     nombre: '',
            //     precio: 0,
            //     unidad: '',
            //     descripcion: '',
            //     grupo: '',
            // },
            // editedItem: {
            //     nombre: '',
            //     precio: 0,
            //     descripcion: '',
            //     grupo: '',
            // },            
            editedIndexP: -1,
            editedIndexG: -1,
            defaultItemP: {
                nombre: '',
                precio: 0,
                unidad: '',
                descripcion: '',
                grupo: '',
            },
            defaultItemG: {
                grupo: '',
                descripcion: '',
                btnexactiv: false,
                btnexetiq: '',
                btnexcont: '',
            },
        }
    },
    validations: {
        // menuproductosdb: {
        //         nombre: {
        //             required,
        //             maxLength: maxLength(30),
        //             minLength: minLength(3)
        //         },
        //         unidad: {
        //             required,
        //             maxLength: maxLength(10),
        //             minLength: minLength(2)
        //         },
        //         precio: {
        //             required,
        //             maxLength: maxLength(10),
        //             minLength: minLength(1)
        //         },
        //         descripcion: {
        //             maxLength: maxLength(300),
        //         },
        //     },
        menugpodb: {
                grupo: {
                    required,
                    maxLength: maxLength(30)
                }
        }
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        formTitleG () {
            return this.editedIndexG === -1 ? 'Servicio Nuevo' : 'Editar Servicio'
        },
        servsNumPermiso() {
          return  this.usuarioHost.permisos.servicios
        },
        // formTitleP () {
        //     return this.editedIndexP === -1 ? 'Producto Nuevo' : 'Editar producto'        
        // },
        // erroresDescripcion() {
        //     let errores = []
        //     if (!this.$v.menuproductosdb.descripcion.$dirty) { return errores }        
        //     if (!this.$v.menuproductosdb.descripcion.maxLength) { errores.push('Ingresa máximo 300 caracteres.') }
        //     return errores
        // },
        // erroresNombre() {
        //     let errores = []
        //     if (!this.$v.menuproductosdb.nombre.$dirty) { return errores }  
        //     if (!this.$v.menuproductosdb.nombre.required) { errores.push('Ingresa el nombre del producto.') }      
        //     if (!this.$v.menuproductosdb.nombre.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
        //     return errores
        // },
        // erroresUnidad() {
        //     let errores = []
        //     if (!this.$v.menuproductosdb.unidad.$dirty) { return errores }  
        //     if (!this.$v.menuproductosdb.unidad.required) { errores.push('Ingresa el unidad del producto.') }      
        //     if (!this.$v.menuproductosdb.unidad.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
        //     return errores
        // },
        // erroresPrecio() {
        //     let errores = []
        //     if (!this.$v.menuproductosdb.precio.$dirty) { return errores }  
        //     if (!this.$v.menuproductosdb.precio.required) { errores.push('Ingresa el precio del producto.') }      
        //     if (!this.$v.menuproductosdb.precio.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
        //     return errores
        // },
        erroresGrupo() {
            let errores = []
            if (!this.$v.menugpodb.grupo.$dirty) { return errores }  
            if (!this.$v.menugpodb.grupo.required) { errores.push('Ingresa el nombre del grupo.') }      
            if (!this.$v.menugpodb.grupo.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
            return errores
        },
        // erroresProducto() {
        //     let errores = []
        //     if (!this.$v.menuproductosdb.$dirty) { return errores }  
        //     if (!this.$v.menuproductosdb.required) { errores.push('Ingresa el nombre del producto.') }      
        //     if (!this.$v.menuproductosdb.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
        //     return errores
        // },
    },
    watch: {
      dialogAddGyP (val) {
        val || this.closeG()
      },
      dialogAddG (val) {
        val || this.closeG()
      },
      dialogAddPs (val) {
        val || this.closeP()
      },
      dialogAddP (val) {
        val || this.closeP()
      },
    //   '$route' () {
    //     this.consultarUsuarioHost()
    //     this.consultarColaboradores()  
    //   },
    },
    created () {
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        async movingSameHost() {
            await dbL.collection('fromSameHost').set([])
            await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName })
            this.$router.go(-1)
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            await dbL.collection('fromSameHost').delete()
            this.sameHost = false
            this.sameHostName = ''
            this.consultarGruposDB()
        },
        ConsulQServ() {
            this.QServ = this.menuGpoDB.length
        },
        emptyFieldsG() {
            this.menugpodb = this.defaultItemG
            this.closeG()
        },       
        editarGyP() {
            this.dialogAddGyP = true
        },
        async consultarPlan() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('subscriptions')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let plansdb = doc.data()
                        
                            this.planPorPrecio.push(plansdb)    
                        
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los Planes-precios.')                
            }
        },    
        async registrarGrupo() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            let userNameDoc = await db.collection('userNames')
                                      .doc(userNameParametro)
                                      .get()

            if(userNameDoc.exists) {
              let userName = userNameDoc.data()
                if (this.$v.menugpodb.grupo.$invalid) { return }
                

                    let menugpodbExistente = await db.collection('usuarios')
                                                    .doc(userName.uid)
                                                    .collection('menuGpoDB')
                                                    .doc(this.menugpodb.grupo.toLowerCase())
                                                    .get()

                if(menugpodbExistente.exists) {
                    this.mostrarAdvertencia(`El Servicio '${this.menugpodb}', ya existe, elige uno diferente`)
                    return
                }

                try {
                    this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })

                        if (this.editedIndexG > -1) {

                            this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                            Object.assign(this.menuGpoDB[this.editedIndexG], this.menugpodb)

                            try {
                                await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('menuGpoDB')
                                        .doc(this.menugpodb.gid)
                                        .update({
                                            grupo: this.menugpodb.grupo,
                                            descripcion: this.menugpodb.descripcion,
                                            btnexactiv: this.menugpodb.btnexactiv,
                                            btnexetiq: this.menugpodb.btnexetiq,
                                            btnexcont: this.menugpodb.btnexcont,  
                                        })
                                
                                this.closeG()
                                this.dialogAddG = false

                                this.mostrarExito('Se editó el Servicio exitosamente')
                            }              
                            catch (error) {
                                this.mostrarError('Ocurrió un error cargando el Servicio')
                            }
                            finally {
                                this.ocultarOcupado()
                            }
                        } else {

                            let batch = db.batch()
                            let gid = uuidv4()
                            
                            let menugpodb = {
                                gid,
                                grupo: this.menugpodb.grupo,
                                descripcion: this.menugpodb.descripcion,
                                autor: this.usuario.userName,
                                userNameHost: userName.userName,
                                btnexactiv: this.menugpodb.btnexactiv,
                                btnexetiq: this.menugpodb.btnexetiq,
                                btnexcont: this.menugpodb.btnexcont,
                                // menuProductosDB: [
                                //                 {
                                //                     pid: this.menuproductosdb.pid,
                                //                     nombre: this.menuproductosdb.nombre,
                                //                     precio: this.menuproductosdb.precio,
                                //                     descripcion: this.menuproductosdb.descripcion,
                                //                     grupo: this.menugpodb.grupo,
                                //                 }
                                //             ]                          
                            }

                            batch.set(db.collection('usuarios').doc(userName.uid)
                                        .collection('menuGpoDB').doc(menugpodb.gid), menugpodb)                
                            
                            try {
                                batch.commit()
                                this.menuGpoDB.push(this.menugpodb)
                                
                                this.mostrarExito('Se registró el Servicio exitosamente')
                            }
                            catch (error) {
                                this.mostrarError('Ocurrió un error cargando el Servicio')
                            }
                            finally {
                                this.ocultarOcupado()
                            }
                            this.closeG()
                            this.menuGpoDB = []
                            this.consultarGruposDB()
                        }

                    this.closeG()
                }
                catch (error) {
                    this.mostrarError('Ocurrió un error cargando la información')
                }
                finally {                
                    this.ocultarOcupado()
                }
            }
            this.closeG()
        },
        async consultarGruposDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('menuGpoDB')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let gpodb = doc.data()
                        
                            this.menuGpoDB.push(gpodb)    
                        
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los Grupos.')                
            }
            this.ConsulQServ()
        },

        // async guardarProducto() {
        //     this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' })

        //     if (this.editedIndexP > -1) {

        //         this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
        //         Object.assign(this.menuProductosDB[this.editedIndexP], this.menuproductosdb)

        //         try {
        //             await db.collection('usuarios')
        //                     .doc(this.usuarioHost.uid)
        //                     .collection('menuGpoDB')
        //                     .doc(this.menugpodb.gid)
        //                     .collection('menuProductosDB')
        //                     .doc(this.menuproductosdb.pid)
        //                     .update({
        //                             nombre: this.menuproductosdb.nombre,
        //                             unidad: this.menuproductosdb.unidad,
        //                             precio: this.menuproductosdb.precio,
        //                             descripcion: this.menuproductosdb.descripcion,                                 
        //                     })
                    
        //             this.closeP()
        //             this.dialogAddP = false

        //             this.mostrarExito('Se cargó la información exitosamente')
        //         }              
        //         catch (error) {
        //             this.mostrarError('Ocurrió un error cargando la información')
        //         }
        //         finally {
        //             this.ocultarOcupado()
        //         }

        //     } else {

        //         let batch = db.batch()
        //         let pid = uuidv4()
                
        //         let menuproductosdb= {
        //             pid,
        //             nombre: this.menuproductosdb.nombre,
        //             unidad: this.menuproductosdb.unidad,
        //             precio: this.menuproductosdb.precio,
        //             descripcion: this.menuproductosdb.descripcion,
        //             grupo: this.menugpodb.grupo,
        //             elaboracion: this.menugpodb.elaboracion,
        //             uidP: this.usuarioHost.uid,
        //             gidP: this.menugpodb.gid       
        //         }


        //         batch.set(db.collection('usuarios').doc(this.usuarioHost.uid)
        //                     .collection('menuGpoDB').doc(this.menugpodb.gid)
        //                     .collection('menuProductosDB').doc(menuproductosdb.pid), menuproductosdb)    

        //         try {
        //             batch.commit()
        //             this.menuProductosDB.push(menuproductosdb)
                    
                    
        //             this.mostrarExito('Se cargó la información exitosamente')
        //         }
        //         catch (error) {
        //             this.mostrarError('Ocurrió un error cargando la información')
        //         }
        //         finally {
        //             this.ocultarOcupado()
        //         }
        //         this.closeP()
        //     }
        //     this.closeP()
        // },
        
        // async consultarProductosDB() {         

        //     try {
        //         let resultado = await db.collection('usuarios')
        //                                 .doc(this.usuarioHost.uid)
        //                                 .collection('menuGpoDB')
        //                                 .doc(this.menugpodb.gid)
        //                                 .collection('menuProductosDB')
        //                                 .get()                
                 
        //         resultado.docs.forEach(doc => {
                    
        //             let proddb = doc.data()

        //             // if  (this.menugpodb.uidG == this.usuarioHost.uid) { 

        //                 if (proddb.gidP == this.menugpodb.gid) {
                            
        //                     this.menuProductosDB.push(proddb)
        //                 }
        //             // }
        //         })
        //     }
        //     catch (error) {                
        //         this.mostrarError('Todavía no hay servicios registrados o la señal de WiFi está fallando. Inténtelo más tarde.')                
        //     }     
        // },
        editItemG (item) {
            this.editedIndexG = this.menuGpoDB.indexOf(item)
            this.menugpodb = Object.assign({}, item)
            this.dialogAddG = true
        }, 
        // editItemP (item) {
        //     this.editedIndexP = this.menuProductosDB.indexOf(item)
        //     this.menuproductosdb = Object.assign({}, item)
        //     this.dialogAddP = true
        // },
        async deleteItemG (item) {
            const index = this.menuGpoDB.indexOf(item)
            this.menuGpoDB.splice(index, 1)
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    this.menugpodb = Object.assign({}, item)

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('menuGpoDB')
                            .doc(this.menugpodb.gid)
                            .delete()                
                
                    this.mostrarExito('Se eliminó el servicio exitosamente')
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error borrando el servicio')                
            }
            finally {
                this.ocultarOcupado()
            }
            this.closeG()
        },
        async deleteItemP (item) {
            const index = this.menuProductosDB.indexOf(item)
            this.menuProductosDB.splice(index, 1)
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    this.menuproductosdb = Object.assign({}, item)

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('menuGpoDB')
                            .doc(this.menugpodb.gid)
                            .collection('menuProductosDB')
                            .doc(this.menuproductosdb.pid)
                            .delete()                
                
                    this.mostrarExito('Se eliminó el producto exitosamente')
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error borrando la información')                
            }
            finally {
                this.ocultarOcupado()
            }
            this.closeP()
        },
        closeG () {
            this.dialogAddG = false
            setTimeout(() => {
                this.menugpodb = Object.assign({}, this.defaultItemG)
                this.editedIndexG = -1
            }, 300)
        },
        // closeP () {
        //     this.dialogAddP = false
        //     setTimeout(() => {
        //         this.menuproductosdb = Object.assign({}, this.defaultItemP)
        //         this.editedIndexP = -1
        //     }, 300)
        // },
        async abrirGrupo(menugpodb) {    
            this.menuProductosDB = []
            this.menugpodb = menugpodb;
            await this.consultarProductosDB(); //con await?
            
        },
        async consulColaboradorBorrar() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                if (userName.uid != auth.currentUser.uid) {
                
                    if(this.colaborador[0].switchBorrar == true){
                        this.colaboradorBorrar = true
                    } else{
                        this.colaboradorBorrar = false
                    }
                } else {
                    this.colaboradorBorrar = true
                }
            }
        },
    }
    
}
</script>