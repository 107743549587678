<template>  
  <v-layout wrap align-center text-xs-center>
    <v-flex xs12 sm6 offset-sm3>
      <v-card flat class="mt-5 pt-5 transparent">
          <h1 class="display-4"><span class="primary--text logoFont font-weight-black">nu<span class="principal--text">Vin</span></span></h1>
      </v-card>
    </v-flex>
    <v-flex xs12 sm6 offset-sm3>
      <!-- <v-card flat>
        <v-card-text class="subheading my-5 logoFont">
          vende <strong class="logoFont"><span class="principal--text">Vi</span>r<span class="principal--text">t</span>ualmente</strong>  colabora <strong class="logoFont"><span class="principal--text">Vi</span>r<span class="principal--text">t</span>ualmente</strong>  administra <strong class="logoFont"><span class="principal--text">Vi</span>r<span class="principal--text">t</span>ualmente</strong>  
          trabaja <strong class="logoFont"><span class="principal--text">Vi</span>r<span class="principal--text">t</span>ualmente</strong>  organiza <strong class="logoFont"><span class="principal--text">Vi</span>r<span class="principal--text">t</span>ualmente</strong>  planea <strong class="logoFont"><span class="principal--text">Vi</span>r<span class="principal--text">t</span>ualmente</strong> 
          compra <strong class="logoFont"><span class="principal--text">Vi</span>r<span class="principal--text">t</span>ualmente</strong>
        </v-card-text>  
      </v-card>   -->
      <v-layout wrap align-center text-xs-center>
        <v-flex xs12>                    
          <h1 class="font-weight-light"><strong>aplicaciones</strong> en donde clientes, socios, colaboradores, proveedores, negocios, invitados, empresas, visitantes<strong> ...interactúan desde un mismo lugar</strong></h1><br>
          <!-- <span class="display-2"><strong class="logoFont">nu<span class="principal--text">Vin</span></strong><span class="font-weight-thin logoFont">app</span></span><br> -->
          <!-- <span class="logoFont">¿Quieres Visitar o Colaborar en una nuVin?</span><br><br> -->
          <!-- <v-btn
            outline 
            icon
            @click.stop="dialogSearch = true"
            >
            <v-icon>mdi-cloud-search-outline</v-icon>
          </v-btn>
          <v-dialog
            v-model="dialogSearch"
            max-width="290"
            >
            <v-toolbar
              color="white"
              >
              <v-toolbar-title></v-toolbar-title>
              <v-autocomplete
                v-model="select"
                :loading="loading"
                :items="items"
                :search-input.sync="search"
                cache-items
                class="mx-3"
                flat
                hide-no-data
                hide-details
                label="a qué nuVin quieres ir?"
                solo-inverted
              >ir</v-autocomplete>
              <button v-if="select != null" @click="irSearch" >Ir</button>
            </v-toolbar>        
          </v-dialog> -->
          <!-- <v-autocomplete
                v-model="select"
                :loading="loading"
                :items="items"
                :search-input.sync="search"
                cache-items
                class="mx-3 purple accent-1"
                flat
                hide-no-data
                hide-details
                label="a qué nuVin quieres ir?"
                dark
                solo-inverted
          ></v-autocomplete>
          <v-btn v-if="select != null" @click="irSearch" class="principal dark" fab small> <span class="text-none white--text">ir</span> </v-btn> -->
        </v-flex>
        <v-flex xs12>
          <div v-if="!usuario">
            <!-- <span class="logoFont">¿Ya tienes una nuVin?</span><br> -->
            <v-btn
              dark
              class="text-none white--text principal"
              round 
              flat
              :to="{ name: 'login' }"
              >
              <strong>Acceso Clientes</strong>
            </v-btn><br>
            <v-btn color="primary" @click="hidden = !hidden" class="text-none caption slow-transition" small round>
              {{ hidden ? 'webapp' : 'cerrar' }}
            </v-btn><br>
            <v-btn dark round class="text-none white--text grey darken-1" @click="openGiros()">Crea una nuVin</v-btn>
            <!-- <v-btn :to="{ path: '/guia-practica' }" flat color="transparent">
              <v-chip class="text-xs-center body-2 text-none font-weight-bold principal--text transparent">
                <v-avatar class="grey lighten-2"><strong>?</strong></v-avatar> 
                guía práctica
              </v-chip>
            </v-btn> -->
            <v-dialog v-model="dialogNego" fullscreen hide-overlay transition="dialog-bottom-transition">
              <template v-if="!dialogNego">
                <v-btn dark round class="text-none white--text principal" @click="openGiros()">Crea una nuVin</v-btn>
              </template>
              <v-card>
                <v-toolbar flat class="white logoFont">
                  <h3>Escoge entre cuenta personal (gratis) o giro de Negocio</h3>
                  <v-spacer></v-spacer>
                  <v-btn small class="principal caption text-none" round @click="closeDialogNego">
                    <span class="white--text">cerrar</span>
                  </v-btn>
                </v-toolbar>
                <v-container fluid>
                  <h3 class="white logoFont mt-3">app Gratuita (visitante, colaborador, cliente)</h3><br>
                  <v-layout align-start row wrap>
                    <div v-for="(item, index) in itemsApps" :key="index">
                      <v-flex v-if="item.app == 'personal'">
                          <v-layout column justify-center>
                            <div class="ficha-content">
                            <div class="custom-content">                                
                              <v-btn fab dark small color="principal">
                              <v-icon dark>{{ item.icon }}</v-icon>
                              </v-btn>
                              <span class="logoFont mt-3 text-none font-weight-medium white--text" style="font-size: 20px;">{{ item.app }}</span>
                              <div class="description-content text-none font-weight-light">{{ item.descripcion }}</div>                                
                            </div>
                            <v-divider></v-divider>
                            <v-btn small round class="text-none" :to="{ name: 'caracteristicas-aplicacion', params: {app: item.app } }">ver más...</v-btn>
                            </div>
                          </v-layout>
                      </v-flex>
                    </div>
                  </v-layout>
                  <v-divider></v-divider>
                  <h3 class="white logoFont mt-3">apps por Giro de Negocio</h3><br>
                  <v-layout justify-space-around row wrap>
                    <div v-for="(item, index) in itemsApps" :key="index">
                      <v-flex v-if="item.tipoApp == 'giro' && item.app != 'personal'">
                          <v-layout row justify-center>
                            <div class="ficha-content">
                            <div class="custom-content">
                              <v-btn fab dark small color="principal">
                              <v-icon dark>{{ item.icon }}</v-icon>
                              </v-btn>
                              <span class="logoFont mt-3 text-none font-weight-medium white--text" style="font-size: 20px;">{{ item.app }}</span>
                              <div class="description-content text-none font-weight-light">{{ item.descripcion }}</div>
                            </div>
                            <v-divider></v-divider>
                            <v-btn small round class="text-none" :to="{ name: 'caracteristicas-aplicacion', params: {app: item.app } }">ver más...</v-btn>
                            </div>
                          </v-layout>    
                      </v-flex>
                    </div>
                  </v-layout>
                  <v-divider></v-divider>
                  <!-- <h3 class="white logoFont mt-3">apps como Herramienta</h3><br> -->
                  <v-layout align-start row wrap>
                    <div v-for="(item, index) in itemsApps" :key="index">
                      <!-- <v-flex xs12 lg2 v-if="item.app && item.appComplemento.some(el => el == 'todas')"> -->
                      <v-flex xs12 v-if="item.tipoApp == 'herramienta' && item.app != 'personal' && item.app != 'concentrado-funciones'">
                        <v-btn
                          color="principal"
                          outline
                          dark
                          icon
                          :to="{ name: 'caracteristicas-aplicacion', params: {app: item.app } }"
                          >
                          <v-icon>{{ item.icon }}</v-icon>
                        </v-btn><span class="logoFont">{{ item.app }}</span>
                      </v-flex>
                    </div>
                  </v-layout>
                </v-container>              
              </v-card>
            </v-dialog>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>

    <!-- <v-flex xs12 md6>
      <v-card flat>
        <v-card-text class="my-5 text-xs-left ml-3">
          <span class="display-3 font-weight-black">desarrollamos</span><br>
          <span class="display-2 font-weight-light">aplicaciones software</span><br>
          <span class="display-1 font-weight-regular">prácticas <span class="headline">y</span> eficientes</span><br>  
          <span class="display-2 font-weight-bold"><span class="headline">para</span> empresas <span class="headline">y</span> negocios </span><br>
          <span class="display-2 font-weight-light">acompañados de</span><br>
          <span class="display-1 font-weight-regular"> consultoría <span class="headline">y/o</span> asesoría</span><br>
        </v-card-text>
      </v-card>
    </v-flex> -->
    
    <v-fab-transition>
      <v-dialog v-model="dialogtl0" fullscreen class="slow-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-show="!hidden" color="principal" dark absolute top left  class="mt-5 ml-5 big-square-button" v-on="on">
            <v-icon class="mt-1" large>{{ temasCards[0].icon }}</v-icon>
          </v-btn>
        </template>
        <v-card color="transparent">
          <v-container>
            <v-layout align-center justify-center column fill-height>
              <v-flex xs10 sm6 class="mt-5 pt-5">
                <v-card class="rounded-card mt-5 pt-5 elevation-10" max-width="500px" height="auto">
                  <v-btn color="principal" dark small class="mb-5 pb-5 square-button" top absolute left >
                    <v-icon class="mt-5" large>{{ temasCards[0].icon }}</v-icon>
                  </v-btn>
                  <v-card-title class="title grey lighten-2" >
                    {{ temasCards[0].title }}
                  </v-card-title>
                  <v-card-text>
                    {{ temasCards[0].content }}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="dialogtl0 = false" class="caption text-none">cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>                
        </v-card>                
      </v-dialog>
    </v-fab-transition>
    <v-fab-transition>
      <v-dialog v-model="dialogtl1" fullscreen class="slow-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-show="!hidden" color="primary" dark absolute top right  class="mt-5 mr-5 big-square-button" v-on="on">
            <v-icon class="mt-1" large>{{ temasCards[1].icon }}</v-icon>
          </v-btn>
        </template>
        <v-card color="transparent">
          <v-container>
            <v-layout align-center justify-center column fill-height>
              <v-flex xs10 sm6 class="mt-5 pt-5">
                <v-card class="rounded-card mt-5 pt-5 elevation-10" max-width="500px" height="auto">
                  <v-btn color="principal" dark small class="mb-5 pb-5 square-button" top absolute left >
                    <v-icon class="mt-5" large>{{ temasCards[1].icon }}</v-icon>
                  </v-btn>
                  <v-card-title class="title grey lighten-2" >
                    {{ temasCards[1].title }}
                  </v-card-title>
                  <v-card-text>
                    {{ temasCards[1].content }}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="dialogtl1 = false" class="caption text-none">cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>                
        </v-card>                
      </v-dialog>
    </v-fab-transition>
    <v-fab-transition>
      <v-dialog v-model="dialogtl2" fullscreen class="slow-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-show="!hidden" color="primary" dark absolute bottom left class="mb-5 ml-5 big-square-button" v-on="on">
            <v-icon class="mt-1" large>{{ temasCards[2].icon }}</v-icon>
          </v-btn>
        </template>
        <v-card color="transparent">
          <v-container>
            <v-layout align-center justify-center column fill-height>
              <v-flex xs10 sm6 class="mt-5 pt-5">
                <v-card class="rounded-card mt-5 pt-5 elevation-10" max-width="500px" height="auto">
                  <v-btn color="principal" dark small class="mb-5 pb-5 square-button" top absolute left >
                    <v-icon class="mt-5" large>{{ temasCards[2].icon }}</v-icon>
                  </v-btn>
                  <v-card-title class="title grey lighten-2" >
                    {{ temasCards[2].title }}
                  </v-card-title>
                  <v-card-text>
                    {{ temasCards[2].content }}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="dialogtl2 = false" class="caption text-none">cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>                
        </v-card>                
      </v-dialog>
    </v-fab-transition>
    <v-fab-transition>
      <v-dialog v-model="dialogtl3" fullscreen class="slow-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-show="!hidden" color="principal" dark absolute bottom right class="mb-5 mr-5 big-square-button" v-on="on">
            <v-icon class="mt-1" large>{{ temasCards[3].icon }}</v-icon>
          </v-btn>
        </template>
        <v-card color="transparent">
          <v-container>
            <v-layout align-center justify-center column fill-height>
              <v-flex xs10 sm6 class="mt-5 pt-5">
                <v-card class="rounded-card mt-5 pt-5 elevation-10" max-width="500px" height="auto">
                  <v-btn color="principal" dark small class="mb-5 pb-5 square-button" top absolute left >
                    <v-icon class="mt-5" large>{{ temasCards[3].icon }}</v-icon>
                  </v-btn>
                  <v-card-title class="title grey lighten-2" >
                    {{ temasCards[3].title }}
                  </v-card-title>
                  <v-card-text>
                    {{ temasCards[3].content }}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="dialogtl3 = false" class="caption text-none">cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>                
        </v-card>                
      </v-dialog>
    </v-fab-transition>
        
  </v-layout>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'
  import { db} from '@/firebase'
  // import Localbase from 'localbase'

  // let dbL = new Localbase('db')

  export default {
    data () {
      return {
        hidden: true,
        dialogtl0: false,
        dialogtl1: false,
        dialogtl2: false,
        dialogtl3: false,
        dialog: false,
        dialogSearch: false,
        dialogNego: false,
        dialogDescRest: false,
        dialogDescPlaz: false,
        dialogDescDespch: false,
        dialogServPro: false,
        dialogOrgEvent: false,
        dialogCom: false,
        dialogPersonal: false,
        items: [],
        search: null,
        select: null,
        loading: false,
        usuariosDB: [],
        itemsApps: [],
        temasCards: [
          {
            icon: "mdi-update",
            title: "Constantes actualizaciones e integración de nuevas funciones y herramientas",
            content: "Nuestra plataforma enfocada a productividad y empresas, se actualiza constantemente para ofrecerle las últimas funciones y herramientas para mejorar su eficiencia y productividad. Además, integramos nuevas funciones y aplicaciones a medida que surgen nuevas necesidades en el mercado."
          },
          {
            icon: "mdi-share",
            title: "Control y compartición de información",
            content: "Ofrecemos una base de datos histórica y un control de permisos para que pueda controlar quién tiene acceso a qué información en la cuenta de su empresa o perfil. También ofrecemos áreas de interacción para clientes autorizados y opciones de publicación opcionales para compartir información con ellos y/o al público en general. Además, nuestra plataforma le permite compartir información y autorizar la participación de colaboradores en las funciones que elija, dándole un control total sobre cómo muestra, administra y controla su negocio."
          },
          {
            icon: "mdi-account-multiple",
            title: "Servicio de atención personalizado y opciones de suscripción",
            content: "Nuestra plataforma ofrece un servicio de atención personalizada para aquellos que necesiten una atención más especializada o un seguimiento más cercano; este servicio cuenta con un costo adicional. Además, ofrecemos planes de suscripción mensual o anual que consideran el historial de datos, para que pueda elegir la opción que mejor se adapte a sus necesidades en cada una de las apps que utilice. También brindamos capacitación en el uso de las herramientas y la plataforma. Nuestra plataforma cuenta con la versatilidad de conectarse a través de APIs con otras aplicaciones y es escalable para crecer junto a su empresa."
          },
          {
            icon: "mdi-emoticon-happy",
            title: "Un ecosistema de trabajo relajado y flexible",
            content: "Nuestra plataforma se ha diseñado para ser utilizada de manera práctica, fácil e intuitiva, lo que significa que puede concentrarse en lo importante de su negocio en lugar de perder tiempo con tareas repetitivas y distractoras. Con nuestra plataforma, podrá distribuir su tiempo de manera balanceada entre el trabajo y lo personal."
          }
        ]
            
    // hacemos app por giro de negocio, servicio de asesoría y desarrollo del proyecto (de negocio o sistema) app digital, paquetes para agencias o asesores independientes -->
    //< apps extras, adicionales, extensiones, para complementar las app contratadas -->

    //<!-- *actualizacion de fnciones constantemnte, integracion de nuevas funciones, herramientas y apps constatemtne
    //*bases de datos historicas, control de permisos para colaboradores dentro de la app, area y opcion de de interaccion para clientes autorizados, area o informacion, publica opcional
    //*servicio de atención personalizado con costo
    //*donde trabajar es relajado, relax
    //*comparte información, autoriza particiacion en las apps o funciones que elijas 
    //*escoge la forma en que quieres mostrar, administrar, controlar, tu negocio
    //*forma de utilizar práctica, fácil, intuitiva
    //*todo lo necesario para que te enfoques en lo importante de tu negocio (clientes, productos) y no las repetitivas talacha que quitan tiempo y distraen
    //*podrás distribuir el tiempo de manera balanceada, entre el trabajo y lo personal
    //*planes: 7, 30, 90 o ilimitado dias de historial; funciones basicas, opcion de agregar funciones 
    //*costos (de actualizacion, licencias), compatibilidad con otros softwares, capacitacion (costos, tiempo), seguridad de la inforacion de la empresa y bd, escalabilidad -->
      }
    },
    computed: {
    ...mapState('sesion', ['usuario']),
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      }
    },
    created () {
      // this.consulMemberLink()
      this.ocultarOcupado()   
      // this.consultarUsuariosDB()
      // this.consultarNotificaciones()
    },
    methods: {
      ...mapMutations(['ocultarNotificacion', 'mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      // async consulMemberLink() {
      // await dbL.collection('memberLink').get().then(mLink => {
      //     this.select = mLink[0].userName
      //   })
      //   await dbL.collection('memberLink').delete()
      //   this.irSearch()
      // },
      closeDialogNego() {
        
        this.dialogNego = false
      },
      async openGiros() {
        this.itemsApps = []; // vaciar la matriz antes de llamar a getitemsApps
        this.getitemsApps()
        this.dialogNego = true
      },
      // async getitemsApps() {
      //   await dbL.collection('itemsApps').get().then(itGi => {
      //     this.itemsApps = itGi
      //   })
      // },
      async getitemsApps() {
        
        try {
          let resultado = await db.collection('itemsApps')
                                  .get()
          
          resultado.docs.forEach(doc => {
              let item = doc.data()
              this.itemsApps.push(item)    
          })

        }
        catch (error) {                
            this.mostrarError('Ocurrió un error consultando las apps.')                
        }
      },
      async consultarUsuariosDB() {         

        try {
            let resultado = await db.collection('userNames')
                                      .get()                
              
            resultado.docs.forEach(doc => {
                
                let usersdb = doc.data()
                // if  (this.grupodb.uidG == userName.uid) {                     
                    this.usuariosDB.push(usersdb.userName)    
                // }
              //   this.forSaleCat(catdb)
            })
        }
        catch (error) {                
            this.mostrarError('Ocurrió un error consultando los usuarios.')                
        }     
      },
      querySelections (v) {
          this.loading = true
          // Simulated ajax query
          setTimeout(() => {
            this.items = this.usuariosDB.filter(e => {
              return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
            })
            this.loading = false
          }, 500)      
       },
      // forceRerender() {
      // this.componentKey += 1;
      // },
      irSearch () {
        this.$router.push({ path: `/${this.select}`})
        this.dialogSearch = false
        this.select=[]
        // this.forceRerender()
      },
      irSearchUser () {
        this.$router.push({ path: `/${this.usuario.userName}/datos`})
        // this.dialogSearch = false, 
        // this.select=[]
        // this.forceRerender()
      },
    },
    
  }
  
</script>

<style>
  .ficha-content {
    border-radius: 10px;
    background-color: #CE93D8;
    /* padding: 10px; */
    text-align: left;
    width: 250px; /* Puedes ajustar el ancho máximo del cuadro según tus necesidades */
    max-width: 100%; /* Limita el ancho máximo del cuadro */
    max-height: 220px;
    overflow: hidden; /* Oculta el contenido que supere la altura máxima */
    position: relative;
  }

  .custom-content {
    max-height: 180px;
  }

  .description-content {
    margin-top: 10px;
    white-space: normal; /* Permite que el texto se ajuste automáticamente */
    word-wrap: break-word; /* Rompe las palabras largas para ajustarse al ancho del cuadro */
  }

  .logoFont {
    font-family: 'Comfortaa', cursive;
  }

  .txtfont {
    font-family: 'Roboto', sans-serif;
  }

  .big-square-button {
    /* Añade estilos para hacer el botón más grande y cuadrado */
    width: 68px;
    height: 68px;
    border-radius: 10px;
  }

  .square-button {
    width: 150px;
    height: 50px;
    border-radius: 10px;
  }

  .slow-transition {
    transition: .95s;
  }
  
  .rounded-button {
    /* Añade estilos para redondear los bordes del botón */
    border-radius: 50px;
  }

  .rounded-card {
    /* Añade estilos para redondear los bordes del botón */
    border-radius: 10px;
  }

  .rounded-top-button {
    /* Añade estilos para redondear los bordes superiores del botón */
    border-radius: 25px 15px 0 0;
  }
</style>