import { db, } from '@/firebase'
import router from '@/router'
import { mapMutations, } from 'vuex'

export default {
    namespaced: true,
    state: {
      saldoTotPorDinero: [],
      gastos: [],
      ingresos: []
    },
    mutations: {
        actualizarSaldoTotPordinero(state, saldoTotPorDinero) {
            state.saldoTotPorDinero = saldoTotPorDinero
        },
        
        actualizarIngresos(state, ingresos) {
            state.ingresos = ingresos
        },

        actualizarGastos(state, gastos) {
            state.gastos = gastos
        },
    },
    actions: {
      ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      async consultarGastos({commit, dispatch}) {  
        let userNameParametro = router.currentRoute.params.userName.toLowerCase()

        try {
            let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()
                let resultado = await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('gastos')
                                        .get()
                let gastosData = [];
                resultado.docs.forEach(doc => {
                    
                    let gast = doc.data()
                    gast.formatToIso = new Date(gast.fechaMili).toISOString().substr(0, 10);
                    gastosData.push(gast)
                })
                let gastos = gastosData.filter(elm => !elm.programado || elm.programado == false )
                commit('actualizarGastos', gastos) 
                
            }
        }
        catch (error) {                
            // this.mostrarError('Ocurrió un error consultando las gastos.')                
        }
        await dispatch('consultarIngresos');
      },
      async consultarIngresos({commit, dispatch}) {
        let userNameParametro = router.currentRoute.params.userName.toLowerCase()

        try {
            let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('ingresos')
                                            .orderBy('fechaMili', 'desc')
                                            .get()
                    let ingresos = [];
                    resultado.docs.forEach(doc => {                        
                        let ingr = doc.data()
                        ingresos.push(ingr)                        
                    })
                    commit('actualizarIngresos', ingresos)  
                }
        }
        catch (error) {     
            console.log(error)           
            // this.mostrarError('Ocurrió un error consultando los ingresos.')                
        }
        await dispatch('consultarSaldoTotPorDinero');
      },
      async consultarSaldoTotPorDinero({ commit, state }) {
        // Primero, creamos un objeto para almacenar los saldos por tipo de dinero
        let saldo = {};

        // Luego, sumamos todos los ingresos por tipo de dinero
        state.ingresos.forEach(ingreso => {
        if (!saldo[ingreso.tipoDinero]) {
            saldo[ingreso.tipoDinero] = 0;
        }
        saldo[ingreso.tipoDinero] += ingreso.monto;
        });

        // Después, restamos todos los gastos por tipo de dinero
        state.gastos.forEach(gasto => {
        if (!saldo[gasto.tipoDinero]) {
            saldo[gasto.tipoDinero] = 0;
        }
        saldo[gasto.tipoDinero] -= gasto.monto;
        });

        // Finalmente, convertimos el objeto a un array de objetos para poder desplegarlo en una tabla
        let saldoTotPorDinero = Object.keys(saldo).map(tipoDinero => {
        return {
            tipoDinero: tipoDinero,
            saldo: saldo[tipoDinero]
        };
        });

        commit('actualizarSaldoTotPordinero', saldoTotPorDinero)
        return saldoTotPorDinero;           
      },
    },
    getters: {
    }
}