// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, collection, getDocs, doc, getDoc, WriteBatch } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCF5WUW4mTefTaJ1cpF8yKwFWuTrULFLzs",
  authDomain: "nubesconbits.firebaseapp.com",
  databaseURL: "https://nubesconbits.firebaseio.com",
  projectId: "nubesconbits",
  storageBucket: "nubesconbits.appspot.com",
  messagingSenderId: "132982153111",
  appId: "1:132982153111:web:00bca45212413ea991aed2",
  measurementId: "G-JQG6R6PK9G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export {
  auth,
  db,
  storage,
  functions,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  collection,
  getDocs,
  doc,
  getDoc,
  ref,
  getDownloadURL,
  WriteBatch, 
  httpsCallable 
};
