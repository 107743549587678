
let lectorCodigoBarras = () => import( '../views/herramientasExtra/lectorCodigoBarras.vue')
let lectorCodigoVenta = () => import( '../views/herramientasExtra/lectorCodigoVenta.vue')
let reservoHabitacion = () => import( '../views/herramientasExtra/reservoHab/reservoHabitacion.vue')
let reservoHabAlPublico = () => import( '../views/herramientasExtra/reservoHab/reservoHabAlPublico.vue')
let reservoHabComprobante = () => import( '../views/herramientasExtra/reservoHab/reservoHabComprobante.vue')
let reservoHabCliente = () => import( '../views/herramientasExtra/reservoHab/reservoHabCliente.vue')
let reservoHabClienteEventos = () => import( '../views/herramientasExtra/reservoHab/reservoHabClienteEventos.vue')


let pagoTransferClienteDeUser = () => import( '../views/herramientasExtra/pagoTransferClienteDeUser.vue')
let proveedoresList = () => import( '../views/herramientasExtra/proveedoresHub/proveedoresList.vue')
let chatia = () => import( '../views/herramientasExtra/chatIA.vue')
let OpenAIApp = () => import('../views/OpenAIApp.vue')


export default [
    {
      path: '/:userName/chatia',
      name: 'chatia',
      component: chatia,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/tool/lector-codigo-venta/:comandaid',
      name: 'lector-codigo-venta',
      component: lectorCodigoVenta,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/herramienta/lector-codigo-barras',
      name: 'lector-codigo-barras',
      component: lectorCodigoBarras,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/ReservoHabitacion',
      name: 'reservoHabitacion',
      component: reservoHabitacion,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/reservoHab/:evento/reservaciones',
      name: 'reservoHabAlPublico',
      component: reservoHabAlPublico,      
      meta: { isPublic: true }
    },
    {
      path: '/reservoHab/reservaciones/comprobante',
      name: 'reservaciones-habitaciones-comprobante',
      component: reservoHabComprobante,
      meta: { isPublic: true }
    },
    {
      path: '/:userName/reservoHab/estatus-reservaciones/:evento',
      name: 'reservoHabCliente',
      component: reservoHabCliente,
      meta: { isPublic: true }
    },
    {
      path: '/:userName/reservoHab/eventos',
      name: 'reservoHabClienteEventos',
      component: reservoHabClienteEventos,
      meta: { isPublic: true }
    },
    {
      path: '/:userName/:appRef/pago-transferencia-electronica/:monto',
      name: 'pagoTransfer-cliente-user',
      component: pagoTransferClienteDeUser,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/proveedores-hub-list',
      name: 'proveedoresList',
      component: proveedoresList,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/openai-example',
      name: 'openai-example',
      component: OpenAIApp,
      meta: {
        autenticado: true
      }
    },
]