<template>
  <v-layout justify-center align-center wrap>
    <v-flex xs12 ml-3 mt-2>
      <v-btn icon dark>                
          <a @click="regresoExpeds()"><v-icon small dark left color="black">arrow_back</v-icon></a>
      </v-btn>
      <span class="body-2">expedientes</span>
    </v-flex>
    <v-flex xs12>
      <v-card class="elevation=5 ma-3">
        <v-layout>
          <v-flex xs12 class="text-xs-right">
              <v-dialog
                  v-model="dialogHelp"
                  width="500"
              >
                  <template v-slot:activator="{ on }">
                  <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                  </template>

                  <v-card>
                  <v-card-title class="headline">
                      Cómo funciona esta sección...
                  </v-card-title>

                  <v-card-text>
                      En esta área se agrega o cambia la Foto de Portada del Área Pública que al miso tiempo es 
                      la del "avatar" (foto circular que aparece en la parte superior izquierda de tu cuenta nuVin). <br>
                      <ul>
                          <li>En la parte inferior, al dar click en "Seleccionar imagen" se desplegará la ventana de tu dispositivo que dirige
                            a las carpetas que se manejan en él, dirígete a la (que por lo general es Galería o Imágenes) que contenga la imagen que deseas.
                          </li>
                          <li>
                              Cuando seleccionas la imagen, aparece en un recuadro para recortar si así lo deseas. Una vez que esté la imagen como deseas y dar
                              click en Guardar, la imagen se despliga de inmediato a la Foto de Portada y Avatar.
                          </li>
                      </ul> 
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                  </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout align-center justify-center column row fill-height wrap>
          <v-flex xs5>
            <v-card class="mt-2">
              <v-toolbar card>
                <v-toolbar-title>
                  <span v-if="vista == 1">Imagen Expediente Actual</span>
                  <span v-if="vista == 2" >Vista Previa</span>
                </v-toolbar-title>        
              </v-toolbar>
              <v-card-text class="text-xs-center">
                <v-img v-if="vista == 1 && imgStatus != false" :src="imgStatus" alt="imagen de Expediente"></v-img>
                <v-icon large v-if="imgExpLoad == '' && imgStatus == false && vista != 2">mdi-image</v-icon>
                <div v-if="vista == 2" ref="vistaPrevia" class="vistaPrevia"></div>
              </v-card-text>
              <v-card-text>
                <!-- <v-layout v-if="vista == 1" justify-center>
                  <v-btn color="secondary" v-if="usuario" :to="{ name: 'homePublicaUsuario', params: { userName: usuario.userName } }">Regresar</v-btn>
                </v-layout> -->
                <v-layout v-if="vista == 2" justify-center>
                  <v-flex xs6>
                    <v-btn v-if="usuario" :to="{ name: 'Entrada', params: { userName: usuario.userName } }">Cancelar</v-btn>
                  </v-flex>
                  <v-flex xs6>
                    <v-btn @click="guardarImagen" color="secondary">Guardar</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>      
          <v-flex xs5 mt-3>
            <v-card min-width="250px">
              <v-tabs icons-and-text grow>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab>
                  <span class="caption">Subir Imagen</span>
                  <v-icon>cloud_upload</v-icon>
                </v-tab>
                <v-tab-item>
                  <file-pond v-if="vista == 1" @addfile="cargarImagen" instant-upload="false" fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}" labelFileTypeNotAllowed="Agrega una imagen .png o .jpg" accepted-file-types="image/jpeg, image/png" label-idle="Da click aquí y selecciona o arrastra una imagen..."></file-pond>
                  <!-- <file-pond v-if="vista == 1 && fotosPortada.length >= 3" instant-upload="false" fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}" labelFileTypeNotAllowed="Agrega una imagen .png o .jpg" accepted-file-types="image/jpeg, image/png" label-idle="Has superado el mínimo de imágenes guardadas, borra alguna para poder agregar."></file-pond> -->
                  <v-responsive v-if="vista == 2">
                    <img ref="imagenOriginal" src="" alt="" class="edicionImagen">
                  </v-responsive>
                </v-tab-item>
                <!-- <v-tab v-if="fotosPortada.length > 1">
                  <span class="caption">Galería</span>
                  <v-icon>photo_library</v-icon>
                </v-tab> -->
                <!-- <v-tab-item v-if="fotosPortada.length > 1">
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm4 lg3 v-for="foto in fotosPortada" :key="foto.id">
                      <v-card class="ma-3">
                        <v-card-text>
                          <v-img :src="foto.url" alt="Foto Portada"></v-img>
                          <v-layout class="mt-3">
                            <v-flex xs6>
                              <v-layout justify-start>
                                <v-icon v-if="foto.fotoId == usuario.fotoPortada" color="green" medium>check_circle</v-icon>
                                <v-icon v-else color="grey" @click="seleccionarFotoPortada(foto.fotoId)" medium>check_circle_outline</v-icon>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6>
                              <v-layout justify-end>
                                <v-icon v-if="foto.fotoId != usuario.fotoPortada" medium @click="preguntarEliminarImagen(foto)">delete</v-icon>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-dialog v-model="preguntaEliminar" max-width="400" persistent>
                    <v-card>
                      <v-card-text class="title">
                        La imagen se eliminará permanentemente, ¿Deseas Continuar?
                      </v-card-text>
                      <v-btn color="secondary" flat @click="preguntaEliminar = false">Cancelar</v-btn>
                      <v-btn flat @click="eliminarImagen">Eliminar</v-btn>
                    </v-card>
                  </v-dialog>
                </v-tab-item> -->
              </v-tabs>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>    
  </v-layout>
</template>

<script>
import { db, storage, } from '@/firebase'
import { mapMutations, mapState, mapActions } from 'vuex'
import Localbase from 'localbase'

import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond/dist/filepond.min.css"

import Cropper from 'cropperjs/dist/cropper'
import 'cropperjs/dist/cropper.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType)
let dbL = new Localbase('db')

export default {
  components: { FilePond },
  data() {
    return {
      vista: 1,
      cropper: null,
      fotosPortada: [],
      imgExpLoad: '',
      preguntaEliminar: false,
      fotoEliminar: null,
      dialogHelp: false,
      cronoMomParam: '',
      idsExpeds: [
        {imgBefore: '',
        imgAfter: ''}
      ]
    }
  },
  created() {
    this.consulIdsExpeds()
    this.cronoMomParam = this.$route.params.cronoMoment
  },
  computed: {
    ...mapState('sesion', ['usuario']),   
    ...mapState('usuarioHost', ['usuarioHost']),
    imgStatus() {
        let x = ''
        if(this.cronoMomParam == 'imgBefore' && this.idsExpeds[0].imgBefore == '') {
            x = false
        } else if (this.cronoMomParam == 'imgBefore' && this.idsExpeds[0].imgBefore != '') {
            x = this.idsExpeds[0].imgBefore
        }

        if(this.cronoMomParam == 'imgAfter' && this.idsExpeds[0].imgAfter == '') {
            x = false
        } else if (this.cronoMomParam == 'imgAfter' && this.idsExpeds[0].imgAfter != '') {
            x = this.idsExpeds[0].imgAfter
        }

        return x

        
    }
  },
  methods: {
    ...mapMutations(['mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
    ...mapActions('sesion', ['actualizarFotoPortada']),
    async regresoExpeds() {
        await dbL.collection('idsExpeds').delete()
        this.$router.push({ name: 'clientes', params: { userName: this.usuarioHost.userName } })
    },
    async consulIdsExpeds() {
        await dbL.collection('idsExpeds').get().then(idsExp => {
            this.idsExpeds = idsExp
        })
    },
    cargarImagen (error, archivo) {
      if (error) {
        this.mostrarError('Ocurrió un error leyendo la imagen.')
        return
      }

      this.vista = 2

      let reader = new FileReader()

      let vm = this

      reader.onloadend = () => {
        vm.$refs.imagenOriginal.src = reader.result
        vm.recortarImagen()
      }

      if (archivo) {
        if (archivo.file) {
          reader.readAsDataURL(archivo.file)
        }
      }
    },
    recortarImagen() {
      this.cropper = new Cropper(this.$refs.imagenOriginal, {
        preview: this.$refs.vistaPrevia,
        aspectRatio: 1, //2.13,
        modal: false,
        guides: true,
        sized: false,
        zoomable: false,
        highlight: true,
        background: false,
        autoCrop: true
      })
    },
    async guardarImagen() {
      this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Actualizando imagen de expediente...' })
      let userNameParametro = this.$route.params.userName.toLowerCase()
      let userNameDoc = await db.collection('userNames')
                                  .doc(userNameParametro)
                                  .get()

      if(userNameDoc.exists) {
        let userName = userNameDoc.data()
    
        let canvas = this.cropper.getCroppedCanvas()
        let imagen = canvas.toDataURL('image/jpeg')

        try {
          let ref = storage.ref()

          let resultado = await ref.child(`usuarios/${userName.uid}/clientes/${this.idsExpeds[0].cliid}/eventos-citas/${this.idsExpeds[0].cliEvid}/cronoMoment/${this.cronoMomParam}`)
                  .putString(imagen, 'data_url')

          let url = await resultado.ref.getDownloadURL()

          if (this.cronoMomParam == 'imgBefore') {
              await db.collection('usuarios')
              .doc(userName.uid)
              .collection('clientes')
              .doc(this.idsExpeds[0].cliid)
              .collection('eventos-citas')
              .doc(this.idsExpeds[0].cliEvid)
              .update({imgBefore: url})    
          } else if ( this.cronoMomParam == 'imgAfter') {
              await db.collection('usuarios')
              .doc(userName.uid)
              .collection('clientes')
              .doc(this.idsExpeds[0].cliid)
              .collection('eventos-citas')
              .doc(this.idsExpeds[0].cliEvid)
              .update({imgAfter: url})    
          }        
          this.regresoExpeds()
        }
        catch (error) {
          this.mostrarError('Ocurrió un error almacenando la imagen.')
        }
        finally {
          this.ocultarOcupado()
        }
      }
    },
  }
}
</script>

<style>
  
.filepond--drop-label {
  font-size: 14pt !important;
}

.edicionImagen {
  width: 100%;
}

.vistaPrevia {
  width: 320px;
  height: 150px;
  overflow: hidden;
}
</style>
