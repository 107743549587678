<template>
<!-- Este componente es para Reservo Habitacion -->
<div>
    <v-flex xs12 ml-3 mt-2>
        <v-btn icon  dark v-if="usuarioHost" :to="{ name: 'Entrada', params: {userName: usuarioHost.userName} }">
            <v-icon small dark left color="black">arrow_back</v-icon>
        </v-btn>
        <span class="body-2">área pública</span>
    </v-flex>
    <div
        id="e3"
        style="max-width: 400px; margin: auto;"
        class="grey lighten-3"
    >
        
        <template v-if="eventoReservaciones.length == 0">
            <v-alert :value="true" color="white">
                <v-icon class="mr-3">mdi-message</v-icon>
                <span class="text-xs-center black--text">Por el momento no tienes Comprobantes de algún servicio en esta nuVin.</span>
            </v-alert>
        </template>
        <v-toolbar
        color=""
        >
        <v-toolbar-title>Estatus Comprobantes</v-toolbar-title>
        <v-spacer></v-spacer>
        </v-toolbar>

        <div >
            <v-card v-for="item in eventoReservaciones" :key="item.fotoPagoId">
                <v-card-title class="headline">Comprobante de Reservación de Habitación</v-card-title>
                <v-card-text v-if="item.autor == usuario.userName">
                    <span class="font-weight-black">Quien reservó:</span> {{ item.autor }}<br>
                    <span class="font-weight-black">email:</span> {{ item.emailAutor }}<br>
                    <span class="font-weight-black">Fecha:</span> {{ item.fecha }}<br>
                    <span class="font-weight-black">Estatus Pago:</span> {{ item.estatusPago }}<br>
                    <span class="font-weight-black">Estancia:</span> {{ item.fichaHabitacion.nombreEstancia }}<br>
                    <span class="font-weight-black">Nombre de habitación:</span> {{ item.fichaHabitacion.nombreTipoHab }}<br>
                    <span class="font-weight-black">Descripción:</span> {{ item.fichaHabitacion.descripcionHab }}<br> 
                    <span class="font-weight-black">Capacidad:</span> {{ item.fichaHabitacion.personasHab }} personas<br>
                    <span class="font-weight-black">Precio:</span> ${{ item.fichaHabitacion.precioHab }} por noche<br>
                    <span class="font-weight-black"># noches:</span> {{ item.nochesHabitacion }}<br>
                    <span class="font-weight-black">Monto total:</span> {{ item.montoTransfer }}<br>
                </v-card-text>
                <v-spacer></v-spacer>
                <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" flat @click="dialogFicha = false" class="text-none">cerrar</v-btn>
                </v-card-actions> -->
            </v-card>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { db } from '@/firebase'

export default {
    data: () => ({
        eventoReservaciones: [],
    }),

    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
    },

    created() {
        this.consultarReservaciones()
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        async consultarReservaciones () {
            let userNameParametro = this.$route.params.userNameHost.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()

                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('clientes')
                                            .where('autor', '==', this.usuario.userName)
                                            .where('appExtra', '==', 'ReservoHabitacion')
                                            // .collection('comprasAppExtra')
                                            // .doc('ReservoHabitacion')
                                            // .collection('reservaciones')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let evestcs = doc.data()                   
                        this.eventoReservaciones.push(evestcs)    
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Hay un problema con el internet favor de intentar más tarde.')              
            }
        },
    },   
}
</script>