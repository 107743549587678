<template> 
    <v-layout justify-center align-center wrap>
        <v-flex xs12 v-if="dialogProgress == false">
            <v-card class="elevation=5 ma-3 py-3 custom-card" >
                <div class="text-xs-right">
                    <v-dialog
                        v-model="dialogHelp"
                        width="500"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                        </template>

                        <v-card>
                        <v-card-title class="headline">
                            Cómo funciona esta sección...
                        </v-card-title>

                        <v-card-text>
                            Se muestran los SALDOS principales, en un rango de Fechas. Para tener un panorama general del negocio o 
                            proyecto o finanzas personales.
                            <ul>
                                <li>Selecciona una FECHA INICIAL y una FECHA FINAL, posteriormete da click en CONSULTAR. 
                                </li>
                                <li>
                                    Posteriormente se reflejan los subtotales, en los campos ubicados en la parte inferior.
                                </li>
                                <li>
                                    En la parte inferior está el botón de etiquetas, al dar click se despliegan todas las etiquetas
                                    de Gastos, Ingresos, Dinero y Créditos/Préstamos, del rango de fecha que seleccionaste.
                                </li>
                            </ul> 
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <v-card>
                    <v-layout column>
                        <v-layout row class="mb-3">
                            <v-flex xs2 class="text-xs-left ml-4">
                                <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-chart-gantt</v-icon>                
                            </v-flex>
                            <v-flex xs8 class="text-xs-left">
                                <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Saldos por rango de fechas:</p></h3>
                                <h3 class="hidden-sm-and-up"><p class="text-xs-center title mt-3">Saldos por rango <br> de fechas:</p></h3>
                            </v-flex> 
                        </v-layout>
                        <v-layout row wrap class="text-xs-center">
                            <v-flex xs12 sm6 md4 class="pl-3">
                                <v-menu
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                max-width="290px"
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                    v-model="dateFormatted1"
                                    label="Fecha inicial"
                                    hint="DD/MM/YYYY formato"
                                    persistent-hint
                                    prepend-icon="event"
                                    @blur="date1 = parseDate1(dateFormatted1)"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date1" no-title @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                                <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                max-width="290px"
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                    v-model="dateFormatted2"
                                    label="Fecha Final"
                                    hint="DD/MM/YYYY formato"
                                    persistent-hint
                                    prepend-icon="event"
                                    @blur="date2 = parseDate2(dateFormatted2)"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date2" no-title @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                        <div class="text-xs-center">
                            <v-btn small @click="consultasCyNV" :style="{'background-color': paletaColores.primero}" dark class="caption elevation-13 text-none" v-if="this.dateMili1 <= this.dateMili2">Consultar</v-btn><br>
                            <v-tooltip top v-if="this.dateMili1 > this.dateMili2">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="grey lighten-1" small class="caption text-none">Consultar</v-btn><br>
                                </template>
                                <span>Escoge una fecha Inicial menor a la Final</span> 
                            </v-tooltip>                        
                        </div>
                        <v-flex xs6 class="subheading ml-2">
                            <!-- (ingresos por ventas sin IVA): <br> -->
                           
                            <!-- <span class="subheading">Ingresos por Ventas: <strong class="green--text">$ {{ sumPedsPagados.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                            <span class="subheading">IngrsXVtas. extrayendo Costo-Producto: <strong class="green--text">$ {{ sumPedsPagados - sumCostoPedsPagados }}</strong></span><br> -->
                            <span class="subheading">Ingresos: <strong class="green--text">$ {{ sumIngrsDivrs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                            <span class="subheading">Gastos: <strong class="red--text">$ {{ sumGstsDivrs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                            <span class="subheading">Saldo(Ingresos - Gastos): <strong :class=" saldoTot >= 0 ? 'green--text' : 'red--text' ">$ {{ saldoTot.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                            <span class="subheading">Préstamos y Créditos: <strong class="grey--text">$ {{ sumInversYcreds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>                                                                                  
                        </v-flex><br>
                        <template>
                            <template >
                                <v-btn :style="{'background-color': paletaColores.primero}" dark small @click="showEtiqs" 
                                        class="caption text-none elevation-13"><v-icon class="mr-2">mdi-tag-outline</v-icon>saldos por etiquetas</v-btn>
                            </template>
                            <v-layout row justify-center>
                                <v-dialog v-model="dialogEtiquetas" fullscreen hide-overlay transition="dialog-bottom-transition">                                
                                <v-card>
                                    <v-toolbar dark :style="{'background-color': paletaColores.primero}">
                                    <v-btn icon dark @click="closeSetqs">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Saldos de Etiquetas</v-toolbar-title><span>&nbsp; del {{ dateFormatted1 }} al {{ dateFormatted2 }}</span>
                                    <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <template>
                                        <v-layout align-center justify-center row fill-height>
                                        <v-flex xs12 sm10 md6>
                                            <v-card class="mt-3 elevation-3">
                                                <v-card-title class="subheading font-weight-bold white--text" :style="{'background-color': paletaColores.segundo}">Etiquetas de Gasto</v-card-title>
                                                <v-data-table
                                                :headers="headersEtiq"
                                                :items="montosXetiquetasG"
                                                class="elevation-3"
                                                >
                                                    <template v-slot:items="props">
                                                    <td><strong>{{ props.item.etiqueta }}</strong></td>
                                                    <td class="text-xs-right">{{ props.item.monto.toFixed(2) }}</td>
                                                    <!-- <td class="text-xs-right">{{ props.item.tipoEtiq }}</td> -->
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </v-flex>
                                        </v-layout>                                    
                                    </template>
                                    <template>
                                        <v-layout align-center justify-center row fill-height>
                                        <v-flex xs12 sm10 md6>
                                            <v-card class="mt-3 elevation-3">
                                                <v-card-title class="subheading font-weight-bold white--text" :style="{'background-color': paletaColores.segundo}">Etiquetas de Ingreso</v-card-title>
                                                <v-data-table
                                                :headers="headersEtiq"
                                                :items="montosXetiquetasI"
                                                class="elevation-3"
                                                >
                                                    <template v-slot:items="props">
                                                    <td><strong>{{ props.item.etiqueta }}</strong></td>
                                                    <td class="text-xs-right">{{ props.item.monto.toFixed(2) }}</td>
                                                    <!-- <td class="text-xs-right">{{ props.item.tipoEtiq }}</td> -->
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </v-flex>
                                        </v-layout>                                    
                                    </template>
                                    <template>
                                        <v-layout align-center justify-center row fill-height>
                                        <v-flex xs12 sm10 md6>
                                            <v-card class="mt-3 elevation-3">
                                                <v-card-title class="subheading font-weight-bold white--text" :style="{'background-color': paletaColores.segundo}">Etiquetas de Dinero</v-card-title>
                                                <v-data-table
                                                :headers="headersEtiq"
                                                :items="montosXetiquetasGDID"
                                                class="elevation-3"
                                                >
                                                    <template v-slot:items="props">
                                                    <td><strong>{{ props.item.etiqueta }}</strong></td>
                                                    <td class="text-xs-right">{{ props.item.monto.toFixed(2) }}</td>
                                                    <!-- <td class="text-xs-right">{{ props.item.tipoEtiq }}</td> -->
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </v-flex>
                                        </v-layout>                                    
                                    </template>
                                </v-card>
                                </v-dialog>
                            </v-layout>
                        </template>
                        <v-divider></v-divider><br>
                        <v-flex xs6>
                            <span class="subheading font-weight-medium pl-2 pt-4">Saldos desde inicio de operaciones:</span>
                        </v-flex><br>
                        <v-flex xs6 class="subheading ml-2">
                            <!-- (ingresos por ventas sin IVA): <br> -->
                            <!-- <span class="subheading">Pendientes de Pago: $ {{ sumPedsNoPagados }}</span><br> -->
                            <!-- <span class="subheading">Ingresos por Ventas: <strong class="green--text">$ {{ sumPedsPagadosTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                            <span class="subheading">IngrsXVtas. extrayendo Costo-Producto: <strong class="green--text">$ {{ sumPedsPagadosTot - sumCostoPedsPagadosTot }}</strong></span><br> -->
                            <span class="subheading">Ingresos: <strong class="green--text">$ {{ sumIngrDivrsTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                            <span class="subheading">Gastos: <strong class="red--text">$ {{ sumGstsDivrsTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>
                            <span class="subheading">Saldo(Ingresos - Gastos): <strong :class=" saldoTot >= 0 ? 'green--text' : 'red--text' ">$ {{saldoInitOp.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</strong></span><br>
                            <span class="subheading">Préstamos y Créditos: <strong class="grey--text">$ {{ sumInversYcredsTot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</strong></span><br>                                                                                  
                        </v-flex>
                        <v-divider></v-divider><br>
                        <template>
                            <v-layout align-center justify-center row fill-height>
                            <v-flex xs12 sm10 md6>
                                <v-card class="mt-3 elevation-3">
                                    <v-card-title class="subheading font-weight-bold white--text" :style="{'background-color': paletaColores.segundo}">Etiquetas de Dinero</v-card-title>
                                    <v-data-table
                                    :headers="headersEtiq"
                                    :items="saldoTotPorDinero"
                                    class="elevation-3"
                                    >
                                        <template v-slot:items="props">
                                            <td><strong>{{ props.item.tipoDinero }}</strong></td>
                                            <td class="text-xs-right">{{ props.item.saldo.toFixed(2) }}</td>
                                            <td class="justify-center align-center layout">
                                                <!-- traspaso entre ctas DATOS -->
                                                <v-flex xs12 sm6 >
                                                    <v-dialog v-model="dialogTraspaso" full-width max-width="500px" persistent>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn class="hidden-xs-only text-none elevation-13" icon small
                                                                :style="{'background-color': paletaColores.primero}" dark 
                                                                v-on="on" @click="selectedItem = props.item"><v-icon >mdi-transfer</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title>
                                                                <span class="headline">Traspaso entre Cuentas</span>
                                                            </v-card-title>
                                                            <v-card-title>
                                                                <span class="subheading">Desde <span class="title">{{ selectedItem ? selectedItem.tipoDinero : '' }}</span> con saldo <span class="title">{{ selectedItem ? selectedItem.saldo.toFixed(2) : '' }}</span></span>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-container grid-list-md>
                                                                <v-layout wrap >
                                                                    <!-- <v-flex xs12 sm6 md4>
                                                                        <v-text-field v-model="ingreso.proveniente" label="de dónde proviene (referencia)" @blur="$v.ingreso.proveniente.$touch()" :error-messages="erroresProveedor"></v-text-field>
                                                                    </v-flex> -->
                                                                    <!-- <v-flex xs12 sm6 md4>
                                                                        <v-select @blur="$v.ingreso.tipoIngreso.$touch()" :error-messages="erroresTipoIngreso"
                                                                        v-model="ingreso.tipoIngreso"
                                                                        :items="etiquetasIngreso"
                                                                        label="Subgrupo de ingreso (etiqueta-Ingreso)" 
                                                                        ></v-select>                                        
                                                                    </v-flex> -->
                                                                    <v-flex xs12>
                                                                        <v-select @blur="$v.ingreso.tipoDinero.$touch()" :error-messages="erroresTipoDinero"
                                                                        v-model="ingreso.tipoDinero"
                                                                        :items="etiquetasDineroFiltradas"
                                                                        label="A dónde se enviará el traspaso (etiqueta-Dinero)"
                                                                        ></v-select>                                        
                                                                    </v-flex>
                                                                    <!-- <v-flex xs12 sm6 md4> -->
                                                                        <!-- <v-text-field type="text" v-model="ingreso.fechaDocumento" label="Fecha de documento" locale="es-MX"></v-text-field> -->
                                                                        <!-- <v-menu
                                                                            ref="menu1"
                                                                            v-model="menu1"
                                                                            :close-on-content-click="false"
                                                                            :nudge-right="40"
                                                                            lazy
                                                                            transition="scale-transition"
                                                                            offset-y
                                                                            full-width
                                                                            max-width="290px"
                                                                            min-width="290px"
                                                                            >
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-text-field
                                                                                v-model="dateFormatted"
                                                                                label="Fecha"
                                                                                persistent-hint
                                                                                prepend-icon="event"
                                                                                @blur="updateFechaFactura"
                                                                                v-on="on"
                                                                                ></v-text-field>
                                                                            </template>
                                                                            <v-date-picker v-model="ingreso.fechaDocumento" no-title @input="menu1 = false"></v-date-picker>
                                                                        </v-menu> -->
                                                                    <!-- </v-flex> -->
                                                                    <v-flex xs12>
                                                                        <v-text-field type="number" v-model="ingreso.monto" label="Monto" @blur="$v.ingreso.monto.$touch()" :error-messages="erroresMonto"></v-text-field>
                                                                    </v-flex>
                                                                        <!-- :rules="rules" -->
                                                                    <v-flex xs12>
                                                                        <v-text-field
                                                                            v-model="ingreso.notas"
                                                                            counter="25"
                                                                            label="Notas"
                                                                            outline
                                                                            @blur="$v.ingreso.numDocumento.$touch()" :error-messages="erroresNumDocumento"
                                                                        ></v-text-field>
                                                                    </v-flex>
                                                                </v-layout>
                                                                </v-container>
                                                            </v-card-text>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn color="primary" flat @click="closeDialogTr">Cancelar</v-btn>
                                                                <v-btn color="primary" :depressed="$v.ingreso.$invalid" :disabled="$v.ingreso.$invalid" flat @click="saveTraspaso(selectedItem.tipoDinero)">Guardar</v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                </v-flex>
                                            </td>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-flex>
                            </v-layout>                                    
                        </template>
                    </v-layout>
                </v-card>                
                <v-card>
                    <!-- <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title> -->
                    <!-- <v-data-table 
                        :headers="headers"
                        :items="ingrsygsts"
                        :search="search"                 
                        class="elevation-1"   
                        >
                        <template v-slot:items="props">
                            <td class="text-xs-right">{{ props.item.fecha }}</td>
                            <td>{{ props.item.cliente }}</td>
                            <td class="text-xs-right">{{ props.item.estatusPago }}</td>
                            <td class="text-xs-right">{{ props.item.hora }}</td>
                            <td class="text-xs-right">{{ props.item.formaPedido }}</td>
                            <td class="text-xs-right">{{ props.item.comid.substr(0, 10) }}</td>
                            <td class="justify-center align-center layout">
                                <v-dialog v-model="dialog" persistent max-width="600px">                            
                                    <template v-slot:activator="{ on }">                                
                                        <v-icon
                                            @click="abrirComanda(props.item)"
                                            dark v-on="on"
                                            color="primary"
                                            class="mr-2"
                                            small
                                            >
                                            book
                                        </v-icon>
                                    </template>
                                    <v-card  
                                        v-if="comanda"
                                        :key= "comanda.comid"
                                        :nombre="comanda.cliente"
                                        
                                        >
                                        <v-toolbar flat color="white">
                                                <h3 class="title"> {{comanda.cliente}} </h3>                                        
                                            <v-divider
                                                class="mx-2"
                                                inset
                                                vertical
                                            ></v-divider>
                                            <v-spacer></v-spacer>
                                            <v-btn icon small fab class="hidden-sm-and-up text-xs-right" color="transparent" @click="emptyFields">
                                                <v-icon >close</v-icon>   
                                            </v-btn>
                                            <v-btn outline class="hidden-xs-only" color="primary" flat @click="emptyFields">Cerrar</v-btn>
                                        </v-toolbar>
                                        <v-data-table 
                                            :headers="headers2"
                                            :items="pedidosPorComanda"
                                            class="elevation-1"                    
                                            >
                                            <template v-slot:items="props">
                                                <td>{{ props.item.producto.nombre }}</td>
                                                <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                <td class="text-xs-center">{{ props.item.producto.precio }}</td>
                                                <td class="text-xs-right">{{ props.item.producto.unidad }}</td>
                                                <td class="text-xs-center">$ {{ props.item.pxq }}</td>                                                     
                                            </template>
                                        </v-data-table>   
                                        <template>
                                            <v-layout row wrap>
                                                <v-flex xs12>
                                                    <v-card color="blue-grey" class="white--text text-xs-right">
                                                        <span class="subheading mr-1">Sub-Total: $ {{ subTotPorComanda }}</span><br>
                                                        <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                                        <span class="subheading mr-1">Total: $ {{ totSum }}</span>
                                                    </v-card>
                                                </v-flex>                                   
                                            </v-layout>
                                        </template>                    
                                    </v-card>      
                                </v-dialog>
                            </td> -->
                            <!-- <td class="text-xs-right">{{ props.item.estatus }}</td>
                            <td class="text-xs-right">{{ props.item.mesero.userName }}</td>
                            <td class="text-xs-right">{{ props.item.formaPedido }}</td>
                            <td class="text-xs-right">{{ props.item.notas }}</td> -->
                            
                            <!-- <td class="text-xs-right">{{ props.item.pxq }}</td> -->
                                        
                        <!-- </template>
                        <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                            </v-alert>
                        </template>
                    </v-data-table> -->
                </v-card>
            </v-card>
        </v-flex>

        <template v-else>
            <div class="mt-5 pt-5" >
                <div class="text-xs-center mt-5 pt-5">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    :style="{'color': paletaColores.primero}"
                    indeterminate
                    class="mt-5 pt-5"
                    ></v-progress-circular>
                </div>
            </div>                  
        </template>
    </v-layout>
</template>

<script>
import { db } from '@/firebase'
import { collection, collectionGroup, getDocs, doc, getDoc, orderBy, query, where, writeBatch } from 'firebase/firestore';
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapMutations, mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {

    data: () => ({
        selectedItem: null, // Agrega esta línea
        headersEtiq: [
          { text: 'Etiqueta', align: 'center', value: 'etiqueta', sortable: false, },     
          { text: 'Saldo', align: 'rigth', value: 'monto', sortable: false, },
          { text: 'Acciones', align: 'center', sortable: false, },
        ],
        date1: new Date().toISOString().substr(0, 10),
        date2: new Date().toISOString().substr(0, 10),
        dateFormatted1: new Date().toLocaleDateString('es-MX', { timeZone: 'America/Mexico_City', year: 'numeric', month: '2-digit', day: '2-digit' }),
        dateFormatted2: new Date().toLocaleDateString('es-MX', { timeZone: 'America/Mexico_City', year: 'numeric', month: '2-digit', day: '2-digit' }),
        dateMili1: '',
        dateMili2: '',
        dateMili2Starts: '',
        dialogTraspaso: false,
        dialog: false,
        dialog2: false,
        dialogHelp: false,
        dialogEtiquetas: false,
        dialogProgress: true,
        menu1: false,
        menu2: false,
        modal: false,        
        subTotPagado: 0,
        search: '',
        arrayfilterG: [],
        arrayfilterI: [],
        arrayfilterGD: [],
        arrayfilterID: [],
        arrayfilterGDID: [],
        montosXetiquetasG: [],
        montosXetiquetasI: [],
        montosXetiquetasGD: [],
        montosXetiquetasID: [],
        montosetiqs: [],
        montosXetiquetasGDID: [],
        colaborador: [],
        ingrsygsts: [],
        notasdeventa: [],
        comandas: [],
        comandasTot: [],
        saldoTotPorDinero: [],
        gastosRango: [],
        gastosTot: [],
        gastosData: [],
        ingresosRango:[],
        ingresosTot: [],
        inversYcreds: [],
        inversYcredsTot: [],
        comsNoPags: [],
        comsPags: [],
        pedidoTot: [],
        pedidoTotTot: [],
        pedidosPagados: [],
        pedidosNoPagados: [],
        pedidosPagadosTot: [],
        etiquetasFzsDB: [],
        etiquetasIngreso: [],
        etiquetasDinero: [],
        comanda: {
            cliente: '',
            mesero: {
                userName: '',
            },
            // fecha: new Date().toISOString().substr(0, 10),
            zona: '',
            mesa: '',
            comid: '',
            notas: '',            
            pedidoTot: [''],
            estid: '',
            estatusPago: 'Pendiente de Pago'
        },
        ingreso: {
            proveniente: '',
            autor: {
                userName: ''
            },
            fechaMili: '',
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaDocumento: null,
            tipoDinero: '',
            tipoIngreso: '',
            numDocumento: '',
            monto: '',
            ingrid: '',
            notas: '',            
            gastoPartidas: [''],
        },
        defaultItem: {
            proveniente: '',
            autor: {
                userName: ''
            },
            fechaMili: '',
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaDocumento: null,
            tipoDinero: '',
            tipoIngreso: '',
            numDocumento: '',
            monto: '',
            ingrid: '',
            notas: '',            
            gastoPartidas: [''],
        },
        pedidosPorComanda: [],
        headers: [
          { text: 'Fecha', value: 'fecha' },
          {
            text: 'Cliente',
            align: 'left',
            value: 'cliente'
          },
          { text: 'Estado-Pago', value: 'estatusPago' },
          { text: 'Hora', value: 'hora' },
          { text: 'Tipo-Pedido', value: 'formaPedido' },
          { text: '#Comanda', value: 'comid' },
          { text: 'Consulta', value: '', align: 'center' },
        //   { text: 'Cliente', value: 'cliente' },
        //   { text: 'Estatus', value: 'estatus' },
        //   { text: 'Mesero', value: 'mesero' },
        //   { text: 'Local/Auto-Pedido', value: 'formaPedido' },
        //   { text: 'Notas', value: 'notas' },
        ],
        headers2: [
          {
            text: 'Producto',
            align: 'left',
            value: 'nombre'
          },
          { text: 'Cant', value: 'cantidad', align: 'center', },
          { text: '$Precio', value: 'precio' },
          { text: 'Unidad', value: 'unidad', align: 'center', },
          { text: 'Sub-Total', value: 'pxq' },
        ],
    }),
    created() { 
      this.consultarWhereFrom()
      this.actualizarPerfilAuth()
      this.consultarComandasTot()      
      this.consultaretiquetasFzsDB()
    },
    validations: {
        ingreso: {
            // proveniente: {
            //     required,
            //     minLength: minLength(3),
            //     maxLength: maxLength(20),
            // },
            numDocumento: {
                maxLength: maxLength(20),
            },
            monto: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(20),
            },
            // tipoIngreso: {
            //     required,
            // },
            tipoDinero: {
                required,
            },
        },
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        etiquetasDineroFiltradas() {
            if (!this.selectedItem) {
            return this.etiquetasDinero;
            }
            return this.etiquetasDinero.filter(etiqueta => etiqueta !== this.selectedItem.tipoDinero);
        },
        erroresTipoDinero() {
            let errores = []
            if (!this.$v.ingreso.tipoDinero.$dirty) { return errores }
            if (!this.$v.ingreso.tipoDinero.required) { errores.push('Selecciona tipo de Ingreso.') }
            return errores
        },
        erroresNumDocumento() {
            let errores = []
            if (!this.$v.ingreso.numDocumento.$dirty) { return errores }
            if (!this.$v.ingreso.numDocumento.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresMonto() {
            let errores = []
            if (!this.$v.ingreso.monto.$dirty) { return errores }
            if (!this.$v.ingreso.monto.required) { errores.push('Ingresa el monto.') }
            if (!this.$v.ingreso.monto.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.ingreso.monto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresProducto() {
            let errores = []
            if (!this.$v.partida.nombreProducto.$dirty) { return errores }
            if (!this.$v.partida.nombreProducto.required) { errores.push('Ingresa el nombre del producto.') }
            if (!this.$v.partida.nombreProducto.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            if (!this.$v.partida.nombreProducto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresUnidad() {
            let errores = []
            if (!this.$v.partida.unidad.$dirty) { return errores }
            if (!this.$v.partida.unidad.required) { errores.push('Ingresa el unidad del producto.') }
            if (!this.$v.partida.unidad.minLength) { errores.push('Ingresa al menos 2 caracteres.') }
            if (!this.$v.partida.unidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresPrecio() {
            let errores = []
            if (!this.$v.partida.precio.$dirty) { return errores }
            if (!this.$v.partida.precio.required) { errores.push('Ingresa el precio.') }
            if (!this.$v.partida.precio.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.precio.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresCantidad() {
            let errores = []
            if (!this.$v.partida.cantidad.$dirty) { return errores }
            if (!this.$v.partida.cantidad.required) { errores.push('Ingresa la cantidad de unidades.') }
            if (!this.$v.partida.cantidad.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.cantidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        computedDateFormatted1 () {
            return this.formatDate1(this.date1)
        },
        computedDateFormatted2 () {
            return this.formatDate2(this.date2)
        },
        sumPedsPagados() {

            var i;
            var sum = 0;
            for ( i=0; i < this.pedidosPagados.length; i++) {

                this.pedidosPagados[i].forEach(pedXcomid => {
                    
                    sum += pedXcomid.pxq;
                });
            }
            return sum.toFixed(2)
        },
        sumPedsPagadosTot() {

            var i;
            var sum = 0;
            for ( i=0; i < this.pedidosPagadosTot.length; i++) {

                this.pedidosPagadosTot[i].forEach(pedXcomid => {
                    
                    sum += pedXcomid.pxq;
                });
            }
            return sum.toFixed(2)
        },
        sumCostoPedsPagados() {

            var i;
            var sum = 0;
            for ( i=0; i < this.pedidosPagados.length; i++) {

                this.pedidosPagados[i].forEach(pedXcomid => {
                    
                    sum += pedXcomid.cxq;
                });
            }
            return sum.toFixed(2)
        },
        sumCostoPedsPagadosTot() {

            var i;
            var sum = 0;
            for ( i=0; i < this.pedidosPagadosTot.length; i++) {

                this.pedidosPagadosTot[i].forEach(pedXcomid => {
                    
                    sum += pedXcomid.cxq;
                });
            }
            return sum.toFixed(2)
        },
        sumInversYcreds() {
            // var i;
            var sum = 0;
            // for ( i=0; i < this.inversYcreds.length; i++) {
                this.inversYcreds.forEach(ingrdXcomid => {                    
                    sum += ingrdXcomid.monto;
                });
            // }
            return sum.toFixed(2)
        },
        sumInversYcredsTot() {
            // var i;
            var sum = 0;
            // for ( i=0; i < this.inversYcreds.length; i++) {
                this.inversYcredsTot.forEach(ingrdXcomid => {                    
                    sum += ingrdXcomid.monto;
                });
            // }
            return sum.toFixed(2)
        },
        sumGstsDivrs() {

            // var i;
            var sum = 0;
            // for ( i=0; i < this.inversYcreds.length; i++) {

                this.gastosRango.forEach(gastXcomid => {
                    
                    sum += gastXcomid.monto;
                });
            // }
            return sum.toFixed(2)
        },
        sumIngrsDivrs() {

            // var i;
            var sum = 0;
            // for ( i=0; i < this.inversYcreds.length; i++) {

                this.ingresosRango.forEach(ingrsXcomid => {
                    
                    sum += ingrsXcomid.monto;
                });
            // }
            return sum.toFixed(2)
        },
        sumGstsDivrsTot() {
            var sum = 0;
            this.gastosTot.forEach(gastXcomid => {
                
                sum += gastXcomid.monto;
            });
            return sum.toFixed(2)
        },
        sumIngrDivrsTot() {

            // var i;
            var sum = 0;
            // for ( i=0; i < this.inversYcreds.length; i++) {

                this.ingresosTot.forEach(ingrXcomid => {
                    
                    sum += ingrXcomid.monto;
                });
            // }
            return sum.toFixed(2)
        },
        sumPedsNoPagados() {

            var i;
            var sum = 0;
            for ( i=0; i < this.pedidosNoPagados.length; i++) {

                this.pedidosNoPagados[i].forEach(pedXcomid => {
                    
                    sum += pedXcomid.pxq;
                });
            }
            return sum.toFixed(2)
        },
        subTotPorComanda () {
            var sum = 0;
            this.pedidosPorComanda.forEach(x => {
                sum += x.pxq;
            });
            return sum.toFixed(2)
        },
        impuesto () {
            var imp = this.subTotPorComanda * .16
            // return imp
            return +(Math.round(imp + "e+2")  + "e-2");
        },
        totSum () {
            var tS = this.impuesto + this.subTotPorComanda
            return tS.toFixed(2)
        },
        saldoInitOp() {
            return Number(this.sumPedsPagadosTot) + Number(this.sumIngrDivrsTot) - Number(this.sumGstsDivrsTot)
        },
        saldoTot() {
            return Number(this.sumPedsPagados) + Number(this.sumIngrsDivrs) - Number(this.sumGstsDivrs)
        }
    },
    watch: {
      date1 () {
        this.dateFormatted1 = this.formatDate1(this.date1)
      },
      date2 () {
          this.dateFormatted2 = this.formatDate2(this.date2)
      }
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        closeDialogTr () {
            this.dialogTraspaso = false
            setTimeout(() => {
                this.ingresoTot = Object.assign({}, this.defaultItem)
            }, 300)            
        },
        async saveTraspaso(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    let batch = writeBatch(db);

                    // Generar IDs únicos para los documentos
                    let ingrid = uuidv4();
                    let ingridDos = uuidv4();

                    // Crear objetos de ingreso
                    let ingreso = {
                        ingrid,
                        proveniente: 'traspasoEntreCuentas',
                        numDocumento: 'noAplica',
                        monto: -Number(this.ingreso.monto),
                        fechaMili: new Date().getTime(),
                        fechaDocumento: this.ingreso.fechaDocumento,
                        fechaRegistro: this.ingreso.fechaRegistro,
                        tipoDinero: item,
                        tipoIngreso: 'traspasoEnviando',
                        notas: this.ingreso.notas,
                        autor: {
                            colid: this.colaborador.colid || 'userHostAsColaborador',
                            userName: this.colaborador.userName,                            
                        },
                    };

                    let ingresoDos = {
                        ingridDos,
                        proveniente: 'traspasoEntreCuentas',
                        numDocumento: 'noAplica',
                        monto: Number(this.ingreso.monto),
                        fechaMili: new Date().getTime(),
                        fechaDocumento: this.ingreso.fechaDocumento,
                        fechaRegistro: this.ingreso.fechaRegistro,
                        tipoDinero: this.ingreso.tipoDinero,
                        tipoIngreso: 'traspasoRecibiendo',
                        notas: this.ingreso.notas,
                        autor: {
                            colid: this.colaborador.colid || 'userHostAsColaborador',
                            userName: this.colaborador.userName,                            
                        },
                    };

                    // Agregar operaciones al batch
                    batch.set(doc(db, 'usuarios', userName.uid, 'ingresos', ingreso.ingrid), ingreso);
                    batch.set(doc(db, 'usuarios', userName.uid, 'ingresos', ingresoDos.ingridDos), ingresoDos);

                    // Ejecutar el batch
                    await batch.commit();

                    // Consultar el saldo total
                    this.consultarSaldoTotPorDinero();
                    this.mostrarExito('Se cargó la información exitosamente');
                } else {
                    this.mostrarError('Usuario no encontrado.');
                }
            } catch (error) {
                console.error('Error cargando la información:', error);
                this.mostrarError('Ocurrió un error cargando la información');
            } finally {
                this.ocultarOcupado();
                this.closeDialogTr();
            }
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            // await dbL.collection('fromSameHost').delete()
            // this.sameHost = false
            // this.sameHostName = ''
            this.consultarInversYcredsTot()
        },
        emptyFields() {
            this.comanda = this.defaultItem
            this.dialog = false
        },
        async abrirComanda(comanda) {    
            this.comanda = comanda;
            this.pedidosPorComanda = this.pedidoTot.filter(pedXcom => pedXcom.comid == comanda.comid);
        },
        formatDate1 (date1) {
            if (!date1) return null

            const [year, month, day] = date1.split('-')
            return `${day}/${month}/${year}`
        },
        formatDate2 (date2) {
            if (!date2) return null

            const [year, month, day] = date2.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate1 (date1) {
            if (!date1) return null

            const [month, day, year] = date1.split('/')
            return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
        },
        parseDate2 (date2) {
            if (!date2) return null

            const [month, day, year] = date2.split('/')
            return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
        },
        async ingrsYgsts() {
            
            this.ingrsygsts = await this.comandas.concat(this.inversYcreds, this.gastosRango, this.ingresosRango)
            
        },
        async consultasCyNV() {
            await this.consultarComandas()
            // await this.consultarNotasDeVenta()
            await this.consultarGastosRango()
            await this.consultarIngresosRango()            
            this.consultarInversYcredsRango()
            this.ingrsYgsts()
        },
        async consultarComandas() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.pedidoTot = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    this.dateMili1 = new Date(this.date1).getTime(); // inicio del día en milisegundos
                    this.dateMili2 = Number(new Date(this.date2).getTime() + 86400000); // fin del día en milisegundos

                    this.consultarPedidos();

                    // Verificar si las fechas son iguales
                    if (this.dateFormatted1 === this.dateFormatted2) {
                        // Formatear la fecha para la consulta
                        const [day, month, year] = this.dateFormatted2.split('/');
                        const formattedDate2 = `${day}/${month}/${year.slice(-2)}`;
                        this.comandas = [];

                        // Consultar comandas para una fecha específica
                        let resultadoCom = await getDocs(query(
                            collectionGroup(db, 'comandas'),
                            where('userNameHost', '==', userName.userName),
                            where('fecha', '==', formattedDate2)
                        ));

                        resultadoCom.forEach(doc => {
                            let comandasInd = doc.data();
                            this.comandas.push(comandasInd);
                        });

                    } else {
                        this.comandas = [];

                        // Consultar comandas en un rango de fechas
                        let resultadoCom = await getDocs(query(
                            collectionGroup(db, 'comandas'),
                            where('userNameHost', '==', userName.userName),
                            where('fechaMili', '>=', this.dateMili1),
                            where('fechaMili', '<=', this.dateMili2)
                        ));

                        resultadoCom.forEach(doc => {
                            let comandasInd = doc.data();
                            this.comandas.push(comandasInd);
                        });
                    }
                }
            } catch (error) {
                console.error('Error consultando las comandas:', error);
                // this.mostrarError('Ocurrió un error consultando las comandas.');
            }

            // Opcional: Consultar ingresos, gastos, etc.
            // await this.consultarIngresosRango();
            // await this.consultarGastosRango();
            // this.consultarInversYcredsRango();
            // this.ingrsYgsts();
        },
        async consultarNotasDeVenta() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.notasdeventa = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    this.dateMili1 = new Date(this.date1).getTime(); // inicio del día en milisegundos
                    this.dateMili2 = Number(new Date(this.date2).getTime() + 86400000); // fin del día en milisegundos

                    this.consultarPedidos();

                    // Verificar si las fechas son iguales
                    if (this.dateFormatted1 === this.dateFormatted2) {
                        // Consultar notas de venta para una fecha específica
                        let resultadoCom = await getDocs(query(
                            collectionGroup(db, 'notasdeventa'),
                            where('userNameHost', '==', userName.userName),
                            where('fecha', '==', this.date2)
                        ));

                        resultadoCom.forEach(doc => {
                            let notvind = doc.data();
                            this.notasdeventa.push(notvind);
                        });

                    } else {
                        // Consultar notas de venta en un rango de fechas
                        let resultadoCom = await getDocs(query(
                            collectionGroup(db, 'notasdeventa'),
                            where('userNameHost', '==', userName.userName),
                            where('fechaMili', '>=', this.dateMili1),
                            where('fechaMili', '<=', this.dateMili2)
                        ));

                        resultadoCom.forEach(doc => {
                            let notaVenta = doc.data();
                            this.notasdeventa.push(notaVenta);
                        });
                    }
                }
            } catch (error) {
                console.error('Error consultando las notas de venta:', error);
                // this.mostrarError('Ocurrió un error consultando las notas de venta.');
            }

            // Opcional: Consultar otros datos
            // await this.consultarIngresosRango();
            // await this.consultarGastosRango();
            // this.consultarInversYcredsRango();
            // this.ingrsYgsts();
        },
        async consultarComandasTot() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.comandasTot = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar comandas del usuario
                    let resultadoCom = await getDocs(query(
                        collectionGroup(db, 'comandas'),
                        where('userNameHost', '==', userName.userName)
                    ));

                    resultadoCom.forEach(doc => {
                        let comandasInd = doc.data();
                        this.comandasTot.push(comandasInd);
                    });
                }
            } catch (error) {
                console.error('Error consultando las comandas:', error);
                // this.mostrarError('Ocurrió un error consultando las comandas.');
            }

            // Consultar pedidos totales (opcional)
            this.consultarPedidosTot();
        },
        async consultaretiquetasFzsDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.etiquetasFzsDB = [];
            this.etiquetasDinero = [];
            this.etiquetasIngreso = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar las etiquetas
                    let etiquetasQuery = query(
                        collection(db, 'usuarios', userName.uid, 'etiquetasFzsDB')
                    );
                    let resultado = await getDocs(etiquetasQuery);

                    resultado.forEach(doc => {
                        let datadb = doc.data();
                        this.etiquetasFzsDB.push(datadb);

                        if (datadb.tipoEtiq === 'Dinero') {
                            this.etiquetasDinero.push(datadb.nombreEtq);
                        } else if (datadb.tipoEtiq === 'Ingreso') {
                            this.etiquetasIngreso.push(datadb.nombreEtq);
                        }
                    });
                }
            } catch (error) {
                console.error('Error consultando las etiquetas:', error);
                this.mostrarError('Ocurrió un error consultando las Etiquetas.');
            }

            this.consultarGastosTot();
        },
        async consultarGastosTot() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.gastosData = [];
            this.gastosTot = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar los gastos
                    let gastosQuery = query(
                        collection(db, 'usuarios', userName.uid, 'gastos')
                    );
                    let resultadoCom = await getDocs(gastosQuery);

                    // Procesar los resultados
                    resultadoCom.forEach(subdoc => {
                        let gast = subdoc.data();
                        this.gastosData.push(gast);
                    });

                    // Filtrar los gastos
                    this.gastosTot = this.gastosData.filter(elm => !elm.programado || elm.programado === false);
                }
            } catch (error) {
                console.error('Error consultando los gastos:', error);
                this.mostrarError('Ocurrió un error consultando los gastos.');
            }

            this.consultarIngresosTot();
        },
        async consultarGastosRango() {
            this.gastosRango = []
            this.gastosRango = await this.gastosTot.filter(g => {
                let fecha = new Date(g.fechaFactura).toISOString().split('T')[0];
                return fecha >= this.date1 && fecha <= this.date2;
            });

            this.ingrsYgsts()
        },
        async consultarIngresosTot() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.ingresosTot = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar los ingresos, ordenados por fecha
                    let ingresosQuery = query(
                        collection(db, 'usuarios', userName.uid, 'ingresos'),
                        orderBy('fechaMili', 'desc')
                    );
                    let resultado = await getDocs(ingresosQuery);

                    // Procesar los resultados
                    resultado.forEach(doc => {
                        let ingreso = doc.data();
                        this.ingresosTot.push(ingreso);
                    });
                }
            } catch (error) {
                console.error('Error consultando los ingresos:', error);
                this.mostrarError('Ocurrió un error consultando los ingresos.');
            }

            this.consultarSaldoTotPorDinero();
        },
        async consultarIngresosRango() {
            this.ingresosRango = []
            this.ingresosRango = await this.ingresosTot.filter(g => {
                                    let fecha = new Date(g.fechaDocumento).toISOString().split('T')[0];
                                    return fecha >= this.date1 && fecha <= this.date2;
                                });
            this.ingrsYgsts()
        },
        async consultarSaldoTotPorDinero() {
            // Primero, creamos un objeto para almacenar los saldos por tipo de dinero
            let saldo = {};

            // Luego, sumamos todos los ingresos por tipo de dinero
            this.ingresosTot.forEach(ingreso => {
            if (!saldo[ingreso.tipoDinero]) {
                saldo[ingreso.tipoDinero] = 0;
            }
            saldo[ingreso.tipoDinero] += ingreso.monto;
            });

            // Después, restamos todos los gastos por tipo de dinero
            this.gastosTot.forEach(gasto => {
            if (!saldo[gasto.tipoDinero]) {
                saldo[gasto.tipoDinero] = 0;
            }
            saldo[gasto.tipoDinero] -= gasto.monto;
            });

            // Finalmente, convertimos el objeto a un array de objetos para poder desplegarlo en una tabla
            this.saldoTotPorDinero = Object.keys(saldo).map(tipoDinero => {
            return {
                tipoDinero: tipoDinero,
                saldo: saldo[tipoDinero]
            };
            });
            this.dialogProgress = false
            return this.saldoTotPorDinero;
        },
        async closeSetqs() {
            this.dialogEtiquetas = false
            this.montosXetiquetasG = []
            this.arrayfilterG = []
            this.montosXetiquetasI = []
            this.arrayfilterI = []
            this.montosXetiquetasGD = []
            this.arrayfilterGD = []
            this.montosXetiquetasID = []
            this.arrayfilterID = []
            this.montosXetiquetasGDID = []
            this.arrayfilterGDID = []
        },
        async showEtiqs() {
            this.calcMontXetiqsG()
            this.dialogEtiquetas = true
        },
        async calcMontXetiqsG() {
            
            for (let index = 0; index < this.etiquetasFzsDB.length; index++) {
                
                let arrayfg = await this.gastosRango.filter(el => el.tipoGasto == this.etiquetasFzsDB[index].nombreEtq);                  
                this.arrayfilterG.push(arrayfg)
            }

            for (let indexB = 0; indexB < this.arrayfilterG.length; indexB++) {
                if (this.arrayfilterG[indexB].length >= 2) {
                    let monto = this.arrayfilterG[indexB].map(item => item.monto).reduce((valAnt, valActual) => valAnt + valActual,);
                    let etiqueta = this.arrayfilterG[indexB].reduce((valAnt, valActual) => valAnt.tipoGasto || valActual.tipoGasto);
                    this.montosXetiquetasG.push({etiqueta: etiqueta, monto: monto})
                } 
                else if (this.arrayfilterG[indexB].length === 1) {
                    let obj = this.arrayfilterG[indexB].reduce((valAnt, valActual) => valAnt.monto + valActual.monto,);
                    this.montosXetiquetasG.push({etiqueta: obj.tipoGasto, monto: obj.monto})
                }
            }
            this.calcMontXetiqsI()    
        },
        async calcMontXetiqsI() {
            
            for (let index = 0; index < this.etiquetasFzsDB.length; index++) {
                
                let arrayfi = await this.ingresosRango.filter(el => el.tipoIngreso == this.etiquetasFzsDB[index].nombreEtq)            
                this.arrayfilterI.push(arrayfi)
            }

            for (let indexB = 0; indexB < this.arrayfilterI.length; indexB++) {
                if (this.arrayfilterI[indexB].length >= 2) {
                    let monto = this.arrayfilterI[indexB].map(item => item.monto).reduce((valAnt, valActual) => valAnt + valActual,);
                    let etiqueta = this.arrayfilterI[indexB].reduce((valAnt, valActual) => valAnt.tipoIngreso || valActual.tipoIngreso,);
                    this.montosXetiquetasI.push({etiqueta: etiqueta, monto: monto})
                } 
                else if (this.arrayfilterI[indexB].length === 1) {
                    let obj = this.arrayfilterI[indexB].reduce((valAnt, valActual) => valAnt.monto + valActual.monto,);
                    this.montosXetiquetasI.push({etiqueta: obj.tipoIngreso , monto: obj.monto})
                }
            }
            this.calcMontXetiqsGD()
        },
        async calcMontXetiqsGD() {
            
            for (let index = 0; index < this.etiquetasFzsDB.length; index++) {
                
                let arrayfi = await this.gastosRango.filter(el => el.tipoDinero == this.etiquetasFzsDB[index].nombreEtq)            
                this.arrayfilterGD.push(arrayfi)
            }

            for (let indexB = 0; indexB < this.arrayfilterGD.length; indexB++) {
                if (this.arrayfilterGD[indexB].length >= 2) {
                    let monto = this.arrayfilterGD[indexB].map(item => item.monto).reduce((valAnt, valActual) => valAnt + valActual,);
                    let etiqueta = this.arrayfilterGD[indexB].reduce((valAnt, valActual) => valAnt.tipoDinero || valActual.tipoDinero,);
                    this.montosXetiquetasGD.push({etiqueta: etiqueta, monto: monto * -1})
                } 
                else if (this.arrayfilterGD[indexB].length === 1) {
                    let obj = this.arrayfilterGD[indexB].reduce((valAnt, valActual) => valAnt.monto + valActual.monto,);
                    this.montosXetiquetasGD.push({etiqueta: obj.tipoDinero , monto: obj.monto * -1})
                }
            }
            this.calcMontXetiqsID()    
        },
        async calcMontXetiqsID() {
            
            for (let index = 0; index < this.etiquetasFzsDB.length; index++) {
                
                let arrayfi = await this.ingresosRango.filter(el => el.tipoDinero == this.etiquetasFzsDB[index].nombreEtq)            
                this.arrayfilterID.push(arrayfi)
            }

            for (let indexB = 0; indexB < this.arrayfilterID.length; indexB++) {
                if (this.arrayfilterID[indexB].length >= 2) {
                    let monto = this.arrayfilterID[indexB].map(item => item.monto).reduce((valAnt, valActual) => valAnt + valActual,);
                    let etiqueta = this.arrayfilterID[indexB].reduce((valAnt, valActual) => valAnt.tipoDinero || valActual.tipoDinero,);
                    this.montosXetiquetasID.push({etiqueta: etiqueta, monto: monto})
                } 
                else if (this.arrayfilterID[indexB].length === 1) {
                    let obj = this.arrayfilterID[indexB].reduce((valAnt, valActual) => valAnt.monto + valActual.monto,);
                    this.montosXetiquetasID.push({etiqueta: obj.tipoDinero , monto: obj.monto})
                }
            }
            this.concatGDyID()
        },
        async concatGDyID() {
            
            this.montosetiqs = this.montosXetiquetasGD.concat(this.montosXetiquetasID)

            for (let index = 0; index < this.etiquetasFzsDB.length; index++) {
                
                let arrayfi = await this.montosetiqs.filter(el => el.etiqueta == this.etiquetasFzsDB[index].nombreEtq)            
                this.arrayfilterGDID.push(arrayfi)
            }

            for (let indexB = 0; indexB < this.arrayfilterGDID.length; indexB++) {
                if (this.arrayfilterGDID[indexB].length >= 2) {
                    let monto = this.arrayfilterGDID[indexB].map(item => item.monto).reduce((valAnt, valActual) => valAnt + valActual,);
                    let etiqueta = this.arrayfilterGDID[indexB].reduce((valAnt, valActual) => valAnt.etiqueta || valActual.etiqueta,);
                    this.montosXetiquetasGDID.push({etiqueta: etiqueta, monto: monto})
                } 
                else if (this.arrayfilterGDID[indexB].length === 1) {
                    let obj = this.arrayfilterGDID[indexB].reduce((valAnt, valActual) => valAnt.monto + valActual.monto,);
                    this.montosXetiquetasGDID.push({etiqueta: obj.etiqueta , monto: obj.monto})
                }
            }
        },
        async consultarInversYcredsTot() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.inversYcredsTot = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar la colección 'inversYcreds'
                    let inversYcredsQuery = collection(db, 'usuarios', userName.uid, 'inversYcreds');
                    let resultadoCom = await getDocs(inversYcredsQuery);

                    // Procesar los resultados
                    resultadoCom.forEach(doc => {
                        let ingr = doc.data();
                        this.inversYcredsTot.push(ingr);
                    });
                }
            } catch (error) {
                console.error('Error consultando inversiones y créditos:', error);
                this.mostrarError('Ocurrió un error consultando las inversiones y créditos.');
            }

            this.ingrsYgsts();
        },
        async consultarInversYcredsRango() {
            this.inversYcredsRango = []
            this.inversYcredsRango = await this.inversYcredsTot.filter(g => {
                                    let fecha = new Date(g.fechaDoc).toISOString().split('T')[0];
                                    return fecha >= this.date1 && fecha <= this.date2;
                                });
            this.ingrsYgsts()
        },
        async consultarPedidos() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.pedidoTot = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    let resultadoPed;
                    if (this.dateFormatted1 === this.dateFormatted2) {
                        // Consultar por fecha exacta
                        resultadoPed = await getDocs(
                            collectionGroup(db, 'pedidoTot')
                                .where('userNameHost', '==', userName.userName)
                                .where('fecha', '==', this.date2)
                        );
                    } else {
                        // Consultar por rango de fechas
                        resultadoPed = await getDocs(
                            collectionGroup(db, 'pedidoTot')
                                .where('userNameHost', '==', userName.userName)
                                .where('fechaMili', '>=', this.dateMili1)
                                .where('fechaMili', '<=', this.dateMili2)
                        );
                    }

                    // Procesar los resultados
                    resultadoPed.forEach(doc => {
                        let pedidoIs = doc.data();
                        this.pedidoTot.push(pedidoIs);
                    });
                }
            } catch (error) {
                console.error('Error consultando los pedidos:', error);
                this.mostrarError('Ocurrió un error consultando los pedidos.');
            }

            // Consultar pedidos pagados y no pagados
            this.consultarPedidosPagados();
            this.consultarPedidosNoPagados();
        },
        async consultarPedidosTot() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.pedidoTotTot = [];

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Consultar todos los documentos en la colección `pedidoTot` del grupo
                    let resultadoPed = await getDocs(
                        collectionGroup(db, 'pedidoTot')
                            .where('userNameHost', '==', userName.userName)
                    );

                    // Procesar los resultados
                    resultadoPed.forEach(doc => {
                        let pedidoIs = doc.data();
                        this.pedidoTotTot.push(pedidoIs);
                    });
                }
            } catch (error) {
                console.error('Error consultando los pedidos totales:', error);
                this.mostrarError('Ocurrió un error consultando los pedidos totales.');
            }

            // Consultar pedidos pagados totales
            this.consultarPedidosPagadosTot();
        },
        async consultarPedidosPagados () {

            this.pedidosPagados = []
            this.comsPags = await this.comandas.filter(comPag => comPag.estatusPago == "Pagada")

                this.comsPags.forEach(subdoc => {                    
                    
                    let pedsPag = this.pedidoTot.filter(ped => ped.comid == subdoc.comid)

                    this.pedidosPagados.push(pedsPag)

                })
        },
        async consultarPedidosNoPagados () {

            this.pedidosNoPagados = []
            this.comsNoPags = await this.comandas.filter(comNoPag => comNoPag.estatusPago == "Pendiente de Pago")
            
            this.comsNoPags.forEach(subdoc => {                    
                    
                    let pedsNoPag = this.pedidoTot.filter(ped => ped.comid == subdoc.comid)

                    this.pedidosNoPagados.push(pedsNoPag)

                }) 
        },
        async consultarPedidosPagadosTot () {

            this.pedidosPagadosTot = []
            this.comsPags = await this.comandasTot.filter(comPag => comPag.estatusPago == "Pagada")

            this.comsPags.forEach(subdoc => {                    
                
                let pedsPag = this.pedidoTotTot.filter(ped => ped.comid == subdoc.comid)

                this.pedidosPagadosTot.push(pedsPag)

            })
        },
    }
}
</script>

<style scoped>

.custom-card {
  border-radius: 30px;
}

</style>