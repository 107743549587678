<template>
  <v-layout justify-center align-center wrap>
    <v-flex xs12 ml-3 mt-2>
      <v-btn icon dark>                
          <a @click="$router.go(-1)"><v-icon small dark left color="black">arrow_back</v-icon></a>
      </v-btn>
      <span class="body-2">área cliente</span>
    </v-flex>
    <v-flex xs12 sm6>
      <!-- <v-card class="elevation=5 ma-3"> -->
        <v-layout justify-center wrap>
          <v-flex xs10 md8>
            <v-card class="mt-2">
              <v-toolbar card>
                <v-toolbar-title>
                  <span v-if="vista == 1">Comprobante de Pago</span>
                  <span v-if="vista == 2" >Vista Previa</span>
                </v-toolbar-title>        
              </v-toolbar>
              <v-card-text >
                <v-img v-if="vista == 1" alt="Foto de Pago"></v-img>
                <div v-if="vista == 2" ref="vistaPrevia" class="vistaPrevia ml-4 pl-3"></div>
              <!-- </v-card-text>
              <v-card-text> -->
                <!-- <v-layout v-if="vista == 1" justify-center>
                  <v-btn color="secondary" v-if="usuarioHost" :to="{ name: 'homePublicaUsuario', params: { userName: usuarioHost.userName } }">Regresar</v-btn>
                </v-layout> -->
                <v-layout v-if="vista == 2" align-center justify-center row fill-height>
                  <v-flex>
                    <v-btn class="ml-4" @click="vista = 1">Cancelar</v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn class="ml-4" @click="guardarImagen" color="secondary">Guardar</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs10 md8 lg6 mt-3>
            <v-card>
              <v-tabs icons-and-text grow>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab>
                  <span class="caption">Subir Imagen</span>
                  <v-icon>cloud_upload</v-icon>
                </v-tab>
                <v-tab-item>
                  <file-pond v-if="vista == 1" @addfile="cargarImagen" instant-upload="false" 
                            fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}"
                            labelFileTypeNotAllowed="Agrega una imagen .png o .jpg"
                            accepted-file-types="image/jpeg, image/png" 
                            label-idle="Selecciona o arrastra una imagen...">
                  </file-pond>
                  <v-responsive v-if="vista == 2">
                    <img ref="imagenOriginal" src="" alt="" class="edicionImagen">
                  </v-responsive>
                </v-tab-item>
                <!-- <v-tab v-if="fotosPortada.length > 1">
                  <span class="caption">Galería</span>
                  <v-icon>photo_library</v-icon>
                </v-tab> -->
                <!-- <v-tab-item v-if="fotosPortada.length > 1">
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm4 lg3 v-for="foto in fotosPortada" :key="foto.id">
                      <v-card class="ma-3">
                        <v-card-text>
                          <v-img :src="foto.urlFotoPago" alt="Foto Portada"></v-img>
                          <v-layout class="mt-3">
                            <v-flex xs6>
                              <v-layout justify-start>
                                <v-icon v-if="foto.urlFotoPago == usuarioHost.fotoPago" color="green" medium>check_circle</v-icon>
                                <v-icon v-else color="grey" @click="seleccionarfotoPago(foto.urlFotoPago)" medium>check_circle_outline</v-icon>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6>
                              <v-layout justify-end>
                                <v-icon v-if="foto.urlFotoPago != usuarioHost.fotoPago" medium @click="preguntarEliminarImagen(foto)">delete</v-icon>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-dialog v-model="preguntaEliminar" max-width="400" persistent>
                    <v-card>
                      <v-card-text class="title">
                        La imagen se eliminará permanentemente, ¿Deseas Continuar?
                      </v-card-text>
                      <v-btn color="secondary" flat @click="preguntaEliminar = false">Cancelar</v-btn>
                      <v-btn flat @click="eliminarImagen">Eliminar</v-btn>
                    </v-card>
                  </v-dialog>
                </v-tab-item> -->
              </v-tabs>
            </v-card>
          </v-flex>
        </v-layout>
        
      <!-- </v-card> -->
    </v-flex>    
  </v-layout>
</template>

<script>
import { db, storage } from '@/firebase'
import { mapMutations, mapState } from 'vuex'
import Localbase from 'localbase'

let dbL = new Localbase('db')

import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond/dist/filepond.min.css"

import Cropper from 'cropperjs/dist/cropper'
import 'cropperjs/dist/cropper.css'

import { v4 as uuidv4 } from 'uuid';

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  components: { FilePond },
  data() {
    return {
      vista: 1,
      cropper: null,
    //   fotosPortada: [],
      preguntaEliminar: false,
      fotoEliminar: null,
      cliAuto: ''
    }
  },
  computed: {
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['usuarioHost']),    
  },
  created() {
    this.cliAutoDefined()
  },
  methods: {
    ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
    // ...mapMutations('sesion', ['actualizarImgPortada']),    
    // async consultarFotosPortada() {

    //   this.mostrarOcupado({ titulo: 'Consultando Galería', mensaje: 'Recuperando galería de imágenes...' })

    //   try {
    //     let resultado = await db.collection('usuarios')
    //                             .doc(this.usuarioHost.uid)
    //                             .collection('fotos-portada')
    //                             .orderBy('fecha', 'desc')
    //                             .get()

    //     resultado.docs.forEach(doc => {
    //       this.fotosPortada.push(doc.data())
    //     })
    //   }
    //   catch (error) {
    //     this.mostrarError('Ocurrió un error recuperando tu galería de fotos.')
    //   }
    //   finally {
    //     this.ocultarOcupado()
    //   }
    // },
    async cliAutoDefined() {

      if (this.usuario != null) {
          this.cliAuto = this.usuario.userName;
      } else {
        await dbL.collection('cliInvit').get().then(cli => {
          this.cliAuto = cli[0].cliInvitID
        }).catch(() => {
            // // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
        });
      }
    },
    cargarImagen (error, archivo) {
      if (error) {
        this.mostrarError('Ocurrió un error leyendo la imagen.')
        return
      }

      this.vista = 2

      let reader = new FileReader()

      let vm = this

      reader.onloadend = () => {
        vm.$refs.imagenOriginal.src = reader.result
        vm.recortarImagen()
      }

      if (archivo) {
        if (archivo.file) {
          reader.readAsDataURL(archivo.file)
        }
      }
    },
    recortarImagen() {
      this.cropper = new Cropper(this.$refs.imagenOriginal, {
        preview: this.$refs.vistaPrevia,
        aspectRatio: .68,
        modal: false,
        guides: true,
        sized: false,
        zoomable: false,
        highlight: true,
        background: false,
        autoCrop: true
      })
    },
    async guardarImagen() {
      this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Almacenando el comprobante de Pago...' })
    
      let userNameHost = this.$route.params.userName.toLowerCase()
      let userNameDoc = await db.collection('userNames')
                                .doc(userNameHost)
                                .get()

      if(userNameDoc.exists) {
        let userName = userNameDoc.data()

        let canvas = this.cropper.getCroppedCanvas()
        let imagen = canvas.toDataURL('image/jpeg')
        let fotoPagoId = uuidv4()
        let comid = this.$route.params.comanda

        // try {
          let ref = storage.ref()
          
          let resultado = await ref.child(`usuarios/${userName.uid}/clientes/${this.cliAuto}/comandas/${comid}/foto-pago/${fotoPagoId}.jpg`)
                                  .putString(imagen, 'data_url')                                  
                                  
          let urlFotoPago = await resultado.ref.getDownloadURL()
          
          let fotoPago = {
            fotoPagoId,
            urlFotoPago,
          }

          
          
          let batch = db.batch()
          let ntfid = uuidv4()

          let notif = {    
              ntfid,
              tipoNotif: 'cliente->restaurante',
              asunto: "El comprobante de pago ha sido enviado.",
              // avatar: this.usuario.fotoPortada || '',
              irA: "auto-pedido",
              cliente: this.cliAuto,
              comid,
              hora: new Date().toLocaleTimeString('es-MX'),
              fecha: new Date().toISOString().substr(0, 10),
              fechaStd: new Date(),
          }
      
          await batch.set(db.collection('usuarios').doc(userName.uid)
                  .collection('notificaciones').doc(notif.ntfid), notif)

          await batch.update(db.collection('userNames').doc(this.usuarioHost.userName.toLowerCase()), {notifNew: true})

          await batch.update(db.collection('usuarios')
                  .doc(userName.uid)
                  .collection('clientes')
                  .doc(this.cliAuto)
                  .collection('comandas')
                  .doc(comid), fotoPago)
                  
                  
          

          try {
              batch.commit()
              // this.mostrarExito('Se cargó la información exitosamente')
              this.mostrarExito('El comprobante ha sido enviado, favor de esperar la notificación del restaurante.')
          }              
          catch (error) {
              
              this.mostrarError('Ocurrió un error cargando la información')
          }
          finally {
              this.ocultarOcupado()
          }
          
        
        // }
        // catch (error) {
        //   this.mostrarError('Ocurrió un error almacenando la imagen.')
        // }
        // finally {
        //   this.ocultarOcupado()
        // }
        this.ocultarOcupado()
        this.$router.push({ name: 'cliPedEstat', params: {userName: userName.userName, userNameCli: this.cliAuto} })
      }
    },
    async seleccionarfotoPago(urlFotoPago) {
      let userNameHost = this.$route.params.userName.toLowerCase()
      this.mostrarOcupado({ titulo: 'Actualizando Imagen', mensaje: 'Estableciendo foto de portada...' })
    
      try {
        let userNameDoc = await db.collection('userNames')
                                  .doc(userNameHost)
                                  .get()

      if(userNameDoc.exists) {
          let userName = userNameDoc.data()
          await db.collection('usuarios')
                  .doc(userName.uid)
                  .update({ fotoPago: urlFotoPago })

          this.actualizarImgPortada(urlFotoPago)
        }
      }
      catch (error) {
        this.mostrarError('Ocurrió un error seleccionando la imagen.')
      }
      finally {
        this.ocultarOcupado()
      }
    },
    preguntarEliminarImagen (foto) {
      this.fotoEliminar = foto
      this.preguntaEliminar = true
    },
    // async eliminarImagen () {
    //   this.preguntaEliminar = false

    //   this.mostrarOcupado({ titulo: 'Eliminando Imagen', mensaje: 'Se está eliminando tu imagen...' })

    //   try {
    //     await db.collection('usuarios')
    //             .doc(this.usuarioHost.uid)
    //             .collection('fotos-portada')
    //             .doc(this.fotoEliminar.fotoPagoId)
    //             .delete()

    //     await storage.ref().child(`usuarios/${this.usuarioHost.uid}/fotos-portada/${this.fotoEliminar.fotoPagoId}.jpg`)
    //                        .delete()

    //     // console.log(`usuarios/${this.usuarioHost.uid}/fotos-portada/${this.fotoEliminar.fotoPagoId}.jpg`)

    //     let index = this.fotosPortada.indexOf(this.fotoEliminar)
    //     this.fotosPortada.splice(index, 1)
    //   }
    //   catch (error) {        
    //     this.mostrarError('Ocurrió un error eliminando la imagen.')
    //   }
    //   finally {
    //     this.ocultarOcupado()
    //   }
    // },
  }
}
</script>

<style>
  
.filepond--drop-label {
  font-size: 14pt !important;
}

.edicionImagen {
  width: 100%;
}

.vistaPrevia {
  width: 240px;
  height: 426px;
  overflow: hidden;
}
</style>
