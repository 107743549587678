<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" @click="openCargado" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on }">
        <v-btn small color="primary" class="caption" dark v-on="on">Comprobante</v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="">
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-layout justify-center align-center wrap>
          <v-flex xs12 sm6>
            <v-card class="elevation=5 ma-3">
                <v-layout justify-center>
                <v-card class="mt-2">
                    <v-toolbar card>
                    <v-toolbar-title>
                        <span v-if="vista == 1">Comprobante de Pago</span>
                        <span v-if="vista == 2" >Vista Previa</span>
                    </v-toolbar-title>        
                    </v-toolbar>
                    <v-card-text>
                    <v-img v-if="vista == 1" alt="Foto de Pago"></v-img>
                    <div v-if="vista == 2" ref="vistaPrevia" class="vistaPrevia"></div>
                    </v-card-text>
                    <v-card-text>
                    <!-- <v-layout v-if="vista == 1" justify-center>
                        <v-btn color="secondary" v-if="usuarioHost" :to="{ name: 'homePublicaUsuario', params: { userName: usuarioHost.userName } }">Regresar</v-btn>
                    </v-layout> -->
                    <v-layout v-if="vista == 2" justify-center wrap>
                        <v-flex xs12>
                            <v-text-field
                                v-model="folioComp"
                                label="Folio"
                                placeholder="núm. que aparece en el comprobante"
                                outline
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                        <v-btn v-if="usuario" @click="vista = 1">Cancelar</v-btn>
                        </v-flex>
                        <v-flex xs6>
                        <v-btn v-if="folioComp != ''" @click="guardarImagen" color="secondary">Enviar</v-btn>
                        </v-flex>
                    </v-layout>
                    </v-card-text>
                </v-card>
                </v-layout>
                <v-flex xs12 sm10 md8 lg6 xl4 mt-3>
                <v-card>
                    <v-tabs icons-and-text grow>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab>
                        <span class="caption">Subir Imagen</span>
                        <v-icon>cloud_upload</v-icon>
                    </v-tab>
                    <v-tab-item>
                        <file-pond v-if="vista == 1" @addfile="cargarImagen" instant-upload="false" 
                                fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}"
                                labelFileTypeNotAllowed="Agrega una imagen .png o .jpg"
                                accepted-file-types="image/jpeg, image/png" 
                                label-idle="Selecciona o arrastra una imagen...">
                        </file-pond>
                        <v-responsive v-if="vista == 2">
                        <img ref="imagenOriginal" src="" alt="" class="edicionImagen">
                        </v-responsive>
                    </v-tab-item>
                    <!-- <v-tab v-if="fotosPortada.length > 1">
                        <span class="caption">Galería</span>
                        <v-icon>photo_library</v-icon>
                    </v-tab> -->
                    <!-- <v-tab-item v-if="fotosPortada.length > 1">
                        <v-layout wrap justify-start>
                        <v-flex xs6 sm4 lg3 v-for="foto in fotosPortada" :key="foto.id">
                            <v-card class="ma-3">
                            <v-card-text>
                                <v-img :src="foto.urlFotoPago" alt="Foto Portada"></v-img>
                                <v-layout class="mt-3">
                                <v-flex xs6>
                                    <v-layout justify-start>
                                    <v-icon v-if="foto.urlFotoPago == usuarioHost.fotoPago" color="green" medium>check_circle</v-icon>
                                    <v-icon v-else color="grey" @click="seleccionarfotoPago(foto.urlFotoPago)" medium>check_circle_outline</v-icon>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs6>
                                    <v-layout justify-end>
                                    <v-icon v-if="foto.urlFotoPago != usuarioHost.fotoPago" medium @click="preguntarEliminarImagen(foto)">delete</v-icon>
                                    </v-layout>
                                </v-flex>
                                </v-layout>
                            </v-card-text>
                            </v-card>
                        </v-flex>
                        </v-layout>
                        <v-dialog v-model="preguntaEliminar" max-width="400" persistent>
                        <v-card>
                            <v-card-text class="title">
                            La imagen se eliminará permanentemente, ¿Deseas Continuar?
                            </v-card-text>
                            <v-btn color="secondary" flat @click="preguntaEliminar = false">Cancelar</v-btn>
                            <v-btn flat @click="eliminarImagen">Eliminar</v-btn>
                        </v-card>
                        </v-dialog>
                    </v-tab-item> -->
                    </v-tabs>
                </v-card>
                </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-layout>    
</template>  


<script>
import { db, storage } from '@/firebase'
import { mapMutations, mapState } from 'vuex'
import Localbase from 'localbase'

let dbL = new Localbase('db')

import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond/dist/filepond.min.css"

import Cropper from 'cropperjs/dist/cropper'
import 'cropperjs/dist/cropper.css'

import { v4 as uuidv4 } from 'uuid';

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  components: { FilePond },
  props: ['datosPlan2'],
  data() {
    return {
        folioComp: '',
        dialog: false,
        vista: 1,
        cropper: null,
        //   fotosPortada: [],
        preguntaEliminar: false,
        fotoEliminar: null,
        itemPlan: []
    }
  },

  created() {
      // this.item = this.datosAppExtra
      this.consulItemPlan()
  },

  computed: {
    // ...mapGetters('sesion', ['fotoPago']),
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['usuarioHost']),
    // ...mapGetters('sesion', { usuarioFromStore: ['usuario'] }),
    //   usuario: {

    //       get(){
    //           return this.usuarioFromStore
    //       },
    //       set(newUsuario){
    //           return newUsuario
    //       }
    //   },           
    
  },
  methods: {
    ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
    // ...mapMutations('sesion', ['actualizarImgPortada']),    
    // async consultarFotosPortada() {

    //   this.mostrarOcupado({ titulo: 'Consultando Galería', mensaje: 'Recuperando galería de imágenes...' })

    //   try {
    //     let resultado = await db.collection('usuarios')
    //                             .doc(this.usuarioHost.uid)
    //                             .collection('fotos-portada')
    //                             .orderBy('fecha', 'desc')
    //                             .get()

    //     resultado.docs.forEach(doc => {
    //       this.fotosPortada.push(doc.data())
    //     })
    //   }
    //   catch (error) {
    //     this.mostrarError('Ocurrió un error recuperando tu galería de fotos.')
    //   }
    //   finally {
    //     this.ocultarOcupado()
    //   }
    // },
    async openCargado() {
      this.consulItemPlan()
      // this.dialog = true
      
    },
    close() {
      this.dialog = false
      // this.router.push({ name: 'oficina'})
    },
    async consulItemPlan() {
      // this.itemPlan = this.datosPlan
      dbL.collection('itemPicked').get().then(ipk => {
        this.itemPlan = ipk
      })   
    },
    cargarImagen (error, archivo) {
      if (error) {
        this.mostrarError('Ocurrió un error leyendo la imagen.')
        return
      }

      this.vista = 2

      let reader = new FileReader()

      let vm = this

      reader.onloadend = () => {
        vm.$refs.imagenOriginal.src = reader.result
        vm.recortarImagen()
      }

      if (archivo) {
        if (archivo.file) {
          reader.readAsDataURL(archivo.file)
        }
      }
    },
    recortarImagen() {
      this.cropper = new Cropper(this.$refs.imagenOriginal, {
        preview: this.$refs.vistaPrevia,
        aspectRatio: .68,
        modal: false,
        guides: true,
        sized: false,
        zoomable: false,
        highlight: true,
        background: false,
        autoCrop: true
      })
    },
    async guardarImagen() {
      this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Almacenando el comprobante de Pago...' })
    
      let canvas = this.cropper.getCroppedCanvas()
      let imagen = canvas.toDataURL('image/jpeg')
      let fotoPagoId = uuidv4()
      let appExtra = this.$route.params.appExtra
      let userNameParametro = this.$route.params.userName.toLowerCase()

      try {
        let userNameDoc = await db.collection('userNames')
                                  .doc(userNameParametro)
                                  .get()

        if(userNameDoc.exists) {
            let userName = userNameDoc.data()
            let ref = storage.ref()

            let resultado = await ref.child(`usuarios/${userName.uid}/comprasAppsExtra/${appExtra}/foto-pago/${fotoPagoId}.jpg`)
                                    .putString(imagen, 'data_url')
                                    

            let urlFotoPago = await resultado.ref.getDownloadURL()

            let fotoPago = {
                fotoPagoId,
                urlFotoPago,
                appExtra,
                folioComp: this.folioComp,
                fecha: new Date().toISOString().substr(0, 10),
                activa: 'Activada',
                estatusPago: 'NoVerif'
            }

            await db.collection('usuarios')
                    .doc(userName.uid)
                    .collection('comprasAppExtra')
                    .doc(appExtra)
                    .set(fotoPago)

            // await db.collection('usuarios')
            //         .doc(userName.uid)
            //         .set({ extraApps: { 'Auto-Pedido': true}},
            //              { merge: true })
            
            let batch = db.batch()
            let ntfid = uuidv4()

            let notif = {    
                ntfid,
                tipoNotif: 'compra-appExtra',
                asunto: "Se ha comprado una appExtra",
                avatar: this.usuarioHost.fotoPortada,
                // irA: "usuarios",
                cliente: this.usuarioHost.userName,
                appExtra,
                hora: new Date().toLocaleTimeString('es-MX'),
                fecha: new Date().toISOString().substr(0, 10),
                fechaStd: new Date(),
            }
        
            await batch.set(db.collection('usuarios').doc(userName.uid)
                    .collection('notificaciones').doc(notif.ntfid), notif)

            await batch.set(db.collection('usuarios').doc('6ooam9l7LdMrCVHG9Qwm6qS7MCq1')
                    .collection('notificaciones').doc(notif.ntfid), notif)

            try {
                batch.commit()
            } 
            finally {
                this.ocultarOcupado()
                this.mostrarExito('Has realizado la subscripción de una nuVin App Extra. El comprobante ha sido enviado.')
            }
        }
      }
      finally {
        this.ocultarOcupado()
        this.$router.push({ name: 'oficina', params: {userName: this.usuarioHost.userName} })
      }
    },
    async guardarPagoImgPlan() {
      this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Almacenando el comprobante de Pago...' })
    
      let canvas = this.cropper.getCroppedCanvas()
      let imagen = canvas.toDataURL('image/jpeg')
      let fotoPagoId = uuidv4()
      let userNameParametro = this.$route.params.userName.toLowerCase()

      try {
        let userNameDoc = await db.collection('userNames')
                                  .doc(userNameParametro)
                                  .get()

        if(userNameDoc.exists) {
            let userName = userNameDoc.data()
            let ref = storage.ref()

            let resultado = await ref.child(`usuarios/${userName.uid}/comprasAppsExtra/foto-pago/${fotoPagoId}.jpg`)
                                    .putString(imagen, 'data_url')
                                    

            let urlFotoPago = await resultado.ref.getDownloadURL()

            let fotoPago = {
                fotoPagoId,
                urlFotoPago,
                folioComp: this.folioComp,
                fecha: new Date().toISOString().substr(0, 10),
                activa: 'Activada',
                estatusPago: 'NoVerif',
                plazo: this.itemPlan.plazo,
                fechaSigPago: this.itemPlan.fechaSigMes
            }

            await db.collection('usuarios')
                    .doc(userName.uid)
                    .collection('subscripcionPlan')
                    .doc()
                    .set(fotoPago)

            // await db.collection('usuarios')
            //         .doc(userName.uid)
            //         .set({ extraApps: { 'Auto-Pedido': true}},
            //              { merge: true })
            
            let batch = db.batch()
            let ntfid = uuidv4()

            let notif = {    
                ntfid,
                tipoNotif: 'compra-plan',
                asunto: "Se ha comprado una plan",
                avatar: this.usuarioHost.fotoPortada,
                // irA: "usuarios",
                cliente: this.usuarioHost.userName,
                // appExtra,
                hora: new Date().toLocaleTimeString('es-MX'),
                fecha: new Date().toISOString().substr(0, 10),
                fechaStd: new Date(),
            }
        
            await batch.set(db.collection('usuarios').doc(userName.uid)
                    .collection('notificaciones').doc(notif.ntfid), notif)

            await batch.set(db.collection('usuarios').doc('6ooam9l7LdMrCVHG9Qwm6qS7MCq1')
                    .collection('notificaciones').doc(notif.ntfid), notif)

            try {
                batch.commit()
            } 
            finally {
                this.ocultarOcupado()
                this.mostrarExito('Has realizado la subscripción de un Plan nuVin. El comprobante ha sido enviado.')
            }
        }
      }
      finally {
        this.ocultarOcupado()
        this.$router.push({ name: 'oficina', params: {userName: this.usuarioHost.userName} })
      }
    },
    async seleccionarfotoPago(urlFotoPago) {
      let userNameParametro = this.$route.params.userName.toLowerCase()
      this.mostrarOcupado({ titulo: 'Actualizando Imagen', mensaje: 'Estableciendo foto de portada...' })
    
      try {
        let userNameDoc = await db.collection('userNames')
                                  .doc(userNameParametro)
                                  .get()

      if(userNameDoc.exists) {
          let userName = userNameDoc.data()
          await db.collection('usuarios')
                  .doc(userName.uid)
                  .update({ fotoPago: urlFotoPago })

          this.actualizarImgPortada(urlFotoPago)
        }
      }
      catch (error) {
        this.mostrarError('Ocurrió un error seleccionando la imagen.')
      }
      finally {
        this.ocultarOcupado()
      }
    },
    preguntarEliminarImagen (foto) {
      this.fotoEliminar = foto
      this.preguntaEliminar = true
    },
  }
}
</script>

<style>
  
.filepond--drop-label {
  font-size: 14pt !important;
}

.edicionImagen {
  width: 100%;
}

.vistaPrevia {
  width: 240px;
  height: 426px;
  overflow: hidden;
}
</style>
