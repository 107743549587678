<template>
  <v-layout justify-center align-center wrap>
    <v-flex xs12 v-if="dialogProgress == false">
      <v-card class="elevation=5 ma-3 py-3 custom-card">
        <v-layout>
            <v-flex xs2 class="text-xs-left ml-4">
                <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-cash-plus</v-icon>                
            </v-flex>
            <v-flex xs8 class="text-xs-left">
                <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Créditos y Préstamos</p></h3>
                <h3 class="hidden-sm-and-up"><p class="text-xs-center title mt-3">Créditos y Préstamos</p></h3>
                {{colaborador.userName}}
            </v-flex>        
            <v-flex xs2 class="text-xs-right">
                <v-dialog
                    v-model="dialogHelp"
                    width="500"
                >
                    <template v-slot:activator="{ on }">
                    <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                    </template>

                    <v-card>
                    <v-card-title class="headline">
                        Cómo funciona esta sección...
                    </v-card-title>

                    <v-card-text>
                        En esta área se agregan los conceptos de capital-dnero que utiliza el negocio o el hogar para su desarrollo. Esta sección 
                        es útil para tener noción de la aportacion que se le ha dado al negocio en le tiempo y nos ayuda a realizar
                        cálculos de rentabilidad.
                        <ul>
                            <li>En el signo de "+" o botón "Agregar" dar click, se despliega un formulario para ingresar los datos característicos
                                de este crédito o préstamo que solicitamos a una entidad financiera o persona.
                            </li>
                            <li>
                                Asignar etiquetas te ayudará a tener un mejor control del Dinero que se tiene que retornar.
                            </li>
                            <li>Loa abonos a Créditos o Préstamos que vayas agregando se realizarán de manera automática desde la sección de GASTOS, tomando como
                                referencia las etiquetas que asignaste.
                            </li>
                            <li>
                                La cifra total, también será reflejada en la sección de SALDOS. 
                            </li>                            
                        </ul> 
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-spacer></v-spacer>
            <v-flex class="mr-3" xs12 sm6 md6 lg5 xl4>
                <v-text-field
                    class="mx-3"
                    v-model="search"
                    flat
                    label=""
                    prepend-inner-icon="search"
                    solo-inverted
                ></v-text-field>
            </v-flex>
        </v-layout>        
        <v-flex xs12>            
            <v-card class="elevation=5 ma-3">
                <v-toolbar flat color="white" >
                    <v-layout wrap>
                        <v-flex xs12 sm6 md6 lg5 xl4 >
                            <v-dialog v-model="dialog">
                                <template v-slot:activator="{ on }">
                                    <v-btn small class="hidden-xs-only caption white--text" :style="{'background-color': paletaColores.primero}" mb-2 v-on="on" v-if="puedeAddCyP">Registrar</v-btn>
                                    <v-btn icon top right absolute small fab dark :style="{'background-color': paletaColores.primero}" class="hidden-sm-and-up mt-2" v-on="on" v-if="puedeAddCyP">
                                        <v-icon class="pt-2 mt-1">add</v-icon>   
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                        <v-card-text>
                                            <v-container grid-list-md>
                                            <v-layout wrap >
                                                <!-- <v-flex xs12 sm6 md4>
                                                    <v-text-field v-model="credYprest.fuente" label="Fuente" @blur="$v.credYprest.fuente.$touch()" :error-messages="erroresProveedor"></v-text-field>
                                                </v-flex> -->
                                                <v-flex xs12 sm6 md4>
                                                    <v-select
                                                        v-model="credYprest.fuente"
                                                        :items="getItemsDisponibles"
                                                        @blur="$v.credYprest.fuente.$touch()"
                                                        :error-messages="erroresProveedor"
                                                        label="Fuente del crédito o préstamo (etiqueta-crédito/préstamo)"
                                                    ></v-select>                                     
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <v-text-field v-model="credYprest.numDoc" label="Núm. de documento" counter="20" @blur="$v.credYprest.numDoc.$touch()" :error-messages="erroresNumFactura"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <v-select v-model="credYprest.tipoDinero" :items="etiquetasDinero" label="A dónde se enviará este capital (etiqueta-Dinero)"></v-select>
                                                </v-flex>
                                                <!-- <v-flex xs12 sm6 md4>
                                                    <v-select v-model="credYprest.tipoInvYcred"  :items="itemsTipo" label="Tipo de Crédito o Préstamo" solo @blur="$v.credYprest.numDoc.$touch()" :error-messages="erroresNumFactura"></v-select>
                                                </v-flex> -->
                                                <v-flex xs12 sm6 md4>
                                                    <!-- <v-text-field type="text" v-model="credYprest.fechaDoc" label="Fecha de documento" locale="es-MX"></v-text-field> -->
                                                    <v-menu
                                                        ref="menu1"
                                                        v-model="menu1"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        lazy
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        max-width="290px"
                                                        min-width="290px"
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                            v-model="dateFormatted"
                                                            label="Fecha"
                                                            persistent-hint
                                                            prepend-icon="event"
                                                            @blur="updateFechaFactura"
                                                            v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="credYprest.fechaDoc" no-title @input="menu1 = false"></v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <v-text-field type="number" v-model="credYprest.monto" label="Monto" @blur="$v.credYprest.monto.$touch()" :error-messages="erroresMonto"></v-text-field>
                                                </v-flex>
                                                    <!-- :rules="rules" -->
                                                <v-flex xs12 sm6>
                                                    <v-text-field
                                                        v-model="credYprest.notas"
                                                        counter="25"
                                                        label="Describe: tasa, plazo, monto, etc."
                                                        outline
                                                        @blur="$v.credYprest.numDoc.$touch()" :error-messages="erroresNumFactura"
                                                    ></v-text-field>
                                                </v-flex>
                                            </v-layout>
                                            </v-container>
                                        </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" flat @click="close">Cancelar</v-btn>
                                        <v-btn color="primary" :depressed="$v.credYprest.$invalid" :disabled="$v.credYprest.$invalid" flat @click="save">Guardar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-flex>                
                        <!-- ETIQUETAS -->
                        <v-flex class="mb-1 mr-5">
                            <v-btn class="caption text-lowercase" small :style="{'background-color': paletaColores.primero}" dark :to="{ name: 'etiquetasFinanzas', params: {userName: usuarioHost.userName}}" v-if="puedeCrearEtq">Etiquetas</v-btn>
                        </v-flex>                    
                    </v-layout>
                </v-toolbar>            
                <v-data-table
                    :headers="headers"
                    :items="credsYprestms"
                    :search="search"
                    class="elevation-1"
                    >
                    <template v-slot:items="props">
                        <td><strong>{{ props.item.fuente }}</strong></td>
                        <td class="justify-center align-center layout">       
                            <!-- icono dialog de book para ver credsYprestms-->
                            <v-dialog v-model="dialog2" persistent max-width="600px">                            
                                <template v-slot:activator="{ on }">                                
                                    <v-icon @click="abrirInvYcred(props.item)" dark v-on="on" color="primary" class="mr-2" small>
                                        mdi-view-list
                                    </v-icon>
                                </template>
                                <!-- v-if="credYprest"
                                    :key= "credYprest.fuente"
                                    :fuente="credYprest.fuente"
                                    :credYprestmPartidas="credYprest.credYprestmPartidas" -->
                                <v-card v-if="selectedItem">
                                    <v-toolbar flat color="white">
                                        <h3>Abonos a:</h3><br>
                                        <v-toolbar-title >
                                            <h3 class="caption"> {{selectedItem.fuente}} </h3>
                                            <!-- <h6 class="caption">Número de documento:</h6><h3 class="caption font-weight-medium"> {{credYprest.numDoc}} </h3> -->
                                        </v-toolbar-title>
                                        <v-divider
                                            class="mx-2"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-dialog v-model="dialog3" max-width="500px" persistent>
                                            <!-- <template v-slot:activator="{ on }">
                                                <v-btn class="hidden-xs-only" mb-2 color="primary" dark v-on="on" >Nueva partida</v-btn>
                                                <v-btn  class="hidden-sm-and-up pt-2" small fab color="primary" outline v-on="on">
                                                    <v-icon>add</v-icon>   
                                                </v-btn>
                                            </template> -->
                                            <v-card>
                                            <v-card-title>
                                                <span class="headline">{{ formTitlePartidas }}</span>
                                            </v-card-title>
                                            <v-card-text >
                                                <v-container grid-list-md>
                                                    <v-layout wrap>
                                                        <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="partida.nombreProducto" label="Producto" @blur="$v.partida.nombreProducto.$touch()" :error-messages="erroresProducto"></v-text-field>
                                                            <v-text-field v-model="partida.unidad" label="Unidad" @blur="$v.partida.unidad.$touch()" :error-messages="erroresUnidad"></v-text-field>
                                                            <v-text-field v-model="partida.precio" label="Precio" @blur="$v.partida.precio.$touch()" :error-messages="erroresPrecio" type="number"></v-text-field>
                                                            <v-text-field v-model="partida.cantidad" label="Cantidad" class="number" @blur="$v.partida.cantidad.$touch()" :error-messages="erroresCantidad" type="number" min="1" max="100"></v-text-field>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                                <small>*Campo requerido</small>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary" flat @click="closePartida">Cerrar</v-btn>
                                                <v-btn color="primary" flat :depressed="$v.partida.$invalid" :disabled="$v.partida.$invalid" @click="savePartida">Guardar</v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-btn class="hidden-sm-and-up pt-2" small fab dark :style="{'background-color': paletaColores.primero}" @click="dialog2 = false">
                                            <v-icon dark>close</v-icon>   
                                        </v-btn>
                                        <v-btn class="hidden-xs-only caption text-none" :style="{'background-color': paletaColores.primero}" small dark flat @click="dialog2 = false">cerrar</v-btn>
                                    </v-toolbar>
                                    <v-data-table :headers="headers2" :items="filteredItems" class="elevation-1">
                                        <template v-slot:items="props">
                                            <td><strong>{{ props.item.fechaFactura }}</strong></td>
                                            <td class="text-xs-right">{{ props.item.monto }}</td>
                                            <td class="text-xs-center">{{ props.item.numDoc }}</td>
                                            <td class="text-xs-right">{{ props.item.tipoDinero }}</td>           
                                            <td class="text-xs-center number">{{ props.item.notas }}</td>                                                       
                                        </template>
                                    </v-data-table>   
                                    <template>
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <v-card color="blue-grey" class="white--text text-xs-right">
                                                    <span class="subheading mr-1">Total: $ {{ filteredItemsTotal.toLocaleString('es-MX') }}</span><br>
                                                    <!-- <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                                    <span class="subheading mr-1">Total: $ {{ totSum }}</span> -->
                                                </v-card>
                                            </v-flex>            
                                            <!-- <v-layout row justify-center>
                                                <v-dialog scrollable max-width="330px">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="primary" dark v-on="on">Mostrar ticket o imprimir?</v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>Ticket de Crédito</v-card-title>
                                                    <v-card-actions>
                                                        <v-btn icon color="primary" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                                    </v-card-actions>
                                                    <v-divider></v-divider>
                                                    <v-card-text style="height: 350px;" ref="printTable">                                                    
                                                            <v-flex xs12 sm12>
                                                            <v-card>                                                            
                                                                <v-list two-line>
                                                                <template >                                                                
                                                                    <p class="text-xs-center">
                                                                        <span>{{ contTicket.empresa }}</span><br>
                                                                        <span>{{ contTicket.RFC }}</span><br>
                                                                        <span>{{ contTicket.direccion }}</span><br>
                                                                        <span>{{ contTicket.telefono }}</span><br>
                                                                        <span>{{ contTicket.notapie }}</span>
                                                                    </p>                                                                
                                                                    <v-divider></v-divider>
                                                                        <template>
                                                                        <v-data-table
                                                                            :headers="headers3"
                                                                            :items="credYprestmPartidas"
                                                                            class="elevation-1"                                                            
                                                                            hide-actions
                                                                        >
                                                                            <template v-slot:items="props">
                                                                            <td>{{ props.item.producto }}</td>
                                                                            <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                                            <td class="text-xs-right">{{ props.item.producto.precio }}</td>                                                            
                                                                            </template>
                                                                        </v-data-table>
                                                                        <v-card class="text-xs-right">
                                                                            <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                                            <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                                            <span class="body-2">Total: $ {{ totSum }}</span>
                                                                        </v-card>
                                                                        </template>
                                                                    <v-divider></v-divider>
                                                                        <span>{{ contTicket.empresa }}</span><br>
                                                                        <span>{{ contTicket.RFC }}</span><br>
                                                                        <span>{{ contTicket.direccion }}</span><br>
                                                                        <span>{{ contTicket.telefono }}</span><br>
                                                                        <span>{{ contTicket.notapie }}</span>
                                                                </template>
                                                                </v-list>
                                                            </v-card>
                                                            </v-flex>                                                    
                                                    </v-card-text>
                                                    <v-divider></v-divider>                                                
                                                </v-card>
                                                </v-dialog>
                                            </v-layout>                                     -->
                                        </v-layout>
                                    </template>                    
                                </v-card>      
                            </v-dialog>
                            
                            <v-icon v-if="!tieneUnidadesMayorACero(props.item)"
                                class="mr-2" color="green"
                                @click="editItem(props.item)" small>
                                edit
                            </v-icon>
                            <v-icon v-else
                                class="mr-2"
                                disabled small>
                                edit
                            </v-icon>

                            <estasSeguro v-if="!tieneUnidadesMayorACero(props.item) && (colaboradorBorrar || perfilAuth)">
                                <v-icon
                                    color="red"
                                    slot="nombrebtn"
                                    small                                       
                                    >
                                    delete
                                </v-icon>
                                <div slot="elemento">
                                    <span>este concepto de Crédito o Préstamo?</span>
                                </div>
                                <div slot="btnaceptar">
                                    <v-btn flat @click="deleteItem(props.item)">Ok</v-btn>
                                </div>
                            </estasSeguro>
                            <v-icon
                                disabled
                                small
                                v-else
                            >
                                delete
                            </v-icon>
                        </td>
                        <td>{{ props.item.numDoc }}</td>
                        <td>{{ props.item.tipoDinero }}</td>
                        <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                        <td class="text-right">{{ '$' + getSumaMonto(props.item.fuente).toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                        <td class="text-right">{{ '$' + calcularDiferencia(props).toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                        <td>{{ props.item.fechaDoc }}</td>                                                       
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                        Your search for "{{ search }}" found no results.
                        </v-alert>
                    </template>
                </v-data-table>
                <template>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                <!-- <span class="subheading mr-1">Total: $ {{ subtotSum }}</span><br> -->
                                <!-- <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                <span class="subheading mr-1">Total: $ {{ totSum }}</span> -->
                            </v-card>
                        </v-flex>            
                        <!-- Ticket para imprimir -->
                        <v-layout row justify-center>
                            <v-dialog v-model="cardTicket" scrollable max-width="330px">
                            <!-- <template v-slot:activator="{ on }">
                                <v-btn :style="{'background-color': paletaColores.primero}" dark v-on="on">imprimir</v-btn>
                            </template> -->
                            <v-card>
                                <v-card-title>Ticket de Créditos o Préstamos</v-card-title>
                                <v-card-actions>
                                    <v-btn icon :style="{'background-color': paletaColores.primero}" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                </v-card-actions>
                                <v-divider></v-divider>
                                <v-card-text style="height: 350px;" ref="printTable">                                                    
                                        <v-flex xs12 sm12>
                                        <v-card>                                                            
                                            <v-list two-line>
                                            <template >                                                                
                                                <p class="text-xs-center">
                                                    <span>{{ contTicket.empresa }}</span><br>
                                                    <span>{{ contTicket.RFC }}</span><br>
                                                    <span>{{ contTicket.direccion }}</span><br>
                                                    <span>{{ contTicket.telefono }}</span><br>
                                                    <span>{{ contTicket.notapie }}</span>
                                                </p>                                                                
                                                <v-divider></v-divider>
                                                    <template>
                                                        <v-data-table
                                                            :headers="headers"
                                                            :items="credsYprestms"
                                                            class="elevation-1"                                                            
                                                            hide-actions
                                                        >
                                                            <template v-slot:items="props">
                                                            <td><strong>{{ props.item.fuente }}</strong></td>
                                                            <td>{{ props.item.monto.toLocaleString('es-MX') }}</td>
                                                            <td>{{ props.item.fechaDoc }}</td>                                                                 
                                                            </template>
                                                        </v-data-table>
                                                        <v-card class="text-xs-right">
                                                            <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                            <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                            <span class="body-2">Total: $ {{ totSum }}</span>
                                                        </v-card>
                                                        </template>
                                                        <v-divider></v-divider>
                                                        <span>{{ contTicket.empresa }}</span><br>
                                                        <span>{{ contTicket.RFC }}</span><br>
                                                        <span>{{ contTicket.direccion }}</span><br>
                                                        <span>{{ contTicket.telefono }}</span><br>
                                                        <span>{{ contTicket.notapie }}</span>      
                                                    </template>
                                            </v-list>
                                        </v-card>
                                        </v-flex>                                                    
                                </v-card-text>
                                <v-divider></v-divider>                                                
                            </v-card>
                            </v-dialog>
                        </v-layout>                                    
                    </v-layout>
                </template>
            </v-card>            
        </v-flex> 
      </v-card>   
    </v-flex>

    <template v-else>
        <div class="mt-5 pt-5" >
            <div class="text-xs-center mt-5 pt-5">
            <v-progress-circular
                :size="70"
                :width="7"
                :style="{'color': paletaColores.primero}"
                indeterminate
                class="mt-5 pt-5"
                ></v-progress-circular>
            </div>
        </div>                  
    </template>
  </v-layout>  
</template>

<script>
import estasSeguro from '@/components/estasSeguro.vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapMutations, mapState, mapActions } from 'vuex'
import { doc, collection, collectionGroup, updateDoc, deleteDoc, getDocs, writeBatch, getDoc } from 'firebase/firestore';
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
// import Multiselect from 'vue-multiselect'
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    // props: ['colaboradorRecibido'],
    // components: { comandasInd },   
    // components: { Multiselect },
    components: { estasSeguro },
    data: () => ({
        dateFormatted: null,
        selectedItem: null,
        menu1: false,
        etiquetasCreditoPrestamo: [],
        etiquetasDinero: ['Efectivo', 'Banco'],
        colaborador: '',
        colaboradores: [],
        contTicket: [],
        rowsPerPageItems: [4, 8, 12],
        pagination: {
            rowsPerPage: 4
        },
        dialogProgress: true,
        dialog: false,
        dialog2: false,
        dialog3: false,
        dialogHelp: false,
        cardTicket: false,
        meseroUser: [],
        partidaId: [],
        productoid: {
            name: 'Seleccionar',
            nombre: '',
            unidad: '',
            precio: 0,
            grupo: ''
        },
        credsYprestms: [],
        credYprest: {
            fuente: '',
            autor: {
                userName: ''
            },
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaDoc: null,
            tipoDinero: '',
            numDoc: '',
            monto: '',
            notas: '',            
            credYprestmPartidas: [''],
        },
        editedIndex: -1,
        editedIndexPartida: -1,
        defaultItem: {
            fuente: '',
            autor: {
                userName: ''
            },
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaDoc: null,
            tipoDinero: '',
            numDoc: '',
            monto: '',
            notas: '',            
            credYprestmPartidas: [''],
        },
        defaultItemPartida: {
            nombreProducto: '',
            unidad: '',
            subtotal: 0,
            cantidad: '',
            precio: '',
            ptdaId: '',
            fuente: '',
            hora: ''
        },    
        credYprestmPartidas: [],
        partida: {
            nombreProducto: '',
            unidad: '',
            subtotal: 0,
            cantidad: '',
            precio: '',
            ptdaId: '',
            fuente: '',
            hora: ''
            // estatusCocina: 'En Proceso',   
        },
        search: '',  
        selectComanda: null,
        today: '',
        daysPast: '',
        headers: [
          {
            text: 'Fuente',
            align: 'center',
            value: 'fuente'
          },
          { text: 'Acciones', value: '', sortable: false, },
          { text: '#Documento', value: 'numDoc', },
          { text: 'Contenedor', value: 'tipoDinero', },
          { text: 'Monto', value: 'monto', },
          { text: 'Abonos', value: 'monto', },
          { text: 'Diferencia', value: 'monto', },
          { text: 'Fecha de documento', value: 'fechaDoc', }
        ],              
        headers2: [
          {
            text: 'Fecha',
            align: 'left',
            value: 'fechaFactura'
          },
          { text: 'Monto', value: 'monto', align: 'center', },
          { text: '#Documento', value: 'numDoc' },
          { text: 'Tipo de Dinero', value: 'tipoDinero', align: 'center', },
          { text: 'Notas', value: 'notas' },
        ], 
        headers3: [ //ticket
          {
            text: 'Producto',
            align: 'left',
            value: 'producto',
          },
          { text: 'Cantidad', value: 'cantidad', sortable: false, },
          { text: '$Precio', value: 'precio', sortable: false, },
        ],           
    }),
    validations: {
        credYprest: {
                fuente: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(20),
                },
                numDoc: {
                    maxLength: maxLength(20),
                },
                monto: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(20),
                },
        },
        partida: {            
            nombreProducto: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
            unidad: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(15),
            },            
            precio: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(15),
            },
            cantidad: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(15),
            }
        }   
    },
    created() {      
        this.dateFormatted = this.formatDate(this.credYprest.fechaDoc);
        this.consultarWhereFrom()
        this.actualizarPerfilAuth() 
        this.consultaretiquetasFzsDB()
    },
    watch: {
      'credYprest.fechaDoc'() {
        this.dateFormatted = this.formatDate(this.credYprest.fechaDoc);
      }
    },
    mounted() {
        this.setFechaFactura();
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        puedeAddCyP() {
            const perfilAuth = this.perfilAuth;

            if (perfilAuth) {
                return true;
            } else {
                const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "RegistrarCréditosyPréstamos" && acceso.value);
                return Boolean(accesoAgregar);
            }
        },
        puedeCrearEtq() {
            const perfilAuth = this.perfilAuth;

            if (perfilAuth) {
                return true;
            } else {
                const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "CrearEtiquetas" && acceso.value);
                return Boolean(accesoAgregar);
            }
        },
        getItemsDisponibles() {
            const fuentesExistente = this.credsYprestms.map(item => item.fuente);
            return this.etiquetasCreditoPrestamo.filter(item => !fuentesExistente.includes(item));
        },
        filteredItemsTotal() {
            if (this.selectedItem) {
            const filteredItems = this.credYprestmPartidas.filter(item => item.fuente === this.selectedItem.fuente);
            return filteredItems.reduce((total, item) => total + item.monto, 0);
            }
            return 0;
        },
        filteredItems() {
            if (this.selectedItem) {
            return this.credYprestmPartidas.filter(item => item.fuente === this.selectedItem.fuente);
            }
            return [];
        },
        calcularDiferencia() {
            return props => {
            const monto = props.item.monto;
            const fuente = props.item.fuente;
            const sumaMonto = this.getSumaMonto(fuente);
            return sumaMonto - monto;
            }
        },
        tieneUnidadesMayorACero() {
            return propitem => {
            const fuente = propitem.fuente;
            // console.log(this.credYprestmPartidas[0])
            const count = this.credYprestmPartidas.filter(item => item.fuente === fuente).length;
            return count > 0;
            };
        },
        getSumaMonto() {
            return fuente => {
            const suma = this.credYprestmPartidas.reduce((total, item) => {
                if (item.fuente === fuente) {
                return total + item.monto;
                } else {
                return total;
                }
            }, 0);
            
            return suma;
            };
        },
        computedDateFormatted() {
            return this.formatDate(this.credYprest.fechaDoc);
        },
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        colaboradorBorrar() {

            if(this.colaborador.switchBorrar == true){
                // alert("Object found inside the array.");
                return true
            } else{
                // alert("Object not found.");
                return false
            }
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Nuevo Crédito o Préstamo' : 'Editar Crédito o Préstamo'  
        },
        formTitlePartidas () {
            return this.editedIndexPartida === -1 ? 'Nueva Partida' : 'Editar Partida'
        }, 
        subtotSum () {
            var sum = 0;
            this.credsYprestms.forEach(x => {
                sum += x.monto;
            });
            return sum            
        },
        impuesto () {
            let x = 0.16
            var imp = this.subtotSum * x
            // return imp
            return +(Math.round(imp + "e+2")  + "e-2");
        },
        totSum () {
            var tS = this.impuesto + this.subtotSum
            return tS
        },
        // totInversYcreds () {
        //     var sum = 0;
        //     this.credsYprestms.forEach(x => {
        //         sum += x.monto;
        //     });
        //     return sum
        // },
        erroresProveedor() {
            let errores = []
            if (!this.$v.credYprest.fuente.$dirty) { return errores }
            if (!this.$v.credYprest.fuente.required) { errores.push('Ingresa el nombre de la fuente.') }
            if (!this.$v.credYprest.fuente.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            if (!this.$v.credYprest.fuente.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresNumFactura() {
            let errores = []
            if (!this.$v.credYprest.fuente.$dirty) { return errores }
            if (!this.$v.credYprest.fuente.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresMonto() {
            let errores = []
            if (!this.$v.credYprest.monto.$dirty) { return errores }
            if (!this.$v.credYprest.monto.required) { errores.push('Ingresa el monto.') }
            if (!this.$v.credYprest.monto.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.credYprest.monto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresProducto() {
            let errores = []
            if (!this.$v.partida.nombreProducto.$dirty) { return errores }
            if (!this.$v.partida.nombreProducto.required) { errores.push('Ingresa el nombre del producto.') }
            if (!this.$v.partida.nombreProducto.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            if (!this.$v.partida.nombreProducto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresUnidad() {
            let errores = []
            if (!this.$v.partida.unidad.$dirty) { return errores }
            if (!this.$v.partida.unidad.required) { errores.push('Ingresa el unidad del producto.') }
            if (!this.$v.partida.unidad.minLength) { errores.push('Ingresa al menos 2 caracteres.') }
            if (!this.$v.partida.unidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresPrecio() {
            let errores = []
            if (!this.$v.partida.precio.$dirty) { return errores }
            if (!this.$v.partida.precio.required) { errores.push('Ingresa el precio.') }
            if (!this.$v.partida.precio.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.precio.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresCantidad() {
            let errores = []
            if (!this.$v.partida.cantidad.$dirty) { return errores }
            if (!this.$v.partida.cantidad.required) { errores.push('Ingresa la cantidad de unidades.') }
            if (!this.$v.partida.cantidad.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.cantidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        setFechaFactura() {
            const fechaActual = new Date();
            const tzOffset = fechaActual.getTimezoneOffset() * 60000; // Obtener compensación de la zona horaria en milisegundos
            const fechaLocalISO = new Date(fechaActual - tzOffset)
            .toISOString()
            .substr(0, 10);
            this.credYprest.fechaDoc = fechaLocalISO;
        },
        formatDate(date) {
        if (!date) return null;

        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year.slice(-2)}`;
        },
        parseDate(date) {
        if (!date) return null;

        const [day, month, year] = date.split('/');
        return `20${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        updateFechaFactura() {
        this.credYprest.fechaDoc = this.parseDate(this.dateFormatted);
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            // await dbL.collection('fromSameHost').delete()
            // this.sameHost = false
            // this.sameHostName = ''
            this.consultarcredYprestm()
            
        },   
        async savePartida() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            if (this.editedIndexPartida > -1) {
                this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });
                Object.assign(this.credYprestmPartidas[this.editedIndexPartida], this.partida);

                try {
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                    
                    if (userNameDoc.exists()) {
                        var userName = userNameDoc.data();
                        await updateDoc(doc(db, 'usuarios', userName.uid, 'credsYprestms', this.credYprest.fuente, 'credYprestmPartidas', this.partida.ptdaId), {
                            cantidad: this.partida.cantidad,
                            producto: this.partida.nombreProducto,
                            unidad: this.partida.unidad,
                            fuente: this.credYprest.fuente,
                            numDoc: this.credYprest.numDoc,
                            autor: this.credYprest.autor.userName,
                            notas: this.credYprest.notas,
                            tipoDinero: this.credYprest.tipoDinero,
                        });

                        this.close();
                        this.dialog3 = false;
                        this.dialog2 = false;
                        this.mostrarExito('Se cargó la información exitosamente');
                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error cargando la información');
                } finally {
                    this.ocultarOcupado();
                }

            } else {
                let batch = writeBatch(db);
                let ptdaId = uuidv4();
                let subtotal = this.partida.precio * this.partida.cantidad;

                let partida = {
                    ptdaId,
                    cantidad: this.partida.cantidad,
                    precio: this.partida.precio,
                    producto: this.partida.nombreProducto,
                    unidad: this.partida.unidad,
                    subtotal,
                    fuente: this.credYprest.fuente,
                    fechaRegistro: this.credYprest.fechaRegistro,
                    numDoc: this.credYprest.numDoc,
                    notas: this.credYprest.notas,
                    tipoDinero: this.credYprest.tipoDinero,
                    autor: this.usuario.userName,
                    userNameHost: this.usuarioHost.userName,
                };

                batch.set(doc(db, 'usuarios', userName.uid, 'credsYprestms', this.credYprest.fuente, 'credYprestmPartidas', partida.ptdaId), partida);

                try {
                    await batch.commit();
                    this.credYprestmPartidas.push(this.partida);
                    this.mostrarExito('Se cargó la información exitosamente');
                } catch (error) {
                    this.mostrarError('Ocurrió un error cargando la información');
                } finally {
                    this.ocultarOcupado();
                }

                this.$nextTick(() => {
                    this.credYprestmPartidas = [];
                    this.consultarPartidas();
                });

                this.closePartida();
            }
        },
        async save() {     
            let userNameParametro = this.$route.params.userName.toLowerCase();           
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                
                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    
                    if (this.editedIndex > -1) {
                        this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });
                        Object.assign(this.credsYprestms[this.editedIndex], this.credYprest);

                        try {
                            await updateDoc(doc(db, 'usuarios', userName.uid, 'credsYprestms', this.credYprest.fuente), {
                                tipoDinero: this.credYprest.tipoDinero,
                                numDoc: this.credYprest.numDoc,
                                notas: this.credYprest.notas,
                                monto: Number(this.credYprest.monto),
                            });

                            this.close();
                            this.dialog = false;
                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }
                    } else {
                        let batch = writeBatch(db);
                        
                        let credYprest = {
                            fuente: this.credYprest.fuente,
                            numDoc: this.credYprest.numDoc,
                            monto: Number(this.credYprest.monto),
                            fechaMili: new Date().getTime(),
                            fechaDoc: this.credYprest.fechaDoc,
                            fechaRegistro: this.credYprest.fechaRegistro,
                            tipoDinero: this.credYprest.tipoDinero,
                            notas: this.credYprest.notas,
                            autor: {
                                colid: this.colaborador.colid || 'userHostAsColaborador',
                                userName: this.colaborador.userName,                            
                            },
                            userNameHost: userName.userName,                                                
                        };

                        batch.set(doc(db, 'usuarios', userName.uid, 'credsYprestms', credYprest.fuente), credYprest);

                        try {
                            await batch.commit();
                            this.credsYprestms.push(credYprest);
                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }

                        this.close();
                    }
                }    
            } catch (error) {
                this.mostrarError('Ocurrió un error cargando la información');
            }
        },
        close () {
            this.dialog = false
            setTimeout(() => {
                this.credYprest = Object.assign({}, this.defaultItem)
                this.credYprest.fechaDoc = this.date
                this.editedIndex = -1
            }, 300)        
        },
        closePartida () {            
            this.dialog3 = false
            setTimeout(() => {
                this.partida = Object.assign({}, this.defaultItemPartida)
                this.editedIndexPartida = -1
            }, 300)
        },
        editItem (item) {          
            this.editedIndex = this.credsYprestms.indexOf(item) 
            this.credYprest = Object.assign({}, item)
            this.dialog = true
        },
        async deleteItem(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            
            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Eliminar el ítem del array local
                    const index = this.credsYprestms.indexOf(item);
                    if (index > -1) {
                        this.credsYprestms.splice(index, 1);
                    }

                    // Eliminar el ítem de Firestore
                    await deleteDoc(doc(db, 'usuarios', userName.uid, 'credsYprestms', item.fuente));

                    this.mostrarExito('Se eliminó el concepto exitosamente');
                } else {
                    this.mostrarError('Ocurrió un error borrando la información');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error borrando la información');
            } finally {
                this.close();
            }
        },
        async consultaretiquetasFzsDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase();
            
            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                
                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Obtener etiquetas de Firestore
                    let etiquetasQuerySnapshot = await getDocs(collection(db, 'usuarios', userName.uid, 'etiquetasFzsDB'));
                    
                    etiquetasQuerySnapshot.forEach(doc => {
                        let datadb = doc.data();
                        
                        if (datadb.tipoEtiq === 'crédito/préstamo') {
                            this.etiquetasCreditoPrestamo.push(datadb.nombreEtq);    
                        }
                        if (datadb.tipoEtiq === 'Dinero') {
                            this.etiquetasDinero.push(datadb.nombreEtq);    
                        }
                    });
                } else {
                    this.mostrarError('Usuario no encontrado.');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando las Etiquetas.');
            }
        },
        async consultarcredYprestm() {         
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.today = new Date().getTime();
            this.daysPast = this.today - (86400000 * 30);

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Obtener los documentos de credYprestms
                    let resultado = await getDocs(collection(db, 'usuarios', userName.uid, 'credsYprestms'));

                    // Limpiar la lista actual antes de agregar nuevos datos
                    this.credsYprestms = [];

                    resultado.forEach(doc => {
                        let credYprest = doc.data();
                        this.credsYprestms.push(credYprest);
                    });
                } else {
                    this.mostrarError('Usuario no encontrado.');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando las credYprestms.');
            } finally {
                this.dialogProgress = false;
                this.consultarPartidas();
            }
        },
        async consultarPartidas() {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    let uName = userName.userName;

                    // Obtener las partidas con el userNameHost correspondiente
                    let resultado = await getDocs(collectionGroup(db, 'credYprestmPartidas')
                                                .where('userNameHost', '==', uName));

                    // Limpiar la lista actual antes de agregar nuevos datos
                    this.credYprestmPartidas = [];

                    resultado.forEach(subdoc => {
                        let partida = subdoc.data();
                        this.credYprestmPartidas.push(partida);
                    });
                } else {
                    this.mostrarError('Usuario no encontrado.');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando las partidas.');
            }
        },       
        async abrirInvYcred(item) {   
            this.selectedItem = item;
            this.dialog2 = true;
        },
        printData() {
          var divToPrint= this.$refs.printTable
          let newWin= window.open("");
          newWin.document.write(divToPrint.outerHTML);
          newWin.print();
          newWin.close();
        }
    }
    
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.text-right {
    text-align: right;
}
.tablasubtot {
    font-size: 1rem;
}

.custom-card {
  border-radius: 30px;
}
    
</style>

