<template>
  <v-layout justify-center align-center wrap>
    <v-flex xs12 ml-3 mt-2>
        <v-btn icon dark>                
            <a @click="$router.go(-1)"><v-icon small dark left color="black">arrow_back</v-icon></a>
        </v-btn>
        <span class="body-2">oficina virtual</span>
    </v-flex>
    <v-flex xs12 sm6>
      <v-toolbar>
        <v-toolbar-title>IMÁGENES A FILTRAR</v-toolbar-title>
      </v-toolbar>

      <v-card>
        <v-container
          fluid
          grid-list-md
        >
          <v-layout row wrap>
            <v-flex
              v-for="card in fotosPortadas"
              :key="card.fotoId"
              v-bind="{ [`xs${card.flex}`]: true }"
            >
              <v-card>
                <v-img
                  :src="card.url"
                  height="200px"
                >
                  <v-container
                    fill-height
                    fluid
                    pa-2
                  >
                    <v-layout fill-height>
                      <v-flex xs12 align-end flexbox>
                        <span class="headline white--text" v-text="card.fecha"></span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-img>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>favorite</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>bookmark</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>share</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { db } from '@/firebase'

  export default {
    data: () => ({
      fotosPortadas: [],
      cards: [
        { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 6 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
        { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 }
      ]
    }),
    created () {
        this.consultarFotosPortadaDB()
        // this.consultarCatalogosDB()
        
    },
    methods: {
        async consultarFotosPortadaDB() {         

          try {
              let resultado = await db.collectionGroup('fotos-portada')
                                        .get()                
                
              resultado.docs.forEach(doc => {
                  
                let fotdb = doc.data()
                
                this.fotosPortadas.push(fotdb)    
                
              })
          }
          catch (error) {                
              this.mostrarError('Ocurrió un error consultando las Fotos de Portadas.')                
          }     
      },
    },
  }
</script>