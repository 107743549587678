<template>
  <v-layout justify-center align-center column>
    <v-flex xs12 sm12 md12 lg8 text-xs-center>
      <h4 class="subheading my-5"></h4>      
      <!-- <h4 class="subheading my-5">VENDE, COLABORA, administra</h4>   -->
      <v-layout wrap align-center text-xs-center>
        <v-flex xs12 md6>                    
          <span class="display-2"><strong class="logoFont">nuVin</strong><span class="font-weight-thin logoFont">app</span></span><br>
          <!-- <v-btn
            outline 
            icon
            @click.stop="dialogSearch = true"
            >
            <v-icon>mdi-cloud-search-outline</v-icon>
          </v-btn> -->
          <v-dialog
            v-model="dialogSearch"
            max-width="290"
            >
            <v-toolbar
              color="white"
              >
              <v-toolbar-title></v-toolbar-title>
              <v-autocomplete
                v-model="select"
                :loading="loading"
                :items="items"
                :search-input.sync="search"
                cache-items
                class="mx-3"
                flat
                hide-no-data
                hide-details
                label="a qué nuVin quieres ir?"
                solo-inverted
              >ir</v-autocomplete>
              <button v-if="select != null" @click="irSearch" >Ir</button>
            </v-toolbar>        
          </v-dialog>
        </v-flex>
        <v-flex xs12 md6 my-4>
          <!-- <div class="text-xs-center">
            <v-btn outline :to="{ name: 'login' }">Ingresar</v-btn>
          </div> -->
          <div class="text-xs-center">
            <v-dialog
              v-model="dialog"
              width="500"
              >
              <!-- <template v-slot:activator="{ on }"> -->
                <!-- <v-btn
                  dark
                  v-on="on"
                >
                  Crea una Cuenta
                </v-btn> -->
              <!-- </template> -->

              <v-card>
                <v-card-title
                  class="headline"
                  primary-title
                >
                  Cuenta para uso:
                </v-card-title>

                <div>
                  <v-btn color="primary" :to="{ name: 'registro', params: {app: 'personal'} }">Personal (gratis)</v-btn>
                  <v-dialog v-model="dialogNego" fullscreen hide-overlay transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn dark v-on="on">Negocio (3 días de prueba)</v-btn>
                    </template>
                    <v-card>
                      <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialogNego = false">
                          <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Qué giro es tu Negocio?</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                        <v-btn
                          :to="{ name: 'registro-negocio-restaurante' }"
                          color="grey darken-4"
                          dark
                          icon
                          >
                          <v-icon>restaurant</v-icon>
                        </v-btn>restaurante                    
                    </v-card>
                  </v-dialog>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    flat
                    @click="dialog = false"
                  >
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <span>nubesdebits@gmail.com</span><br>
          <span>EN MANTENIMIENTO</span>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { db} from '@/firebase'

  export default {
    data () {
      return {
        dialog: false,
        dialogSearch: false,
        dialogNego: false,
        items: [],
        search: null,
        select: null,
        loading: false,
        usuariosDB: [],
        // componentKey: 0,
      }
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      }
    },
    created () {
      this.consultarUsuariosDB()
      // this.consultarNotificaciones()
    },
    methods: {
      ...mapMutations(['ocultarNotificacion', 'mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      async consultarUsuariosDB() {         

        try {
            let resultado = await db.collection('userNames')
                                      .get()                
              
            resultado.docs.forEach(doc => {
                
                let usersdb = doc.data()
                // if  (this.grupodb.uidG == userName.uid) {                     
                    this.usuariosDB.push(usersdb.userName)    
                // }
              //   this.forSaleCat(catdb)
            })
        }
        catch (error) {                
            this.mostrarError('Ocurrió un error consultando los usuarios.')                
        }     
      },
      querySelections (v) {
          this.loading = true
          // Simulated ajax query
          setTimeout(() => {
            this.items = this.usuariosDB.filter(e => {
              return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
            })
            this.loading = false
          }, 500)      
       },
      // forceRerender() {
      // this.componentKey += 1;
      // },
      irSearch () {
        this.$router.push({ path: `/${this.select}/datos`})
        this.dialogSearch = false
        this.select=[]
        // this.forceRerender()
      },
      irSearchUser () {
        this.$router.push({ path: `/${this.usuario.userName}/datos`})
        // this.dialogSearch = false, 
        // this.select=[]
        // this.forceRerender()
      },
    },
    
  }
  
</script>

<style>
  .logoFont {
    font-family: 'Comfortaa', cursive;
  }
</style>