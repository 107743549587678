<template>
  <!-- <div> -->
    <v-layout justify-center align-center wrap>
      <v-flex xs12 ml-3 mt-2>
        <v-btn icon dark>                
            <a @click="movingSameHost"><v-icon small dark left color="black">arrow_back</v-icon></a>
        </v-btn>
        <span class="body-2">oficina virtual</span>
      </v-flex>
      <v-flex xs12 sm6>
          <v-card class="elevation=5 ma-3">
              <v-layout>
                <v-flex xs12 class="text-xs-right">
                    <v-dialog
                        v-model="dialogHelp"
                        width="500"
                    >
                        <template v-slot:activator="{ on }">
                        <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                        </template>

                        <v-card>
                        <v-card-title class="headline">
                            Cómo funciona esta sección...
                        </v-card-title>

                        <v-card-text>
                            En esta área se agregan o cambian los datos que te gustaría que tus clientes supieran sobre tu negocio. Estos 
                            Datos se reflejan en el área pública de DATOS.
                            <ul>
                                <li>Dando click al botón "Agregar" se desplica un formulario para seleccionar el tipo de dato que desas agregar
                                  y su contenido.
                                </li>
                                <li>
                                  Al dar click en "Agregar" los datos se desplegarán en la tabla inferior al mismo tiempo que en el Área Pública.
                                </li>
                                <li>
                                  En la tabla, cada dato tiene las opciones de Cambiar su contenido (icono-lapiz) o borrarlos (icono-basurero).
                                </li>
                            </ul> 
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-flex>
              </v-layout>    
              <v-toolbar flat color="white">
                  <v-toolbar-title>Datos al <br>Público</v-toolbar-title>
                  <v-spacer></v-spacer>               
                  <v-divider
                      class="mx-2"
                      inset
                      vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" small v-on="on">Agregar</v-btn>
                      </template>
                      <v-card>
                      <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                          <v-container grid-list-md>
                          <v-layout wrap> 
                              <v-flex xs12>
                                  <v-select
                                  v-model="editedItem.tipoDato"
                                  :items="items"
                                  label="tipo de dato"
                                  outline
                                  @blur="$v.editedItem.tipoDato.$touch()" :error-messages="erroresTipoDato"
                                  ></v-select>
                              </v-flex>
                              <v-flex xs12 v-if="editedItem.tipoDato != 'map-marker' && editedItem.tipoDato != 'horario en establecimiento' && editedItem.tipoDato != 'horario en línea' && editedItem.tipoDato != 'card-text'">
                                <v-text-field v-model="editedItem.dato" counter="54" label="dato" @blur="$v.editedItem.dato.$touch()" :error-messages="erroresDato"></v-text-field>
                              </v-flex>
                              <v-flex xs12 v-if="editedItem.tipoDato == 'map-marker'">
                                <v-text-field v-model="editedItem.dato" counter="54" label="Dirección" @blur="$v.editedItem.dato.$touch()" :error-messages="erroresDato"></v-text-field>
                                <v-text-field v-model="editedItem.datoMap" label="Copia aquí el enlace de google maps"></v-text-field>
                              </v-flex>
                              <v-flex xs12 v-if="editedItem.tipoDato == 'horario en establecimiento'">
                                <v-textarea
                                  v-model="editedItem.dato"
                                  @blur="$v.editedItem.dato.$touch()" :error-messages="erroresDato"
                                  name="input-7-1"
                                  label="horario en establecimiento"
                                  value=""
                                  hint="ej. L a V: 8:00 - 20:00 hrs, S: 10:00 - 13:00 hrs."
                                ></v-textarea>
                              </v-flex>
                              <v-flex xs12 v-if="editedItem.tipoDato == 'horario en línea'">
                                <v-textarea
                                  v-model="editedItem.dato"
                                  @blur="$v.editedItem.dato.$touch()" :error-messages="erroresDato"
                                  name="input-7-1"
                                  label="horario virtual o en línea"
                                  value=""
                                  hint="ej. L a V: 8:00 - 20:00 hrs, S: 10:00 - 13:00 hrs."
                                ></v-textarea>
                              </v-flex>
                              <v-flex xs12 v-if="editedItem.tipoDato == 'card-text'">
                                <v-textarea
                                  v-model="editedItem.dato"
                                  @blur="$v.editedItem.dato.$touch()" :error-messages="erroresDato"
                                  name="input-7-1"
                                  label="Descripción del negocio o proyecto"
                                  value=""
                                  hint="ej. Desarrollamos páginas web y aplicaciones para cualquier dispositivo"
                                ></v-textarea>
                              </v-flex>
                          </v-layout>
                          </v-container>
                      </v-card-text>

                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="principal" flat @click="close">Cancelar</v-btn>
                          <v-btn color="principal" flat @click="guardarDatosPublicos" :depressed="$v.editedItem.$invalid" :disabled="$v.editedItem.$invalid">Guardar</v-btn>
                      </v-card-actions>
                      </v-card>
                  </v-dialog>
              </v-toolbar>
              <v-data-table
                  :headers="headers"
                  :items="datosDB"
                  class="elevation-1"
                  >
                  <template v-slot:items="props">
                      <td>{{ props.item.tipoDato }}</td>
                      <td class="text-xs-right">{{ props.item.dato }}</td>
                      <td class="justify-center align-center layout">
                      <v-icon
                          small
                          class="mr-2"
                          @click="editItem(props.item)"
                      >
                          edit
                      </v-icon>
                      <v-icon
                          small
                          @click="deleteItem(props.item)"
                      >
                          delete
                      </v-icon>
                      </td>
                  </template>
              <!-- <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template> -->
              </v-data-table>
          </v-card>            
      </v-flex>
    </v-layout>    
  <!-- </div> -->
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapMutations, mapState, mapActions } from 'vuex'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

  export default {
    data: () => ({      
      items: ['phone', 'whatsapp', 'mail', 'facebook', 'twitter', 'instagram', 'telegram', 'web', 'map-marker', 'nosotros', 'horario en establecimiento', 'horario en línea'],
      dialog: false,
      dialogHelp: false,
      headers: [
        {
          text: 'Tipo de Dato',
          align: 'left',
          value: 'tipoDato'
        },
        { text: 'Datos', value: 'dato', sortable: false, },        
      ],
      datosDB: [],
      editedIndex: -1,
      editedItem: {
        dato: '',
        datoMap: '',
        tipoDato: '',
      },
      defaultItem: {
        dato: '',
        datoMap: '',
        tipoDato: '',
      }
    }),

    created () {
      this.consultarWhereFrom()
      this.actualizarPerfilAuth()  
    },
    
    validations: {
        editedItem: {
            tipoDato: {
                required,
            },
            dato: {
                required,
            },
        },
    },

    computed: {
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      ...mapState('usuarioHost', ['perfilAuth']),
      formTitle () {
        return this.editedIndex === -1 ? 'Dato Nuevo' : 'Editar Dato'
      },
      erroresTipoDato() {
          let errores = []
          if (!this.$v.editedItem.tipoDato.$dirty) { return errores }
          if (!this.$v.editedItem.tipoDato.required) { errores.push('Ingresa el tipo de dato que quieres ingresar.') }      
          return errores
      },
      erroresDato() {
          let errores = []
          if (!this.$v.editedItem.dato.$dirty) { return errores }
          if (!this.$v.editedItem.dato.required) { errores.push('Ingresa el dato.') }      
          return errores
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    methods: {
      ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
      async movingSameHost() {
          await dbL.collection('fromSameHost').set([])
          await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName })
          this.$router.go(-1)
      },
      async consultarWhereFrom() {
          await dbL.collection('fromSameHost').get().then(fP => {
              this.sameHost = fP[0].sameHost
              this.sameHostName = fP[0].userHost
          })
          this.consultarColLB()
      },
      async consultarColLB() {
          if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName ) {
              // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
              this.$router.push({ name: 'Entrada' })
          } else if (this.sameHost == true) {
          
              this.colaboradores = []
              this.colaborador = ''
              if (this.perfilAuth == true) {
                  this.colaborador = this.usuarioHost
                  //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
              } else if (this.perfilAuth == false) {
              
                  await dbL.collection('colaboradores').get().then(cols => {
                  this.colaboradores = cols
                  })

                  if (this.colaboradores.length > 0) {
                      let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                      if (typeof colaborador === 'object') {
                          let convertINarray = Object.values(colaborador.estatusAccesos)
                          delete colaborador.estatusAccesos
                          colaborador.estatusAccesos = convertINarray
                          this.colaborador = colaborador  
                      } else {
                          this.colaborador = this.usuarioHost
                          //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                      } 
                  } else {
                      // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                      this.$router.push({ name: 'Entrada' })  
                  }
              } else {
                  // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                  this.$router.push({ name: 'Entrada' })
              }
          }
          await dbL.collection('fromSameHost').delete()
          this.sameHost = false
          this.sameHostName = ''
          this.consultarDatosDB()
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.datosDB[this.editedIndex], this.editedItem)
        } else {
          this.datosDB.push(this.editedItem)
        }
        this.close()
      },
      async guardarDatosPublicos() {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                  .doc(userNameParametro)
                                  .get()

        if(userNameDoc.exists) {
            let userName = userNameDoc.data()

          try {
            this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })

            if (this.editedIndex > -1) {

                this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                Object.assign(this.datosDB[this.editedIndex], this.editedItem)
                // Object.assign(this.datosDB[this.editedIndex], this.editedItem)

                try {

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('datosDB')
                            .doc(this.editedItem.did)
                            .update({
                                    dato: this.editedItem.dato,
                                    datoMap: this.editedItem.datoMap,                           
                                    tipoDato: this.editedItem.tipoDato,  
                            })
                    
                    // this.actualizarMinicatalogo( this.editedItem )
                    this.close()
                    this.dialog = false

                    this.mostrarExito('Se editó el Dato exitosamente')
                }              
                catch (error) {
                    this.mostrarError('Ocurrió un error editando el Dato')
                }
                finally {
                    this.ocultarOcupado()
                }
            } else {

                let batch = db.batch()
                let did = uuidv4()

                let editedItem = {
                    did,
                    dato: this.editedItem.dato,                  
                    datoMap: this.editedItem.datoMap,                  
                    tipoDato: this.editedItem.tipoDato,                  
                }

                batch.set(db.collection('usuarios').doc(userName.uid)
                            .collection('datosDB').doc(editedItem.did), editedItem) 

                try {
                    batch.commit()
                    this.datosDB.push(this.editedItem)
                    // this.actualizarMinicatalogo( this.editedItem )
                    
                    this.mostrarExito('Se registró el dato exitosamente')
                }
                catch (error) {
                    this.mostrarError('Ocurrió un error cargando el Dato')
                }
                finally {
                    this.ocultarOcupado()
                }
                this.close()
            }
              
            this.close()
          }
          catch (error) {
            this.mostrarError('Ocurrió un error cargando la información')
          }
          finally {                
            this.ocultarOcupado()
          }
        }
        this.close()
      },
      async consultarDatosDB() {         
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

        if(userNameDoc.exists) {
          let userName = userNameDoc.data()

          try {
              let resultado = await db.collection('usuarios')
                                      .doc(userName.uid)
                                      .collection('datosDB')
                                      .get()                
                  
              resultado.docs.forEach(doc => {
                  
                  let datadb = doc.data()
                  // if  (this.grupodb.uidG == .uid) {                     
                      this.datosDB.push(datadb)    
                  // }
              })
              
          }
          catch (error) {                
              this.mostrarError('Ocurrió un error consultando los Datos Públicos.')                
          }
        }     
      }, 
      editItem (item) {
        this.editedIndex = this.datosDB.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

        if(userNameDoc.exists) {
          let userName = userNameDoc.data()

          const index = this.datosDB.indexOf(item)
          confirm('Está seguro de querer eliminar este Dato?') && this.datosDB.splice(index, 1)
      
          try {
              
              this.editedItem = Object.assign({}, item)

              await db.collection('usuarios')
                      .doc(userName.uid)
                      .collection('datosDB')
                      .doc(this.editedItem.did)
                      .delete()                

              // this.actualizarMinicatalogo( this.catalogodb )
              this.mostrarExito('Se eliminó el Dato exitosamente')
          }
          catch (error) {
              this.mostrarError('Ocurrió un error borrando el Dato')                
          }
          finally {
              this.ocultarOcupado()
          }
        }
        this.close()
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      
    }
  }
</script>