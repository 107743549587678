import { db, auth } from '@/firebase'
import router from '@/router'
import { mapMutations } from 'vuex'
import { doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export default {
    namespaced: true,
    state: {
      usuarioHost: null,
      contTicket: null,
      perfilAuth: null,
    },
    mutations: {
      actualizarUsuarioHostState(state, usuarioHostdata) {
        state.usuarioHost = usuarioHostdata
      },
      actualizarPerfilAuth(state, perfilAuth) {
        state.perfilAuth = perfilAuth
      },
      actualizarContTicketState(state, contTicket) {
        state.contTicket = contTicket
        // state.contTicket.empresa = payload.empresa,
        // state.contTicket.RFC = payload.RFC,
        // state.contTicket.direccion = payload.direccion,
        // state.contTicket.telefono = payload.telefono,
        // state.contTicket.notapie = payload.nota
      },
      actualizarFotoPortada(state, fotoPortada) {
        if(!state.usuarioHost) { return }
        
        state.usuarioHost.fotoPortada = fotoPortada
      },
      actualizarFotoPortadaDimension(state, payload) {
        if (!state.usuarioHost) { return }
  
        switch (payload.dimension) {
          case 32:
            state.usuarioHost.fotoPortada32 = payload.url
            break
  
          case 64:
            state.usuarioHost.fotoPortada64 = payload.url
            break
  
          case 128:
            state.usuarioHost.fotoPortada128 = payload.url
            break
  
          case 256:
            state.usuarioHost.fotoPortada256 = payload.url
            break
  
          case 512:
            state.usuarioHost.fotoPortada512 = payload.url
            break
        }
      }
    },
    actions: {
      ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      
      async actualizarUsuarioHost({ commit }) {
        // Asegúrate de que userName existe antes de llamar a toLowerCase()
        let userNameParametro = router.currentRoute.params.userName
          ? router.currentRoute.params.userName.toLowerCase()
          : '';

        try {
          let userNameDocRef = doc(db, 'userNames', userNameParametro);
          let userNameDoc = await getDoc(userNameDocRef);

          if (userNameDoc.exists()) {
            let userName = userNameDoc.data();

            let usuarioDocRef = doc(db, 'usuarios', userName.uid);
            let usuarioDoc = await getDoc(usuarioDocRef);

            if (usuarioDoc.exists()) {
              let usuarioHostdata = usuarioDoc.data();
              commit('actualizarUsuarioHostState', usuarioHostdata);

              // Obtener la URL de la imagen de portada antes de eliminar uid
              const fotoPortada = usuarioHostdata.fotoPortada; // Asegúrate de que esto sea correcto
              const storage = getStorage();
              const uid = usuarioHostdata.uid;
              commit('actualizarFotoPortada', fotoPortada);

              const dimensiones = [512, 256, 128, 64, 32];

              try {
                for (let dimension of dimensiones) {
                  try {
                    const imageRef = ref(storage, `usuarios/${uid}/fotos-portada/${fotoPortada}-${dimension}x${dimension}.jpg`);
                    const url = await getDownloadURL(imageRef);
                    commit('actualizarFotoPortadaDimension', { dimension, url });
                  } catch (error) {
                    console.error(`Error al obtener la URL de la imagen ${dimension}x${dimension}`, error);
                  }
                }
              } catch (error) {
                console.error('Error al obtener las URLs de las imágenes', error);
              }

              delete usuarioHostdata.uid;
              delete usuarioHostdata.apellidos;
              delete usuarioHostdata.stripeId;
              // delete usuarioHostdata.fotoPortada
              delete usuarioHostdata.stripeLink;
              delete usuarioHostdata.keypaypal;
              delete usuarioHostdata.paypalClientId;

              commit('actualizarUsuarioHostState', usuarioHostdata);
            } else {
              // this.$router.push({ name: '404' })
            }
          } else {
            // this.$router.push({ name: '404' })
          }
        } catch (error) {
          console.log(error);
          // this.$router.push({ name: '404' }) no hay internet, o la nuvin no existe
          // this.mostrarError('no hay internet, o la nuvin no existe')
        }
      },

      async actualizarPerfilAuth({ commit }) {
        let userNameParametro = router.currentRoute.params.userName
          ? router.currentRoute.params.userName.toLowerCase()
          : '';

        try {
          let userNameDocRef = doc(db, 'userNames', userNameParametro);
          let userNameDoc = await getDoc(userNameDocRef);

          if (userNameDoc.exists()) {
            let userName = userNameDoc.data();

            let usuarioDocRef = doc(db, 'usuarios', userName.uid);
            let usuarioDoc = await getDoc(usuarioDocRef);

            if (usuarioDoc.exists()) {
              let usuarioHostdata = usuarioDoc.data();

              try {
                let currentUserDocRef = doc(db, 'usuarios', auth.currentUser.uid);
                let currentUserDoc = await getDoc(currentUserDocRef);

                if (currentUserDoc.exists()) {
                  let usuario = currentUserDoc.data();
                  if (usuario) {
                    let perfilAuth = false;

                    if (
                      usuarioHostdata.uid === auth.currentUser.uid &&
                      usuario.userName === usuarioHostdata.userName
                    ) {
                      perfilAuth = true;
                    } else if (usuario.email === usuarioHostdata.email) {
                      perfilAuth = true;
                    }

                    commit('actualizarPerfilAuth', perfilAuth);
                  } else {
                    commit('actualizarPerfilAuth', false);
                  }
                }
              } catch (error) {
                console.error('Error fetching current user document', error);
              }
            }
          }
        } catch (error) {
          console.error('Error fetching userName document', error);
          this.mostrarError('no hay internet, o la nuvin no existe');
        }
      },

      async borrarActualUsuarioHost({ commit }) {
        let usuarioHostData = {}
        let perfilAuth = null
        commit('actualizarUsuarioHostState', usuarioHostData)        
        commit('actualizarPerfilAuth', perfilAuth)
        // let fotoPortada = {}
        // commit('actualizarFotoPortadaHost', fotoPortada)
      },

      async actualizarContTicket({ commit }) {   
        let userNameParametro = router.currentRoute.params.userName
          ? router.currentRoute.params.userName.toLowerCase()
          : '';

        try {
          let userNameDocRef = doc(db, 'userNames', userNameParametro);
          let userNameDoc = await getDoc(userNameDocRef);

          if (userNameDoc.exists()) {
            let userName = userNameDoc.data();
            let ticketDocRef = doc(db, 'usuarios', userName.uid, 'contTicket', 'cTicket');
            let ticketDoc = await getDoc(ticketDocRef);

            if (ticketDoc.exists()) {
              let contTicket = ticketDoc.data();
              commit('actualizarContTicketState', contTicket);
            } else {
              // this.mostrarError('Faltan datos en el área de Configuración para incluir en el ticket.');
            }
          }
        } catch (error) {
          console.log(error);
        }
      }

      
    },
    getters: {
      usuarioHostdata (state) {
          return state.usuarioHost
      },
      // contTicket (state) {
      //   return state.contTicket
      // },
    }
}

  