<template>
  <v-layout align-center justify-center column>
    <v-flex xs12 sm6 offset-sm3>
      <v-btn icon dark @click="regresar">
        <v-icon small dark left color="black">arrow_back</v-icon>
      </v-btn>
      <span class="body-2">a tu área pública</span>
    </v-flex>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title>notificaciones</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>search</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list three-line>
          <template v-if="notificaciones.length == 0">
            <v-alert :value="true" color="white">
              <v-icon class="mr-3">mdi-message</v-icon>
              <span class="text-xs-center black--text">Por el momento no hay notificaciones.</span>
            </v-alert>
          </template>
          <template v-for="(item, index) in notificacionesAscHr">
            <v-subheader
              v-if="item.header"
              :key="item.header"
            >
              {{ item.header }}
            </v-subheader>

            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>

            <v-list-tile
              v-else
              :key="item.title"
              avatar
              ripple
            >
              <!-- <v-list-tile-avatar>
                <img :src="item.avatar">
              </v-list-tile-avatar> -->

              <v-list-tile-content>
                <v-list-tile-action-text v-if="item.cliente" class="font-weight-light">
                  {{ item.cliente }}
                </v-list-tile-action-text>
                <v-list-tile-action-text v-if="item.asunto" class="font-weight-light">
                  {{ item.asunto }}
                </v-list-tile-action-text>
                <v-list-tile-action-text v-if="item.total" class="font-weight-light">
                  {{ item.total }}
                </v-list-tile-action-text>
                <v-list-tile-action-text v-if="item.horaPedidoListo" class="caption">
                  Hora de solicitud: {{ item.hora }}
                </v-list-tile-action-text>
                <v-list-tile-action-text v-if="item.horaPedidoListo" class="caption">
                  Fecha: {{ item.fecha }}
                </v-list-tile-action-text>

                <!-- <span @click="irA">revisar</span> -->                
              </v-list-tile-content>

              <v-list-tile-action>
                <v-list-tile-action-text v-if="item.horaPedidoListo" class="caption">
                  Hora: {{ item.horaPedidoListo }}
                </v-list-tile-action-text>

                <estasSeguro>
                    <v-icon
                        color="grey darken-2"
                        slot="nombrebtn"
                        small                                       
                        >
                        delete
                    </v-icon>
                    <div slot="elemento">
                        <span>esta notificación?</span>
                    </div>
                    <!-- <div  icon ripple> -->
                        <div flat slot="btnaceptar" @click="deleteItem(item)">De acuerdo</div>
                    <!-- </div> -->
                </estasSeguro>
                <v-btn v-if="item.aComponente" fab small color="transparent" :to="{ name: item.aComponente }">
                  <v-icon color="grey darken-2" small>
                      mdi-arrow-right-thin-circle-outline
                  </v-icon>
                </v-btn>                
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { db } from '@/firebase'
import estasSeguro from '@/components/estasSeguro.vue'
// import Localbase from 'localbase'

// let dbL = new Localbase('db')

  export default {
    components: { estasSeguro },
    data () {
      return {
        notificaciones: [],
        notificacion: {
          ntfid: ''
        }
      }
    },
    created() {
      this.consulPerfil()
      
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        notificacionesAscHr() {
          return this.notificaciones.slice().sort( (a, b) => {return b.fechaMili - a.fechaMili;});
        },
    },
    methods: {
      ...mapMutations(['ocultarNotificacion', 'mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
      async consulPerfil() {
        await this.actualizarPerfilAuth()
        this.consulPerfilAuth()
      },
      async consulPerfilAuth() {
        if (this.perfilAuth == false) {                
          this.mostrarError('No tienes las credenciales para el área que querías accesar.')
          this.$router.push({ name: 'Entrada' })  
        } else {
          this.consultarNotificaciones()
        }
      },
      async consultarNotificaciones() {         
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
          let userName = userNameDoc.data()

          try {
            let resultado = await db
                            .collection('usuarios')                             
                            .doc(userName.uid)
                            .collection('notificaciones')
                            .orderBy('fecha', 'desc')
                            .get()                
            
            resultado.docs.forEach(doc => {
                
                let notf = doc.data()
                // if (comanda.mesero.colid == this.colaborador[0].colid) {                    
                this.notificaciones.push(notf)    
                // }
            })
          }
          catch (error) {                
              this.mostrarError('Ocurrió un error consultando las notificaciones.')                
          }
        }
      },
      async deleteItem (item) {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
          let userName = userNameDoc.data()

          const index = this.notificaciones.indexOf(item)
          this.notificaciones.splice(index, 1)
      
          try {
              
              this.notificacion = Object.assign({}, item)

              await db.collection('usuarios')                             
                      .doc(userName.uid)
                      .collection('notificaciones')
                      .doc(this.notificacion.ntfid)
                      .delete()   

              this.mostrarExito('Se eliminó la notificación exitosamente')            
              
          }
          catch (error) {
              this.mostrarError('Ocurrió un error borrando la notificación')                
          }
          finally {
              this.ocultarOcupado()
          }
        }
      },
      regresar() {
        this.$router.push( {name: 'Entrada', params: this.usuario.userName})
      }
    },
  }
</script>