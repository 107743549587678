<template>
    <v-layout justify-center align-center wrap>
        <v-flex xs12 v-if="dialogProgress == false">
        <v-card class="elevation=5 ma-3 py-3 custom-card">
            <v-layout>
                <v-flex xs2 class="text-xs-left ml-4">
                    <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-comment-edit-outline</v-icon>                
                </v-flex>
                <v-flex xs8 class="text-xs-left">
                    <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Planeación de Gastos</p></h3>
                    <h3 class="hidden-sm-and-up"><p class="text-xs-center title mt-3">Planeación de Gastos</p></h3>
                    {{colaborador.userName}}
                </v-flex>        
                <v-flex xs2 class="text-xs-right">
                    <v-dialog
                        v-model="dialogHelp"
                        width="500"
                    >
                        <!-- <template v-slot:activator="{ on }">
                        <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                        </template> -->

                        <v-card>
                        <v-card-title class="headline">
                            Cómo funciona esta sección...
                        </v-card-title>

                        <v-card-text>
                            Esta área te ayudará a organizar tus Gastos en orden de importancia, para que los consideres como metas y los vayas
                            ejecutando en corrcto orden y principalmente antes que cualquier Gasto no necesario o no útil. Haz el hábito de
                            registrar aquí los Gastos que piensas hacer en un futuro, para que los consideres con respecto a los 
                            demás en la lista; y así realices Gastos y movimientos de tu Dinero de manera consciente e inteligente.
                            <ul>
                            <li>
                                Hay dos pestañas: En proceso y Realizados. En Proceso estarán los proyectos que te asignaste como meta Y
                                cuando estos sean concluidos aparecerán en la pestaña de Realizados.
                            </li>
                            <li>Para establecer un Plan de Gasto, la pestaña de En Proceso, en el signo de "+" o botón "Agregar" dar click, 
                                se despliega un formulario para ingresar los datos del Plan de Gasto, y cuando esté sea realizado, desliza el switch
                                y de manera automática aparecerá en la pestaña Realizados.
                            </li>
                            </ul> 
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-spacer></v-spacer>
                <v-flex class="mr-3" xs12 sm6 md6 lg5 xl4>
                    <v-text-field
                        class="mx-3"
                        v-model="search"
                        flat
                        label=""
                        prepend-inner-icon="search"
                        solo-inverted
                    ></v-text-field>
                </v-flex>
            </v-layout>        
            <v-flex xs12>
            <template>
                <v-tabs centered :style="{'color': paletaColores.segundo}" dark icons-and-text>
                    <v-tabs-slider :style="{'color': paletaColores.primero}"></v-tabs-slider>
                    <v-spacer></v-spacer>
                    <v-tab href="#tab-1">
                    En Proceso
                    <v-icon>mdi-progress-wrench</v-icon>
                    </v-tab>
                    <v-spacer></v-spacer>
                    <v-tab href="#tab-2">
                    Realizados
                    <v-icon>mdi-check-all</v-icon>
                    </v-tab>
                    <v-spacer></v-spacer>
                    <v-tab-item v-for="i in 2" :key="i" :value="'tab-' + i">
                        <v-card v-if="i == 1" class="elevation=5 ma-3">
                            <v-toolbar flat color="white" >
                                <v-layout wrap>
                                    <v-flex xs12 sm6 md6 lg5 xl4 >
                                        <v-dialog v-model="dialog">
                                            <template v-slot:activator="{ on }">
                                                <v-btn small class="hidden-xs-only caption white--text" :style="{'background-color': paletaColores.primero}" mb-2 v-on="on" @click="consultarGastos">Agregar</v-btn>
                                                <v-btn icon top right absolute small fab dark :style="{'background-color': paletaColores.primero}" class="hidden-sm-and-up mt-2" v-on="on">
                                                    <v-icon class="pt-2 mt-1">add</v-icon>   
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ formTitle }}</span>
                                                </v-card-title>
                                                    <v-card-text>
                                                        <v-container grid-list-md>
                                                        <v-layout wrap >
                                                            <v-flex xs12 sm6 md4>
                                                                <v-text-field v-model="gastPlan.proveedor" label="Proveedor o referencia corta del gasto planeado" counter="30" @blur="$v.gastPlan.proveedor.$touch()" :error-messages="erroresProveedor"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                                <v-select @blur="$v.gastPlan.tipoGasto.$touch()" :error-messages="erroresTipoGasto"
                                                                v-model="gastPlan.tipoGasto"
                                                                :items="etiquetasGasto"
                                                                label="Subgrupo de Gasto (etiqueta-Gasto)"
                                                                ></v-select>
                                                                <v-select v-if="gastPlan.tipoGasto == 'abonoAcrédito/préstamo' && editedIndex == -1"
                                                                v-model="gastPlan.etiqCyP" @blur="$v.gastPlan.tipoGasto.$touch()" :error-messages="erroresTipoGasto"
                                                                :items="etiquetasCreditoPrestamo"
                                                                label="Fuente del crédito o préstamo (etiqueta-crédito/préstamo)"
                                                                ></v-select>                           
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                                <v-select @blur="$v.gastPlan.tipoDinero.$touch()" :error-messages="erroresTipoDinero"
                                                                v-model="gastPlan.tipoDinero" :items="saldoTotPorDinero"
                                                                label="Con qué se realizará el Pago (etiqueta-Dinero)"
                                                                :item-text="itemText"
                                                                ></v-select>                                        
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                                <v-select v-model="gastPlan.prioridad" :items="etiquetasPrioridad" label="Grado de importancia dentro del Tipo de Gasto" @blur="$v.gastPlan.prioridad.$touch()" :error-messages="erroresPrioridad"></v-select>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                                <v-text-field type="number" v-model="gastPlan.monto" label="Agrega un Monto estimado" @blur="$v.gastPlan.monto.$touch()" :error-messages="erroresMonto"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                                <!-- <v-text-field type="text" v-model="gastPlan.fechaDoc" label="Fecha de documento" locale="es-MX"></v-text-field> -->
                                                                <v-menu
                                                                    ref="menu1"
                                                                    v-model="menu1"
                                                                    :close-on-content-click="false"
                                                                    :nudge-right="40"
                                                                    lazy 
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    full-width
                                                                    max-width="290px"
                                                                    min-width="290px"
                                                                    >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field
                                                                        v-model="dateFormatted"
                                                                        label="Fecha de registro"
                                                                        persistent-hint
                                                                        prepend-icon="event"
                                                                        @blur="updateFechaFactura"
                                                                        v-on="on" readonly
                                                                        ></v-text-field>
                                                                    </template>
                                                                    <v-date-picker v-model="gastPlan.fechaDoc" no-title @input="menu1 = false"></v-date-picker>
                                                                </v-menu>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                                <v-text-field
                                                                    v-model="gastPlan.notas"
                                                                    counter="25"
                                                                    label="Si lo requieres agrega una descripción más amplia"
                                                                    outline
                                                                ></v-text-field>
                                                            </v-flex>
                                                            <v-flex>
                                                                <v-switch v-model="gastPlan.estatus" :label="gastPlan.estatus ? 'En proceso' : 'Realizado'"></v-switch>
                                                            </v-flex>
                                                        </v-layout>
                                                        </v-container>
                                                    </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="primary" flat @click="close">Cancelar</v-btn>
                                                    <v-btn color="primary" :depressed="$v.gastPlan.$invalid" :disabled="$v.gastPlan.$invalid" flat @click="save">Guardar</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-flex>                
                                    <!-- ETIQUETAS -->
                                    <v-flex class="mb-1 mr-5">
                                        <v-btn class="caption text-lowercase" small :style="{'background-color': paletaColores.primero}" dark :to="{ name: 'etiquetasFinanzas', params: {userName: usuarioHost.userName}}">Etiquetas</v-btn>
                                    </v-flex>                    
                                </v-layout>
                            </v-toolbar>            
                            <v-data-table
                                :headers="headers"
                                :items="filteredGastosEnProceso"
                                :search="search"
                                class="elevation-1"
                                >
                                <template v-slot:items="props">
                                    <td><strong>{{ props.item.proveedor }}</strong></td>
                                    <td class="justify-center align-center layout">       
                                        
                                        <v-icon v-if="!tieneMontoMayorACero(props.item)"
                                            class="mr-2" color="green"
                                            @click="editItem(props.item)" small>
                                            edit
                                        </v-icon>
                                        <v-icon v-else
                                            class="mr-2"
                                            disabled small>
                                            edit
                                        </v-icon>

                                        <estasSeguro v-if="!tieneMontoMayorACero(props.item) && (colaboradorBorrar || perfilAuth)">
                                            <v-icon
                                                color="red"
                                                slot="nombrebtn"
                                                small                                       
                                                >
                                                delete
                                            </v-icon>
                                            <div slot="elemento">
                                                <span>este concepto de Plan de Gasto?</span>
                                            </div>
                                            <div slot="btnaceptar">
                                                <v-btn flat @click="deleteItem(props.item)">Ok</v-btn>
                                            </div>
                                        </estasSeguro>
                                        <v-icon
                                            disabled
                                            small
                                            v-else
                                        >
                                            delete
                                        </v-icon>
                                    </td>
                                    <td>{{ props.item.tipoGasto }}</td>
                                    <td>{{ props.item.tipoDinero }}</td>
                                    <td>{{ props.item.prioridad }}</td>
                                    <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                                    <!-- <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                                    <td class="text-right">{{ '$' + getSumaMonto(props.item.proveedor).toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                                    <td class="text-right">{{ '$' + calcularDiferencia(props).toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td> -->
                                    <td>{{ props.item.fechaDoc }}</td>                                                       
                                    <td>{{ props.item.notas }}</td>
                                </template>
                                <template v-slot:no-results>
                                    <v-alert :value="true" color="error" icon="warning">
                                    Your search for "{{ search }}" found no results.
                                    </v-alert>
                                </template>
                            </v-data-table>
                            <template>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                            <!-- <span class="subheading mr-1">Total: $ {{ subtotSum }}</span><br> -->
                                            <!-- <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                            <span class="subheading mr-1">Total: $ {{ totSum }}</span> -->
                                        </v-card>
                                    </v-flex>            
                                    <!-- Ticket para imprimir -->
                                    <v-layout row justify-center>
                                        <v-dialog v-model="cardTicket" scrollable max-width="330px">
                                        <!-- <template v-slot:activator="{ on }">
                                            <v-btn :style="{'background-color': paletaColores.primero}" dark v-on="on">imprimir</v-btn>
                                        </template> -->
                                        <v-card>
                                            <v-card-title>Ticket de Plan de Gastos</v-card-title>
                                            <v-card-actions>
                                                <v-btn icon :style="{'background-color': paletaColores.primero}" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                            </v-card-actions>
                                            <v-divider></v-divider>
                                            <v-card-text style="height: 350px;" ref="printTable">                                                    
                                                    <v-flex xs12 sm12>
                                                    <v-card>                                                            
                                                        <v-list two-line>
                                                        <template >                                                                
                                                            <p class="text-xs-center">
                                                                <span>{{ contTicket.empresa }}</span><br>
                                                                <span>{{ contTicket.RFC }}</span><br>
                                                                <span>{{ contTicket.direccion }}</span><br>
                                                                <span>{{ contTicket.telefono }}</span><br>
                                                                <span>{{ contTicket.notapie }}</span>
                                                            </p>                                                                
                                                            <v-divider></v-divider>
                                                                <template>
                                                                    <v-data-table
                                                                        :headers="headers"
                                                                        :items="gastosPlaneadosDB"
                                                                        class="elevation-1"                                                            
                                                                        hide-actions
                                                                    >
                                                                        <template v-slot:items="props">
                                                                        <td><strong>{{ props.item.proveedor }}</strong></td>
                                                                        <td>{{ props.item.monto.toLocaleString('es-MX') }}</td>
                                                                        <td>{{ props.item.fechaDoc }}</td>                                                                 
                                                                        </template>
                                                                    </v-data-table>
                                                                    <v-card class="text-xs-right">
                                                                        <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                                        <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                                        <span class="body-2">Total: $ {{ totSum }}</span>
                                                                    </v-card>
                                                                    </template>
                                                                    <v-divider></v-divider>
                                                                    <span>{{ contTicket.empresa }}</span><br>
                                                                    <span>{{ contTicket.RFC }}</span><br>
                                                                    <span>{{ contTicket.direccion }}</span><br>
                                                                    <span>{{ contTicket.telefono }}</span><br>
                                                                    <span>{{ contTicket.notapie }}</span>      
                                                                </template>
                                                        </v-list>
                                                    </v-card>
                                                    </v-flex>                                                    
                                            </v-card-text>
                                            <v-divider></v-divider>                                                
                                        </v-card>
                                        </v-dialog>
                                    </v-layout>                                    
                                </v-layout>
                            </template>
                        </v-card>
                        <v-card v-if="i == 2" class="elevation=5 ma-3">
                            <v-toolbar flat color="white" >
                                <v-layout wrap>
                                                        
                                </v-layout>
                            </v-toolbar>            
                            <v-data-table
                                :headers="headers"
                                :items="filteredGastosRealizados"
                                :search="search"
                                class="elevation-1"
                                >
                                <template v-slot:items="props">
                                    <td><strong>{{ props.item.proveedor }}</strong></td>
                                    <td class="justify-center align-center layout">       
                                        
                                        <v-icon v-if="!tieneMontoMayorACero(props.item)"
                                            class="mr-2" color="green"
                                            @click="editItem(props.item)" small>
                                            edit
                                        </v-icon>
                                        <v-icon v-else
                                            class="mr-2"
                                            disabled small>
                                            edit
                                        </v-icon>

                                        <estasSeguro v-if="!tieneMontoMayorACero(props.item) && (colaboradorBorrar || perfilAuth)">
                                            <v-icon
                                                color="red"
                                                slot="nombrebtn"
                                                small                                       
                                                >
                                                delete
                                            </v-icon>
                                            <div slot="elemento">
                                                <span>este concepto de Plan de Gasto?</span>
                                            </div>
                                            <div slot="btnaceptar">
                                                <v-btn flat @click="deleteItem(props.item)">Ok</v-btn>
                                            </div>
                                        </estasSeguro>
                                        <v-icon
                                            disabled
                                            small
                                            v-else
                                        >
                                            delete
                                        </v-icon>
                                    </td>
                                    <td>{{ props.item.tipoGasto }}</td>
                                    <td>{{ props.item.prioridad }}</td>
                                    <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                                    <!-- <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                                    <td class="text-right">{{ '$' + getSumaMonto(props.item.proveedor).toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                                    <td class="text-right">{{ '$' + calcularDiferencia(props).toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td> -->
                                    <td>{{ props.item.fechaDoc }}</td>                                                       
                                    <td>{{ props.item.notas }}</td>
                                </template>
                                <template v-slot:no-results>
                                    <v-alert :value="true" color="error" icon="warning">
                                    Your search for "{{ search }}" found no results.
                                    </v-alert>
                                </template>
                            </v-data-table>
                            <template>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                            <!-- <span class="subheading mr-1">Total: $ {{ subtotSum }}</span><br> -->
                                            <!-- <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                            <span class="subheading mr-1">Total: $ {{ totSum }}</span> -->
                                        </v-card>
                                    </v-flex>            
                                    <!-- Ticket para imprimir -->
                                    <v-layout row justify-center>
                                        <v-dialog v-model="cardTicket" scrollable max-width="330px">
                                        <!-- <template v-slot:activator="{ on }">
                                            <v-btn :style="{'background-color': paletaColores.primero}" dark v-on="on">imprimir</v-btn>
                                        </template> -->
                                        <v-card>
                                            <v-card-title>Ticket de Plan de Gastos</v-card-title>
                                            <v-card-actions>
                                                <v-btn icon :style="{'background-color': paletaColores.primero}" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                            </v-card-actions>
                                            <v-divider></v-divider>
                                            <v-card-text style="height: 350px;" ref="printTable">                                                    
                                                    <v-flex xs12 sm12>
                                                    <v-card>                                                            
                                                        <v-list two-line>
                                                        <template >                                                                
                                                            <p class="text-xs-center">
                                                                <span>{{ contTicket.empresa }}</span><br>
                                                                <span>{{ contTicket.RFC }}</span><br>
                                                                <span>{{ contTicket.direccion }}</span><br>
                                                                <span>{{ contTicket.telefono }}</span><br>
                                                                <span>{{ contTicket.notapie }}</span>
                                                            </p>                                                                
                                                            <v-divider></v-divider>
                                                                <template>
                                                                    <v-data-table
                                                                        :headers="headers"
                                                                        :items="gastosPlaneadosDB"
                                                                        class="elevation-1"                                                            
                                                                        hide-actions
                                                                    >
                                                                        <template v-slot:items="props">
                                                                        <td><strong>{{ props.item.proveedor }}</strong></td>
                                                                        <td>{{ props.item.monto.toLocaleString('es-MX') }}</td>
                                                                        <td>{{ props.item.fechaDoc }}</td>                                                                 
                                                                        </template>
                                                                    </v-data-table>
                                                                    <v-card class="text-xs-right">
                                                                        <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                                        <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                                        <span class="body-2">Total: $ {{ totSum }}</span>
                                                                    </v-card>
                                                                    </template>
                                                                    <v-divider></v-divider>
                                                                    <span>{{ contTicket.empresa }}</span><br>
                                                                    <span>{{ contTicket.RFC }}</span><br>
                                                                    <span>{{ contTicket.direccion }}</span><br>
                                                                    <span>{{ contTicket.telefono }}</span><br>
                                                                    <span>{{ contTicket.notapie }}</span>      
                                                                </template>
                                                        </v-list>
                                                    </v-card>
                                                    </v-flex>                                                    
                                            </v-card-text>
                                            <v-divider></v-divider>                                                
                                        </v-card>
                                        </v-dialog>
                                    </v-layout>                                    
                                </v-layout>
                            </template>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </template>         
                        
            </v-flex> 
        </v-card>   
        </v-flex>

        <template v-else>
            <div class="mt-5 pt-5" >
                <div class="text-xs-center mt-5 pt-5">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    :style="{'color': paletaColores.primero}"
                    indeterminate
                    class="mt-5 pt-5"
                    ></v-progress-circular>
                </div>
            </div>                  
        </template>
    </v-layout>  
</template>
  
  <script>
  import estasSeguro from '@/components/estasSeguro.vue'
  import { required, minLength, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { collection, doc, getDoc, deleteDoc, getDocs, query, collectionGroup, where, updateDoc, writeBatch } from 'firebase/firestore';
  import { db } from '@/firebase'
  import { v4 as uuidv4 } from 'uuid';
  // import Multiselect from 'vue-multiselect'
  import Localbase from 'localbase'
  
  let dbL = new Localbase('db')
  
  export default {
      // props: ['colaboradorRecibido'],
      // components: { comandasInd },   
      // components: { Multiselect },
      components: { estasSeguro },
      data: () => ({
          dateFormatted: null,
          selectedItem: null,
          menu1: false,
          etiquetasPrioridad: ['1°', '2°', '3°', '4°', '5°', '6°', '7°', '8°', '9°', '10°'],
          etiquetasGasto: [],
          etiquetasDinero: ['Efectivo', 'Banco'],
          colaborador: '',
          colaboradores: [],
          contTicket: [],
          rowsPerPageItems: [4, 8, 12],
          pagination: {
              rowsPerPage: 4
          },
          dialogProgress: true,
          dialog: false,
          dialog2: false,
          dialog3: false,
          dialogHelp: false,
          cardTicket: false,
          meseroUser: [],
          partidaId: [],
          productoid: {
              name: 'Seleccionar',
              nombre: '',
              unidad: '',
              precio: 0,
              grupo: ''
          },
          gastosPlaneadosDB: [],
          gastPlan: {
              proveedor: '',
              autor: {
                  userName: ''
              },
              fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
              fechaDoc: null,
              prioridad: '',
              tipoGasto: '',
              tipoDinero: '',
              monto: '',
              notas: '',
              estatus: true        
          },
          editedIndex: -1,
          editedIndexPartida: -1,
          defaultItem: {
              proveedor: '',
              autor: {
                  userName: ''
              },
              fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
              fechaDoc: null,
              prioridad: '',
              tipoGasto: '',
              tipoDinero: '',
              monto: '',
              notas: '',   
              estatus: true                 
          },
          defaultItemPartida: {
              nombreProducto: '',
              unidad: '',
              subtotal: 0,
              cantidad: '',
              precio: '',
              ptdaId: '',
              proveedor: '',
              hora: ''
          },    
          credYprestmPartidas: [],
          partida: {
              nombreProducto: '',
              unidad: '',
              subtotal: 0,
              cantidad: '',
              precio: '',
              ptdaId: '',
              proveedor: '',
              hora: ''
              // estatusCocina: 'En Proceso',   
          },
          search: '',  
          selectComanda: null,
          today: '',
          daysPast: '',
          headers: [
            {
              text: 'Plan',
              align: 'center',
              value: 'proveedor'
            },
            { text: 'Acciones', value: '', sortable: false, },
            { text: 'Tipo de Gasto', value: 'tipoGasto', },
            { text: 'Recurso para Pago', value: 'tipoDinero', },
            { text: 'Prioridad', value: 'prioridad', },
            { text: 'Monto Estimado', value: 'monto', },
            { text: 'Fecha de documento', value: 'fechaDoc', }
          ],              
          headers2: [
            {
              text: 'Fecha',
              align: 'left',
              value: 'fechaFactura'
            },
            { text: 'Monto', value: 'monto', align: 'center', },
            { text: '#Documento', value: 'tipoGasto' },
            { text: 'Tipo de Dinero', value: 'prioridad', align: 'center', },
            { text: 'Notas', value: 'notas' },
          ], 
          headers3: [ //ticket
            {
              text: 'Producto',
              align: 'left',
              value: 'producto',
            },
            { text: 'Cantidad', value: 'cantidad', sortable: false, },
            { text: '$Precio', value: 'precio', sortable: false, },
          ],           
      }),
      validations() {
        const maxSaldo = this.getSaldoFromTipoDinero(this.gastPlan.tipoDinero);
        return {
          gastPlan: {
            proveedor: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
            tipoGasto: {
                required,
            },
            prioridad: {
                required,
            },
            monto: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(20),
                minValue: minValue(0),
                maxValue: maxValue(maxSaldo)
            },
            tipoDinero: {
                required,
            }
          },
        }
      },
      created() {      
          this.dateFormatted = this.formatDate(this.gastPlan.fechaDoc);
          this.consultarWhereFrom()
          this.actualizarPerfilAuth() 
          this.consultaretiquetasFzsDB()
        //   this.consultarGastos()
      },
      watch: {
        'gastPlan.fechaDoc'() {
          this.dateFormatted = this.formatDate(this.gastPlan.fechaDoc);
        }
      },
      mounted() {
          this.setFechaFactura();
      },
      computed: {
          ...mapState('sesion', ['usuario']),
          ...mapState('usuarioHost', ['usuarioHost']),
          ...mapState('usuarioHost', ['perfilAuth']),
          ...mapState('fzsPracticas', ['saldoTotPorDinero']),
          filteredGastosEnProceso() {
            return this.gastosPlaneadosDB.filter(item => item.estatus === true);
          },
          filteredGastosRealizados() {
            return this.gastosPlaneadosDB.filter(item => item.estatus === false);
          },
          
          calcularDiferencia() {
              return props => {
              const monto = props.item.monto;
              const proveedor = props.item.proveedor;
              const sumaMonto = this.getSumaMonto(proveedor);
              return sumaMonto - monto;
              }
          },
          tieneMontoMayorACero() {
              return propitem => {
              const proveedor = propitem.proveedor;
              // console.log(this.credYprestmPartidas[0])
              const count = this.credYprestmPartidas.filter(item => item.proveedor === proveedor).length;
              return count > 0;
              };
          },
          getSumaMonto() {
              return proveedor => {
              const suma = this.credYprestmPartidas.reduce((total, item) => {
                  if (item.proveedor === proveedor) {
                  return total + item.monto;
                  } else {
                  return total;
                  }
              }, 0);
              
              return suma;
              };
          },
          computedDateFormatted() {
              return this.formatDate(this.gastPlan.fechaDoc);
          },
          paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
          },
          colaboradorBorrar() {
  
              if(this.colaborador.switchBorrar == true){
                  // alert("Object found inside the array.");
                  return true
              } else{
                  // alert("Object not found.");
                  return false
              }
          },
          formTitle () {
              return this.editedIndex === -1 ? 'Nuevo Plan de Gasto' : 'Editar Plan de Gasto'  
          },
          formTitlePartidas () {
              return this.editedIndexPartida === -1 ? 'Nueva Partida' : 'Editar Partida'
          }, 
          subtotSum () {
              var sum = 0;
              this.gastosPlaneadosDB.forEach(x => {
                  sum += x.monto;
              });
              return sum            
          },
          impuesto () {
              let x = 0.16
              var imp = this.subtotSum * x
              // return imp
              return +(Math.round(imp + "e+2")  + "e-2");
          },
          totSum () {
              var tS = this.impuesto + this.subtotSum
              return tS
          },
          // totInversYcreds () {
          //     var sum = 0;
          //     this.gastosPlaneadosDB.forEach(x => {
          //         sum += x.monto;
          //     });
          //     return sum
          // },
          erroresTipoDinero() {
            let errores = []
            if (!this.$v.gastPlan.tipoDinero.$dirty) { return errores }
            if (!this.$v.gastPlan.tipoDinero.required) { errores.push('Selecciona de dónde estás tomando recursos para este gasto.') }
            return errores
          },
          erroresPrioridad() {
              let errores = []
              if (!this.$v.gastPlan.prioridad.$dirty) { return errores }
              if (!this.$v.gastPlan.prioridad.required) { errores.push('Selecciona el grado de prioridad.') }
              return errores
          },
          erroresProveedor() {
              let errores = []
              if (!this.$v.gastPlan.proveedor.$dirty) { return errores }
              if (!this.$v.gastPlan.proveedor.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
              return errores
          },
          erroresTipoGasto() {
              let errores = []
              if (!this.$v.gastPlan.tipoGasto.$dirty) { return errores }
              if (!this.$v.gastPlan.tipoGasto.required) { errores.push('Selecciona el Tipo de Gasto.') }
              return errores
          },
          erroresMonto() {
              let errores = []
              if (!this.$v.gastPlan.monto.$dirty) { return errores }
              if (!this.$v.gastPlan.monto.required) { errores.push('Ingresa el monto.') }
              if (!this.$v.gastPlan.monto.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
              if (!this.$v.gastPlan.monto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
              if (!this.$v.gastPlan.monto.minValue) { errores.push('Ingresa un número mayor a cero.') }
              if (!this.$v.gastPlan.monto.maxValue) { errores.push('Ingresa un monto igual o menor al Saldo-cuenta que seleccionaste.') }
              
              return errores
          },
      },
      methods: {
          ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
          ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
          ...mapActions('fzsPracticas', ['consultarGastos']),
          getSaldoFromTipoDinero(tipoDinero) {
                if (!tipoDinero) return Infinity;
                const saldo = tipoDinero.split('Saldo: ')[1];
                return parseFloat(saldo);
          },
          itemText(item) {
                return `${item.tipoDinero} - Saldo: ${item.saldo.toFixed(2)}`;
          },
          setFechaFactura() {
              const fechaActual = new Date();
              const tzOffset = fechaActual.getTimezoneOffset() * 60000; // Obtener compensación de la zona horaria en milisegundos
              const fechaLocalISO = new Date(fechaActual - tzOffset)
              .toISOString()
              .substr(0, 10);
              this.gastPlan.fechaDoc = fechaLocalISO;
          },
          formatDate(date) {
          if (!date) return null;
  
          const [year, month, day] = date.split('-');
          return `${day}/${month}/${year.slice(-2)}`;
          },
          parseDate(date) {
          if (!date) return null;
  
          const [day, month, year] = date.split('/');
          return `20${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
          },
          updateFechaFactura() {
          this.gastPlan.fechaDoc = this.parseDate(this.dateFormatted);
          },
          async consultarWhereFrom() {
              await dbL.collection('fromSameHost').get().then(fP => {
                  this.sameHost = fP[0].sameHost
                  this.sameHostName = fP[0].userHost
              }).catch(() => {
                  // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                  this.$router.push({ name: 'Entrada' })
              }).finally(() => {
                  this.consultarColLB()
              });
          },
          async consultarColLB() {
              if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                  // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                  this.$router.push({ name: 'Entrada' })
              } else if (this.sameHost == true) {
              
                  this.colaboradores = []
                  this.colaborador = ''
                  if (this.perfilAuth == true) {
                      this.colaborador = this.usuarioHost
                      //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                  } else if (this.perfilAuth == false) {
                  
                      await dbL.collection('colaboradores').get().then(cols => {
                      this.colaboradores = cols
                      })
  
                      if (this.colaboradores.length > 0) {
                          let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                          if (typeof colaborador === 'object') {
                              let convertINarray = Object.values(colaborador.estatusAccesos)
                              delete colaborador.estatusAccesos
                              colaborador.estatusAccesos = convertINarray
                              this.colaborador = colaborador  
                          } else {
                              this.colaborador = this.usuarioHost
                              //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                          } 
                      } else {
                          // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                          this.$router.push({ name: 'Entrada' })  
                      }
                  } else {
                      // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                      this.$router.push({ name: 'Entrada' })
                  }
              }
              // await dbL.collection('fromSameHost').delete()
              // this.sameHost = false
              // this.sameHostName = ''
              this.consultarGastosPlaneados()              
          },  
          async save() {     
                let userNameParametro = this.$route.params.userName.toLowerCase();           
                this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();
                        let batch = writeBatch(db);
                        let docRef = doc(db, 'usuarios', userName.uid, 'gastosPlaneados', this.gastPlan.gplid);
                        let objGastPlan = {
                            proveedor: this.gastPlan.proveedor,
                            prioridad: this.gastPlan.prioridad,
                            tipoGasto: this.gastPlan.tipoGasto,
                            tipoDinero: this.gastPlan.tipoDinero,
                            notas: this.gastPlan.notas,
                            monto: Number(this.gastPlan.monto),
                            estatus: this.gastPlan.estatus
                        };

                        if (this.editedIndex > -1) {
                            // Actualización de documento
                            batch.update(docRef, objGastPlan);               

                            if (this.gastPlan.tipoGasto === 'abonoAcrédito/préstamo') {    
                                await this.updatecredyprestm(this.gastPlan);
                            }

                            if (this.gastPlan.estatus === false) {
                                // Añadir o actualizar el gasto relacionado
                                batch.set(doc(db, 'usuarios', userName.uid, 'gastos', this.gastPlan.gaid), this.gastPlan);                

                                if (this.gastPlan.tipoGasto === 'abonoAcrédito/préstamo') {                       
                                    batch.set(doc(db, 'usuarios', userName.uid, 'credsYprestms', this.gastPlan.etiqCyP, 'credYprestmPartidas', this.gastPlan.gaid), this.gastPlan);
                                }
                            }
                        } else {
                            // Creación de nuevo documento
                            let gplid = uuidv4();
                            let gaid = uuidv4();
                            
                            let newGastPlan = {
                                gplid,
                                gaid,
                                proveedor: this.gastPlan.proveedor,
                                tipoGasto: this.gastPlan.tipoGasto,
                                tipoDinero: this.gastPlan.tipoDinero,
                                monto: Number(this.gastPlan.monto),
                                fechaMili: new Date().getTime(),
                                fechaDoc: this.gastPlan.fechaDoc,
                                fechaRegistro: this.gastPlan.fechaRegistro,
                                prioridad: this.gastPlan.prioridad,
                                notas: this.gastPlan.notas,
                                autor: {
                                    colid: this.colaborador.colid || 'userHostAsColaborador',
                                    userName: this.colaborador.userName,                            
                                },
                                userNameHost: userName.userName,
                                estatus: this.gastPlan.estatus                                               
                            };

                            batch.set(doc(db, 'usuarios', userName.uid, 'gastosPlaneados', gplid), newGastPlan);                

                            if (this.gastPlan.estatus === false) {
                                batch.set(doc(db, 'usuarios', userName.uid, 'gastos', gaid), newGastPlan);                

                                if (this.gastPlan.tipoGasto === 'abonoAcrédito/préstamo') {                                                   
                                    batch.set(doc(db, 'usuarios', userName.uid, 'credsYprestms', this.gastPlan.etiqCyP, 'credYprestmPartidas', gaid), newGastPlan);
                                }
                            }
                        }

                        // Ejecutar el batch
                        await batch.commit();
                        this.consultarGastosPlaneados();
                        this.mostrarExito('Se cargó la información exitosamente');
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error cargando la información');
                    console.error('Error guardando la información:', error);
                } finally {
                    this.ocultarOcupado();
                    this.close();
                }
            },
            async updatecredyprestm(item) {
                let userNameParametro = this.$route.params.userName.toLowerCase();

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Actualizar el documento en la colección 'credsYprestms'
                        await updateDoc(doc(db, 'usuarios', userName.uid, 'credsYprestms', item.etiqCyP, 'credYprestmPartidas', item.gaid), {
                            numDoc: item.numFactura,
                            proveedor: item.proveedor,
                            tipoGasto: item.tipoGasto,
                            tipoDinero: item.tipoDinero,
                            fechaFactura: item.fechaFactura,
                            notas: item.notas,
                            monto: Number(item.monto),
                        });

                        this.mostrarExito('Se actualizó la información de crédito/préstamo exitosamente');
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error actualizando la información.');
                    console.error('Error actualizando la información:', error);
                }
            },
          close () {
              this.dialog = false
              setTimeout(() => {
                  this.gastPlan = Object.assign({}, this.defaultItem)
                  this.gastPlan.fechaDoc = this.date
                  this.editedIndex = -1
              }, 300)        
          },
          closePartida () {            
              this.dialog3 = false
              setTimeout(() => {
                  this.partida = Object.assign({}, this.defaultItemPartida)
                  this.editedIndexPartida = -1
              }, 300)
          },
          editItem (item) {          
              this.editedIndex = this.gastosPlaneadosDB.indexOf(item) 
              this.gastPlan = Object.assign({}, item)
              this.dialog = true
          },
          async deleteItem(item) {
                let userNameParametro = this.$route.params.userName.toLowerCase();

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Eliminar el documento en la colección 'gastosPlaneados'
                        await deleteDoc(doc(db, 'usuarios', userName.uid, 'gastosPlaneados', item.proveedor));
                        
                        // Eliminar el item de la lista local
                        const index = this.gastosPlaneadosDB.indexOf(item);
                        if (index > -1) {
                            this.gastosPlaneadosDB.splice(index, 1);
                        }

                        this.mostrarExito('Se eliminó el concepto exitosamente');
                    } else {
                        this.mostrarError('No se encontró el usuario.');
                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error borrando la información.');
                    console.error('Error borrando la información:', error);
                }

                this.close();
            },
            async consultaretiquetasFzsDB() {
                let userNameParametro = this.$route.params.userName.toLowerCase();

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Obtener las etiquetas desde 'etiquetasFzsDB'
                        let etiquetasQuery = collection(db, 'usuarios', userName.uid, 'etiquetasFzsDB');
                        let resultado = await getDocs(etiquetasQuery);
                        
                        // Procesar los documentos obtenidos
                        resultado.docs.forEach(doc => {
                            let datadb = doc.data();

                            if (datadb.tipoEtiq === 'Dinero') {
                                let nom = datadb.nombreEtq;
                                this.etiquetasDinero.push(nom);
                            }
                            if (datadb.tipoEtiq === 'Gasto') {
                                let nom = datadb.nombreEtq;
                                this.etiquetasGasto.push(nom);
                            }
                        });
                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error consultando las Etiquetas.');
                    console.error('Error consultando las etiquetas:', error);
                }
            },
            async consultarGastosPlaneados() {
                this.gastosPlaneadosDB = [];
                let userNameParametro = this.$route.params.userName.toLowerCase();
                this.today = new Date().getTime();
                this.daysPast = this.today - (86400000 * 30);

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();

                        // Obtener los documentos de 'gastosPlaneados'
                        let gastosPlaneadosQuery = collection(db, 'usuarios', userName.uid, 'gastosPlaneados');
                        let resultado = await getDocs(gastosPlaneadosQuery);

                        // Procesar los documentos obtenidos
                        resultado.docs.forEach(doc => {
                            let gastPlan = doc.data();
                            this.gastosPlaneadosDB.push(gastPlan);
                        });

                        // Consultar gastos y partidas después de obtener los gastos planeados
                        await this.consultarGastos();
                        await this.consultarPartidas();
                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error consultando los gastos planeados.');
                    console.error('Error consultando los gastos planeados:', error);
                } finally {
                    this.dialogProgress = false;
                }
            },
            async consultarPartidas() {
                let userNameParametro = this.$route.params.userName.toLowerCase();

                try {
                    // Obtener el documento del usuario
                    let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                    if (userNameDoc.exists()) {
                        let userName = userNameDoc.data();
                        let uName = userName.userName;

                        // Consultar las partidas usando collectionGroup y where
                        let partidasQuery = query(
                            collectionGroup(db, 'credYprestmPartidas'),
                            where('userNameHost', '==', uName)
                        );
                        let resultado = await getDocs(partidasQuery);

                        // Procesar los documentos obtenidos
                        resultado.docs.forEach(doc => {
                            let partida = doc.data();
                            this.credYprestmPartidas.push(partida);
                        });

                    }
                } catch (error) {
                    this.mostrarError('Ocurrió un error consultando las partidas.');
                    console.error('Error consultando las partidas:', error);
                }
            },
          printData() {
            var divToPrint= this.$refs.printTable
            let newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML);
            newWin.print();
            newWin.close();
          }
      }
      
  }
  </script>
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  <style>
  .text-right {
      text-align: right;
  }
  .tablasubtot {
      font-size: 1rem;
  }
  
  .custom-card {
    border-radius: 30px;
  }
      
  </style>
  
  