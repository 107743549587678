<template>
    <v-card>
        <v-layout justify-center>
            <v-list two-line>
                <template>
                    <v-list-tile
                        v-for="item in miembrosDB" :key="item.miemid"
                        avatar
                        @click="goWithMember(item)"
                    >
                        <v-list-tile-avatar>
                        <!-- <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon> -->
                            <img :src="item.foto" large>
                            <!-- <v-btn fab dark large>
                                <v-icon dark>{{item.foto}}</v-icon>
                            </v-btn> -->
                        </v-list-tile-avatar>

                        <v-list-tile-content>
                            <v-list-tile-title>{{ item.userName }}</v-list-tile-title>
                        <!-- <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title> -->
                        </v-list-tile-content>
                    </v-list-tile>
                    <!-- <div v-for="item in miembrosDB" :key="item.miemid">
                        <v-btn @click="goWithMember(item)">{{ item.userName}}</v-btn>
                        
                    </div> -->
                    
                    <v-divider></v-divider>
                    
                </template>
            </v-list>
        </v-layout>
        <template v-if="miembrosDB.length < 1">
            <v-alert :value="true" color="transparent" icon="warning" dark class="black--text">
                Todavía no hay Miembros.
            </v-alert>
        </template>
    </v-card>
</template>

<script>
// import { mapState } from 'vuex'
import { db } from '@/firebase'
import { mapState, mapMutations } from 'vuex'
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    // props: ['usuarioRecibido'],    
    data() {
        return {
            miembrosDB: [''],
            member: '',
        }
    },
    created() {
        // this.usuario = this.usuarioRecibido
        this.consultarListaMiembros()
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
    },
    // watch: {
    //     '$route' () {
    //     // this.usuario = this.usuarioRecibido
    //         this.consultarUsuarioHost()
    //     }
    // },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarOcupado', 'mostrarError', 'ocultarOcupado']),
        async goWithMember(item) {
            await dbL.collection('memberLink').delete()
            await dbL.collection('memberLink').set([item])
            this.$router.push( { name: 'HomeSistema' } )            
        },
        async consultarListaMiembros() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()
            let userNameDoc = await db.collection('userNames')
                            .doc(userNameParametro)
                            .get()

            if(userNameDoc.exists) {
                let userHost = userNameDoc.data()
                this.miembrosDB = []
            
                try {
                    let resultado = await db.collection('usuarios')
                                            .doc(userHost.uid)
                                            .collection('miembros')
                                            .where('switchMiemAceptado', '==', true)
                                            .get()                
                        
                    resultado.docs.forEach(doc => {
                        
                        let miemdb = doc.data()
                        // if  (this.grupodb.uidG == userName.uid) {                     
                            this.miembrosDB.push(miemdb)    
                        // }
                    })
                    
                }
                catch (error) {                
                    this.mostrarError('Ocurrió un error, puede haber problemas con el internet.')
                }
            }   
        }, 
    }
}
</script>