<template>
    <v-layout justify-center align-center class="mt-5 pt-5">
      <v-flex xs12 sm8 md6 lg5 xl4>
        <!-- <v-slide-y-transition mode="out-in" @enter="enter"> -->
          <v-slide-y-transition mode="out-in">
            <v-card class="elevation-6">
            <v-toolbar color="transparent" card>
              <v-toolbar-title>
                Ingresa el nombre público para tu nuva nuVin
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field @blur="$v.userName.$touch()" :error-messages="erroresUserName" v-model="userName" label="Nombre para tu nuVin"></v-text-field>
              <div row>
                <avisoDePrivacidad/>
                <terminosYcondiciones/>
              </div>
              <v-checkbox v-model="checkbox" :label="`He leído y estoy de acuerdo con los 'Términos y Condiciones' y 'Aviso de Privacidad'`"></v-checkbox>
            </v-card-text>
            <v-card-text>
              <v-layout>
                <v-flex xs6>
                  <v-layout justify-end>
                    <v-btn v-if="checkbox == false" disabled>Registrarse</v-btn>
                    <v-btn v-if="checkbox == true" @click="registrar" :depressed="$v.userName.$invalid" :disabled="$v.userName.$invalid" color="principal white--text">Registrarse</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
      </v-flex>
    </v-layout>
  </template>
  
  <script>
  import { required, minLength, maxLength, alphaNum } from 'vuelidate/lib/validators'
  import { mapMutations, mapGetters, mapState } from 'vuex'
  import { db } from '@/firebase'
  import avisoDePrivacidad from '@/components/avisoDePrivacidad'
  import terminosYcondiciones from '@/components/terminosYcondiciones'
  import { v4 as uuidv4 } from 'uuid';
  import Localbase from 'localbase'
  
  let dbL = new Localbase('db')
  
  export default {
    components: { avisoDePrivacidad, terminosYcondiciones },
    data() {
      return {
        itemA: '',
        redirectHost: '',
        checkbox: false,
        dialog3: false,
        dialog4: false,
        userName: '',
        app: '',
      }
    },
    validations: {
      userName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(33),
        alphaNum
      }
    },
    computed: {
      ...mapGetters('sesion', ['saludo']),
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      ...mapState('usuarioHost', ['perfilAuth']),
      erroresUserName() {
        let errores = []
        if (!this.$v.userName.$dirty) { return errores }
        if (!this.$v.userName.required) { errores.push('Ingresa tu nombre Comercial.') }
        if (!this.$v.userName.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
        if (!this.$v.userName.maxLength) { errores.push('Ingresa máximo 33 caracteres.') }
        if (!this.$v.userName.alphaNum) { errores.push('Ingresa sólo letras y números, sin espacios') }
        return errores
      },
      activEstat() {
        var x = '';
        if (this.app == 'personal') {
          x = 'Activada'
        } else {
          x = 'Prueba'
        }
        return x;
      }
    },
    created() {
      // this.verifCtaRedirect()
      this.consultarItemsAppPersonal()
    },
    methods: {
      ...mapMutations(['mostrarOcupado', 'ocultarOcupado', 'mostrarExito', 'mostrarError', 'mostrarAdvertencia']),
      async verifCtaRedirect () {
  
        try {
          await dbL.collection('ctaRedirect').get().then(item => {
            this.redirectHost = item[0].redirectHost
          })
        } catch (error) {
          // console.log('error: ', error)
        }
      },
      async consultarItemsAppPersonal() {

        try {
            let resultado = await db.collection('itemsApps')
                                    .doc('personal')
                                    .get()

            this.itemA = resultado.data(); // Asignar el objeto al componente
            
        }
        catch (error) {                
            this.mostrarError('Ocurrió un error consultando las Apps.')                
        }
      },
      async registrar() {
        if (this.$v.userName.$invalid) { return }
        
  
        let userNameExistente = await db.collection('userNames')
                                        .doc(this.userName.toLowerCase())
                                        .get()
  
        if(userNameExistente.exists) {
          this.mostrarAdvertencia(`El nombre Comercial '${this.userName}', ya está tomado, selecciona uno diferente`)
          return
        }
  
        try {
          this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })
          
          await this.guardarUsuario()          
        }
        catch (error) {
  
          switch(error.code) {
            case 'auth/email-already-in-use':
              this.mostrarAdvertencia('Ya se ha registrado esta diección de email con anterioridad')
              break 
  
            default:
              this.mostrarError('Ocurrió un error registrando tu cuenta. Inténtalo más tarde.')
              break
          }
        }
        finally {
          // dbL.collection('ctaRedirect').delete()   
          this.ocultarOcupado()
        }
      },
      async guardarUsuario() {
        
        let redirectHost = '';
        if (this.redirectHost != null) {
            redirectHost = this.redirectHost;
        } else {
          redirectHost = 'no-redirect';
        }
        
        let userNameParametro = this.$route.params.userName.toLowerCase()

        try {
            let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

            if(userNameDoc.exists) {
                var userData = userNameDoc.data()
                let uid = uuidv4()

                let usuarioHijo = {
                uid,
                email: this.usuario.email,
                redirectHost: redirectHost,
                userName: this.userName, //Nombre comercial
                fechaInicioCuenta: new Date(),
                fechaInitPrueba: new Date(),
                app: this.itemA.app,
                administrador: true,
                logo: '',
                bolsaDeTrabajo: false,
                activa: this.activEstat,
                nivelPlan: 'prueba',
                permisos: '', // this.itemApp[0].permisosPrueba,
                designColors: {
                    primero: '',
                    segundo: '',
                    tercero: '',
                    cuarto: ''
                }
                }
                
                let userNameHijo = {
                userName: this.userName,
                uid,
                email: this.usuario.email,
                }

                let enlid = uuidv4()
                let enl = {
                enlid,
                enlace: this.userName,
                tipoEnlace: '',
                autor: this.usuario.userName
                }
                
                await db.collection('userNames').doc(this.userName.toLowerCase()).set(userNameHijo);
                
                await db.collection('usuarios').doc(usuarioHijo.uid).set(usuarioHijo);       

                await db.collection('usuarios').doc(userData.uid).collection('enlacesnuvin').doc(enl.enlid).set(enl);
                this.$emit('nuevo-enlace', enl); // Emitir el evento con el objeto enl como argumento
                
                this.itemA = this.itemA.secciones
        
                for (let index = 0; index < this.itemA.length; index++) {
                const element = this.itemA[index];
        
                await db.collection('usuarios').doc(usuarioHijo.uid).collection('comprasAppExtra')
                        .doc(element.app)
                        .set(element);
                }
        
                this.mostrarExito('Se ha creado una nueva nuVin con éxito. Puedes ingresar a ella en el botón de menú de área pública "Enlaces".')
            }
        } catch (error) {                
            // this.mostrarError('Ocurrió un error consultando los extra.')              
        }
        this.checkbox= false;
        this.userName= '';
      },
    },
  }
  </script>
  