
// let planes = () => import( '../checkout/client/index.html')
let addproductos = () => import( '../views/restaurante/addProductos.vue')
let cocina = () => import( '../views/restaurante/coCina.vue')
let clientePedEstatus = () => import( '../views/restaurante/clientePedEstatus.vue')
let autoPedidoCliente = () => import( '../views/restaurante/autoPedidoCliente.vue')
// let Colaborador = () => import( '../views/restaurante/Colaborador.vue')
let autoPedido = () => import( '../views/restaurante/autoPedido.vue')

export default [
    // {
    //   path: '/:userName/planes',
    //   name: 'planes',
    //   component: planes,
    //   meta: {
    //     autenticado: true
    //   }
    // },
    
      {
        path: '/:userName/admin/productos',
        name: 'addproductos',
        component: addproductos,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/auto-pedido',
        name: 'autoPedido',
        component: autoPedido,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/salida-producto',
        name: 'cocina',
        component: cocina,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/restaurante/persona-especial-estatus/:userNameCli',
        name: 'cliPedEstat',
        component: clientePedEstatus,
        // meta: {
        //   autenticado: true
        // }
      },
      {
        path: '/:userName/restaurante/auto-pedido-cliente',
        name: 'auto-pedido-cliente',
        component: autoPedidoCliente,
      },
]