
import { db, auth, storage, doc, getDoc, ref, getDownloadURL } from '@/firebase';
import router from '@/router'

const dimensiones = [512, 256, 128, 64, 32]

export default {
    namespaced: true,
    state: {
      usuario: null,
      fotoPortada: null,
      fotoPortadaDimensiones: {},
      perfilAuth: false,
    },
    mutations: {
      actualizarUsuario(state, usuario) {
        state.usuario = usuario
      },
      actualizarDatosMini(state, payload) {
        if(!state.usuario) { return }

        state.usuario.email = payload.email
        state.usuario.telefono = payload.telefono
        state.usuario.whatsapp = payload.whatsapp
        state.usuario.facebook = payload.facebook
        state.usuario.instagram = payload.instagram
        state.usuario.direccion = payload.direccion
      },         
      actualizarFotoPortada(state, fotoPortada) {                
        state.fotoPortada = fotoPortada
      },
      actualizarFotoPortadaDimension(state, payload) {
        if (!state.usuario) { return }
  
        switch (payload.dimension) {
          case 32:
            state.usuario.fotoPortada32 = payload.url
            break
  
          case 64:
            state.usuario.fotoPortada64 = payload.url
            break
  
          case 128:
            state.usuario.fotoPortada128 = payload.url
            break
  
          case 256:
            state.usuario.fotoPortada256 = payload.url
            break
  
          case 512:
            state.usuario.fotoPortada512 = payload.url
            break
        }
      }
    },
    actions: {
      actualizarUsuario({ commit }, usuario) {
        commit('actualizarUsuario', usuario);
      },
      actualizarFotoPortada({ state, commit }, fotoPortada) {
     
        commit('actualizarFotoPortada', fotoPortada)
  
        let ref = storage.ref()
        let uid = state.usuario.uid
        
        dimensiones.forEach(dimension => {
          // const path = `usuarios/${uid}/fotos-portada/${fotoPortada}-${dimension}x${dimension}.jpg`;
      
          ref.child(`usuarios/${uid}/fotos-portada/${fotoPortada}-${dimension}x${dimension}.jpg`)
             .getDownloadURL()
             .then(url => {
              
               commit('actualizarFotoPortadaDimension', { dimension, url })
             })
             .catch(error => {
              console.error(`Error al obtener la URL de descarga para la dimensión ${dimension}:`, error);
            });
        })
      },
      async iniciarSesion({ commit, getters }, uid ) {
        try {
          const userDocRef = doc(db, 'usuarios', uid);
          const docSnap = await getDoc(userDocRef);

          if(docSnap.exists) {
            let usuario = docSnap.data();
            commit('actualizarUsuario', usuario);
            
            const fotoPortada = usuario.fotoPortada;
            commit('actualizarFotoPortada', fotoPortada);
            
            const dimensiones = [512, 256, 128, 64, 32];
            const storageRef = ref(storage);

             try {
                dimensiones.forEach(async (dimension) => {
                  try {
                    const url = await getDownloadURL(ref(storageRef, `usuarios/${uid}/fotos-portada/${fotoPortada}-${dimension}x${dimension}.jpg`));
                    commit('actualizarFotoPortadaDimension', { dimension, url });
                  } catch (error) {
                    console.error(`Error al obtener la URL de la imagen para ${dimension}x${dimension}`, error);
                  }
                });
              } catch (error) {
                console.error('Error al obtener las URLs de las imágenes de portada:', error);
              }

            delete usuario.keypaypal
            delete usuario.paypalClientId
            delete usuario.uid
            commit('actualizarUsuario', usuario);

            switch(router.currentRoute.name) {
              case 'login':
                commit('mostrarExito', getters.saludo, { root: true });                
                break

              case 'envio-verificacion-email':
                commit('mostrarExito', getters.saludo + ', tu contraseña ha sido cambiada exitosamente.', { root: true})
                break
            }            
          } else {
            router.push({ name: 'registro'})
          }
        } catch (error) {
          console.error('Error consultando información del usuario:', error);
          commit('mostrarError', 'Ocurrió un error consultando tu información.', { root: true })
        }
      },
      cerrarSesion({ commit }) {
        auth.signOut()
        commit('actualizarUsuario', null)
      },
    },
    getters: {
      usuario (state) {
        return state.usuario
      },

      saludo(state) {
        if (!state.usuario) { 
          return `¡Bienvenid@! `
        }  
        let vocal = state.usuario.sexo && state.usuario.sexo == 'F' ? 'a' : 'o'
        return `¡Bienvenid${vocal} ${state.usuario.userName}!`
      },
    }
}

  