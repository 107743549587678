<template>
    <v-layout justify-center align-center wrap>
        <v-flex xs12 ml-3 mt-2>
            <v-btn icon dark>                
                <a @click="movingSameHost"><v-icon small dark left color="black">arrow_back</v-icon></a>
            </v-btn>
            <span class="body-2">oficina virtual</span>
        </v-flex>
        <v-flex xs12 sm10>
            <template v-if="usuarioHost.filtrosComercio == true">
                <v-layout row justify-center>
                    <!-- zona de agregar filtros estaticos -->
                    <v-dialog v-model="dialogFiltros" persistent max-width="290">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="caption" color="primary" dark v-on="on">filtros</v-btn>
                        </template>
                        <v-card>
                            <v-card-text class="body-1">Aquí puedes agregar filtros por etiqueta de tus productos, 
                                para facilitar la experiencia de búsqueda a tus clientes</v-card-text>
                            <template>
                            <div>
                                <v-toolbar flat color="white">
                                <v-toolbar-title>Filtros</v-toolbar-title>
                                <v-divider
                                    class="mx-2"
                                    inset
                                    vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialogAddFiltros" max-width="500px">
                                    <template v-slot:activator="{ on }">
                                    <v-btn color="primary" dark class="mb-2 caption" small v-on="on">Agregar Filtro</v-btn>
                                    </template>
                                    <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitleFiltro }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container grid-list-md>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6 md4>
                                            <v-text-field v-model="editedItemFiltro.etiqueta" label="Etiqueta"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="grey darken-1" flat @click="closeFiltro">Cerrar</v-btn>
                                        <v-btn color="grey darken-1" flat @click="saveFiltro">Guardar</v-btn>
                                    </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                </v-toolbar>
                                <v-data-table
                                    :headers="headersFiltros"
                                    :items="filtros"
                                    class="elevation-1"
                                    hide-actions
                                    >
                                    <template v-slot:items="props">
                                        <td>{{ props.item.etiqueta }}</td>
                                        <td class="justify-center layout px-0">
                                        <estasSeguro small>
                                            <v-icon
                                                color="grey darken-2"
                                                slot="nombrebtn"
                                                small                                   
                                                >
                                                delete
                                            </v-icon>
                                            <div slot="elemento">
                                                <span>esta Etiqueta? Si ya hay productos que tengan esta etiqueta y la eliminas, 
                                                    deberás ubicarlos y agregarles una etiqueta nueva y existente.
                                                </span>
                                            </div>
                                            <div slot="btnaceptar">
                                                <v-btn flat @click="deleteItemFiltro(props.item)">Ok</v-btn>
                                            </div>
                                        </estasSeguro>
                                        </td>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-alert :value="true" color="error" icon="warning">
                                            Todavía no hay etiquetas para filtrar.
                                        </v-alert>
                                    </template>
                                </v-data-table>
                            </div>
                            </template>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="grey darken-1" flat @click="dialogFiltros = false">Cerrar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-layout>
            </template>
            <span v-if="existenciasAlLimite == true" class="red--text pl-5">Verificar, producto en existencia al límite</span>            
            <v-card class="elevation=5 ma-3">
                <v-toolbar flat color="white">
                    <v-toolbar-title>Productos<br>Estatus</v-toolbar-title>
                    <v-divider
                        class="mx-2"
                        inset
                        vertical
                    ></v-divider>
                    <v-spacer></v-spacer>                
                                <!-- área para agregar producto de catalogo-->
                    <div v-if="usuarioHost.LectorCodigo == true">
                        <v-btn v-if="QCatlg < catlgNumPermiso" small class="caption" :to="{ name: 'lector-codigo-barras', params: { userName: usuarioHost.userName } }" >                
                            lbrr
                        </v-btn>
                    </div>
                    

                    <v-dialog v-model="dialogAddC" max-width="500px">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="QCatlg < catlgNumPermiso" class="hidden-xs-only mb-2" color="primary" dark v-on="on" @click="emptyFieldsC">Nuevo producto</v-btn>
                            <v-btn v-else disabled class="hidden-xs-only" mb-2 >Nuevo producto</v-btn>
                            <div v-if="QCatlg < catlgNumPermiso">
                                <v-btn small class="hidden-sm-and-up caption mb-2" color="primary" dark v-on="on" @click="emptyFieldsC">agregar</v-btn>    
                            </div>                                
                            <div v-else>
                                <v-btn disabled class="hidden-sm-and-up caption" small icon>agregar</v-btn>
                                <v-tooltip v-model="show" top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                        <v-icon>mdi-face-agent</v-icon>
                                        </v-btn>
                                    </template>
                                    <span class="caption">Has superado la cantidad de productos permitido por el plan, solicita uno adicional en: nubesdebits@gmail.com.</span>
                                </v-tooltip> 
                            </div>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitleC }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container grid-list-md>
                                    <v-layout wrap>
                                        <v-flex xs12>
                                            <v-text-field v-model="catalogodb.nombre" label="Nombre del producto" counter="40" autofocus @blur="$v.catalogodb.nombre.$touch()" :error-messages="erroresNombre"></v-text-field>
                                            <v-text-field v-model="catalogodb.descripcion" label="Caractrísticas principales del producto" counter="100" autofocus @blur="$v.catalogodb.descripcion.$touch()"  :error-messages="erroresDescripcion"></v-text-field>
                                            <v-text-field v-model="catalogodb.unidad" label="Tipo de Unidad del producto" autofocus @blur="$v.catalogodb.unidad.$touch()" :error-messages="erroresUnidad" ></v-text-field>
                                            <v-text-field v-model="catalogodb.costo" label="Costo del producto" type="number" autofocus @blur="$v.catalogodb.costo.$touch()" :error-messages="erroresCosto" ></v-text-field>
                                            <v-text-field v-model="catalogodb.precio" label="Precio neto del producto" type="number" autofocus @blur="$v.catalogodb.precio.$touch()" :error-messages="erroresPrecio" ></v-text-field>
                                            <v-text-field v-model="catalogodb.existencia" label="# de unidades en Existencia" type="number" autofocus @blur="$v.catalogodb.existencia.$touch()" :error-messages="erroresExistencia" ></v-text-field>
                                            <v-text-field v-model="catalogodb.cantAlert" label="# en Stock para recibir alerta" type="number" autofocus @blur="$v.catalogodb.cantAlert.$touch()" :error-messages="erroresCantAlert" ></v-text-field>
                                            <div v-if="filtros.length > 0">
                                                <v-select
                                                v-model="select"
                                                :hint="`${select.etiqueta}`"
                                                :items="filtros"
                                                item-text="etiqueta"
                                                label="Escoge una Etiqueta para filtrar"
                                                persistent-hint
                                                return-object
                                                single-line
                                                ></v-select>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <!-- área de ingreso de fotos -->
                            

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" flat @click="closeC">Cancelar</v-btn>
                                <v-btn color="primary" :depressed="$v.catalogodb.$invalid" :disabled="$v.catalogodb.$invalid" flat @click="guardarPdeCatalogo">Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
                <v-data-table
                    :headers="headersC"
                    :items="catalogosDB"
                    class="elevation-1"
                    >
                    <template v-slot:items="props">
                        <td>{{ props.item.nombre }}</td>
                        <td>{{ props.item.descripcion }}</td>
                        <td class="text-xs-right">{{ Number(props.item.costo) }}</td>
                        <td class="text-xs-right">{{ Number(props.item.precio) }}</td>
                        <td>                             
                            <v-btn icon flat :to="{ name: 'edicion-minprod-catalogo', params: { userName: usuarioHost.userName, pid: props.item.pid} }">
                                <v-icon
                                small
                                class="mr-2"                                
                                >
                                insert_photo
                             </v-icon>       
                            </v-btn>                             
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItemC(props.item)"
                                >
                                edit
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItemC(props.item)"
                                >
                                delete
                            </v-icon>
                        </td>
                        <td class="text-xs-center">{{ props.item.codigo }}</td>                
                        <td v-if="Number(props.item.cantAlert) >= Number(props.item.existencia)" class="text-xs-right red--text font-weight-bold">En límite mínimo de Stock <br>{{ Number(props.item.existencia) }}</td>
                        <td v-else class="text-xs-right">{{ Number(props.item.existencia) }}</td>
                        <td class="text-xs-right">{{ Number(props.item.cantAlert) }}</td>
                    </template>
                    <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning">
                            Todavía no hay productos en el catálogo, presiona el botón '+' para agregar.
                        </v-alert>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>        
    <!-- </v-dialog> -->
</template>

<script>

import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import estasSeguro from '@/components/estasSeguro.vue'
import { db, auth } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    components: { estasSeguro },
    data: () => ({
      QCatlg: '',
      slotProps: '',
      vista: 1,
      colaborador: '',
      colaboradores: [],
    //   cropper: null,
      fotosProducto: [],
      preguntaEliminar: false,
      fotoEliminar: null,
      dialogEditC: false,
      dialogAddC: false,
      dialogEditFoto: false,
      dialogFiltros: false,
      dialogAddFiltros: false,
      catalogosDB: [],
      catalogoMini: [],
      filtros: [],
      select: { etiqueta: '',},
      catalogodb: {
          nombre: '',
          codigo: '',
          descripcion: '',
          costo: 0,
          precio: 0,
          existencia: 0,
          cantAlert: 0,
          url: '' ,
          pid: '',
          elaboracion: '',
          etiqueta: ''   
      },
      headersC: [
          { text: 'Nombre', value: 'nombre' },
          { text: 'Descripción', value: 'descripcion', sortable: false },
          { text: 'Costo($)', value: 'costo', sortable: false },
          { text: 'Precio($)', value: 'precio', sortable: false },
          { text: 'Actions', value: 'nombre', sortable: false },
          { text: 'Código-Barras', value: 'codigo' },
          { text: 'Existencia', value: 'existencia' },
          { text: '#min-Stock', value: 'cantAlert' },
      ],
      headersFiltros: [
        {
          text: 'Etiquetas',
          align: 'left',
          sortable: false,
          value: 'etiqueta'
        },
      ],
      editedIndexC: -1,            
      defaultItemC: {
          nombre: '',
          codigo: '',
          descripcion: '',
          costo: '',
          precio: '',
          existencia: '',
          cantAlert: ''
      },
      editedIndex: -1,
      editedItemFiltro: {
        etiqueta: '',
      },
      defaultItemFiltro: {
        etiqueta: '',
      },
      dialog: false,
      show: false,
    }),
    validations: {
        catalogodb: {
            nombre: {
                required,
                maxLength: maxLength(30),
                minLength: minLength(3)
            },
            unidad: {
                required,
                maxLength: maxLength(30),
                minLength: minLength(2)
            },
            costo: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(1)
            },
            precio: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(1)
            },
            existencia: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(1)
            },
            cantAlert: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(1)
            },
            descripcion: {
                maxLength: maxLength(300),
            },
            url: {
            //   required,
              maxLength: maxLength(100),
              minLength: minLength(1)
            },
        },
    },
    computed: {
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      ...mapState('usuarioHost', ['perfilAuth']),
    //   ...mapGetters('sesion', ['fotoProducto']),
      ...mapGetters('sesion', { usuarioFromStore: ['usuario'] }),
      usuario: {
        get(){
            return this.usuarioFromStore
        },
        set(newUsuario){
            return newUsuario
        }
      },
      existenciasAlLimite() {
          return this.catalogosDB.some( element => element.cantAlert >= element.existencia)
      },
      formTitleC () {
        return this.editedIndexC === -1 ? 'Producto Nuevo' : 'Editar Producto'
      },
      formTitleFiltro () {
        return this.editedIndexFiltro === -1 ? 'Nuevo Filtro' : 'Editar Filtro'
      },
      catlgNumPermiso() {
        return this.usuarioHost.permisos.productos
      },
      erroresNombre() {
        let errores = []
        if (!this.$v.catalogodb.nombre.$dirty) { return errores }  
        if (!this.$v.catalogodb.nombre.required) { errores.push('Ingresa el nombre del producto.') }      
        if (!this.$v.catalogodb.nombre.maxLength) { errores.push('Ingresa máximo 40 caracteres.') }
        if (!this.$v.catalogodb.nombre.minLength) { errores.push('Ingresa mínimo 3 caracteres.') }
        
        return errores
      },
      erroresDescripcion() {
        let errores = []
        if (!this.$v.catalogodb.descripcion.$dirty) { return errores }  
        if (!this.$v.catalogodb.descripcion.maxLength) { errores.push('Ingresa máximo 100 caracteres.') }
        
        return errores
      },
      erroresUnidad() {
        let errores = []
        if (!this.$v.catalogodb.unidad.$dirty) { return errores }  
        if (!this.$v.catalogodb.unidad.required) { errores.push('Ingresa la unidad del producto.') }      
        if (!this.$v.catalogodb.unidad.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
        if (!this.$v.catalogodb.unidad.minLength) { errores.push('Ingresa mínimo 2 caracteres.') }
        
        return errores
      },
      erroresCosto() {
        let errores = []
        if (!this.$v.catalogodb.costo.$dirty) { return errores }  
        if (!this.$v.catalogodb.costo.required) { errores.push('Ingresa el costo del producto.') }      
        if (!this.$v.catalogodb.costo.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
        if (!this.$v.catalogodb.costo.minLength) { errores.push('Ingresa mínimo 1 caracteres.') }
        
        return errores
      },
      erroresPrecio() {
        let errores = []
        if (!this.$v.catalogodb.precio.$dirty) { return errores }  
        if (!this.$v.catalogodb.precio.required) { errores.push('Ingresa el precio del producto.') }      
        if (!this.$v.catalogodb.precio.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
        if (!this.$v.catalogodb.precio.minLength) { errores.push('Ingresa mínimo 1 caracteres.') }
        
        return errores
      },
      erroresExistencia() {
        let errores = []
        if (!this.$v.catalogodb.existencia.$dirty) { return errores }  
        if (!this.$v.catalogodb.existencia.required) { errores.push('Ingresa la existencia del producto.') }      
        if (!this.$v.catalogodb.existencia.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
        if (!this.$v.catalogodb.existencia.minLength) { errores.push('Ingresa mínimo 1 caracteres.') }
        
        return errores
      },
      erroresCantAlert() {
        let errores = []
        if (!this.$v.catalogodb.cantAlert.$dirty) { return errores }  
        if (!this.$v.catalogodb.cantAlert.required) { errores.push('Ingresa la cantidad para que se active la alerta de Stock mínimo.') }      
        if (!this.$v.catalogodb.cantAlert.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
        if (!this.$v.catalogodb.cantAlert.minLength) { errores.push('Ingresa mínimo 1 caracteres.') }
        
        return errores
      },
      erroresFoto() {
        let errores = []
        // if (!this.$v.catalogodb.url.required) { errores.push('Ingresa la foto del producto.') }
        if (!this.$v.catalogodb.url.maxLength) { errores.push('Ingresa máximo 100 caracteres.') }
        if (!this.$v.catalogodb.url.minLength) { errores.push('Ingresa mínimo 1 caracteres.') }
        
        return errores
      },      
    },
    watch: {
        dialogAddC (val) {
            val || this.closeC()
        },
        dialogFiltros (val) {
            val || this.closeFiltro()
        }
    },
    created () {
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()
        // this.numProdsAut()
        if (this.usuarioHost.filtrosCtlg) {
            this.consultarFiltros()
        }
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        async movingSameHost() {
            await dbL.collection('fromSameHost').set([])
            await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName })
            this.$router.go(-1)
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            await dbL.collection('fromSameHost').delete()
            this.sameHost = false
            this.sameHostName = ''
            this.consultarCatalogosDB()
        },
        async ConsulQCatlg() {
            this.QCatlg = this.catalogosDB.length
        },
        async consultarPlan() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('subscriptions')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let plansdb = doc.data()
                        this.planPorPrecio.push(plansdb)    
                    })
                }                
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los Planes-Precios.')                
            }
        },
        emptyFieldsC() {
            this.catalogodb = this.defaultItemC
            this.closeC()
        },
        async consultarColaboradores() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })
            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                try {

                    let colaboradorDoc = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('colaboradores')
                                            .where("uid", '==', auth.currentUser.uid)
                                            .get()

                        // if(colaboradorDoc.exists) {
                        // this.colaborador = colaboradorDoc.data()

                        colaboradorDoc.docs.forEach(doc => {                    
                            let colab = doc.data()

                                this.colaborador.push(colab)

                        })

                    if (userName.uid != auth.currentUser.uid) {

                        let colAuth = this.colaborador.some(col => col.uid == auth.currentUser.uid)

                        if ( colAuth == false) {

                            this.$router.push({ name: '404' })
                        }
                    }
                }
                catch (error) {
                    // this.$router.push({ name: '404' })
                }
                finally {
                    this.ocultarOcupado()
                }
            }
        },
        async guardarPdeCatalogo() {
            let userNameParametro = this.$route.params.userName.toLowerCase()
            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()
            
            this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })
                

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                if (this.editedIndexC > -1) {

                    this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                    Object.assign(this.catalogosDB[this.editedIndexC], this.catalogodb)

                    try {

                        await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('catalogosDB')
                            .doc(this.catalogodb.pid)
                            .update({
                                nombre: this.catalogodb.nombre,
                                etiqueta: this.select.etiqueta,
                                // codigo: this.catalogodb.codigo,                       
                                descripcion: this.catalogodb.descripcion,
                                unidad: this.catalogodb.unidad,
                                costo: Number(this.catalogodb.costo), 
                                precio: Number(this.catalogodb.precio),
                                existencia: Number(this.catalogodb.existencia),
                                cantAlert: Number(this.catalogodb.cantAlert),
                            })
                        
                        // this.actualizarMinicatalogo( this.catalogodb )
                        this.closeC()
                        this.dialogAddC = false

                        this.mostrarExito('Se editó el Catálogo exitosamente')
                    }              
                    catch (error) {
                        this.mostrarError('Ocurrió un error editando el Catálogo')
                    }
                    finally {
                        this.ocultarOcupado()
                    }
                } else {

                    let pid = uuidv4()
                    let batch = db.batch()
                    let ctlgdb = {
                        pid,
                        nombre: this.catalogodb.nombre,
                        codigo: this.catalogodb.codigo,
                        descripcion: this.catalogodb.descripcion,
                        unidad: this.catalogodb.unidad,
                        costo: Number(this.catalogodb.costo),
                        precio: Number(this.catalogodb.precio),
                        existencia: Number(this.catalogodb.existencia),
                        cantAlert: Number(this.catalogodb.cantAlert),
                        userNameHost: userName.userName,
                        fotoProducto: '',
                        elaboracion: 'almacén'
                    }

                    batch.set(db.collection('usuarios').doc(userName.uid)
                                .collection('catalogosDB').doc(ctlgdb.pid), ctlgdb) 

                    
                    try {
                        await batch.commit()
                        this.catalogosDB.push(ctlgdb)
                        // this.actualizarMinicatalogo( ctlgdb )                        
                        this.mostrarExito('Se registró el Producto en el Catálogo exitosamente')
                    }
                    catch (error) {
                        this.mostrarError('Ocurrió un error cargando el Producto en el Catálogo. Verifica tu señal de internet.')
                    }
                    // finally {
                    //     this.ocultarOcupado()
                    // }
                    // this.closeC()
                }
            }
            // }
            // catch (error) {
            //     this.mostrarError('Ocurrió un error cargando la información')
            // }
            this.ocultarOcupado()
            this.closeC()
        },    
        async consultarCatalogosDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('catalogosDB')
                                            .get()                
                        
                    resultado.docs.forEach(doc => {
                        
                        let catdb = doc.data()
                            this.catalogosDB.push(catdb)    
                    })
                }                
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los Productos del Catálogo.')                
            }
            this.ConsulQCatlg()
        },       
        editItemC (item) {
            this.editedIndexC = this.catalogosDB.indexOf(item)
            this.catalogodb = Object.assign({}, item)
            this.dialogAddC = true
        },
        async deleteItemC (item) {
            const index = this.catalogosDB.indexOf(item)
            confirm('Está seguro de querer eliminar este producto del catálogo?') && this.catalogosDB.splice(index, 1)
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    this.catalogodb = Object.assign({}, item)

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('catalogosDB')
                            .doc(this.catalogodb.pid)
                            .delete()                

                    // this.actualizarMinicatalogo( this.catalogodb )
                    this.mostrarExito('Se eliminó el producto del catálogo exitosamente')
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error borrando el producto del catálogo')                
            }
            finally {
                this.ocultarOcupado()
            }
            this.closeC()
        },
        closeC () {
            this.dialogAddC = false
            setTimeout(() => {
                this.catalogodb = Object.assign({}, this.defaultItemC)
                this.editedIndexC = -1
            }, 300)
        },
        async abrirFoto(catalogodb) {    
            this.catalogosDB = []
            this.catalogodb = catalogodb;
            await this.consultarCatalogosDB(); //con o sin await?            
        },
        async deleteItemFiltro (item) {
            const index = this.filtros.indexOf(item)
            this.filtros.splice(index, 1)

            let userNameParametro = this.$route.params.userName.toLowerCase()
        
            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    // this.filtros = Object.assign({}, item)

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .update({filtrosCtlg: this.filtros})                
                
                    this.mostrarExito('Se eliminó la Etiqueta exitosamente')
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error borrando la información')                
            }
            finally {
                this.ocultarOcupado()
            }
            this.closeFiltro()
        },
        closeFiltro () {
            this.dialogAddFiltros = false
            setTimeout(() => {
            this.editedItemFiltro = Object.assign({}, this.defaultItemFiltro)
            this.editedIndexFiltro = -1
            }, 300)
        },
        async saveFiltro () {
            
            let userNameParametro = this.$route.params.userName.toLowerCase()
            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()
            
            this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })
                

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                if (this.editedIndexFiltro > -1) {

                    this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                    Object.assign(this.filtros[this.editedIndexFiltro], this.editedItemFiltro)

                    try {

                        await db.collection('usuarios')
                            .doc(userName.uid)
                            .update({
                                filtrosCtlg: this.filtros[this.editedIndexFiltro].etiqueta
                            })
                        
                        // this.actualizarMinicatalogo( this.catalogodb )
                        this.closeFiltro()
                        this.dialogAddFiltro = false

                        this.mostrarExito('Se editó el Filtro exitosamente')
                    }              
                    catch (error) {
                        this.mostrarError('Ocurrió un error editando el Filtro')
                    }
                    finally {
                        this.ocultarOcupado()
                    }
                } else {
                    this.filtros.push({etiqueta: this.editedItemFiltro.etiqueta})                    

                    await db.collection('usuarios').doc(userName.uid)
                            .set({filtrosCtlg: this.filtros}, {merge: true})

                    try {       
                                                            
                        this.mostrarExito('Se registró la etiqueta exitosamente')
                    }
                    catch (error) {
                        this.mostrarError('Ocurrió un error cargando la Etiqueta. Verifica tu señal de internet.')
                    }
                }
            }
            this.ocultarOcupado()
            this.closeFiltro()            
        },
        consultarFiltros() {
            this.filtros = []
            this.filtros = this.usuarioHost.filtrosCtlg
        }
    }
}
</script>

<style>
  
  .filepond--drop-label {
    font-size: 14pt !important;
  }

  .edicionImagen {
    width: 100%;
  }

  .vistaPrevia {
    width: 288px;
    height: 180px;
    overflow: hidden;
  }

</style>