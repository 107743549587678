
let almacen = () => import( '../views/compras/almaCen.vue')
let almacenSalida = () => import( '../views/compras/almacenSalida.vue')
let proveedores = () => import( '../views/compras/proveedoRes.vue')
let proveedorCotizaciones = () => import( '../views/compras/proveedorCotizaciones.vue')
let ordenesDeCompra = () => import( '../views/compras/ordenesDeCompra.vue')


export default [
    
      {
        path: '/:userName/compras/almacen',
        name: 'almacen',
        component: almacen,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/compras/almacen',
        name: 'almacenSalida',
        component: almacenSalida,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/compras',
        name: 'proveedores',
        component: proveedores,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/compras/proveedor-cotizaciones/:nombreProveedor/:expedProveedor',
        name: 'proveedorCotizaciones',
        component: proveedorCotizaciones,
        meta: {
          autenticado: true
        }
      },
      {
        path: '/:userName/compras',
        name: 'ordenesDeCompra',
        component: ordenesDeCompra,
        meta: {
          autenticado: true
        }
      },

]