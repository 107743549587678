
let registroInmuebles = () => import( '../views/serviciosInmobiliarios/registroInmuebles.vue')
let infoInmueble = () => import( '../views/serviciosInmobiliarios/infoInmueble.vue')

export default [
    {
      path: '/:userName/registro-inmuebles',
      name: 'registroInmuebles',
      component: registroInmuebles,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/informacion-inmueble/:inmuID',
      name: 'infoInmueble',
      component: infoInmueble,
      meta: {
        autenticado: true
      }
    },
]