let creaCajasClinica = () => import( '../views/clinica/creaCajasClinica.vue')
let clientesClinica = () => import( '../views/clinica/clientesClinica.vue')
let clientesClinicaGeneral = () => import( '../views/clinica/clientesClinicaGeneral.vue')
let almacenClinica = () => import( '../views/clinica/almacenClinica.vue')
let bodegaClinica = () => import( '../views/clinica/bodegaClinica.vue')
let notaVtaClinica = () => import( '../views/clinica/notaVtaClinica.vue')

export default [    
    
    {
        path: '/:userName/cajas',
        name: 'creaCajasClinica',
        component: creaCajasClinica,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/pacientes',
        name: 'clientesClinica',
        component: clientesClinica,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/pacientesg',
        name: 'clientesClinicaGeneral',
        component: clientesClinicaGeneral,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/almacen',
        name: 'almacenClinica',
        component: almacenClinica,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/bodega',
        name: 'bodegaClinica',
        component: bodegaClinica,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/nota-de-venta',
        name: 'notaVtaClinica',
        component: notaVtaClinica,
        meta: {
            autenticado: true
        }
    },
    
]