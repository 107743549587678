<template>
  <v-parallax dark height="600" :src=fotoBack>
    <div class="text-xs-center">     
      <div class="headline mt-3 black--text"><strong class="logoFont">bienvenido al lobby de 
        <strong class="logoFont black--text">nu<strong class="principal--text">Vin</strong></strong> :)</strong>
      </div><br>
      <span class="logoFont black--text">aquí te podremos dar una idea de lo que <strong class="logoFont">nu<strong class="principal--text">Vin</strong></strong>
        puede hacer por ti... <br><br>
        <strong class="black--text">¿qué giro es tu negocio?</strong></span><br>
      <v-layout justify-center wrap>
        <v-flex xs10 sm8 md6>
          <v-select
            :items="itemsGiros"
            v-model="giroPicked"
            giro="giroPicked"
            item-text="giro"
            label="Giros de negocio"
            outline
            class="mt-4"
          ></v-select>
          <v-btn v-if="giroPicked != '' && infoCard == false" @click="getInfo()" round small>ver funciones</v-btn>
        </v-flex>
        
        <v-flex xs10 sm8 md8 v-if="infoCard == true">
          <v-card
            class="mx-auto" 
            max-width="500"            
            v-for="item in itemsGiros" :key="item.app"
          >
            <div v-if="item.app == giroPicked">
              <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
                <!-- <v-avatar
                  color="primary lighten-2"
                  class="subheading white--text"
                  size="24"
                  v-text="step"
                ></v-avatar> -->
              </v-card-title>

              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-card-text v-for="element in item.funciones" :key="element.index" class="py-0 text-xs-left">
                    <span class="caption grey--text">
                      {{ element }}
                    </span>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                  <v-card-text>
                    <span class="caption grey--text text--darken-1">
                      El precio promedio por usuario ANUAL es de <strong>$ 555.88 MXN.</strong> A mayor cantidad de usuarios, 
                      el PRECIO por usuario es más BAJO. Depende del número de funciones que 
                      requieras y del número de usuarios que operarán dentro de la aplicación/sistema/web.
                      Podemos hacer un plan personalizado para ti con una o varias funciones.               
                    </span>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                  <div class="pa-3 text-xs-center">
                    <h3 class="title font-weight-light mb-2">
                      ¿Te gustaría tener un precio exacto del paquete o plan que sería útil para tu negocio?
                      Proporciónanos tu email o teléfono para comunicarnos contigo:</h3>
                    <v-text-field
                      v-model="formaContacto"
                      label="email o teléfono"
                      box
                    ></v-text-field>
                    <v-btn
                      v-if="step === 3"
                      color="principal" 
                      dark 
                      depressed 
                      @click="getInfo2()" 
                      class="text-none" 
                      small
                    >
                      enviar mis datos
                    </v-btn>
                    <v-btn
                      v-if="step === 3"
                      color="grey darken-1" 
                      dark 
                      depressed 
                      @click="noSendInfo()" 
                      class="text-none" 
                      small
                    >
                      no deseo enviar mis datos
                    </v-btn>
                    <template>
                      <v-layout row justify-center>
                        <v-dialog v-model="dialog" persistent max-width="290">
                          <!-- <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
                          </template> -->
                          <v-card>
                            <v-card-title class="headline logoFont">gracias por contactarnos! :)</v-card-title>
                            <v-card-title class="title logoFont" v-if="sendInfoAsk == 'yes'">en breve nos comunicaremos contigo para saber tus necesidades 
                              para la virtualización de tu negocio</v-card-title>
                            <v-card-title class="title logoFont" v-if="sendInfoAsk == 'not'">cuando estés listo puedes enviárnos un email para ayudarte en la 
                              virtualización de tu negocio :)</v-card-title>
                            <div class="headline text-xs-center">
                              <strong class="logoFont">nu<strong class="principal--text">Vin</strong></strong>
                            </div>                      
                            <v-card-text class="logoFont">nubesdebits@gmail.com</v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="principal" flat :to="{ name: 'HomeSistema'}">Salir</v-btn>
                              <!-- <v-btn color="green darken-1" flat @click="dialog = false">Agree</v-btn> -->
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-layout>
                    </template>
                  </div>
                </v-window-item>
              </v-window>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  :disabled="step === 1"
                  flat
                  @click="step--"
                  class="text-none"
                  small
                >
                  atrás
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="step === 3" 
                  color="principal" 
                  dark 
                  depressed 
                  @click="step++" 
                  class="text-none" 
                  small
                >
                  siguiente
                </v-btn>            
              </v-card-actions>
            </div>
          </v-card>
        </v-flex>
      </v-layout>        
    </div>
  </v-parallax>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { db } from '@/firebase'

export default {
  data() {
    return {
      fotoBack: require("@/assets/fondoLobby.png"),
      itemsGiros: [{giro:'restaurante', funciones: ['°toma de pedido mesero a cliente',
                                                      '°envío de pedido-comandas a barra y/o cocina',
                                                      '°aviso de "Pedido Listo" desde barra o cocina a dispositivo de mesero',
                                                      '°menu para vista de cliente en código QR o liga www',
                                                      '°lista de existencias en bodega o almacén',
                                                      '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                      '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'comedores industriales', funciones: ['°toma de pedido de área exclusiva por comensal o mesero a cliente',
                                                      '°envío de pedido-comandas a barra y/o cocina',
                                                      '°aviso de "Pedido Listo" desde barra o cocina a dispositivo de mesero o comensal',
                                                      '°menu para vista de cliente en código QR o liga www',
                                                      '°lista de existencias en bodega o almacén',
                                                      '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                      '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'comercio', funciones: ['°toma de pedido del personal de mostrador a cliente',
                                                  '°envío de pedido a bodega o almacén para empaquetar',
                                                  '°aviso de "Pedido Listo" a personal de mostrador para recoger',
                                                  '°lista de productos para vista de cliente en código QR o liga www',
                                                  '°relación de existencias en bodega o almacén',
                                                  '°mecanismo de compra en línea',
                                                  '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                  '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                 ]},
                   {giro:'consultorio', funciones: ['°agenda de citas por cliente',
                                                    '°registro de clientes con historial de citas con reportes con opción de fotografías',
                                                    '°vista al público de datos de contacto y servicios de consultorio en código QR o liga www',
                                                    '°lista de existencias en bodega o almacén de insumos o material',
                                                    '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                    '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'servicios profesionales', funciones: ['°agenda de citas por cliente',
                                                    '°registro de clientes con historial de citas con reportes con opción de fotografías',
                                                    '°vista al público de datos de contacto y servicios de consultorio en código QR o liga www',
                                                    '°lista de existencias en bodega o almacén de insumos o materiales',
                                                    '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                    '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'organizacion eventos', funciones: ['°agenda de citas por cliente',
                                                    '°sección por cliente del estatus de los servicios que se les está ofreciendo',
                                                    '°registro de clientes con historial de citas con reportes con opción de fotografías',
                                                    '°vista al público de datos de contacto y servicios de consultorio en código QR o liga www',
                                                    '°lista de existencias en bodega o almacén de insumos o materiales',
                                                    '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                    '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'taller automotriz', funciones: ['°agenda de citas por cliente',
                                                    '°sección por cliente del estatus de los servicios que se les está ofreciendo',
                                                    '°registro de clientes con historial de citas con reportes con opción de fotografías',
                                                    '°vista al público de datos de contacto y servicios del taller en código QR o liga www',
                                                    '°lista de existencias en bodega o almacén de insumos o materiales',
                                                    '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                    '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'maquila o fábrica de productos', funciones: ['°agenda de citas por cliente',
                                                    '°sección por cliente del estatus de los servicios que se les está ofreciendo',
                                                    '°registro de clientes con historial de citas con reportes con opción de fotografías',
                                                    '°vista al público de datos de contacto y productos en código QR o liga www',
                                                    '°lista de existencias en bodega o almacén de insumos o materiales',
                                                    '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                    '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'inmobiliarias', funciones: ['°agenda de citas por cliente',
                                                    '°aplica para administración, venta o renta de inmuebles',
                                                    '°sección por cliente del estatus de los servicios que se les está ofreciendo',
                                                    '°registro de clientes con historial de citas con reportes con opción de fotografías',
                                                    '°vista al público de datos de contacto e inmuebles en código QR o liga www',
                                                    '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                    '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'constructora', funciones: ['°agenda de citas por cliente',
                                                    '°aplica para administración, modificación, construcción, diseño de interiores/exteriores de inmuebles',
                                                    '°sección por cliente del estatus de los servicios que se les está ofreciendo',
                                                    '°lista de existencias en bodega o almacén de insumos o materiales',
                                                    '°registro de clientes con historial de citas con reportes con opción de fotografías',
                                                    '°vista al público de datos de contacto e inmuebles en código QR o liga www',
                                                    '°estatus de ventas y gastos en tiempo real en cualquier dispositivo, vista privada',
                                                    '°etc. También puedes ir agregando funciones extras y solicitar funciones persoanlizadas.'
                                                    ]},
                   {giro:'otro giro', funciones: ['°platícanos tu giro y las funciones o necesidades que requieres virtualizar/digitalizar para darte precio y tiempo de entrega.',                                                    ]},
                   {giro:''}
                  ],
      giroPicked: '',
      infoCard: false,
      dialog: false,
      sendInfoAsk: '',
      formaContacto: '',
      visita: { formaContacto: '' },
      step: 1
    }
  },

  // created () {
  //       this.appPicked = this.itemsGiros.find(i => i.app === this.giroPicked);
  // },

  computed: {
    currentTitle () {
      switch (this.step) {
        case 1: return 'funciones'
        case 2: return 'planes-precios'
        default: return 'contáctanos'
      }
    }
  },

  methods: {
    async getInfo () {
      let visitid = uuidv4()
      let objVisita = {
          visitid,
          giroVisita: this.giroPicked,
          fuente: 'visita-lobby',
          formaContacto: '',
          fecha: new Date()
      }
      let userNameDoc = await db.collection('userNames')
                            .doc('soportenuvit')
                            .get()

      if(userNameDoc.exists) {
        let userName = userNameDoc.data()

        await db.collection('usuarios')
                .doc(userName.uid)
                .collection('clientes')
                .doc(objVisita.visitid)
                .set(objVisita)

          this.visita = objVisita
          this.infoCard = true
      }
    },

    async getInfo2 () {

      let userNameDoc = await db.collection('userNames')
                            .doc('soportenuvit')
                            .get()

      if(userNameDoc.exists) {
        let userName = userNameDoc.data()

        await db.collection('usuarios')
                .doc(userName.uid)
                .collection('clientes')
                .doc(this.visita.visitid)
                .update({formaContacto: this.formaContacto});

          this.dialog = true
          this.sendInfoAsk = 'yes'
      }
    },

    noSendInfo() {
      this.dialog = true
      this.sendInfoAsk = 'not'
    }
  },
}
</script>

<style>
  .logoFont {
    font-family: 'Comfortaa', cursive;
  }
</style>