
let edicionMenu = () => import( '../views/alimentosYbebidas/edicionMenu.vue')
let menualpublico = () => import( '../views/alimentosYbebidas/menualPublico.vue')
let comandas = () => import( '../views/alimentosYbebidas/comanDas.vue')
let creaCajas = () => import( '../views/alimentosYbebidas/creaCajas.vue')
let almacenAlimYBebid = () => import( '../views/alimentosYbebidas/almacenAlimYBebid.vue')

export default [    
    {
        path: '/:userName/edicion-menu',
        name: 'edicion-menu',
        component: edicionMenu,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/clientes/menu-al-publico',
        name: 'menualpublico',
        component: menualpublico,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/comandas',
        name: 'comandas',
        component: comandas,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/cajas',
        name: 'creaCajas',
        component: creaCajas,
        meta: {
            autenticado: true
        }
    },
    {
        path: '/:userName/almacen-alimYbebid',
        name: 'almacen-alimentosYbebidas',
        component: almacenAlimYBebid,
        meta: {
            autenticado: true
        }
    },
]