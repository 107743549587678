<template>
    <v-flex xs12 sm10 md8>
        <v-card class="elevation=5 ma-3">
            <v-layout>
            <!-- <v-flex xs12 class="text-xs-right">
                <v-dialog
                    v-model="dialogHelp"
                    width="500"
                >
                    <template v-slot:activator="{ on }">
                    <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                    </template>

                    <v-card>
                    <v-card-title class="headline">
                        Cómo funciona esta sección...
                    </v-card-title>

                    <v-card-text>
                        En esta área se agregan o cambian los datos que te gustaría que tus clientes supieran sobre tu negocio. Estos 
                        Datos se reflejan en el área pública de DATOS.
                        <ul>
                            <li>Dando click al botón "Agregar" se desplica un formulario para seleccionar el tipo de etiqueta que desas agregar
                                y su contenido.
                            </li>
                            <li>
                                Al dar click en "Agregar" los datos se desplegarán en la tabla inferior al mismo tiempo que en el Área Pública.
                            </li>
                            <li>
                                En la tabla, cada dato tiene las opciones de Cambiar su contenido (icono-lapiz) o borrarlos (icono-basurero).
                            </li>
                        </ul> 
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat small dark class="primary--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex> -->
            </v-layout>    
            <v-toolbar flat color="white">
                <v-toolbar-title>Etiquetas</v-toolbar-title>
                <v-spacer></v-spacer>               
                <v-divider
                    class="mx-2"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialogEtiq" max-width="500px">
                    <template v-slot:activator="{ on }">
                    <v-btn :style="{'background-color': paletaColores.primero}" dark class="mb-2 caption text-none" small v-on="on">Agregar</v-btn>
                    </template>
                    <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container grid-list-md>
                        <v-layout wrap> 
                            <v-flex xs12>
                                <v-select
                                v-model="editedItemEtiq.tipoEtiq"
                                :items="items"
                                label="Escoge el tipo de etiqueta a registrar"
                                outline
                                @blur="$v.editedItemEtiq.tipoEtiq.$touch()" :error-messages="errorestipoEtiq"
                                ></v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-model="editedItemEtiq.nombreEtq" counter="54" label="etiqueta" @blur="$v.editedItemEtiq.nombreEtq.$touch()" :error-messages="erroresDato"></v-text-field>
                            </v-flex>
                        </v-layout>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" flat @click="close">Cancelar</v-btn>
                        <v-btn color="primary" flat @click="guardarEtiqueta" :depressed="$v.editedItemEtiq.$invalid" :disabled="$v.editedItemEtiq.$invalid">Guardar</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-card-title>Las Etiquetas se utilizan para organizar en subgrupos la información de Gastos, Ingresos y Dinero/Fondos. Ejemplos: 
                *de GASTO: Limpieza, Entretenimiento, Viáticos;
                *de INGRESO: Vta. en mostrador, Vta. en línea, Consultas;
                *de DINERO/Fondos (donde se va el dinero que vas obteniendo): Efectivo, Efectivo en casa, Banco X, Ahorro X.
            </v-card-title>
            <v-data-table
                :headers="headersEtiq"
                :items="etiquetasFzsDB"
                class="elevation-1"
                >
                <template v-slot:items="props">
                    <td>{{ props.item.tipoEtiq }}</td>
                    <td class="text-xs-right">{{ props.item.nombreEtq }}</td>
                    <td class="justify-center align-center layout">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                    >
                        edit
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(props.item)"
                    >
                        delete
                    </v-icon>
                    </td>
                </template>
            <!-- <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template> -->
            </v-data-table>
        </v-card>
    </v-flex>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    data: () => ({
        dialogEtiquetas: false,
        dialogEtiq: false,
        etiquetasFzsBase: [
            {nombreEtq: 'abonoAcrédito/préstamo', tipoEtiq: 'Gasto', etqid: 'IDETIQUETACREDPRESTM'},
            {nombreEtq: 'Efectivo', tipoEtiq: 'Dinero', etqid: 'IDETIQUETAEFECTIV'},
            {nombreEtq: 'Banco', tipoEtiq: 'Dinero', etqid: 'IDETIQUETABANK'}
        ],
        etiquetasFzsDB: [],
        items: ['Gasto', 'Ingreso', 'Dinero', 'crédito/préstamo'],
        headersEtiq: [
            {
            text: 'Tipo de Etiqueta',
            align: 'left',
            value: 'tipoEtiq'
            },
            { text: 'Etiqueta', value: 'nombreEtq', sortable: false, },        
        ],
        editedIndexEtiq: -1,
        editedItemEtiq: {
            nombreEtq: '',
            tipoEtiq: '',
        },
        defaultItemEtiq: {
            nombreEtq: '',
            tipoEtiq: '',
        }       
    }),

    validations: {
        editedItemEtiq: {
            tipoEtiq: {
                required,
            },
            nombreEtq: {
                required,
            },
        },
    },

    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        formTitle () {
            return this.editedIndexEtiq === -1 ? 'Nueva Etiqueta' : 'Editar Etiqueta'  
        },
        errorestipoEtiq() {
            let errores = []
            if (!this.$v.editedItemEtiq.tipoEtiq.$dirty) { return errores }
            if (!this.$v.editedItemEtiq.tipoEtiq.required) { errores.push('Ingresa el tipo de etiqueta que quieres ingresar.') }      
            return errores
        },
        erroresDato() {
          let errores = []
          if (!this.$v.editedItemEtiq.nombreEtq.$dirty) { return errores }
          if (!this.$v.editedItemEtiq.nombreEtq.required) { errores.push('Ingresa el dato.') }      
          return errores
      },
    },

    created () {
        this.consultaretiquetasFzsDB()
    },

    methods: {
      ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
      async movingSameHost() {
        await dbL.collection('fromSameHost').set([])
        await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName })
        this.$router.go(-1)
      },
      nombreEtiquetaExiste(nombreEtq) {
        return this.etiquetasFzsDB.some(
            etiqueta => etiqueta.nombreEtq.toLowerCase() === nombreEtq.toLowerCase()
        );
      },
      async guardarEtiqueta() {
        if (this.nombreEtiquetaExiste(this.editedItemEtiq.nombreEtq)) {
            alert('El nombre de etiqueta ya existe. Por favor, elige otro nombre.');
            return;
        }

        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
            let userName = userNameDoc.data()

            try {
                this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })

                if (this.editedIndexEtiq > -1) {

                    this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                    Object.assign(this.etiquetasFzsDB[this.editedIndexEtiq], this.editedItemEtiq)
                    // Object.assign(this.etiquetasFzsDB[this.editedIndex], this.editedItem)

                    try {

                        await db.collection('usuarios')
                                .doc(userName.uid)
                                .collection('etiquetasFzsDB')
                                .doc(this.editedItemEtiq.etqid)
                                .update({
                                    nombreEtq: this.editedItemEtiq.nombreEtq,                         
                                    tipoEtiq: this.editedItemEtiq.tipoEtiq,  
                                })
                        
                        // this.actualizarMinicatalogo( this.editedItem )
                        this.close()
                        this.dialogEtiq = false

                        this.mostrarExito('Se editó el Dato exitosamente')
                    }              
                    catch (error) {
                        this.mostrarError('Ocurrió un error editando el Dato')
                    }
                    finally {
                        this.ocultarOcupado()
                    }
                } else {
                    // Guardar los elementos existentes en etiquetasFzsBase
                    this.etiquetasFzsBase.forEach((etiqueta) => {                        
                        db.collection('usuarios')
                        .doc(userName.uid)
                        .collection('etiquetasFzsDB')
                        .doc(etiqueta.etqid)
                        .set(etiqueta)
                    });
                    
                    this.etiquetasFzsDB = []
                    let batch = db.batch()
                    let etqid = uuidv4()

                    let etiqObj = {
                        etqid,
                        nombreEtq: this.editedItemEtiq.nombreEtq,                        
                        tipoEtiq: this.editedItemEtiq.tipoEtiq,                  
                    }

                    await batch.set(db.collection('usuarios').doc(userName.uid)
                                .collection('etiquetasFzsDB').doc(etiqObj.etqid), etiqObj)                    

                    try {
                        batch.commit()
                        this.consultaretiquetasFzsDB()
                        // this.etiquetasFzsDB.push(etiqObj)
                        // this.actualizarMinicatalogo( this.editedItem )
                        
                        this.mostrarExito('Se registró el dato exitosamente')
                    }
                    catch (error) {
                        this.mostrarError('Ocurrió un error cargando el Dato')
                    }
                    finally {
                        this.ocultarOcupado()
                    }
                    this.close()
                }
                
                this.close()
            }
            catch (error) {
                this.mostrarError('Ocurrió un error cargando la información')
            }
            finally {                
                this.ocultarOcupado()
            }
        }
        this.close()
      },
      async consultaretiquetasFzsDB() {  
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
            let userName = userNameDoc.data()

            try {
                let resultado = await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('etiquetasFzsDB')
                                        .get()                
                    
                resultado.docs.forEach(doc => {
                    
                    let datadb = doc.data()
                    // if  (this.grupodb.uidG == userName.uid) {                     
                        this.etiquetasFzsDB.push(datadb)    
                    // }
                })
                
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando las Etiquetas.')                
            }
        }    
      }, 
      editItem (item) {
        this.editedIndexEtiq = this.etiquetasFzsDB.indexOf(item)
        this.editedItemEtiq = Object.assign({}, item)
        this.dialogEtiq = true
      },
      async deleteItem (item) {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
            let userName = userNameDoc.data()

            const index = this.etiquetasFzsDB.indexOf(item)
            confirm('Está seguro de querer eliminar este Dato?') && this.etiquetasFzsDB.splice(index, 1)
        
            try {
                
                this.editedItemEtiq = Object.assign({}, item)

                await db.collection('usuarios')
                        .doc(userName.uid)
                        .collection('etiquetasFzsDB')
                        .doc(this.editedItemEtiq.etqid)
                        .delete()                

                // this.actualizarMinicatalogo( this.catalogodb )
                this.mostrarExito('Se eliminó el Dato exitosamente')
            }
            catch (error) {
                this.mostrarError('Ocurrió un error borrando el Dato')                
            }
            finally {
                this.ocultarOcupado()
            }
        }
        this.close()
      },
      close () {
        this.dialogEtiq = false
        setTimeout(() => {
          this.editedItemEtiq = Object.assign({}, this.defaultItemEtiq)
          this.editedIndexEtiq = -1
        }, 300)
      },
    },
}
</script>