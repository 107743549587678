<template>
    <v-card>
        <v-list>
            <!-- <div class="text-xs-right caption" v-if="autoPedido && this.usuario">
                Auto Pedido:
                <v-btn small round color="transparent" class="caption text-none" @click="saveCliAutoPed">
                    {{ hidden ? 'activar' : 'desactivar' }}
                </v-btn>
            </div> -->
            <!-- <div class="text-xs-right caption"> -->
             <!-- <template v-if="autoPedido && !this.usuario">
                <v-layout row justify-end>
                    <v-dialog v-model="dialog2" persistent max-width="290">
                        <template v-slot:activator="{ on }">                         
                                <div class="text-xs-right caption">
                                    Auto Pedido:
                                    <v-btn small round color="transparent" class="caption text-none" v-on="on"> -->
                                        <!-- {{ hidden ? 'activar' : 'desactivar' }} -->
                                        <!-- activar
                                    </v-btn>    
                                </div>                    
                        </template>
                        <v-card>
                            <v-card-title class="title font-weight-medium">Para activar el auto-pedido requieres una cuenta nuBit</v-card-title>
                            <v-card-text>¿Deseas crearla?, es gratis y sólo te solicitamos tu email y un nombre de usuario...</v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" flat @click="dialog2 = false">Cancelar</v-btn>
                            <v-btn color="green darken-1" flat @click="dialog2 = false" :to="{ path: '/' }">Sí</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-layout>
            </template> -->
            <!-- </div> -->
            <!-- <div class="text-xs-center" >
                <v-dialog
                v-model="dialog"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-fab-transition>
                        <v-btn
                            v-show="!hidden"
                            color="transparent"
                            fab
                            small
                            class="caption"
                            v-on="on"
                            @click="showCart = !showCart"
                            >
                            <v-icon>mdi-playlist-edit</v-icon>
                            ({{ pedido.length + (pedido.length > 1 || pedido.length === 0 ? " " : " ") }})
                        </v-btn>
                    </v-fab-transition>
                </template>
                <v-card>
                    <v-card-title
                        class="title"
                        >
                        Auto Pedido
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text class="cart" @click="dialog = true">
                            <div v-if="pedido.length > 0">
                                <table class="table table-cart">
                                    <tr v-for="item in pedido" :key="item.pid" transition="fade">
                                         <v-divider
                                        v-if="item.divider"
                                        :key="index"
                                        :inset="item.inset"
                                        ></v-divider>
                                        <td>{{ item.details.nombre }}</td>
                                        <td style="width:20px">
                                            <input color="black" v-model="item.quantity" class="form-control input-qty" type="number">
                                        </td>
                                        <td>${{item.details.precio}}</td>
                                        <td><v-icon small @click="removeFromCart(item)">mdi-trash-can</v-icon></td>                                        
                                    </tr>
                                    <v-divider></v-divider><br>
                                    <tr v-if="pedido.length > 0">
                                        <v-spacer></v-spacer>
                                        <td class="text-right">Total</td>
                                        <v-spacer></v-spacer><v-spacer></v-spacer>
                                        <td class="text-right">${{Total}}</td>                                        
                                    </tr>
                                    <tr>
                                        <v-flex>
                                            ¿Cómo deseas recibir tu pedido?
                                            <template>
                                                <v-layout row justify-center>
                                                    <v-dialog v-model="dialogOpciones" persistent max-width="290">
                                                    <template v-slot:activator="{ on }" class="pl-3">
                                                        <v-btn v-on="on" fab flat>
                                                            <div class="text-xs-center">
                                                                <v-chip>
                                                                    <v-icon>mdi-ballot-outline</v-icon>
                                                                    {{ radios || 'Selecciona' }}
                                                                </v-chip>
                                                            </div>
                                                        </v-btn>
                                                    </template>
                                                    <v-card class="text-xs-left">
                                                        <v-card-title class="font-weight-medium">Selecciona una opción:</v-card-title>
                                                            <v-radio-group v-model="radios" :mandatory="false">
                                                                <v-radio class="caption pl-5" v-if="usuarioHost.paraLlevar == true" label="Para llevar" value="Para llevar"></v-radio>
                                                                <v-radio class="caption pl-5" v-if="usuarioHost.comerLocal == true" label="Para comer" value="Para comer"></v-radio>
                                                                <v-radio class="caption pl-5" v-if="usuarioHost.envioDomicilio == true" label="Envío a domicilio" value="A domicilio"></v-radio>
                                                            </v-radio-group>                                                    
                                                        <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn flat class="caption" @click="dialogOpciones = false">De acuerdo</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                    </v-dialog>
                                                </v-layout>
                                            </template>
                                        </v-flex>
                                    </tr>
                                    <tr v-if="radios == 'A domicilio'"><span>Por favor, agrega tu domicilio:</span><br></tr>                          
                                    <tr>
                                        <v-flex v-if="radios == 'A domicilio'">
                                            <template>
                                                <v-layout row justify-center>
                                                    <v-dialog v-model="dialogDomicilio" persistent max-width="290">
                                                        <template v-slot:activator="{ on }" class="pl-3">
                                                            <v-btn v-on="on" small flat>
                                                                <div class="text-xs-center">
                                                                    <v-chip>
                                                                        <v-icon>mdi-map-marker</v-icon>
                                                                        Domicilio
                                                                    </v-chip>
                                                                </div>
                                                            </v-btn>
                                                        </template>
                                                        <v-card class="text-xs-left">
                                                            <v-toolbar color="transparent" card>
                                                            <v-toolbar-title>
                                                                Dirección de envío:
                                                            </v-toolbar-title>
                                                            </v-toolbar>
                                                            <v-card-text>
                                                                <v-container grid-list-md>
                                                                <v-layout wrap >
                                                                    <v-flex xs12 sm6 md4>
                                                                    <v-text-field v-model="datosEnvio.receptor" label="Quién recibe" counter="30" @blur="$v.datosEnvio.receptor.$touch()" :error-messages="erroresReceptor"></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex xs12 sm6 md4>
                                                                    <v-text-field v-model="datosEnvio.direccion" label="Dirección" counter="40" @blur="$v.datosEnvio.direccion.$touch()" :error-messages="erroresDireccion"></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex xs12 sm6 md4>
                                                                    <v-text-field v-model="datosEnvio.ciudad" label="Ciudad" counter="10" @blur="$v.datosEnvio.ciudad.$touch()" :error-messages="erroresCiudad"></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex xs12 sm6 md4>
                                                                    <v-text-field v-model="datosEnvio.estado" label="Estado" counter="15" @blur="$v.datosEnvio.estado.$touch()" :error-messages="erroresEstado"></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex xs12 sm6 md4>
                                                                    <v-text-field v-model="datosEnvio.cp" label="Código postal" counter="6" @blur="$v.datosEnvio.cp.$touch()" :error-messages="erroresCp"></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex xs12 sm6 md4>
                                                                    <v-text-field v-model="datosEnvio.pais" label="País" counter="12" @blur="$v.datosEnvio.pais.$touch()" :error-messages="erroresPais"></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex xs12 sm6 md4>
                                                                    <v-text-field v-model="datosEnvio.telefono" label="Teléfono" counter="12" @blur="$v.datosEnvio.telefono.$touch()" :error-messages="erroresTelefono"></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex xs12 sm6>                            
                                                                        <v-text-field
                                                                            v-model="datosEnvio.referencia"
                                                                            counter="40"
                                                                            label="ej. Color de casa, entre qué calles, etc."
                                                                            outline 
                                                                            @blur="$v.datosEnvio.referencia.$touch()" :error-messages="erroresReferencia"
                                                                        ></v-text-field>
                                                                    </v-flex>                                       
                                                                </v-layout>
                                                                </v-container>
                                                            </v-card-text>
                                                            <v-spacer></v-spacer>
                                                            <v-card-actions>
                                                            <v-btn flat class="caption" @click="clearDatosEnv">Cancelar</v-btn>
                                                            <v-btn flat class="caption" @click="saveDomicilioEnv" :depressed="$v.datosEnvio.$invalid" :disabled="$v.datosEnvio.$invalid">Guardar</v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                </v-layout>
                                            </template>
                                        </v-flex>
                                    </tr>
                                    <tr><v-btn small class="text-none" @click="savePedido" v-if="radios == 'Para comer' || radios == 'Para llevar' || datosEnvio.completo == true">Enviar pedido</v-btn></tr>
                                </table>
                            </div>
                            <div v-show="pedido.length === 0">
                                <p>Todavía no hay pedido</p>
                            </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        flat
                        @click="dialog = false"
                        >
                        Cerrar
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </div>             -->
            <v-expansion-panel>
                <v-expansion-panel-content
                    v-for="item in menuGpoDB"
                    :key="item.grupo"
                    >
                    <template v-slot:actions>
                        <v-icon color="primary">mdi-cog</v-icon>
                    </template>
                    <template v-slot:header>
                        <div>{{item.grupo}}</div>
                    </template>
                    <v-card>
                    <v-card-text class="grey lighten-3">{{ item.descripcion }}</v-card-text>
                    </v-card>
                    <template v-if="item.btnexactiv == true">
                        <div class="text-xs-right">
                            <v-dialog
                            v-model="dialogBtnExtra"
                            width="500"
                            >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                color="primary"
                                small
                                v-on="on"
                                class="caption"
                                >
                                {{ item.btnexetiq}}
                                </v-btn>
                            </template>

                            <v-card>
                                <!-- <v-card-title
                                class="headline grey lighten-2"
                                primary-title
                                >
                                Privacy Policy
                                </v-card-title> -->

                                <v-card-text>
                                    {{ item.btnexcont}}
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color=""
                                    flat
                                    @click="dialogBtnExtra = false"
                                >
                                    Cerrar
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-expansion-panel-content>
                <!-- <v-expansion-panel-content
                    v-for="item in menuGpoDB"
                    :key="item.grupo"                   
                    >
                    <template v-slot:header >
                        <div @click="abrirGrupo(item)" class="body-1">{{item.grupo}}</div>
                    </template>
                    <v-card
                        v-if="menugpodb"
                        :key= "menugpodb.gid"
                        :nombre="menugpodb.nombre"
                        :menuProductosDB="menugpodb.menuProductosDB">
                        <v-expansion-panel>
                            <v-expansion-panel-content
                            v-for="item in menuProductosDB"
                            :key="item.pid"
                            >
                            <template v-slot:header >
                                <v-fab-transition>
                                    <div>
                                        <v-btn
                                            v-show="!hidden"
                                            fab
                                            small
                                            class="elevation-2 transparent caption"
                                            @click.native.stop="addToCart(item)"
                                            >
                                            <v-icon>mdi-playlist-plus</v-icon>
                                            <input color="black" v-model="item.quantity" class="form-control input-qty" type="number">
                                        </v-btn>
                                    </div>
                                </v-fab-transition>
                                <div class="body-2 font-weight-bold">{{item.nombre}}</div>
                                    <v-spacer></v-spacer>
                                <div>${{item.precio}}</div>
                            </template>
                            <v-card>
                                <v-card-text class="font-weight-medium font-italic">{{item.descripcion}}</v-card-text>
                            </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-card>
                </v-expansion-panel-content> -->
            </v-expansion-panel>       
        </v-list>
        <template v-if="menuGpoDB.length < 1">
            <v-alert :value="true" color="transparent" icon="warning" dark class="black--text">
                Todavía no hay servicios agregados.
            </v-alert>
        </template>
    </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            radios: null,
            hidden: true,
            dialog: false,
            dialog2: false,
            dialogOpciones: false,
            dialogDomicilio: false,
            dialogBtnExtra: false,
            showCart: false,
            verified: false,
            // quantity: 1,
            pedido: [],
            pedidoCli: [],
            clienteAuto: '',
            // selected: [],
            usuarioHost: {
                userName: '',
                autoPedido: '',
                envioDomicilio: '',
                paraLlevar: '',
                comerLocal: '',
                fotoPortada: ''
            },
            datosEnvio: {
                receptor: '',
                direccion: '',
                ciudad: '',
                estado: '',
                cp: '', 
                pais: 'México',
                telefono: '',
                referencia: '',
                completo: false,         
            },
            headersP: [
                {
                text: 'Productos',
                align: 'left',
                value: 'nombre'
                },
                // { text: 'Grupo', value: 'grupo' },
                { text: 'Precio ($)', value: 'precio' },
                { text: 'Descripcion', value: 'descripcion', sortable: false },
                { text: 'Actions', value: 'nombre', sortable: false }
            ],
            menuGpoDB: [],
            menugpodb: {
                grupo: '',
                menuProductosDB: [{nombre:''}],
                productos: [
                    {nombre: '',}
                ],
            },
            menuProductosDB: [],
            menuproductosdb: {
                nombre: '',
                precio: 0,
                descripcion: '',
                grupo: '',
            },    
            editedIndexP: -1,
            editedIndexG: -1,
            defaultItemP: {
                nombre: '',
                precio: 0,
                descripcion: '',
                grupo: '',
            },
            defaultItemG: {
                grupo: '',
            }
        }
    },
    validations: {
        datosEnvio: {
            receptor: {
                required,
                maxLength: maxLength(30),
                minLength: minLength(3)
            },
            direccion: {
                required,
                maxLength: maxLength(40),
                minLength: minLength(10)
            },
            ciudad: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(3)
            },
            estado: {
                required,
                maxLength: maxLength(15),
            },
            cp: {
                required,
                maxLength: maxLength(6),
                minLength: minLength(5)
            },
            pais: {
                required,
                maxLength: maxLength(12),
            },
            telefono: {
                required,
                maxLength: maxLength(12),
                minLength: minLength(10)
            },
            referencia: {
                maxLength: maxLength(40),
            },            
        },
    },
    computed: {
      ...mapState('sesion', ['usuario']),
      erroresReceptor() {
            let errores = []
            if (!this.$v.datosEnvio.receptor.$dirty) { return errores }
            if (!this.$v.datosEnvio.receptor.required) { errores.push('Ingresa la receptor de la empresa.') }      
            if (!this.$v.datosEnvio.receptor.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
            if (!this.$v.datosEnvio.receptor.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            return errores
        },
        erroresDireccion() {
            let errores = []
            if (!this.$v.datosEnvio.direccion.$dirty) { return errores }
            if (!this.$v.datosEnvio.direccion.required) { errores.push('Ingresa la direccion de la empresa.') }      
            if (!this.$v.datosEnvio.direccion.maxLength) { errores.push('Ingresa máximo 40 caracteres.') }
            if (!this.$v.datosEnvio.direccion.minLength) { errores.push('Ingresa al menos 10 caracteres.') }
            return errores
        },
        erroresCiudad() {
            let errores = []
            if (!this.$v.datosEnvio.ciudad.$dirty) { return errores }
            if (!this.$v.datosEnvio.ciudad.required) { errores.push('Ingresa la ciudad de la empresa.') }      
            if (!this.$v.datosEnvio.ciudad.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
            if (!this.$v.datosEnvio.ciudad.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            return errores
        },
        erroresEstado() {
            let errores = []
            if (!this.$v.datosEnvio.estado.$dirty) { return errores }
            if (!this.$v.datosEnvio.estado.required) { errores.push('Ingresa la estado de la empresa.') }      
            if (!this.$v.datosEnvio.estado.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresCp() {
            let errores = []
            if (!this.$v.datosEnvio.cp.$dirty) { return errores }
            if (!this.$v.datosEnvio.cp.required) { errores.push('Ingresa la cp de la empresa.') }      
            if (!this.$v.datosEnvio.cp.maxLength) { errores.push('Ingresa máximo 6 caracteres.') }
            if (!this.$v.datosEnvio.cp.minLength) { errores.push('Ingresa al menos 5 caracteres.') }
            return errores
        },
        erroresPais() {
            let errores = []
            if (!this.$v.datosEnvio.pais.$dirty) { return errores }
            if (!this.$v.datosEnvio.pais.required) { errores.push('Ingresa la pais de la empresa.') }      
            if (!this.$v.datosEnvio.pais.maxLength) { errores.push('Ingresa máximo 12 caracteres.') }
            return errores
        },
        erroresTelefono() {
            let errores = []
            if (!this.$v.datosEnvio.telefono.$dirty) { return errores }
            if (!this.$v.datosEnvio.telefono.required) { errores.push('Ingresa la telefono de la empresa.') }      
            if (!this.$v.datosEnvio.telefono.maxLength) { errores.push('Ingresa máximo 12 caracteres.') }
            if (!this.$v.datosEnvio.telefono.minLength) { errores.push('Ingresa al menos 10 caracteres.') }
            return errores
        },
        erroresReferencia() {
            let errores = []
            if (!this.$v.datosEnvio.referencia.$dirty) { return errores }    
            if (!this.$v.datosEnvio.referencia.maxLength) { errores.push('Ingresa máximo 40 caracteres.') }
            return errores
        },
        Total() {
            let total = 0;
            this.pedido.forEach(item => {
                total += (item.details.precio * item.quantity);
            });
            return total;
        },
        autoPedido() {
            return this.usuarioHost.autoPedido == true
        },
        fotoPortadaHost() {
            if (!this.usuarioHost.fotoPortada) {
                return require('@/assets/fotoUsuario.png')
            } else {
                return this.usuarioHost.fotoPortada
            }      
        }
    },
    created () {
     //   this.initialize()        
        this.consultarUsuarioHost()
        
        // this.consultarProductosDB()
    },
    // watch: {
    //     '$route' () {
    //     this.consultarUsuarioHost()
    //     }
    // },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarOcupado', 'mostrarError', 'ocultarOcupado']),
        saveDomicilioEnv() {
            this.datosEnvio.completo = true
            this.dialogDomicilio = false
        },
        clearDatosEnv() {
            this.datosEnvio = {}
            this.dialogDomicilio = false
        },
        async saveOpciones() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            this.mostrarOcupado({ titulo: 'Modificando Tipos de Auto-Pedido', mensaje: 'Cargando datos...' })

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()

                        await db.collection('usuarios')
                                .doc(userName.uid)
                                .update({
                                        paraLlevar: this.usuarioHost.paraLlevar,
                                        comerLocal: this.usuarioHost.comerLocal,
                                        envioDomicilio: this.usuarioHost.envioDomicilio
                                })                    

                        this.mostrarExito('Auto-Pedido Modificado')
                    }
                }              
                catch (error) {
                    this.mostrarError('Ocurrió un error activando Auto-Pedido')
                }
                finally {
                    this.ocultarOcupado()
                }
            this.dialogAutoPed = false
        },
        async consultarUsuarioHost() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })

                try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()

                    let usuarioDoc = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .get()

                    if(usuarioDoc.exists) {
                    let usuarioHostdata = usuarioDoc.data()
                    this.usuarioHost.userName = usuarioHostdata.userName
                    this.usuarioHost.autoPedido = usuarioHostdata.autoPedido
                    this.usuarioHost.envioDomicilio = usuarioHostdata.envioDomicilio
                    this.usuarioHost.paraLlevar = usuarioHostdata.paraLlevar
                    this.usuarioHost.comerLocal = usuarioHostdata.comerLocal
                    this.usuarioHost.fotoPortada = usuarioHostdata.fotoPortada
                    }
                    else {
                    this.$router.push({ name: '404' })
                    }
                }
                else {
                    this.$router.push({ name: '404' })
                }
                }
                catch (error) {
                this.$router.push({ name: '404' })
                }
                finally {
                this.ocultarOcupado()
                }
                this.consultarGruposDB()
        },
        async consultarGruposDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('menuGpoDB')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let gpodb = doc.data()
                                            
                            this.menuGpoDB.push(gpodb)    
                        
                    })
                }                
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los Grupos.')                
            }     
        },
        async consultarProductosDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('menuGpoDB')
                                            .doc(this.menugpodb.gid)
                                            .collection('menuProductosDB')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let proddb = doc.data()

                            if (proddb.gidP == this.menugpodb.gid) {
                                
                                this.menuProductosDB.push(proddb)
                            }
                        
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los productos para el menu.')                
            }     
        },
        async abrirGrupo(menugpodb) {    
            this.menuProductosDB = []
            this.menugpodb = menugpodb;
            await this.consultarProductosDB(); //con await?            
        },
        addToCart(item) {

            const locationInCart = this.pedido.findIndex(p => {
                return p.details.pid === item.pid
            })
            
            if(locationInCart === -1){
                this.pedido.push({
                        details: item,
                        quantity: 1,
                        cliente: this.usuario.userName,
                        // fecha: this.comanda.fecha,
                        formaPedido: "autoPedido",
                        // notas: ,
                        // hora: new Date().toLocaleTimeString(),
                        estatusCocina: 'red',
                        // elaboracion: this.pedido.details.elaboracion
                        
                    })
                } else {
                    this.pedido[locationInCart].quantity++
                }
        },
        removeFromCart(item) {
            item.quantity -= 1;
            this.pedido.splice(this.pedido.indexOf(item), 1);
        },
        async saveCliAutoPed() {     
            let userNameParametro = this.$route.params.userName.toLowerCase()

            this.hidden = !this.hidden
            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()
                let usuarioDoc = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('clientes')
                                            .doc(this.usuario.userName)
                                            .get()
                
                if(usuarioDoc.exists) {

                    this.clienteAuto = usuarioDoc.data()
                    // this.mostrarExito('Hola, bienvenido!')    

                } else {

                    this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' })                    

                        let batch = db.batch()
                        let cliAutoid = uuidv4()

                        let clienteAuto = {
                            cliAutoid,
                            cliente: this.usuario.userName,
                            userNameHost: userName.userName,
                            userName: this.usuario.userName,
                        }

                        await batch.set(db.collection('usuarios').doc(userName.uid)
                                .collection('clientes').doc(clienteAuto.cliente), clienteAuto)                            
                                
                    try {
                        batch.commit()

                        let usuarioDoc = await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('clientes')
                                        .doc(this.usuario.userName)
                                        .get()
            
                        this.clienteAuto = usuarioDoc.data()

                        this.mostrarExito('Hola, bienvenido!')
                    }
                    catch (error) {
                        this.mostrarError('Ocurrió un error registrando el Cliente')
                    }
                    finally {
                        this.ocultarOcupado()
                    }
                }
            }
        },
        async savePedido() {
            let userNameParametro = this.$route.params.userName.toLowerCase()
            let comid = uuidv4()
            let ntfid = uuidv4()

            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                let orden = {
                        comid,
                        estatusPago: "Pendiente de Pago",
                        formaPedido: "autoPedido",
                        estatusAceptada: "blue",
                        cliAutoid: this.clienteAuto.cliAutoid,
                        mesero: {
                            cliente: this.clienteAuto.cliente,
                            autor: this.usuario.userName,
                        },
                        userNameHost: userName.userName,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                        prepararPara: this.radios,
                        datosEnvio: this.datosEnvio,
                        envioDomicilio: this.usuarioHost.envioDomicilio,
                        // momentoPago: this.usuarioHost.momentoPago
                    }

                let notif = {
                        ntfid,
                        tipoNotif: 'cliente->restaurante',
                        asunto: "ha realizado un pedido. ¿lo ACEPTAS?...",
                        avatar: this.fotoPortadaHost,
                        irA: "autoPedido",
                        cliente: this.clienteAuto.cliente,
                        cliAutoid: this.clienteAuto.cliAutoid,
                        userNameHost: userName.userName,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                    }

                var i;
                for ( i=0; i < this.pedido.length; i++) {    
                    
                    let batch = db.batch()
                    let piid = uuidv4()
                    let pxq = this.pedido[i].quantity * this.pedido[i].details.precio
                    
                    let pedido = {                            
                            piid,
                            comid,
                            autor: this.usuario.userName,
                            userNameHost: userName.userName,
                            cliAutoid: this.clienteAuto.cliAutoid,
                            mesero: {
                                cliente: this.clienteAuto.cliente,
                                uid: this.clienteAuto.uid,
                            },
                            cantidad: this.pedido[i].quantity,
                            // producto: this.pedido.details.nombre,
                            // unidad: this.pedido.details.unidad,
                            // precio: this.pedido.details.precio,
                            producto: this.pedido[i].details,
                            estatusCocina: this.pedido[i].estatusCocina,
                            formaPedido: this.pedido[i].formaPedido,
                            hora: new Date().toLocaleTimeString('es-MX'),
                            fecha: new Date().toISOString().substr(0, 10),
                            fechaStd: new Date(),
                            pxq,
                            total: this.Total,
                            avatar: this.fotoPortadaHost,
                            prepararPara: this.radios

                    }                
                            
                await batch.set(db.collection('usuarios').doc(userName.uid)
                        .collection('clientes').doc(this.clienteAuto.cliente)
                        .collection('comandas').doc(orden.comid), orden)

                await batch.set(db.collection('usuarios').doc(userName.uid)
                        .collection('notificaciones').doc(notif.ntfid), notif)

                await batch.set(db.collection('usuarios').doc(userName.uid)
                        .collection('clientes').doc(this.clienteAuto.cliente)
                        .collection('comandas').doc(orden.comid)
                        .collection('pedidoTot').doc(pedido.piid), pedido)                
                    
                    try {
                        batch.commit()
                        // this.pedidoCli.push(pedido)                    
                        this.mostrarExito('El Pedido ha sido enviado, favor de esperar por la respuesta del proveedor')
                        
                    }
                    catch (error) {
                        this.mostrarError('Ocurrió un error cargando el pedido')
                    }
                    finally {
                        this.ocultarOcupado()
                    }
                    
                    this.close()
                }
                this.$router.push({ name: 'cliPedEstat', params: {userNameHost: this.usuarioHost.userName, userName: this.usuario.userName} })
            }
        },
        close() {
            this.dialog = false
        }
    }
}
</script>

<style>
    .input-qty {
    width: 30px;
    float: right
    }

    .table-cart > tr > td {
    vertical-align: middle !important;
    }
</style>