<template>
  <v-layout justify-center>
    <v-flex xs12>
      <v-card>
        <!-- <div class="text-xs-right caption" v-if="autoPedido && this.usuario">
          <span v-if="usuarioHost.app == 'comercio'">Carrito:</span>
          <span v-if="usuarioHost.app == 'restaurante'">Auto Pedido:</span>
          <v-btn small round color="transparent" class="caption text-none" @click="saveCliAutoPed">
              {{ hidden ? 'activar' : 'desactivar' }}
          </v-btn>
        </div> -->
        <div class="text-xs-center" v-if="autoPedido && usuario">
          <v-dialog
              v-model="dialog"
              width="500"
              >
              <template v-slot:activator="{ on }">
                  <v-fab-transition>
                      <v-btn
                          color="transparent"
                          fab
                          small
                          class="caption"
                          v-on="on"
                          @click="showCart = !showCart"
                          >
                          <v-icon>mdi-cart-variant</v-icon>
                          ({{ pedido.length + (pedido.length > 1 || pedido.length === 0 ? " " : " ") }})
                      </v-btn>
                  </v-fab-transition>
              </template>
              <v-card>
                  <v-card-title
                      class="title"
                      v-if="usuarioHost.app == 'comercio'"
                      >
                      Carrito de {{ usuarioHost.userName }} <v-icon class="pl-3">mdi-cart-variant</v-icon>
                  </v-card-title>
                  <v-card-title
                      class="title"
                      v-if="usuarioHost.app == 'restaurante'"
                      >
                      Auto Pedido
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-card-text class="cart" @click="dialog = true">
                    <div v-if="pedido.length > 0">
                        <table class="table table-cart">
                            <tr v-for="(item, index) in pedido" :key="index" transition="fade">
                                <v-divider
                                v-if="item.divider"
                                :key="index"
                                :inset="item.inset"
                                ></v-divider>
                                <td>{{ item.producto }}</td>
                                <td style="width:20px">
                                    <input color="red" v-model="item.cantidad" class="form-control input-qty grey" type="number">
                                </td>
                                <td>${{item.precio}}</td>
                                <td><v-icon small @click="removeFromCart(item)">mdi-trash-can</v-icon></td>                                        
                            </tr>
                            <v-divider></v-divider><br>
                            <tr v-if="pedido.length > 0">
                                <v-spacer></v-spacer>
                                <td class="text-right">Total</td>
                                <v-spacer></v-spacer><v-spacer></v-spacer>
                                <td class="text-right">${{Total}}</td>                                        
                            </tr>
                            <tr>
                                <v-flex>
                                    ¿Cómo deseas recibir tu pedido?
                                    <template>
                                        <v-layout row justify-center>
                                            <v-dialog v-model="dialogOpciones" persistent max-width="290">
                                            <template v-slot:activator="{ on }" >
                                                <v-btn v-on="on" fab flat class="pl-3">
                                                    <div class="text-xs-center">
                                                        <v-chip>
                                                            <!-- <v-avatar class="teal">A</v-avatar> -->
                                                            <v-icon>mdi-ballot-outline</v-icon>
                                                            {{ radios || 'Selecciona' }}
                                                        </v-chip>
                                                    </div>
                                                </v-btn>
                                            </template>
                                            <v-card class="text-xs-left">
                                                <v-card-title class="font-weight-medium">Selecciona una opción:</v-card-title>
                                                    <div v-if="usuarioHost.envioDomicilio == true && usuarioHost.tiempoEntrega != false" class="ml-3">
                                                      El servicio a domicilio toma un tiempo de entrega: 
                                                      <strong>{{ usuarioHost.tiempoEntrega }}</strong> días</div>
                                                    <v-radio-group v-model="radios" :mandatory="false">
                                                            <!-- <v-icon small >mdi-package-variant</v-icon> -->
                                                        <v-radio class="caption pl-5" v-if="usuarioHost.paraLlevar == true" label="Pasar a estabecimiento y recoger" value="Para llevar"></v-radio>
                                                        <!-- <v-icon small >mdi-table-chair</v-icon> -->
                                                        <v-radio class="caption pl-5" v-if="usuarioHost.comerLocal == true" label="Para comer" value="Para comer"></v-radio>                                        
                                                        <!-- <v-icon small >mdi-moped</v-icon> -->
                                                        <v-radio class="caption pl-5" v-if="usuarioHost.envioDomicilio == true" label="Envío a domicilio" value="A domicilio"></v-radio>
                                                    </v-radio-group>                                                    
                                                <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn flat class="caption" @click="dialogOpciones = false">De acuerdo</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                            </v-dialog>
                                        </v-layout>
                                    </template>
                                </v-flex>
                            </tr>
                            <tr v-if="radios == 'A domicilio'"><span>Por favor, agrega tu domicilio:</span><br></tr>                          
                            <tr>
                                <v-flex v-if="radios == 'A domicilio'">
                                    <template>
                                        <v-layout row justify-center>
                                            <v-dialog v-model="dialogDomicilio" persistent max-width="290">
                                                <template v-slot:activator="{ on }" >
                                                    <v-btn v-on="on" small flat class="pl-3">
                                                        <div class="text-xs-center">
                                                            <v-chip>
                                                                <!-- <v-avatar class="teal">A</v-avatar> -->
                                                                <v-icon>mdi-map-marker</v-icon>
                                                                Domicilio
                                                            </v-chip>
                                                        </div>
                                                    </v-btn>
                                                </template>
                                                <v-card class="text-xs-left">
                                                    <v-toolbar color="transparent" card>
                                                    <v-toolbar-title>
                                                        Dirección de envío:
                                                    </v-toolbar-title>
                                                    </v-toolbar>
                                                    <v-card-text>
                                                        <v-container grid-list-md>
                                                        <v-layout wrap >
                                                            <v-flex xs12>
                                                            <v-text-field v-model="datosEnvio.receptor" label="Quién recibe" counter="30" @blur="$v.datosEnvio.receptor.$touch()" :error-messages="erroresReceptor"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                            <v-text-field v-model="datosEnvio.direccion" label="Dirección" counter="40" @blur="$v.datosEnvio.direccion.$touch()" :error-messages="erroresDireccion"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                            <v-text-field v-model="datosEnvio.ciudad" label="Ciudad" counter="10" @blur="$v.datosEnvio.ciudad.$touch()" :error-messages="erroresCiudad"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                            <v-text-field v-model="datosEnvio.estado" label="Estado" counter="15" @blur="$v.datosEnvio.estado.$touch()" :error-messages="erroresEstado"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                            <v-text-field v-model="datosEnvio.cp" label="Código postal" counter="6" @blur="$v.datosEnvio.cp.$touch()" :error-messages="erroresCp"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                            <v-text-field v-model="datosEnvio.pais" label="País" counter="12" @blur="$v.datosEnvio.pais.$touch()" :error-messages="erroresPais"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                            <v-text-field v-model="datosEnvio.telefono" label="Teléfono" counter="12" @blur="$v.datosEnvio.telefono.$touch()" :error-messages="erroresTelefono"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12>                            
                                                                <v-text-field
                                                                    v-model="datosEnvio.referencia"
                                                                    counter="40"
                                                                    label="ej. Color de casa, entre qué calles, etc."
                                                                    outline 
                                                                    @blur="$v.datosEnvio.referencia.$touch()" :error-messages="erroresReferencia"
                                                                ></v-text-field>
                                                            </v-flex>                                       
                                                        </v-layout>
                                                        </v-container>
                                                    </v-card-text>
                                                    <v-spacer></v-spacer>
                                                    <v-card-actions>
                                                    <v-btn flat class="caption" @click="clearDatosEnv">Cancelar</v-btn>
                                                    <v-btn flat class="caption" @click="saveDomicilioEnv" :depressed="$v.datosEnvio.$invalid" :disabled="$v.datosEnvio.$invalid">Guardar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-layout>
                                    </template>
                                </v-flex>
                            </tr>
                            <tr><v-btn small class="text-none" @click="savePedido" v-if="radios == 'Para comer' || radios == 'Para llevar' || datosEnvio.completo == true">Enviar pedido</v-btn></tr>
                        </table>
                    </div>
                    <div v-show="pedido.length === 0">
                        <p>Todavía no hay pedido</p>
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn small
                      color="primary" outline
                      flat class="caption"
                      v-if="pedido.length > 0"
                      @click="saveCarrito()"
                      >
                      Guardar Carrito y Salir
                    </v-btn>
                  <v-spacer></v-spacer>
                    <v-btn small
                        color="primary" outline
                        flat class="caption"
                        @click="dialog = false"
                        >
                      Seguir Comprando
                    </v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
        </div>
        <!-- <v-btn :to="{ name: 'edicion-minicatalogo' }" color="secondary" class="elevation-1" fab small absolute top right>
            <v-layout align-center justify-center>
                <v-icon class="text-xs-center">edit</v-icon>
            </v-layout>   
        </v-btn> -->
        <!-- {{ catalogosDB.catalogosDB.nombre}} -->
        <!-- <div class="text-xs-center">
          <v-dialog
            v-model="dialogCart"
            width="500"
            >
            <template v-slot:activator="{ on }">
              <v-btn
                absolute
                color="orange" class="white--text"
                fab left top
                v-on="on"
                >
                  cart ({{cartItems.length}})
              </v-btn>
            </template>

            <v-card>              
                <div>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Mi Carrito</v-toolbar-title>
                    <v-divider
                      class="mx-2"
                      inset
                      vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-data-table
                    :headers="headersCart"
                    :items="cartItems"
                    class="elevation-1"
                  >
                    <template v-slot:items="props">
                      <td>{{ props.item.nombre }}</td>
                      <td class="text-xs-right">{{ props.item.precio }}</td>
                      <td class="text-xs-right">{{ props.item.qty }}</td>
                      <td class="justify-center layout px-0">
                        <v-icon
                          small
                          @click="deleteItem(props.item)"
                        >
                          delete
                        </v-icon>
                      </td>
                    </template>
                  </v-data-table>
                </div>
            </v-card>
          </v-dialog>
        </div> -->
        
        <template>
          <!-- <div class="container bg-light"> -->
            <!-- <div class="row mt-5 justify-content-center">
              <div class="col-sm-8 col-md-8 col-lg-6 m-lg-auto">
                <div class="input-group">
                  <input
                    type="search"
                    class="form-control rounded"
                    placeholder="Type here"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    v-model="searchString"
                  />
                  <button type="button" class="btn btn-outline-danger" @click="search">
                    Search Videos
                  </button>
                </div>
              </div>
            </div> -->

            <div class="row ml-3 justify-content-end" v-if="catalogosDB.length >= 10">
              <div class="col-2">
                <span>Selecciona # elementos por pág.</span><br>
                <select
                  class="form-select form-select-sm"
                  aria-label=".form-select-lg example"
                  @change="selectPerPageCount"
                >
                  <option selected>opciones:</option>
                  <option v-for="i in 5" :key="i * 5" :value="i * 5">
                    {{ i * 5 }}
                  </option>
                </select>
              </div>
            </div>
            <!-- filtros -->
            <div class="ml-5" v-if="usuarioHost.filtrosCtlg && usuarioHost.filtrosCtlg.length > 0">
              <v-radio-group v-model="currentFilter" row @click="consultarProdCatlgLB">
              <v-radio label="Todos" value="Todos"></v-radio>
              <div v-for="(item, index) in usuarioHost.filtrosCtlg" :key="index">
                <v-radio :label="`${item.etiqueta}`" :value="`${item.etiqueta}`"></v-radio>
              </div>
            </v-radio-group>
            </div>
            

            <div class="row mt-0 justify-content-center" id="videoContainer">
              <!-- <div class="col-auto" v-for="video in this.videosToShow" :key="video.id"> -->
                <v-container
                  fluid
                  grid-list-md
                  >
                  <v-layout row wrap>
                    <v-flex
                      v-for="item in this.videosToShow"
                      v-bind:key="item.pid"
                      v-bind="{ [`xs${6}`]: true, [`sm${4}`]: true, [`md${3}`]: true }"
                      >              
                      <v-card>
                          <v-img      
                            :src="item.fotoProducto"            
                            height="150px"
                            >
                            <v-container
                              fill-height
                              fluid
                              pa-2
                              >
                            </v-container>
                          </v-img>
                          <v-card-actions >
                            <v-spacer></v-spacer>                    
                            <!-- <p class="text-center">
                              <input v-model="item.qty" type="number" class="form-control"
                              style="width: 40px;" placeholder="Qty" min="1"/>
                            </p> -->
                            <v-btn icon 
                              v-if="autoPedido && usuario"
                              fab
                              small
                              class="elevation-2 transparent caption"
                              @click.native.stop="addToCart(item)"
                              >
                              <v-icon>mdi-cart-arrow-down</v-icon>
                            </v-btn>
                            <template v-if="autoPedido && !usuario">
                              <v-layout row justify-end>
                                <v-dialog v-model="dialog2" persistent max-width="290">
                                  <template v-slot:activator="{ on }">                         
                                    <div class="text-xs-right caption">
                                        <!-- <span v-if="usuarioHost.app == 'comercio'">Carrito:</span>
                                        <span v-if="usuarioHost.app == 'restaurante'">Auto Pedido:</span> -->
                                        <!-- <v-btn small round color="transparent" class="caption text-none" v-on="on"> -->
                                            <!-- {{ hidden ? 'activar' : 'desactivar' }} -->
                                            <!-- activar
                                        </v-btn> -->
                                        <v-btn icon 
                                          fab
                                          small
                                          class="elevation-2 transparent caption"
                                          v-on="on"
                                          >
                                          <v-icon>mdi-cart-arrow-down</v-icon>
                                        </v-btn>  
                                    </div>                    
                                  </template>
                                  <v-card>
                                      <v-card-title class="title font-weight-medium">Todavía no tenemos tus datos para poder registrar tu pedido correctamente...</v-card-title>
                                      <v-card-text>Sólo ingresa tu email, un nombre de usuario y contraseña...</v-card-text>
                                      <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="principal" flat @click="dialog2 = false">Cancelar</v-btn>
                                      <v-btn color="principal" flat @click="dialog2 = false" :to="{ name: 'registro' }">Siguiente</v-btn>
                                      </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-layout>
                            </template>
                            <v-spacer></v-spacer>
                              <v-dialog       
                                width="500"
                                >
                                <template v-slot:activator="{ on }" >
                                  <v-btn icon  >
                                    <v-icon v-on="on" >visibility</v-icon>
                                  </v-btn>
                                </template>
                                <v-layout row >
                                  <v-flex xs12 sm6 offset-sm3 >
                                    <v-card >
                                      <v-img
                                        :src="item.fotoProducto"
                                        height="300px"
                                        >
                                      </v-img>
                                      <!-- <v-card-title primary-title>
                                          <div class="headline">{{ item.nombre }}</div>
                                      </v-card-title>

                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="show = !show">
                                          <v-icon>{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
                                        </v-btn>
                                      </v-card-actions>

                                      <v-slide-y-transition>
                                        <v-card-text v-show="show">
                                          {{ item.descripcion }}
                                        </v-card-text>
                                      </v-slide-y-transition> -->
                                    </v-card>
                                  </v-flex>
                                </v-layout>                          
                              </v-dialog>
                          </v-card-actions>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <strong><span class="subheading" v-text="item.nombre"></span><br></strong>
                              <strong><span class="caption">${{ item.precio}}</span></strong><br><br>
                              <span class="caption">{{ item.descripcion }}</span>
                            </v-flex>
                          </v-layout>          
                      </v-card>              
                    </v-flex>
                  </v-layout>
                </v-container>
              <!-- </div> -->
            </div>

            <div class="row mt-3">
              <hr class="my-4" />
            </div>

            <div class="row justify-content-end" v-if="catalogosDB.length >= 10">
              <div class="col-12 text-center">
                <nav aria-label="...">
                  <ul class="pagination justify-content-end pagination-sm">
                    <li class="page-item" v-bind:class="{ disabled: previousButton }">
                      <a
                        href="#"
                        class="page-link"
                        @click="
                          selectedPage + 1 > 1 ? (selectedPage -= 1) : selectedPage
                        "
                        >Previo</a
                      >
                    </li>

                    <li
                      class="page-item"
                      v-for="index in this.totalPageCount"
                      :key="index"
                      :value="index"
                    >
                      <a class="page-link" href="#" @click="selectPage">{{ index }}</a>
                    </li>

                    <li class="page-item" v-bind:class="{ disabled: nextButton }">
                      <a
                        class="page-link"
                        href="#"
                        @click="
                          selectedPage + 1 === totalPageCount
                            ? selectedPage
                            : (selectedPage += 1)
                        "
                        >Siguiente</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div v-if="catalogosDB.length <= 0">
              <span>Por el momento no hay productos a mostrar en Tienda</span>
            </div>
          <!-- </div> -->
        </template>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapMutations, mapState } from 'vuex'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'
import _ from "lodash"

let dbL = new Localbase('db')

  export default {
    data: () => ({
      currentFilter: 'Todos',
      searchString: null,
      oldSearchString: null,
      searchedVideos: [],
      selectedPage: 0,
      videosPerPage: 0,
      videoChunks: [],
      showCart: false,
      hidden: false,
      radios: null,
      dialogOpciones: false,
      dialogDomicilio: false,
      dialog: false,
      dialog2: false,
      pedido: [],
      slotProps: '',
      catalogosDB: [],
      catalogodb: {
          nombre: '',
          descripcion: '',
          precio: '',
          url: '',
          qty: ''     
      },
      datosEnvio: {
          receptor: '',
          direccion: '',
          ciudad: '',
          estado: '',
          cp: '', 
          pais: 'México',
          telefono: '',
          referencia: '',
          completo: false,         
      },
      // dialogCart: false,
      // headersCart: [
      //   {
      //     text: 'Producto',
      //     align: 'left',
      //     value: 'nombre'
      //   },
      //   { text: 'Precio', value: 'precio', sortable: false },
      //   { text: 'Cantidad', value: 'qty', sortable: false },
      //   { text: 'Actions', sortable: false }        
      // ],
      show: false,
      fotoIndex: 8-1,
    }),
    validations: {
        datosEnvio: {
            receptor: {
                required,
                maxLength: maxLength(30),
                minLength: minLength(3)
            },
            direccion: {
                required,
                maxLength: maxLength(40),
                minLength: minLength(10)
            },
            ciudad: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(3)
            },
            estado: {
                required,
                maxLength: maxLength(15),
            },
            cp: {
                required,
                maxLength: maxLength(6),
                minLength: minLength(5)
            },
            pais: {
                required,
                maxLength: maxLength(12),
            },
            telefono: {
                required,
                maxLength: maxLength(12),
                minLength: minLength(10)
            },
            referencia: {
                maxLength: maxLength(40),
            },            
        },
    },
    computed: {
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      // ...mapState('sesion', ['catalogosDB']),
      // ...mapState('cartcatalogo', ['cartItems']),
      // catalogosDB() { return this.$store.state.cartcatalogo.catalogosDB; },
      // ...mapState({ catalogosDB: state => state.cartcatalogo.catalogosDB })
      // cartItems() { return this.$store.getters.cartItems; },
     fotoPortadaHost() {
        if (!this.usuarioHost.fotoPortada) {
          return require('@/assets/fotoUsuario.png')
        } else {
          return this.usuarioHost.fotoPortada
        }      
      },
      videosToShow() {
        return this.videoChunks[this.selectedPage];
      },
      totalPageCount() {
        return this.videoChunks.length;
      },
      previousButton() {
        return !(this.totalPageCount === 1 || this.selectedPage !== 0);
      },
      nextButton() {
        return !(this.selectedPage + 1 < this.totalPageCount);
      },
      Total() {
          let total = 0;
          this.pedido.forEach(item => {
              total += (item.precio * item.cantidad);
          });
          return total;
      },
      autoPedido() {
          return this.usuarioHost.autoPedido == true
      },
      erroresReceptor() {
          let errores = []
          if (!this.$v.datosEnvio.receptor.$dirty) { return errores }
          if (!this.$v.datosEnvio.receptor.required) { errores.push('Ingresa la receptor de la empresa.') }      
          if (!this.$v.datosEnvio.receptor.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
          if (!this.$v.datosEnvio.receptor.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
          return errores
      },
      erroresDireccion() {
          let errores = []
          if (!this.$v.datosEnvio.direccion.$dirty) { return errores }
          if (!this.$v.datosEnvio.direccion.required) { errores.push('Ingresa la direccion de la empresa.') }      
          if (!this.$v.datosEnvio.direccion.maxLength) { errores.push('Ingresa máximo 40 caracteres.') }
          if (!this.$v.datosEnvio.direccion.minLength) { errores.push('Ingresa al menos 10 caracteres.') }
          return errores
      },
      erroresCiudad() {
          let errores = []
          if (!this.$v.datosEnvio.ciudad.$dirty) { return errores }
          if (!this.$v.datosEnvio.ciudad.required) { errores.push('Ingresa la ciudad de la empresa.') }      
          if (!this.$v.datosEnvio.ciudad.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
          if (!this.$v.datosEnvio.ciudad.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
          return errores
      },
      erroresEstado() {
          let errores = []
          if (!this.$v.datosEnvio.estado.$dirty) { return errores }
          if (!this.$v.datosEnvio.estado.required) { errores.push('Ingresa la estado de la empresa.') }      
          if (!this.$v.datosEnvio.estado.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
          return errores
      },
      erroresCp() {
          let errores = []
          if (!this.$v.datosEnvio.cp.$dirty) { return errores }
          if (!this.$v.datosEnvio.cp.required) { errores.push('Ingresa la cp de la empresa.') }      
          if (!this.$v.datosEnvio.cp.maxLength) { errores.push('Ingresa máximo 6 caracteres.') }
          if (!this.$v.datosEnvio.cp.minLength) { errores.push('Ingresa al menos 5 caracteres.') }
          return errores
      },
      erroresPais() {
          let errores = []
          if (!this.$v.datosEnvio.pais.$dirty) { return errores }
          if (!this.$v.datosEnvio.pais.required) { errores.push('Ingresa la pais de la empresa.') }      
          if (!this.$v.datosEnvio.pais.maxLength) { errores.push('Ingresa máximo 12 caracteres.') }
          return errores
      },
      erroresTelefono() {
          let errores = []
          if (!this.$v.datosEnvio.telefono.$dirty) { return errores }
          if (!this.$v.datosEnvio.telefono.required) { errores.push('Ingresa la telefono de la empresa.') }      
          if (!this.$v.datosEnvio.telefono.maxLength) { errores.push('Ingresa máximo 12 caracteres.') }
          if (!this.$v.datosEnvio.telefono.minLength) { errores.push('Ingresa al menos 10 caracteres.') }
          return errores
      },
      erroresReferencia() {
          let errores = []
          if (!this.$v.datosEnvio.referencia.$dirty) { return errores }    
          if (!this.$v.datosEnvio.referencia.maxLength) { errores.push('Ingresa máximo 40 caracteres.') }
          return errores
      },
    },
    created () {
      this.consultarProdCatalogFirebase()      
      this.videosPerPage = 10;
      this.selectedPage = 0;      
    },
    methods: {
      ...mapMutations(['mostrarExito', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']), 
      saveDomicilioEnv() {
          this.datosEnvio.completo = true
          this.dialogDomicilio = false
      },
      clearDatosEnv() {
          this.datosEnvio = {}
          this.dialogDomicilio = false
      },
      search() {
        this.videosPerPage = 10
        this.selectedPage = 0
        if (
          this.searchString !== "" &&
          this.oldSearchString !== this.searchString
        ) {
          this.searchedVideos = this.catalogosDB(null, this.searchString)
          this.videoChunks = _.chunk(this.searchedVideos, this.videosPerPage)
        }
        this.oldSearchString = this.searchString
      },
      selectPerPageCount(event) {
        this.selectedPage = 0
        this.videosPerPage = event.target.value
        this.videoChunks = _.chunk(this.searchedVideos, this.videosPerPage)
      },
      selectPage(event) {
        this.selectedPage = event.target.text - 1
      },
      async consultarProdCatalogFirebase() {
          let userNameParametro = this.$route.params.userName.toLowerCase()
          let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

          if(userNameDoc.exists) {
            var userName = userNameDoc.data()        

            try {   
                await dbL.collection('productosidCatg').set([])
                let resultado = await db.collection('usuarios')
                                .doc(userName.uid)
                                .collection('catalogosDB')                                     
                                .get()

                await resultado.forEach(doc => {
                    let dosres = doc.data()
                    delete dosres.uidC
                    dbL.collection('productosidCatg').add(dosres)
                })
            }
            catch (error) {
                this.mostrarError('Todavía no hay productos registrados, o su señal de wifi está fallando. Inténtelo más tarde.')
            }
          }
          this.consultarProdCatlgLB()
      },
      async consultarProdCatlgLB() {
        await dbL.collection('productosidCatg').get().then( productosidCatg => {
          this.catalogosDB = productosidCatg
        })
        if (this.currentFilter == 'Todos') {
          this.searchedVideos = this.catalogosDB
        } else {
          this.searchedVideos = this.catalogosDB.filter(x => x.etiqueta == this.currentFilter)
        }
        // this.searchedVideos = this.catalogosDB(null, null)
        this.videoChunks = _.chunk(this.searchedVideos, this.videosPerPage)
        this.consulPedidosCarrito()
      },
      async consulPedidosCarrito() {
        await dbL.collection('arrayspedidos').get().then(arryp => {
          this.pedido = arryp.filter(crr => crr.carrito == true)
        })
      },
      async abrirFoto(catalogodb) {
               this.fotoIndex = this.catalogosDB.indexOf(catalogodb)
              // this.editedItem = Object.assign({}, item)
              this.dialog = true            
      },
      async addToCart(item) {

        const locationInCart = this.pedido.findIndex(p => {
            return p.pid === item.pid
        })
        
        if(locationInCart === -1){
          this.pedido.push({
            producto: item.nombre,
            precio: item.precio,
            unidad: item.unidad,
            pid: item.pid, //id de producto
            cantidad: 1,
            cliente: this.usuario.userName,
            // fecha: this.comanda.fecha,
            formaPedido: "autoPedido",
            carrito: true,
            // notas: ,
            // hora: new Date().toLocaleTimeString(),
            estatusCocina: 'red',
          })

        } else {
            // this.pedido[locationInCart].cantidad++
            this.mostrarError('Este producto ya está en Carrito')

        }
        this.saveCliAutoPed()
      },
      async saveCliAutoPed() {     
        let userNameParametro = this.$route.params.userName.toLowerCase()

        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
          let userName = userNameDoc.data()
          // this.hidden = !this.hidden
          let usuarioDoc = await db.collection('usuarios')
                                      .doc(userName.uid)
                                      .collection('clientes')
                                      .doc(this.usuario.userName)
                                      .get()
          
          if(usuarioDoc.exists) {

              this.clienteAuto = usuarioDoc.data()
              // this.mostrarExito('Hola, bienvenido!')

          } else {

              this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' })                    

                  let batch = db.batch()
                  let cliAutoid = uuidv4()

                  let clienteAuto = {
                      cliAutoid,
                      cliente: this.usuario.userName,
                      userNameHost: this.usuarioHost.userName,
                      userName: this.usuario.userName,
                  }

                  await batch.set(db.collection('usuarios').doc(userName.uid)
                          .collection('clientes').doc(clienteAuto.cliente), clienteAuto)                            
                          
              try {
                  batch.commit()

                  let usuarioDoc = await db.collection('usuarios')
                                  .doc(userName.uid)
                                  .collection('clientes')
                                  .doc(this.usuario.userName)
                                  .get()
      
                  this.clienteAuto = usuarioDoc.data()

                  this.mostrarExito('Hola, bienvenido!')
              }
              catch (error) {
                  this.mostrarError('Ocurrió un error registrando el Cliente')
              }
              finally {
                  this.ocultarOcupado()
              }
          }
        }
      },
      removeFromCart(item) {
          item.cantidad -= 1
          this.pedido.splice(this.pedido.indexOf(item), 1)
      },
      async saveCarrito() {
        let userNameParametro = this.$route.params.userName.toLowerCase()
        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()
        
         let comid = uuidv4(); 
        
        if(userNameDoc.exists) {
          let userName = userNameDoc.data()
          let orden = {
            comid: this.pedido[0].comid || comid,
            estatusPago: "Pendiente de Pago",
            formaPedido: "autoPedido",
            cliAutoid: this.clienteAuto.cliAutoid,
            mesero: {
                cliente: this.clienteAuto.cliente,
            },
            userNameHost: this.usuarioHost.userName,
            hora: new Date().toLocaleTimeString('es-MX'),
            fecha: new Date().toISOString().substr(0, 10),
            fechaMili: new Date().getTime(),
            fechaStd: new Date(),
            prepararPara: this.radios,
            datosEnvio: this.datosEnvio,
            envioDomicilio: this.usuarioHost.envioDomicilio,
          }

          var i
          for ( i=0; i < this.pedido.length; i++) {
              
            let batch = db.batch()
            let piid = uuidv4()
            let pxq = this.pedido[i].cantidad * this.pedido[i].precio
            
            let pedido = {                            
              piid: this.pedido[i].piid || piid,
              comid: this.pedido[0].comid || comid,
              userNameHost: this.usuarioHost.userName,
              cliAutoid: this.clienteAuto.cliAutoid,
              userName: this.usuario.userName,
              mesero: {
                  cliente: this.clienteAuto.cliente,
              },
              precio: this.pedido[i].precio,
              unidad: this.pedido[i].unidad,
              cantidad: this.pedido[i].cantidad,
              carrito: this.pedido[i].carrito,
              producto: this.pedido[i].producto,
              estatusCocina: this.pedido[i].estatusCocina,
              formaPedido: this.pedido[i].formaPedido,
              hora: new Date().toLocaleTimeString('es-MX'),
              fecha: new Date().toISOString().substr(0, 10),
              fechaMili: new Date().getTime(),
              fechaStd: new Date(),
              pxq,
              total: this.Total,
              avatar: this.fotoPortadaHost,
              prepararPara: this.radios,
            }                
                      
          await batch.set(db.collection('usuarios').doc(userName.uid)
                  .collection('clientes').doc(this.clienteAuto.cliente)
                  .collection('comandas').doc(orden.comid), orden)

          await batch.set(db.collection('usuarios').doc(userName.uid)
                  .collection('clientes').doc(this.clienteAuto.cliente)
                  .collection('comandas').doc(orden.comid)
                  .collection('pedidoTot').doc(pedido.piid), pedido)                
              
            try {
                batch.commit()             
                this.mostrarExito('El Carrito ha sido Guardado para tu próxima visita.')                  
            }
            catch (error) {
                this.mostrarError('Ocurrió un error cargando el pedido')
            }
            finally {
                this.ocultarOcupado()
            }
            this.dialog = false
          }
          this.dialog = false
          // this.hidden = true
        }
      },
      async savePedido() {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        let comid = uuidv4()
        let ntfid = uuidv4()

        let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

        if(userNameDoc.exists) {
          let userName = userNameDoc.data()
          let orden = {
            comid: this.pedido[0].comid || comid,
            estatusPago: "Pendiente de Pago",
            formaPedido: "autoPedido",
            estatusAceptada: "blue",
            cliAutoid: this.clienteAuto.cliAutoid,
            mesero: {
                cliente: this.clienteAuto.cliente,
            },
            userNameHost: this.usuarioHost.userName,
            hora: new Date().toLocaleTimeString('es-MX'),
            fecha: new Date().toISOString().substr(0, 10),
            fechaMili: new Date().getTime(),
            fechaStd: new Date(),
            ordenCompleta: false,
            prepararPara: this.radios,
            datosEnvio: this.datosEnvio,     
            enEfectivo: this.usuarioHost.enEfectivo,
            transfElectronica: this.usuarioHost.transfElectronica,
            paraLlevar: this.usuarioHost.paraLlevar,
            comerLocal: this.usuarioHost.comerLocal,
            envioDomicilio: this.usuarioHost.envioDomicilio,
            momentoPagoAdomicilio: this.usuarioHost.momentoPagoAdomicilio,
            momentoPagoComerLocal: this.usuarioHost.momentoPagoComerLocal,
            momentoPagoLlevar: this.usuarioHost.momentoPagoLlevar,
          }

          let notif = {
            ntfid,
            tipoNotif: 'cliente->restaurante',
            asunto: "ha realizado un pedido. ¿lo ACEPTAS?...",
            avatar: this.fotoPortadaHost,
            irA: "autoPedido",
            cliente: this.clienteAuto.cliente,
            cliAutoid: this.clienteAuto.cliAutoid,
            hora: new Date().toLocaleTimeString('es-MX'),
            fecha: new Date().toISOString().substr(0, 10),
            fechaMili: new Date().getTime(),
            fechaStd: new Date(),
            aComponente: 'autoPedido'
          }

          var i
          for ( i=0; i < this.pedido.length; i++) {
              
              let batch = db.batch()
              let piid = uuidv4()
              let pxq = this.pedido[i].cantidad * this.pedido[i].precio
              
              let pedido = {                            
                piid: this.pedido[i].piid || piid,
                comid: this.pedido[0].comid || comid,
                userNameHost: this.usuarioHost.userName,
                cliAutoid: this.clienteAuto.cliAutoid,
                userName: this.usuario.userName,
                mesero: {
                    cliente: this.clienteAuto.cliente,
                },
                cantidad: this.pedido[i].cantidad,
                precio: this.pedido[i].precio,
                unidad: this.pedido[i].unidad,
                producto: this.pedido[i].producto,
                estatusCocina: this.pedido[i].estatusCocina,
                formaPedido: this.pedido[i].formaPedido,
                hora: new Date().toLocaleTimeString('es-MX'),
                fecha: new Date().toISOString().substr(0, 10),
                fechaMili: new Date().getTime(),
                fechaStd: new Date(),
                pxq,
                total: this.Total,
                avatar: this.fotoPortadaHost,
                prepararPara: this.radios,
              }                
                      
          await batch.set(db.collection('usuarios').doc(userName.uid)
                  .collection('clientes').doc(this.clienteAuto.cliente)
                  .collection('comandas').doc(orden.comid), orden)

          await batch.set(db.collection('usuarios').doc(userName.uid)
                  .collection('notificaciones').doc(notif.ntfid), notif)

          await batch.set(db.collection('usuarios').doc(userName.uid)
                  .collection('clientes').doc(this.clienteAuto.cliente)
                  .collection('comandas').doc(orden.comid)
                  .collection('pedidoTot').doc(pedido.piid), pedido)                
              
              try {
                  batch.commit()
                  // this.pedidoCli.push(pedido)                    
                  this.mostrarExito('El Pedido ha sido enviado, favor de esperar por la respuesta del proveedor')                  
              }
              catch (error) {
                  this.mostrarError('Ocurrió un error cargando el pedido')
              }
              finally {
                  this.ocultarOcupado()
              }
              this.dialog = false
          }
          this.dialog = false
          this.$router.push({ name: 'cliPedEstat', params: {userNameHost: this.usuarioHost.userName, userName: this.usuario.userName} })
        }
      },
    },
  }
</script>