
let editInfoLiga = () => import( '../views/infoLiga/editInfoLiga.vue')
let displayInfoLiga = () => import( '../views/infoLiga/displayInfoLiga.vue')

export default [
  {
    path: '/:userName/edit-infoLiga',
    name: 'editInfoLiga',
    component: editInfoLiga,
    meta: {
      autenticado: true
    }
  },

  {
    path: '/:userName/infoLiga',
    name: 'displayInfoLiga',
    component: displayInfoLiga,
    
  },
]