import Vue from 'vue'
import Router from 'vue-router'
import { auth } from '@/firebase'

import primerAfterLogin from '@/views/primerAfterLogin.vue'
import HomePublicaUsuario from '@/views/HomePublicaUsuario.vue'
import visitasEnLobbyInfo from '@/views/visitasEnLobbyInfo.vue'
// import HomePublicaSinUsuario from '@/views/HomePublicaSinUsuario.vue'
import HomeSistema from '@/views/HomeSistema.vue'
// import Home from '@/views/Home.vue'
import HomeMtto from '@/views/HomeMtto.vue'
// import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import AccionesEmail from '@/views/usuario/AccionesEmail.vue'
import LegalDocs from '@/views/LegalDocs.vue'
import GuiaPractica from '@/views/GuiaPractica.vue'
import EnvioVerificacionEmail from '@/views/usuario/EnvioVerificacionEmail.vue'
import VerificacionEmail from '@/views/usuario/VerificacionEmail.vue'
import RegistroUser from '@/views/usuario/RegistroUser.vue'
import LoGin from '@/views/usuario/LoGin.vue'
import minimenu from '@/views/miniMenu.vue'
import minidatosg from '@/views/miniDatosg.vue'
import listaMiembros from '@/views/listaMiembros.vue'
import miniservicios from '@/views/miniServicios.vue'
import minicatalogo from '@/views/miniCatalogo.vue'
import miniservicioseventos from '@/views/organizacionEventos/miniServicioseventos.vue'
import OficinaMain from '@/views/OficinaMain.vue'
import gastos from '@/views/gasTos.vue'
import planeacionDeGastos from '@/views/planeacionDeGastos.vue'
import gastosProgramados from '@/views/gastosProgramados.vue'
import ingresos from '@/views/ingrEsos.vue'
import etiquetasFinanzas from '@/views/etiquetasFinanzas.vue'
import historial from '@/views/hisTorial.vue'
import creditosYprestamos from '@/views/creditosYprestamos.vue'
import ingresoVSgasto from '@/views/ingresoVSgasto.vue'
import EdicionFotoProductoCatalogo from '@/views/EdicionFotoProductoCatalogo.vue'
import EdicionMiniDatos from '@/views/EdicionMiniDatos.vue'
import EdicionImgPortada from '@/views/usuario/EdicionImgPortada.vue'
import EdicionMiniMenu from '@/views/EdicionMiniMenu.vue'
import EdicionMiniServicios from '@/views/EdicionMiniServicios.vue'
import EdicionMiniCatalogo from '@/views/EdicionMiniCatalogo.vue'
import solicitudCotiz from '@/views/solicitudCotiz.vue'
import ProspectVentasCRM from '@/views/ProspectVentasCRM.vue'
import Clientes from '@/views/ClienTes.vue'
import clientesAgregar from '@/components/clientesAgregar.vue'
import ClienteEstatus from '@/views/ClienteEstatus.vue'
import Calendario from '@/views/CalenDario.vue'
import NotiFicaciones from '@/views/NotiFicaciones.vue'
import mnsjsVentanilla from '@/views/mnsjsVentanilla.vue'
import Colaboradores from '@/views/ColaboraDores.vue'
import CrearNuvinHija from '@/views/CrearNuvinHija.vue'
import colorPalet from '@/views/colorPalet.vue'
import EdicionImgPago from '@/views/EdicionImgPago.vue'
import EdicionImgExpediente from '@/views/EdicionImgExpediente.vue'
import comprobantePago from '@/components/comprobantePago.vue'
import pagoConTransfElect from '@/components/pagoConTransfElect.vue'
import configuracion from '@/views/configuraCion.vue'
import caracteristicasApp from '@/views/caracteristicasApp.vue'
import SendMessage from './views/whatsappBot/SendMessage.vue';

import ImagenesFiltro from '@/views/superadmin/ImagenesFiltro.vue'

import moduleRestaurante from '@/modulesRouter/moduleRestaurante.js'
import moduleDespachoContable from '@/modulesRouter/moduleDespachoContable.js'
import modulePlaza from '@/modulesRouter/modulePlaza.js'
import moduleInfoLiga from '@/modulesRouter/moduleInfoLiga.js'
import moduleSuperadmin from '@/modulesRouter/moduleSuperadmin.js'
import moduleHerramientasExtra from '@/modulesRouter/moduleHerramientasExtra.js'
import moduleServInmobiliarios from '@/modulesRouter/moduleServInmobiliarios.js'
import moduleComercio from '@/modulesRouter/moduleComercio.js'
import moduleCompras from '@/modulesRouter/moduleCompras.js'
import moduleAlimYBebidas from '@/modulesRouter/moduleAlimYBebidas.js'
import moduleClinica from '@/modulesRouter/moduleClinica.js'
import moduleNegoApp from '@/modulesRouter/moduleNegoApp.js'
import moduleVtaProdDigital from './modulesRouter/moduleVtaProdDigital.js'
import moduleMisLetras from './modulesRouter/moduleMisLetras.js'
import moduleVeiFloti from './modulesRouter/moduleVeiFloti.js'
import moduleOrgEventos from './modulesRouter/moduleOrgEventos.js'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...moduleRestaurante,
    ...moduleDespachoContable,
    ...modulePlaza,
    ...moduleInfoLiga,
    ...moduleSuperadmin,
    ...moduleHerramientasExtra,
    ...moduleServInmobiliarios,
    ...moduleComercio,
    ...moduleCompras,
    ...moduleAlimYBebidas,
    ...moduleClinica,
    ...moduleNegoApp,
    ...moduleVtaProdDigital,
    ...moduleMisLetras,
    ...moduleVeiFloti,
    ...moduleOrgEventos,

    {
      path: '/verificacion-email-link',
      name: 'verificacion-email-link',
      component: VerificacionEmail
    },
    {
      path: '/',
      name: 'HomeSistema',
      component: HomeSistema
    },
    {
      path: '/web',
      name: 'HomeSistema',
      component: HomeSistema
    },
    {
      path: '/#',
      name: 'HomeMtto',
      component: HomeMtto
    },
    {
      path: '/legal',
      name: 'LegalDocs',
      component: LegalDocs
    },
    {
      path: '/visita-en-lobby',
      name: 'visita-en-lobby',
      component: visitasEnLobbyInfo
    },
    {
      path: '/guia-practica',
      name: 'GuiaPractica',
      component: GuiaPractica
    },
    {
      path: '/aplicacion-caracteristicas/:app',
      name: 'caracteristicas-aplicacion',
      component: caracteristicasApp,
      meta: { isPublic: true }
    },
    {
      path: '/inicio-cuenta', // info de restaurant, y cada giro tendrá está página o componente
      name: 'primerAfterLogin',
      component: primerAfterLogin,
    },
    {
      // path: '/registro/:tipoCuenta/:app',
      path: '/registro/:app',
      name: 'registro',
      component: RegistroUser,
      meta: { isPublic: true }
    },
    {
      path: '/login',
      name: 'login',
      component: LoGin
    },
    {
      path: '/:userName',
      name: 'Entrada',
      component: HomePublicaUsuario,
      meta: { autenticado: true }, // Asegúrate de tener esto si la ruta requiere autenticación
      props: true,
      children: [
        { path: 'datos', component: minidatosg }, 
        { path: 'miembros', component: listaMiembros },
        { path: 'menu', component: minimenu }, 
        { path: 'servicios', component: miniservicios },
        { path: 'productos', component: minicatalogo },
        { path: 'servicios', component: miniservicioseventos },
      ]
    },
    // {
    //   path: '/:userName', 
    //   name: 'EntradaSinUsuario',
    //   component: HomePublicaSinUsuario,
    //   props: true,
    //   children: [
    //     { path: 'datos', component: minidatosg },
    //     { path: 'miembros', component: listaMiembros },
    //     { path: 'menu', component: minimenu }, 
    //     { path: 'servicios', component: miniservicios },
    //     { path: 'productos', component: minicatalogo },
    //     { path: 'servicios', component: miniservicioseventos },     
    //   ]
    // },    
    {
      path: '/:userName/notificaciones',
      name: 'notificaciones',
      component: NotiFicaciones,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/send-message',
      name: 'SendMessage',
      component: SendMessage,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/ventanilla',
      name: 'ventanilla',
      component: mnsjsVentanilla,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/comprobante-pago/:comanda',
      name: 'comprobante-pago',
      component: EdicionImgPago,
      // meta: {
      //   autenticado: true
      // }
    },
    {
      path: '/:userName/editor-img-expediente/:cronoMoment',
      name: 'edit-img-expediente',
      component: EdicionImgExpediente,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/comprobante-pago',
      name: 'comprobantePago',
      component: comprobantePago,
    },
    {
      path: '/:userName/pago-transferencia-electronica/:referencia',
      name: 'pagoConTransfElect',
      component: pagoConTransfElect,
    },
    {
      path: '/:userName/oficina',
      name: 'oficina',
      component: OficinaMain,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/oficina/soportenuvit',
      name: 'oficinasa',
      component: OficinaMain,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/gastos',
      name: 'gastos',
      component: gastos,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/planeacion-de-gastos',
      name: 'planeacionDeGastos',
      component: planeacionDeGastos,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/ingresos',
      name: 'ingresos',
      component: ingresos,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/gastos-programados',
      name: 'gastosProgramados',
      component: gastosProgramados,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/etiquetas',
      name: 'etiquetasFinanzas',
      component: etiquetasFinanzas,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/creditos-prestamos',
      name: 'creditos-prestamos',
      component: creditosYprestamos,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/ingresos-gastos',
      name: 'ingresoVSgasto',
      component: ingresoVSgasto,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/admin/historial',
      name: 'historial',
      component: historial,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/edicion/datos',
      name: 'edicion-mini-datos',
      component: EdicionMiniDatos,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/edicion/imagen',
      name: 'edicion-mini-imagen',
      component: EdicionImgPortada,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/edicion/menu',
      name: 'edicion-mini-menu',
      component: EdicionMiniMenu,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/edicionmini/servicios',
      name: 'edicion-mini-servicios',
      component: EdicionMiniServicios,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/edicion/productos',
      name: 'edicion-mini-catalogo',
      component: EdicionMiniCatalogo,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/cotizaciones',
      name: 'sol-cotizaciones',
      component: solicitudCotiz,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/prospectos-ventas-CRM',
      name: 'ProspectVentasCRM',
      component: ProspectVentasCRM,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/clientes',
      name: 'Clientes',
      component: Clientes,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/clientesApp/agregar',
      name: 'clientesAgregar',
      component: clientesAgregar,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userNameHost/ReservoHabitacion/persona-especial-estatus/:userName',
      name: 'cliente-estatus',
      component: ClienteEstatus,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/calendario',
      name: 'calendario',
      component: Calendario,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/edicion-foto-ctglo/:pid',
      name: 'edicion-minprod-catalogo',
      component: EdicionFotoProductoCatalogo,
      props: true,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/configuracion',
      name: 'configuracion',
      component: configuracion,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/superadmin/imagenes-filtro',
      name: 'imagenes-filtro',
      component: ImagenesFiltro,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/agregar-colaborador',
      name: 'agregarColaboradores',
      component: Colaboradores,
      meta: {
        autenticado: true
      },
    },
    {
      path: '/:userName/crear-nuvin',
      name: 'CrearNuvinHija',
      component: CrearNuvinHija,
      meta: {
        autenticado: true
      }
    },
    {
      path: '/:userName/paleta-colores',
      name: 'colorPalet',
      component: colorPalet,
      meta: {
        autenticado: true
      }
    },

    



    
    {
      path: '/envio-verificacion-email',
      name: 'envio-verificacion-email',
      component: EnvioVerificacionEmail
    },
    {
      path: '/acciones-email',
      name: 'acciones-email',
      component: AccionesEmail
    },
    {
      path: '/404',
      name: '404',
      component: NotFound
    },        
  ]
})

router.beforeEach((to, from, next) => {
  let user = auth.currentUser;
  // console.log('Current user:', user);

  if (to.matched.some(record => record.meta.autenticado)) {
    if (user) {
      // console.log('User is authenticated:', user);
      if (!user.emailVerified) {
        console.log('User email is not verified');
        next({ name: 'envio-verificacion-email' });
      } else {
        next();
      }
    } else {
      console.log('User is not authenticated');
      next({ name: 'login' });
    }
  } else {
    next();
  }
});


// router.beforeEach((to, from, next) => {
//   let user = auth.currentUser

//   if(to.matched.some(record => record.meta.autenticado)) {
//     if(user) {
//       if(!user.emailVerified) {
//         next({ name: 'envio-verificacion-email'})
//       }
//       else
//         next()
//     }
//     else {
//       next({ name: 'login'})
//     }
//   }
//   else {
//     next()
//   }
// })

export default router