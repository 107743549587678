<template>
  <v-layout justify-center align-center wrap>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="dialogNoDisp" persistent max-width="290">
          <!-- <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
          </template> -->
          <v-card>
            
            <v-card-text>Esta sección está en reparación temporalmente</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="movingSameHostAoficina">Entendido</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
    <v-flex xs12 ml-3 mt-2>
      <!-- <v-btn icon dark>                
          <a @click="movingSameHost"><v-icon small dark left color="black">arrow_back</v-icon></a>
      </v-btn>
      <span class="body-2">oficina virtual</span> -->
    </v-flex>
    <v-flex xs12>
      <v-card class="elevation=5 ma-3 mt-5 pt-3 pb-3" >
        <v-layout>
          <v-flex xs12 class="text-xs-right">
              <v-dialog
                  v-model="dialogHelp"
                  width="500"
              >
                  <template v-slot:activator="{ on }">
                    <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                  </template>

                  <v-card>
                  <v-card-title class="headline">
                      Cómo funciona esta sección...
                  </v-card-title>

                  <v-card-text>
                      En esta área se agrega o cambia la Foto de Portada del Área Pública que al miso tiempo es 
                      la del "avatar" (foto circular que aparece en la parte superior izquierda de tu cuenta nuVin). <br>
                      <ul>
                          <li>En la parte inferior, al dar click en "Seleccionar imagen" se desplegará la ventana de tu dispositivo que dirige
                            a las carpetas que se manejan en él, dirígete a la (que por lo general es Galería o Imágenes) que contenga la imagen que deseas.
                          </li>
                          <li>
                              Cuando seleccionas la imagen, aparece en un recuadro para recortar si así lo deseas. Una vez que esté la imagen como deseas y dar
                              click en Guardar, la imagen se despliga de inmediato a la Foto de Portada y Avatar.
                          </li>
                      </ul> 
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                  </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout align-center justify-center column row fill-height wrap>
          <v-flex xs5>
            <v-card class="elevation=5 ma-3 mt-5 pt-3 pb-3" >
              <v-toolbar card dark class="elevation=5" :style="{'background-color': paletaColores.primero}">
                <v-toolbar-title>
                  <span v-if="vista == 1">Foto de Portada Actual</span>
                  <span v-if="vista == 2" >Vista Previa</span>
                </v-toolbar-title>        
              </v-toolbar>
              <v-card-text>
                <v-img v-if="vista == 1" :src="usuario.fotoPortada256" alt="Foto de Portada"></v-img>
                <div v-if="vista == 2" ref="vistaPrevia" class="vistaPrevia"></div>
              </v-card-text>
              <v-card-text>
                <!-- <v-layout v-if="vista == 1" justify-center>
                  <v-btn color="secondary" v-if="usuario" :to="{ name: 'homePublicaUsuario', params: { userName: usuario.userName } }">Regresar</v-btn>
                </v-layout> -->
                <v-layout v-if="vista == 2" justify-center>
                  <v-flex xs6>
                    <v-btn v-if="usuario" :to="{ name: 'Entrada', params: { userName: usuario.userName } }">Cancelar</v-btn>
                  </v-flex>
                  <v-flex xs6>
                    <v-btn @click="guardarImagen" color="secondary">Guardar</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>      
          <v-flex xs5 mt-3>
            <v-card min-width="250px">
              <v-tabs icons-and-text grow>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab>
                  <span class="caption">Subir Imagen</span>
                  <v-icon>cloud_upload</v-icon>
                </v-tab>
                <v-tab-item>
                  <file-pond v-if="vista == 1 && fotosPortada.length < 3" @addfile="cargarImagen" instant-upload="false" fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}" labelFileTypeNotAllowed="Agrega una imagen .png o .jpg" accepted-file-types="image/jpeg, image/png" label-idle="Da click aquí y selecciona o arrastra una imagen..."></file-pond>
                  <file-pond v-if="vista == 1 && fotosPortada.length >= 3" instant-upload="false" fileValidateTypeLabelExpectedTypes="Se esperaba {allButLastType} o {lastType}" labelFileTypeNotAllowed="Agrega una imagen .png o .jpg" accepted-file-types="image/jpeg, image/png" label-idle="Has superado el mínimo de imágenes guardadas, borra alguna para poder agregar."></file-pond>
                  <v-responsive v-if="vista == 2">
                    <img ref="imagenOriginal" src="" alt="" class="edicionImagen">
                  </v-responsive>
                </v-tab-item>
                <v-tab v-if="fotosPortada.length > 1">
                  <span class="caption">Galería</span>
                  <v-icon>photo_library</v-icon>
                </v-tab>
                <v-tab-item v-if="fotosPortada.length > 1">
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm4 lg3 v-for="foto in fotosPortada" :key="foto.id">
                      <v-card class="ma-3">
                        <v-card-text>
                          <v-img :src="foto.url" alt="Foto Portada"></v-img>
                          <v-layout class="mt-3">
                            <v-flex xs6>
                              <v-layout justify-start>
                                <v-icon v-if="foto.fotoId == usuario.fotoPortada" color="green" medium>check_circle</v-icon>
                                <v-icon v-else color="grey" @click="seleccionarFotoPortada(foto.fotoId)" medium>check_circle_outline</v-icon>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6>
                              <v-layout justify-end>
                                <v-icon v-if="foto.fotoId != usuario.fotoPortada" medium @click="preguntarEliminarImagen(foto)">delete</v-icon>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-dialog v-model="preguntaEliminar" max-width="400" persistent>
                    <v-card>
                      <v-card-text class="title">
                        La imagen se eliminará permanentemente, ¿Deseas Continuar?
                      </v-card-text>
                      <v-btn color="secondary" flat @click="preguntaEliminar = false">Cancelar</v-btn>
                      <v-btn flat @click="eliminarImagen">Eliminar</v-btn>
                    </v-card>
                  </v-dialog>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>    
  </v-layout>
</template>

<script>
import { db, storage, auth, functions } from '@/firebase'
import { mapMutations, mapState, mapActions } from 'vuex'

import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import "filepond/dist/filepond.min.css"

import Cropper from 'cropperjs/dist/cropper'
import 'cropperjs/dist/cropper.css'

import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  components: { FilePond },
  data() {
    return {
      dialogNoDisp: false,
      vista: 1,
      cropper: null,
      fotosPortada: [],
      preguntaEliminar: false,
      fotoEliminar: null,
      dialogHelp: false,
    }
  },
  created() {
    this.consultarWhereFrom()
    this.actualizarPerfilAuth() 
  },
  computed: {
    ...mapState('sesion', ['usuario']),
    ...mapState('usuarioHost', ['usuarioHost']),
    ...mapState('usuarioHost', ['perfilAuth']),
    paletaColores() {
        if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
            return this.usuarioHost.designColors;
        } else {
            const colores = ['#E040FB',];
            const colores2 = ['#616161',];
            const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
            const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
            return {
            primero: colorAleatorio,
            segundo: colorAleatorio2,
            tercero: colorAleatorio,
            cuarto: colorAleatorio
            };
        }
    },
    
  },
  methods: {
    ...mapMutations(['mostrarError', 'mostrarInformacion', 'mostrarOcupado', 'ocultarOcupado']),
    ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
    ...mapActions('sesion', ['actualizarFotoPortada']),
    async movingSameHostAoficina() {
      if (this.$route.name != 'oficina') {
        await dbL.collection('fromOfi').set([])
        await dbL.collection('fromOfi').add({sameHost: true, userHost: this.$route.params.userName.toLowerCase()})
        this.$router.push({ name: 'oficina' }) 
      }
    },
    async movingSameHost() {
        await dbL.collection('fromSameHost').set([])
        await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName.toLowerCase() })
        this.$router.go(-1)
    },
    async consultarWhereFrom() {
        await dbL.collection('fromSameHost').get().then(fP => {
            this.sameHost = fP[0].sameHost
            this.sameHostName = fP[0].userHost
        })
        this.consultarColLB()
    },
    async consultarColLB() {
        if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
            // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
            this.$router.push({ name: 'Entrada' })
        } else if (this.sameHost == true) {
        
            this.colaboradores = []
            this.colaborador = ''
            if (this.perfilAuth == true) {
                this.colaborador = this.usuarioHost
                //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
            } else if (this.perfilAuth == false) {
            
                await dbL.collection('colaboradores').get().then(cols => {
                this.colaboradores = cols
                })

                if (this.colaboradores.length > 0) {
                    let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                    if (typeof colaborador === 'object') {
                        let convertINarray = Object.values(colaborador.estatusAccesos)
                        delete colaborador.estatusAccesos
                        colaborador.estatusAccesos = convertINarray
                        this.colaborador = colaborador  
                    } else {
                        this.colaborador = this.usuarioHost
                        //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                    } 
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })  
                }
            } else {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }
        }
        // await dbL.collection('fromSameHost').delete()
        // this.sameHost = false
        // this.sameHostName = ''
        this.consultarFotosPortada()
    },
    async consultarFotosPortada() {
      this.mostrarOcupado({ titulo: 'Consultando Galería', mensaje: 'Recuperando galería de imágenes...' })
      let userNameParametro = this.$route.params.userName.toLowerCase()
      let userNameDoc = await db.collection('userNames')
                              .doc(userNameParametro)
                              .get()

      if(userNameDoc.exists) {
          let userName = userNameDoc.data()

        try {
          let resultado = await db.collection('usuarios')
                                  .doc(userName.uid)
                                  .collection('fotos-portada')
                                  .orderBy('fecha', 'desc')
                                  .get()

          resultado.docs.forEach(doc => {
            let fotoPortada = doc.data()
            fotoPortada.url = ''

            this.fotosPortada.push(fotoPortada)
          })

          let ref = storage.ref()
          let uid = userName.uid

          this.fotosPortada.forEach(fotoPortada => {
            ref.child(`usuarios/${uid}/fotos-portada/${fotoPortada.fotoId}-256x256.jpg`)
              .getDownloadURL()
              .then(url => {
                fotoPortada.url = url
              })
          })
        }
        catch (error) {
          this.mostrarError('Ocurrió un error recuperando tu galería de fotos.')
        }
        finally {
          this.ocultarOcupado()
        }
      }
    },
    cargarImagen (error, archivo) {
      if (error) {
        this.mostrarError('Ocurrió un error leyendo la imagen.')
        return
      }

      this.vista = 2

      let reader = new FileReader()

      let vm = this

      reader.onloadend = () => {
        vm.$refs.imagenOriginal.src = reader.result
        vm.recortarImagen()
      }

      if (archivo) {
        if (archivo.file) {
          reader.readAsDataURL(archivo.file)
        }
      }
    },
    recortarImagen() {
      this.cropper = new Cropper(this.$refs.imagenOriginal, {
        preview: this.$refs.vistaPrevia,
        aspectRatio: 1, //2.13,
        modal: false,
        guides: true,
        sized: false,
        zoomable: false,
        highlight: true,
        background: false,
        autoCrop: true
      })
    },
    async guardarImagen() {
      this.mostrarOcupado({ titulo: 'Almacenando Imagen', mensaje: 'Actualizando imagen de portada...' })
      let userNameParametro = this.$route.params.userName.toLowerCase()
      
      let userNameDoc = await db.collection('userNames')
                              .doc(userNameParametro)
                              .get()

      if(userNameDoc.exists) {
        let userName = userNameDoc.data()
        
        let canvas = this.cropper.getCroppedCanvas()
        let imagen = canvas.toDataURL('image/jpeg')
        let fotoId = uuidv4()

        try {
          let ref = storage.ref()          

          await ref.child(`usuarios/${userName.uid}/fotos-portada/${fotoId}.jpg`)
                  .putString(imagen, 'data_url')
                  
          let generarMiniaturas = functions.httpsCallable('generarMiniaturas')
          let idToken = await auth.currentUser.getIdToken(true)
          
          if (await generarMiniaturas({ idToken, fotoId })) {
            
            let fotoPortada = {
              fotoId,
              fecha: new Date(),
              uid: userName.uid
            }
            
            await db.collection('usuarios')
                  .doc(fotoPortada.uid)
                  .collection('fotos-portada')
                  .doc(fotoId)
                  .set(fotoPortada)

            await db.collection('usuarios')
                    .doc(fotoPortada.uid)
                    .update({ fotoPortada: fotoId })
                    
            this.actualizarFotoPortada(fotoId)
            this.$router.push({ name: 'Entrada', params: { userName: this.usuario.userName } })
            window.location.reload();

          }
        }
        catch (error) {
          console.error("Error al guardar la imagen:", error);
          this.mostrarError('Ocurrió un error almacenando la imagen.')
        }
        finally {
          this.ocultarOcupado()
        }
      }
    },
    async seleccionarFotoPortada(fotoId) {
      this.mostrarOcupado({ titulo: 'Actualizando Imagen', mensaje: 'Estableciendo foto de portada...' })
      let userNameParametro = this.$route.params.userName.toLowerCase()
      let userNameDoc = await db.collection('userNames')
                              .doc(userNameParametro)
                              .get()

      if(userNameDoc.exists) {
        let userName = userNameDoc.data()
    
        try {
          await db.collection('usuarios')
                  .doc(userName.uid)
                  .update({ fotoPortada: fotoId })

          this.actualizarFotoPortada(fotoId)
        } 
        catch (error) {
          this.mostrarError('Ocurrió un error seleccionando la imagen.')
        }
        finally {
          this.ocultarOcupado()
        }
      }
    },
    preguntarEliminarImagen (foto) {
      this.fotoEliminar = foto
      this.preguntaEliminar = true
    },
    async eliminarImagen () {
      this.preguntaEliminar = false
      this.mostrarOcupado({ titulo: 'Eliminando Imagen', mensaje: 'Se está eliminando tu imagen...' })
      let userNameParametro = this.$route.params.userName.toLowerCase()
      let userNameDoc = await db.collection('userNames')
                              .doc(userNameParametro)
                              .get()

      if(userNameDoc.exists) {
          let userName = userNameDoc.data()

        try {
          await db.collection('usuarios')
                  .doc(userName.uid)
                  .collection('fotos-portada')
                  .doc(this.fotoEliminar.fotoId)
                  .delete()

          let dimensiones = [512, 256, 128, 64, 32]

          let promises = dimensiones.map(async dimension => {
            let ancho = dimension
            let alto = dimension

            await storage.ref().child(`usuarios/${userName.uid}/fotos-portada/${this.fotoEliminar.fotoId}-${ancho}x${alto}.jpg`)
                            .delete()
          })

          await Promise.all(promises)

          let index = this.fotosPortada.indexOf(this.fotoEliminar)
          this.fotosPortada.splice(index, 1)
        }
        catch (error) {        
          this.mostrarError('Ocurrió un error eliminando la imagen.')
        }
        finally {
          this.ocultarOcupado()
        }
      }
    },
  }
}
</script>

<style>
  
.filepond--drop-label {
  font-size: 14pt !important;
}

.edicionImagen {
  width: 100%;
}

.vistaPrevia {
  width: 320px;
  height: 150px;
  overflow: hidden;
}
</style>
