<template>
  <v-layout justify-center align-center wrap>
    <v-card class="elevation=5 ma-3 mt-3" :style="{'background-color': paletaColores.cuarto}">
      <!-- <v-flex xs6 sm3 md3 lv-card
        <div class="ml-5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="date"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Ver estatus de fecha"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
              
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="openCalDate(date)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>        
      </v-flex>
      <v-spacer></v-spacer> -->
      <v-toolbar class="text-xs-right py-2">
        <span class="display-1 font-weight-medium">Pacientes</span>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialogHelp"
            width="500"
        >
            <template v-slot:activator="{ on }">
            <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
            </template>
            <v-card>
            <v-card-title class="headline">
                Cómo funciona esta sección...
            </v-card-title>

            <v-card-text>
                En esta área se agregan los Pacientes en el recuadro resultante de dar click en "agregar", se llenan los campos y se fa click en guardar. <br><br>
                Al momento se enlistan en la tabla y aparecen las opciones de borrar o editar para cuando se requiera. <br><br>
                Todos los Pacientes agregados aparecerán en el campo de Selección en la sección de Candelario para asignar las citas.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
      </v-toolbar>
      <v-toolbar flat color="white" class="mt-3">
        <!-- <v-toolbar-title>Pacientes</v-toolbar-title> -->
        <!-- <span class="body-1 font-weight-bold">Pacientes</span>
        <v-divider
            class="mx-2"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer> -->
        <!-- <v-layout align-start justify-center fill-height wrap> -->
          <v-dialog v-model="dialog" max-width="500px" class="text-xs-right">
            <template v-slot:activator="{ on }">
            <v-btn :style="{'background-color': paletaColores.primero}" dark class="mb-2 ml-5 caption font-weight-bold text-lowercase" v-on="on" small v-if="puedeCrearPaciente">Agregar</v-btn>
            <v-btn dark class="mb-2 ml-5 caption font-weight-bold text-lowercase" disabled small v-else>Agregar</v-btn>
            </template>
            <v-card>
              <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                  <v-container grid-list-md>
                  <v-layout wrap>
                      <v-flex xs12>
                      <v-text-field v-model="editedItem.cliente" label="Nombre" required @blur="$v.editedItem.cliente.$touch()" :error-messages="erroresNombre"></v-text-field>
                      <v-text-field v-model="editedItem.telefono" label="Teléfono"></v-text-field>
                      <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                      </v-flex>
                  </v-layout>
                  </v-container>
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" flat @click="close">Cancelar</v-btn>
                  <v-btn color="primary" flat @click="save" :depressed="$v.editedItem.cliente.$invalid" :disabled="$v.editedItem.cliente.$invalid">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Búsqueda"
            single-line
            hide-details
          ></v-text-field>
        <!-- </v-layout> -->
      </v-toolbar>
      <v-data-table
      :headers="headers"
      :items="clientesdb"
      :search="search"
      >
      <template v-slot:items="props">
        <td>{{ props.item.cliente }}</td>
        <td>{{ props.item.telefono }}</td>
        <td>{{ props.item.email }}</td>
        <td class="justify-start layout">
          <v-dialog v-model="dialogEventos" persistent width="600px" >
            <template v-slot:activator="{ on }">
              <v-icon class="my-2 mr-3" @click="abrirCliente(props.item)" color="grey darken-2" dark v-on="on">mdi-file-cabinet</v-icon>
            </template>
            <v-card v-if="clientePicked" 
              :key="clientePicked.cliid">
              <template>
                <div>
                  <v-toolbar flat color="white">
                    <v-toolbar-title><span>Eventos/Citas</span> <br><span>{{ clientePicked.cliente }}</span></v-toolbar-title><br>                       
                    <v-divider class="mx-2" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogEv" persistent max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2 caption" v-on="on" small>Agregar</v-btn>
                      </template>
                      <v-card >
                        <v-card-title>
                          <span class="headline">{{ formTitleEv }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm6 >
                                <v-text-field v-model="editedItemEv.concepto" label="Concepto" required @blur="$v.editedItemEv.concepto.$touch()" :error-messages="erroresConcepto"></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 >                                    
                                <!-- value="2021-06-07T00:00" esto va aca abajo pero marcaba error -->
                                <input type="datetime-local" id="meeting-time"
                                      v-model="editedItemEv.fechaEvento" 
                                      
                                      min="2021-06-07T00:00" max="2144-06-14T00:00"
                                      required @blur="$v.editedItemEv.fechaEvento.$touch()" :error-messages="erroresFecha">
                                <!-- <template>
                                  <v-layout row justify-center>
                                    <v-dialog v-model="dialogViewCal" scrollable max-width="600px">
                                      <template v-slot:activator="{ on }">
                                        <v-btn small color="primary" dark v-on="on" icon><v-chip label color="primary" text-color="white"><v-icon left small>mdi-calendar-range</v-icon><span class="caption">consultar estatus</span></v-chip></v-btn>
                                      </template>
                                      <v-card>
                                        <v-card-title>Estatus del Calendario</v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                          <Calendario/>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                          <v-btn color="primary" flat @click="dialogViewCal = false">Cerrar</v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-dialog>
                                  </v-layout>
                                </template> -->
                              </v-flex>
                              <v-flex xs12 sm7>
                                <v-select
                                  v-model="editedItemEv.duration"
                                  :items="itemsDuration"
                                  label="Duración (minutos)"
                                ></v-select>
                              </v-flex>
                              <v-flex xs12 sm6 >
                                <v-textarea
                                  v-model="editedItemEv.activInit"
                                  outline
                                  name="input-6-4"
                                  label="Actividad a realizar"
                                  value=""
                                  required @blur="$v.editedItemEv.activInit.$touch()" :error-messages="erroresActivInit"
                                ></v-textarea>
                                <!-- <div>
                                  <v-badge
                                    color="green"
                                    left
                                    overlap>
                                    <template v-slot:badge>
                                      <v-icon
                                        dark
                                        small
                                      >
                                        done
                                      </v-icon>
                                    </template>
                                    <v-btn icon @click="idsExpedsBefore()">
                                      <v-icon>mdi-image-plus</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </div> -->
                                <div>
                                  <v-btn icon @click="idsExpedsBefore()">
                                    <v-icon>mdi-image-plus</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 >
                                <v-textarea
                                  v-model="editedItemEv.activEnd"
                                  outline
                                  name="input-6-4"
                                  label="Conclusión de la actividad"
                                  value=""
                                  :error-messages="erroresActivEnd"
                                ></v-textarea>
                                <v-btn icon @click="idsExpedsAfter()">
                                  <v-icon>mdi-image-plus</v-icon>
                                </v-btn>
                              </v-flex>                  
                            </v-layout>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" flat @click="closeEv">Cerrar</v-btn>
                          <v-btn :depressed="$v.editedItemEv.concepto.$invalid" :disabled="$v.editedItemEv.$invalid" color="primary" flat @click="saveEv">Guardar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                  <v-data-table
                    :headers="headersEv"
                    :items="cliEventos"
                    class="elevation-1"
                  >
                    <template v-slot:items="props">
                      <td>{{ props.item.concepto }}</td>
                      <td class="text-xs-right">{{ props.item.fechaEvento }}</td>
                      <td class="justify-left layout mt-3">
                        <!-- <template>
                          <v-layout> -->
                            <v-dialog v-model="dialogExped" fullscreen hide-overlay transition="dialog-bottom-transition">
                              <template v-slot:activator="{ on }">
                                <v-btn small icon color="grey darken-2" v-on="on">
                                  <v-icon
                                    small
                                    @click="abrirClienteEv(props.item)"
                                    color="white"
                                  >
                                    mdi-eye
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-card v-if="clienteEvPicked" 
                                      :key="clienteEvPicked.cliEvid">
                                <v-toolbar dark color="grey darken-4">
                                  <v-btn icon dark @click="dialogExped = false">
                                    <v-icon small>mdi-close</v-icon>
                                  </v-btn>
                                  <v-layout wrap>
                                    <v-toolbar-title>Expediente de {{ clienteEvPicked.cliente }}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <span class="mt-1">Feecha: <br> {{ clienteEvPicked.fechaEvento }}</span>
                                  </v-layout>
                                  <v-spacer></v-spacer>
                                </v-toolbar>
                                <span class="headline ml-5 mt-5">Servicio Realizado:</span><br>
                                <span class="headline ml-5 mt-5">{{ clienteEvPicked.concepto }}</span><br>
                                <v-layout align-center justify-space-around wrap fill-height>
                                  <v-flex mt-3 xs10 md5 >
                                    <v-card color="transparent" class="primary--text">
                                      <v-layout align-center justify-space-around fill-height wrap>
                                        <v-flex xs10 sm5 v-if="clienteEvPicked.imgBefore">
                                          <v-img
                                            :src="clienteEvPicked.imgBefore"
                                            height="125px"
                                            contain
                                          ></v-img>
                                        </v-flex>
                                        <v-spacer></v-spacer>
                                        <v-flex xs10 sm7>
                                          <v-card-title primary-title class="py-1 px-1">
                                              <span><strong>Estatus y Actividad inicial</strong></span>                                              
                                          </v-card-title>
                                          <v-card-title primary-title class="py-1 px-1">
                                              {{ clienteEvPicked.activInit }}                                          
                                          </v-card-title>
                                        </v-flex>
                                      </v-layout>
                                    </v-card>
                                  </v-flex>
                                  <v-flex mt-3 xs10 sm5 >
                                    <v-card color="transparent" class="primary--text">
                                      <v-layout align-center justify-space-around fill-height wrap>
                                        <v-flex xs10 sm5 v-if="clienteEvPicked.imgAfter">>
                                          <v-img
                                            :src="clienteEvPicked.imgAfter"
                                            height="125px"
                                            contain
                                          ></v-img>
                                        </v-flex>
                                        <v-spacer></v-spacer>
                                        <v-flex xs10 sm7>
                                          <v-card-title primary-title class="py-1 px-1">
                                              <span><strong>Estatus y Conclusiones</strong></span>                                              
                                          </v-card-title>
                                          <v-card-title primary-title class="py-1 px-1">
                                              {{ clienteEvPicked.activEnd }}                                          
                                          </v-card-title>
                                        </v-flex>
                                      </v-layout>
                                    </v-card>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-dialog>
                          <!-- </v-layout>
                        </template> -->
                        <v-icon
                          small
                          @click="editItemEv(props.item)"
                          class="mb-2"
                        >
                          mdi-pencil
                        </v-icon>
                        <estasSeguro v-if="colaboradorBorrar == true">
                            <v-icon
                                color="grey darken-2"
                                slot="nombrebtn"
                                small                                       
                                >
                                mdi-delete
                            </v-icon>
                            <div slot="elemento">
                                <span>este Elemento?</span>
                            </div>
                            <div slot="btnaceptar">
                                <v-btn flat @click="deleteItemEv(props.item)">Ok</v-btn>
                            </div>
                        </estasSeguro>
                        <v-icon
                            disabled
                            small
                            v-if="colaboradorBorrar == false"
                            class="ml-3 pl-3"
                        >
                            mdi-delete
                        </v-icon>
                      </td>
                    </template>
                    <template v-slot:no-data>
                      Por el momento no hay eventos o citas agendadas.
                    </template>
                  </v-data-table>
                </div>
              </template>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" flat="flat" @click="dialogEventos = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-icon small class="mt-3 mr-1" @click="editItem(props.item)">
              edit
          </v-icon>
          <estasSeguro v-if="colaboradorBorrar == true">
              <v-icon
                  color="grey darken-2"
                  slot="nombrebtn"
                  small
                  class="pt-2"
                  >
                  delete
              </v-icon>
              <div slot="elemento">
                  <span>este Elemento?</span>
              </div>
              <div slot="btnaceptar">
                  <v-btn flat @click="deleteItem(props.item)">Ok</v-btn>
              </div>
          </estasSeguro>
          <v-icon
              disabled
              small
              v-if="colaboradorBorrar == false"
              class="mt-3"
          >
              delete
          </v-icon>
        </td>            
      </template>
      <!-- <template v-slot:no-data>
          <v-btn color="primary" @click="">Reset</v-btn>
      </template> -->
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          No hay resultados existentes con la búsqueda "{{ search }}".
        </v-alert>
      </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
import estasSeguro from '@/components/estasSeguro.vue'
// import Calendario from '@/views/CalenDario.vue'
import { required, minLength, maxLength, } from 'vuelidate/lib/validators'
import { mapState, mapMutations, mapActions } from 'vuex'
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

  export default {
    components: { estasSeguro },
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      search: '',
      itemsDuration: [ 15, 30, 45, 60, 90, 120, 180, 240],
      trip: '',
      dialog: false,
      dialogExped: false,
      dialogViewCal: false,
      dialogHelp: false,
      headers: [
        {
          text: 'Nombre',
          align: 'left',
          value: 'cliente',
        },
        { text: 'Teléfono', value: 'telefono', sortable: false },
        { text: 'E-Mail', value: 'email', sortable: false },
        { text: 'Funciones', value: 'cliente', sortable: false }        
      ],
      clientesdb: [],
      colaboradores: [],
      colaborador: '',
      editedIndex: -1,
      editedItem: {
        cliente: '',
        telefono: '',
        email: ''
      },
      defaultItem: {
        cliente: '',
        telefono: '',
        email: ''
      },

      dialogEv: false,
      dialogEventos: false,
      clientePicked: {
        cliid: '',
      },
      clienteEvPicked: '',
      headersEv: [
        {
          text: 'Concepto',
          align: 'left',
          sortable: false,
          value: 'concepto'
        },
        { text: 'Fecha', align: 'center', value: 'fechaEvento' },
        { text: 'Funciones', value: 'cliente', sortable: false }    
      ],
      cliEventos: [],
      editedIndexEv: -1,
      editedItemEv: {
        concepto: '',
        fechaEvento: '',
        cliEvid: '',
        activInit: '',
        activEnd: '',
        time: '',
        duration: 60,
      },
      defaultItemEv: {
        concepto: '',
        fechaEvento: '',
        cliEvid: '',
        activInit: '',
        activEnd: ''
      },
    }),

    computed: {
      ...mapState('sesion', ['usuario']),
      ...mapState('usuarioHost', ['usuarioHost']),
      ...mapState('usuarioHost', ['perfilAuth']),
      paletaColores() {
        if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
          return this.usuarioHost.designColors;
        } else {
          const colores = ['red', 'green', 'blue', 'purple', 'orange'];
          const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
          return {
            primero: colorAleatorio,
            segundo: colorAleatorio,
            tercero: colorAleatorio,
            cuarto: colorAleatorio
          };
        }
      },
      puedeCrearPaciente() {
          const perfilAuth = this.perfilAuth;

          if (perfilAuth) {
              return true;
          } else {
              const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "crearPaciente" && acceso.value);
              return Boolean(accesoAgregar);
          }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar' : 'Editar'
      },
      formTitleEv () {
        return this.editedIndexEv === -1 ? 'Nuevo Evento/Cita' : 'Editar Evento/Cita'
      },
      colaboradorBorrar() {
        if(this.perfilAuth == true || this.colaborador.switchBorrar == true ){
            return true
        } else{
            return false
        }
      },
      erroresNombre() {
          let errores = []
          if (!this.$v.editedItem.cliente.required) { errores.push('Ingresa el cliente.') }
          if (!this.$v.editedItem.cliente.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
          if (!this.$v.editedItem.cliente.maxLength) { errores.push('Ingresa máximo 66 caracteres.') }
          return errores
      },
      erroresConcepto() {
          let errores = []
          if (!this.$v.editedItemEv.concepto.required) { errores.push('Ingresa el concepto.') }
          if (!this.$v.editedItemEv.concepto.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
          if (!this.$v.editedItemEv.concepto.maxLength) { errores.push('Ingresa máximo 99 caracteres.') }
          return errores
      },
      erroresFecha() {
          let errores = []
          if (!this.$v.editedItemEv.fechaEvento.required) { errores.push('Ingresa la Fecha.') }
          return errores
      },
      erroresActivInit() {
          let errores = []
          if (!this.$v.editedItemEv.activInit.required) { errores.push('Ingresa una descripción de la actividad.') }
          if (!this.$v.editedItemEv.activInit.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
          if (!this.$v.editedItemEv.activInit.maxLength) { errores.push('Ingresa máximo 333 caracteres.') }
          return errores
      },
      erroresActivEnd() {
          let errores = []
          if (!this.$v.editedItemEv.activEnd.maxLength) { errores.push('Ingresa máximo 333 caracteres.') }
          return errores
      },
    },

    validations: {
      editedItem: {
        cliente: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(33)
        },
      },
      editedItemEv: {
        fechaEvento: {
          required,
        },
        concepto: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(99)
        },
        activInit: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(333)
        },
        activEnd: {
          maxLength: maxLength(333)
        }
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogEv (val) {
        val || this.closeEv()
      }
    },

    created () {
      this.consultarWhereFrom()
      this.actualizarPerfilAuth()      
    },

    methods: {
      ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
      ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
      async movingSameHost() {
        await dbL.collection('fromSameHost').set([])
        await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName })
        this.$router.go(-1)
      },
      async openCalDate(date) {
        this.$refs.menu.save(date)
        await dbL.collection('fechaPicked').set([{date: this.date}])
        this.$router.push({ name: 'calendario', params: { userName: this.usuarioHost.userName }})
      },
      async consultarClientes () {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        try {
            let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                let resultado = await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('clientes')
                                        .get()                
                
                resultado.docs.forEach(doc => {
                    
                    let clidb = doc.data()                   
                    this.clientesdb.push(clidb)    
                })
            }
        }
        catch (error) {                
            this.mostrarError('Hay un problema con el internet favor de intentar más tarde.')              
        }
      },
      async consultarWhereFrom() {
          await dbL.collection('fromSameHost').get().then(fP => {
              this.sameHost = fP[0].sameHost
              this.sameHostName = fP[0].userHost
          }).catch(() => {
              // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
              this.$router.push({ name: 'Entrada' })
          }).finally(() => {
              this.consultarColLB()
          });
      },
      async consultarColLB() {
          if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
              // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
              this.$router.push({ name: 'Entrada' })
          } else if (this.sameHost == true) {
          
              this.colaboradores = []
              this.colaborador = ''
              if (this.perfilAuth == true) {
                  this.colaborador = this.usuarioHost
                  //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
              } else if (this.perfilAuth == false) {
              
                  await dbL.collection('colaboradores').get().then(cols => {
                  this.colaboradores = cols
                  })

                  if (this.colaboradores.length > 0) {
                      let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                      if (typeof colaborador === 'object') {
                          let convertINarray = Object.values(colaborador.estatusAccesos)
                          delete colaborador.estatusAccesos
                          colaborador.estatusAccesos = convertINarray
                          this.colaborador = colaborador  
                      } else {
                          this.colaborador = this.usuarioHost
                          //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                      } 
                  } else {
                      // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                      this.$router.push({ name: 'Entrada' })  
                  }
              } else {
                  // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                  this.$router.push({ name: 'Entrada' })
              }
          }
          // await dbL.collection('fromSameHost').delete()
          // this.sameHost = false
          // this.sameHostName = ''
          this.consultarClientes()
      },
      editItem (item) {
        this.editedIndex = this.clientesdb.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        const index = this.clientesdb.indexOf(item)
        this.clientesdb.splice(index, 1)
    
        try {
            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                await db.collection('usuarios')
                        .doc(userName.uid)
                        .collection('clientes')
                        .doc(item.cliid)
                        .delete()                

                this.mostrarExito('Se eliminó el elemento exitosamente.')
            }
        }
        finally {
            this.ocultarOcupado()
        }
        this.close()
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      async save () {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

        if(userNameDoc.exists) {
            var userName = userNameDoc.data()

            if (this.editedIndex > -1) {
                this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                Object.assign(this.clientesdb[this.editedIndex], this.editedItem)

                try {
                    db.collection('usuarios')
                    .doc(userName.uid)
                    .collection('clientes')
                    .doc(this.clientesdb[this.editedIndex].cliid)
                    .update({
                        cliente: this.editedItem.cliente,
                        telefono: this.editedItem.telefono,
                        email: this.editedItem.email,
                    })

                    this.close()
                    this.dialog = false
                    this.mostrarExito('Se actualizó la información exitosamente.')                
                }   
                finally {
                    this.ocultarOcupado()
                }

            } else {

                let cliid = uuidv4()

                let clienteObj = {
                    cliente: this.editedItem.cliente,
                    telefono: this.editedItem.telefono,
                    email: this.editedItem.email,
                    cliid,
                    fechaRegistro: new Date(),
                    autor: this.usuario.userName,
                }

                try {
                    await db.collection('usuarios') 
                            .doc(userName.uid)
                            .collection('clientes')
                            .doc(cliid)
                            .set(clienteObj)

                    this.clientesdb.push(clienteObj)
                    this.mostrarExito('Se cargó la información exitosamente.')
                }
                catch (error) {
                    this.mostrarError('Se guardó la iformación sólo Localmente. Estás en modalidad SinRED')
                }
                finally {
                    
                    this.ocultarOcupado()
                }
                this.close()
            }

        } else {
            this.mostrarError('Hay un problema con la red, favor de intentar más tarde.')
        }
      },

      async consulCliEventos () {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        try {
            let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                let resultado = await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('clientes')
                                        .doc(this.clientePicked.cliid)
                                        .collection('eventos-citas')
                                        .get()                
                
                resultado.docs.forEach(doc => {
                    
                    let clidb = doc.data()                   
                    this.cliEventos.push(clidb)    
                })
            }
        }
        catch (error) {                
            this.mostrarError('Hay un problema con el internet, favor de intentar más tarde.')              
        }
      },
      async abrirCliente(clientePicked) {
        this.cliEventos = []
        this.clientePicked = clientePicked
        this.consulCliEventos()
      },
      async abrirClienteEv(clienteEvPicked) {
        // this.cliEventos = []
        this.clienteEvPicked = clienteEvPicked
        // this.consulCliEventos()
      },
      editItemEv (itemEv) {
        this.editedIndexEv = this.cliEventos.indexOf(itemEv)
        this.editedItemEv = Object.assign({}, itemEv)
        this.dialogEv = true
      },
      viewItemEv (itemEv) {
        this.editedIndexEv = this.cliEventos.indexOf(itemEv)
        this.editedItemEv = Object.assign({}, itemEv)
        this.dialogExped = true
      },
      async deleteItemEv (itemEv) {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        const index = this.cliEventos.indexOf(itemEv)
        this.cliEventos.splice(index, 1)
    
        try {
            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                await db.collection('usuarios')
                        .doc(userName.uid)
                        .collection('clientes')
                        .doc(itemEv.cliid)
                        .collection('eventos-citas')
                        .doc(itemEv.cliEvid)
                        .delete()                

                this.mostrarExito('Se eliminó el elemento exitosamente.')
            }
        }
        finally {
            this.ocultarOcupado()
        }
        this.closeEv()
      },
      closeEv () {
        this.dialogEv = false
        setTimeout(() => {
          this.editedItemEv = Object.assign({}, this.defaultItemEv)
          this.editedIndexEv = -1
        }, 300)
      },
      async saveEv () {
        let userNameParametro = this.$route.params.userName.toLowerCase()

        let userNameDoc = await db.collection('userNames')
                                .doc(userNameParametro)
                                .get()

        if(userNameDoc.exists) {
            var userName = userNameDoc.data()

            if (this.editedIndexEv > -1) {
                this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                Object.assign(this.cliEventos[this.editedIndexEv], this.editedItemEv)

                try {
                    db.collection('usuarios')
                    .doc(userName.uid)
                    .collection('clientes')
                    .doc(this.clientePicked.cliid)
                    .collection('eventos-citas')
                    .doc(this.editedItemEv.cliEvid)
                    .update({
                        concepto: this.editedItemEv.concepto,
                        fechaEvento: this.editedItemEv.fechaEvento,
                        duration: this.editedItemEv.duration,
                        time: this.editedItemEv.fechaEvento.substr(-5,5),
                        activInit: this.editedItemEv.activInit,
                        activEnd: this.editedItemEv.activEnd,
                    })
                    this.mostrarExito('Se actualizó la información exitosamente.')                
                }   
                finally {
                    this.ocultarOcupado()
                }

            } else {

              let cliEvid = uuidv4()

              let clienteEvObj = {
                  cliEvid,
                  cliente: this.clientePicked.cliente,
                  cliid: this.clientePicked.cliid,                  
                  fechaEvento: this.editedItemEv.fechaEvento,
                  duration: this.editedItemEv.duration,
                  time: this.editedItemEv.fechaEvento.substr(-5,5),
                  activInit: this.editedItemEv.activInit,
                  activEnd: this.editedItemEv.activEnd,
                  concepto: this.editedItemEv.concepto,
                  autor: this.usuario.userName,                  
                  userNameHost: this.usuarioHost.userName,
                  open: false,
                  imgBefore: '',
                  imgAfter: ''
              }

              try {
                  await db.collection('usuarios') 
                          .doc(userName.uid)
                          .collection('clientes')
                          .doc(this.clientePicked.cliid)
                          .collection('eventos-citas')
                          .doc(clienteEvObj.cliEvid)
                          .set(clienteEvObj)

                  this.cliEventos.push(this.editedItemEv)
                  this.mostrarExito('Se cargó la información exitosamente.')
              }
              finally {                    
                  this.ocultarOcupado()
              }
            }
            this.closeEv()
        } else {
            this.mostrarError('Hay un problema con la red, favor de intentar más tarde.')
        }
      },
      async idsExpedsBefore() {
        await dbL.collection('idsExpeds').set([this.cliEventos[0]])
        this.$router.push({ name: 'edit-img-expediente', params: {userName: this.usuarioHost.userName, cronoMoment: 'imgBefore'}})
      },
      async idsExpedsAfter() {
        await dbL.collection('idsExpeds').set([this.cliEventos[0]])
        this.$router.push({ name: 'edit-img-expediente', params: {userName: this.usuarioHost.userName, cronoMoment: 'imgAfter'}})
      },
    }
  }
</script>