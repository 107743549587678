<!-- src/views/SendMessage.vue -->
<template>
    <div>
      <h1>Enviar mensaje de WhatsApp</h1>
      <form @submit.prevent="sendMessage">
        <div>
          <label for="phoneNumber">Número de WhatsApp:</label>
          <input type="text" v-model="phoneNumber" id="phoneNumber" required />
        </div>
        <div>
          <label for="messageBody">Mensaje:</label>
          <textarea v-model="messageBody" id="messageBody" required></textarea>
        </div>
        <button type="submit">Enviar Mensaje</button>
      </form>
      <p v-if="responseMessage">{{ responseMessage }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        phoneNumber: '525528602380',
        messageBody: 'Bienvenido a taxiwats',
        responseMessage: '',
      };
    },
    methods: {
      async sendMessage() {
        const url = 'https://graph.facebook.com/v19.0/362152626980784/messages ';
        const token = 'EAByFQt46hl0BO55PjHkLQyb3OZCGxWh7yllahNRadssnGSJiOqMWAZBVrKrVxR13j80e2NZBMmmvOCa2NbTYX1ZAQUfBUDZC6qeZBdq4oD1Rqliat4l7dpo9ZCZCNZCcGZCVOzMxCjXtloAGrYshHaxZBnF1kyyaVZAJpoTuMugLZCTUeiKvCZAFyeMDZBkhr7NO6uQ';
  
        const data = {
            messaging_product: 'whatsapp',
            recipient_type: 'individual',
            to: this.phoneNumber, // Asegúrate de que el número esté en formato internacional
            type: 'interactive',
            interactive: {
            type: 'button',
            body: {
                text: this.messageBody,
            },
            action: {
                buttons: [
                {
                    type: 'reply',
                    reply: {
                    id: 'register_taxi',
                    title: 'Registrar Taxi',
                    },
                },
                {
                    type: 'reply',
                    reply: {
                    id: 'request_taxi',
                    title: 'Pedir Taxi',
                    },
                },
                ],
            },
            },
        };
  
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
  
        try {
            const response = await axios.post(url, data, { headers });
            console.log('Response from WhatsApp API:', response.data);
            this.responseMessage = 'Mensaje de bienvenida enviado correctamente.';

            // Escuchar la respuesta del usuario (simulación para el propósito de este ejemplo)
            this.listenForResponse();
        } catch (error) {
            console.error('Error sending message:', error.response ? error.response.data : error.message);
            this.responseMessage = 'Error al enviar el mensaje.';
        }
      },
      async listenForResponse() {
        // Simulación de la respuesta del usuario para "Pedir Taxi"
        setTimeout(async () => {
            const userResponse = 'request_taxi'; // Simula que el usuario ha seleccionado "Pedir Taxi"

            if (userResponse === 'request_taxi') {
            await this.sendFollowUpMessage('Escribe la dirección donde quieres que llegue el taxi.');
            }
        }, 5000); // Simula una espera de 5 segundos para la respuesta del usuario
        },
        async sendFollowUpMessage(messageText) {
            const url = 'https://graph.facebook.com/v19.0/362152626980784/messages ';
            const token = 'EAByFQt46hl0BO55PjHkLQyb3OZCGxWh7yllahNRadssnGSJiOqMWAZBVrKrVxR13j80e2NZBMmmvOCa2NbTYX1ZAQUfBUDZC6qeZBdq4oD1Rqliat4l7dpo9ZCZCNZCcGZCVOzMxCjXtloAGrYshHaxZBnF1kyyaVZAJpoTuMugLZCTUeiKvCZAFyeMDZBkhr7NO6uQ';

            const data = {
                messaging_product: 'whatsapp',
                recipient_type: 'individual',
                to: this.phoneNumber, // Asegúrate de que el número esté en formato internacional
                type: 'text',
                text: {
                body: messageText,
                },
            };

            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };

            try {
                const response = await axios.post(url, data, { headers });
                console.log('Follow-up message sent:', response.data);
                this.responseMessage = 'Mensaje de seguimiento enviado correctamente.';
            } catch (error) {
                console.error('Error sending follow-up message:', error.response ? error.response.data : error.message);
                this.responseMessage = 'Error al enviar el mensaje de seguimiento.';
            }
        },
    },
  };
  </script>
  
  <style scoped>
  /* Agrega tus estilos aquí */
  </style>
  