
let creandoLetras = () => import( '../views/misLetras/creandoLetras.vue')
let lecturaDeLetras = () => import( '../views/misLetras/lecturaDeLetras.vue')
let letrasDocumento = () => import( '../views/misLetras/letrasDocumento.vue')

export default [
    
  {
    path: '/:userName/creandoLetras',
    name: 'creandoLetras',
    component: creandoLetras,
    meta: {
      autenticado: true
    }
  },
  {
    path: '/:userName/persona-especial/lectura-de-letras',
    name: 'misLetras',
    component: lecturaDeLetras,
    meta: {
      autenticado: true
    }
  },
  {
    path: '/:userName/letras-documento/:nombreDocLetras', //todavia no se utiliza para nada
    name: 'letrasDocumento',
    component: letrasDocumento,
    meta: {
      autenticado: true
    }
  },

]