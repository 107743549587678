<template>
  <v-layout justify-center align-center class="mt-5 pt-5">
    <v-flex xs12 sm4>
      <v-card flat color="transparent">
        <v-toolbar color="transparent" card>
          <v-flex class="text-xs-center headline mt-3">
            <p class="font-weight-bold">Bienvenido de nuevo :)</p>
          </v-flex>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Email"
            autofocus outline
            v-model="formulario.email"
            :error-messages="erroresEmail"
            @blur="$v.formulario.email.$touch()"
          ></v-text-field>
          <v-text-field
            label="Password"
            @keyup.enter="ingresar"
            type="password" outline
            v-model="formulario.password"
            :error-messages="erroresPasword"
            @blur="$v.formulario.password.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-layout justify-center>
            <v-btn
              @click="ingresar" class="caption text-none"
              :depressed="$v.formulario.$invalid"
              :disabled="$v.formulario.$invalid"
              color="principal white--text" block 
            >Ingresar</v-btn>
          </v-layout>
        </v-card-text>        
        <v-flex class="text-xs-center">
          <v-btn @click="solicitudPassword = true" flat color="principal white--text caption" class="text-none">¿Olvidaste o Quieres crear tu contraseña?</v-btn>
        </v-flex>
        <v-flex class="text-xs-center">
          <span class="font-weight-bold">¿Todavía no tienes cuenta?</span>
          <v-btn :to="{ name: 'HomeSistema' }" flat color="principal white--text" class="caption text-none">
            Regístrate
          </v-btn>
        </v-flex>
      </v-card>
      <v-dialog v-model="solicitudPassword" max-width="400" persistent>
        <v-card>
          <v-toolbar color="transparent" card>
            <v-toolbar-title>Reestablecer contraseña</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="subheading">Ingresa la dirección de email con la cual te registraste.</v-card-text>
          <v-card-text>
            <v-text-field
              label="Email"
              autofocus
              v-model="emailEnvio"
              :error-messages="erroresEmailEnvio"
              @blur="$v.emailEnvio.$touch()"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-layout>
              <v-flex xs6>
                <v-layout justify-start>
                  <v-btn @click="solicitudPassword = false">Cancelar</v-btn>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout justify-end>
                  <v-btn
                    color="principal white--text"
                    @click="enviarSolicitudPassword"
                    :depressed="$v.emailEnvio.$invalid"
                    :disabled="$v.emailEnvio.$invalid"
                  >Enviar</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import { mapMutations, mapGetters, mapState } from "vuex";
import { auth, signInWithEmailAndPassword, sendPasswordResetEmail } from "@/firebase";
//import { setTimeout } from "timers";

export default {
  name: 'LoGin',
  data() {
    return {
      formulario: {
        email: "",
        password: ""
      },
      error: null,
      // usuario: null,
      solicitudPassword: false,
      emailEnvio: ""
    };
  },
  validations: {
    formulario: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20)
      }
    },
    emailEnvio: {
      required,
      email
    }
  },
  methods: {
    ...mapMutations([ "mostrarOcupado", "ocultarOcupado", "mostrarExito", "mostrarAdvertencia", "mostrarError" ]),
    ...mapMutations('sesion', ['actualizarUsuario']),
    async ingresar() {
      if (this.$v.formulario.$invalid) {
        this.$v.formulario.$touch()
        return
      }

      let ocupado = { titulo: "Validando datos", mensaje: "Estamos validando tu información..."};
      this.mostrarOcupado(ocupado)
      this.error = null
      try {
        // Asegúrate de que 'auth' está bien definido y la configuración de Firebase es correcta
        if (!auth) {
          console.error('Firebase auth no está inicializado correctamente.')
          return
        }

        // Intentar iniciar sesión con correo y contraseña
        const userCredential = await signInWithEmailAndPassword(auth, this.formulario.email, this.formulario.password);
        const user = userCredential.user;
        this.error = null;

        this.actualizarUsuario(user);
        // console.log('Usuario actualizado:', this.usuario);

        // Redirigir al usuario
        setTimeout(() => {
          if (this.usuario && this.usuario.userName) {
            console.log('Redirigiendo a la ruta Entrada con userName:', this.usuario.userName);
            this.$router.replace({ name: 'Entrada', params: { userName: this.usuario.userName } });
          }
        }, 5000);

        
      } catch (error) {
        this.ocultarOcupado()
        console.error('Error al iniciar sesión:', error)

        switch (error.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            this.mostrarAdvertencia("Usuario no válido. Revisa tu correo y contraseña")
            break

          default:
            this.error = 'Ocurrió un error validando la información'
            break
        }
      } finally {
        this.ocultarOcupado()
      }
    },

    async enviarSolicitudPassword() {
      this.solicitudPassword = false;
      this.mostrarOcupado(
        "Se ha enviado la solicitud de reestablecimiento de contraseña"
      )

      try {
        // await auth.sendPasswordResetEmail(this.emailEnvio);

        sendPasswordResetEmail(auth, this.emailEnvio)
        .then(() => {
          // Notificación de que se ha enviado el email
          console.log('Se ha enviado el email para restablecer la contraseña.');
        })
        .catch((error) => {
          // Manejo del error
          console.error('Error al enviar el email para restablecer la contraseña:', error);
        });
    
        this.mostrarExito(
          "Se ha enviado la solicitud de reestablecimiento de contraseña."
        )
      } catch (error) {
        console.log(error)
        // this.mostrarError("Ocurrió un error enviando la solicitud");
      } finally {
        this.ocultarOcupado()
      }
    }
  },
  computed: {
    ...mapGetters('sesion', ['saludo']),
    ...mapState('sesion', ['usuario']),
    erroresEmail() {
      let errores = []
      if (!this.$v.formulario.email.$dirty) {
        return errores
      }
      if (!this.$v.formulario.email.required) {
        errores.push("Ingresa tu email.")
      }
      if (!this.$v.formulario.email.email) {
        errores.push("Ingresa un email válido.")
      }
      return errores
    },
    erroresPasword() {
      let errores = [];
      if (!this.$v.formulario.password.$dirty) {
        return errores
      }
      if (!this.$v.formulario.password.required) {
        errores.push("Ingresa tu email.")
      }
      if (!this.$v.formulario.password.minLength) {
        errores.push("Ingresa al menos 6 caracteres.")
      }
      if (!this.$v.formulario.password.maxLength) {
        errores.push("Ingresa máximo 20 caracteres.")
      }
      return errores
    },
    erroresEmailEnvio() {
      let errores = [];
      if (!this.$v.emailEnvio.email.required) {
        errores.push("Ingresa tu email.")
      }
      if (!this.$v.emailEnvio.email.$dirty) {
        return errores
      }
      if (!this.$v.emailEnvio.email.email) {
        errores.push("Ingresa un email válido.")
      }
      return errores
    }
  }
}
</script>
