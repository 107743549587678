<template>
    <v-layout align-center justify-center class="mt-4 pt-5">
        <v-flex xs12 sm8 md6 lg5 xl4>
            <v-card>
                <v-toolbar color="white" class="black--text" card>
                    <v-toolbar-title>
                        Confirma tus datos:
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <span class="subheading">
                        Te enviamos una liga de verificación al email que registraste, sólo abre el email, 
                        da click en la liga. No olvides revisar la carpeta de SPAM.
                    </span>
                </v-card-text>
                <v-card-text>
                    <span class="subheading">
                        ¿No has recibido el eMail?, no olvides revisar la carpeta de Spam. aquí puedes solicitar el reenvío.
                    </span>
                </v-card-text>
                <v-card-text>
                    <v-btn color="white" class="black--text" @click="reenviarEmail">Reenviar Email</v-btn>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { auth } from '@/firebase'
import { mapMutations, mapActions } from 'vuex'

export default {
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarError']),
        ...mapActions('sesion', ['cerrarSesion']),
        async reenviarEmail() {
            try {
                await auth.currentUser.sendEmailVerification()
                this.mostrarExito('Te hemos enviado un nuevo correo de verificacion.')
                this.$router.push({ name: 'home'})
            }
            catch(error) {
                this.mostrarError('Ocurrió un error enviando el mail de verificación. Intentelo nuevamente.')
            }
        },
        async despAfterVerif() {
            this.cerrarSesion()
            this.$router.push({ name: 'login' })        
        }
    }
}
</script>
