<!-- este compoente es sustituido oir edicionMenu de alybe -->
<template>
    <v-layout justify-center align-center wrap>
        <v-flex xs12 md6>
            <v-card class="elevation=5 ma-3">
                <v-flex xs12 class="text-xs-right">
                    <v-dialog
                        v-model="dialogHelp"
                        width="500"
                    >
                        <template v-slot:activator="{ on }">
                        <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                        </template>

                        <v-card>
                        <v-card-title class="headline">
                            Cómo funciona esta sección...
                        </v-card-title>

                        <v-card-text>
                            En esta sección se agregan los productos que ofrecerás al público, organizados por Grupos de productos. Una vez que sean regitrados, apaecerán en
                            todos los apartados que requieran lista de Productos, por ejemplo: área pública, área de Comandas o aquellos que 
                            vayas solicitando a nuVin.<br>
                            <ul>
                                <li>Al dar click en AGREGAR se despliega un formulario para Asignar un Grupo, que se reflejará en la tabla inferior, con las 
                                    opciones de editar (icono-lápiz), borrar (icono-basurero) y agregar (icono-libro).
                                </li>
                                <li>
                                    En el botón agregar, se despliega un formulario para agregar los datos de cada producto, que serán reflejados en la tabla
                                    de la parte inferior con sus opciones de editar y borrar.
                                </li>
                            </ul> 
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-flex>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Agrega un<br>GRUPO</v-toolbar-title>
                        <v-divider
                            class="mx-2"
                            inset
                            vertical
                            ></v-divider>
                        <v-spacer></v-spacer>
                                    <!-- área para agregar Grupos       -->
                        <v-dialog v-model="dialogAddG" max-width="500px">
                            <template v-slot:activator="{ on }">
                            <v-btn :style="{'background-color': paletaColores.primero}" dark class="mb-2 text-none" v-on="on" small @click="emptyFieldsG">agregar</v-btn><br>
                            <!-- <v-btn outline class="hidden-sm-and-up" small fab dark color="primary" @click="dialogAddGyP = false">
                                <v-icon dark>close</v-icon>   
                            </v-btn> -->
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ formTitleG }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container grid-list-md>
                                    <v-layout wrap>
                                        <v-flex xs12 md6>
                                            <v-text-field v-model="menugpodb.grupo" label="Grupo, ej. entrada, ensaladas, pastas" counter="30" autofocus :error-messages="erroresGrupo"></v-text-field>
                                            <v-radio-group v-model="menugpodb.elaboracion" :mandatory="false">
                                                Este grupo se elabora en:
                                                <v-radio color= "primary" label="Cocina" value="cocina"></v-radio>
                                                <v-radio color= "primary" label="Bar" value="bar"></v-radio>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>
                                    </v-container>
                                </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" flat @click="closeG">Cancelar</v-btn>
                                <v-btn color="primary" :depressed="$v.menugpodb.grupo.$invalid" :disabled="$v.menugpodb.grupo.$invalid" flat @click="registrarGrupo">Guardar</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                </v-toolbar>
                <v-data-table
                    :headers="headersG"
                    :items="menuGpoDB"
                    class="elevation-1"
                    >
                    <template v-slot:items="props">
                        <td>{{ props.item.grupo }}</td>
                        <td class="justify-center align-center layout">
                                <!-- área para agregar productos dentro de grupos icono book    -->
                            <v-dialog v-model="dialogAddPs" persistent max-width="600px">                            
                                <template v-slot:activator="{ on }">                                
                                    <v-icon
                                        @click="abrirGrupo(props.item)"
                                        dark v-on="on"
                                        color="primary"
                                        class="mr-2"
                                        small
                                        >
                                        book
                                    </v-icon>
                                </template>
                                <v-card  
                                    v-if="menugpodb"
                                    :key= "menugpodb.gid"
                                    :menuProductosDB="menugpodb.menuProductosDB"
                                    >
                                    <v-toolbar flat color="white">
                                        <h3 class="title"> {{menugpodb.grupo}} </h3>                                        
                                        <v-divider
                                            class="mx-2"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-dialog v-model="dialogAddP" max-width="500px" persistent>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-if="QProd < prodsNumPermiso" class="hidden-xs-only" mb-2 :style="{'background-color': paletaColores.primero}" dark v-on="on" @click="emptyFieldsP">Nuevo producto</v-btn>
                                                <v-btn v-else disabled class="hidden-xs-only caption" mb-2 >Nuevo producto</v-btn>
                                                <div v-if="QProd < prodsNumPermiso">
                                                    <v-btn class="hidden-sm-and-up caption" small :style="{'background-color': paletaColores.primero}" dark v-on="on" @click="emptyFieldsP">agregar</v-btn>
                                                </div>
                                                <div v-else>
                                                    <v-btn disabled class="hidden-sm-and-up caption" small icon>agregar</v-btn>
                                                    <v-tooltip v-model="show" top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon v-on="on">
                                                            <v-icon>mdi-face-agent</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span class="caption">Has superado la cantidad de Productos permitido por el plan, solicita un paquete adicional.</span>
                                                    </v-tooltip> 
                                                </div>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <span class="headline">{{ formTitleP }}</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container grid-list-md>
                                                        <v-layout wrap>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="menuproductosdb.producto" label="Nombre del producto" counter="30" autofocus @blur="$v.menuproductosdb.producto.$touch()" :error-messages="erroresNombre"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="menuproductosdb.unidad" label="Unidad" counter="10" autofocus @blur="$v.menuproductosdb.unidad.$touch()" :error-messages="erroresUnidad"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model.number="menuproductosdb.precio" type="number" label="Precio" counter="10" autofocus @blur="$v.menuproductosdb.precio.$touch()" :error-messages="erroresPrecio"></v-text-field>
                                                            </v-flex>
                                                            <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="menuproductosdb.descripcion" label="Breve descripción" counter="300" autofocus :error-messages="erroresDescripcion"></v-text-field>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn :style="{'background-color': paletaColores.primero}" dark flat @click="closeP">Cancelar</v-btn>
                                                    <v-btn :style="{'background-color': paletaColores.primero}" :depressed="$v.menuproductosdb.$invalid" :disabled="$v.menuproductosdb.$invalid" flat @click="guardarProducto">Guardar</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-btn icon small fab class="hidden-sm-and-up text-xs-center" :style="{'background-color': paletaColores.primero}" @click="dialogAddPs = false">
                                            <v-icon>close</v-icon>   
                                        </v-btn>
                                        <v-btn small flat outline class="hidden-xs-only" :style="{'background-color': paletaColores.primero}" @click="dialogAddPs = false">Cerrar</v-btn>
                                    </v-toolbar>
                                    <v-data-table
                                        :headers="headersP"
                                        :items="menuProductosDB"
                                        class="elevation-1"
                                        >
                                        <template v-slot:items="props">
                                            <td>{{ props.item.producto }}</td>
                                            <td class="text-xs-right">{{ props.item.unidad }}</td>
                                            <td class="text-xs-right">{{ props.item.precio }}</td>
                                            <td class="text-xs-right">{{ props.item.descripcion }}</td>
                                            <td class="justify-center align-center layout">
                                                <v-layout row >
                                                    <v-flex>
                                                        <v-icon
                                                        small
                                                        class="mt-2 pt-1 mr-0 pr-0"
                                                        @click="editItemP(props.item)"
                                                        >
                                                            edit
                                                        </v-icon>
                                                    </v-flex>
                                                    <v-flex>
                                                        <estasSeguro v-if="perfilAuth == true || colaboradorBorrar == true" small>
                                                            <v-icon
                                                                color="grey darken-2"
                                                                slot="nombrebtn"
                                                                small                                   
                                                                >
                                                                delete
                                                            </v-icon>
                                                            <div slot="elemento">
                                                                <span>este Producto?</span>
                                                            </div>
                                                            <div slot="btnaceptar">
                                                                <v-btn flat @click="deleteItemP(props.item)">Ok</v-btn>
                                                            </div>
                                                        </estasSeguro>
                                                        <v-icon
                                                            disabled
                                                            small
                                                            v-if="perfilAuth == false && colaboradorBorrar == false"
                                                        > 
                                                            delete
                                                        </v-icon>
                                                    </v-flex>
                                                </v-layout>
                                            </td>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-alert :value="true" color="error" icon="warning">
                                                Todavía no hay productos, presiona el botón '+' para agregar.
                                            </v-alert>
                                        </template>
                                    </v-data-table>                     
                                </v-card>  
                            </v-dialog>
                            <v-layout row>
                                <v-flex>
                                    <v-icon
                                        class="mt-2 pt-1 pr-0 mr-0"
                                        @click="editItemG(props.item)" small
                                        >
                                        edit
                                    </v-icon>
                                </v-flex>
                                <v-flex class="ml-0 pl-0">
                                    <estasSeguro v-if="perfilAuth == true || colaboradorBorrar == true">
                                        <v-icon
                                            color="grey darken-2"
                                            slot="nombrebtn"
                                            small                            
                                            >
                                            delete
                                        </v-icon>
                                        <div slot="elemento">
                                            <span>este Grupo de Productos?</span>
                                        </div>
                                        <div slot="btnaceptar">
                                            <v-btn flat @click="deleteItemG(props.item)">Ok</v-btn>
                                        </div>
                                    </estasSeguro>
                                    <v-icon
                                        disabled
                                        small
                                        v-if="perfilAuth == false && colaboradorBorrar == false"
                                    >
                                        delete
                                    </v-icon>
                                </v-flex>
                            </v-layout>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning">
                            Todavía no hay grupos, presiona el botón '+' para agregar.
                        </v-alert>
                    </template>
                </v-data-table>       
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import estasSeguro from '@/components/estasSeguro.vue'
import { mapMutations, mapState, mapActions } from 'vuex'
import { db, auth } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    components: { estasSeguro },
    data() {
        return {
            show: false,
            colaboradorBorrar: false,
            // perfilCol: '',
            // colaborador: [],
            colaborador: '',
            colaboradores: [],
            dialogAddGyP: false,
            dialogAddG: false,
            dialogAddPs: false,
            dialogAddP: false,
            dialogHelp: false,
            QProdArray: [],
            QProd: '',
            nuvitGratis: 'price_1IWW9wGLlS3xAdXuxEwvjESu',
            nuvit1: 'price_1IUbLQGLlS3xAdXuPAvgzhp2',
            nuvit2: 'price_1IUbMVGLlS3xAdXupUDgRNhi',
            nuvit3: 'price_1IUbNCGLlS3xAdXubxbTjcfF',
            planPorPrecio: [],
            headersG: [
                { text: 'Grupo', value: 'grupo' },
                { text: 'Acciones', value: 'producto', sortable: false }
            ],
            headersP: [
                {
                text: 'Productos',
                align: 'left',
                value: 'producto'
                },
                // { text: 'Grupo', value: 'grupo' },
                { text: 'Unidad', value: 'unidad' },
                { text: 'Precio ($)', value: 'precio' },
                { text: 'Descripcion', value: 'descripcion', sortable: false },
                { text: 'Acciones', value: 'producto', sortable: false }
            ],
            menuGpoDB: [],
            menugpodb: {
                elaboracion: 'cocina',
                grupo: '',
                menuProductosDB: [{producto:''}],
                productos: [
                    {producto: '',}
                ],
            },
            menuProductosDB: [],
            menuproductosdb: {
                producto: '',
                precio: 0,
                unidad: '',
                descripcion: '',
                grupo: '',
            },
            // editedItem: {
            //     producto: '',
            //     precio: 0,
            //     descripcion: '',
            //     grupo: '',
            // },            
            editedIndexP: -1,
            editedIndexG: -1,
            defaultItemP: {
                producto: '',
                precio: 0,
                unidad: '',
                descripcion: '',
                grupo: '',
            },
            defaultItemG: {
                grupo: '',
                elaboracion: 'cocina',
            }
        }
    },

    validations: {
        menuproductosdb: {
            producto: {
                required,
                maxLength: maxLength(30),
                minLength: minLength(3)
            },
            unidad: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(2)
            },
            precio: {
                required,
                maxLength: maxLength(10),
                minLength: minLength(1)
            },
            descripcion: {
                maxLength: maxLength(300),
            },
        },
        menugpodb: {
            grupo: {
                required,
                maxLength: maxLength(30)
            }
        }
    },

    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['principal',];
                const colores2 = ['secondary',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        formTitleG () {
            return this.editedIndexG === -1 ? 'Grupo Nuevo' : 'Editar Grupo'
        },
        formTitleP () {
            return this.editedIndexP === -1 ? 'Producto Nuevo' : 'Editar producto'        
        },
        prodsNumPermiso () {
            return this.usuarioHost.permisos.productos
        }, 
        erroresDescripcion() {
            let errores = []
            if (!this.$v.menuproductosdb.descripcion.$dirty) { return errores }        
            if (!this.$v.menuproductosdb.descripcion.maxLength) { errores.push('Ingresa máximo 300 caracteres.') }
            return errores
        },
        erroresNombre() {
            let errores = []
            if (!this.$v.menuproductosdb.producto.$dirty) { return errores }  
            if (!this.$v.menuproductosdb.producto.required) { errores.push('Ingresa el nombre del producto.') }      
            if (!this.$v.menuproductosdb.producto.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
            return errores
        },
        erroresUnidad() {
            let errores = []
            if (!this.$v.menuproductosdb.unidad.$dirty) { return errores }  
            if (!this.$v.menuproductosdb.unidad.required) { errores.push('Ingresa el unidad del producto.') }      
            if (!this.$v.menuproductosdb.unidad.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
            return errores
        },
        erroresPrecio() {
            let errores = []
            if (!this.$v.menuproductosdb.precio.$dirty) { return errores }  
            if (!this.$v.menuproductosdb.precio.required) { errores.push('Ingresa el precio del producto.') }      
            if (!this.$v.menuproductosdb.precio.maxLength) { errores.push('Ingresa máximo 10 caracteres.') }
            return errores
        },
        erroresGrupo() {
            let errores = []
            if (!this.$v.menugpodb.grupo.$dirty) { return errores }  
            if (!this.$v.menugpodb.grupo.required) { errores.push('Ingresa el nombre del grupo.') }      
            if (!this.$v.menugpodb.grupo.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
            return errores
        },
        erroresProducto() {
            let errores = []
            if (!this.$v.menuproductosdb.$dirty) { return errores }  
            if (!this.$v.menuproductosdb.required) { errores.push('Ingresa el nombre del producto.') }      
            if (!this.$v.menuproductosdb.maxLength) { errores.push('Ingresa máximo 30 caracteres.') }
            return errores
        },
    },
    watch: {
      dialogAddGyP (val) {
        val || this.closeG()
      },
      dialogAddG (val) {
        val || this.closeG()
      },
      dialogAddPs (val) {
        val || this.closeP()
      },
      dialogAddP (val) {
        val || this.closeP()
      },
    //   '$route' () {
    //     this.consultarColaboradores()  
    //   },
    },
    created () {
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        async movingSameHost() {
            await dbL.collection('fromSameHost').set([])
            await dbL.collection('fromSameHost').add({ sameHost: true, userHost: this.$route.params.userName })
            this.$router.go(-1)
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador 
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }  
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        } 
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            // await dbL.collection('fromSameHost').delete()
            // this.sameHost = false
            // this.sameHostName = ''
            this.consultarGruposDB()
        },
        ConsulQProd() {
            this.QProd = this.QProdArray.length
        },
        // async consultarPlan() {
        //     let userNameParametro = this.$route.params.userName.toLowerCase()

        //     try {
        //         let userNameDoc = await db.collection('userNames')
        //                                     .doc(userNameParametro)
        //                                     .get()

        //         if(userNameDoc.exists) {
        //             let userName = userNameDoc.data()
        //             let resultado = await db.collection('usuarios')
        //                                     .doc(userName.uid)
        //                                     .collection('subscriptions')
        //                                     .get()                
                    
        //             resultado.docs.forEach(doc => {
                        
        //                 let plansdb = doc.data()
        //                     this.planPorPrecio.push(plansdb)    
        //             })
        //         }                
        //     }
        //     catch (error) {                
        //         this.mostrarError('Ocurrió un error consultando los Planes-precios.')                
        //     }
        // },
        // perfilCol() {
        //     let userNameParametro = this.$route.params.userName.toLowerCase()

        //     let userNameDoc = db.collection('userNames')
        //                         .doc(userNameParametro)
        //                         .get()

        //         if(userNameDoc.exists) {
        //             let userName = userNameDoc.data()
        //             if(this.colaborador.some(col => col.uidAdmin === userName.uid )){
        //                 this.perfilCol = true
        //             } else{            
        //                 this.perfilCol = false        
        //             }
        //         }
        // },
        
        // async consultarColaboradores() {
        //     let userNameParametro = this.$route.params.userName.toLowerCase()

        //     this.mostrarOcupado({ titulo: 'Consultando Información', mensaje: 'Cargando datos...' })

        //     try {
        //         let userNameDoc = await db.collection('userNames')
        //                                         .doc(userNameParametro)
        //                                         .get()

        //         if(userNameDoc.exists) {
        //             let userName = userNameDoc.data()
        //             let colaboradorDoc = await db.collection('usuarios')
        //                                     .doc(userName.uid)
        //                                     .collection('colaboradores')
        //                                     .where("switchAgProd", '==', true)
        //                                     // .where("uidCol", '==', auth.currentUser.uid)
        //                                     .get()                

        //                 colaboradorDoc.docs.forEach(doc => {                    
        //                     let colab = doc.data()

        //                     this.colaborador.push(colab)

        //                 })

        //             if(this.colaborador == '' && userName.uid != auth.currentUser.uid) {

        //                 this.$router.push({ name: '404' })
                        
        //             }
        //         }
        //     }
        //     catch (error) {
        //         this.$router.push({ name: '404' })
        //     }
        //     finally {
        //         this.ocultarOcupado()
        //     }
        //     this.consulColaboradorBorrar()
        // },
        async consulColaboradorBorrar() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                if (userName.uid != auth.currentUser.uid) {
                
                    if(this.colaborador.switchBorrar == true){
                        this.colaboradorBorrar = true
                    } else{
                        this.colaboradorBorrar = false
                    }
                } else {
                    this.colaboradorBorrar = true
                }
            }
        },
        emptyFieldsG() {
            this.menugpodb = this.defaultItemG
            this.closeG()
        },
        emptyFieldsP() {
            this.menuproductosdb = this.defaultItemP
            this.closeP()
        },        
        editarGyP() {
            this.dialogAddGyP = true
        },
        // editarProductos() {
        //     this.dialogAddPs = true
        //     // this.dialogAddP = true
        // },        
        async registrarGrupo() {
            let userNameParametro = this.$route.params.userName.toLowerCase()

            let userNameDoc = await db.collection('userNames')
                                        .doc(userNameParametro)
                                        .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()

                if (this.$v.menugpodb.grupo.$invalid) { return }
                

                    let menugpodbExistente = await db.collection('usuarios')
                                                    .doc(userName.uid)
                                                    .collection('menuGpoDB')
                                                    .doc(this.menugpodb.grupo.toLowerCase())
                                                    .get()

                if(menugpodbExistente.exists) {
                    this.mostrarAdvertencia(`El Grupo '${this.menugpodb}', ya existe, elige uno diferente`)
                    return
                }

                try {
                    this.mostrarOcupado({ titulo: 'Creando Registro', mensaje: 'Estamos registrando tu información...' })

                        if (this.editedIndexG > -1) {

                            this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                            Object.assign(this.menuGpoDB[this.editedIndexG], this.menugpodb)

                            try {
                                await db.collection('usuarios')
                                        .doc(userName.uid)
                                        .collection('menuGpoDB')
                                        .doc(this.menugpodb.gid)
                                        .update({
                                                grupo: this.menugpodb.grupo,
                                                elaboracion: this.menugpodb.elaboracion,                 
                                        })
                                
                                this.closeG()
                                this.dialogAddG = false

                                this.mostrarExito('Se editó el Grupo exitosamente')
                            }              
                            catch (error) {
                                this.mostrarError('Ocurrió un error cargando el Grupo')
                            }
                            finally {
                                this.ocultarOcupado()
                            }
                        } else {

                            let batch = db.batch()
                            let gid = uuidv4()
                            
                            let menugpodb = {
                                gid,
                                grupo: this.menugpodb.grupo,
                                elaboracion: this.menugpodb.elaboracion,
                                uidG: userName.uid,
                                // menuProductosDB: [
                                //                 {
                                //                     pid: this.menuproductosdb.pid,
                                //                     producto: this.menuproductosdb.producto,
                                //                     precio: this.menuproductosdb.precio,
                                //                     descripcion: this.menuproductosdb.descripcion,
                                //                     grupo: this.menugpodb.grupo,
                                //                 }
                                //             ]                          
                            }

                            batch.set(db.collection('usuarios').doc(userName.uid)
                                        .collection('menuGpoDB').doc(menugpodb.gid), menugpodb)                
                            
                            try {
                                batch.commit()
                                this.menuGpoDB.push(this.menugpodb)
                                
                                this.mostrarExito('Se registró el Grupo exitosamente')
                            }
                            catch (error) {
                                this.mostrarError('Ocurrió un error cargando el Grupo')
                            }
                            finally {
                                this.ocultarOcupado()
                            }
                            this.closeG()
                            this.menuGpoDB = []
                            this.consultarGruposDB()
                        }

                    this.closeG()
                }
                catch (error) {
                    this.mostrarError('Ocurrió un error cargando la información')
                }
                finally {                
                    this.ocultarOcupado()
                }
                this.closeG()
            }
        },
        async guardarProducto() {
            let userNameParametro = this.$route.params.userName.toLowerCase()
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' })

            let userNameDoc = await db.collection('userNames')
                                    .doc(userNameParametro)
                                    .get()

            if(userNameDoc.exists) {
                let userName = userNameDoc.data()
                    
                if (this.editedIndexP > -1) {

                    this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' })
                    Object.assign(this.menuProductosDB[this.editedIndexP], this.menuproductosdb)

                    try {
                        await db.collection('usuarios')
                                .doc(userName.uid)
                                .collection('menuGpoDB')
                                .doc(this.menugpodb.gid)
                                .collection('menuProductosDB')
                                .doc(this.menuproductosdb.pid)
                                .update({
                                        producto: this.menuproductosdb.producto,
                                        unidad: this.menuproductosdb.unidad,
                                        precio: this.menuproductosdb.precio,
                                        descripcion: this.menuproductosdb.descripcion,                                 
                                })
                        
                        this.closeP()
                        this.dialogAddP = false

                        this.mostrarExito('Se cargó la información exitosamente')
                    }              
                    catch (error) {
                        this.mostrarError('Ocurrió un error cargando la información')
                    }
                    finally {
                        this.ocultarOcupado()
                    }

                } else {

                    let batch = db.batch()
                    let pid = uuidv4()
                    
                    let menuproductosdb= {
                        pid,
                        producto: this.menuproductosdb.producto,
                        unidad: this.menuproductosdb.unidad,
                        precio: this.menuproductosdb.precio,
                        descripcion: this.menuproductosdb.descripcion,
                        grupo: this.menugpodb.grupo,
                        elaboracion: this.menugpodb.elaboracion,
                        uidP: userName.uid,
                        gidP: this.menugpodb.gid, 
                        autor: this.usuario.userName,
                        userNameHost: userName.userName
                    }


                    batch.set(db.collection('usuarios').doc(userName.uid)
                                .collection('menuGpoDB').doc(this.menugpodb.gid)
                                .collection('menuProductosDB').doc(menuproductosdb.pid), menuproductosdb)    

                    try {
                        batch.commit()
                        this.menuProductosDB.push(menuproductosdb)
                        
                        
                        this.mostrarExito('Se cargó la información exitosamente')
                    }
                    catch (error) {
                        this.mostrarError('Ocurrió un error cargando la información')
                    }
                    finally {
                        this.ocultarOcupado()
                    }
                    this.closeP()
                }
                this.closeP()
            }
        },
        async consultarGruposDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()


            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('menuGpoDB')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let gpodb = doc.data()
                        this.menuGpoDB.push(gpodb)    
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Ocurrió un error consultando los Grupos.')                
            }     
        },
        async consultarProductosDB() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collection('usuarios')
                                            .doc(userName.uid)
                                            .collection('menuGpoDB')
                                            .doc(this.menugpodb.gid)
                                            .collection('menuProductosDB')
                                            .get()                
                    
                    resultado.docs.forEach(doc => {
                        
                        let proddb = doc.data()
                            if (proddb.gidP == this.menugpodb.gid) {                                
                                this.menuProductosDB.push(proddb)
                            }
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Todavía no hay productos registrados o la señal de WiFi está fallando. Inténtelo más tarde.')                
            }     
        },
        async consultarQproductos() {         
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    let resultado = await db.collectionGroup('menuProductosDB')
                                            .where('userNameHost', '==', userName.userName)
                                            .get()               
                    
                    resultado.docs.forEach(doc => {
                        
                        let qprod = doc.data()

                        this.QProdArray.push(qprod)
                        
                    })
                }
            }
            catch (error) {                
                this.mostrarError('Todavía no hay productos registrados o la señal de WiFi está fallando. Inténtelo más tarde.')                
            }
            this.ConsulQProd()  
        },
        editItemG (item) {
            this.editedIndexG = this.menuGpoDB.indexOf(item)
            this.menugpodb = Object.assign({}, item)
            this.dialogAddG = true
        }, 
        editItemP (item) {
            this.editedIndexP = this.menuProductosDB.indexOf(item)
            this.menuproductosdb = Object.assign({}, item)
            this.dialogAddP = true
        },
        async deleteItemG (item) {
            const index = this.menuGpoDB.indexOf(item)
            this.menuGpoDB.splice(index, 1)
            let userNameParametro = this.$route.params.userName.toLowerCase()

            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    this.menugpodb = Object.assign({}, item)

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('menuGpoDB')
                            .doc(this.menugpodb.gid)
                            .delete()                
                
                    this.mostrarExito('Se eliminó el grupo exitosamente')
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error borrando el grupo')                
            }
            finally {
                this.ocultarOcupado()
            }
            this.closeG()
        },
        async deleteItemP (item) {
            const index = this.menuProductosDB.indexOf(item)
            this.menuProductosDB.splice(index, 1)
            let userNameParametro = this.$route.params.userName.toLowerCase()
        
            try {
                let userNameDoc = await db.collection('userNames')
                                            .doc(userNameParametro)
                                            .get()

                if(userNameDoc.exists) {
                    let userName = userNameDoc.data()
                    this.menuproductosdb = Object.assign({}, item)

                    await db.collection('usuarios')
                            .doc(userName.uid)
                            .collection('menuGpoDB')
                            .doc(this.menugpodb.gid)
                            .collection('menuProductosDB')
                            .doc(this.menuproductosdb.pid)
                            .delete()                
                
                    this.mostrarExito('Se eliminó el producto exitosamente')
                }
            }
            catch (error) {
                this.mostrarError('Ocurrió un error borrando la información')                
            }
            finally {
                this.ocultarOcupado()
            }
            this.closeP()
        },
        closeG () {
            this.dialogAddG = false
            setTimeout(() => {
                this.menugpodb = Object.assign({}, this.defaultItemG)
                this.editedIndexG = -1
            }, 300)
        },
        closeP () {
            this.dialogAddP = false
            setTimeout(() => {
                this.menuproductosdb = Object.assign({}, this.defaultItemP)
                this.editedIndexP = -1
            }, 300)
        },
        async abrirGrupo(menugpodb) {    
            this.menuProductosDB = []
            this.menugpodb = menugpodb;
            await this.consultarProductosDB(); //con await?
            
        },
    }
    
}
</script>