<template>
  <!-- master en orden -->
  <!-- <v-layout align-center justify-space-around wrap class="grey lighten-4"> -->
  <v-layout justify-center align-center wrap>
    <v-flex xs12>
      <v-layout row justify-end>
        <!-- <v-btn v-if="perfilAuth" flat transparent class="caption text-capitalize" @click="estatusAreaPu">
            <v-switch v-model="usuarioHost.areaPublica"></v-switch>
            {{ usuarioHost.areaPublica ? 'Área Pública Cerrada' : 'Área Pública Abierta' }}
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          @click.stop="dialogTicket = true"
          small
          class="caption text-lowercase"
          v-if="usuarioHost.gratis != true && usuarioHost.btnEstatusSubscripcion == true"
        >
          subscripción nuVin
        </v-btn>
        <v-dialog
          v-model="dialogTicket"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Estatus de tu nuVin</v-card-title>
            <v-card-text>
              <ul v-for="(item, index) in extraAppsUser" :key="index">
                <li>Aplicación: <strong>{{ item.app }}</strong><br>
                    Estatus: <strong>{{ item.activa }}</strong><br>
                    Fecha inicio: <strong>{{ item.fecha }}</strong><br>
                    Plan: <strong>{{ item.nivelPlan }}</strong><br>
                </li>
              </ul>
            </v-card-text>
            
            <template v-if="usuarioHost.activa == 'Pausada'">
              <v-layout row justify-center>
                <v-dialog v-model="dialogPausada" persistent max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
                  </template>
                  <v-card>
                    <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
                    <v-card-text>Esta cuenta está Pausada, para reactivarla, comunícate con soporte de nuVin: nubesdebits@gmail.com</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" flat :to="{ name: 'Entrada', params: {userName: usuarioHost.userName} }">Salir</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-layout>
            </template>
            <!-- <v-card-text>
              <ul>
                <li v-if="usuarioHost.activa != 'Prueba'">Tipo de subscripción: <strong>{{ usuarioHost.nivelPlan }}</strong></li>
                <li>Giro: <strong>{{ usuarioHost.app }}</strong></li>
                <div v-if="usuarioHost.activa != 'Pausada'">
                  <li v-if="usuarioHost.nivelPlan != 'especial' && secondsExist == true">Fecha para renovación: <br> <strong>{{ new Date(usuarioHost.fechaSigPago.seconds * 1000).toISOString().substr(0, 10) }}</strong></li>
                </div>
                <li v-if="usuarioHost.nivelPlan != 'especial' && secondsPruebaExist == true">Fecha inicio de prueba: <br> <strong>{{ new Date(usuarioHost.fechaInitPrueba.seconds * 1000).toISOString().substr(0, 10) }}</strong>(vence después de 48 hrs.)</li>
                <li v-if="subscripciones.length > 0">Estatus de subscripción: <strong>{{ subscripciones[0].status }}</strong></li>
                <li v-else>Estatus de subscripción: <strong>{{ usuarioHost.activa }}</strong></li>
              </ul>
              <ul v-for="item2 in extraAppsUser" :key="item2.app">
                <li v-if="item2.activa == 'Activada' && item2.app == usuarioHost.app">Nivel de Plan: <strong>{{ item2.nivelPlan }}</strong></li>
                <li v-if="item2.activa == 'Activada' && item2.app == usuarioHost.app">Plazo: <strong>{{ item2.plazo }}</strong></li>                
                <li v-if="item2.activa == 'Activada' && item2.nivelPlan == 'semilla' && item2.plazo == 'mensual'">Cuota: <strong>${{ precioMensualSemilla }} MXN </strong></li>
                <li v-if="item2.activa == 'Activada' && item2.nivelPlan == 'semilla' && item2.plazo ==  'anual'">Cuota: <strong>${{ precioAnualSemilla }} MXN </strong></li>
                <li v-if="item2.activa == 'Activada' && item2.nivelPlan == 'emprendedor' && item2.plazo == 'mensual'">Cuota: <strong>${{ precioMensualEmprendedor }} MXN </strong></li>
                <li v-if="item2.activa == 'Activada' && item2.nivelPlan == 'emprendedor' && item2.plazo == 'anual'">Cuota: <strong>${{ precioAnualEmprendedor }} MXN </strong></li>
                <li v-if="item2.activa == 'Activada' && item2.nivelPlan == 'empresario' && item2.plazo == 'mensual'">Cuota: <strong>${{ precioMensualEmpresario }} MXN </strong></li>
                <li v-if="item2.activa == 'Activada' && item2.nivelPlan == 'empresario' && item2.plazo == 'anual'">Cuota: <strong>${{ precioAnualEmpresario }} MXN </strong></li>
              </ul><br> -->
              
              <!-- Está pendiente, esta parte de abajo, mostrar las apps extra activas en el btn de subs -->
              <!-- <div v-if="usuarioHost.activa != 'Pausada'">
                <strong class="title">nuVin apps extra activas:</strong>
                <div v-for="item in itemsApps" :key="item.title">
                  <v-list-tile-content v-for="item2 in extraAppsUser" :key="item2.appExtra">
                    <div v-if="item.title == item2.appExtra && item2.activa == 'Activada'">
                      <v-list-tile-title><strong>{{ item.title }}</strong></v-list-tile-title>
                      <v-list-tile-title v-if="item2.plazo == 'mensual'">Cuota mensual: $ {{ item.precioMensual }} MXN</v-list-tile-title>
                      <v-list-tile-title v-if="item2.plazo == 'anual'">Precio anual: $ {{ item.precioAnual }} MXN</v-list-tile-title>
                      <v-list-tile-title v-if="usuarioHost.nivelPlan != 'especial' && secondsExist == true">Fecha para renovación: {{ new Date(item2.fechaSigPago.seconds * 1000).toISOString().substr(0, 10) }}</v-list-tile-title>                                     
                    </div>
                  </v-list-tile-content>
                </div><br>          
              
                <strong>próxima facturación</strong> <br>
                  total: ${{ totalCuotas }} MXN<br>
                <v-btn v-if="secondsExist == true && usuarioHost.fechaSigPago.seconds < this.X" class="caption" small :to="{  name: 'pagoConTransfElect', params: {userName: usuarioHost.userName, referencia: 'total-cuotas'} }">hacer transferencia</v-btn><br> -->
                
                <!-- fecha: <br><br> -->
                <!-- <template v-if="usuarioHost.activa != 'Prueba'">
                  <v-layout row justify-center>
                    <v-dialog v-model="dialogPausar" persistent max-width="290">
                      <template v-slot:activator="{ on }">
                        <v-btn small class="caption text-lowercase" color="primary" dark v-on="on">pausar subscripción o cambiar de plan</v-btn>
                      </template>
                      <v-card>
                        <v-card-text>Si aceptas Pausar la subscripción, ya no tendrás las funciones de Negocio que elegiste, sólo tendrás
                          las funciones como plan "Personal". Tu información seguirá guardada para cuando desées reactivar la subscripción
                          o cambiar de plan.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" flat @click="dialogPausar = false">Cancelar</v-btn>
                          <v-btn color="primary" flat @click="pausarSubscripcion">Acepto Pausar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                    <span>-La cancelación de las app Extra es en la sección 
                  de "nuVin apps extra"-</span> 
                </template>
                
              </div> -->
              <div v-if="subscripciones.length > 0">
                <span v-if="subscripciones[0].status != 'active'">La reactivación de tu subscripción se realiza en el botón <strong>Giros-Negocio</strong></span>
                <span v-if="subscripciones[0].status == '' && usuarioHost.nivelPlan != 'especial' && usuarioHost.activa == 'Pausada'">La reactivación de tu subscripción se realiza en el botón <strong>Giros-Negocio</strong></span>
                <v-btn v-if="subscripciones[0].status == 'active'" @click="sendToCustomerPortal" class="caption" small>Cancelar subscripción</v-btn>
              </div>
            <!-- </v-card-text> -->
              <div>
                <span class="subheading ml-4 font-weight-black">Soporte</span><strong class="logoFont ml-1">nu<strong class="principal--text">Vin</strong></strong><br>
                <span><span class="primary--text font-weight-bold ml-2">email:</span>  nubesdebits@gmail.com</span><br>
                <span><span class="primary--text font-weight-bold ml-2">móvil-whatsapp:</span>  9996470286</span><br>
              </div>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                flat="flat"
                @click="dialogTicket = false"
                class="caption" 
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <v-card flat class="grey lighten-4">
        <!-- <div class="text-xs-right pr-2" v-if="usuarioHost.activa == 'Prueba' && this.today < this.vttoX2 && usuarioHost.nivelPlan != 'especial'">
          <span>Estás en periodo de prueba</span><br>          
          <planesSubs/>
        </div> -->
        <!-- <template>
          <v-card
            class="mt-4 mx-auto"
            max-width="100"
          >
            <v-sheet
              class="v-sheet--offset mx-auto"
              color="cyan"
              elevation="12"
              max-width="calc(100% - 32px)"
            >
              <v-sparkline
                :labels="labels"
                :value="value"
                color="white"
                line-width="2"
                padding="16"
              ></v-sparkline>
              <v-icon>home</v-icon><br>
              <span>prueba de datos</span>
            </v-sheet>

            <v-card-text class="pt-0">
              <div class="text-h6 font-weight-light mb-2">
                User Registrations
              </div>
              <div class="subheading font-weight-light grey--text">
                Last Campaign Performance
              </div>
              <v-divider class="my-2"></v-divider>
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock
              </v-icon>
              <span class="text-caption grey--text font-weight-light">last registration 26 minutes ago</span>
            </v-card-text>
          </v-card>
        </template> -->
        
        <div class="mt-5 mb-3">
          <h5 class="title text-xs-center font-weight-bold">Centro de Control:<br><span class="display-1 text-xs-center logoFont">{{usuarioHost.userName}}</span></h5>
          <v-card-text ml-3 class="text-xs-center">
            <span class="body-2">www.nuvin.app/{{usuarioHost.userName}}</span>
            <v-icon class="ml-2" color="black" v-clipboard:copy="'nuvin.app/' + usuario.userName" small @click="copy" left>mdi-content-copy</v-icon><br>
          </v-card-text>
        </div> 
        <v-layout>
          <v-flex xs12 class="text-xs-center headline" v-if="gastoProgrToday && perfilAuth">
              <span class="red--text text--accent-4">Hay Gastos Programados para Pago el día de Hoy o Vencidos</span>
          </v-flex>
        </v-layout> 
        <v-layout v-if="dashData.length && perfilAuth" justify-center wrap>
          <v-flex xs12 sm6 md4 lg3 class="grey lighten-4" v-for="(item, index) in dashData" :key="index">
            <v-card class="rounded-card elevation-10 anchoDataNum" >
              <v-layout>
                <v-flex xs6>
                  <v-card-title class="justify-left title pb-1">
                    <span class="grey--text lighten-2--text">{{ item.title }}</span> 
                  </v-card-title>
                  <span class="text-left font-weight-medium headline ml-3"><span>$</span>{{ item.data.toLocaleString('es-MX') }}</span><span class=" ml-1 subheading green--text"></span>
                </v-flex>
                <v-flex xs6 style="display: flex; align-items: center; justify-content: center;">
                  <div class="square elevation-7 mb-3 ml-2" :style="{'background-color': paletaColores.primero}">
                    <v-icon color="white" large>{{ item.icon }}</v-icon>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row wrap v-if="usuarioHost.userName == 'demoCol' && perfilAuth" class="grey lighten-4" >
          <template>
            <v-layout>
              <v-flex xs6 class="mt-3">
                <v-card
                  class="mx-auto text-xs-center"
                  color="green"
                  dark
                  max-width="600"
                >
                  <v-card-text>
                    <v-sheet color="rgba(0, 0, 0, .12)">
                      <v-sparkline
                        :value="value"
                        color="rgba(255, 255, 255, .7)"
                        height="100"
                        padding="24"
                        stroke-linecap="round"
                        smooth
                      >
                        <template v-slot:label="item">
                          ${{ item.value }}
                        </template>
                      </v-sparkline>
                    </v-sheet>
                  </v-card-text>

                  <v-card-text>
                    <div class="display-1 font-weight-thin">Ventas de 24h</div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions class="justify-center">
                    <v-btn block flat>Ir a Reportes</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
              <v-flex xs6 class="mt-3 ml-2">
                <template>
                  <v-card
                    class="mt-3 mx-auto"
                    max-width="400"
                  >
                    <v-sheet
                      class="v-sheet--offset mx-auto"
                      color="cyan"
                      elevation="12"
                      max-width="calc(100% - 32px)"
                    >
                      <v-sparkline
                        :labels="labels"
                        :value="value"
                        color="white"
                        line-width="2"
                        padding="16"
                      ></v-sparkline>
                    </v-sheet>

                    <v-card-text class="pt-0">
                      <div class="title font-weight-light mb-2">Pedidos</div>
                      <div class="subheading font-weight-light grey--text">Al día de Hoy</div>
                      <v-divider class="my-2"></v-divider>
                      <v-icon
                        class="mr-2"
                        small
                      >
                        mdi-clock
                      </v-icon>
                      <span class="caption grey--text font-weight-light">último pedido: hace 26 minutos</span>
                    </v-card-text>
                  </v-card>
                </template>
              </v-flex>                  
            </v-layout>                
          </template>
        </v-layout>

        <div v-if="usuarioHost.app == 'personal'">            
          <v-layout row justify-end>
            <v-btn
              color="primary"
              dark icon 
              @click.stop="dialogNota = true"
            >
              <v-icon>mdi-file</v-icon>
            </v-btn>

            <v-dialog
              v-model="dialogNota"
              max-width="290"
            >
              <v-card>
                <v-card-text>
                  Actualmente esta cuenta está en modo Personal, con la que podrás interactuar con otras nuVin
                  (Personal o Negocio) de forma gratuita; también podrás adquirir aplicaciones-herrmientas gratuitas
                  o de pago.
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="primary"
                    flat="flat"
                    @click="dialogNota = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>            
        </div>
        <!-- <div v-if="usuarioHost.app != 'personal'"> -->
          <!-- <template v-if="this.today < this.vttoX2 && subscripcionActiva != true">
            <span>Prueba gratuita de {{this.diasPrueba}} días. Vence {{vttoLetra}}</span>
            Subscripción Pendiente:
          </template>
          <v-btn small @click="sendToCustomerPortal" >Planes</v-btn> -->

          <!-- <div v-if="usuarioHost.nivelPlan != 'especial'"> -->
            <!-- <template v-if="perfilAuth && usuarioHost.stripeId && subscripcionActiva == true">
              <v-layout row justify-end>
                <v-dialog v-model="dialog2" persistent max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-btn color="" outline small v-on="on">Subscripción</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Estado de Subscripción</v-card-title>
                    
                    <v-card-text>Fecha de inicio: {{ rankStartSusc }}</v-card-text>
                    <v-card-text>Fecha de vencimiento: {{ rankEndSusc }}</v-card-text>
                    <v-btn small @click="sendToCustomerPortal" >Modificar SubSc</v-btn>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="grey darken-4" flat @click="dialog2 = false">Cerrar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-layout>
            </template> -->

            <!-- <template v-if="usuarioHost.activa == 'Prueba' && this.today > this.vttoX2 && usuarioHost.nivelPlan != 'especial' && usuarioHost.app != 'personal'">
              <v-layout row justify-center>
                <v-dialog v-model="dialog" persistent max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on" v-if="perfilAuth">Planes</v-btn>
                  </template>
                  <v-card> -->
                    <!-- <v-card-title class="headline">Planes de subscripción</v-card-title> -->
                    <!-- <v-card-title class="subheading">
                      <span>Ha finalizado tu periodo Prueba Gratuita de {{this.diasPrueba}} días.</span>
                      En el botón  <strong> Giros-Negocio</strong> podrás subscribirte al plan que más se 
                      adapte a las necesidades de tu negocio. En cualquier momento podrás hacer el cambio
                        de Plan o cancelar, si así lo requieres. Si no adquieres ningún plan de Negocio, quedará 
                        tu nuVin con las funciones de modalidad "Personal".
                    </v-card-title> -->
                    <!-- <div>
                      <planesSubs/>
                    </div> -->
                    <!-- <v-btn icon dark @click="salirPrueba">
                      <v-icon small dark left color="primary">arrow_back</v-icon>
                    </v-btn>
                    <span class="body-2">salir</span> -->
                    <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="this.today < this.vttoX2" color="principal" flat @click="dialog = false">Cerrar</v-btn>
                    </v-card-actions> -->
                  <!-- </v-card>
                </v-dialog>
              </v-layout>
            </template> -->

                  <!-- demo  -->
        <!-- <div>
          <v-layout align-start justify-start column v-if="perfilAuth == true">
            <div v-for="item in extraAppsUser" :key="item.app">
              <v-subheader>{{item.app}}</v-subheader>
              <v-layout align-center justify-space-around row fill-height wrap>
                <div v-for="(item2, index) in item.accesosCols" :key="index">
                  <v-flex xs4 v-if="item2.funcion != 'secundaria'">
                  <v-btn round small class="elevation-4" @click="movingSameHost(item2.componente, $route.params.userName)">
                    <v-icon small dark left>{{ item2.icon }}</v-icon><span class="caption text-none font-weight-bold">{{ item2.etiqueta }}</span>
                  </v-btn>
                </v-flex>
                </div>
              </v-layout>
            </div>
          </v-layout>
          
          <v-layout align-center justify-space-around row v-else>
            <div v-for="item in extraAppsUser" :key="item.app">
              <v-subheader>{{item.app}}</v-subheader>
              <v-layout align-center justify-space-around row fill-height wrap>
                <div v-for="(item2, index) in item.accesosCols" :key="index">
                  <v-flex xs4 v-if="item2.funcion != 'secundaria'">
                    <v-btn v-if="colaborador.estatusAccesos.some(el => el.switch == item2.switch && el.valor == true)" round small class="elevation-4" @click="movingSameHost(item2.componente, $route.params.userName)">
                      <v-icon small dark left>{{ item2.icon }}</v-icon><span class="caption text-none font-weight-bold">{{ item2.etiqueta }}</span>
                    </v-btn>
                    <v-btn v-else round small disabled class="elevation-4">
                      <v-icon small dark left>{{ item2.icon }}</v-icon><span class="caption text-none font-weight-bold">{{ item2.etiqueta }}</span>
                    </v-btn>
                </v-flex>
                </div>
              </v-layout>
            </div>
          </v-layout>
        </div>  -->

                <!-- div inmediato: Contenido areas para escoger giros y/o herramientas -->
        
        <div v-if="usuarioHost.btnConfigApps == true && (usuario.userName == 'demoCol' || usuario.userName == 'llogaran' || usuario.userName == 'appDemo')">
          <extraapps :estatusAppsExtraUser="extraAppsUser" />
          <configAppsUser/>
        </div>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogProgress" fullscreen hide-overlay transition="dialog-top-transition">
      <v-card>
          <template>
            <div class="mt-5 pt-5">
              <div class="text-xs-center mt-5 pt-5">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    :style="{'color': paletaColores.primero}"
                    indeterminate
                    class="mt-5 pt-5"
                  ></v-progress-circular>
              </div>
            </div>                  
          </template>
        </v-card>
    </v-dialog>
  </v-layout>
</template>
  
  <script>
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { db, functions, httpsCallable } from '@/firebase'
  import { doc, getDoc, addDoc, getDocs, collection, updateDoc, WriteBatch } from 'firebase/firestore'; 
  import 'firebase/functions'
  // import avisoDePrivacidad from '@/components/avisoDePrivacidad'
  // import terminosYcondiciones from '@/components/terminosYcondiciones'
  import extraapps from '@/components/extraApps'
  import configAppsUser from '@/views/superadmin/configAppsUser'
  import { v4 as uuidv4 } from 'uuid';
  import Localbase from 'localbase'
  
  let dbL = new Localbase('db')
  
    export default {
      components: { extraapps, configAppsUser },
      data () {
        return {
          labels: [
            '12am',
            '3am',
            '6am',
            '9am',
            '12pm',
            '3pm',
            '6pm',
            '9pm',
          ],
          value: [
            200,
            675,
            410,
            390,
            310,
            460,
            250,
            240,
          ],
          itemsFunciones: [
            {funcion: 'Datos', descripcion: 'Aquí se agregan y editan los Datos que se muestran en el área pública'},
            {funcion: 'Imagen', descripcion: 'Aquí se agregan y edita la imagen que se muestran en el área pública, incluyendo la imagen de tu Avatar'},
            {funcion: 'Menú', descripcion: 'Aquí se agregan y editan los Productos que ofrecerás a tus clientes'},
            {funcion: 'Estatus-Ventas', descripcion: 'Muestra el Estado de las ventas indicando la fecha'},
            {funcion: 'Inversiones y Créditos', descripcion: 'Área para agregar el capital que ingresas a tu negocio para su operación.'},
            {funcion: 'Saldos', descripcion: 'Se desglosan los saldos más importantes de tu negocio para que tengas claridad del escenario financiero de tu negocio'},
            {funcion: 'Gastos', descripcion: 'Área para agregar los gastos que realizas y que competen a tu negocio.'},
            {funcion: 'Colaboradores', descripcion: 'Aquí se agregan y administran las funciones de los colaboradores que colaborarán en tu negocio.'},
            {funcion: 'Comandas', descripcion: 'ärea donde tus colaboradores registrarán a cada uno de los clientes que le den atención, así como los productos que vayan a consumir.'},
            {funcion: 'Cocina', descripcion: 'Relación de pedidos que se solicitn en comandas correspondientes a cocina'},
            {funcion: 'Ticket&SPEI', descripcion: 'Configuración de los datos que aparecerán en el ticket de venta cuando sea impresa, y por otro lado, los datos para transferencia electrónica a tu negocio, para que tus clientes les sea más práctico hacer su pago.'},
          ],
          dashData: [],
          productosidCatg: [],
          subscripcion: [],
          planes: [],
          extraAppsUser: [],
          subscripciones: [],
          itemsApps: [],
          colaboradores: [],
          colaborador: '',
          precioMensualSemilla: 34.00, 
          precioAnualSemilla: 340.00,
          precioMensualEmprendedor: 144.00, 
          precioAnualEmprendedor: 1440.00,
          precioMensualEmpresario: 610.00, 
          precioAnualEmpresario: 6100.00,
          dialogProgress: false,
          dialogNota: false,
          dialogPausada: true,
          dialogPausar: false,
          dialogErrCompPago: true,
          dialogTicket: false,
          dialog: false,
          dialog2: false,
          dialog3: false,
          dialog4: false,
          dialogPlanes: false,
          dialogPromo: false,
          dialogFzs: false,
          dialogVtas: false,
          dialogConfig: false,
          dialogArPu: false,
          dialogHelp: false,
          show: false,
          sameHost: false,
          sameHostName: '',
          today: new Date().getTime(),
          vtto: '',
          vttoX2: '',
          diasPrueba: 2,
          codigoPromoRecibido: '',
          codigoPromoServer: '',
          elsCuotaProx: '',
          elSubsc: '',
          elappExt: '',
          cuotaSubsc: '',
          X: Math.floor(Date.now() / 1000),
          appExtMens: {
            precioMensual: 0,
            precioAnual: 0
          },
          cuotaAppExtA: 0,
          cuotaAppExtM: 0,
          totalCuotas: 0,
          gastosData: [],
          gastos: []
        }
      },
      
      created() {
        this.dialogProgress = true
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()
        this.actualizarContTicket()
        // this.guardarInfoGiros()
        // this.consultarOpcionesPlanesLB()
        // if (this.usuarioHost.nivelPlan != 'especial') {
        //   this.consultarSubscripcion()
        // } else (
          
        // )
        // this.consultarSuscripcionesLB()
        if (this.usuarioHost.app == 'comercio') {
          this.consultarProdCatalogFirebase()
        }
        this.tiempoPrueba()
        this.consultarDashData()
        this.consultarGastosProgr()
        // this.getItemsGiros()
      },
      computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        gastoProgrToday() {
          let todayDate = new Date().toISOString().substr(0, 10)
          return this.gastos.some(elm => elm.fechaFactura <= todayDate)
        },
        paletaColores() {
          if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
              return this.usuarioHost.designColors;
          } else {
              const colores = ['#E040FB',];
              const colores2 = ['#616161',];
              const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
              const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
              return {
              primero: colorAleatorio,
              segundo: colorAleatorio2,
              tercero: colorAleatorio,
              cuarto: colorAleatorio
              };
          }
        },
        itemGiroEnExtra() {
          return this.extraAppsUser.find(gr => gr.app == this.usuarioHost.app )
        },
        secondsExist() {
          return Object.prototype.hasOwnProperty.call(this.usuarioHost, 'fechaSigPago')
        },
        secondsPruebaExist() {
          return Object.prototype.hasOwnProperty.call(this.usuarioHost, 'fechaInitPrueba')
        },
        // girosqueaplica() {
        //   let itemsAppsHrr = this.itemsApps.filter( itHrr => itHrr.tipoApp == 'herramienta')
        //   return itemsAppsHrr.forEach(extr => 
        //               extr.girosqueaplica.includes( gir => gir == this.usuarioHost.app)) 
        // },
        fechaTimeStamp() {
          // let y = ''
          let x = this.usuarioHost.fechaSigPago
          return x.toDate().toDateString()
          
        },
        appExtraAutoPedido() {
          return this.extraAppsUser.some(extr => extr.appExtra === "Auto-Pedido"
                                      && extr.activa === "Activada") 
        },
        appExtraClientes() {
          return this.extraAppsUser.some(extr => extr.appExtra === "Clientes"
                                      && extr.activa === "Activada") 
        },
        appExtraReservoHab() {
          return this.extraAppsUser.some(extr => extr.appExtra === "ReservoHabitacion"
                                      && extr.activa === "Activada") 
        },
        keyPlanSusc() {
          return this.subscripcion[0].product._key.path.segments[6]
        },
        rankStartSusc() {
          var rSobj = this.subscripcion[0].current_period_start
          var rS = rSobj.toDate().getTime()
          return new Date(rS).toString().substr(0, 15)
        },
        rankEndSusc() {
          var rEobj = this.subscripcion[0].current_period_end
          var rE = rEobj.toDate().getTime()
          return new Date(rE).toString().substr(0, 15)
        },
        vttoLetra() {
          return new Date(this.vttoX2).toString().substr(0, 15)
        },
        algunPagoConError() {
          return this.extraAppsUser.some(extr => extr.estatusPago === "VerifNoCorr")
        },
        subscripcionActiva() {
          // return this.subscripcion.some(subs => subs.status === 'active')    
          return true 
        },
      },
      methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado']),
        ...mapActions('usuarioHost', ['consultarColaboradoresFB']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        ...mapActions('usuarioHost', ['actualizarContTicket']),
        async movingSameHost(item, item2) {
          await dbL.collection('fromSameHost').set([])
          await dbL.collection('fromSameHost').add({sameHost: true, userHost: item2.toLowerCase() })
          this.$router.push({ name: item })
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            })
            // .finally(() => {
                this.consultarColLB()
            // });
        },
        async consultarColLB() {
          
          if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
            // this.mostrarInformacion('por el momento no tienes los permisos para acceder a la ofi-nuVin de'+' '+`${this.usuarioHost.userName}`+', intenta desde su área pública....')
            this.$router.push({ name: 'Entrada' })
          } else if (this.sameHost == true) {
          
            this.colaboradores = []
            this.colaborador = ''
            if (this.perfilAuth == true) {
              this.colaborador = this.usuarioHost
              // //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
            } else if (this.perfilAuth == false) {
            
              await dbL.collection('colaboradores').get().then(cols => {
                this.colaboradores = cols
              })
  
              if (this.colaboradores.length > 0) {
                let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                if (typeof colaborador === 'object') {
                  let convertINarray = Object.values(colaborador.estatusAccesos)
                  delete colaborador.estatusAccesos
                  colaborador.estatusAccesos = convertINarray
                  this.colaborador = colaborador  
                } else {
                  this.colaborador = this.usuarioHost
                  // //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                }
              } else {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a la ofi-nuVin de'+' '+`${this.usuarioHost.userName}`+', intenta desde su área pública....')
                this.$router.push({ name: 'Entrada' })
              }
            } else {
              // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a la ofi-nuVin de'+' '+`${this.usuarioHost.userName}`+', intenta desde su área pública....')
                this.$router.push({ name: 'Entrada' })
            }
          }
          // await dbL.collection('fromSameHost').delete()
          // this.sameHost = false
          // this.sameHostName = ''
          this.consultarExtraAppsCompradas()
        },
        copy() {
            this.mostrarExito('Dato COPIADO')
        },
        async estatusAreaPu() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            const userNameRef = doc(db, 'userNames', userNameParametro);
            
            this.mostrarOcupado({ titulo: 'Modificando Estatus de Área Pública', mensaje: 'Cargando datos...' });

            try {
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    const usuarioRef = doc(db, 'usuarios', userName.uid);

                    await updateDoc(usuarioRef, {
                        areaPublica: this.usuarioHost.areaPublica
                    });

                    this.mostrarExito('Estatus Área Pública Modificado');
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error configurando el estatus de Área Pública');
            } finally {
                this.ocultarOcupado();
            }
        },
        async salirPrueba() {
            this.mostrarOcupado({ titulo: 'Terminando la prueba Gratis', mensaje: 'Almacenando datos para la app "Personal"...' });

            let userNameParametro = this.$route.params.userName.toLowerCase();
            const userNameRef = doc(db, 'userNames', userNameParametro);

            try {
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    let batch = WriteBatch(db);
                    let ntfid = uuidv4();

                    let notif = {    
                        ntfid,
                        tipoNotif: 'fin-prueba-plan',
                        asunto: "Ha terminado el tiempo de prueba",
                        cliente: this.usuarioHost.userName,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                    };

                    const comprasAppExtraRef = doc(db, 'usuarios', userName.uid, 'comprasAppExtra', this.usuarioHost.app);
                    const usuarioRef = doc(db, 'usuarios', userName.uid);
                    const notificacionesRefUser = doc(db, 'usuarios', userName.uid, 'notificaciones', ntfid);
                    const notificacionesRefAdmin = doc(db, 'usuarios', '6ooam9l7LdMrCVHG9Qwm6qS7MCq1', 'notificaciones', ntfid);

                    batch.update(comprasAppExtraRef, { activa: 'Desactivada' });
                    batch.update(usuarioRef, { activa: 'Pausada' });
                    batch.set(notificacionesRefUser, notif);
                    batch.set(notificacionesRefAdmin, notif);

                    await batch.commit();

                    this.ocultarOcupado();
                    this.mostrarExito('La prueba gratuita nuVin ha finalizado.');
                    this.$router.push({ name: 'Entrada', params: { userName: this.usuarioHost.userName } });
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error al finalizar la prueba.');
            }
        },
        async pausarSubscripcion() {
            this.mostrarOcupado({ titulo: 'Cargando la información', mensaje: 'Almacenando la instrucción...' });
            let userNameParametro = this.$route.params.userName.toLowerCase();
            const userNameRef = doc(db, 'userNames', userNameParametro);

            try {
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    const usuarioRef = doc(db, 'usuarios', userName.uid);
                    await usuarioRef.update({
                        activa: 'Pausada',
                    });

                    let batch = WriteBatch(db);
                    let ntfid = uuidv4();

                    let notif = {    
                        ntfid,
                        tipoNotif: 'pausa-plan',
                        asunto: "Se ha pausado el plan",
                        cliente: this.usuarioHost.userName,
                        hora: new Date().toLocaleTimeString('es-MX'),
                        fecha: new Date().toISOString().substr(0, 10),
                        fechaStd: new Date(),
                    };

                    const notificacionesRefUser = doc(db, 'usuarios', userName.uid, 'notificaciones', ntfid);
                    const notificacionesRefAdmin = doc(db, 'usuarios', '6ooam9l7LdMrCVHG9Qwm6qS7MCq1', 'notificaciones', ntfid);

                    batch.set(notificacionesRefUser, notif);
                    batch.set(notificacionesRefAdmin, notif);

                    await batch.commit();
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error al pausar la suscripción.');
            } finally {
                this.ocultarOcupado();
                this.mostrarExito('Has pausado la subscripción de un Plan nuVin. Cuando gustes puedes reactivarlo.');
                this.$router.push({ name: 'Entrada', params: { userName: this.usuarioHost.userName } });
            }
        },
        async sendToCustomerPortal() {
          this.mostrarOcupado({ titulo: 'Redireccionando para Cancelar', mensaje: 'Cargando datos...' });
          
          try {
            const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
            const { data } = await functionRef({ returnUrl: window.location.origin });
            window.location.assign(data.url);
          } catch (error) {
            this.mostrarError('Ocurrió un error al redireccionar para cancelar.');
          } finally {
            this.ocultarOcupado();
          }
        },
        async consultarExtraAppsCompradas() {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            try {
                const userNameRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    const userName = userNameDoc.data();
                    const comprasAppExtraRef = collection(db, 'usuarios', userName.uid, 'comprasAppExtra');
                    
                    // Suponiendo que dbL es otra instancia de Firestore, que necesitas manejar aparte
                    await dbL.collection('extraAppsUser').set([]); // Esto puede no ser correcto, revisa la API de Firestore

                    const resultado = await getDocs(comprasAppExtraRef);

                    resultado.forEach(doc => {
                        const extraapps = doc.data();
                        addDoc(collection(dbL, 'extraAppsUser'), extraapps); // Añade el documento a dbL
                        this.extraAppsUser.push(extraapps); // Añade el documento al array
                    });
                }
            } catch (error) {
                // this.mostrarError('Ocurrió un error consultando los extra.');
            } finally {
                if (this.extraAppsUser.length > 0) {
                    if (this.usuarioHost.nivelPlan !== 'especial' && (!this.subscripciones || this.subscripciones.length === 0)) {
                        this.totalCuotaProx();
                    }
                }
            }
        },
        async totalCuotaProx(){
          if (this.extraAppsUser.some(x => x.fechaSigPago)) {
            this.elsCuotaProx = this.extraAppsUser.filter(element => element.fechaSigPago.seconds > this.X)
          }
          this.elSubsc = this.elsCuotaProx.filter( elS => elS.app )
          this.elappExt = this.elsCuotaProx.filter( elAext => elAext.appExtra )
  
            if (this.elSubsc[0].plazo == 'mensual') {
              if (this.elSubsc[0].nivelPlan == 'semilla') {
                this.cuotaSubsc = this.precioMensualSemilla
              } else if (this.elSubsc[0].nivelPlan == 'emprendedor') {
                this.cuotaSubsc = this.precioMensualEmprendedor
              } else if (this.elSubsc[0].nivelPlan == 'empresario') {
                this.cuotaSubsc = this.precioMensualEmpresario
              } else {
                this.cuotaSubsc = 0
              }
              let diaescogidoCobroSubs = 3
              var fechaSigM = new Date(new Date().getFullYear(), new Date().getMonth()+1, diaescogidoCobroSubs)
            } else if (this.elSubsc[0].plazo == 'anual') {
              if (this.elSubsc[0].nivelPlan == 'semilla') {
                this.cuotaSubsc = this.precioAnualSemilla
              } else if (this.elSubsc[0].nivelPlan == 'emprendedor') {
                this.cuotaSubsc = this.precioAnualEmprendedor
              } else if (this.elSubsc[0].nivelPlan == 'empresario') {
                this.cuotaSubsc = this.precioAnualEmpresario
              } else {
                this.cuotaSubsc = 0
              }
              let diaescogidoCobroSubs = 3
              let mesescogidoCobroSubs = new Date().getMonth()
              var fechaSigA = new Date(new Date().getFullYear()+1, mesescogidoCobroSubs, diaescogidoCobroSubs,)
            }
  
          try {
            this.appExtMens = this.elappExt.filter( elmt => elmt.plazo == 'mensual')
              this.cuotaAppExtM = 0
              for(let i = 0; i < this.appExtMens.length; i++) this.cuotaAppExtM+=this.appExtMens[i].precioMensual;
               
            this.appExtAnl = this.elappExt.filter( elmt => elmt.plazo == 'anual')
              this.cuotaAppExtA = 0
              for(let i = 0; i < this.appExtAnl.length; i++) this.cuotaAppExtA+=this.appExtAnl[i].precioAnual;
          } finally {
            let fechaSig = fechaSigM || fechaSigA    
            this.totalCuotas = this.cuotaSubsc + this.cuotaAppExtM + this.cuotaAppExtA
            // adjunta fecha del sig pago dentro del array a enviar a local base
            await dbL.collection('totCuotas').set([{totalCuotas: this.totalCuotas, referencia: 'total-cuotas', fechaSig: fechaSig}])
          }
            
        },
        async tiempoPrueba() {
          let dt = this.usuarioHost.fechaInitPrueba
          // this.vtto = this.usuarioHost.fechaInitPrueba.toDate().getTime()
          this.vtto = dt && dt.toDate && dt.toDate().getTime()
          this.vttoX2= this.vtto + (86400000 *this.diasPrueba)
          
          // if(this.today > this.vttoX2) {
          //   this.dialog = true
          // }
        },
        async consultarGastosProgr() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.today = new Date().getTime();
            this.daysPast = this.today - (86400000 * 30);       

            try {
                const userNameRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    const userName = userNameDoc.data();
                    const gastosRef = collection(db, 'usuarios', userName.uid, 'gastos');
                    const resultado = await getDocs(gastosRef);

                    resultado.forEach(doc => {
                        const gasto = doc.data();
                        gasto.formatToIso = new Date(gasto.fechaMili).toISOString().substr(0, 10);
                        this.gastosData.push(gasto);
                    });

                    this.gastos = this.gastosData.filter(elm => elm.programado === true);
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando las gastos.');
            }
        },
        async consultarOpcionesPlanesLB() {
          await dbL.collection('planes').get().then(plns => {
            this.planes = plns
          })
        },
        async consultarProdCatalogFirebase() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            
            try {
                const userNameRef = doc(db, 'userNames', userNameParametro);
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    const userName = userNameDoc.data();

                    const catalogosRef = collection(db, 'usuarios', userName.uid, 'catalogosDB');
                    const resultado = await getDocs(catalogosRef);

                    resultado.forEach(doc => {
                        const dosres = doc.data();
                        delete dosres.uidC;
                        this.productosidCatg.push(dosres);
                        // dbL.collection('productosidCatg').add(dosres); // Asegúrate de que `dbL` esté correctamente inicializado y importado
                    });

                    // Asegúrate de que `existenciasAlLimite` esté definido en tu componente
                    this.existenciasAlLimite();
                }
            } catch (error) {
                // this.mostrarError('Todavía no hay productos registrados, o su señal de wifi está fallando. Inténtelo más tarde.');
            }
        },
        existenciasAlLimite() {          
            if (this.productosidCatg.some( element => element.cantAlert >= element.existencia)) {
              this.show = true
            } else {
              this.show = false
            }
        },
        async consultarDashData() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            const userNameRef = doc(db, 'userNames', userNameParametro);

            try {
                const userNameDoc = await getDoc(userNameRef);

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    const dashDataRef = collection(db, 'usuarios', userName.uid, 'dashData');
                    const resultado = await getDocs(dashDataRef);

                    resultado.forEach(doc => {
                        let dashd = doc.data();
                        this.dashData.push(dashd);
                    });
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error consultando los datos del dashboard.');
            } finally {
                this.dialogProgress = false;
            }
        }
      },
  }
  </script>

<style>



  .logoFont {
    font-family: 'Comfortaa', cursive;
  }

  .anchoDataNum {
    min-width: 200px
  }

  .v-sheet--offset {
    top: -24px;
    position: relative
  }

  .rounded-card {
    border: 2px solid black;
    border-radius: 20px;
    height: 100px;
    margin: 10px;
  }

  .square {
    width: 40px;
    height: 40px;
    /* background-color: blue; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>