
let editMiembrosPlaza = () => import( '../views/plaza/editMiembrosPlaza.vue')

export default [
    {
        path: '/:userName/admin/edit-miembros',
        name: 'editMiembrosPlaza',
        component: editMiembrosPlaza,
        meta: {
          autenticado: true
        }
      },
]