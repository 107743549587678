<template>
  <v-layout justify-center align-center wrap>
    <v-flex xs12 v-if="dialogProgress == false">
        <v-card class="elevation=5 ma-3 mt-5 py-3 custom-card">
            <v-layout>
            <v-flex xs2 class="text-xs-left ml-4">
                <v-icon :style="{'color': paletaColores.primero}" class="display-3 pl-4">mdi-currency-usd</v-icon>                
            </v-flex>
            <v-flex xs8 class="text-xs-left">
                <h3 class="hidden-xs-only"><p class="text-xs-left display-1 mt-3">Ingresos</p></h3>
                <h3 class="hidden-sm-and-up"><p class="text-xs-center display-1 mt-3">Ingresos</p></h3>
            </v-flex>        
            <v-flex xs2 class="text-xs-right">
                <v-dialog
                    v-model="dialogHelp"
                    width="500"
                >
                    <template v-slot:activator="{ on }">
                    <v-btn icon small dark v-on="on" class="text-xs-rigth secondary"><v-icon small>mdi-help</v-icon></v-btn>
                    </template>
                    <v-card>
                    <v-card-title class="headline">
                        Cómo funciona esta sección...
                    </v-card-title>

                    <v-card-text>
                        En esta área se agregan los conceptos de Ingreso que utiliza el negocio, familia o persona. Esta sección 
                        es útil para tener noción de los Ingresos de Operación que ha realizado el negocio en le tiempo y nos ayuda a realizar
                        cálculos de rentabilidad.
                        <ul>
                            <li>En el signo de "+" o botón "Agregar" dar click, se desplega un formulario para ingresar los datos característicos
                                de este Ingreso.
                            </li>
                            <li>
                                Asigna etiquetas para una perspectiva más clara de dónde recibes dinero, proporciones y cada cuánto tiempo.
                            </li>
                            <li>
                                La cifra total, también será reflejada en la sección de SALDOS. 
                            </li>
                        </ul> 
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat small dark class="principal--text caption" @click="dialogHelp = false">Cerrar</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex xs6 ml-3 mt-2>        
                
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs4 class="mr-3">
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
            </v-flex>            
        </v-layout>   

        <v-flex xs12>            
            <v-card class="elevation=5 ma-3">
                <v-toolbar flat class="mt-4">
                    <v-layout wrap>
                        <!-- INGRESAR INGRESO DATOS -->
                        <v-flex xs12 sm6 >
                            <v-dialog v-model="dialog">
                                <template v-slot:activator="{ on }">
                                    <v-btn class="hidden-xs-only text-none elevation-13" :style="{'background-color': paletaColores.primero}" dark v-on="on" v-if="puedeRegIngresos">Registrar Ingreso</v-btn>
                                    <v-btn icon top right absolute small fab dark :style="{'background-color': paletaColores.primero}" class="hidden-sm-and-up mt-2" v-on="on" v-if="puedeRegIngresos">
                                        <v-icon class="pt-2 mt-1">add</v-icon>   
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                        <v-card-text>
                                            <v-container grid-list-md>
                                            <v-layout wrap >
                                                <v-flex xs12 sm6 md4>
                                                    <v-text-field v-model="ingreso.proveniente" label="de dónde proviene (referencia)" @blur="$v.ingreso.proveniente.$touch()" :error-messages="erroresProveedor"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <v-text-field v-model="ingreso.numDocumento" label="Núm. de documento (opcional)" counter="20" @blur="$v.ingreso.numDocumento.$touch()" :error-messages="erroresNumFactura"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <v-select @blur="$v.ingreso.tipoIngreso.$touch()" :error-messages="erroresTipoIngreso"
                                                    v-model="ingreso.tipoIngreso"
                                                    :items="etiquetasIngreso"
                                                    label="Subgrupo de ingreso (etiqueta-Ingreso)" 
                                                    ></v-select>                                        
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <v-select @blur="$v.ingreso.tipoDinero.$touch()" :error-messages="erroresTipoDinero"
                                                    v-model="ingreso.tipoDinero"
                                                    :items="etiquetasDinero"
                                                    label="A dónde se enviará este ingreso (etiqueta-Dinero)"
                                                    ></v-select>                                        
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <!-- <v-text-field type="text" v-model="ingreso.fechaDocumento" label="Fecha de documento" locale="es-MX"></v-text-field> -->
                                                    <v-menu
                                                        ref="menu1"
                                                        v-model="menu1"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        lazy
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        max-width="290px"
                                                        min-width="290px"
                                                        >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                            v-model="dateFormatted"
                                                            label="Fecha"
                                                            persistent-hint
                                                            prepend-icon="event"
                                                            @blur="updateFechaFactura"
                                                            v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="ingreso.fechaDocumento" no-title @input="menu1 = false"></v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                                <v-flex xs12 sm6 md4>
                                                    <v-text-field type="number" v-model="ingreso.monto" label="Monto" @blur="$v.ingreso.monto.$touch()" :error-messages="erroresMonto"></v-text-field>
                                                </v-flex>
                                                    <!-- :rules="rules" -->
                                                <v-flex xs12 sm6>
                                                    <v-text-field
                                                        v-model="ingreso.notas"
                                                        counter="25"
                                                        label="Notas"
                                                        outline
                                                        @blur="$v.ingreso.numDocumento.$touch()" :error-messages="erroresNumFactura"
                                                    ></v-text-field>
                                                </v-flex>
                                            </v-layout>
                                            </v-container>
                                        </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" flat @click="close">Cancelar</v-btn>
                                        <v-btn color="primary" :depressed="$v.ingreso.$invalid" :disabled="$v.ingreso.$invalid" flat @click="save">Guardar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-flex>
                        <!-- ETIQUETAS DE INGRESO -->
                        <v-btn class="caption text-lowercase" small :style="{'background-color': paletaColores.primero}" dark :to="{ name: 'etiquetasFinanzas', params: {userName: usuarioHost.userName}}" v-if="puedeRegEtqs">Etiquetas</v-btn>
                    </v-layout>
                </v-toolbar>            
                <v-data-table
                    :headers="headers"
                    :items="ingresos"
                    :search="search"                    
                    class="elevation-1"
                    >
                    <template v-slot:items="props">
                        <td><strong>{{ props.item.proveniente }}</strong></td>
                        <td class="justify-center align-center layout">       
                            <!-- icono dialog de book para ver ingresos-->
                            <v-dialog v-model="dialog2" persistent max-width="600px">                            
                                <!-- <template v-slot:activator="{ on }">                                
                                    <v-icon
                                        @click="abrirGasto(props.item)"
                                        dark v-on="on"
                                        color="primary"
                                        class="mr-2"
                                        small
                                        >
                                        book
                                    </v-icon>
                                </template> -->
                                <v-card  
                                    v-if="ingreso"
                                    :key= "ingreso.ingrid"
                                    :gastoPartidas="ingreso.gastoPartidas"
                                    >
                                    <v-toolbar flat color="white">
                                        <h3>Partidas de:</h3><br>
                                        <v-toolbar-title >
                                            <h3 class="caption"> {{ingreso.proveniente}} </h3>
                                            <h6 class="caption">Número de documento:</h6><h3 class="caption font-weight-medium"> {{ingreso.numDocumento}} </h3>
                                        </v-toolbar-title>
                                        <v-divider
                                            class="mx-2"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-dialog v-model="dialog3" max-width="500px" persistent>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="caption text-none hidden-xs-only" mb-2 :style="{'background-color': paletaColores.primero}" dark v-on="on" >Nueva Partida</v-btn>
                                                <v-btn  class="caption text-none hidden-sm-and-up pt-2" small fab :style="{'background-color': paletaColores.primero}" outline v-on="on">
                                                    <v-icon>add</v-icon>   
                                                </v-btn>
                                            </template>
                                            <v-card>
                                            <v-card-title>
                                                <span class="headline">{{ formTitlePartidas }}</span>
                                            </v-card-title>
                                            <v-card-text >
                                                <v-container grid-list-md>
                                                    <v-layout wrap>
                                                        <v-flex xs12 sm6 md4>
                                                            <v-text-field v-model="partida.nombreProducto" label="Producto" @blur="$v.partida.nombreProducto.$touch()" :error-messages="erroresProducto"></v-text-field>
                                                            <v-text-field v-model="partida.unidad" label="Unidad" @blur="$v.partida.unidad.$touch()" :error-messages="erroresUnidad"></v-text-field>
                                                            <v-text-field v-model="partida.precio" label="Precio" @blur="$v.partida.precio.$touch()" :error-messages="erroresPrecio" type="number"></v-text-field>
                                                            <v-text-field v-model="partida.cantidad" label="Cantidad" class="number" @blur="$v.partida.cantidad.$touch()" :error-messages="erroresCantidad" type="number" min="1" max="100"></v-text-field>
                                                        </v-flex>                                    
                                                    </v-layout>
                                                </v-container>
                                                <small>*Campo requerido</small>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn class="caption text-none" dark :style="{'background-color': paletaColores.primero}" flat @click="closePartida">Cerrar</v-btn>
                                                <v-btn class="caption text-none" flat :depressed="$v.partida.$invalid" :disabled="$v.partida.$invalid" @click="savePartida">Guardar</v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-btn outline class="hidden-sm-and-up pt-2" small fab dark color="primary" @click="dialog2 = false">
                                            <v-icon dark>close</v-icon>   
                                        </v-btn>
                                        <v-btn outline class="hidden-xs-only" color="primary" flat @click="dialog2 = false">Cerrar</v-btn>
                                    </v-toolbar>
                                    <v-data-table 
                                        :headers="headers2"
                                        :items="gastoPartidas"
                                        class="elevation-1"                    
                                        >
                                        <template v-slot:items="props">
                                            <td><strong>{{ props.item.producto }}</strong></td>
                                            <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                            <td class="text-xs-center">{{ props.item.precio }}</td>
                                            <td class="text-xs-right">{{ props.item.unidad }}</td>                                                                                        
                                            <!-- <td class="text-xs-right">{{ props.item.subtotal }}</td> -->
                                            <td class="text-xs-center number">$ {{ props.item.subtotal }}</td>
                                            <td class="text-xs-center">
                                                <v-icon
                                                    small color="green"
                                                    class="mr-2"
                                                    @click="editItemPartida(props.item)"
                                                >
                                                    edit
                                                </v-icon>
                                                <estasSeguro v-if="colaboradorBorrar == true">
                                                    <v-icon
                                                        color="red"
                                                        slot="nombrebtn"
                                                        small                                       
                                                        >
                                                        delete
                                                    </v-icon>
                                                    <div slot="elemento">
                                                        <span>esta partida?</span>
                                                    </div>
                                                    <div slot="btnaceptar">
                                                        <v-btn flat @click="deletePartida(props.item)">Ok</v-btn>
                                                    </div>
                                                </estasSeguro>
                                                <v-icon
                                                    disabled
                                                    small
                                                    v-if="colaboradorBorrar == false"
                                                >
                                                    delete
                                                </v-icon>
                                            </td>                                                          
                                        </template>
                                    </v-data-table>   
                                    <template>
                                        <v-layout row wrap>
                                            <v-flex xs12>
                                                <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                                    <span class="subheading mr-1">Sub-Total: $ {{ subtotSum }}</span><br>
                                                    <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                                    <span class="subheading mr-1">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                </v-card>
                                            </v-flex>            
                                            <!-- Ticket para imprimir -->
                                            <v-layout row justify-center>
                                                <v-dialog v-model="cardTicket" scrollable max-width="330px">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="primary" dark v-on="on">Mostrar ticket o imprimir?</v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>Ticket de Ingresos</v-card-title>
                                                    <v-card-actions>
                                                        <v-btn icon color="primary" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                                    </v-card-actions>
                                                    <v-divider></v-divider>
                                                    <v-card-text style="height: 350px;" ref="printTable">                                                    
                                                            <v-flex xs12 sm12>
                                                            <v-card>                                                            
                                                                <v-list two-line>
                                                                <template >                                                                
                                                                    <p class="text-xs-center">
                                                                        <span>{{ contTicket.empresa }}</span><br>
                                                                        <span>{{ contTicket.RFC }}</span><br>
                                                                        <span>{{ contTicket.direccion }}</span><br>
                                                                        <span>{{ contTicket.telefono }}</span><br>
                                                                        <span>{{ contTicket.notapie }}</span>
                                                                    </p>                                                                
                                                                    <v-divider></v-divider>
                                                                        <template>
                                                                        <v-data-table
                                                                            :headers="headers3"
                                                                            :items="gastoPartidas"
                                                                            class="elevation-1"                                                            
                                                                            hide-actions
                                                                        >
                                                                            <template v-slot:items="props">
                                                                            <td><strong>{{ props.item.producto }}</strong></td>
                                                                            <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                                            <td class="text-xs-right">{{ props.item.precio }}</td>                                                            
                                                                            </template>
                                                                        </v-data-table>
                                                                        <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                                                            <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                                            <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                                            <span class="body-2">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                                        </v-card>
                                                                        </template>
                                                                    <v-divider></v-divider>
                                                                        <span>{{ contTicket.empresa }}</span><br>
                                                                        <span>{{ contTicket.RFC }}</span><br>
                                                                        <span>{{ contTicket.direccion }}</span><br>
                                                                        <span>{{ contTicket.telefono }}</span><br>
                                                                        <span>{{ contTicket.notapie }}</span>
                                                                    </template>
                                                                </v-list>
                                                            </v-card>
                                                            </v-flex>                                                    
                                                    </v-card-text>
                                                    <v-divider></v-divider>                                                
                                                </v-card>
                                                </v-dialog>
                                            </v-layout>                                    
                                        </v-layout>
                                    </template>                    
                                </v-card>      
                            </v-dialog>
                            <v-icon
                                class="mr-2" color="green"
                                @click="editItem(props.item)" small
                                >
                                edit
                            </v-icon>
                            <estasSeguro v-if="colaboradorBorrar == true || perfilAuth == true">
                                <v-icon
                                    color="red"
                                    slot="nombrebtn"
                                    small                                       
                                    >
                                    delete
                                </v-icon>
                                <div slot="elemento">
                                    <span>este concepto de Ingreso?</span>
                                </div>
                                <div slot="btnaceptar">
                                    <v-btn flat @click="deleteItem(props.item)">Ok</v-btn>
                                </div>
                            </estasSeguro>
                            <v-icon
                                disabled
                                small
                                v-else
                            >
                                delete
                            </v-icon>
                        </td>
                        <td>{{ props.item.tipoIngreso }}</td>
                        <td>{{ props.item.tipoDinero }}</td>                        
                        <td class="text-right">{{ '$' + props.item.monto.toLocaleString('es-MX', { minimumFractionDigits: 2 }) }}</td>
                        <td class="justify-center align-center">{{ props.item.fechaDocumento }}</td>                                                       
                        <td class="justify-center align-center">{{ props.item.numDocumento }}</td>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                        Your search for "{{ search }}" found no results.
                        </v-alert>
                    </template>
                </v-data-table>
                <template>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                <!-- <span class="subheading mr-1">Total: $ {{ totIngresos.toLocaleString('es-MX') }}</span><br> -->
                                <!-- <span class="subheading mr-1">TAX: $ {{ impuesto }}</span><br>
                                <span class="subheading mr-1">Total: $ {{ totSum }}</span> -->
                            </v-card>
                        </v-flex>            
                        <!-- Ticket para imprimir -->
                        <!-- <v-layout row justify-center>
                            <v-dialog v-model="cardTicket" scrollable max-width="330px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" dark v-on="on">imprimir</v-btn>
                            </template>
                            <v-card>
                                <v-card-title>Ticket de Ingresos</v-card-title>
                                <v-card-actions>
                                    <v-btn icon color="primary" flat @click="printData"><v-icon>print</v-icon></v-btn>
                                </v-card-actions>
                                <v-divider></v-divider>
                                <v-card-text style="height: 350px;" ref="printTable">                                                    
                                        <v-flex xs12 sm12>
                                        <v-card>                                                            
                                            <v-list two-line>
                                            <template >                                                                
                                                <p class="text-xs-center">
                                                    <span>{{ contTicket.empresa }}</span><br>
                                                    <span>{{ contTicket.RFC }}</span><br>
                                                    <span>{{ contTicket.direccion }}</span><br>
                                                    <span>{{ contTicket.telefono }}</span><br>
                                                    <span>{{ contTicket.notapie }}</span>
                                                </p>                                                                
                                                <v-divider></v-divider>
                                                    <template>
                                                    <v-data-table
                                                        :headers="headers3"
                                                        :items="gastoPartidas"
                                                        class="elevation-1"                                                            
                                                        hide-actions
                                                    >
                                                        <template v-slot:items="props">
                                                        <td>{{ props.item.producto }}</td>
                                                        <td class="text-xs-right">{{ props.item.cantidad }}</td>
                                                        <td class="text-xs-right">{{ props.item.precio }}</td>                                                            
                                                        </template>
                                                    </v-data-table>
                                                    <v-card :style="{'background-color': paletaColores.segundo}" class="white--text text-xs-right">
                                                        <span class="body-2" >Sub-Total: $ {{ subtotSum }}</span><br>
                                                        <span class="body-2">TAX: $ {{ impuesto }}</span><br>
                                                        <span class="body-2">Total: $ {{ Math.round((totSum + Number.EPSILON) * 100) / 100 }}</span>
                                                    </v-card>
                                                    </template>
                                                <v-divider></v-divider>
                                                    <span>{{ contTicket.empresa }}</span><br>
                                                    <span>{{ contTicket.RFC }}</span><br>
                                                    <span>{{ contTicket.direccion }}</span><br>
                                                    <span>{{ contTicket.telefono }}</span><br>
                                                    <span>{{ contTicket.notapie }}</span>
                                                </template>
                                            </v-list>
                                        </v-card>
                                        </v-flex>                                                    
                                </v-card-text>
                                <v-divider></v-divider>                                                
                            </v-card>
                            </v-dialog>
                        </v-layout>                                     -->
                    </v-layout>
                </template>
            </v-card>            
        </v-flex> 
      </v-card>   
    </v-flex>

    <template v-else>
        <div class="mt-5 pt-5" >
            <div class="text-xs-center mt-5 pt-5">
            <v-progress-circular
                :size="70"
                :width="7"
                :style="{'color': paletaColores.primero}"
                indeterminate
                class="mt-5 pt-5"
                ></v-progress-circular>
            </div>
        </div>                  
    </template>
  </v-layout>  
</template>

<script>
import estasSeguro from '@/components/estasSeguro.vue'
import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import { mapMutations, mapState, mapActions } from 'vuex'
import { query, where, getDocs, orderBy, collection, deleteDoc, doc, getDoc, updateDoc, setDoc, writeBatch } from 'firebase/firestore';
import { db } from '@/firebase'
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase'

let dbL = new Localbase('db')

export default {
    components: { estasSeguro },
    data: () => ({
        // rules: [v => v.length <= 25 || 'Max 25 characters'],
        // colaboradorEnviado: [],
        dateFormatted: null,
        menu1: false,
        colaborador: '',
        colaboradores: [],
        contTicket: '',
        rowsPerPageItems: [4, 8, 12],
        pagination: {
            rowsPerPage: 4
        },
        dialog: false,
        dialog2: false,
        dialog3: false,
        dialogProgress: true,
        dialogHelp: false,
        cardTicket: false,
        meseroUser: [],
        partidaId: [],
        productoid: {
            name: 'Seleccionar',
            nombre: '',
            unidad: '',
            precio: 0,
            grupo: ''
        },
        fechaSeleccionada: null, // Variable para almacenar la fecha seleccionada en el date picker
        mostrarDatePicker: false, // Variable para controlar la visibilidad del date picker
        ingresos: [],
        ingreso: {
            proveniente: '',
            autor: {
                userName: ''
            },
            fechaMili: '',
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaDocumento: null,
            tipoDinero: '',
            tipoIngreso: '',
            numDocumento: '',
            monto: '',
            ingrid: '',
            notas: '',            
            gastoPartidas: [''],
        },
        etiquetasDinero: ['Efectivo', 'Banco'],
        etiquetasIngreso: [],
        sumGastos: '',
        editedIndex: -1,
        editedIndexPartida: -1,
        defaultItem: {
            proveniente: '',
            autor: {
                userName: ''
            },
            fechaMili: '',
            fechaRegistro: new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }),
            fechaDocumento: null,
            tipoDinero: '',
            tipoIngreso: '',
            numDocumento: '',
            monto: '',
            ingrid: '',
            notas: '',            
            gastoPartidas: [''],
        },
        defaultItemPartida: {
            nombreProducto: '',
            unidad: '',
            precio: '',
            subtotal: 0,
            cantidad: '',
        },    
        gastoPartidas: [],
        partida: {
            nombreProducto: '',
            unidad: '',
            subtotal: 0,
            cantidad: '',
            precio: '',
            ptdaId: '',
            proveniente: '',
            gastogaId: '',
            hora: '',
            autor: {
                colid: '',
                userName: '',
                // uid: ''    
            },
        },
        search: '',  
        selectComanda: null,
        today: '',
        daysPast: '',
        headers: [
          {
            text: 'Proveniencia',
            align: 'center',
            value: 'proveniente'
          },
          { text: 'Acciones', value: '', sortable: false, align: 'center' },
          { text: 'Etiqueta de Ingreso', value: 'tipoIngreso', },
          { text: 'Direccionado a:', value: 'tipoDinero', },
          { text: 'Monto', value: 'monto', },
          { text: 'Fecha de documento', value: 'fechaDocumento', },
          { text: '#documento', value: 'numDocumento', },
        ],              
        headers2: [
          {
            text: 'Producto',
            align: 'left',
            value: 'producto'
          },
          { text: 'Cant', value: 'cantidad', align: 'center', },
          { text: '$Precio', value: 'precio' },
          { text: 'Unidad', value: 'unidad', align: 'center', },
          { text: 'Sub-Total', value: 'subtotal' },
          { text: 'Acciones', value: '', align: 'center' },
        ], 
        headers3: [ //ticket
          {
            text: 'Producto',
            align: 'left',
            value: 'producto',
          },
          { text: 'Cantidad', value: 'cantidad', sortable: false, },
          { text: '$Precio', value: 'precio', sortable: false, },
        ],  
    }),
    validations: {
        ingreso: {
            proveniente: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
            numDocumento: {
                maxLength: maxLength(20),
            },
            monto: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(20),
                minValue: minValue(0),
            },
            tipoIngreso: {
                required,
            },
            tipoDinero: {
                required,
            },
        },
        partida: {            
            nombreProducto: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
            unidad: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(15),
            },            
            precio: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(15),
            },
            cantidad: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(15),
            }
        },
        editedItemEtiq: {
            tipoDato: {
                required,
            },
            dato: {
                required,
            },
        },
    },
    created() {
        this.dateFormatted = this.formatDate(this.ingreso.fechaDocumento);
        this.consultarWhereFrom()
        this.actualizarPerfilAuth()
        this.consultaretiquetasFzsDB()
    },
    watch: {
      'ingreso.fechaDocumento'() {
        this.dateFormatted = this.formatDate(this.ingreso.fechaDocumento);
      }
    },
    mounted() {
        this.setFechaFactura();
    },
    computed: {
        ...mapState('sesion', ['usuario']),
        ...mapState('usuarioHost', ['usuarioHost']),
        ...mapState('usuarioHost', ['perfilAuth']),
        computedDateFormatted() {
            return this.formatDate(this.ingreso.fechaDocumento);
        },
        paletaColores() {
            if (this.usuarioHost.designColors && this.usuarioHost.designColors.primero !== '') {
                return this.usuarioHost.designColors;
            } else {
                const colores = ['#E040FB',];
                const colores2 = ['#616161',];
                const colorAleatorio = colores[Math.floor(Math.random() * colores.length)];
                const colorAleatorio2 = colores2[Math.floor(Math.random() * colores2.length)];
                return {
                primero: colorAleatorio,
                segundo: colorAleatorio2,
                tercero: colorAleatorio,
                cuarto: colorAleatorio
                };
            }
        },
        colaboradorBorrar() {

            if(this.colaborador.switchBorrar == true){
                // alert("Object found inside the array.");
                return true
            } else{
                // alert("Object not found.");
                return false
            }
        },
        puedeRegIngresos() {
            const perfilAuth = this.perfilAuth;

            if (perfilAuth) {
                return true;
            } else {
                const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "registrarIngreso" && acceso.value);
                return Boolean(accesoAgregar);
            }
        },
        puedeRegEtqs() {
            const perfilAuth = this.perfilAuth;

            if (perfilAuth) {
                return true;
            } else {
                const accesoAgregar = this.colaborador.estatusAccesos && this.colaborador.estatusAccesos.find(acceso => acceso.funcion === "CrearEtiquetas" && acceso.value);
                return Boolean(accesoAgregar);
            }
        },
        formTitle () {
            return this.editedIndex === -1 ? 'Nuevo Ingreso' : 'Editar Ingreso'  
        },
        formTitlePartidas () {
            return this.editedIndexPartida === -1 ? 'Nueva Partida' : 'Editar Partida'
        }, 
        subtotSum () {
            var sum = 0;
            this.gastoPartidas.forEach(x => {
                sum += x.subtotal;
            });
            return sum            
        },
        impuesto () {
            let x = 0.16
            var imp = this.subtotSum * x
            // return imp
            return +(Math.round(imp + "e+2")  + "e-2");
        },
        totSum () {
            var tS = this.impuesto + this.subtotSum
            return tS
        },
        totIngresos () {
            var sum = 0;
            this.ingresos.forEach(x => {
                sum += Number(x.monto);
            });
            return sum
        },
        erroresTipoIngreso() {
            let errores = []
            if (!this.$v.ingreso.tipoIngreso.$dirty) { return errores }
            if (!this.$v.ingreso.tipoIngreso.required) { errores.push('Selecciona tipo de Ingreso.') }
            return errores
        },
        erroresTipoDinero() {
            let errores = []
            if (!this.$v.ingreso.tipoDinero.$dirty) { return errores }
            if (!this.$v.ingreso.tipoDinero.required) { errores.push('Selecciona tipo de Ingreso.') }
            return errores
        },
        erroresProveedor() {
            let errores = []
            if (!this.$v.ingreso.proveniente.$dirty) { return errores }
            if (!this.$v.ingreso.proveniente.required) { errores.push('Ingresa el nombre del proveniente.') }
            if (!this.$v.ingreso.proveniente.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            if (!this.$v.ingreso.proveniente.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresNumFactura() {
            let errores = []
            if (!this.$v.ingreso.proveniente.$dirty) { return errores }
            if (!this.$v.ingreso.proveniente.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresMonto() {
            let errores = []
            if (!this.$v.ingreso.monto.$dirty) { return errores }
            if (!this.$v.ingreso.monto.required) { errores.push('Ingresa el monto.') }
            if (!this.$v.ingreso.monto.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.ingreso.monto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            if (!this.$v.ingreso.monto.minValue) { errores.push('Ingresa un número mayor a cero.') }
            return errores
        },
        erroresProducto() {
            let errores = []
            if (!this.$v.partida.nombreProducto.$dirty) { return errores }
            if (!this.$v.partida.nombreProducto.required) { errores.push('Ingresa el nombre del producto.') }
            if (!this.$v.partida.nombreProducto.minLength) { errores.push('Ingresa al menos 3 caracteres.') }
            if (!this.$v.partida.nombreProducto.maxLength) { errores.push('Ingresa máximo 20 caracteres.') }
            return errores
        },
        erroresUnidad() {
            let errores = []
            if (!this.$v.partida.unidad.$dirty) { return errores }
            if (!this.$v.partida.unidad.required) { errores.push('Ingresa el unidad del producto.') }
            if (!this.$v.partida.unidad.minLength) { errores.push('Ingresa al menos 2 caracteres.') }
            if (!this.$v.partida.unidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresPrecio() {
            let errores = []
            if (!this.$v.partida.precio.$dirty) { return errores }
            if (!this.$v.partida.precio.required) { errores.push('Ingresa el precio.') }
            if (!this.$v.partida.precio.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.precio.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
        erroresCantidad() {
            let errores = []
            if (!this.$v.partida.cantidad.$dirty) { return errores }
            if (!this.$v.partida.cantidad.required) { errores.push('Ingresa la cantidad de unidades.') }
            if (!this.$v.partida.cantidad.minLength) { errores.push('Ingresa al menos 1 caracteres.') }
            if (!this.$v.partida.cantidad.maxLength) { errores.push('Ingresa máximo 15 caracteres.') }
            return errores
        },
    },
    methods: {
        ...mapMutations(['mostrarExito', 'mostrarInformacion', 'mostrarError', 'mostrarOcupado', 'ocultarOcupado', 'mostrarAdvertencia']),
        ...mapActions('usuarioHost', ['actualizarPerfilAuth']),
        setFechaFactura() {
            const fechaActual = new Date();
            const tzOffset = fechaActual.getTimezoneOffset() * 60000; // Obtener compensación de la zona horaria en milisegundos
            const fechaLocalISO = new Date(fechaActual - tzOffset)
            .toISOString()
            .substr(0, 10);
            this.ingreso.fechaDocumento = fechaLocalISO;
        },
        formatDate(date) {
        if (!date) return null;

        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year.slice(-2)}`;
        },
        parseDate(date) {
        if (!date) return null;

        const [day, month, year] = date.split('/');
        return `20${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        updateFechaFactura() {
        this.ingreso.fechaDocumento = this.parseDate(this.dateFormatted);
        },
        async consultarWhereFrom() {
            await dbL.collection('fromSameHost').get().then(fP => {
                this.sameHost = fP[0].sameHost
                this.sameHostName = fP[0].userHost
            }).catch(() => {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            }).finally(() => {
                this.consultarColLB()
            });
        },
        async consultarColLB() {
            if (this.sameHost == false || this.sameHostName != this.usuarioHost.userName.toLowerCase() ) {
                // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                this.$router.push({ name: 'Entrada' })
            } else if (this.sameHost == true) {
            
                this.colaboradores = []
                this.colaborador = ''
                if (this.perfilAuth == true) {
                    this.colaborador = this.usuarioHost
                    //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                } else if (this.perfilAuth == false) {
                
                    await dbL.collection('colaboradores').get().then(cols => {
                    this.colaboradores = cols
                    })

                    if (this.colaboradores.length > 0) {
                        let colaborador = this.colaboradores.find(({userName}) => userName === this.usuario.userName);
                        if (typeof colaborador === 'object') {
                            let convertINarray = Object.values(colaborador.estatusAccesos)
                            delete colaborador.estatusAccesos
                            colaborador.estatusAccesos = convertINarray
                            this.colaborador = colaborador
                            const tieneAcceso = this.colaborador.estatusAccesos.some(
                                acceso => acceso.componente === this.$route.name && acceso.value === true
                            );

                            if (!tieneAcceso) {
                                this.$router.push({ name: 'Entrada' });
                                this.mostrarError('No tienes los permisos para operar en este componente')
                            }
                            
                        } else {
                            this.colaborador = this.usuarioHost
                            //this.mostrarExito(`${this.usuario.userName}`+' '+ 'bienVenido a tu ofi-nuVin')
                        }
                    } else {
                        // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                        this.$router.push({ name: 'Entrada' })  
                    }
                } else {
                    // this.mostrarInformacion('Por el momento no tienes los permisos para acceder a esta área, intenta desde el área pública....')
                    this.$router.push({ name: 'Entrada' })
                }
            }
            // await dbL.collection('fromSameHost').delete()
            // this.sameHost = false
            // this.sameHostName = ''
            this.consultarIngresos()       
        },      
        async savePartida() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    var userName = userNameDoc.data();
                    
                    if (this.editedIndexPartida > -1) {
                        this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });
                        Object.assign(this.gastoPartidas[this.editedIndexPartida], this.partida);

                        try {
                            await updateDoc(doc(db, 'usuarios', userName.uid, 'ingresos', this.ingreso.ingrid, 'gastoPartidas', this.partida.ptdaId), {
                                cantidad: this.partida.cantidad,
                                producto: this.partida.nombreProducto,
                                unidad: this.partida.unidad,
                                proveniente: this.ingreso.proveniente,
                                numDocumento: this.ingreso.numDocumento,
                                autor: this.ingreso.autor.userName,
                                notas: this.ingreso.notas,
                                tipoDinero: this.ingreso.tipoDinero,
                                tipoIngreso: this.ingreso.tipoIngreso,
                            });

                            this.close();
                            this.dialog3 = false;
                            this.dialog2 = false;

                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }
                    } else {
                        let batch = writeBatch(db);
                        let ptdaId = uuidv4();
                        let subtotal = this.partida.precio * this.partida.cantidad;

                        let partida = {
                            ptdaId,
                            cantidad: Number(this.partida.cantidad),
                            precio: Number(this.partida.precio),
                            producto: this.partida.nombreProducto,
                            unidad: this.partida.unidad,
                            subtotal,
                            ingrid: this.ingreso.ingrid,
                            proveniente: this.ingreso.proveniente,
                            fechaRegistro: this.ingreso.fechaRegistro,
                            numDocumento: this.ingreso.numDocumento,
                            notas: this.ingreso.notas,
                            tipoDinero: this.ingreso.tipoDinero,
                            tipoIngreso: this.ingreso.tipoIngreso,
                            autor: {
                                colid: this.colaborador.colid || 'userHostAsColaborador',
                                userName: this.colaborador.userName,
                            },
                        };

                        batch.set(doc(db, 'usuarios', userName.uid, 'ingresos', this.ingreso.ingrid, 'gastoPartidas', partida.ptdaId), partida);

                        try {
                            await batch.commit();
                            this.gastoPartidas.push(this.partida);

                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }

                        this.$nextTick(function () {
                            this.gastoPartidas = [];
                            this.consultarPartidas();
                        });

                        this.closePartida();
                    }
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error cargando la información');
            }
        },
        async save() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    var userName = userNameDoc.data();
                    
                    if (this.editedIndex > -1) {
                        this.mostrarOcupado({ titulo: 'Actualizando Datos', mensaje: 'Cargando datos...' });
                        Object.assign(this.ingresos[this.editedIndex], this.ingreso);

                        try {
                            await updateDoc(doc(db, 'usuarios', userName.uid, 'ingresos', this.ingreso.ingrid), {
                                proveniente: this.ingreso.proveniente,
                                tipoDinero: this.ingreso.tipoDinero,
                                tipoIngreso: this.ingreso.tipoIngreso,
                                numDocumento: this.ingreso.numDocumento,
                                fechaDocumento: this.ingreso.fechaDocumento,
                                notas: this.ingreso.notas,
                                monto: Number(this.ingreso.monto),
                            });

                            this.close();
                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }
                    } else {
                        let batch = writeBatch(db);
                        let ingrid = uuidv4();
                        
                        let ingreso = {
                            ingrid,
                            proveniente: this.ingreso.proveniente,
                            numDocumento: this.ingreso.numDocumento,
                            monto: Number(this.ingreso.monto),
                            fechaMili: new Date().getTime(),
                            fechaDocumento: this.ingreso.fechaDocumento,
                            fechaRegistro: this.ingreso.fechaRegistro,
                            tipoDinero: this.ingreso.tipoDinero,
                            tipoIngreso: this.ingreso.tipoIngreso,
                            notas: this.ingreso.notas,
                            autor: {
                                colid: this.colaborador.colid || 'userHostAsColaborador',
                                userName: this.colaborador.userName,
                            },
                        };

                        batch.set(doc(db, 'usuarios', userName.uid, 'ingresos', ingreso.ingrid), ingreso);

                        try {
                            await batch.commit();
                            this.ingresos.push(ingreso);

                            this.mostrarExito('Se cargó la información exitosamente');
                        } catch (error) {
                            this.mostrarError('Ocurrió un error cargando la información');
                        } finally {
                            this.ocultarOcupado();
                        }

                        this.close();
                    }

                    await this.sendToDash();
                }
            } catch (error) {
                this.mostrarError('Ocurrió un error cargando la información');
                this.ocultarOcupado();
            }
        },
        async sendToDash() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Cargando datos...' });

            try {
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    let suma = 0;
                    let today = new Date();
                    let currentMonth = today.getMonth();

                    this.ingresos.forEach(x => {
                        let ingrsoDate = new Date(x.fechaDocumento);
                        if (ingrsoDate.getMonth() === currentMonth) {
                            suma += Number(x.monto);
                        }
                    });

                    let objToDash = {
                        fromComponent: this.$route.name,
                        icon: 'mdi-currency-usd',
                        title: 'Ingresos Mensual',
                        typeShow: 'datoNum', // datoNum, datoTexto, grafica, list-text, list-number, list-text-number
                        data: suma,
                        autor: this.usuario.userName
                    };

                    await setDoc(doc(db, 'usuarios', userName.uid, 'dashData', 'dash-' + objToDash.title), objToDash);
                }
            } catch (error) {
                console.error('Error al enviar los datos al dashboard:', error);
                this.mostrarError('Ocurrió un error enviando los datos al dashboard.');
            } finally {
                this.ocultarOcupado();
            }
        },
        close () {
            this.dialog = false
            setTimeout(() => {
                this.ingreso = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            }, 300)            
        },
        closePartida () {            
            this.dialog3 = false
            setTimeout(() => {
                this.partida = Object.assign({}, this.defaultItemPartida)
                this.editedIndexPartida = -1
            }, 300)
        },
        editItem (item) {          
            this.editedIndex = this.ingresos.indexOf(item) 
            this.ingreso = Object.assign({}, item)
            this.dialog = true
        },
        editItemPartida (item) {          
            this.editedIndexPartida = this.gastoPartidas.indexOf(item) 
            this.partida = Object.assign({}, item)
            this.dialog3 = true
        },
        async deleteItem(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Eliminando datos...' });

            try {
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    const index = this.ingresos.indexOf(item);

                    if (index !== -1) {
                        this.ingresos.splice(index, 1);
                        await deleteDoc(doc(db, 'usuarios', userName.uid, 'ingresos', item.ingrid));
                        this.mostrarExito('Se eliminó el concepto de Ingreso exitosamente');
                    } else {
                        this.mostrarError('El ingreso no se encontró en la lista.');
                    }
                }
            } catch (error) {
                console.error('Error al eliminar el ingreso:', error);
                this.mostrarError('Ocurrió un error borrando la información');
            } finally {
                this.ocultarOcupado();
            }
            this.close();
        },
        async deletePartida(item) {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Eliminando partida...' });

            const index = this.gastoPartidas.indexOf(item);
            if (index !== -1) {
                this.gastoPartidas.splice(index, 1);
            }

            try {
                this.partida = { ...item };
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));

                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    await deleteDoc(doc(db, 'usuarios', userName.uid, 'ingresos', this.ingreso.ingrid, 'gastoPartidas', this.partida.ptdaId));

                    this.mostrarExito('Se eliminó la partida exitosamente');
                }
            } catch (error) {
                console.error('Error al eliminar la partida:', error);
                this.mostrarError('Ocurrió un error borrando la información');
            } finally {
                this.ocultarOcupado();
            }
            this.closePartida();
        },
        async consultaretiquetasFzsDB() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Consultando etiquetas...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();

                    // Obtener las etiquetas
                    let etiquetasQuery = collection(db, 'usuarios', userName.uid, 'etiquetasFzsDB');
                    let resultado = await getDocs(etiquetasQuery);

                    resultado.forEach(doc => {
                        let datadb = doc.data();
                        if (datadb.tipoEtiq === 'Dinero') {
                            this.etiquetasDinero.push(datadb.nombreEtq);
                        }
                        if (datadb.tipoEtiq === 'Ingreso') {
                            this.etiquetasIngreso.push(datadb.nombreEtq);
                        }
                    });

                } else {
                    this.mostrarError('No se encontró el usuario.');
                }
            } catch (error) {
                console.error('Error al consultar las etiquetas:', error);
                this.mostrarError('Ocurrió un error consultando las Etiquetas.');
            } finally {
                this.ocultarOcupado();
            }
        },
        async consultarIngresos() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            this.today = new Date().getTime();
            this.daysPast = this.today - (86400000 * 30);

            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Consultando ingresos...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    
                    // Consultar ingresos ordenados por fecha
                    let ingresosQuery = query(
                        collection(db, 'usuarios', userName.uid, 'ingresos'),
                        orderBy('fechaMili', 'desc')
                    );
                    let resultado = await getDocs(ingresosQuery);
                    
                    resultado.forEach(doc => {
                        let ingreso = doc.data();
                        this.ingresos.push(ingreso);
                    });
                } else {
                    this.mostrarError('No se encontró el usuario.');
                }
            } catch (error) {
                console.error('Error al consultar los ingresos:', error);
                this.mostrarError('Ocurrió un error consultando los ingresos.');
            } finally {
                this.ocultarOcupado();
                this.dialogProgress = false;
            }
        },
        async consultarPartidas() {
            let userNameParametro = this.$route.params.userName.toLowerCase();

            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Consultando partidas...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    
                    // Consultar las partidas asociadas al ingreso
                    let partidasQuery = query(
                        collection(db, 'usuarios', userName.uid, 'ingresos', this.ingreso.ingrid, 'gastoPartidas'),
                        where('ingrid', '==', this.ingreso.ingrid)
                    );
                    let resultado = await getDocs(partidasQuery);

                    resultado.forEach(subdoc => {
                        let partida = subdoc.data();
                        this.gastoPartidas.push(partida);
                    });
                } else {
                    this.mostrarError('No se encontró el usuario.');
                }
            } catch (error) {
                console.error('Error al consultar las partidas:', error);
                this.mostrarError('Ocurrió un error consultando las partidas.');
            } finally {
                this.ocultarOcupado();
            }
        },
        async consultarTicket() {
            let userNameParametro = this.$route.params.userName.toLowerCase();
            
            this.mostrarOcupado({ titulo: 'Cargando Datos', mensaje: 'Consultando ticket...' });

            try {
                // Obtener el documento del usuario
                let userNameDoc = await getDoc(doc(db, 'userNames', userNameParametro));
                if (userNameDoc.exists()) {
                    let userName = userNameDoc.data();
                    
                    // Obtener el documento del ticket
                    let ticketDoc = await getDoc(doc(db, 'usuarios', userName.uid, 'contTicket', 'cTicket'));
                    
                    if (ticketDoc.exists()) {
                        this.contTicket = ticketDoc.data();
                    } else {
                        this.mostrarError('Faltan datos en el área de Configuración para incluir en el ticket.');
                    }
                } else {
                    this.mostrarError('No se encontró el usuario.');
                }
            } catch (error) {
                console.error('Error al consultar el ticket:', error);
                this.mostrarError('Ocurrió un error consultando el ticket.');
            } finally {
                this.ocultarOcupado();
            }
        },         
        async abrirGasto(ingreso) {    
            this.gastoPartidas = []
            this.ingreso = ingreso;
            await this.consultarPartidas(); //con o sin await?
            // this.consultarTicket()            
        },
        printData() {
          var divToPrint= this.$refs.printTable
          let newWin= window.open("");
          newWin.document.write(divToPrint.outerHTML);
          newWin.print();
          newWin.close();
        }
    }
    
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.text-right {
  text-align: right;
}

.tablasubtot {
    font-size: 1rem;
}

.custom-card {
  border-radius: 30px;
}
    
</style>

