<template>
  <v-layout>
    <v-flex xs12 sm10 >
      <div v-for="(item, index) in itemsApps" :key="index">
        <v-card
          class="mx-auto" 
          max-width="500"            
          v-if="appSelected == item.app"
        >
        <v-layout align-center justify-center row mt-1 py-3>
          <v-flex xs7 text-xs-center>
            <v-img
            v-if="item.fotoLogoApp"
            :src=item.fotoLogoApp
            aspect-ratio="1.75"
            ></v-img>
            <v-btn v-else fab dark large color="principal">
               <v-icon large >{{ item.icon }}</v-icon>
            </v-btn>           
          </v-flex>
          <v-flex xs5 text-xs-center>
            <strong class="logoFont black--text">nu<strong class="principal--text">Vin</strong></strong><br>
            <span class="headline">{{ item.app }}</span><br>
            <span class="caption grey--text">sofware-web-aplicación</span><br>
            <div v-if="item.secciones">
              <v-btn
                color="principal" 
                dark 
                depressed 
                class="text-none" 
                small
                @click="instalarApp()"
                v-if="!usuario || usuario.app == 'personal'"
              >
                <v-icon>get_app</v-icon>
                adquirir acceso
              </v-btn>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="grey" 
                    class="text-none"
                    dark
                    small
                    v-on="on"
                  >
                    <v-icon disabled dark>get_app</v-icon>
                    adquirir acceso
                  </v-btn>
                </template>
                <span>No puedes adquirir acceso esta app porque ya has adquirido esta app u otro giro. Sólo puedes adquirir un giro por cuenta nuVin.</span>
              </v-tooltip>
            </div>
            <template v-if="!item.secciones">
              <v-layout row justify-center>
                <v-dialog v-model="dialogSolAsesor" persistent max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="principal" 
                        dark 
                        depressed 
                        class="text-none" 
                        small
                        v-if="!usuario || usuario.app == 'personal'"
                      >
                        <!-- <v-icon>get_app</v-icon> -->
                        Solicitar</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Solicitar Cotización o más información:</v-card-title>
                    <v-card-text>Por favor comunícate directamente con tu asesor o 
                      <v-card-title class="subheading font-weight-medium">contáctanos:</v-card-title>
                      <v-card-text>
                          <span><span class="principal--text font-weight-bold">email:</span>  nubesdebits@gmail.com</span><br>
                          <span><span class="principal--text font-weight-bold">móvil-whatsapp:</span>  9996470286</span><br>
                      </v-card-text>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="prinicipal" flat @click="dialogSolAsesor = false">Enterado</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-layout>
            </template>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>descripción</span>
        </v-card-title>
        <v-card-text class="py-0 text-xs-left">
          <span class="caption grey--text">
            {{ item.descripcion }}
          </span>
        </v-card-text>
        <v-card-title class="title font-weight-regular justify-space-between">
          <!-- <span>{{ item.app }}</span> -->
          <span>características-funciones</span>
        </v-card-title>
        <v-card-text v-for="element in item.funciones" :key="element.index" class="py-0 text-xs-left">
          <span class="caption grey--text">
            {{ element }}
          </span>
        </v-card-text>

        <div v-if="item.precioMensualSemilla || item.precioAnualSemilla">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>precios</span>
          </v-card-title>
          <v-card-text class="py-0 text-xs-left">
            <span class="caption grey--text">
              {{ item.descPrueba }}
            </span><br>
            <span class="subheading">precios desde</span><br>
            <span class="caption grey--text">
              mensual: ${{ item.precioMensualSemilla }} mxn
            </span><br>
            <span class="caption grey--text">
              anual: ${{ item.precioAnualSemilla }} mxn
            </span><br>
            <span class="subheading">precios hasta</span><br>
            <span class="caption grey--text">
              mensual: ${{ item.precioMensualEmpresario }} mxn
            </span><br>
            <span class="caption grey--text">
              anual: ${{ item.precioAnualEmpresario }} mxn
            </span>
          </v-card-text>
        </div>

        <v-card-title class="title font-weight-regular justify-space-between">
          <span>información extra</span>
        </v-card-title>
        <v-card-text class="py-0 text-xs-left">
          <span class="caption grey--text">
            °Tamaño: dentro del equipo no se ocupa nada de espacio. Los datos son almacenados en la nube virtual. <br>
            °Dispositivo para los que funciona: móvil, tableta, laptop, computadores de escritorio, <br>
            °Fecha de ultima actualización: {{ item.fechaUltUpdate }}
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <div v-if="item.secciones">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>adquirir acceso</span>
          </v-card-title>
          <v-card-text class="py-0 text-xs-left">
            <span class="caption grey--text">
              Al dar click en adquirir acceso, por seguridad de la información que ingresarás, se te pedirá un 
              email, password y el nombre de usuario como quieres ser reconocido en la nuVin.
            </span>
          </v-card-text>
        </div>
        <v-card-actions>          
          <v-flex v-if="item.secciones" class="text-xs-right" >
            <v-btn
              color="principal" 
              dark 
              depressed 
              class="text-none" 
              small
              @click="instalarApp()"
              v-if="!usuario || usuario.app == 'personal'"
            >
              <v-icon>get_app</v-icon>
              adquirir acceso
            </v-btn>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="grey" 
                  class="text-none"
                  dark
                  small
                  v-on="on"
                >
                  <v-icon disabled dark>get_app</v-icon>
                  adquirir acceso
                </v-btn>
              </template>
              <span>No puedes adquirir acceso esta app porque ya has adquirido esta app u otro giro. Sólo puedes adquirir un giro por cuenta nuVin.</span>
            </v-tooltip>
          </v-flex>     
          <v-flex v-if="!item.secciones">
            <v-dialog v-model="dialogSolAsesor" persistent max-width="290">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="principal" 
                    dark 
                    depressed 
                    class="text-none" 
                    small
                    v-if="!usuario || usuario.app == 'personal'"
                  >
                    <!-- <v-icon>get_app</v-icon> -->
                    Solicitar</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Solicitar Cotización o más información:</v-card-title>
                <v-card-text>Por favor comunícate directamente con tu asesor o envía un email con tus datos a: 
                  <strong>nubesdebits@gmail.com</strong> y con gusto nos comunicaremos contigo.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="principal" flat @click="dialogSolAsesor = false">Enterado</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>   
        </v-card-actions>
        
        </v-card>
      </div>        
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { db} from '@/firebase'
import Localbase from 'localbase'

let dbL = new Localbase('db')

  export default {
    data () {
      return {
        appSelected: '',
        itemsApps: [],
        dialogSolAsesor: false
      }

    },

    created() {
      this.appSelectedFunc()
      this.getitemsApps()
    },
    computed: {
      ...mapState('sesion', ['usuario']),
      // ...mapState('usuarioHost', ['usuarioHost']),
    },
    methods: {
      appSelectedFunc () {
        this.appSelected = this.$route.params.app
      },
      async getitemsApps() {
        await dbL.collection('itemApp').delete()
        // await dbL.collection('itemsApps').get().then(itGi => {
        //   this.itemsApps = itGi
        // })
        try {
          let resultado = await db.collection('itemsApps')
                                  .get()
          
          resultado.docs.forEach(doc => {
              let item = doc.data()
              this.itemsApps.push(item)    
          })

        }
        catch (error) {                
            this.mostrarError('Ocurrió un error consultando las apps.')                
        }

      },
      async instalarApp() {
        let itemApp = this.itemsApps.filter( item => item.app == this.appSelected )
        await dbL.collection('itemApp').set(itemApp)
        if ( itemApp[0].tipoApp == 'giro' ) {
          this.$router.push({ name: 'registro', params: {app: this.appSelected}})
        } else if (itemApp[0].tipoApp == 'herramienta') {
          this.$router.push({ name: 'registro', params: {app: this.appSelected}})
        }
        
      },
    },
  }
</script>